import './index.sass';

import { isEmpty } from 'lodash';
import { instanceOf } from 'prop-types';
import React from 'react';
import uuidv1 from 'uuid/v1';

const Breacrumb = (props) => {
  const { items } = props;

  if (!isEmpty(items)) {
    return (
      <ul className="Breadcrumb">
        {items.map((item) => {
          if (item.url) {
            return (
              <li key={uuidv1()}>
                <a href={item.url}>{item.name}</a>
              </li>
            );
          }
          if (item.bold) {
            return (
              <li key={uuidv1()}>
                <strong>{item.name}</strong>
              </li>
            );
          }
          return (
            <li className="active" key={uuidv1()}>
              {item.name}
            </li>
          );
        })}
      </ul>
    );
  }

  return <div></div>; //null
};

Breacrumb.propTypes = {
  items: instanceOf(Array),
};

Breacrumb.defaultProps = {
  items: [],
};

export default Breacrumb;
