/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './index.sass';
import 'react-datepicker/dist/react-datepicker.css';

import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import { arrayOf, bool, func, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  getBusinessHours as getBusinessHoursAction,
  getBusinessOptions,
  getBusinessProfileApi,
  getBusinessProfile as getBusinessProfileAction,
  getBusinessSocialMedia as getBusinessSocialMediaAction,
  updateBusinessV2,
} from '../../../actions/businesses';
import NoBusinessesIMG from '../../../assets/images/img_nobusinesses.jpg';
import AsyncContent from '../../../components/AsyncContent';
import EmptyPage from '../../../components/EmptyPage';
import Loader from '../../../components/Loader';
import MasterPage from '../../../components/MasterPage';
import {
  BUSINESS_HOUR_TYPE,
  BUSINESS_PROFILE_TYPE,
  BUSINESS_SOCIAL_MEDIA_TYPE,
} from '../../../types/business';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';
import { validURL } from '../../../utils/placeId';
import BusinessBasicForm from '../BusinessProfile/BusinessBasicForm';
import BusinessContactInformationForm from '../BusinessProfile/BusinessContactInformationForm';
import BusinessHours, { dbToHoursModal, hoursModaltoDb } from '../BusinessProfile/BusinessHours';
import BusinessSocialInformationForm from '../BusinessProfile/BusinessSocialInformationForm';
import BusinessSearchForm from '../BusinessProfile/BusinessSearchForm';
import BusinessAddressForm from '../BusinessProfile/BusinessAddressForm';
import LightBox from '../../../components/LightBox';
import BusinessOptions from '../BusinessProfile/BusinessOptions';
import BusinessAmenities from '../BusinessProfile/BusinessAmenities';
import BusinessHealth from '../BusinessProfile/BusinessHealth';
import BusinessMenuForm from '../BusinessProfile/BusinessMenuForm';
import BusinessHeader from '../BusinessProfile/BusinessHeader';
import infoIcon from '../../../assets/images/info.svg';
import USER_ROLES from '../../../config/user-roles';
import HISTORY_TYPE from '../../../types/history';

/* global localStorage */

class BusinessEdit extends React.Component {
  state = {
    businessProfileSaveErrorMessage: '',
    businessProfileSaveSuccessMessage: '',
    form: {
      Address: '',
      BusinessId: null,
      BusinessSourceId: null,
      Type: null,
      BusinessHours: null,
      Name: '',
      Category: null,
      FollowerCount: null,
      ThirdPartyBusinessSourceId: '',
      GigCount: null,
      GooglePlaceId: '',
      IsUserFollowingTheBusiness: bool,
      Latitude: null,
      Longitude: null,
      RatingCount: null,
      VideoCount: null,
      Website: '',
      PhoneNumber: null,
      MenuUrl: null,

      City: '',
      Country: '',
      Neighborhood: '',
      Facebook: '',
      Instagram: '',
      IsPublic: false,
      Zipcode: '',
      State: '',
      Description: '',
      Twitter: '',
      Tiktok: '',
      OtherSocialMediaLink: '',

      BusinessOption: {},
    },
    nonFourSquare: false,
    nonFourSquareChangeTrigger: false,
    clearValue: false,
    isFormError: false,
    isPhoneNumberInvalid: false,
    hideContent: true,
    loading: false,
    logoUrl: '',
    bannerUrl: '',

    businessOptionParamaters: {},
    businessProfile: null,
    businessProfileError: '',
    isDialog: false,
    isHowWeUse: false,
  };

  baseState = this.state;

  componentDidMount() {
    const {
      match: {
        params: { corporateGroupId },
      },
      history,
    } = this.props;
    const userRole = localStorage.getItem('frankiRole');

    getBusinessProfileApi(corporateGroupId).subscribe(
      (data) => {
        const businessProfile = { ...data };
        if (
          [USER_ROLES.businessManager].includes(userRole) &&
          businessProfile &&
          businessProfile.VerificationStatus &&
          businessProfile.VerificationStatus !== 'Verified'
        ) {
          history.push(`/admin/business-profile/${corporateGroupId}`);
        }
        businessProfile.Facebook = businessProfile.FacebookUrl;
        businessProfile.Instagram = businessProfile.InstagramUrl;
        businessProfile.Twitter = businessProfile.TwitterUrl;
        businessProfile.Tiktok = businessProfile.TikTokUrl;
        businessProfile.OtherSocialMediaLink = businessProfile.OtherSocialMediaUrl;
        businessProfile.BusinessHours = businessProfile.BusinessHours
          ? dbToHoursModal(businessProfile.BusinessHours)
          : null;

        const newFormattedOptions = {};
        if (businessProfile.BusinessOptions) {
          businessProfile.BusinessOptions.forEach(({ Name, Value }) => {
            newFormattedOptions[Name] = Value;
          });
        }
        businessProfile.BusinessOption = newFormattedOptions;

        this.setState({ businessProfile });
        this.initFormValue(businessProfile);
      },
      (err) => {
        this.setState({ businessProfileError: err });
      },
    );

    getBusinessOptions().subscribe(({ BusinessOptionTypes }) => {
      this.setState({ businessOptionParamaters: BusinessOptionTypes });
    });
  }

  onHandleChange(name, value) {
    this.setState(({ form }) => ({
      form: { ...form, [name]: value },
    }));
  }

  onHandleBusinessOptionChange(name, value) {
    this.setState(({ form }) => ({
      form: { ...form, BusinessOption: { ...form.BusinessOption, [name]: value } },
    }));
  }

  onNonFourSquareChange() {
    const { nonFourSquare } = this.state;

    if (nonFourSquare) {
      this.deActivateNonFourSquare();
    } else {
      this.setState({ nonFourSquareChangeTrigger: true });
    }
  }

  onFormErrorStateUpdate(errorState) {
    const { isFormError } = this.state;
    if (isFormError !== errorState) {
      this.setState({ isFormError: errorState });
    }
  }

  async onHandleSubmit(event) {
    event.preventDefault();
    const {
      match: {
        params: { corporateGroupId },
      },
    } = this.props;
    const { isFormError, form, nonFourSquare } = this.state;

    this.setState({
      businessProfileSaveErrorMessage: '',
      businessProfileSaveSuccessMessage: '',
      isPhoneNumberInvalid: false,
    });
    const phoneNumber = parsePhoneNumberFromString(`+${String(form.PhoneNumber).replace('+', '')}`);
    const isPhoneNumberInvalid = !phoneNumber || !phoneNumber.isValid();
    if (isPhoneNumberInvalid) {
      this.setState({ isPhoneNumberInvalid: true });
      return;
    }
    if (!this.checkSocialMediaUrls(form.Facebook)) {
      this.setState({ businessProfileError: 'Facebook address is invalid' });
      return;
    }
    if (!this.checkSocialMediaUrls(form.Instagram)) {
      this.setState({ businessProfileError: 'Instagram address is invalid' });
      return;
    }
    if (!this.checkSocialMediaUrls(form.Twitter)) {
      this.setState({ businessProfileError: 'Twitter address is invalid' });
      return;
    }
    if (!this.checkSocialMediaUrls(form.Tiktok)) {
      this.setState({ businessProfileError: 'Tiktok address is invalid' });
      return;
    }

    if (!isFormError) {
      const newBusinessHours =
        form.BusinessHours && form.BusinessHours.length ? hoursModaltoDb(form.BusinessHours) : null;

      const newBusinessOptions = [];
      Object.entries(form.BusinessOption).forEach(([Name, Value]) => {
        if (Name === 'Parking') {
          if (Value) {
            newBusinessOptions.push({ Name, Value: Value.map((item) => item.Id) });
          }
        } else if (Name === 'HealthScore') {
          if (Value) {
            newBusinessOptions.push({ Name, Value: Value.Id || Value });
          }
        } else if (Value) {
          newBusinessOptions.push({ Name, Value: 1 });
        }
      });

      const requestParams = {
        Address: form.Address,
        BusinessSourceId: form.BusinessSourceId,
        Type: form.Type && form.Type.Id,
        City: form.City,
        Country: form.Country,
        Name: form.Name,
        Latitude: form.Latitude,
        Longitude: form.Longitude,
        PhoneNumber: form.PhoneNumber,
        Zipcode: form.Zipcode,
        State: form.State,
        Description: form.Description,
        Website: form.Website,
        Neighborhood: form.Neighborhood,
        ...(!nonFourSquare && form.ThirdPartyBusinessSourceId
          ? { ThirdPartyBusinessSourceId: form.ThirdPartyBusinessSourceId }
          : {}),
        ...(newBusinessHours ? { BusinessHours: JSON.stringify(newBusinessHours) } : {}),
        ...(form.Logo ? { Logo: form.Logo } : {}),
        ...(form.Banner ? { Banner: form.Banner } : {}),
        ...(form.MenuUrl ? { MenuUrl: form.MenuUrl } : {}),
        ...(form.Facebook ? { FacebookUrl: form.Facebook } : {}),
        ...(form.Instagram ? { InstagramUrl: form.Instagram } : {}),
        ...(form.Twitter ? { TwitterUrl: form.Twitter } : {}),
        ...(form.Tiktok ? { TikTokUrl: form.Tiktok } : {}),
        ...(form.OtherSocialMediaLink ? { OtherSocialMediaUrl: form.OtherSocialMediaLink } : {}),
        ...(newBusinessOptions.length > 0
          ? { BusinessOptions: JSON.stringify(newBusinessOptions) }
          : {}),
        ...(form.Category ? { CategoryId: form.Category.Id } : {}),
      };

      this.setState({ loading: true });
      // /* eslint-disable no-undef */
      // window.requestParams = requestParams;
      // window.form = form;
      // window.state = this.state;
      const result = await updateBusinessV2(corporateGroupId, requestParams);
      this.setState({
        businessProfileSaveErrorMessage: !result.Success ? result.Message : '',
        businessProfileSaveSuccessMessage: result.Success ? result.Message : '',
        loading: false,
      });
    }
  }

  checkSocialMediaUrls = (value) => {
    if (!value) return true;
    return validURL(value);
  };

  initFormValue(businessProfile) {
    const { form: defaultFormValue } = this.state;
    const {
      Address,
      City,
      Country,
      Latitude,
      Longitude,
      Zipcode,
      State,
      ThirdPartyBusinessSourceId,
      ...preloadedBusinessProfileDetails
    } = businessProfile;

    const form = {
      ...defaultFormValue,
      ...preloadedBusinessProfileDetails,
      ...(businessProfile.BusinessSourceId === 2
        ? preloadedBusinessProfileDetails
        : businessProfile),
    };

    const logoUrl = businessProfile.LogoUrl;
    const bannerUrl = businessProfile.BannerUrl;

    this.setState({
      form,
      logoUrl,
      bannerUrl,
      hideContent: false,
      nonFourSquare: !ThirdPartyBusinessSourceId,
    });
  }

  activateNonFourSquare() {
    this.setState({ nonFourSquare: true });
    this.setState(({ clearValue }) => ({
      clearValue: !clearValue,
    }));
    this.onHandleChange('ThirdPartyBusinessSourceId', '');
  }

  deActivateNonFourSquare() {
    this.setState({ nonFourSquare: false });
  }

  render() {
    const {
      auth,
      match: {
        params: { corporateGroupId },
      },
    } = this.props;

    const {
      businessProfile,
      businessProfileError,
      businessProfileSaveErrorMessage,
      businessProfileSaveSuccessMessage,
      businessSocialMediaError,
      businessOptionParamaters,
      form,
      isPhoneNumberInvalid,
      hideContent,
      loading,
      logoUrl,
      bannerUrl,
      nonFourSquare,
      clearValue,
      nonFourSquareChangeTrigger,
      isDialog,
      isHowWeUse,
    } = this.state;

    const userRole = localStorage.getItem('frankiRole');

    const { ThirdPartyBusinessSourceId } = form;

    const masterPageConfig = {
      auth,
      businessSidebarOn: true,
      businessSidebarData: { ...businessProfile, Logo: null },
      headerComponent: businessProfile ? (
        <BusinessHeader
          logoUrl={logoUrl}
          bannerUrl={bannerUrl}
          onHandleChange={(...params) => this.onHandleChange(...params)}
          onLogoUrlChange={(newLogoUrl) => this.setState({ logoUrl: newLogoUrl })}
          onBannerUrlChange={(newBannerUrl) => this.setState({ bannerUrl: newBannerUrl })}
          businessProfile={businessProfile}
          nonFourSquare={nonFourSquare}
        />
      ) : (
        <></>
      ),
    };

    return (
      <>
        <Helmet title="Franki Admin: edit profile" />

        <MasterPage {...masterPageConfig}>
          <AsyncContent
            content={(details) => (
              <form className="BusinessEditContainer" onSubmit={(e) => this.onHandleSubmit(e)}>
                <div className="BusinessEdit">
                  {[USER_ROLES.admin, USER_ROLES.saleAdmin].includes(userRole) && (
                    <div className="BusinessEdit__Blocks">
                      <h2 className="BusinessEdit__Blocks__Title">Search</h2>
                      <h3 className="BusinessEdit__Blocks__Subtitle">
                        Search for Foursquare businesses
                      </h3>

                      <div className="BusinessEdit__Blocks__Content">
                        <BusinessSearchForm
                          formState={form}
                          onHandleFormChange={(...params) => this.onHandleChange(...params)}
                          checkboxHandler={() => {
                            this.onNonFourSquareChange();
                          }}
                          nonFourSquare={nonFourSquare}
                          clearValue={clearValue}
                        />
                      </div>
                    </div>
                  )}
                  <div className="BusinessEdit__Blocks">
                    <h2 className="BusinessEdit__Blocks__Title">
                      Who are you?
                      <img
                        className="ml-10 infoElement"
                        src={infoIcon}
                        alt=""
                        height={20}
                        width={20}
                        onClick={() => {
                          this.setState({ isHowWeUse: true });
                        }}
                      />
                    </h2>
                    <h3 className="BusinessEdit__Blocks__Subtitle">
                      How would you describe your business? This information helps customers find
                      you on franki
                    </h3>

                    <div className="BusinessEdit__Blocks__Content BusinessEdit__Blocks__Content__Logo">
                      <BusinessBasicForm
                        formState={form}
                        disabled={!(nonFourSquare || ThirdPartyBusinessSourceId)}
                        onFormErrorStateUpdate={(errorState) =>
                          this.onFormErrorStateUpdate(errorState)
                        }
                        onHandleChange={(...params) => this.onHandleChange(...params)}
                      />
                    </div>
                  </div>

                  <div className="BusinessEdit__Blocks">
                    <h2 className="BusinessEdit__Blocks__Title">Where are you?</h2>
                    <h3 className="BusinessEdit__Blocks__Subtitle">
                      Our app uses {'Foursquare Place™ data'} so our users can find your location
                    </h3>

                    <div className="BusinessEdit__Blocks__Content">
                      <BusinessAddressForm
                        addressDisabled={!(nonFourSquare || ThirdPartyBusinessSourceId)}
                        formState={form}
                        onHandleFormChange={(...params) => this.onHandleChange(...params)}
                      />
                    </div>
                  </div>

                  <div className="BusinessEdit__Blocks">
                    <h2 className="BusinessEdit__Blocks__Title">
                      How can people connect with you?
                    </h2>
                    <h3 className="BusinessEdit__Blocks__Subtitle">
                      Tell your customers how they can connect with you
                    </h3>

                    <div className="BusinessEdit__Blocks__Content pb-20">
                      <BusinessContactInformationForm
                        formState={form}
                        isPhoneNumberInvalid={isPhoneNumberInvalid}
                        onHandleChange={(...params) => this.onHandleChange(...params)}
                        onHandleCloseDialog={() => this.setState({ isPhoneNumberInvalid: false })}
                      />
                      <BusinessSocialInformationForm
                        formState={form}
                        onHandleChange={(...params) => this.onHandleChange(...params)}
                      />
                    </div>
                  </div>

                  <div className="BusinessEdit__Blocks">
                    <h2 className="BusinessEdit__Blocks__Title">What&apos;s on the menu?</h2>
                    <h3 className="BusinessEdit__Blocks__Subtitle">
                      Upload a link to your menu so customers can see what they can get when they
                      come in-store
                    </h3>

                    <div className="BusinessEdit__Blocks__Content">
                      <BusinessMenuForm
                        formState={form}
                        onHandleChange={(...params) => this.onHandleChange(...params)}
                      />
                    </div>
                  </div>

                  <div className="BusinessEdit__Blocks">
                    <h2 className="BusinessEdit__Blocks__Title">When are you open?</h2>
                    <h3 className="BusinessEdit__Blocks__Subtitle">
                      Let customers know when you are open
                    </h3>

                    <div className="BusinessEdit__Blocks__Content">
                      <BusinessHours
                        businessHours={form.BusinessHours}
                        onHandleChange={(...params) => this.onHandleChange(...params)}
                      />
                    </div>
                  </div>

                  <div className="BusinessEdit__Blocks">
                    <h2 className="BusinessEdit__Blocks__Title">
                      What else do you offer?
                      <img
                        className="ml-10 infoElement"
                        src={infoIcon}
                        alt=""
                        height={20}
                        width={20}
                        onClick={() => {
                          this.setState({ isDialog: true });
                        }}
                      />
                    </h2>
                    <h3 className="BusinessEdit__Blocks__Subtitle BusinessEdit__Blocks__Subtitle--BorderBottom">
                      Select all of the amenities and features available at your business.
                    </h3>
                    {businessOptionParamaters && businessOptionParamaters.length > 0 && (
                      <>
                        <div className="BusinessEdit__Blocks__Content BusinessEdit__Blocks__Content--BorderBottom">
                          <BusinessOptions
                            formState={form}
                            businessOptionParamaters={businessOptionParamaters}
                            onHandleChange={(...params) =>
                              this.onHandleBusinessOptionChange(...params)
                            }
                          />
                        </div>
                        <div className="BusinessEdit__Blocks__Content BusinessEdit__Blocks__Content--BorderBottom">
                          <BusinessAmenities
                            formState={form}
                            businessOptionParamaters={businessOptionParamaters}
                            onHandleChange={(...params) =>
                              this.onHandleBusinessOptionChange(...params)
                            }
                          />
                        </div>
                        <div className="BusinessEdit__Blocks__Content BusinessEdit__Blocks__Content--BorderBottom">
                          <BusinessHealth
                            formState={form}
                            businessOptionParamaters={businessOptionParamaters}
                            onHandleChange={(...params) =>
                              this.onHandleBusinessOptionChange(...params)
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="buttons-actions">
                  {loading && (
                    <div className="loader">
                      <Loader />
                    </div>
                  )}

                  <Link
                    to={`/admin/business-profile/${corporateGroupId}`}
                    className="button small w100 f-size-14"
                  >
                    Cancel
                  </Link>

                  <button
                    disabled={loading}
                    type="submit"
                    className="button small orange-business-theme"
                  >
                    Save Changes
                  </button>

                  {details.BusinessSourceId === 2 && (
                    <label className="BusinessEdit__ConvertGooglePlace">
                      <input
                        name="bookmarks"
                        onChange={() =>
                          this.onHandleChange('BusinessSourceId', 3 - form.BusinessSourceId)
                        }
                        type="checkbox"
                        checked={form.BusinessSourceId === 1}
                      />
                      Convert Google Place to Franki business
                    </label>
                  )}

                  {details.BusinessSourceId === 3 && (
                    <label className="BusinessEdit__ConvertGooglePlace">
                      <input
                        name="bookmarks"
                        onChange={() =>
                          this.onHandleChange('BusinessSourceId', 4 - form.BusinessSourceId)
                        }
                        type="checkbox"
                        checked={form.BusinessSourceId === 1}
                      />
                      Convert third party business to Franki business
                    </label>
                  )}
                </div>
              </form>
            )}
            emptyPageContent={
              <EmptyPage
                buttonText="create business"
                buttonURI="/admin/register-business"
                description="let’s add your first business now"
                img={NoBusinessesIMG}
                title="looks like you have no businesses"
              />
            }
            errorMessage={
              businessProfileError || businessSocialMediaError || businessProfileSaveErrorMessage
            }
            expectedData={businessProfile}
            loading={hideContent}
            successMessage={businessProfileSaveSuccessMessage}
            onHandleCloseDialog={() =>
              this.setState({
                businessProfileError: '',
                businessSocialMediaError: '',
                businessProfileSaveErrorMessage: '',
              })
            }
          />
        </MasterPage>
        <LightBox
          style={{
            width: 450,
            height: 200,
            marginLeft: -225,
            marginTop: -100,
          }}
          onClose={() => {
            this.setState({ isHowWeUse: false });
          }}
          show={isHowWeUse}
        >
          <div className="DialogTitle">How we use your info?</div>
          <div className="DialogContent">
            The information you provide here will be used to help
            <br />
            users find your business through our search and filter
            <br />
            features. Please note: your business name and
            <br />
            description will be shown publicly on your profile.
          </div>
        </LightBox>
        <LightBox
          style={{
            width: 500,
            height: 200,
            marginLeft: -250,
            marginTop: -100,
          }}
          onClose={() => {
            this.setState({ isDialog: false });
          }}
          show={isDialog}
        >
          <div className="DialogContent">
            The more information you provide here the better our users can find
            <br />
            and decide whether your business is right for them. Note: we will
            <br />
            periodically review and ask our community to verify the amenities
            <br />
            and features you have said you provide here, so please be sure to
            <br />
            keep them up to date and accurate.
          </div>
        </LightBox>
        <LightBox
          style={{
            width: 500,
            height: null,
            marginLeft: -250,
            marginTop: -200,
          }}
          onClose={() => {
            this.setState({ nonFourSquareChangeTrigger: false });
          }}
          show={nonFourSquareChangeTrigger}
          title="Not a Foursquare Business?"
        >
          <>
            <div>
              <span
                style={{
                  fontSize: '1.4rem',
                  fontWeight: '300',
                }}
              >
                Are you sure you want to replace this Foursquare business? The Foursquare ID will be
                removed, if so.
              </span>
              <div className="buttons-actions" style={{ marginTop: '2rem' }}>
                <button
                  type="button"
                  className="button small w100"
                  onClick={() => {
                    this.setState({ nonFourSquareChangeTrigger: false });
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="button small orange-theme w100"
                  onClick={() => {
                    this.setState({ nonFourSquareChangeTrigger: false });
                    this.activateNonFourSquare();
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </>
        </LightBox>
      </>
    );
  }
}

BusinessEdit.propTypes = {
  auth: bool.isRequired,
  businessProfile: BUSINESS_PROFILE_TYPE.isRequired,
  businessProfileError: string.isRequired,
  businessSocialMedia: BUSINESS_SOCIAL_MEDIA_TYPE.isRequired,
  businessSocialMediaError: string.isRequired,
  getBusinessProfile: func.isRequired,
  getBusinessSocialMedia: func.isRequired,
  isFetchingBusinessProfile: bool.isRequired,
  isFetchingBusinessSocialMedia: bool.isRequired,
  match: ROUTER_MATCH_TYPE({ corporateGroupId: string.isRequired }).isRequired,
  history: HISTORY_TYPE.isRequired,
  getBusinessHours: func.isRequired,
  businessHours: arrayOf(BUSINESS_HOUR_TYPE).isRequired,
  businessHoursError: string.isRequired,
  isFetchingBusinessHours: bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    businessProfile,
    businessProfileError,
    businessSocialMedia,
    businessSocialMediaError,
    isFetchingBusinessProfile,
    isFetchingBusinessSocialMedia,

    businessHours,
    businessHoursError,
    isFetchingBusinessHours,
  } = state.businesses;
  return {
    businessProfile,
    businessProfileError,
    businessSocialMedia,
    businessSocialMediaError,
    isFetchingBusinessProfile,
    isFetchingBusinessSocialMedia,

    businessHours,
    businessHoursError,
    isFetchingBusinessHours,
  };
};

export default connect(mapStateToProps, {
  getBusinessHours: getBusinessHoursAction,
  getBusinessProfile: getBusinessProfileAction,
  getBusinessSocialMedia: getBusinessSocialMediaAction,
})(BusinessEdit);
