import 'rc-slider/assets/index.css';

import { isEmpty, isEqual } from 'lodash';
import { arrayOf, bool, instanceOf, number, string } from 'prop-types';
import React from 'react';

import RangeSlider from '../../RangeSlider';

class FiltersModalRangeSlider extends React.Component {
  state = { value: [] };

  componentDidMount() {
    const { value, defaultValue } = this.props;
    if (isEmpty(value)) {
      this.setState({ value: defaultValue });
    } else {
      this.setState({ value });
    }
  }

  componentDidUpdate(prevProps) {
    const { value, defaultValue } = this.props;
    if (!isEqual(prevProps.value, value)) {
      if (isEmpty(value)) {
        this.updateState(defaultValue);
      } else {
        this.updateState(value);
      }
    }
  }

  updateState(defaultValue) {
    this.setState({ value: defaultValue });
  }

  render() {
    const { value } = this.state;
    const { disabled, onChange, label, min, max, step } = this.props;
    if (isEmpty(value)) {
      return null;
    }

    return (
      <fieldset>
        <legend>{label}</legend>
        <RangeSlider
          allowCross={false}
          disabled={disabled}
          max={max}
          min={min}
          onChange={(newValue) => onChange(newValue)}
          pushable
          step={step}
          tooltip
          value={value}
        />
      </fieldset>
    );
  }
}

FiltersModalRangeSlider.propTypes = {
  defaultValue: arrayOf(number),
  disabled: bool,
  label: string.isRequired,
  max: number.isRequired,
  min: number.isRequired,
  onChange: instanceOf(Function).isRequired,
  step: number,
  value: arrayOf(number),
};

FiltersModalRangeSlider.defaultProps = {
  defaultValue: [],
  disabled: false,
  step: 1,
  value: [],
};

export default FiltersModalRangeSlider;
