import './index.sass';

import { isEmpty } from 'lodash';
import { bool, func, string } from 'prop-types';
import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import uuidv1 from 'uuid/v1';

const AutocompleteGooglePlaces = (props) => {
  const {
    disabled,
    inputClassName,
    placeholder,
    onSelect,
    required,
    id,
    noBrowserComplete,
    ...rest
  } = props;

  return (
    <PlacesAutocomplete
      onSelect={(selectedAddress, placeID, suggestion) =>
        onSelect(selectedAddress, placeID, suggestion)
      }
      {...rest}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="AutocompleteGooglePlaces">
          <input
            {...getInputProps({
              placeholder,
              className: `AutocompleteGooglePlaces-input ${inputClassName}`,
              disabled,
              required,
              id,
            })}
            autoComplete={noBrowserComplete ? 'none' : 'on'}
          />

          {!isEmpty(suggestions) && (
            <ul className="AutocompleteGooglePlaces__DropDown">
              {loading ? (
                <li key={uuidv1()} className="AutocompleteGooglePlaces__DropDown__Item">
                  loading...
                </li>
              ) : (
                <>
                  {suggestions.map((suggestion) => (
                    <li
                      key={suggestion.placeId || uuidv1()}
                      className="AutocompleteGooglePlaces__DropDown__Item"
                      {...getSuggestionItemProps(suggestion)}
                    >
                      {suggestion.description}
                    </li>
                  ))}
                </>
              )}
            </ul>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

AutocompleteGooglePlaces.propTypes = {
  disabled: bool,
  inputClassName: string,
  onSelect: func.isRequired,
  placeholder: string,
  required: bool,
  value: string,
  noBrowserComplete: bool,
};

AutocompleteGooglePlaces.defaultProps = {
  disabled: false,
  inputClassName: '',
  placeholder: 'Search places...',
  value: '',
  required: false,
  noBrowserComplete: false,
};

export default AutocompleteGooglePlaces;
