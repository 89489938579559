import { GET_US_STATE_LIST_ERROR, GET_US_STATE_LIST_SUCCESS } from '../actions/usStates';

const initialState = {
  usStates: [],
  usStatesError: null,
  isFetchingUsStates: true,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_US_STATE_LIST_SUCCESS: {
      return {
        ...state,
        isFetchingUsStates: false,
        usStates: payload.data || [],
      };
    }

    case GET_US_STATE_LIST_ERROR: {
      return {
        ...state,
        isFetchingUsStates: false,
        usStatesError: payload.error || null,
      };
    }

    default:
      return state;
  }
}
