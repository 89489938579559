import { bool, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import uuidv1 from 'uuid/v1';

import { createUser, getUserRoleList, getUsers } from '../../../actions/users';
import AlertMessages from '../../../components/AlertMessages';
import AsyncContent from '../../../components/AsyncContent';
import Filters from '../../../components/Filters';
import { Col, Row } from '../../../components/Grid';
import LightBox from '../../../components/LightBox';
import MasterPage from '../../../components/MasterPage';
import Paginator from '../../../components/Paginator';
import { DEFAULT_PAGE_INDEX } from '../../../config/pagination';
import HISTORY_TYPE from '../../../types/history';
import LOCATION_TYPE from '../../../types/location';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';
import UserCreateOrEdit from '../CreateOrEdit';
import UserCard from './UserCard';

/* global localStorage document window */
export default function Users(props) {
  const {
    auth,
    history,
    location: { search },
    match: {
      params: { pageNumber, itemsCountPerPage },
    },
  } = props;

  const [userRoleList, setUserRoleList] = useState([]);
  const [isFetchingUsers, setIsFetchingUsers] = useState(true);
  const [users, setUsers] = useState({});
  const [usersError, setUsersError] = useState('');
  const [isCreateUserOpen, setIsCreateUserOpen] = useState(false);
  const [paginationCache, setPaginationCache] = useState([]);
  const [searchCache, setSearchCache] = useState(search);
  const [getAllUsersSubscription, setGetAllUsersSubscription] = useState(null);
  const [successDeletingUser, setSuccessDeletingUser] = useState(
    !!localStorage.getItem('successDeletingUser'),
  );

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const searchTerms = useMemo(() => query.get('search'), [query]);
  const userRoles = useMemo(() => query.get('userRoles'), [query]);
  const orderBy = useMemo(() => query.get('orderBy'), [query]);
  const userList = useMemo(() => (users && users.UserList) || {}, [users]);
  const successMessage = useMemo(
    () => (successDeletingUser ? 'user was successfully deleted' : ''),
    [successDeletingUser],
  );

  const userRoleOptions = useMemo(
    () => userRoleList.map(({ Group, Name }) => ({ label: Name, value: Group })),
    [userRoleList],
  );

  const masterPageConfig = useMemo(
    () => ({
      auth,
      headerBreadcrumb: [{ name: 'Home', url: '/' }, { name: 'User Management' }],
      className: successMessage ? 'with-alert' : '',
    }),
    [auth, successMessage],
  );

  const getAllUsers = useCallback(() => {
    if (!Number(orderBy)) {
      return;
    }

    if (getAllUsersSubscription) {
      getAllUsersSubscription.unsubscribe();
      setGetAllUsersSubscription(null);
    }

    setIsFetchingUsers(true);
    const subscription = getUsers({
      RowsPerPage: Number(itemsCountPerPage),
      PageNum: Number(pageNumber),
      SortBy: Number(orderBy),
      ...(searchTerms ? { SearchText: searchTerms } : {}),
      ...(userRoles ? { GroupId: userRoles.split(',') } : {}),
    }).subscribe(
      setUsers,
      (err) => {
        setUsersError(err);
        setIsFetchingUsers(false);
      },
      () => setIsFetchingUsers(false),
    );

    setGetAllUsersSubscription(subscription);
  }, [
    search,
    orderBy,
    getAllUsersSubscription,
    setGetAllUsersSubscription,
    setIsFetchingUsers,
    setUsers,
    setUsersError,
    setIsFetchingUsers,
    itemsCountPerPage,
    pageNumber,
  ]);

  const onDelete = useCallback(() => {
    setSuccessDeletingUser(true);
    setTimeout(() => setSuccessDeletingUser(false), 10000);
    getAllUsers();
  }, [setSuccessDeletingUser]);


  const popStateListener = () => {
    const el = document.querySelectorAll('.pagination > .active a');
    if (el.length > 0) {
      const txt = Number(el[0].textContent);
      if (txt === 1) window.history.go(-2);
    }
  };

  const updatePagination = useCallback(
    (pageSize, pageIndex) => {
      history.push({ pathname: `/admin/users/${pageSize}/${pageIndex}`, search });
    },
    [history, search],
  );

  useEffect(() => {
    const getUserRoleListScp = getUserRoleList().subscribe(setUserRoleList);
    localStorage.removeItem('successDeletingUser');
    return () => {
      getUserRoleListScp.unsubscribe();
      if (getAllUsersSubscription) {
        getAllUsersSubscription.unsubscribe();
        setGetAllUsersSubscription(null);
      }
      setSearchCache(null);
    };
  }, []);

  useEffect(() => {
    if (search !== searchCache) {
      setSearchCache(search);
      if (Number(pageNumber) !== DEFAULT_PAGE_INDEX) {
        history.push({
          pathname: `/admin/users/${itemsCountPerPage}/${DEFAULT_PAGE_INDEX}`,
          search,
        });
      } else {
        getAllUsers();
        history.push({ pathname: `/admin/users/${itemsCountPerPage}/${pageNumber}`, search });
      }
    } else if (pageNumber !== paginationCache[0] || itemsCountPerPage !== paginationCache[1]) {
      setPaginationCache([pageNumber, itemsCountPerPage]);
      getAllUsers();
    }
  }, [pageNumber, itemsCountPerPage, search]);

  useEffect(() => {
    if (successDeletingUser) {
      localStorage.removeItem('successDeletingUser');
      setTimeout(() => setSuccessDeletingUser(false), 10000);
    }
    window.addEventListener('popstate', popStateListener);

    return () => {
      window.removeEventListener('popstate', popStateListener);
    };
  }, []);


  return (
    <div className="Users">
      <Helmet title="Franki Admin: User Management" />

      <AlertMessages
        className="Profile__AlertMessage"
        show={successMessage}
        type="success"
        message={successMessage}
      />

      <MasterPage {...masterPageConfig}>
        <>
          <Filters
            activeFilterNames={['orderBy', 'search', 'userRoles']}
            content={(
              <button
                className="button small yellow-theme filterBtn"
                onClick={() => setIsCreateUserOpen(true)}
                type="button"
              >
                Create User
              </button>
            )}
            modalHeight={350}
            sorterItems={[
              { label: 'recently active', value: '1' },
              { label: 'name (a-z)', value: '2' },
              { label: 'date created', value: '3' },
            ]}
            userRoleOptions={userRoleOptions}
          />
          <AsyncContent
            content={list => (
              <Row cellSpacing={7} rowSpacing={7}>
                {list.map((item) => {
                  const userRole = userRoleList.find(({ Group }) => Group === item.GroupId);
                  return (
                    <Col lg={3} md={4} sm={6} xs={12} key={uuidv1()} className="user-column">
                      <UserCard
                        item={item}
                        userRole={userRole ? userRole.Name : ''}
                        onDelete={onDelete}
                      />
                    </Col>
                  );
                })}
              </Row>
            )}
            errorMessage={usersError}
            expectedData={userList.Results}
            loading={isFetchingUsers}
          />

          {userList.TotalItems > 0 && (
            <Paginator
              onHandlePage={updatePagination}
              onHandleShow={updatePagination}
              pageIndex={Number(userList.CurrentPage)}
              totalItems={userList.TotalItems}
              itemsCountPerPage={Number(itemsCountPerPage)}
            />
          )}
        </>
      </MasterPage>

      {isCreateUserOpen && (
        <LightBox
          width={1312}
          height={623}
          show
          spacing={24}
          className="CreateOrEditUserLightBox"
          onClose={() => setIsCreateUserOpen(false)}
        >
          <UserCreateOrEdit
            showDeleteButton={false}
            auth={auth}
            callBackOnSubmit={createUser}
            pageSubtitle="We will send them an invite, from there they will setup their credentials"
            pageTitle="Create User"
            submitButtonCaption="Create User"
          />
          <div className="CreateOrEditUserLightBox__Actions">
            <button
              type="button"
              className="button outline-theme purple-text"
              onClick={() => setIsCreateUserOpen(false)}
            >
              CANCEL
            </button>
          </div>
        </LightBox>
      )}
    </div>
  );
}

Users.propTypes = {
  auth: bool.isRequired,
  history: HISTORY_TYPE.isRequired,
  location: LOCATION_TYPE.isRequired,
  match: ROUTER_MATCH_TYPE({ pageNumber: string, itemsCountPerPage: string }).isRequired,
};
