import './index.sass';

import { bool, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';

import { BUSINESS_DETAILS_TYPE } from '../../types/business';
import MasterPage from '../MasterPage';

const ManagePlan = (props) => {
  let masterPageConfigs = {};
  const { businessDetails, businessDetailsError, auth } = props;
  masterPageConfigs = {
    headerBreadcrumb: [{ name: 'my subscription' }, { name: 'manage plan', bold: true }],
    headerButtons: (
      <Link to="/" className="button small red-theme">
        Cancel subscription
      </Link>
    ),
  };
  return (
    <>
      <Helmet title={`Franki Admin: ${businessDetails.DisplayName || 'Manage Plan'}`} />

      <MasterPage
        auth={auth}
        businessSidebarOn
        businessSidebarData={businessDetails}
        businessSidebarDataError={businessDetailsError}
        businessMobileTitle="Manage Plan"
        {...masterPageConfigs}
      >
        <div className="manage-plan">
          <h2 className="manage-plan-title">Manage Plan</h2>
          <p className="manage-plan-subtitle">
            Franki currently services selected areas in the United State. By clicking 'Confirm' you
            are agreeing to franki&rsquo;s&nbsp;
            <a href="https://befranki.com/terms-of-service/" target="_blank">
              Terms of Service
            </a>
            .
          </p>
          <table className="table-striped">
            <tr>
              <th />
              <th>
                <span />
                <h4>basic</h4>
              </th>
              <th className="bg-purple">
                <span>Current Plan</span>
                <h4>standard</h4>
              </th>
            </tr>
            <tr>
              <td>
                profile management
                <Link className="text-purple" to="/">
                  EDIT PROFILE
                </Link>
              </td>
              <td className="text-center">
                <i className="icon-success" />
              </td>
              <td className="bg-purple text-center">
                <i className="icon-success" />
              </td>
            </tr>
            <tr>
              <td>
                ability to run gigs
                <Link className="text-purple" to="/">
                  TALK TO A SALES REP FOR IDEAS
                </Link>
              </td>
              <td className="text-center">
                <i className="icon-success" />
              </td>
              <td className="bg-purple text-center">
                <i className="icon-success" />
              </td>
            </tr>
            <tr>
              <td>max gig winners per month</td>
              <td className="text-center">
                <span>25 winners max</span>
              </td>
              <td className="bg-purple text-center">
                <span>unlimited</span>
              </td>
            </tr>
            <tr>
              <td>service fee on gig spend</td>
              <td className="text-center">
                <span>10%</span>
              </td>
              <td className="bg-purple text-center">
                <span>waived</span>
              </td>
            </tr>
            <tr>
              <td>gig credits</td>
              <td className="text-center">
                <span>$50 per month</span>
              </td>
              <td className="bg-purple text-center">
                <span>$75 per month</span>
              </td>
            </tr>
            <tr>
              <td />
              <td className="text-center">
                <Link to="/" className="button small orange-theme">
                  downgrade to basic
                </Link>
              </td>
              <td />
            </tr>
          </table>
        </div>
      </MasterPage>
    </>
  );
};

ManagePlan.propTypes = {
  loading: bool.isRequired,
  auth: bool.isRequired,
  businessDetails: BUSINESS_DETAILS_TYPE.isRequired,
  businessDetailsError: bool.isRequired,
  userRole: string.isRequired,
};

export default withRouter(ManagePlan);
