export const ANALYTICS_TRACKER_ROUTES = [
  '/claim-my-business',
  '/sign-up/',
  '/email-code-verification/',
  '/mobile-code-verification/',
  '/business-phone-verification/',
  '/admin/business-profile/',
];

export default ANALYTICS_TRACKER_ROUTES;
