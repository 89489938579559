import React from 'react';

const GIG_PACKAGES = [
  {
    type: 'Food and Drink',
    description: 'Highlight anything on your menu.',
    title: 'Eat, drink and be frank',
    gigDescription:
      "It's easy - come in, eat to your heart's content, and let the world see! Tell us what you think.",
    info: <>$5 first 10 reviews</>,
    value: 50,
    rules: [
      'Get a shot of the goods (6+ seconds, well-framed)',
      "Tell the community if you'd recommend it",
      "Be helpful - it's about helping people decide",
    ],
    prizes: [
      { id: 1, name: 'First 10 submissions that meets the rules, wins', amount: 5, winners: 10 },
    ],
  },
  {
    type: 'Atmosphere and Vibe',
    description: 'Showcase the look, feel, and atmosphere of your venue.',
    title: 'Show off the ambience',
    gigDescription:
      "Come in, capture the atmosphere, show the franki community our venue. And if you're looking to win the jackpot - get creative!",
    info: (
      <>
        $5 first 10 reviews
        <br />
        $20 most creative
      </>
    ),
    value: 70,
    rules: [
      'Show off our spot! (10+ seconds, 2+ clips)',
      "Tell the community if you'd recommend it",
      "Be helpful - it's about helping people decide",
    ],
    prizes: [
      { id: 1, name: 'First 10 submissions that meets the rules, wins', amount: 5, winners: 10 },
      { id: 2, name: 'Most creative as chosen by our team, wins', amount: 20, winners: 1 },
    ],
  },
  {
    type: 'Specific Menu Item',
    description: 'Promote a new or fan-favorite menu item.',
    title: 'Come in and grab our [product name]',
    gigDescription:
      'Come on in, try our [product name], let the camera eat a bit, tell the franki community what you think. ' +
      "And if you're looking to win the jackpot - get creative!",
    info: (
      <>
        $10 first 10 reviews
        <br />
        $20 most creative
      </>
    ),
    value: 120,
    rules: [
      'Get a clean shot of the [product] (6+ seconds, well-framed)',
      "Tell the community if you'd recommend it",
      "Be helpful - it's about helping people decide",
    ],
    prizes: [
      { id: 1, name: 'First 10 submissions that meets the rules, wins', amount: 10, winners: 10 },
      { id: 2, name: 'Most creative as chosen by our team, wins ', amount: 20, winners: 1 },
    ],
  },
  {
    type: 'Special offer',
    description: "Feature a special offer or promotion you're running.",
    title: '[explain deal i.e. 2 for 1 tacos]',
    gigDescription:
      "We've got [product name] on special. " +
      "Try it out, let the franki community see the goods, and let em know what you think. And if you're looking to win the jackpot - get creative.",
    info: (
      <>
        $10 first 10 reviews
        <br />
        $20 most creative
      </>
    ),
    value: 120,
    rules: [
      'Show off the special (well-framed, 6+ seconds)',
      "Tell the community if you'd recommend it ",
      "Be helpful - it's about helping people decide",
    ],
    prizes: [
      { id: 1, name: 'First 10 submissions that meets the rules, wins', amount: 10, winners: 10 },
      { id: 2, name: 'Most creative as chosen by our team, wins', amount: 20, winners: 1 },
    ],
  },
  {
    type: 'Social Content',
    description: 'Get shareable marketing content made by your customers.',
    title: 'Be creative & show what you love',
    gigDescription:
      "It's all about being creative. Show the place, the food, and the drink. " +
      'Let us know your thoughts, show off your personality and remember - be helpful!',
    info: (
      <>
        $15 first 10 reviews
        <br />
        $50 most creative
      </>
    ),
    value: 200,
    rules: [
      'Have fun and get creative (2+ clips, 10+ sec)',
      'Show off the [product, venue, etc]',
      "Be helpful - it's about helping people decide",
    ],
    prizes: [
      { id: 1, name: 'First 10 submissions that meets the rules, wins', amount: 15, winners: 10 },
      { id: 2, name: 'Most creative as chosen by our team, wins', amount: 50, winners: 1 },
    ],
  },
  {
    type: 'Create your own GiG',
    description: 'Build your own gig, any way you see fit.',
    gigDescription: '',
    title: '',
    rules: [],
    prizes: [],
  },
];

export default GIG_PACKAGES;
