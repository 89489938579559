import './index.sass';

import { number, string } from 'prop-types';
import React from 'react';

class RemainCharacters extends React.PureComponent {
  render() {
    const { length, label, maxLength } = this.props;

    return (
      <small className={`RemainingChars ${length > maxLength && 'RemainingChars--Error'}`}>
        {length > maxLength ? (
          <>
            {label} exceeds the maximum length of {maxLength}
          </>
        ) : (
          <>
            {Math.max(maxLength - length, 0)}{' '}
            {maxLength - length > 1 ? 'remaining characters' : 'remaining character'}
          </>
        )}
      </small>
    );
  }
}

RemainCharacters.propTypes = {
  label: string.isRequired,
  length: number.isRequired,
  maxLength: number.isRequired,
};

export default RemainCharacters;
