import React from 'react';
import PropTypes from 'prop-types';
import VideoJS from './VideoJs';

const VideoPlayer = ({ url, ratio }) => {
  if (!url) return null;

  const playerRef = React.useRef(null);
  const getVideoSource = () => {
    if (url.endsWith('m3u8')) {
      return {
        src: url,
        type: 'application/x-mpegURL',
      };
    }
    if (url.endsWith('ism/manifest')) {
      return {
        src: `${url}(format=m3u8-aapl)`,
        type: 'application/x-mpegURL',
      };
    }
    return {
      src: url,
      type: 'video/mp4',
    };
  };

  const videoJsOptions = {
    aspectRatio: ratio || '4:3',
    autoplay: false,
    controls: true,
    responsive: true,
    sources: [getVideoSource()],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
  };

  return <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />;
};

export default VideoPlayer;

VideoPlayer.propTypes = {
  url: PropTypes.string,
  ratio: PropTypes.string,
};

VideoPlayer.defaultProps = {
  url: '',
  ratio: '4:3',
};
