import './index.sass';

import { func, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import AsyncContent from '../../../../../components/AsyncContent';
import Loader from '../../../../../components/Loader';
import uploadIcon from '../../../../../assets/images/upload.png';
import LightBox from '../../../../../components/LightBox';
import shopPurplePng from '../../../../../assets/images/shop-purple.png';

const ACCEPTED_LOGO_FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
const LOGO_MINIMUM_WIDTH_IN_PX = 250;
const LOGO_MINIMUM_HEIGHT_IN_PX = 250;
const MAXIMUM_FILE_SIZE_IN_MB = 5;
const MAXIMUM_FILE_SIZE = MAXIMUM_FILE_SIZE_IN_MB * 1024 * 1024;

/* global FileReader */
const BusinessLogo = (props) => {
  const {
    logoUrl,
    onHandleChange,
    onLogoUrlChange,
  } = props;

  const [businessLogoLoading, setBusinessLogoLoading] = useState(false);
  const [logoError, setLogoError] = useState(null);
  const [logo, setLogo] = useState(logoUrl);
  const [defaultLogo, setDefaultLogo] = useState(logoUrl);
  const logoImg = useRef(null);
  const inputFileLogo = useRef(null);

  const markLogoInputErrorAndReset = (errorMessage) => {
    setLogo(defaultLogo || logo);
    setLogoError(errorMessage);
    onHandleChange('businessLogo', '');
    if (inputFileLogo) {
      inputFileLogo.current.value = '';
    }
  };

  const logoFileTypeAndFileSizeValid = (file) => {
    if (ACCEPTED_LOGO_FILE_TYPES.indexOf(file.type) === -1) {
      markLogoInputErrorAndReset('Please select a .jpeg or .png file.');
      return false;
    }

    if (file.size > MAXIMUM_FILE_SIZE) {
      markLogoInputErrorAndReset(`Please select a file smaller than ${MAXIMUM_FILE_SIZE_IN_MB}MB.`);
      return false;
    }
    return true;
  };

  const onHandleLogo = async (name, file) => {
    if (!file) {
      return;
    }

    if (logoFileTypeAndFileSizeValid(file)) {
      setBusinessLogoLoading(true);
      await onHandleChange(name, file);

      const reader = new FileReader();

      reader.onloadend = () => {
        setLogo(reader.result);
        setLogoError('');
        setBusinessLogoLoading(false);
      };
      reader.readAsDataURL(file);
    }
  };


  useEffect(() => {
    onLogoUrlChange(logo);
  }, [logo]);

  useEffect(() => {
    setTimeout(() => {
      if (logo && logo.indexOf('http') === -1) {
        setTimeout(() => {
          if (
            logoImg
            && (logoImg.offsetHeight < LOGO_MINIMUM_HEIGHT_IN_PX
              || logoImg.offsetWidth < LOGO_MINIMUM_WIDTH_IN_PX)
          ) {
            markLogoInputErrorAndReset(
              `Please select a file larger than ${LOGO_MINIMUM_WIDTH_IN_PX}x${LOGO_MINIMUM_HEIGHT_IN_PX} px.`,
            );
          }
        }, 200);
      } else {
        setDefaultLogo(logo);
      }
    }, 10);
  }, [logo, logoImg]);


  return (
    <AsyncContent
      content={() => (
        <>
          <div className="BusinessLogo">
            {/* is this for real? */}
            <img
              className="BusinessLogo__ImageSizeTest"
              ref={logoImg}
              src={logoUrl}
              alt="Business logo"
            />

            <figure
              className={`BusinessLogo__Figure ${
                businessLogoLoading ? 'BusinessLogo__Figure--Loading' : ''
              }`}
            >
              {businessLogoLoading ? (
                <Loader />
              ) : (
                <img src={logoUrl || shopPurplePng} alt="Business logo" />
              )}
            </figure>

            <div className="BusinessLogo__FileInput">
              <label htmlFor="Logo">
                <img src={uploadIcon} alt="upload logo" />
              </label>
              <input
                accept=".png, .jpg, .jpeg"
                id="Logo"
                name="Logo"
                onChange={({ target }) => onHandleLogo(target.name, target.files[0])}
                ref={inputFileLogo}
                type="file"
              />
            </div>

            <span className="BusinessLogo__Limit">
              .jpeg or .png,
              {' '}
              {`${LOGO_MINIMUM_WIDTH_IN_PX}x${LOGO_MINIMUM_HEIGHT_IN_PX}px minimum`}
              <br />
              maximum file size 5MB
            </span>
          </div>
          <LightBox
            style={
                {
                  width: 400,
                  height: 150,
                  marginLeft: -200,
                  marginTop: -75,
                }
              }
            onClose={() => setLogoError('')}
            show={logoError}
          >
            <div className="DialogTitle">There is an error</div>
            <div className="DialogContent">
              {logoError}
            </div>
          </LightBox>
        </>
      )}
      expectNoData
    />
  );
};

BusinessLogo.propTypes = {
  logoUrl: string.isRequired,
  onHandleChange: func.isRequired,
  onLogoUrlChange: func.isRequired,
};

export default BusinessLogo;
