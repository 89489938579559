import { instanceOf } from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, routerProps, ...rest }) => (
  <Route
    {...rest}
    render={(props) => <Component auth={false} routerProps={routerProps} {...props} />}
  />
);

PublicRoute.propTypes = {
  component: instanceOf(Object).isRequired,
  routerProps: instanceOf(Object),
};

PublicRoute.defaultProps = {
  routerProps: {},
};

export default PublicRoute;
