/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './index.sass';

import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import { bool } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import { createBusinessProfileV2, getBusinessOptions } from '../../../actions/businesses';
import infoIcon from '../../../assets/images/info.svg';
import AsyncContent from '../../../components/AsyncContent';
import MasterPage from '../../../components/MasterPage';
import HISTORY_TYPE from '../../../types/history';
import { validURL } from '../../../utils/placeId';
import BusinessBasicForm from '../BusinessProfile/BusinessBasicForm';
import BusinessContactInformationForm from '../BusinessProfile/BusinessContactInformationForm';
import BusinessHours, { hoursModaltoDb } from '../BusinessProfile/BusinessHours';
import BusinessAddressForm from '../BusinessProfile/BusinessAddressForm';
import BusinessSearchForm from '../BusinessProfile/BusinessSearchForm';
import BusinessSocialInformationForm from '../BusinessProfile/BusinessSocialInformationForm';
import shopPurplePng from '../../../assets/images/shop-purple.png';
import { getBlob } from '../../../utils/api';
import BusinessHeader from '../BusinessProfile/BusinessHeader';
import LightBox from '../../../components/LightBox';
import BusinessOptions from '../BusinessProfile/BusinessOptions';
import BusinessAmenities from '../BusinessProfile/BusinessAmenities';
import BusinessHealth from '../BusinessProfile/BusinessHealth';
import Loader from '../../../components/Loader';
import BusinessMenuForm from '../BusinessProfile/BusinessMenuForm';
import USER_ROLES from '../../../config/user-roles';

/* global localStorage */

class BusinessAdd extends React.Component {
  state = {
    errorMessage: '',
    form: {
      Address: '',
      BusinessId: null,
      BusinessSourceId: 1,
      Type: null,
      BusinessHours: null,
      Name: '',
      Category: null,
      FollowerCount: null,
      ThirdPartyBusinessSourceId: '',
      GigCount: null,
      GooglePlaceId: '',
      IsUserFollowingTheBusiness: bool,
      Latitude: null,
      Longitude: null,
      RatingCount: null,
      VideoCount: null,
      Website: '',
      PhoneNumber: null,
      MenuUrl: null,

      City: '',
      Country: '',
      Facebook: '',
      Instagram: '',
      IsPublic: false,
      Zipcode: '',
      State: '',
      Description: '',
      Twitter: '',
      Tiktok: '',
      OtherSocialMediaLink: '',

      BusinessOption: {},
    },
    nonFourSquare: false,
    clearValue: false,
    logoUrl: '',
    bannerUrl: '',
    isPhoneNumberInvalid: false,
    isFormError: false,
    loading: false,

    businessOptionParamaters: {},
    isDialog: false,
    isHowWeUse: false,
  };

  componentDidMount() {
    // navigator.geolocation.getCurrentPosition(
    //   (position) => {
    //     const { latitude, longitude } = position.coords;
    //     localStorage.setItem('latitude', latitude);
    //     localStorage.setItem('longitude', longitude);
    //   },
    //   () => {
    //     localStorage.removeItem('latitude');
    //     localStorage.removeItem('longitude');
    //   },
    // );

    getBusinessOptions().subscribe(({ BusinessOptionTypes }) => {
      this.setState({ businessOptionParamaters: BusinessOptionTypes });
    });
  }

  onHandleChange(name, value) {
    this.setState(({ form }) => ({
      form: { ...form, [name]: value },
    }));
  }

  onHandleBusinessOptionChange(name, value) {
    this.setState(({ form }) => ({
      form: { ...form, BusinessOption: { ...form.BusinessOption, [name]: value } },
    }));
  }

  onNonFourSquareChange() {
    this.setState(({ nonFourSquare }) => {
      if (!nonFourSquare) {
        this.onHandleChange('ThirdPartyBusinessSourceId', '');
        this.setState(({ clearValue }) => ({
          clearValue: !clearValue,
        }));
      }
      return {
        nonFourSquare: !nonFourSquare,
      };
    });
  }

  onFormErrorStateUpdate(errorState) {
    const { isFormError } = this.state;
    if (isFormError !== errorState) {
      this.setState({ isFormError: errorState });
    }
  }

  async onHandleSubmit(event) {
    event.preventDefault();
    const { isFormError, form, nonFourSquare } = this.state;
    const { history } = this.props;

    this.setState({
      isPhoneNumberInvalid: false,
    });
    const phoneNumber = parsePhoneNumberFromString(`+${String(form.PhoneNumber).replace('+', '')}`);
    const isPhoneNumberInvalid = !phoneNumber || !phoneNumber.isValid();
    if (isPhoneNumberInvalid) {
      this.setState({ isPhoneNumberInvalid: true });
      return;
    }
    if (!this.checkSocialMediaUrls(form.Facebook)) {
      this.setState({ errorMessage: 'Facebook address is invalid' });
      return;
    }
    if (!this.checkSocialMediaUrls(form.Instagram)) {
      this.setState({ errorMessage: 'Instagram address is invalid' });
      return;
    }
    if (!this.checkSocialMediaUrls(form.Twitter)) {
      this.setState({ errorMessage: 'Twitter address is invalid' });
      return;
    }
    if (!this.checkSocialMediaUrls(form.Tiktok)) {
      this.setState({ errorMessage: 'Tiktok address is invalid' });
      return;
    }

    if (!isFormError) {
      const newBusinessHours =
        form.BusinessHours && form.BusinessHours.length ? hoursModaltoDb(form.BusinessHours) : null;

      const newBusinessOptions = [];
      Object.entries(form.BusinessOption).forEach(([Name, Value]) => {
        if (Name === 'Parking') {
          if (Value) {
            newBusinessOptions.push({ Name, Value: Value.map((item) => item.Id) });
          }
        } else if (Name === 'HealthScore') {
          if (Value) {
            newBusinessOptions.push({ Name, Value: Value.Id || Value });
          }
        } else if (Value) {
          newBusinessOptions.push({ Name, Value: 1 });
        }
      });

      let imgValue = form.Logo;
      if (!imgValue) {
        imgValue = await getBlob(shopPurplePng);
        if (!imgValue.name) {
          const [imgName] = shopPurplePng.split('/').slice(-1);
          imgValue.name = imgName;
        }
      }

      const requestParams = {
        Address: form.Address,
        BusinessSourceId: form.BusinessSourceId,
        Type: form.Type && form.Type.Id,
        City: form.City,
        Country: form.Country,
        Name: form.Name,
        Latitude: form.Latitude,
        Longitude: form.Longitude,
        PhoneNumber: form.PhoneNumber,
        Zipcode: form.Zipcode,
        State: form.State,
        Description: form.Description,
        Website: form.Website,
        Neighborhood: form.Neighborhood,
        Logo: imgValue,
        ...(!nonFourSquare && form.ThirdPartyBusinessSourceId
          ? { ThirdPartyBusinessSourceId: form.ThirdPartyBusinessSourceId }
          : {}),
        ...(newBusinessHours ? { BusinessHours: JSON.stringify(newBusinessHours) } : {}),
        ...(form.Banner ? { Banner: form.Banner } : {}),
        ...(form.MenuUrl ? { MenuUrl: form.MenuUrl } : {}),
        ...(form.Facebook ? { FacebookUrl: form.Facebook } : {}),
        ...(form.Instagram ? { InstagramUrl: form.Instagram } : {}),
        ...(form.Twitter ? { TwitterUrl: form.Twitter } : {}),
        ...(form.Tiktok ? { TikTokUrl: form.Tiktok } : {}),
        ...(form.OtherSocialMediaLink ? { OtherSocialMediaUrl: form.OtherSocialMediaLink } : {}),
        ...(newBusinessOptions.length > 0
          ? { BusinessOptions: JSON.stringify(newBusinessOptions) }
          : {}),
        ...(form.Category ? { CategoryId: form.Category.Id } : {}),
      };
      this.setState({ loading: true });
      // /* eslint-disable no-undef */
      // window.requestParams = requestParams;
      // window.logo = form.Logo;
      // window.defaultLogo = await getBlob(shopPurplePng);
      // window.form = form;
      // window.state = this.state;
      const result = await createBusinessProfileV2(requestParams);
      this.setState({ loading: false });
      if (result.Success) {
        history.push('/admin/businesses');
      } else {
        this.setState({ errorMessage: result.Message });
      }
    }
  }

  checkSocialMediaUrls = (value) => {
    if (!value) return true;
    return validURL(value);
  };

  render() {
    const { auth, history } = this.props;
    const {
      errorMessage,
      form,
      isPhoneNumberInvalid,
      logoUrl,
      bannerUrl,
      businessOptionParamaters,
      nonFourSquare,
      clearValue,
      isDialog,
      loading,
      isHowWeUse,
    } = this.state;

    const userRole = localStorage.getItem('frankiRole');

    const { ThirdPartyBusinessSourceId } = form;

    const masterPageConfig = {
      auth,
      headerComponent: form ? (
        <BusinessHeader
          logoUrl={logoUrl}
          bannerUrl={bannerUrl}
          onHandleChange={(...params) => this.onHandleChange(...params)}
          onLogoUrlChange={(newLogoUrl) => this.setState({ logoUrl: newLogoUrl })}
          onBannerUrlChange={(newBannerUrl) => this.setState({ bannerUrl: newBannerUrl })}
          businessProfile={form}
          nonFourSquare={nonFourSquare}
        />
      ) : (
        <></>
      ),
    };
    return (
      <>
        <Helmet title="Franki Admin: business profile" />
        <MasterPage {...masterPageConfig}>
          <AsyncContent
            content={() => (
              <form
                className="BusinessAddContainer BusinessEditContainer"
                onSubmit={(e) => this.onHandleSubmit(e)}
              >
                <div className="BusinessEdit">
                  {[USER_ROLES.admin, USER_ROLES.saleAdmin].includes(userRole) && (
                    <div className="BusinessEdit__Blocks">
                      <h2 className="BusinessEdit__Blocks__Title">Search</h2>
                      <h3 className="BusinessEdit__Blocks__Subtitle">
                        Search for Foursquare businesses
                      </h3>

                      <div className="BusinessEdit__Blocks__Content">
                        <BusinessSearchForm
                          formState={form}
                          onHandleFormChange={(...params) => this.onHandleChange(...params)}
                          checkboxHandler={() => {
                            this.onNonFourSquareChange();
                          }}
                          nonFourSquare={nonFourSquare}
                          clearValue={clearValue}
                        />
                      </div>
                    </div>
                  )}

                  <div className="BusinessEdit__Blocks">
                    <h2 className="BusinessEdit__Blocks__Title">
                      Who are you?
                      <img
                        className="ml-10 infoElement"
                        src={infoIcon}
                        alt=""
                        height={20}
                        width={20}
                        onClick={() => {
                          this.setState({ isHowWeUse: true });
                        }}
                      />
                    </h2>
                    <h3 className="BusinessEdit__Blocks__Subtitle">
                      How would you describe your business? This information helps customers find
                      you on franki
                    </h3>

                    <div className="BusinessEdit__Blocks__Content BusinessEdit__Blocks__Content__Logo">
                      <BusinessBasicForm
                        formState={form}
                        disabled={!(nonFourSquare || ThirdPartyBusinessSourceId)}
                        onFormErrorStateUpdate={(errorState) =>
                          this.onFormErrorStateUpdate(errorState)
                        }
                        onHandleChange={(...params) => this.onHandleChange(...params)}
                      />
                    </div>
                  </div>

                  <div className="BusinessEdit__Blocks">
                    <h2 className="BusinessEdit__Blocks__Title">Where are you?</h2>
                    <h3 className="BusinessEdit__Blocks__Subtitle">
                      Our app uses {'Foursquare Place™ data'} so our users can find your location
                    </h3>

                    <div className="BusinessEdit__Blocks__Content">
                      <BusinessAddressForm
                        addressDisabled={!(nonFourSquare || ThirdPartyBusinessSourceId)}
                        formState={form}
                        onHandleFormChange={(...params) => this.onHandleChange(...params)}
                      />
                    </div>
                  </div>

                  <div className="BusinessEdit__Blocks">
                    <h2 className="BusinessEdit__Blocks__Title">
                      How can people connect with you?
                    </h2>
                    <h3 className="BusinessEdit__Blocks__Subtitle">
                      Tell your customers how they can connect with you
                    </h3>

                    <div className="BusinessEdit__Blocks__Content pb-20">
                      <BusinessContactInformationForm
                        formState={form}
                        isPhoneNumberInvalid={isPhoneNumberInvalid}
                        onHandleChange={(...params) => this.onHandleChange(...params)}
                        onHandleCloseDialog={() => this.setState({ isPhoneNumberInvalid: false })}
                      />
                      <BusinessSocialInformationForm
                        formState={form}
                        onHandleChange={(...params) => this.onHandleChange(...params)}
                      />
                    </div>
                  </div>

                  <div className="BusinessEdit__Blocks">
                    <h2 className="BusinessEdit__Blocks__Title">What&apos;s on the menu?</h2>
                    <h3 className="BusinessEdit__Blocks__Subtitle">
                      Upload a link to your menu so customers can see what they can get when they
                      come in-store
                    </h3>

                    <div className="BusinessEdit__Blocks__Content">
                      <BusinessMenuForm
                        formState={form}
                        onHandleChange={(...params) => this.onHandleChange(...params)}
                      />
                    </div>
                  </div>

                  <div className="BusinessEdit__Blocks">
                    <h2 className="BusinessEdit__Blocks__Title">When are you open?</h2>
                    <h3 className="BusinessEdit__Blocks__Subtitle">
                      Let customers know when you are open
                    </h3>

                    <div className="BusinessEdit__Blocks__Content">
                      <BusinessHours
                        businessHours={form.BusinessHours}
                        onHandleChange={(...params) => this.onHandleChange(...params)}
                      />
                    </div>
                  </div>

                  <div className="BusinessEdit__Blocks">
                    <h2 className="BusinessEdit__Blocks__Title">
                      What else do you offer?
                      <img
                        className="ml-10 infoElement"
                        src={infoIcon}
                        alt=""
                        height={20}
                        width={20}
                        onClick={() => {
                          this.setState({ isDialog: true });
                        }}
                      />
                    </h2>
                    <h3 className="BusinessEdit__Blocks__Subtitle BusinessEdit__Blocks__Subtitle--BorderBottom">
                      Select all of the amenities and features available at your business.
                    </h3>
                    {businessOptionParamaters && businessOptionParamaters.length > 0 && (
                      <>
                        <div className="BusinessEdit__Blocks__Content BusinessEdit__Blocks__Content--BorderBottom">
                          <BusinessOptions
                            formState={form}
                            businessOptionParamaters={businessOptionParamaters}
                            onHandleChange={(...params) =>
                              this.onHandleBusinessOptionChange(...params)
                            }
                          />
                        </div>
                        <div className="BusinessEdit__Blocks__Content BusinessEdit__Blocks__Content--BorderBottom">
                          <BusinessAmenities
                            formState={form}
                            businessOptionParamaters={businessOptionParamaters}
                            onHandleChange={(...params) =>
                              this.onHandleBusinessOptionChange(...params)
                            }
                          />
                        </div>
                        <div className="BusinessEdit__Blocks__Content BusinessEdit__Blocks__Content--BorderBottom">
                          <BusinessHealth
                            formState={form}
                            businessOptionParamaters={businessOptionParamaters}
                            onHandleChange={(...params) =>
                              this.onHandleBusinessOptionChange(...params)
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="buttons-actions">
                    {loading && (
                      <div className="loader">
                        <Loader />
                      </div>
                    )}

                    <button
                      onClick={() => history.push('/admin/businesses')}
                      type="button"
                      className="button small f-size-14"
                    >
                      CANCEL
                    </button>

                    <button
                      disabled={loading}
                      type="submit"
                      className="button small orange-business-theme"
                    >
                      FINISH
                    </button>
                  </div>
                </div>
                {/* <div className="BusinessAdd__Copyright">
                  Geodata copyright
                  {' '}
                  <a href="https://www.openstreetmap.org/">OpenStreetMap</a>
                  {' '}
                  contributors
                </div> */}
              </form>
            )}
            errorMessage={errorMessage}
            expectedData={form}
            onHandleCloseDialog={() => this.setState({ errorMessage: '' })}
          />
        </MasterPage>
        {/* <RegisterFooter /> */}
        <LightBox
          style={{
            width: 450,
            height: 200,
            marginLeft: -225,
            marginTop: -100,
          }}
          onClose={() => {
            this.setState({ isHowWeUse: false });
          }}
          show={isHowWeUse}
        >
          <div className="DialogTitle">How we use your info?</div>
          <div className="DialogContent">
            The information you provide here will be used to help
            <br />
            users find your business through our search and filter
            <br />
            features. Please note: your business name and
            <br />
            description will be shown publicly on your profile.
          </div>
        </LightBox>
        <LightBox
          style={{
            width: 500,
            height: 200,
            marginLeft: -250,
            marginTop: -100,
          }}
          onClose={() => {
            this.setState({ isDialog: false });
          }}
          show={isDialog}
        >
          <div className="DialogContent">
            The more information you provide here the better our users can find
            <br />
            and decide whether your business is right for them. Note: we will
            <br />
            periodically review and ask our community to verify the amenities
            <br />
            and features you have said you provide here, so please be sure to
            <br />
            keep them up to date and accurate.
          </div>
        </LightBox>
      </>
    );
  }
}

BusinessAdd.propTypes = {
  auth: bool.isRequired,
  history: HISTORY_TYPE.isRequired,
};

export default BusinessAdd;
