import '../TagsInput/index.sass';
import './index.sass';

import { isEmpty } from 'lodash';
import { bool, func, string } from 'prop-types';
import React from 'react';
import uuidv1 from 'uuid/v1';

import AutocompleteGooglePlaces from '../AutocompleteGooglePlaces';

class AddressTagsInput extends React.Component {
  AddressTagsInput = React.createRef();

  state = {
    addressSearchError: '',
    addressSearchTerm: '',
    allTags: [],
  };

  componentDidMount() {
    const { value } = this.props;

    if (value) {
      this.updateTags(value.split(','));
    }
  }

  componentDidUpdate(prevProps) {
    const { value, disabled } = this.props;
    if (prevProps.value !== value) {
      this.updateTags(value ? value.split(',') : []);
    }
    if (!prevProps.disabled && disabled) {
      this.clearInput();
    }
  }

  onSearchAddress(addressSearchTerm) {
    this.setState({
      addressSearchError: '',
      addressSearchTerm,
    });
  }

  onHandleDeleteTag(_item) {
    const { allTags } = this.state;
    const { onChange } = this.props;

    const filteredAllTags = allTags.filter((item) => item !== _item);
    this.setState({ allTags: filteredAllTags });
    onChange(filteredAllTags.join(','));
  }

  updateTags(value) {
    this.setState({ allTags: value });
  }

  clearInput() {
    this.setState({ addressSearchTerm: '' });
  }

  async addAddress(searchTerm) {
    let term = searchTerm;
    const { onChange, multiple, filter } = this.props;
    if (filter && filter.types && filter.types.includes('(cities)')) {
      term = searchTerm.split(',')[0];
    } else {
      term = searchTerm.split(',').join(' ');
    }
    const { allTags } = this.state;
    this.setState({ addressSearchTerm: '' });
    if (!multiple) {
      allTags.pop();
      allTags.push(term);
    } else {
      allTags.push(term);
    }
    this.setState({ allTags });
    onChange(allTags.join(','));
  }

  render() {
    const {
      disabled,
      id,
      name,
      filter,
      placeholder,
      className: parentClass,
      multiple,
    } = this.props;
    const { addressSearchError, addressSearchTerm, allTags } = this.state;

    return (
      <>
        <div
          className={`TagsInput ${disabled ? 'TagsInput--Disabled' : ''} ${parentClass || ''}`}
          ref={(el) => {
            this.AddressTagsInput = el;
          }}
        >
          <ul className="TagsInput__List">
            {!isEmpty(allTags) &&
              allTags.map((item) => (
                <li key={uuidv1()} className="tags-add">
                  {item}
                  <i
                    className="icon-close"
                    onClick={() => this.onHandleDeleteTag(item)}
                    onKeyPress={() => this.onHandleDeleteTag(item)}
                    role="button"
                    tabIndex="0"
                    title="delete item"
                  />
                </li>
              ))}
            {(multiple || isEmpty(allTags)) && (
              <AutocompleteGooglePlaces
                disabled={disabled}
                inputClassName="full w290"
                id={id}
                name={name}
                onChange={(searchTerm) => this.onSearchAddress(searchTerm)}
                onSelect={(address, placeId, suggestion) =>
                  this.addAddress(address, placeId, suggestion)
                }
                value={addressSearchTerm}
                searchOptions={filter}
                placeholder={isEmpty(allTags) ? placeholder : ''}
                noBrowserComplete
              />
            )}
          </ul>
        </div>

        {addressSearchError && (
          <div className="AddressTagsInput__ErrorMessage">{addressSearchError}</div>
        )}
      </>
    );
  }
}

AddressTagsInput.propTypes = {
  multiple: bool,
  disabled: bool,
  name: string.isRequired,
  onChange: func.isRequired,
  value: string,
  placeholder: string,
  id: string,
  className: string,
  noBrowserComplete: bool,
};

AddressTagsInput.defaultProps = {
  multiple: false,
  disabled: false,
  value: '',
  placeholder: '',
  id: 'addressTagsInput',
  className: '',
  noBrowserComplete: false,
};

export default AddressTagsInput;
