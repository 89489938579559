import { APIRequest, req } from '../utils/api';
import { isFoursquareData } from '../utils/placeId';

export const AUTH = 'AUTH';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const TOKEN_RESET_PASSWORD = 'TOKEN_RESET_PASSWORD';
export const RESEND_VERIFICATION_CODE = 'RESEND_VERIFICATION_CODE';
export const RESEND_VERIFICATION_CODE_SUCCESS = 'RESEND_VERIFICATION_CODE_SUCCESS';
export const RESEND_VERIFICATION_CODE_ERROR = 'RESEND_VERIFICATION_CODE_ERROR';
export const VERIFY_ACCOUNT_IDENTIFIER = 'VERIFY_ACCOUNT_IDENTIFIER';
export const VERIFY_ACCOUNT_IDENTIFIER_SUCCESS = 'VERIFY_ACCOUNT_IDENTIFIER_SUCCESS';
export const VERIFY_ACCOUNT_IDENTIFIER_ERROR = 'VERIFY_ACCOUNT_IDENTIFIER_ERROR';
export const ACCOUNTS = 'ACCOUNTS';
export const ACCOUNTS_SUCCESS = 'ACCOUNTS_SUCCESS';
export const ACCOUNTS_ERROR = 'ACCOUNTS_ERROR';

export function authenticate(data) {
  return req({
    uri: 'Login/Authenticate',
    method: 'POST',
    data,
  });
}

export function logout() {
  return req({
    uri: 'Login/Logout',
    method: 'POST',
  });
}

export function forgotPassword(data) {
  return async (dispatch) => {
    await APIRequest({
      uri: 'Password/RequestPasswordReset',
      method: 'POST',
      data: {
        ...data,
        ResetByEmail: true,
      },
    })
      .then(res => dispatch({
        type: FORGOT_PASSWORD,
        payload: {
          errorMessage: null,
          successMessage: res.Message,
        },
      }))
      .catch(err => dispatch({
        type: FORGOT_PASSWORD,
        payload: {
          errorMessage: err,
          successMessage: null,
        },
      }));
  };
}

export function checkResetPasswordToken(token) {
  return async (dispatch) => {
    await APIRequest({
      uri: `Password/ValidateToken?token=${token}`,
      method: 'GET',
    })
      .then(() => {
        dispatch({
          type: TOKEN_RESET_PASSWORD,
          payload: {
            resetPasswordTokenIsValid: true,
            errorMessage: null,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: TOKEN_RESET_PASSWORD,
          payload: {
            errorMessage: err,
            resetPasswordTokenIsValid: false,
          },
        });
      });
  };
}

export function resetPassword(data) {
  return async (dispatch) => {
    await APIRequest({
      uri: 'Password/Reset',
      method: 'POST',
      data,
    })
      .then(res => dispatch({
        type: RESET_PASSWORD,
        payload: {
          successMessage: res.Message || 'Your password has been updated.',
          errorMessage: null,
        },
      }))
      .catch(err => dispatch({
        type: RESET_PASSWORD,
        payload: {
          errorMessage: err,
          successMessage: null,
        },
      }));
  };
}

export function validateAccountIdentifier(identifier, captchaToken) {
  return req(
    {
      baseApiUri: '/Api',
      uri: captchaToken
        ? `V2/Authentication/ValidateAccountIdentifier?identifier=${identifier}&CaptchaToken=${captchaToken}`
        : `Authentication/ValidateAccountIdentifier?identifier=${identifier}`,
      method: 'POST',
    },
    {
      dataProp: 'Success',
    },
  );
}

export function resendVerificationCode(data) {
  return req(
    {
      baseApiUri: '/Api',
      uri: 'V2/Authentication/ResendVerificationCode',
      method: 'POST',
      data,
    },
    {
      dataProp: 'Success',
    },
  );
}

export function verifyIdentifier(data) {
  return req({
    baseApiUri: '/Api',
    uri: 'Authentication/VerifyIdentifier',
    method: 'POST',
    data,
  });
}

export function updateIdentifier(data) {
  return req({
    baseApiUri: '/Api',
    uri: 'Authentication/UpdateIdentifier',
    method: 'POST',
    data,
  });
}

export function signUp(ivBase64, data) {
  return req({
    headers: { IVBase64: ivBase64 },
    baseApiUri: isFoursquareData() ? '/Api/V2' : '/Api',
    uri: 'Authentication/Accounts',
    method: 'POST',
    data,
  });
}
