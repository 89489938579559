import {
  ACCOUNTS,
  ACCOUNTS_ERROR,
  ACCOUNTS_SUCCESS,
  AUTH,
  FORGOT_PASSWORD,
  RESEND_VERIFICATION_CODE,
  RESEND_VERIFICATION_CODE_ERROR,
  RESEND_VERIFICATION_CODE_SUCCESS,
  RESET_PASSWORD,
  TOKEN_RESET_PASSWORD,
} from '../actions/auth';

const initialState = {
  errorMessage: null,
  forgotPasswordSuccessMessage: null,
  resetPasswordSuccessMessage: null,
  resetPasswordTokenIsValid: null,

  isCreatingAccount: null,
  creatingAccountsErrorMessage: null,

  isResendingVerificationCode: null,
  resendingVerificationCodeSuccess: null,
  resendingVerificationCodeErrorMessage: null,

  verifyingIdentifierData: '',
  verifyingIdentifierErrorMessage: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case ACCOUNTS: {
      return {
        ...state,
        createAccountsErrorMessage: null,
        isCreatingAccount: true,
      };
    }

    case ACCOUNTS_SUCCESS: {
      return {
        ...state,
        createAccountsErrorMessage: null,
        isCreatingAccount: false,
      };
    }

    case ACCOUNTS_ERROR: {
      return {
        ...state,
        createAccountsErrorMessage: payload.errorMessage || null,
        isCreatingAccount: false,
      };
    }

    case AUTH: {
      return {
        ...state,
        errorMessage: payload.errorMessage || null,
        token: payload.token || null,
        tokenExpirationDate: payload.tokenExpirationDate || null,
      };
    }

    case FORGOT_PASSWORD: {
      return {
        ...state,
        errorMessage: payload.errorMessage || null,
        forgotPasswordSuccessMessage: payload.successMessage || null,
      };
    }

    case RESEND_VERIFICATION_CODE: {
      return {
        ...state,
        isResendingVerificationCode: true,
        resendingVerificationCodeErrorMessage: null,
        resendingVerificationCodeSuccess: false,
      };
    }

    case RESEND_VERIFICATION_CODE_SUCCESS: {
      return {
        ...state,
        isResendingVerificationCode: false,
        resendingVerificationCodeErrorMessage: null,
        resendingVerificationCodeSuccess: true,
      };
    }

    case RESEND_VERIFICATION_CODE_ERROR: {
      return {
        ...state,
        isResendingVerificationCode: false,
        resendingVerificationCodeErrorMessage: payload.errorMessage || null,
        resendingVerificationCodeSuccess: false,
      };
    }

    case RESET_PASSWORD: {
      return {
        ...state,
        errorMessage: payload.errorMessage || null,
        resetPasswordSuccessMessage: payload.successMessage || null,
      };
    }

    case TOKEN_RESET_PASSWORD: {
      return {
        ...state,
        resetPasswordTokenIsValid: payload.resetPasswordTokenIsValid || null,
        errorMessage: payload.errorMessage || null,
      };
    }

    default:
      return state;
  }
}
