import './index.sass';

import { bool, func, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { removeLink as removeLinkAction, shareLink as shareLinkAction } from '../../actions/videos';
import copyIcon from '../../assets/images/files-empty.svg';
import { copyText } from '../../helpers';
import VIDEO_TYPE from '../../types/video';
import TagsList from '../TagsList';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

class BusinessVideoDetailsComponent extends React.Component {
  state = {
    linkIsCopied: false,
  };

  componentDidMount() {
    setTimeout(() => {
      var x = document.getElementsByClassName('vjs-big-play-button');
      if (x.length > 0) x[0].click();
    }, 300);
  }
  componentWillUnmount() {
    const { removeLink } = this.props;
    removeLink();
  }

  getPublicLink() {
    const { shareLink, videoDetails } = this.props;
    shareLink(videoDetails.Key);
  }

  copyText(link) {
    copyText(link);
    this.setState({ linkIsCopied: true });
    setTimeout(() => this.setState({ linkIsCopied: false }), 1000);
  }

  renderPostBody = () => {
    const {
      videoDetails: { PostBody, TaggedUsers },
    } = this.props;
    var result = PostBody;
    const userIds = [...PostBody.matchAll(/\[UserId:(\d+)\]/g)].map((m) => +m[1]);
    userIds.forEach((userid) => {
      const user = TaggedUsers.filter((f) => f.UserId === userid);
      result = result.replace(`[UserId:${userid}]`, user[0].DisplayName);
    });
    return result;
  };

  renderVideo = () => {
    const { videoDetails } = this.props;
    let result = (
      <VideoPlayer ratio="16:9" url={videoDetails.StreamingVideoUrl} />
    );
    if (!videoDetails.StreamingVideoUrl) {
      result = (
        <div className="player-wrapper">
          <VideoPlayer ratio="16:9" url={videoDetails.VideoUrl} />
        </div>
      );
    }
    return result;
  };

  render() {
    const { linkIsCopied } = this.state;
    const { isFetchingLink, link, linkError, videoDetails } = this.props;

    return (
      <div className="BusinessVideoDetails">
        {this.renderVideo()}

        <div className="BusinessVideoDetails__Social">
          <div className="icon-button BusinessVideoDetails__PublicLink">
            {link && (
              <>
                <button className="icon-button" onClick={() => this.copyText(link)} type="button">
                  <img src={copyIcon} alt="copy" />
                </button>

                {linkIsCopied ? (
                  <div className="BusinessVideoDetails__PublicLink__Copied">Link copied!</div>
                ) : (
                  <a href={link} target="blank">
                    {link}
                  </a>
                )}
              </>
            )}

            {!link && (
              <button
                className="button small BusinessVideoDetails__PublicLinkButton"
                onClick={() => this.getPublicLink()}
                type="button"
              >
                <span>{isFetchingLink ? 'getting link...' : 'get public link to this video'}</span>
              </button>
            )}
          </div>

          {!linkError && <div className="error">{linkError}</div>}
        </div>

        {videoDetails.PostBody && (
          <p className="BusinessVideoDetails__Description">{this.renderPostBody()}</p>
        )}

        {videoDetails.Products && (
          <TagsList className="BusinessVideoDetails__Sags" tags={videoDetails.Products} />
        )}
      </div>
    );
  }
}

BusinessVideoDetailsComponent.propTypes = {
  isFetchingLink: bool.isRequired,
  link: string.isRequired,
  linkError: string.isRequired,
  removeLink: func.isRequired,
  shareLink: func.isRequired,
  videoDetails: VIDEO_TYPE.isRequired,
};

const mapStateToProps = (state) => {
  const { link, linkError, isFetchingLink } = state.videos;

  return {
    link,
    linkError,
    isFetchingLink,
  };
};

export default connect(mapStateToProps, {
  shareLink: shareLinkAction,
  removeLink: removeLinkAction,
})(BusinessVideoDetailsComponent);
