/* eslint-disable no-undef */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import packageJson from '../../package.json';

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);
  return momLatestDateTime.isAfter(momCurrentDateTime);
};

const useClearCache = () => {
  const location = useLocation();

  const refreshCacheAndReload = async () => {
    if (caches) {
      const names = await caches.keys();
      await Promise.all(names.map(name => caches.delete(name)));
    }
    window.location.reload();
  };

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { pragma: 'no-cache', 'cache-control': 'no-cache' },
    };

    fetch('/meta.json', requestOptions)
      .then(response => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;

        const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);
        if (shouldForceRefresh) {
          refreshCacheAndReload();
        }
      })
      .catch(() => {});
  }, [location.pathname]);
};

export default useClearCache;
