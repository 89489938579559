import './index.sass';

import { bool } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import businessPreviewImg from '../../assets/images/business_preview.png';
import WelcomeImg from '../../assets/images/welcome2.jpg';
import AsyncContent from '../../components/AsyncContent';
import MasterPage from '../../components/MasterPage';
import RegisterFooter from '../../components/RegisterFooter';
import Tooltip from '../../components/Tooltip';

const ActivatedAccount = ({ auth }) => (
  <>
    <Helmet title="Franki Admin: payment" />

    <MasterPage auth={auth}>
      <AsyncContent
        content={() => (
          <div className="activatedAccount">
            <figure className="activatedAccount-img">
              <img src={WelcomeImg} alt="" />
            </figure>

            <h1 className="activatedAccount-title">John, welcome to franki!</h1>

            <p className="activatedAccount-desc">
              let&apos;s set up your business profile so we can run some gigs!
            </p>

            <div>
              <Link to="/admin/register-business" className="button orange-theme">
                create business profile
              </Link>

              <Tooltip title="Business Profile" position="right">
                <>
                  <p>
                    This is what your profile will look like in franki, make sure you stand out!
                  </p>
                  <figure>
                    <img src={businessPreviewImg} alt="" />
                  </figure>
                </>
              </Tooltip>
            </div>
          </div>
        )}
        expectNoData
        successMessage="your purchase was sucessful."
      />
    </MasterPage>

    <RegisterFooter />
  </>
);

ActivatedAccount.propTypes = {
  auth: bool.isRequired,
};

export default ActivatedAccount;
