import './index.sass';

import { isEmpty } from 'lodash';
import moment from 'moment';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';

import HamburgerMenu from '../../HamburgerMenu';

const SubmissionsCard = (props) => {
  const { bookmarkItems, item, onClick, playable } = props;

  return (
    <div className={`SubmissionsCard ${item.IsDeleted && 'DeletedItem'}`}>
      {!isEmpty(bookmarkItems) && (
        <HamburgerMenu
          menuItems={bookmarkItems}
          className="SubmissionsCard__Bookmarks"
          icon="icon-bookmark_default"
          position="Left"
        />
      )}

      <div className="SubmissionsCard__ThumbnailContainer">
        <button
          className="SubmissionsCard__ThumbnailCover"
          onClick={onClick}
          onKeyPress={onClick}
          type="button"
        />

        <figure className="SubmissionsCard__Thumbnail" role="presentation">
          {item.FullResolutionUrl &&
            (playable && item.VideoUrl ? (
              <video controls src={item.VideoUrl}>
                <track default kind="captions" srcLang="en" src={item.FullResolutionUrl} />
                Your browser does not support HTML5 video.
              </video>
            ) : (
              <img src={item.FullResolutionUrl} alt={item.FullResolutionUrl} />
            ))}

          {item.featured && <figcaption className="SubmissionsCard__Featured">featured</figcaption>}
        </figure>
      </div>

      <div className="SubmissionsCard__Wrapper">
        <div className="SubmissionsCard__PostedBy">
          {item.Avatar && (
            <figure className="SubmissionsCard__Avatar">
              <img src={item.Avatar} alt="avatar" />
            </figure>
          )}

          {item.PosterProfile &&
            Object.entries(item.PosterProfile).length > 1 &&
            item.PosterProfile.ProfilePhoto &&
            item.PosterProfile.ProfilePhoto.FullResolutionUrl && (
              <figure className="SubmissionsCard__Avatar">
                <img src={item.PosterProfile.ProfilePhoto.FullResolutionUrl} alt="avatar" />
              </figure>
            )}

          <div className="SubmissionsCard__PostedBy__Info">
            {item.PosterProfile &&
              Object.entries(item.PosterProfile).length > 1 &&
              item.PosterProfile.DisplayName && (
                <span className="SubmissionsCard__PostedBy__Info__User">
                  {item.PosterProfile.DisplayName}
                </span>
              )}

            {item.Author && (
              <span className="SubmissionsCard__PostedBy__Info__User">{item.Author}</span>
            )}

            {item.Posted && (
              <span className="SubmissionsCard__PostedBy__Info__Date">
                {moment(new Date(item.Posted)).format('L - LT')}
              </span>
            )}
          </div>
        </div>

        {item.caption && <p className="SubmissionsCard__Description">{item.caption}</p>}

        <div className="SubmissionsCard__Footer">
          <ul>
            <li>
              <span className="SubmissionsCard__Footer__Item">{item.LikeCount || 0}</span>
              <i className="icon-heart" />
            </li>

            <li>
              <span className="SubmissionsCard__Footer__Item">{item.CommentCount || 0}</span>
              <i className="icon-comment" />
            </li>

            <li>
              <span className="SubmissionsCard__Footer__Item">{item.FavoriteCount || 0}</span>
              <i className="icon-save" />
            </li>

            <li>
              <span className="SubmissionsCard__Footer__Item">{item.Rating || 0}</span>
              <i className="icon-star_on" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

SubmissionsCard.propTypes = {
  bookmarkItems: instanceOf(Array),
  item: instanceOf(Object).isRequired,
  onClick: func.isRequired,
  playable: bool,
};

SubmissionsCard.defaultProps = {
  bookmarkItems: [],
  playable: false,
};

export default SubmissionsCard;
