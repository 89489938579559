import './index.sass';

import { isEmpty } from 'lodash';
import moment from 'moment';
import { instanceOf } from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import HamburgerMenu from '../../../components/HamburgerMenu';
import { COLORS } from '../../../config/colors';
import { getStatusName } from '../../../helpers';

const AllGigsCard = (props) => {
  const {
    item: { Gig, CorporateGroup },
  } = props;
  const GigStatusName = getStatusName(Gig.StatusId);
  const currencyFormat = new Intl.NumberFormat();

  return (
    <div className="AllGigsCard">
      <HamburgerMenu
        className="AllGigsCard__Menu"
        ellipsisColor={COLORS.white}
        menuItems={[
          {
            innerContent: 'details',
            url: `/admin/business-gig-details/${Gig.CorporateGroupId}/${Gig.GigId}`,
          },
          {
            innerContent: 'view submissions',
            url: `/admin/business-gig-details/${Gig.CorporateGroupId}/${Gig.GigId}?view=submissions`,
          },
          {
            innerContent: 'view nominees',
            url: `/admin/business-gig-details/${Gig.CorporateGroupId}/${Gig.GigId}?view=nominees`,
          },
          {
            innerContent: (
              <div className="AllGigsCard__Menu__PaidStatus">
                gig status
                <div className="AllGigsCard__Menu__PaidStatus__Value">{GigStatusName.label}</div>
              </div>
            ),
          },
          {
            innerContent: `ID: ${Gig.GigId}`,
          },
        ]}
      />

      <Link to={`/admin/business-gig-details/${Gig.CorporateGroupId}/${Gig.GigId}`}>
        <figure className="AllGigsCard__GigPhoto">
          {Gig.Photo && <img src={Gig.Photo.FullResolutionUrl} alt="" />}
        </figure>

        {!isEmpty(Gig.GigPrizes) && (
          <span className="AllGigsCard__Prize">
            ${currencyFormat.format(Gig.GigPrizes[0].PrizeAmount)}
            {Gig.GigPrizes[1] && <>- ${currencyFormat.format(Gig.GigPrizes[1].PrizeAmount)}</>}
          </span>
        )}

        <div className="AllGigsCard__Informations">
          <div className="AllGigsCard__Informations__CorporateGroup">
            <figure className="AllGigsCard__Informations__CorporateGroup__Logo">
              {CorporateGroup && CorporateGroup.Logo && (
                <img
                  alt={CorporateGroup.Logo.Description}
                  src={CorporateGroup.Logo.FullResolutionUrl}
                />
              )}
            </figure>

            <div className="AllGigsCard__Informations__CorporateGroup__DisplayName">
              {CorporateGroup.DisplayName}
            </div>
          </div>

          <div className="AllGigsCard__Informations__Status">
            <span className="AllGigsCard__Informations__Status__Date">
              Closes on {moment(new Date(Gig.EndDate)).format('MMMM D, YYYY')}
            </span>

            <span
              className={`AllGigsCard__Informations__Status__Type AllGigsCard__Informations__Status__Type--${GigStatusName.slug}`}
            >
              {GigStatusName.label}
            </span>
          </div>

          <div className="AllGigsCard__Informations__GigName">{Gig.Name}</div>
        </div>
      </Link>
    </div>
  );
};

AllGigsCard.propTypes = {
  item: instanceOf(Object).isRequired,
};

export default withRouter(AllGigsCard);
