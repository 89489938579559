/* eslint-disable react/no-danger */
import './index.sass';

import { isEmpty } from 'lodash';
import { bool, func, instanceOf, number, string } from 'prop-types';
import React from 'react';
import uuidv1 from 'uuid/v1';

class CustomizedCheckboxButton extends React.Component {
  onHandleChange = async (target) => {
    const { onChange, checkedItems } = this.props;

    if (target.checked) {
      onChange(checkedItems.concat([target.value]));
    } else {
      onChange(checkedItems.filter((ci) => ci !== target.value));
    }
  };

  render() {
    const {
      showInnerHtml = false,
      checkedItems,
      disabled,
      name,
      items,
      onChange,
      height,
      ...rest
    } = this.props;

    return (
      <div className="CustomizedCheckboxButton" style={{ minHeight: height }}>
        {items &&
          items.map((item) => (
            <label key={uuidv1()} className="CustomizedCheckboxButton__Label">
              <span
                className={`CustomizedCheckboxButton__Label__Button
                  ${
                    !isEmpty(checkedItems) &&
                    !!checkedItems.find((checkedI) => checkedI.toString() === item.value.toString())
                      ? 'CustomizedCheckboxButton__Label__Button--Checked'
                      : ''
                  }
                  ${(disabled || item.disabled) && 'disabled'}
                `}
                dangerouslySetInnerHTML={{ __html: item.label }}
              />

              <input
                checked={
                  !isEmpty(checkedItems) &&
                  !!checkedItems.find((ck) => ck.toString() === item.value.toString())
                }
                disabled={disabled || item.disabled}
                name={name}
                onChange={({ target }) => this.onHandleChange(target)}
                type="checkbox"
                value={item.value}
                {...rest}
              />
              <span
                dangerouslySetInnerHTML={{ __html: item.label }}
                style={{ visibility: showInnerHtml }}
              />
            </label>
          ))}
      </div>
    );
  }
}

CustomizedCheckboxButton.propTypes = {
  checkedItems: instanceOf(Array).isRequired,
  disabled: bool,
  height: number,
  items: instanceOf(Array).isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
};

CustomizedCheckboxButton.defaultProps = {
  disabled: false,
  height: 37,
};

export default CustomizedCheckboxButton;
