import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY } from '../config/variables';

const googleReCaptchaHoc = (Component) => (props) =>
  (
    <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}>
      <Component {...props} />
    </GoogleReCaptchaProvider>
  );

export default googleReCaptchaHoc;
