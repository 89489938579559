import { bool, func, number, string } from 'prop-types';
import React, { Component } from 'react';
import uuidv1 from 'uuid/v1';

import { APIRequest } from '../../utils/api';
import CustomSelect from '../CustomSelect';

const getUserRole = () =>
  APIRequest({
    uri: 'V2/UserAdmin/GetSecurityGroupList',
    method: 'GET',
  });

class UserRoleSelect extends Component {
  state = {
    options: [],
  };

  componentDidMount() {
    getUserRole().then(({ Data }) => {
      this.setState({
        options: Data.map(({ Group, Name }) => ({ label: Name, value: Group })),
      });
    });
  }

  render() {
    const { id, label, name, onChange, placeholder, required, value } = this.props;
    const { options } = this.state;
    const selectedOption = options.find(({ value: optionValue }) => optionValue === value);

    return (
      <div className="form-group has-tooltip">
        <fieldset>
          <label className="form-label" htmlFor={id}>
            {label}

            {required && <span className="require-asterisk">*</span>}
          </label>

          <CustomSelect
            id={id}
            name={name}
            onChange={({ value: userRole }) => onChange(userRole)}
            options={options}
            required={required}
            placeholder={placeholder}
            value={selectedOption}
          />
        </fieldset>
      </div>
    );
  }
}

UserRoleSelect.propTypes = {
  id: string,
  label: string,
  name: string.isRequired,
  onChange: func.isRequired,
  placeholder: string.isRequired,
  required: bool,
  value: number,
};

UserRoleSelect.defaultProps = {
  id: uuidv1(),
  label: '',
  required: false,
  value: [],
};

export default UserRoleSelect;
