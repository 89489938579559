import './index.sass';

import { func, instanceOf, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { createGroupManagerUser } from '../../../../actions/register';
import AsyncContent from '../../../../components/AsyncContent';
import StepsHeader from '../../../../components/StepsHeader';
import { validEmailAddress, validPasswordStraight } from '../../../../helpers';

class RegisterAccount extends React.Component {
  state = {
    showPassword: false,
    loading: false,
    errorMessage: null,
  };

  componentDidUpdate(prevProps) {
    const { token, errorMessage } = this.props;

    if (prevProps.errorMessage !== errorMessage && errorMessage) {
      this.errorMessage();
    }

    if (prevProps.token !== token && token) {
      this.navigateAfterSuccess();
    }
  }

  errorMessage = () => {
    const { errorMessage } = this.props;

    this.setState({
      errorMessage,
      loading: false,
    });
  };

  navigateAfterSuccess = () => {
    this.setState({ loading: false });
    const { history } = this.props;
    history.push('/admin/register-business');
  };

  onHandleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onHandleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    const {
      createGroupManagerUser: createGroupManagerUserAction,
      formFields: { firstName, lastName, email, password },
    } = this.props;

    await createGroupManagerUserAction({
      FirstName: firstName,
      LastName: lastName,
      Password: password,
      Email: email,
    });

    this.setState({ loading: false });
  };

  render() {
    const { showPassword, loading, errorMessage } = this.state;
    const {
      formFields: { firstName, lastName, email, password },
      onHandleFormChange,
    } = this.props;

    const passStraight = validPasswordStraight(password);
    const validEmail = validEmailAddress(email);

    return (
      <div className="register">
        <StepsHeader
          step={1}
          maxSteps={4}
          title="create your account"
          subtitle="you will become your business’s super admin, you can always change this later."
        />

        <AsyncContent
          content={() => (
            <form className="register-form" method="POST" onSubmit={(e) => this.onHandleSubmit(e)}>
              <div className="register-form-blocks">
                <label htmlFor="txtFirstName">
                  first name
                  <span className="required-asterisk">*</span>
                </label>

                <input
                  type="text"
                  className="full"
                  name="firstName"
                  id="txtFirstName"
                  onChange={({ target }) => onHandleFormChange(target.name, target.value)}
                  value={firstName}
                  minLength={3}
                  maxLength={30}
                  autoComplete="off"
                  disabled={loading}
                  required
                />
              </div>

              <div className="register-form-blocks">
                <label htmlFor="txtlastName">last name</label>

                <input
                  type="text"
                  className="full"
                  name="lastName"
                  id="txtLastName"
                  value={lastName}
                  autoComplete="off"
                  maxLength={60}
                  onChange={({ target }) => onHandleFormChange(target.name, target.value)}
                  disabled={loading}
                />
              </div>

              <div className="register-form-blocks">
                <label htmlFor="txtEmail">
                  email
                  <span className="required-asterisk">*</span>
                </label>

                <input
                  type="email"
                  className="full"
                  name="email"
                  id="txtEmail"
                  value={email}
                  maxLength={60}
                  autoComplete="off"
                  pattern={validEmail.regexAsString}
                  onChange={({ target }) => onHandleFormChange(target.name, target.value)}
                  disabled={loading}
                  required
                />
              </div>

              <div className="register-form-blocks">
                <label htmlFor="txtPassword">
                  password
                  <span className="required-asterisk">*</span>
                </label>

                <input
                  type={showPassword ? 'text' : 'password'}
                  className="full"
                  name="password"
                  id="txtPassword"
                  value={password}
                  maxLength={60}
                  minLength={8}
                  onChange={({ target }) => onHandleFormChange(target.name, target.value)}
                  autoComplete="off"
                  pattern={passStraight.regexAsString}
                  disabled={loading}
                  required
                />

                {showPassword ? (
                  <i
                    className="icon-eye-slash"
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => this.onHandleChange('showPassword', false)}
                    onClick={() => this.onHandleChange('showPassword', false)}
                  />
                ) : (
                  <i
                    className="icon-eye"
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => this.onHandleChange('showPassword', true)}
                    onClick={() => this.onHandleChange('showPassword', true)}
                  />
                )}
              </div>

              <button type="submit" className="button orange-theme" disabled={loading}>
                {loading ? 'loading...' : 'create account'}
              </button>
            </form>
          )}
          errorMessage={errorMessage}
          expectNoData
        />
      </div>
    );
  }
}

RegisterAccount.propTypes = {
  formFields: instanceOf(Object).isRequired,
  onHandleFormChange: func.isRequired,
  createGroupManagerUser: func.isRequired,
  errorMessage: string,
  history: instanceOf(Object).isRequired,
  token: string,
};

RegisterAccount.defaultProps = {
  errorMessage: null,
  token: null,
};

const mapStateToProps = (state) => ({
  errorMessage: state.register.errorMessage,
  token: state.auth.token,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { createGroupManagerUser }),
)(RegisterAccount);
