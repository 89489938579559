import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';

const PLAN_TYPE = shape({
  items: arrayOf(oneOfType([bool, string])),
  period: string,
  price: oneOfType([number, string]).isRequired,
  priceDetails: string,
  priceWithDiscount: oneOfType([number, string]).isRequired,
  title: string,
});

export default PLAN_TYPE;
