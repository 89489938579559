import { REACT_APP_API_URL } from '../config/variables';

const MOCK_URL_BASE = REACT_APP_API_URL;

export const mockData = [
  {
    path: `${MOCK_URL_BASE}/Businesses/CreateProfile`,
    method: 'POST',
    status: 200,
    data: {
      Data: {
        BusinessId: 34297,
        DisplayName: 'Mock Cafe',
        FullName: 'Mock Cafe',
      },
      Success: true,
      Message: 'Success',
    },
  },
];

export const ruleSets = [
  {
    id: 0,
    data: {
      eligibility: [
        {
          id: 'c987c6f0-2f84-11ec-b5cf-877ed8960ced',
          eligibilityMetric: { label: 'Videos needed', value: 0 },
          eligibilityComparison: { label: '<=', value: 1 },
          eligibilityValue: '4',
        },
        {
          id: 'cfb42140-2f84-11ec-b5cf-877ed8960ced',
          eligibilityMetric: { label: 'Followers needed', value: 1 },
          eligibilityComparison: { label: '<=', value: 1 },
          eligibilityValue: '5',
        },
      ],
      campaignDriver: [
        {
          id: 'c987c6f1-2f84-11ec-b5cf-877ed8960ced',
          driverMetric: { label: 'Posting a Video', value: 0 },
          driverReward: '534',
          driverCap: '45',
        },
        {
          id: 'd44da690-2f84-11ec-b5cf-877ed8960ced',
          driverMetric: { label: 'Video Shares', value: 1 },
          driverReward: '435',
          driverCap: '6575',
        },
        {
          id: 'd60abfe0-2f84-11ec-b5cf-877ed8960ced',
          driverMetric: { label: 'Follows', value: 2 },
          driverReward: '567',
          driverCap: '56757',
        },
      ],
      payOut: [
        {
          id: 'c987c6f2-2f84-11ec-b5cf-877ed8960ced',
          payOutMetric: { label: 'User Earning Cap', value: 1 },
          payOutReward: '45645',
        },
        {
          id: 'd93fd330-2f84-11ec-b5cf-877ed8960ced',
          payOutMetric: { label: 'User Earning Cap', value: 1 },
          payOutReward: '6575',
        },
      ],
    },
  },
  {
    id: 1,
    data: {
      eligibility: [
        {
          id: 'c987c6f0-2f84-11ec-b5cf-877ed8960ced',
          eligibilityMetric: { label: 'Videos needed', value: 0 },
          eligibilityComparison: { label: '>=', value: 3 },
          eligibilityValue: '12',
        },
      ],
      campaignDriver: [
        {
          id: 'c987c6f1-2f84-11ec-b5cf-877ed8960ced',
          driverMetric: { label: 'Posting a Video', value: 0 },
          driverReward: '78',
          driverCap: '7899',
        },
        {
          id: 'd44da690-2f84-11ec-b5cf-877ed8960ced',
          driverMetric: { label: 'Comments', value: 4 },
          driverReward: '635',
          driverCap: '687',
        },
        {
          id: '65448d30-2f85-11ec-b5cf-877ed8960ced',
          driverMetric: { label: 'Installs', value: 5 },
          driverReward: '342',
          driverCap: '234',
        },
        {
          id: '65ac28a0-2f85-11ec-b5cf-877ed8960ced',
          driverMetric: { label: 'Likes ', value: 3 },
          driverReward: '2342',
          driverCap: '234',
        },
      ],
      payOut: [
        {
          id: 'c987c6f2-2f84-11ec-b5cf-877ed8960ced',
          payOutMetric: { label: 'User Earning Cap', value: 0 },
          payOutReward: '49',
        },
      ],
    },
  },
];

export const campaigns = [
  {
    id: 0,
    data: {
      campaignDetails: {
        campaignTitle: 'Black Friday',
        campaignBudget: '4512122',
        campaignStartDate: '2021-10-18T21:00:00.000Z',
        campaignEndDate: '2021-10-20T21:00:00.000Z',
      },
      campaignRules: {
        eligibility: [
          {
            id: 'c987c6f0-2f84-11ec-b5cf-877ed8960ced',
            eligibilityMetric: { label: 'Videos needed', value: 0 },
            eligibilityComparison: { label: '>=', value: 3 },
            eligibilityValue: '12',
          },
        ],
        campaignDriver: [
          {
            id: 'c987c6f1-2f84-11ec-b5cf-877ed8960ced',
            driverMetric: { label: 'Posting a Video', value: 0 },
            driverReward: '78',
            driverCap: '7899',
          },
          {
            id: 'd44da690-2f84-11ec-b5cf-877ed8960ced',
            driverMetric: { label: 'Comments', value: 4 },
            driverReward: '635',
            driverCap: '687',
          },
          {
            id: '65448d30-2f85-11ec-b5cf-877ed8960ced',
            driverMetric: { label: 'Installs', value: 5 },
            driverReward: '342',
            driverCap: '234',
          },
          {
            id: '65ac28a0-2f85-11ec-b5cf-877ed8960ced',
            driverMetric: { label: 'Likes ', value: 3 },
            driverReward: '2342',
            driverCap: '234',
          },
        ],
        payOut: [
          {
            id: 'c987c6f2-2f84-11ec-b5cf-877ed8960ced',
            payOutMetric: { label: 'User Earning Cap', value: 0 },
            payOutReward: '49',
          },
        ],
      },
      campaignBonuses: { mostLoved: '1000', mostHelpfulFrankiLocals: '5000' },
    },
  },
  {
    id: 1,
    data: {
      campaignDetails: {
        campaignTitle: 'Honey Honey Campaign',
        campaignBudget: '50000',
        campaignStartDate: '2021-10-24T21:00:00.000Z',
        campaignEndDate: '2021-10-30T21:00:00.000Z',
      },
      campaignRules: {
        eligibility: [
          {
            id: 'c987c6f0-2f84-11ec-b5cf-877ed8960ced',
            eligibilityMetric: { label: 'Videos needed', value: 0 },
            eligibilityComparison: { label: '<=', value: 1 },
            eligibilityValue: '4',
          },
          {
            id: 'cfb42140-2f84-11ec-b5cf-877ed8960ced',
            eligibilityMetric: { label: 'Followers needed', value: 1 },
            eligibilityComparison: { label: '<=', value: 1 },
            eligibilityValue: '5',
          },
        ],
        campaignDriver: [
          {
            id: 'c987c6f1-2f84-11ec-b5cf-877ed8960ced',
            driverMetric: { label: 'Posting a Video', value: 0 },
            driverReward: '534',
            driverCap: '45',
          },
          {
            id: 'd44da690-2f84-11ec-b5cf-877ed8960ced',
            driverMetric: { label: 'Video Shares', value: 1 },
            driverReward: '435',
            driverCap: '6575',
          },
          {
            id: 'd60abfe0-2f84-11ec-b5cf-877ed8960ced',
            driverMetric: { label: 'Follows', value: 2 },
            driverReward: '567',
            driverCap: '56757',
          },
        ],
        payOut: [
          {
            id: 'c987c6f2-2f84-11ec-b5cf-877ed8960ced',
            payOutMetric: { label: 'User Earning Cap', value: 1 },
            payOutReward: '45645',
          },
          {
            id: 'd93fd330-2f84-11ec-b5cf-877ed8960ced',
            payOutMetric: { label: 'User Earning Cap', value: 1 },
            payOutReward: '6575',
          },
        ],
      },
      campaignBonuses: {
        mostLoved: '1000',
        mostHelpfulFrankiLocals: '5000',
        mostHelpfulVideo: '500',
      },
    },
  },
];

export default mockData;
