export const metricToOptions = metrics => metrics.map(item => ({
  ...item,
  label: item.DisplayString,
  value: item.Name,
}));

export const valuesToOptionConverter = item => ({
  ...item,
  label: item.DisplayString,
  value: item.Id,
});

export const valuesToOptions = values => values.map(item => valuesToOptionConverter(item));


export const thousandSeperatedNumber = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getLatestDate = (date1, date2) => {
  const formattedDate1 = date1 || new Date();
  const formattedDate2 = date2 || new Date();
  if (formattedDate1 >= formattedDate2) return formattedDate1;
  return formattedDate2;
};
