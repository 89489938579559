import { isEqual } from 'lodash';
import { arrayOf, bool, instanceOf, number, oneOfType, shape, string } from 'prop-types';
import React from 'react';

import TagsInput from '../../TagsInput';

class FiltersModalTagsInput extends React.Component {
  state = { value: [] };

  componentDidMount() {
    const { value } = this.props;
    this.updateState(value);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (!isEqual(prevProps.value, value)) {
      this.updateState(value);
    }
  }

  updateState(value) {
    this.setState({ value });
  }

  render() {
    const { value } = this.state;
    const {
      className,
      disabled,
      error,
      fetching,
      itemListAsync,
      label,
      limit,
      listOfTags,
      name,
      onChange,
      onSearchTermUpdate,
      placeholder,
    } = this.props;

    return (
      <>
        <fieldset className={className}>
          <legend>{label}</legend>
          <TagsInput
            className="full"
            disabled={disabled}
            fetching={fetching}
            itemListAsync={itemListAsync}
            limit={limit}
            listOfTags={listOfTags}
            name={name}
            onChange={(tagName, newValue) => onChange(tagName, newValue)}
            onSearchTermUpdate={onSearchTermUpdate}
            placeholder={placeholder}
            value={value}
          />
        </fieldset>

        {error && <div className="error">{error}</div>}
      </>
    );
  }
}

FiltersModalTagsInput.propTypes = {
  className: string,
  disabled: bool,
  error: string,
  fetching: bool,
  itemListAsync: bool,
  label: string.isRequired,
  limit: number,
  listOfTags: arrayOf(
    shape({
      label: string,
      value: oneOfType([string, number]),
    }),
  ),
  name: string.isRequired,
  onChange: instanceOf(Function).isRequired,
  onSearchTermUpdate: instanceOf(Function),
  placeholder: string.isRequired,
  value: arrayOf(
    shape({
      label: string,
      value: oneOfType([string, number]),
    }),
  ),
};

FiltersModalTagsInput.defaultProps = {
  className: '',
  disabled: false,
  error: null,
  fetching: false,
  itemListAsync: false,
  limit: 999999,
  listOfTags: [],
  onSearchTermUpdate: null,
  value: [],
};

export default FiltersModalTagsInput;
