import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';

import BUSINESS_GIG_TYPE from './businessGig';
import MEDIA_TYPE from './media';
import PRODUCT_TYPE from './product';
import STATE_TYPE from './state';

export const BUSINESS_TYPE_TYPE = shape({
  Id: number,
  Name: string,
});

export const NEW_BUSINESS_TYPE_TYPE = shape({
  Id: number,
  Name: string,
  DisplayString: string,
});

export const NEW_BUSINESS_STATE = shape({
  StateId: number,
  Name: string,
  ShortCode: string,
});

export const BUSINESS_OPENING_HOUR_PERIOD_TYPE = shape({
  Open: shape({
    Day: number,
    Time: string,
  }),
  Close: shape({
    Day: 0,
    Time: string,
  }),
});

export const BUSINESS_OPENING_HOUR_TYPE = shape({
  OpenNow: bool,
  Periods: arrayOf(BUSINESS_OPENING_HOUR_PERIOD_TYPE),
  WeekdayTexts: arrayOf(string),
});

export const BUSINESS_HOUR_TYPE = shape({
  BusinessId: number,
  DayOfTheWeek: number,
  OpeningTime: string,
  ClosingTime: string,
  OpeningTime2: string,
  ClosingTime2: string,
});

export const BUSINESS_DETAILS_TYPE = shape({
  Address: string,
  BusinessHours: arrayOf(BUSINESS_HOUR_TYPE),
  BusinessSourceId: number,
  BusinessType: BUSINESS_TYPE_TYPE,
  BusinessTypeId: number,
  City: string,
  ContentCount: number,
  CorporateGroupId: number,
  Country: string,
  Description: string,
  DisplayName: string,
  Distance: number,
  FacebookLink: string,
  FullName: string,
  Gigs: arrayOf(BUSINESS_GIG_TYPE),
  GooglePlaceId: string,
  GroupCode: string,
  InstagramLink: string,
  IsFollowedByUser: bool,
  IsPublic: bool,
  Latitude: oneOfType([number, string]),
  Location2: string,
  Logo: MEDIA_TYPE,
  Longitude: oneOfType([number, string]),
  NumberFollowers: number,
  NumberVideoReviews: number,
  OpeningHours: BUSINESS_OPENING_HOUR_TYPE,
  PhoneCountry: string,
  PhoneCountryCode: string,
  PhoneNumber: string,
  PostalCode: string,
  Products: arrayOf(PRODUCT_TYPE),
  State: string,
  StateIds: arrayOf(number),
  States: arrayOf(STATE_TYPE),
  TagLine: string,
  Tiktok: string,
  TwitterLink: string,
  Website: string,
});

export const NEW_BUSINESS_DETAILS_TYPE = shape({
  BusinessId: number,
  DisplayName: string,
  Logo: string,
  BusinessType: NEW_BUSINESS_TYPE_TYPE,
  Address: string,
  State: NEW_BUSINESS_STATE,
  VerificationStatus: string,
  TotalActiveGigs: number,
});

export const GOOGLE_BUSINESS_DETAIL_TYPE = shape({
  Address: string,
  BusinessId: number,
  BusinessType: BUSINESS_TYPE_TYPE,
  BusinessTypeId: number,
  Displayname: string,
  Fullname: string,
  GroupCode: string,
  Latitude: number,
  Logo: MEDIA_TYPE,
  Longitude: number,
  State: string,
  TotalActiveGigs: number,
  Type: number,
});

const OPENING_HOURS_TYPE = shape({
  OpenNow: bool,
  Periods: arrayOf(
    shape({
      Open: shape({
        Day: number,
        Time: string,
      }),
      Close: shape({
        Day: number,
        Time: string,
      }),
    }),
  ),
  WeekdayTexts: arrayOf(string),
});

export const BUSINESS_PROFILE_TYPE = shape({
  Address: string,
  BusinessId: number,
  BusinessSourceId: number,
  BusinessType: BUSINESS_TYPE_TYPE,
  City: string,
  Country: string,
  Description: string,
  DisplayName: string,
  FollowerCount: number,
  GigCount: number,
  GooglePlaceId: string,
  IsPublic: bool,
  IsUserFollowingTheBusiness: bool,
  Latitude: number,
  Logo: MEDIA_TYPE,
  Longitude: number,
  OpeningHours: OPENING_HOURS_TYPE,
  Phone: string,
  PostalCode: string,
  RatingCount: number,
  State: string,
  VideoCount: number,
  Website: string,
});

export const BUSINESS_SOCIAL_MEDIA_TYPE = shape({
  FacebookLink: string,
  InstagramLink: string,
  TwitterLink: string,
});

export const BUSINESS_SUMMARY_TYPE = shape({
  ResultType: number,
  Latitude: number,
  Longitude: number,
  BusinessId: number,
  GooglePlaceId: string,
  DisplayName: string,
  FullName: string,
  Address: string,
  BusinessType: shape({
    Id: number,
    Name: string,
  }),
  TagLine: string,
  Logo: MEDIA_TYPE,
  ActiveGig: bool,
  MinPrizeAmount: number,
  MaxPrizeAmount: number,
  CommentCount: number,
  FollowerCount: number,
  OverallRatingPercent: number,
});

export const BUSINESS_NOMINATED_VIDEO_TYPE = shape({
  CorporateGroupId: number,
  DateOfNomination: string,
  NominatedGigId: number,
  NominatedGigPrizeId: number,
  NominatedId: number,
  NominatedVideoContentKey: string,
  NominatingMemberId: number,
});
