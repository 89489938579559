import './index.sass';

import moment from 'moment';
import { instanceOf } from 'prop-types';
import React from 'react';

import defaultProfilePhoto from '../../../assets/images/default-profile-photo.png';

const CommentsCard = (props) => {
  const { comment } = props;
  const profilePhoto =
    (comment &&
      comment.CommenterProfile &&
      comment.CommenterProfile.ProfilePhoto &&
      comment.CommenterProfile.ProfilePhoto.FullResolutionUrl) ||
    defaultProfilePhoto;
  const profileName =
    (comment && comment.CommenterProfile && comment.CommenterProfile.DisplayName) || '';

  return (
    <li className="CommentsCard__Item">
      <figure className="CommentsCard__Img">
        <img src={profilePhoto} alt="" />
      </figure>

      <div className="CommentsCard__Content">
        <div className="CommentsCard__Content__Info">
          <span className="CommentsCard__Content__Info__Name">{profileName}</span>
          <span className="CommentsCard__Content__Info__Date">
            {moment(new Date(comment.PostedOn)).format('L - LT')}
          </span>
        </div>

        <p className="CommentsCard__Content__Message">{comment.Comment}</p>
      </div>
    </li>
  );
};

CommentsCard.propTypes = {
  comment: instanceOf(Object).isRequired,
};

export default CommentsCard;
