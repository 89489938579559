import { bool, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getBusinessProfileNew } from '../../../actions/businesses';
import PlanCard from '../../../components/Cards/PlanCard';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';
import BusinessInfo from '../BusinessInfo';

function BusinessProfileTrial(props) {
  const [businessProfile, setBusinessProfile] = useState({});
  const [businessProfileError, setBusinessProfileError] = useState(null);
  const [isFetchingBusinessProfile, setIsFetchingBusinessProfile] = useState(false);
  const {
    isVerified,
    auth,
    match: {
      params: { corporateGroupId },
    },
    userRole,
  } = props;

  useEffect(() => {
    setIsFetchingBusinessProfile(true);
    getBusinessProfileNew(corporateGroupId).subscribe(
      setBusinessProfile,
      (err) => {
        setBusinessProfileError(err);
        setIsFetchingBusinessProfile(false);
      },
      () => setIsFetchingBusinessProfile(false),
    );
  }, [corporateGroupId]);

  return (
    <BusinessInfo
      auth={auth}
      businessProfile={businessProfile}
      businessProfileError={businessProfileError}
      corporateGroupId={corporateGroupId}
      isFetchingBusinessProfile={isFetchingBusinessProfile}
      membership={1}
      isVerified={isVerified}
      userRole={userRole}
    >
      <PlanCard
        bgDeep
        heading={'franki trial <span class="Subtitles">ends on May 18th 2019</span>'}
        text="take advantage and run some gigs to see what franki can do for you! when your free trial ends, you will revert to the free version. from there you can sign up for one of our membership plans to find the option that works best for your business."
        thumbSubtitles="days left"
        thumbTitle="12"
      />
    </BusinessInfo>
  );
}

BusinessProfileTrial.propTypes = {
  auth: bool.isRequired,
  match: ROUTER_MATCH_TYPE({ corporateGroupId: string.isRequired }).isRequired,
  userRole: string.isRequired,
  isVerified: bool.isRequired,
};

export default BusinessProfileTrial;
