import './index.sass';

import { node, string } from 'prop-types';
import React from 'react';

const InputWithIcon = (props) => {
  const { className, iconClassName, children } = props;
  return (
    <div className={`${className} InputWithIcon`}>
      {children}

      <i className={iconClassName} />
    </div>
  );
};

InputWithIcon.propTypes = {
  children: node.isRequired,
  className: string,
  iconClassName: string.isRequired,
};

InputWithIcon.defaultProps = {
  className: '',
};

export default InputWithIcon;
