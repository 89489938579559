import { func, number, shape, string } from 'prop-types';

import LOCATION_TYPE from './location';

const HISTORY_TYPE = shape({
  action: string,
  block: func,
  createHref: func,
  go: func,
  goBack: func,
  goForward: func,
  length: number,
  listen: func,
  location: LOCATION_TYPE,
  push: func,
  replace: func,
});

export default HISTORY_TYPE;
