import { bool, instanceOf, string } from 'prop-types';
import React from 'react';

import CustomizedCheckboxButton from '../../CustomizedCheckboxButton';

class FiltersModalButtonSelect extends React.Component {
  state = { value: [] };

  componentDidMount() {
    const { value } = this.props;
    this.updateState(value);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.updateState(value);
    }
  }

  updateState(value) {
    this.setState({ value });
  }

  render() {
    const { value } = this.state;
    const { disabled, onChange, label, name, options, className } = this.props;
    return (
      <fieldset className={className}>
        <legend>{label}</legend>
        <CustomizedCheckboxButton
          checkedItems={value}
          disabled={disabled}
          items={options}
          name={name}
          onChange={(newValue) => onChange(newValue)}
        />
      </fieldset>
    );
  }
}

FiltersModalButtonSelect.propTypes = {
  className: string,
  disabled: bool,
  label: string.isRequired,
  name: string.isRequired,
  options: instanceOf(Array),
  onChange: instanceOf(Function).isRequired,
  value: instanceOf(Array),
};

FiltersModalButtonSelect.defaultProps = {
  className: '',
  disabled: false,
  options: [],
  value: [],
};

export default FiltersModalButtonSelect;
