import './index.sass';

import { bool, func } from 'prop-types';
import React, { Component } from 'react';

import LightBox from '../../../../components/LightBox';

class BusinessInfoDeactivate extends Component {
  render() {
    const { onChange, show } = this.props;

    return (
      <LightBox
        className="BusinessInfoDeactivate"
        height={280}
        onClose={() => onChange('deactivateLightBox', false)}
        show={show}
        title="Attention!"
        width={435}
      >
        <p className="BusinessInfoDeactivate__Question">
          Are you sure you want to deativate this business?
        </p>

        <p className="BusinessInfoDeactivate__Description">
          this cannot be undone. please note: any content associated with deactivated business
          profiles will continue to display in the app.
        </p>

        <ul className="BusinessInfoDeactivate__Actions">
          <li>
            <button
              className="button small"
              onClick={() => this.onHandleChange('deactivateLightBox', false)}
              type="button"
            >
              no, cancel
            </button>
          </li>

          <li>
            <button className="button small red-theme" type="button">
              yes, deactivate
            </button>
          </li>
        </ul>
      </LightBox>
    );
  }
}

BusinessInfoDeactivate.propTypes = {
  onChange: func.isRequired,
  show: bool.isRequired,
};

export default BusinessInfoDeactivate;
