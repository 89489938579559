import './index.sass';

import { object, oneOfType } from 'prop-types';
import React from 'react';
import { VERIFICATION_STATUS } from '../../../BusinessInfo/BusinessInfoAbout';

const BusinessTitle = (props) => {
  const { businessProfile } = props;

  return (
    <div className="BusinessTitle">
      <h1 className="BusinessTitle__DisplayName">{businessProfile.Name || '-'}</h1>

      <div className="BusinessTitle__BusinessText">
        {businessProfile.Type
          && (businessProfile.Type.DisplayName
            || businessProfile.Type.DisplayString
            || businessProfile.Type.Name) && (
            <p className="BusinessTitle__BusinessType">
              {businessProfile.Type.DisplayName
                || businessProfile.Type.DisplayString
                || businessProfile.Type.Name}
              {businessProfile.Category
                && ` - ${
                  businessProfile.Category.DisplayName
                  || businessProfile.Category.DisplayString
                  || businessProfile.Category.Name
                  || businessProfile.Category
                }`}
            </p>
        )}

        {businessProfile.Id && (
        <p className="BusinessTitle__ID">
          ID:
          {' '}
          {businessProfile.Id}
        </p>
        )}
      </div>

      {businessProfile.VerificationStatus && (
        <div className="BusinessTitle__Status">
          <p className="BusinessTitle__Status__Text">
            {VERIFICATION_STATUS[businessProfile.VerificationStatus].text}
          </p>
          <img
            src={VERIFICATION_STATUS[businessProfile.VerificationStatus].icon}
            alt={VERIFICATION_STATUS[businessProfile.VerificationStatus].text}
          />
        </div>
      )}
    </div>
  );
};

BusinessTitle.propTypes = {
  businessProfile: oneOfType([object]).isRequired,
};

export default BusinessTitle;
