import { COLORS } from '../../config/colors';

const customSelectTheme = theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary: COLORS.orange,
    primary25: COLORS.lightOrange,
    primary50: COLORS.lightOrange,
    neutral10: COLORS.lightPurple,
    neutral50: COLORS.gray,
    neutral80: COLORS.gray,
  },
  spacing: {
    ...theme.spacing,
    controlHeight: 35,
  },
});

export default customSelectTheme;
