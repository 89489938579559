import moment from 'moment';
import { bool, instanceOf, string } from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';

import { Col, Row } from '../../Grid';

const DATE_FORMAT = 'MM/dd/yyyy';

class FiltersModalFromToDate extends React.Component {
  state = { fromDate: '', toDate: '' };

  componentDidMount() {
    const { fromDate, toDate } = this.props;
    this.updateState(fromDate, toDate);
  }

  componentDidUpdate(prevProps) {
    const { fromDate, toDate } = this.props;
    if (prevProps.fromDate !== fromDate || prevProps.toDate !== toDate) {
      this.updateState(fromDate, toDate);
    }
  }

  updateState(fromDate, toDate) {
    this.setState({ fromDate, toDate });
  }

  render() {
    const { fromDate, toDate } = this.state;
    const { className, disabled, onChange, label, fromId, toId } = this.props;

    return (
      <fieldset className={className}>
        <legend>{label}</legend>
        <Row cellSpacing={10}>
          <Col lg={6} md={6} sm={6} xs={12}>
            <label htmlFor={fromId}>From</label>
            <DatePicker
              calendarClassName="Franki-DatePicker"
              className="full"
              dateFormat={DATE_FORMAT}
              disabled={disabled}
              disabledKeyboardNavigation
              id={fromId}
              maxDate={new Date()}
              onChange={(value) => onChange(fromId, moment(new Date(value)).format('YYYY/MM/DD'))}
              placeholderText={DATE_FORMAT}
              selected={fromDate ? new Date(fromDate) : null}
            />
          </Col>

          <Col lg={6} md={6} sm={6} xs={12}>
            <label htmlFor={toId}>To</label>
            <DatePicker
              calendarClassName="Franki-DatePicker"
              className="full"
              dateFormat={DATE_FORMAT}
              disabled={disabled}
              disabledKeyboardNavigation
              id={toId}
              minDate={new Date(new Date(fromDate).setDate(new Date(fromDate).getDate() + 1))}
              onChange={(value) => onChange(toId, moment(new Date(value)).format('YYYY/MM/DD'))}
              placeholderText={DATE_FORMAT}
              popperPlacement="bottom-end"
              selected={toDate ? new Date(toDate) : null}
            />
          </Col>
        </Row>
      </fieldset>
    );
  }
}

FiltersModalFromToDate.propTypes = {
  className: string,
  disabled: bool,
  fromDate: string.isRequired,
  fromId: string.isRequired,
  label: string.isRequired,
  onChange: instanceOf(Function).isRequired,
  toDate: string.isRequired,
  toId: string.isRequired,
};

FiltersModalFromToDate.defaultProps = {
  className: '',
  disabled: false,
};

export default FiltersModalFromToDate;
