import './index.sass';

import { isEmpty } from 'lodash';
import moment from 'moment';
import { instanceOf, number } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import uuidv1 from 'uuid/v1';

import { POST_REVIEW_REASONING } from '../../config/gig-status';
import CommentsCard from '../Cards/Comments';
import LikesCard from '../Cards/Likes';

const LikesCommentsSavings = (props) => {
  const { savingsCount, comments, statusHistory = [], likes, height } = props;

  const [showFavCount, setShowFavCount] = useState(false);
  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes('/admin/videos/')) {
      setShowFavCount(false);
    } else if (path.includes('/admin/business-video-details/')) {
      setShowFavCount(true);
    } else if (path.includes('/admin/business-gig-details/')) {
      setShowFavCount(true);
    }
  }, []);

  return (
    <div className="LikesCommentsSavings">
      {showFavCount && !!savingsCount && (
        <div className="LikesCommentsSavings__Savings">
          {`${savingsCount} `}
          <i className="icon-save" />
        </div>
      )}

      <Tabs className="LikesCommentsSavings__Tabs" defaultIndex={showFavCount ? 1 : 2}>
        <TabList>
          <Tab>
            {likes ? likes.length || 0 : 0}
            <i className="icon-heart" />
          </Tab>
          <Tab>
            {comments ? comments.length || 0 : 0}
            <i className="icon-comment" />
          </Tab>
          {!showFavCount && (
            <Tab>
              {statusHistory ? statusHistory.length || 0 : 0}
              <i className="icon-flag" />
            </Tab>
          )}
        </TabList>

        <TabPanel>
          <ul className="LikesCommentsSavings__Content" style={{ height }}>
            {!isEmpty(likes) ? (
              likes.map((item) => <LikesCard key={uuidv1()} item={item} />)
            ) : (
              <li>no likes yet</li>
            )}
          </ul>
        </TabPanel>

        <TabPanel>
          <ul className="LikesCommentsSavings__Content" style={{ height }}>
            {!isEmpty(comments) ? (
              comments.map((comment) => <CommentsCard key={uuidv1()} comment={comment} />)
            ) : (
              <li>no comments yet </li>
            )}
          </ul>
        </TabPanel>
        {!showFavCount && (
          <TabPanel>
            <div style={{ height: '290px', overflow: 'auto' }}>
              {statusHistory.length == 0 && <div>No data to show</div>}
              {statusHistory.length > 0 && (
                <table className="table-border" style={{ borderCollapse: 'unset' }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          color: '#828282',
                          fontWeight: 'bold',
                          backgroundColor: '#F0F0F0',
                        }}
                      >
                        Date
                      </th>
                      <th
                        style={{
                          color: '#828282',
                          fontWeight: 'bold',
                          backgroundColor: '#F0F0F0',
                        }}
                      >
                        User
                      </th>
                      <th
                        style={{
                          color: '#828282',
                          fontWeight: 'bold',
                          backgroundColor: '#F0F0F0',
                        }}
                      >
                        Flag Status
                      </th>
                      <th
                        style={{
                          color: '#828282',
                          fontWeight: 'bold',
                          backgroundColor: '#F0F0F0',
                        }}
                      >
                        Comment
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {statusHistory.map(({ Date, User, Comment, UpdatedStatus, ReasonId }) => {
                      const dateStr = moment(Date).format('DD/MM/YYYY HH:mm');
                      return (
                        <tr>
                          <td
                            style={{
                              padding: '10px !important',
                              color: '#828282',
                              fontSize: '1.4rem',
                            }}
                          >
                            {dateStr}
                          </td>
                          <td
                            style={{
                              padding: '10px !important',
                              color: '#828282',
                              fontSize: '1.4rem',
                            }}
                          >
                            {User && User.Fullname}
                          </td>
                          <td
                            style={{
                              padding: '10px !important',
                              color: '#828282',
                              fontSize: '1.4rem',
                            }}
                          >
                            {UpdatedStatus.Name}
                          </td>
                          <td
                            style={{
                              padding: '10px !important',
                              color: '#828282',
                              fontSize: '1.4rem',
                            }}
                          >
                            {(Comment && Comment.Name) ||
                              (ReasonId &&
                                POST_REVIEW_REASONING.filter((f) => f.value === ReasonId)[0].label)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
};

LikesCommentsSavings.propTypes = {
  savingsCount: number,
  comments: instanceOf(Array),
  likes: instanceOf(Array),
  statusHistory: instanceOf(Array),
  height: number,
};

LikesCommentsSavings.defaultProps = {
  savingsCount: null,
  comments: [],
  likes: [],
  statusHistory: [],
  height: 400,
};

export default LikesCommentsSavings;
