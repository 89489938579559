import { shape, string } from 'prop-types';

const LOCATION_TYPE = shape({
  hash: string,
  key: string,
  pathname: string,
  search: string,
});

export default LOCATION_TYPE;
