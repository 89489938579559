import { bool, instanceOf, string } from 'prop-types';
import React from 'react';

import Forbidden from './Forbidden';
import PrivatePage from './PrivatePage';

const Page404 = (props) => {
  const { auth, routerPermission, userRole } = props;

  if (auth && userRole && !routerPermission.includes(userRole)) {
    return <Forbidden auth={auth} />;
  }

  if (!auth && routerPermission.length) {
    return <PrivatePage auth={auth} />;
  }

  return null;
};

Page404.propTypes = {
  auth: bool.isRequired,
  routerPermission: instanceOf(Array).isRequired,
  userRole: string,
};

Page404.defaultProps = {
  userRole: null,
};

export default Page404;
