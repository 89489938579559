import './index.sass';

import React from 'react';
import { bool, func } from 'prop-types';

import { Col, Row } from '../../../../../components/Grid';
import { BUSINESS_DETAILS_TYPE } from '../../../../../types/business';
import { getPlaceIdProp } from '../../../../../utils/placeId';
import USER_ROLES from '../../../../../config/user-roles';

const Address = (props) => {
  const { clearBusinessLocation, formState, onHandleFormChange, addressDisabled } = props;
  const userRole = localStorage.getItem('frankiRole');

  const placeIdProp = getPlaceIdProp();

  const onInputValueChange = (event) => {
    const {
      target: { name, value },
    } = event;
    onHandleFormChange(name, value);
  };

  return (
    <div className="Address">
      <div className="BusinessEdit__Blocks">
        <Row cellSpacing={15} rowSpacing={10}>
          <></>
          {[USER_ROLES.businessManager].includes(userRole) ? (
            <Col lg={12} md={12} sm={12} xs={12}>
              <label htmlFor="txtBusinessAddressLocation1">
                Business address
                <span className="required-asterisk">*</span>
              </label>

              <input
                autoComplete="none"
                className="full"
                disabled={addressDisabled}
                id="txtBusinessAddressLocation1"
                maxLength={60}
                minLength={3}
                name="Address"
                onChange={onInputValueChange}
                placeholder="street and number, P.O Box, c/o."
                required
                type="text"
                value={formState.Address || ''}
              />
            </Col>
          ) : (
            <></>
          )}
          {![USER_ROLES.businessManager].includes(userRole) ? (
            <Col lg={4} md={4} sm={6} xs={12}>
              <label htmlFor="txtBusinessAddressLocation1">
                Business address
                <span className="required-asterisk">*</span>
              </label>

              <input
                autoComplete="none"
                className="full"
                disabled={addressDisabled}
                id="txtBusinessAddressLocation1"
                maxLength={60}
                minLength={3}
                name="Address"
                onChange={onInputValueChange}
                placeholder="street and number, P.O Box, c/o."
                required
                type="text"
                value={formState.Address || ''}
              />
            </Col>
          ) : (
            <></>
          )}

          {![USER_ROLES.businessManager].includes(userRole) ? (
            <Col lg={4} md={4} sm={6} xs={12}>
              <label htmlFor="txtBusinessAddressPlaceID">Foursquare Place ID</label>

              <input
                className="full"
                disabled
                id="txtBusinessAddressPlaceID"
                name={placeIdProp.api}
                type="text"
                value={formState[placeIdProp.api] || ''}
              />
            </Col>
          ) : (
            <></>
          )}
          <Col lg={4} md={4} sm={6} xs={12}>
            <label htmlFor="txtBusinessAddressCity">Neighborhood</label>

            <input
              autoComplete="none"
              className="full"
              disabled={addressDisabled}
              id="txtBusinessAddressNeighborhood"
              name="Neighborhood"
              onChange={onInputValueChange}
              type="text"
              value={formState.Neighborhood || ''}
            />
          </Col>
          <Col lg={4} md={4} sm={6} xs={12}>
            <label htmlFor="txtBusinessAddressCity">
              City
              <span className="required-asterisk">*</span>
            </label>

            <input
              autoComplete="none"
              className="full"
              disabled={addressDisabled}
              id="txtBusinessAddressCity"
              name="City"
              onChange={onInputValueChange}
              required
              type="text"
              value={formState.City || ''}
            />
          </Col>

          <Col lg={4} md={4} sm={6} xs={12}>
            <label htmlFor="txtBusinessAddressState">
              State
              <span className="required-asterisk">*</span>
            </label>

            <input
              autoComplete="none"
              className="full"
              disabled={addressDisabled}
              id="txtBusinessAddressState"
              name="State"
              onChange={onInputValueChange}
              required
              type="text"
              value={formState.State || ''}
            />
          </Col>

          <Col lg={4} md={4} sm={6} xs={12}>
            <label htmlFor="txtBusinessAddressCountry">
              Country
              <span className="required-asterisk">*</span>
            </label>

            <input
              autoComplete="none"
              className="full"
              disabled={addressDisabled}
              id="txtBusinessAddressCountry"
              name="Country"
              onChange={onInputValueChange}
              required
              type="text"
              value={formState.Country || ''}
            />
          </Col>

          <Col lg={4} md={4} sm={6} xs={12}>
            <label htmlFor="txtBusinessAddressZipCode">
              Zip code
              <span className="required-asterisk">*</span>
            </label>

            <input
              autoComplete="none"
              className="full"
              disabled={addressDisabled}
              id="txtBusinessAddressZipCode"
              maxLength={60}
              minLength={3}
              name="Zipcode"
              onChange={onInputValueChange}
              required
              type="text"
              value={formState.Zipcode || ''}
            />
          </Col>

          <Col lg={4} md={4} sm={6} xs={12}>
            <label htmlFor="txtBusinessAddressLatitude">
              Latitude
              {![USER_ROLES.businessManager].includes(userRole) ? (
                <span className="required-asterisk">*</span>
              ) : (
                <></>
              )}
            </label>

            <input
              autoComplete="none"
              className="full"
              disabled={addressDisabled || [USER_ROLES.businessManager].includes(userRole)}
              id="txtBusinessAddressLatitude"
              maxLength={60}
              name="Latitude"
              onChange={onInputValueChange}
              required={![USER_ROLES.businessManager].includes(userRole)}
              step="any"
              type="number"
              value={[null, undefined, ''].includes(formState.Latitude) ? '' : formState.Latitude}
            />
          </Col>

          <Col lg={4} md={4} sm={6} xs={12}>
            <label htmlFor="txtBusinessAddressLongitude">
              Longitude
              {![USER_ROLES.businessManager].includes(userRole) ? (
                <span className="required-asterisk">*</span>
              ) : (
                <></>
              )}
            </label>

            <input
              autoComplete="none"
              className="full"
              disabled={addressDisabled || [USER_ROLES.businessManager].includes(userRole)}
              id="txtBusinessAddressLongitude"
              maxLength={60}
              name="Longitude"
              onChange={onInputValueChange}
              required={![USER_ROLES.businessManager].includes(userRole)}
              step="any"
              type="number"
              value={[null, undefined, ''].includes(formState.Longitude) ? '' : formState.Longitude}
            />
          </Col>

          <Col lg={12} md={12} sm={12} xs={12} className="Address__Contact">
            <div className="BusinessAddressForm__Content display-block">
              <span className="BusinessAddressForm__Title w-content">
                Have more than one storefront?
              </span>
              <div className="BusinessAddressForm__Text w-content">
                Please reach out to a representative to get them all set up at once!
              </div>
              <a
                className="BusinessAddressForm__Text BusinessAddressForm__Text--CTA w-content"
                href="mailto:customersupport@befranki.com"
              >
                CONTACT SUPPORT
              </a>
            </div>

            <button
              className="button black-theme small Address__ClearButton"
              disabled={addressDisabled}
              onClick={() => clearBusinessLocation()}
              type="button"
            >
              Clear
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

Address.propTypes = {
  clearBusinessLocation: func.isRequired,
  formState: BUSINESS_DETAILS_TYPE.isRequired,
  onHandleFormChange: func.isRequired,
  addressDisabled: bool,
};
Address.defaultProps = {
  addressDisabled: false,
};

export default Address;
