import { ADD_PATH, BACK } from '../actions/path';

const initialState = {
  paths: [],
};

export default function (state = initialState, { type, path }) {
  switch (type) {
    case ADD_PATH: {
      return {
        ...state,
        paths: [...state.paths, path],
      };
    }

    case BACK: {
      return {
        ...state,
        paths: state.paths.slice(0, -1),
      };
    }

    default:
      return state;
  }
}
