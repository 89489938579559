import './index.sass';

import { func, string } from 'prop-types';
import React, { useState } from 'react';

import { verifyByIdentification } from '../../../actions/businesses';
import selectAFeaturedVideo from '../../../assets/images/select-a-featured-video.svg';
import AsyncContent from '../../../components/AsyncContent';
import FileUpload from '../../../components/FileUpload';

export default function BusinessVerificationForm(props) {
  const { businessId, onFinishSubmitting } = props;
  const [identification, setIdentification] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  function onHandleSubmit(event) {
    event.preventDefault();
    setErrorMessage('');

    if (identification) {
      setIsSubmitting(true);
      verifyByIdentification(businessId, { Image: identification }).subscribe(
        onFinishSubmitting,
        (err) => {
          setErrorMessage(err);
          setIsSubmitting(false);
        },
        () => {
          setIsSubmitting(false);
        },
      );
    } else {
      setErrorMessage('Mandatory fields have not been completed. Please try again');
    }
  }
  return (
    <AsyncContent
      loading={isSubmitting}
      content={() => (
        <form className="BusinessVerificationForm" onSubmit={onHandleSubmit}>
          <label htmlFor="txtIdentification" className="BusinessVerificationForm__Label">
            Upload valid ID document such as drivers license, passport, or any other approved Photo
            ID
            <em>*</em>
          </label>
          <FileUpload
            accept=".png, .PNG, .jpg, .JPG, .jpeg, .JPEG"
            description={
              <>
                <img
                  src={selectAFeaturedVideo}
                  alt="+"
                  className="BusinessVerificationForm__Upload__PlusIcon"
                />

                <div className="BusinessVerificationForm__Upload__ContentContainer">
                  <div className="BusinessVerificationForm__Upload__Title">
                    Upload Government Photo ID
                  </div>
                  <div className="BusinessVerificationForm__Upload__Content">
                    Drop your JPG or PNG file here or <u>browse</u>
                  </div>
                </div>
              </>
            }
            disabled={isSubmitting}
            fullWidth
            id="txtFile"
            maxFileSize={4}
            name="image"
            onChange={(_, value) => {
              setIdentification(value);
              setErrorMessage('');
            }}
            placeholder=""
            type="image"
            value={identification}
          />
          <button type="submit" className="button orange-theme BusinessPhoneVerification__Confirm">
            CONFIRM
          </button>

          <div className="error">{errorMessage}</div>
        </form>
      )}
      expectNoData
    />
  );
}

BusinessVerificationForm.propTypes = {
  businessId: string.isRequired,
  onFinishSubmitting: func.isRequired,
};
