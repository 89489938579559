import './index.sass';

import { isEmpty } from 'lodash';
import { bool, func, instanceOf, node, number, oneOfType, string } from 'prop-types';
import React from 'react';

import LightBox from '../LightBox';
import Loader from '../Loader';
import NotFound from '../NotFound';

class AsyncContent extends React.PureComponent {
  render() {
    const {
      height,
      content,
      emptyPageContent,
      errorMessage,
      expectedData,
      expectNoData,
      loading,
      onHandleCloseDialog,
      successMessage,
      customStyle,
    } = this.props;
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            {isEmpty(expectedData) && !expectNoData
              ? emptyPageContent || (
                  <NotFound
                    description="We cannot find anything based on your search criteria"
                    title="No results"
                  />
                )
              : content(expectedData)}

            <LightBox
              style={
                customStyle || {
                  width: 600,
                  height,
                  marginLeft: -250,
                  marginTop: -200,
                }
              }
              onClose={onHandleCloseDialog}
              show={!!errorMessage || !!successMessage}
              title={errorMessage ? 'Error' : 'Success'}
            >
              <div className="AsyncContent__ErrorMessage">{errorMessage}</div>
              <div className="AsyncContent__SuccessMessage">{successMessage}</div>
            </LightBox>
          </>
        )}
      </>
    );
  }
}

AsyncContent.propTypes = {
  content: func.isRequired,
  height: oneOfType([instanceOf(Object), number, string]),
  emptyPageContent: node,
  errorMessage: string,
  expectedData: oneOfType([instanceOf(Object), instanceOf(Array)]),
  expectNoData: bool,
  loading: bool,
  onHandleCloseDialog: func,
  successMessage: string,
  customStyle: oneOfType([instanceOf(Object), number, string]),
};

AsyncContent.defaultProps = {
  emptyPageContent: null,
  height: null,
  errorMessage: '',
  expectedData: null,
  expectNoData: false,
  loading: false,
  onHandleCloseDialog: () => {},
  successMessage: '',
  customStyle: null,
};

export default AsyncContent;
