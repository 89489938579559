import CryptoJS from 'crypto-js';

const blockSize = 128;
const key = 'a9855f1ad2873d2af81cde4ca61ea5f0';

const randomIVArray = CryptoJS.lib.WordArray.random(blockSize / 8);

const encrypt = (text) => {
  const inputArray = CryptoJS.enc.Utf8.parse(text);
  const keyArray = CryptoJS.enc.Utf8.parse(key);

  const encrypted = CryptoJS.AES.encrypt(inputArray, keyArray, {
    iv: randomIVArray,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  }).ciphertext.toString(CryptoJS.enc.Base64);

  return {
    iv64: CryptoJS.enc.Base64.stringify(randomIVArray),
    password64: encrypted,
  };
};

export default encrypt;
