import './index.sass';

import moment from 'moment';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import Amex from '../../../../assets/images/payment_flags/amex.png';
import Dinners from '../../../../assets/images/payment_flags/dinners.png';
import Discover from '../../../../assets/images/payment_flags/discover.png';
import MasterCard from '../../../../assets/images/payment_flags/master.png';
import Visa from '../../../../assets/images/payment_flags/visa.png';
import AsyncContent from '../../../../components/AsyncContent';
import CreditCardForm from '../../../../components/CreditCardForm';
import StepsHeader from '../../../../components/StepsHeader';

// FLAGS
class Payment extends React.Component {
  onHandleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const {
      formFields: { cardNumber, expirationYear, expirationMonth, cvv, plan, planPrice },
      onHandleChange,
      onHandleFormChange,
      onHandleSubmit,
    } = this.props;

    return (
      <>
        <Helmet title="Franki Admin: payment" />

        <div className="payment">
          <StepsHeader
            step={3}
            title="enter your payment details"
            subtitle={`remember, you have a 30 day free trial! <br />
              we won’t charge this card until
              ${moment(new Date(new Date().setDate(new Date().getDate() + 30))).format('MMMM Do')}
            `}
          />

          <AsyncContent
            content={() => (
              <form className="payment-form" method="POST" onSubmit={(e) => onHandleSubmit(e)}>
                <div className="payment-flags">
                  <span>we accept all major debit and credit cards.</span>
                  <ul className="payment-flags-list">
                    <li>
                      <img src={MasterCard} alt="masterCard" />
                    </li>
                    <li>
                      <img src={Visa} alt="visa" />
                    </li>
                    <li>
                      <img src={Amex} alt="amex" />
                    </li>
                    <li>
                      <img src={Discover} alt="discover" />
                    </li>
                    <li>
                      <img src={Dinners} alt="dinners" />
                    </li>
                  </ul>
                </div>

                <CreditCardForm
                  cardNumber={cardNumber}
                  expirationYear={expirationYear}
                  expirationMonth={expirationMonth}
                  cvv={cvv}
                  onHandleChange={onHandleFormChange}
                />

                <div className="payment-plan">
                  <span className="payment-plan-label">plan selected</span>
                  <div className="payment-plan-details">
                    <span>
                      <strong>{plan}</strong>
                      {` $${planPrice} / month`}
                    </span>
                    <button
                      type="button"
                      onClick={() => {
                        onHandleChange('step', 1);
                        onHandleChange('planHasBeingChanged', true);
                      }}
                    >
                      change plan
                    </button>
                  </div>
                </div>

                <div className="payment-terms">
                  <p>
                    {
                      'Franki currently services selected areas in the United States. By clicking the '
                    }
                    <strong>&quot;Start Membership&quot;</strong>
                    {'button below, you agree to our '}
                    <a href="https://befranki.com/terms-of-service/" target="_blank">
                      Terms of Service
                    </a>
                    {`, and that you are over 21. You may cancel at any time in your free
                    trial and will not be charged. To cancel, contact us at `}
                    <a href="mailto:customersupport@befranki.com">customersupport@befranki.com</a>
                    {'.'}
                  </p>

                  <p>
                    {`Franki will automatically continue your membership at the end of your
                    free trial and charge the membership fee to your payment method
                    on a monthly basis until you cancel. There are no refunds or credits
                    for partial months.`}
                  </p>
                </div>

                <button type="submit" className="button orange-theme">
                  start your membership
                </button>
              </form>
            )}
            errorMessage="Your card information is incorrect. Please double check the information entered or add another payment method."
            expectNoData
          />
        </div>
      </>
    );
  }
}

Payment.propTypes = {
  formFields: instanceOf(Object).isRequired,
  onHandleSubmit: func.isRequired,
  onHandleChange: func.isRequired,
  onHandleFormChange: func.isRequired,
};

export default Payment;
