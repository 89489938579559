import { includes, isEmpty } from 'lodash';

const addSearchTermToFilters = (searchTerm, currentFilters) => {
  const { searchList } = currentFilters;
  if (!includes(searchList, searchTerm)) {
    searchList.push(searchTerm);
  }

  const updatedFilters = {
    ...currentFilters,
    searchList: isEmpty(searchList) ? [] : searchList,
    search: isEmpty(searchList) ? '' : searchList.join(','),
  };

  return updatedFilters;
};

export default addSearchTermToFilters;
