import { isEmpty } from 'lodash';
import { arrayOf, bool, func, oneOf } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import uuidv1 from 'uuid/v1';

import {
  assignBusinessVideoToPrizes,
  createVideoNomination,
  deleteNominatedVideo,
  getNominatedVideos as getNominatedVideosAction,
  getVideoDetails as getVideoDetailsAction,
} from '../../../../../actions/businesses';
import AlertMessages from '../../../../../components/AlertMessages';
import BusinessVideoDetails from '../../../../../components/BusinessVideoDetails';
import NomineesCard from '../../../../../components/Cards/Nominees';
import SubmissionsCard from '../../../../../components/Cards/Submissions';
import CustomizedCheckboxButton from '../../../../../components/CustomizedCheckboxButton';
import { Col, Row } from '../../../../../components/Grid';
import LightBox from '../../../../../components/LightBox';
import LikesCommentsSavings from '../../../../../components/LikesCommentsSavings';
import Loader from '../../../../../components/Loader';
import TagsList from '../../../../../components/TagsList';
import VideoDetailsHeader from '../../../../../components/VideoDetailsHeader';
import { BUSINESS_NOMINATED_VIDEO_TYPE } from '../../../../../types/business';
import { GIG_PRIZE_TYPE } from '../../../../../types/gig';
import VIDEO_TYPE from '../../../../../types/video';

const submissionCardBookmarkMenu = () => [
  {
    innerContent: (
      <label>
        <input name="bookmarks" type="checkbox" value="mostCreative" />
        most creative
      </label>
    ),
  },
  {
    innerContent: (
      <label>
        <input name="bookmarks" type="checkbox" value="firstPlace" />
        first place
      </label>
    ),
  },
  {
    innerContent: (
      <label>
        <input type="checkbox" name="bookmarks" value="secondPlace" />
        second place
      </label>
    ),
  },
  {
    innerContent: <button type="button">save</button>,
  },
];

const createPrizeCategoryList = (prizes) =>
  prizes.map(({ label, amount, value }) => ({
    label: `
      <span class="CustomizedCheckboxButton__Label">${label}</span>
      <span class="CustomizedCheckboxButton__Value">${amount} remaining</span>
    `,
    value,
  }));

const createPrizeItem = (prize) => ({
  amount: prize.WinnerQty,
  label: prize.Description,
  value: prize.GigPrizeId,
});

const createAwardPrizeItem = (prize) => ({
  amount: prize.WinnerQty,
  label: `$ ${prize.PrizeAmount} ${prize.Description}`,
  value: prize.GigPrizeId,
});

class VideoList extends React.Component {
  state = {
    prizesToNominate: [],
    prizesToAward: [],

    awardedGigPrizes: [],
    nominatedGigPrizes: [],

    selectedToAwardPrizeIds: [],
    selectedToNominatePrizeIds: [],

    isAwardCategoriesVisible: false,
    isAwardDialogVisible: false,
    isNominateCategoriesVisible: false,
    isVideoDetailsDialogVisible: false,
    errorMessage: '',
  };

  componentDidUpdate(prevProps) {
    const { videoDetails, isFetchingVideoDetails } = this.props;

    if (!isFetchingVideoDetails && prevProps.isFetchingVideoDetails) {
      if (videoDetails.ContentId && prevProps.videoDetails.ContentId !== videoDetails.ContentId) {
        this.updatePrizeLists();
      }
    }
  }

  onHandleAwardSelect(items) {
    this.setState({ selectedToAwardPrizeIds: items });
    const { prizesToAward } = this.state;

    const awardedGigPrizes = [];
    items.forEach((item) => {
      const gigPrize = prizesToAward.find((prize) => prize.id === item);

      if (gigPrize) {
        const { GigPrizeId, PrizeAmount, Description } = gigPrize;
        awardedGigPrizes.push({
          value: GigPrizeId,
          label: `$ ${PrizeAmount} ${Description}`,
        });
      }
    });

    this.setState({ awardedGigPrizes });
  }

  async onHandleRemoveNominatedItems(item) {
    const { businessNominatedVideos } = this.props;
    const matchedVideo = businessNominatedVideos.find(
      ({ NominatedGigPrizeId }) => NominatedGigPrizeId === item.value,
    );
    if (!matchedVideo) {
      return;
    }
    const { NominatedId, CorporateGroupId, NominatedVideoContentKey } = matchedVideo;

    const params = {
      NominatedId,
      CorporateGroupId,
      NominatedVideoContentKey,
    };

    const result = await deleteNominatedVideo(params);
    if (!result.Success) {
      this.setState({ errorMessage: result.Message });
      return;
    }
    const { nominatedGigPrizes, isNominateCategoriesVisible, prizesToNominate } = this.state;
    const updatedList = nominatedGigPrizes.filter(({ value }) => value !== item.value);
    prizesToNominate.push(item);
    this.setState({ nominatedGigPrizes: updatedList, prizesToNominate });

    if (isNominateCategoriesVisible) {
      this.setState({ selectedToNominatePrizeIds: updatedList.map(({ value }) => value) });
    }
  }

  async onHandleNominate() {
    const { selectedToNominatePrizeIds, nominatedGigPrizes } = this.state;

    if (!selectedToNominatePrizeIds) {
      return;
    }

    const { gigPrizes, videoDetails } = this.props;
    const newNominatedPrizes = [];
    const newNominatedPrizesIds = [];

    selectedToNominatePrizeIds.forEach((item) => {
      const prize = gigPrizes.find(({ GigPrizeId }) => GigPrizeId.toString() === item);

      if (prize && !nominatedGigPrizes.find(({ value }) => value.toString() === item)) {
        newNominatedPrizes.push(createPrizeItem(prize));
        newNominatedPrizesIds.push(prize.GigPrizeId);
      }
    });

    const { CorporateGroup, GigId, PosterProfile, Key } = videoDetails;
    const params = {
      CorporateGroupId: CorporateGroup.CorporateGroupId,
      NominatedGigId: GigId,
      NominatedGigPrizeIdList: newNominatedPrizesIds,
      NominatingMemberId: PosterProfile.UserId,
      NominatedVideoContentKey: Key,
    };

    const result = await createVideoNomination(params);

    if (!result) {
      return;
    }

    if (result.Success) {
      this.setState(() => ({
        nominatedGigPrizes: newNominatedPrizes,
        isNominateCategoriesVisible: false,
        errorMessage: '',
      }));
      this.getVideoDetails(videoDetails.Key);
    } else {
      this.setState({
        errorMessage: result.Message,
      });
    }
  }

  async onHandleAward(e) {
    e.preventDefault();
    this.setState({
      errorMessage: '',
    });
    const { videoDetails, gigPrizes, onUpdate } = this.props;
    const { selectedToAwardPrizeIds } = this.state;

    if (!selectedToAwardPrizeIds) {
      return;
    }

    const gigPrizeIds = selectedToAwardPrizeIds.join(',');
    const awardedGigPrizes = [];
    selectedToAwardPrizeIds.forEach((item) => {
      const prize = gigPrizes.find(({ GigPrizeId }) => GigPrizeId === item);
      if (prize) {
        awardedGigPrizes.push(createAwardPrizeItem(prize));
      }
    });

    const result = await assignBusinessVideoToPrizes(gigPrizeIds, videoDetails.Key);

    if (result && result.Success) {
      this.setState({
        awardedGigPrizes,
        errorMessage: '',
        isAwardDialogVisible: false,
        isAwardCategoriesVisible: false,
        selectedToAwardPrizeIds: [],
      });
      this.getVideoDetails(videoDetails.Key);
      onUpdate();
    } else {
      this.setState({
        errorMessage: result.Message,
      });
    }
  }

  onHandleCancelChoosing() {
    this.setState({
      isNominateCategoriesVisible: false,
      isAwardDialogVisible: false,
      isAwardCategoriesVisible: false,
      selectedToNominatePrizeIds: [],
      selectedToAwardPrizeIds: [],
      errorMessage: '',
    });
  }

  onVideoCardClick(item) {
    let key = item.Key;
    if (item.VideoInfo) key = item.VideoInfo.Key;
    this.setState({ isVideoDetailsDialogVisible: true });
    this.getVideoDetails(key);
  }

  async getVideoDetails(key) {
    const { getVideoDetails, getNominatedVideos } = this.props;
    this.setState({ loadingVideoDetails: true });
    await getVideoDetails(key);
    this.setState({ loadingVideoDetails: false });
    getNominatedVideos(key);
  }

  updatePrizeLists() {
    const { videoDetails, gigPrizes } = this.props;
    const { NominatedGigPrizes, AwardedGigPrizes } = videoDetails;

    let prizesToNominate = gigPrizes.map(createPrizeItem);
    let nominatedGigPrizes = [];
    let awardedGigPrizes = [];

    if (NominatedGigPrizes) {
      NominatedGigPrizes.forEach(({ GigPrizeId }) => {
        prizesToNominate = prizesToNominate.filter(({ value }) => value !== GigPrizeId);
      });
      nominatedGigPrizes = NominatedGigPrizes.map(createPrizeItem);
    }

    if (AwardedGigPrizes) {
      AwardedGigPrizes.forEach(({ GigPrizeId }) => {
        nominatedGigPrizes = nominatedGigPrizes.filter(({ value }) => value !== GigPrizeId);
        prizesToNominate = prizesToNominate.filter(({ value }) => value !== GigPrizeId);
      });

      awardedGigPrizes = AwardedGigPrizes.map(createAwardPrizeItem);
    }

    const prizesToAward = nominatedGigPrizes;

    this.setState({
      awardedGigPrizes,
      nominatedGigPrizes,
      prizesToNominate,
      prizesToAward,
    });
  }

  isCategoriesShowing() {
    const { isAwardCategoriesVisible, isNominateCategoriesVisible } = this.state;
    return isAwardCategoriesVisible || isNominateCategoriesVisible;
  }

  render() {
    const {
      awardedGigPrizes,
      selectedToAwardPrizeIds,
      errorMessage,
      isAwardCategoriesVisible,
      isAwardDialogVisible,
      isNominateCategoriesVisible,
      isVideoDetailsDialogVisible,
      loadingVideoDetails,
      nominatedGigPrizes,
      selectedToNominatePrizeIds,
      prizesToAward,
      prizesToNominate,
    } = this.state;

    const { gigPrizes, handleModalAward, playable, type, videoDetails, videos } = this.props;

    let totalAwarded = 0;
    awardedGigPrizes.forEach(({ value }) => {
      gigPrizes.forEach((prize) => {
        if (prize.GigPrizeId === value) {
          totalAwarded += prize.PrizeAmount;
        }
      });
    });

    return (
      <>
        <Row cellSpacing={10} rowSpacing={10} className="row">
          {videos.map((item, i) => (
            <Col
              lg={type === 'submissions' ? 3 : 2}
              md={type === 'submissions' ? 4 : 3}
              sm={6}
              xs={12}
              key={uuidv1()}
            >
              {type === 'submissions' ? (
                <SubmissionsCard
                  bookmarkItems={submissionCardBookmarkMenu()}
                  item={item}
                  onClick={() => this.onVideoCardClick(item)}
                  playable={playable}
                />
              ) : (
                <NomineesCard
                  item={item}
                  onHandleAward={() => handleModalAward(true, videos[i])}
                  onClick={() => this.onVideoCardClick(item)}
                />
              )}
            </Col>
          ))}
        </Row>

        <LightBox
          height={768}
          onClose={() => this.setState({ isVideoDetailsDialogVisible: false })}
          show={isVideoDetailsDialogVisible}
          showCloseButton={false}
          spacing={0}
          width={1120}
        >
          <div className="GigSubmissions__LightBox">
            {loadingVideoDetails ? (
              <Loader />
            ) : (
              <>
                <VideoDetailsHeader videoDetails={videoDetails} />

                <Row rowSpacing={25} cellSpacing={15}>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <BusinessVideoDetails videoDetails={videoDetails} />
                  </Col>

                  <Col lg={6} md={6} sm={12} xs={12}>
                    <LikesCommentsSavings
                      likes={videoDetails.Likes}
                      comments={videoDetails.Comments}
                      savingsCount={videoDetails.FavoriteCount}
                    />

                    <div className="GigSubmissions__NominatedTo">
                      <span className="GigSubmissions__NominatedTo__Title">NOMINATED TO</span>

                      {!isEmpty(videoDetails.NominatedGigPrizes) && (
                        <TagsList
                          tags={nominatedGigPrizes}
                          onRemove={(prize) => this.onHandleRemoveNominatedItems(prize)}
                        />
                      )}
                    </div>

                    <div className="GigSubmissions__Prizes">
                      <span className="GigSubmissions__Prizes__Title">
                        Prizes awarded
                        <span className="GigSubmissions__Prizes__TotalPrize">
                          {`total = $${totalAwarded}`}
                        </span>
                      </span>

                      {!isEmpty(awardedGigPrizes) && (
                        <TagsList tags={awardedGigPrizes} className="tags-award" />
                      )}
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>

          {!loadingVideoDetails && (
            <div
              className={`GigSubmissions__Nominate ${
                this.isCategoriesShowing() || isAwardDialogVisible
                  ? 'GigSubmissions__Nominate--active'
                  : ''
              }`}
            >
              {this.isCategoriesShowing() && (
                <>
                  <span className="GigSubmissions__Nominate__Title">CATEGORIES</span>

                  <div
                    className={`GigSubmissions__Nominate__Categories ${
                      isAwardCategoriesVisible ? 'GigSubmissions__Nominate__Categories--award' : ''
                    }`}
                  >
                    {(isAwardCategoriesVisible ? prizesToAward.length : prizesToNominate.length) ? (
                      <CustomizedCheckboxButton
                        name="selectedToNominatePrizeIds"
                        height={70}
                        checkedItems={
                          isAwardCategoriesVisible
                            ? selectedToAwardPrizeIds
                            : selectedToNominatePrizeIds
                        }
                        items={
                          isAwardCategoriesVisible
                            ? createPrizeCategoryList(prizesToAward)
                            : createPrizeCategoryList(prizesToNominate)
                        }
                        onChange={(value) =>
                          isAwardCategoriesVisible
                            ? this.setState({ selectedToAwardPrizeIds: value })
                            : this.setState({ selectedToNominatePrizeIds: value })
                        }
                        showInnerHtml="hidden"
                      />
                    ) : null}
                  </div>
                </>
              )}

              {!isAwardDialogVisible ? (
                <ul
                  className={`GigSubmissions__Actions ${
                    !this.isCategoriesShowing() ? 'GigSubmissions__Actions--full' : ''
                  }`}
                >
                  {this.isCategoriesShowing() && (
                    <li>
                      <button
                        type="button"
                        className="button purple-theme small"
                        onClick={() => this.onHandleCancelChoosing()}
                      >
                        cancel
                      </button>
                    </li>
                  )}

                  {!isAwardCategoriesVisible && !videoDetails.IsDeleted && (
                    <li>
                      <button
                        type="button"
                        className="button small orange-theme"
                        onClick={() => {
                          if (!isNominateCategoriesVisible) {
                            this.setState({ isNominateCategoriesVisible: true });
                          } else {
                            this.onHandleNominate();
                          }
                        }}
                      >
                        Nominate
                      </button>
                    </li>
                  )}

                  {!isNominateCategoriesVisible && !videoDetails.IsDeleted && (
                    <li>
                      <button
                        type="button"
                        className="button small orange-theme"
                        onClick={() => {
                          if (!this.isCategoriesShowing()) {
                            this.setState({ isAwardCategoriesVisible: true });
                          } else {
                            this.setState({ isAwardCategoriesVisible: true });
                            this.setState({ isAwardDialogVisible: true });
                          }
                        }}
                      >
                        Award
                      </button>
                    </li>
                  )}
                </ul>
              ) : (
                <div className="GigSubmissions__AwardConfirm">
                  <span className="GigSubmissions__AwardConfirm__Title">Award prizes</span>

                  <span className="GigSubmissions__AwardConfirm__Description">
                    Are you sure you want to award this video?
                  </span>

                  <span className="GigSubmissions__AwardConfirm__SmallDescription">
                    Once a video is awarded there is no going back.
                  </span>

                  <div className="GigSubmissions__AwardConfirm__Actions">
                    <button
                      className="button purple-theme small"
                      onClick={() =>
                        this.setState({ isAwardDialogVisible: false, errorMessage: '' })
                      }
                      type="button"
                    >
                      no, cancel
                    </button>

                    <button
                      type="button"
                      className="button orange-theme small"
                      onClick={(e) => this.onHandleAward(e)}
                    >
                      yes, award
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}

          {errorMessage && (
            <AlertMessages
              className="Auth__Message"
              message={errorMessage}
              show
              type="error"
              timer={3}
            />
          )}
        </LightBox>
      </>
    );
  }
}

VideoList.propTypes = {
  getNominatedVideos: func.isRequired,
  getVideoDetails: func.isRequired,
  gigPrizes: arrayOf(GIG_PRIZE_TYPE),
  handleModalAward: func,
  isFetchingVideoDetails: bool.isRequired,
  businessNominatedVideos: arrayOf(BUSINESS_NOMINATED_VIDEO_TYPE).isRequired,
  playable: bool,
  type: oneOf(['nominees', 'submissions']),
  videoDetails: VIDEO_TYPE.isRequired,
  videos: arrayOf(VIDEO_TYPE),
  onUpdate: func.isRequired,
};

VideoList.defaultProps = {
  gigPrizes: [],
  handleModalAward: () => {},
  playable: false,
  type: '',
  videos: [],
};

const mapStateToProps = (state) => ({
  gigPrizes: state.gigs.details.GigPrizes,
  isFetchingVideoDetails: state.businesses.isFetchingVideoDetails,
  businessNominatedVideos: state.businesses.businessNominatedVideos,
  videoDetails: state.businesses.videoDetails,
});

export default connect(mapStateToProps, {
  getVideoDetails: getVideoDetailsAction,
  getNominatedVideos: getNominatedVideosAction,
})(VideoList);
