import { arrayOf, bool, number, shape, string } from 'prop-types';

import { BUSINESS_DETAILS_TYPE } from './business';
import MEDIA_TYPE from './media';

const FLAGGED_CONTENT_TYPE = shape({
  CommentCount: number,
  ContentType: number,
  CorporateGroup: BUSINESS_DETAILS_TYPE,
  CorporateGroupId: number,
  FlaggedBy: arrayOf(
    shape({
      DisplayName: string,
      OverallFlagScore: number,
      OverallPendingFlags: number,
      OwnerFlagScore: number,
      OwnerPendingFlags: number,
      ProfilePhoto: MEDIA_TYPE,
      UserId: number,
    }),
  ),
  FullPost: string,
  Image: MEDIA_TYPE,
  IsSponsored: bool,
  ItemId: number,
  LastFlaggedOn: string,
  LikeCount: number,
  OwnerDisplayName: string,
  OwnerId: number,
  Posted: string,
  ProfilePhoto: MEDIA_TYPE,
  Text: string,
  UserCommented: bool,
  UserFavorited: bool,
  UserLiked: bool,
});

export default FLAGGED_CONTENT_TYPE;
