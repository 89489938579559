/* global FileReader */
const fileToBase64 = file => (callBackOnLoad, callbackOnProgress) => {
  const reader = new FileReader();

  if (callbackOnProgress) {
    reader.onprogress = ({ loaded, total }) => callbackOnProgress((loaded / total) * 100);
  }

  reader.onloadend = () => callBackOnLoad(reader.result);

  reader.readAsDataURL(file);
};

export default fileToBase64;
