import './index.sass';

import React from 'react';

import { object, oneOfType } from 'prop-types';
import SocialMedia from '../../../../components/SocialMedia';
import { BUSINESS_PROFILE_TYPE } from '../../../../types/business';
import OpenHours from './OpenHours';

const BusinessInfoContact = (props) => {
  const { businessProfile } = props;
  const {
    Address,
    PhoneNumber,
    Website,
    MenuUrl,
    BusinessHours,
    FacebookUrl,
    InstagramUrl,
    TwitterUrl,
  } = businessProfile;

  const isSocialMediaShowing = !!(TwitterUrl || FacebookUrl || InstagramUrl);

  return (
    <div className="BusinessInfoContact">
      {Address && (
        <p className="BusinessInfoContact__Info">
          <i className="icon-directions" />
          {Address}
        </p>
      )}

      {PhoneNumber && (
        <p className="BusinessInfoContact__Info">
          <i className="icon-call" />
          +
          {PhoneNumber}
        </p>
      )}

      {Website && (
        <p className="BusinessInfoContact__Info">
          <i className="icon-website" />
          {Website}
        </p>
      )}

      {/* TODO: missing tiktok and other */}
      {isSocialMediaShowing && (
        <SocialMedia
          className="BusinessInfo__SocialMedia"
          items={[
            { socialType: 'twitter', url: TwitterUrl },
            { socialType: 'facebook', url: FacebookUrl },
            { socialType: 'instagram', url: InstagramUrl },
          ]}
        />
      )}

      {MenuUrl && (
      <p className="BusinessInfoContact__Info">
        Menu link:
        {' '}
        {MenuUrl}
      </p>
      )}

      {BusinessHours && (
        <div className="BusinessInfoContact__Info">
          <OpenHours openingHours={BusinessHours} />
        </div>
      )}


    </div>
  );
};

BusinessInfoContact.propTypes = {
  businessProfile: BUSINESS_PROFILE_TYPE.isRequired,
};


export default BusinessInfoContact;
