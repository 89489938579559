import { arrayOf, number, shape, string } from 'prop-types';

import MEDIA_TYPE from './media';

export const USER_BUSINESS_TYPE = shape({
  CorporateGroupId: number,
  DisplayName: string,
});

export const USER_ROLE_TYPE = shape({
  Group: number,
  Name: string,
});

export const USER_DETAILS_TYPE = shape({
  AssociatedAsManagerCorporateGroups: string,
  Email: string,
  FirstName: string,
  GroupId: number,
  LastName: string,
  Username: string,
  UserId: number,
});

export const SEARCHED_USER_TYPE = shape({
  ConnectionToken: string,
  DisplayName: string,
  FullName: string,
  ProfilePhoto: MEDIA_TYPE,
  UserId: number,
});

export const USER_PROFILE_TYPE = shape({
  AgeRange: string,
  BlockedUsers: arrayOf(string),
  City: string,
  ConnectionToken: string,
  Country: string,
  DateOfBirth: string,
  DisplayName: string,
  DwollaCustomerId: string,
  DwollaDefaultAccount: string,
  Email: string,
  EmailVerified: false,
  FilterEmail: false,
  FirstName: string,
  FollowersCount: number,
  FollowingCount: number,
  FollowRequestStatus: number,
  Fullname: string,
  Gender: string,
  GenderId: string,
  GigTotalEarnings: number,
  IsBlocked: false,
  IsFollowedByUser: false,
  LastName: string,
  PhoneCountry: string,
  PhoneCountryCode: string,
  PhoneNumber: string,
  ProfilePhoto: MEDIA_TYPE,
  SavedCount: number,
  SocialAuthenticationType: shape({
    AuthenticationTypeId: number,
    AuthenticationTypeName: string,
  }),
  State: string,
  UserId: number,
  UserName: string,
  VenmoEmail: string,
  VideosCount: number,
});
