import './FiltersModalCheckbox.sass';

import { bool, func, string } from 'prop-types';
import React from 'react';

class FiltersModalCheckbox extends React.Component {
  state = { value: [] };

  componentDidMount() {
    const { value } = this.props;
    this.setState({ value });
  }

  handleInputChange(event) {
    const { onChange } = this.props;
    const value = event.target.checked;
    this.setState({ value });
    onChange(value);
  }

  render() {
    const { value } = this.state;
    const { className, disabled, label, name } = this.props;

    return (
      <fieldset className={`${className} FiltersModalCheckbox__Container`}>
        <label>
          {label}
          <input
            checked={value}
            disabled={disabled}
            name={name}
            onChange={(event) => this.handleInputChange(event)}
            type="checkbox"
          />
          <span />
        </label>
      </fieldset>
    );
  }
}

FiltersModalCheckbox.propTypes = {
  className: string,
  disabled: bool,
  label: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  value: bool,
};

FiltersModalCheckbox.defaultProps = {
  className: '',
  disabled: false,
  value: false,
};

export default FiltersModalCheckbox;
