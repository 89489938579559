/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/media-has-caption */
import './index.sass';

import { bool, func, instanceOf, node, number, oneOfType, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import AsyncContent from '../AsyncContent';
import fileToBase64 from './fileToBase64';

/* global Image */
function FileUpload(props) {
  const {
    accept,
    aspect,
    description,
    fullWidth,
    height,
    id,
    name,
    placeholder,
    required,
    type,
    value,
    onChange,
    maxFileSize,
  } = props;

  const fileUploadElement = useRef(null);

  const [loading, setLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState('0%');
  const [fileUploadUrl, setFileUploadUrl] = useState(value);
  const [fileUploadError, setFileUploadError] = useState(null);

  const canvasRef = useRef(null);
  const previewContainerRef = useRef(null);

  const resetLoaderProgress = useCallback(() => {
    setLoadingProgress('0%');
  }, [setLoadingProgress]);

  const updateImage = useCallback(
    (url) => {
      const ctx = canvasRef.current.getContext('2d');
      const img = new Image();
      img.onload = () => {
        const { naturalWidth, naturalHeight } = img;
        const imgAspect = naturalWidth / naturalHeight;
        const newWidth = imgAspect > aspect ? naturalHeight * aspect : naturalWidth;
        const newHeight = imgAspect < aspect ? naturalWidth / aspect : naturalHeight;
        const left = (naturalWidth - newWidth) / 2;
        const top = (naturalHeight - newHeight) / 2;

        const w = newWidth < 600 ? newWidth : 600;
        const h = w / aspect;
        canvasRef.current.width = w;
        canvasRef.current.height = h;
        ctx.drawImage(img, left, top, newWidth, newHeight, 0, 0, w, h);

        const file = canvasRef.current.toDataURL();
        setFileUploadUrl(file);
        onChange(name, file);
      };
      img.src = url;
    },
    [name, canvasRef.current, setFileUploadUrl],
  );

  const transformData = useCallback(
    (file) => {
      fileToBase64(file)(
        (url) => {
          setFileUploadError(null);
          setLoading(false);
          resetLoaderProgress();
          if (aspect) {
            updateImage(url);
          } else {
            setFileUploadUrl(url);
            onChange(name, url);
          }
        },
        (progress) => {
          setLoadingProgress(`${progress}%`);
        },
      );
    },
    [setFileUploadUrl, setFileUploadError, setLoading, resetLoaderProgress, setLoadingProgress],
  );

  useEffect(() => {
    if (value) {
      if (typeof value === 'object') {
        transformData(value);
      }
    }
  }, []);

  useEffect(() => {
    if (typeof value === 'string') {
      setFileUploadUrl(value);
    }
  }, [value]);

  const setError = useCallback(
    (message) => {
      setFileUploadUrl(value);
      setLoading(false);
      setFileUploadError(message);
    },
    [setFileUploadUrl, value, setLoading, setFileUploadError],
  );

  const onHandleChange = useCallback(
    (e) => {
      const file = e.target.files[0];
      setLoading(true);
      if (file) {
        const extension = file.name.split('.').pop();
        if (
          accept &&
          !accept
            .split(',')
            .map((t) => t.trim().substring(1))
            .includes(extension)
        ) {
          setError('Invalid file format');
          return;
        }

        if (file.size < maxFileSize * 1000000) {
          // MB
          transformData(file);
          if (!aspect) {
            onChange(name, file);
          }
        } else {
          resetLoaderProgress();
          setError('Your file size is too large. Please select a smaller image.');
        }
      }
    },
    [setLoading, accept, setError, onChange, name, transformData, resetLoaderProgress],
  );

  const previewArea = useMemo(() => {
    if (aspect) {
      return (
        <div
          className="FileUpload__CroppedImageArea"
          style={{ paddingTop: `${100 / aspect}%` }}
          ref={previewContainerRef}
        >
          <img className="FileUpload__CroppedImage" src={fileUploadUrl} alt="" />
        </div>
      );
    }

    if (type === 'video') {
      return (
        <video style={{ height }} controls>
          <source src={fileUploadUrl} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      );
    }

    return (
      <figure className="FileUpload__Preview__Image" style={{ height }}>
        <img src={fileUploadUrl} alt="" />
      </figure>
    );
  }, [aspect, fileUploadUrl, height, type, canvasRef.current]);

  return (
    <AsyncContent
      content={() => (
        <>
          <div
            className={`FileUpload ${fullWidth ? 'FileUpload--Full' : ''}
            ${fileUploadUrl ? 'FileUpload--WithPreview' : ''}`}
            ref={fileUploadElement}
          >
            {fileUploadUrl ? (
              <div className="FileUpload__Preview">
                {previewArea}

                <div className="FileUpload__Info">
                  <span className="FileUpload__Info__Description">{description}</span>

                  <span className="FileUpload__Preview__Update">
                    <input
                      type="file"
                      id={id}
                      name={name}
                      onChange={onHandleChange}
                      accept={accept}
                    />
                    {`update ${type}`}
                  </span>
                </div>
              </div>
            ) : (
              <div className="FileUpload__Container" style={{ height }}>
                {loading ? (
                  <div className="FileUpload__Loader">
                    <div
                      className="FileUpload__Loader__Progress"
                      style={{ width: loadingProgress }}
                    />
                    <span className="FileUpload__Loader__Status">
                      {type === 'video'
                        ? `uploading video ${loadingProgress}`
                        : `uploading image ${loadingProgress}`}
                    </span>
                  </div>
                ) : (
                  <>
                    <input
                      type="file"
                      style={{ height }}
                      onChange={onHandleChange}
                      id={id}
                      name={name}
                      required={required}
                      accept={accept}
                    />

                    <div className="FileUpload__FileInfo">
                      <span
                        className="FileUpload__Button"
                        dangerouslySetInnerHTML={{ __html: placeholder }}
                      />

                      <span className="FileUpload__FileDescription">{description}</span>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <canvas className="FileUpload__Canvas" ref={canvasRef} />
        </>
      )}
      errorMessage={fileUploadError}
      expectNoData
      onHandleCloseDialog={() => setFileUploadError('')}
    />
  );
}

FileUpload.propTypes = {
  accept: string,
  aspect: number,
  description: node,
  fullWidth: bool,
  height: number,
  id: string,
  maxFileSize: number,
  name: string.isRequired,
  onChange: func.isRequired,
  placeholder: string,
  required: bool,
  type: string,
  value: oneOfType([string, instanceOf(Object)]),
};

FileUpload.defaultProps = {
  accept: null,
  aspect: null,
  description: null,
  fullWidth: false,
  height: 300,
  id: '',
  maxFileSize: 4,
  placeholder: 'upload',
  required: false,
  type: 'image',
  value: null,
};

export default FileUpload;
