import './index.sass';

import { isEmpty } from 'lodash';
import { bool, func, number, shape, string } from 'prop-types';
import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../config/pagination';
import USER_ROLES from '../../config/user-roles';
import MEDIA_TYPE from '../../types/media';
import ROUTER_MATCH_TYPE from '../../types/routerMatch';
import Loader from '../Loader';

/* global localStorage */
class Sidebar extends React.Component {
  state = {
    userRole: null,
  };

  componentDidMount() {
    this.getUserRole();
  }

  getUserRole() {
    const userRole = localStorage.getItem('frankiRole');
    this.setState({ userRole });
  }

  onHandleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  canAccessMembershipMenu() {
    const { membership } = this.props;
    const { userRole } = this.state;
    return membership !== null || [USER_ROLES.admin, USER_ROLES.saleAdmin].indexOf(userRole) > -1;
  }

  render() {
    const {
      businessSidebarData,
      businessSidebarDataError,
      businessItemMenu,
      onHandleBusinessItemMenu,
      match: {
        params: { corporateGroupId },
      },
    } = this.props;

    return (
      <aside
        onClick={() => onHandleBusinessItemMenu(false)}
        role="presentation"
        className={`${businessItemMenu ? 'Sidebar Sidebar__Mobile--active' : 'Sidebar'}`}
      >
        <div className="Sidebar__Content">
          {!businessSidebarDataError && isEmpty(businessSidebarData) && <Loader />}

          {!businessSidebarDataError && !isEmpty(businessSidebarData) && (
            <>
              <div className="Sidebar__Business">
                <figure>
                  {businessSidebarData.Logo && businessSidebarData.Logo.FullResolutionUrl && (
                    <img src={businessSidebarData.Logo.FullResolutionUrl} alt="logo" />
                  )}
                </figure>

                <h1 className="Sidebar__Business__Title">{businessSidebarData.Name || businessSidebarData.DisplayName}</h1>

                <ul className="Sidebar__Business__Metrics">
                  <li>
                    <div className="Sidebar__Business__Metrics__Count">
                      {businessSidebarData.VideoCount
                        || businessSidebarData.NumberVideoReviews
                        || 0}
                    </div>
                    <div className="Sidebar__Business__Metrics__Text">Reviews</div>
                  </li>

                  <li>
                    <div className="Sidebar__Business__Metrics__Count">
                      {businessSidebarData.FollowerCount
                        || businessSidebarData.NumberFollowers
                        || 0}
                    </div>
                    <div className="Sidebar__Business__Metrics__Text">Followers</div>
                  </li>
                </ul>
              </div>

              <nav className="Sidebar__Nav">
                <div className="Sidebar__Business__Credit">
                  {/* <div className="Sidebar__Business__Credit__Amount">
                      <Currency
                        quantity={0}
                      />
                    </div>

                    <div className="Sidebar__Business__Credit__Description">Franki Credits</div> */}
                </div>

                <ul className="Sidebar__Nav__Menu">
                  <li>
                    <NavLink
                      to={`/admin/business-profile/${corporateGroupId}`}
                      activeClassName="Sidebar__Nav__Menu--active"
                    >
                      Business Profile
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/admin/business-videos/${corporateGroupId}/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}?orderBy=DateCreated`}
                      activeClassName="Sidebar__Nav__Menu--active"
                    >
                      Posts
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/admin/business-gigs/${corporateGroupId}/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`}
                      activeClassName="Sidebar__Nav__Menu--active"
                    >
                      GiGs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/admin/business-billing-history/${corporateGroupId}/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`}
                      activeClassName="Sidebar__Nav__Menu--active"
                    >
                      Billing History
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </>
          )}
        </div>
      </aside>
    );
  }
}

Sidebar.propTypes = {
  businessItemMenu: bool,
  businessSidebarData: shape({
    DisplayName: string,
    Logo: MEDIA_TYPE,
  }),
  businessSidebarDataError: string,
  match: ROUTER_MATCH_TYPE({ corporateGroupId: string.isRequired }).isRequired,
  membership: number,
  onHandleBusinessItemMenu: func.isRequired,
};

Sidebar.defaultProps = {
  businessItemMenu: null,
  businessSidebarData: {},
  businessSidebarDataError: null,
  membership: null,
};

export default withRouter(Sidebar);
