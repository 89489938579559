import './index.sass';

import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { logout } from '../../actions/auth';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../config/pagination';
import USER_ROLES from '../../config/user-roles';
import { USER_PROFILE_TYPE } from '../../types/users';
import Loader from '../Loader';

export default function ProfileSidebar(props) {
  const { profile, userRole } = props;

  function onLogout() {
    logout().subscribe(() => {
      localStorage.removeItem('frankiRole');
      localStorage.removeItem('frankiToken');
      localStorage.removeItem('frankiUser');
      localStorage.removeItem('frankiName');
      window.location.href = '/';
    });
  }

  return (
    <aside
      onClick={() => {}}
      role="presentation"
      className={`ProfileSidebar ${profile ? 'ProfileSidebar__Mobile--active' : ''}`}
    >
      <div className="ProfileSidebar__Content">
        {profile ? null : <Loader />}

        {profile ? (
          <>
            <div className="ProfileSidebar__Details">
              <figure>
                {profile.ProfilePhoto ? (
                  <img src={profile.ProfilePhoto.FullResolutionUrl} alt="profile" />
                ) : (
                  <i className="icon-user" />
                )}
              </figure>

              <h1 className="ProfileSidebar__Details__Name">
                {profile.FirstName} {profile.LastName}
              </h1>

              <div className="ProfileSidebar__Details__Role">
                <b>Role</b>:{profile.Role}
              </div>
            </div>

            {![USER_ROLES.contentAdmin, USER_ROLES.businessManager].includes(userRole) && (
              <nav className="ProfileSidebar__Nav">
                <ul className="ProfileSidebar__Nav__Menu">
                  <li>
                    <NavLink
                      to="/admin/profile"
                      activeClassName="ProfileSidebar__Nav__Menu--active"
                    >
                      Profile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/admin/videos/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`}
                      activeClassName="ProfileSidebar__Nav__Menu--active"
                    >
                      Posts
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/admin/gigs/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`}
                      activeClassName="ProfileSidebar__Nav__Menu--active"
                    >
                      Gigs
                    </NavLink>
                  </li>
                  <li>
                    <Link onClick={() => onLogout()} className="ProfileSidebar__Nav__Menu--SignOut">
                      Sign out
                    </Link>
                  </li>
                </ul>
              </nav>
            )}
          </>
        ) : null}
      </div>
    </aside>
  );
}

ProfileSidebar.propTypes = {
  profile: USER_PROFILE_TYPE,
};

ProfileSidebar.defaultProps = {
  profile: null,
};
