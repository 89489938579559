import {
  CLEAR_USERS,
  GET_USERS_CORPORATE_GROUP,
  GET_USERS_DETAILS,
  SEARCH_USERS,
  SEARCH_USERS_ERROR,
  SEARCH_USERS_SUCCESS,
} from '../actions/users';

const initialState = {
  searchedUsers: [],
  searchedUsersError: '',
  searchedUsersToken: null,
  isSearchingUsers: false,

  groupList: [],
  groupListError: null,

  userProfiles: {},
  userProfilesError: null,
};

export default function (state = initialState, { type, payload, token }) {
  switch (type) {
    case GET_USERS_CORPORATE_GROUP: {
      return {
        ...state,
        groupList: payload.data || [],
        groupListError: payload.error || null,
      };
    }

    case GET_USERS_DETAILS: {
      return {
        ...state,
        userProfiles: payload.data || {},
        userProfilesError: payload.error || null,
      };
    }

    case SEARCH_USERS: {
      return {
        ...state,
        isSearchingUsers: true,
        searchedUsers: [],
        searchedUsersToken: token,
        searchedUsersError: '',
      };
    }

    case SEARCH_USERS_SUCCESS: {
      if (token !== state.searchedUsersToken) {
        return state;
      }
      return {
        ...state,
        isSearchingUsers: false,
        searchedUsers: payload.data || [],
      };
    }

    case SEARCH_USERS_ERROR: {
      if (token !== state.searchedUsersToken) {
        return state;
      }
      return {
        ...state,
        isSearchingUsers: false,
        searchedUsersError: payload.error || 'Error getting users for filter',
      };
    }

    case CLEAR_USERS: {
      return initialState;
    }

    default:
      return state;
  }
}
