import { bool, number, shape, string } from 'prop-types';

const PRIZE_TYPE = shape({
  Description: string,
  Disabled: bool,
  GigPrizeId: number,
  Label: string,
  MaxPrizeAmount: number,
  MaxWinners: number,
  MinPrizeAmount: number,
  MinWinners: number,
  Name: string,
  Placeholder: string,
  PrizeAmount: number,
  Removable: bool,
  Required: bool,
  WinnerQty: number,
});

export default PRIZE_TYPE;
