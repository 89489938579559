/* eslint-disable react/no-danger */
import './index.sass';

import { node, number, oneOfType, string } from 'prop-types';
import React, { useEffect } from 'react';

import CircularProgress from '../CircularProgress';

const StepsHeader = (props) => {
  const { maxSteps, step, subtitle, title } = props;
  if (step == 0) return <></>;

  useEffect(() => {
    const el = document.querySelectorAll('.LightBox-content > header');
    if (el.length > 0) {
      el[0].remove();
    }
  }, []);

  return (
    <div className="StepsHeader">
      <CircularProgress text={`${step}/${maxSteps}`} value={(step / maxSteps) * 100} />
      <div className="StepsHeader__Steps">
        {step !== undefined && (
          <span>
            Step:&nbsp;
            <strong>{step}</strong>
            &nbsp;of&nbsp;
            <strong>{maxSteps}</strong>
          </span>
        )}
      </div>

      <h1 className="StepsHeader__Title">{title}</h1>

      <p className="StepsHeader__Description" dangerouslySetInnerHTML={{ __html: subtitle }} />
    </div>
  );
};

StepsHeader.propTypes = {
  maxSteps: number,
  step: number,
  subtitle: string,
  title: oneOfType([string, node]).isRequired,
};

StepsHeader.defaultProps = {
  maxSteps: 3,
  subtitle: '',
  step: undefined,
};

export default StepsHeader;
