import './index.sass';

import React from 'react';
import { object, oneOfType } from 'prop-types';
import moment from 'moment';

const WeekdayTexts = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const getDateString = (dates) => {
  const { OpeningTime, ClosingTime } = dates;
  return `${moment(OpeningTime, 'HH:mm:ss').format('LT')} - ${moment(ClosingTime, 'HH:mm:ss').format('LT')}`;
};

const OpenHours = (props) => {
  const {
    openingHours,
  } = props;

  return (
    <div className="OpenHours">
      {WeekdayTexts.map(day => (
        openingHours[day]
          ? (
            <div key={day} className="OpenHours__Day">
              <p className="w100">{day}</p>
              <p>{openingHours[day].map(item => getDateString(item)).join(', ')}</p>
            </div>
          ) : <></>
      ))}
    </div>
  );
};

OpenHours.propTypes = {
  openingHours: oneOfType([object]),
};

OpenHours.defaultProps = {
  openingHours: {},
};

export default OpenHours;
