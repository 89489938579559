import './index.sass';

import { bool, instanceOf, number } from 'prop-types';
import Slider, { Range } from 'rc-slider';
import React from 'react';
import uuidv1 from 'uuid/v1';

const { Handle } = Slider;

const orangeColor = '#FFB30F';
const styles = {
  dotHandles: {
    width: 10,
    height: 10,
    backgroundColor: orangeColor,
    borderColor: orangeColor,
  },
  track: {
    backgroundColor: orangeColor,
    height: 2,
  },
  rail: {
    height: 1,
    backgroundColor: '#000',
  },
};

const handle = (props) => {
  const { value, dragging, index, max, ...restProps } = props;
  const maxRate = 5;
  const maxPrice = 5;
  return (
    <Handle key={uuidv1()} value={value} {...restProps}>
      <span className="rc-slider-customized-tooltip">
        {max > maxRate && '$'}
        {value}
        {max > maxRate ? '' : <i className="icon-star_on" />}
        {value >= maxPrice && '+'}
      </span>
    </Handle>
  );
};

handle.propTypes = {
  dragging: bool.isRequired,
  index: number.isRequired,
  max: number.isRequired,
  value: number.isRequired,
};

const RangeSlider = ({ tooltip, value, ...rest }) => (
  <div className="rangeSlider">
    <Range
      railStyle={styles.rail}
      trackStyle={[styles.track]}
      handleStyle={[{ ...styles.dotHandles }, { ...styles.dotHandles }]}
      value={value}
      handle={handle}
      {...rest}
    />
  </div>
);

RangeSlider.propTypes = {
  disabled: bool,
  tooltip: bool,
  value: instanceOf(Array).isRequired,
};

RangeSlider.defaultProps = {
  disabled: false,
  tooltip: false,
};

export default RangeSlider;
