import { COLORS } from '../../config/colors';

const customSelectStyles = {
  clearIndicator: () => ({
    display: 'none',
  }),
  control: provided => ({
    ...provided,
    borderWidth: '1px',
    boxShadow: 'none',
    cursor: 'pointer',
    fontSize: 14,
    paddingLeft: '6px',
    borderRadius: '4px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: COLORS.black,
    padding: 0,
    transform: state.selectProps.menuIsOpen ? 'scale(.75) rotate(180deg)' : 'scale(.75)',
    transition: 'transform 100ms',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    width: 24,
    justifyContent: 'center',
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'transparent',
  }),
  multiValue: provided => ({
    ...provided,
    borderRadius: 3,
    boxSizing: 'border-box',
    display: 'flex',
    margin: '2px 6px 2px 4px',
    padding: '5px 0px 4px 7px',
  }),
  multiValueLabel: () => ({
    color: COLORS.purple,
    fontSize: 13,
    fontWeight: 'bold',
    letterSpacing: '-0.23px',
    lineHeight: '16px',
  }),
  multiValueRemove: () => ({
    color: COLORS.purple,
    margin: '0 7px',
    display: 'inline-flex',
    alignItems: 'center',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected || state.isFocused ? COLORS.lightOrange : COLORS.white,
    boxSizing: 'border-box',
    color: COLORS.gray,
    fontSize: 14,
    paddingLeft: '14px',
  }),
  menu: () => ({
    boxShadow: '0 2px 8px 2px rgba(0, 0, 0, 0.1)',
    marginTop: 8,
    zIndex: 2,
  }),
  noOptionsMessage: base => ({
    ...base,
    boxSizing: 'border-box',
    color: COLORS.gray,
    fontSize: 14,
    paddingLeft: '14px',
    backgroundColor: 'white',
  }),
};

export default customSelectStyles;
