import './index.sass';

import { isEmpty } from 'lodash';
import { instanceOf, node, string } from 'prop-types';
import React from 'react';

import Breadcrumb from '../Breadcrumb';

const ContentPage = (props) => {
  const { businessPage, children, headerBreadcrumb, headerButtons } = props;
  if (!isEmpty(headerBreadcrumb) || headerButtons) {
    return (
      <>
        <div className="ContentPage ContentPage__Header">
          <Breadcrumb items={headerBreadcrumb} />
          {headerButtons && <div className="ContentPage__Header__Buttons">{headerButtons}</div>}
        </div>

        <div className="ContentPage ContentPage__Content">{children}</div>
      </>
    );
  }

  if (businessPage !== 'Profile') {
    return <div className="ContentPage ContentPage--Full">{children}</div>;
  }

  return <div className="ContentPage--Full">{children}</div>;
};

ContentPage.propTypes = {
  businessPage: string,
  children: instanceOf(Object),
  headerBreadcrumb: instanceOf(Array),
  headerButtons: node,
};

ContentPage.defaultProps = {
  businessPage: null,
  children: null,
  headerBreadcrumb: [],
  headerButtons: null,
};

export default ContentPage;
