import './index.sass';

import { func, string } from 'prop-types';
import React, { useCallback, useState } from 'react';

import { deleteUser } from '../../../../actions/users';
import HamburgerMenu from '../../../../components/HamburgerMenu';
import LightBox from '../../../../components/LightBox';
import USER_ROLES from '../../../../config/user-roles';
import { USER_DETAILS_TYPE } from '../../../../types/users';

function isEditable(frankiRole, userRole) {
  return (
    frankiRole === USER_ROLES.admin
    || (frankiRole === USER_ROLES.saleAdmin
      && [USER_ROLES.admin, USER_ROLES.saleAdmin].indexOf(userRole) === -1)
  );
}

function isRemovable(frankiRole) {
  return frankiRole === USER_ROLES.admin;
}

/* global localStorage */
const frankiRole = localStorage.getItem('frankiRole');

function UserCard(props) {
  const { item, userRole, onDelete } = props;
  const [isDeleteUserConfirmationShown, setIsDeleteUserConfirmationShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const onDeleteUser = useCallback(() => {
    deleteUser(item.UserId).subscribe(onDelete, setErrorMessage);
  }, [item.UserId, onDelete, setErrorMessage]);

  return (
    <>
      <div className="UserCard">
        <HamburgerMenu
          className="UserCard__HamburgerMenu"
          menuItems={
            isEditable(frankiRole, userRole)
              ? [
                {
                  innerContent: 'edit user',
                  url: `/admin/user-edit/${item.Username}`,
                },
                ...(isRemovable(frankiRole)
                  ? [
                    {
                      innerContent: 'delete user',
                      action: () => setIsDeleteUserConfirmationShown(true),
                    },
                  ]
                  : []),
                {
                  innerContent: 'activate user',
                },
              ]
              : []
          }
        />
        <div className="UserCard__Header">
          <div className="UserCard__Name">{item.FullName}</div>
          <div className="UserCard__Username">{item.DisplayName}</div>
        </div>
        <div className="UserCard__Body">
          <div className="UserCard__Role">{item.SecurityGroupName}</div>
          <div className="UserCard__Businesses">
            {item.AssociatedAsManagerCorporateGroups
              ? item.AssociatedAsManagerCorporateGroups.toString()
              : ' '}
          </div>
        </div>
        <div className="UserCard__Footer">
          <div className="UserCard__Email">{item.Email}</div>
          <div className={`UserCard__Status ${item.Enabled ? 'active' : ''}`}>
            {item.Enabled ? 'active' : 'inactive'}
          </div>
        </div>
      </div>

      {isDeleteUserConfirmationShown && (
        <LightBox
          className="DeleteUserConfirmationShown"
          style={{
            width: 600,
            height: null,
            marginLeft: -300,
            marginTop: -140,
          }}
          onClose={() => setIsDeleteUserConfirmationShown(false)}
          show
          spacing={24}
          title="Attention!"
        >
          <div className="DeleteUserConfirmationShown__Content">
            Are you sure you want to delete this user and their content? You cannot undo this
            action.
          </div>

          <div className="DeleteUserConfirmationShown__Actions">
            <button
              type="button"
              className="button small"
              onClick={() => setIsDeleteUserConfirmationShown(false)}
            >
              NO, CANCEL
            </button>
            <button type="button" className="button small red-theme" onClick={onDeleteUser}>
              YES, DELETE
            </button>
          </div>

          <div className="DeleteUserConfirmationShown__Error">{errorMessage}</div>
        </LightBox>
      )}
    </>
  );
}

UserCard.propTypes = {
  item: USER_DETAILS_TYPE.isRequired,
  userRole: string.isRequired,
  onDelete: func.isRequired,
};

export default UserCard;
