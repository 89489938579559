import { bool, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getBusinessDetails,
  getBusinessManagerVerificationStatus,
  getBusinessOptions,
  getBusinessProfileApi,
  // getBusinessProfileNew,
} from '../../../actions/businesses';
import USER_ROLES from '../../../config/user-roles';
import HISTORY_TYPE from '../../../types/history';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';
import BusinessInfo from '../BusinessInfo';
import Welcome from '../Welcome';
import shopPurplePng from '../../../assets/images/shop-purple.png';

// const statusStrArray = ['Unverified', 'Verified', 'Pending Verification'];
/* global window, localStorage */
function BusinessProfile(props) {
  const {
    auth,
    match: {
      params: { corporateGroupId },
    },
    userRole,
    history,
  } = props;
  const [businessProfile, setBusinessProfile] = useState({});
  const [businessOptionLabel, setBusinessOptionLabel] = useState(null);
  const [businessProfileError, setBusinessProfileError] = useState(null);
  const [isFetchingBusinessProfile, setIsFetchingBusinessProfile] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('');
  const { businessDetails } = useSelector(businessState => businessState.businesses);
  const dispatch = useDispatch();

  const displayWelcomeScreen = useMemo(() => {
    const query = new URLSearchParams(window.location.search);
    const param = query.get('displayWelcomeScreen');
    return param;
  }, [window.location.search]);

  useEffect(() => {
    localStorage.setItem('corporateGroupId', corporateGroupId);
    setIsFetchingBusinessProfile(true);
    if (userRole === USER_ROLES.businessManager) { getBusinessManagerVerificationStatus(corporateGroupId).subscribe(setVerificationStatus); }

    getBusinessProfileApi(corporateGroupId).subscribe(
      (data) => {
        setBusinessProfile(data);
      },
      (err) => {
        setBusinessProfileError(err);
        setIsFetchingBusinessProfile(false);
      },
      () => setIsFetchingBusinessProfile(false),
    );

    getBusinessOptions().subscribe(({ BusinessOptionTypes }) => {
      setBusinessOptionLabel(BusinessOptionTypes);
    });

    dispatch(getBusinessDetails(corporateGroupId));
  }, []);

  useEffect(() => {
    if (!businessProfile.Logo) {
      businessProfile.Logo = (businessProfile.LogoUrl && { FullResolutionUrl: businessProfile.LogoUrl }) || businessDetails.Logo || { FullResolutionUrl: shopPurplePng };
      setBusinessProfile(businessProfile);
    }
    if (!verificationStatus) {
      const { VerificationStatus } = businessDetails;
      if (VerificationStatus === 0) setVerificationStatus('Not Verified');
      if (VerificationStatus === 1) setVerificationStatus('Verified');
      if (VerificationStatus === 2) setVerificationStatus('Pending');
      if (VerificationStatus === 3) setVerificationStatus('Rejected');
    }
  }, [businessDetails, businessProfile]);

  return (
    <BusinessInfo
      auth={auth}
      businessProfile={businessProfile}
      businessProfileError={businessProfileError}
      corporateGroupId={corporateGroupId}
      isFetchingBusinessProfile={isFetchingBusinessProfile}
      membership={null}
      userRole={userRole}
      history={history}
      verificationStatus={verificationStatus}
      businessOptionLabel={businessOptionLabel}
    >
      {displayWelcomeScreen ? (
        <Welcome status={displayWelcomeScreen} businessId={corporateGroupId} history={history} />
      ) : (
        <></>
      )}
    </BusinessInfo>
  );
}

BusinessProfile.propTypes = {
  auth: bool.isRequired,
  match: ROUTER_MATCH_TYPE({ corporateGroupId: string.isRequired }).isRequired,
  userRole: string.isRequired,
  history: HISTORY_TYPE.isRequired,
};

export default BusinessProfile;
