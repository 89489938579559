/* global */
export function isFoursquareData() {
  return true; // localStorage.getItem("showFoursquareData") === "true";
}

export function getPlaceIdProp() {
  return {
    api: 'ThirdPartyBusinessSourceId',
    query: 'thirdPartyBusinessSourceId',
  };
}

export function getPlaceId(props) {
  return new URLSearchParams(props.history.location.search).get(getPlaceIdProp().query);
}

export const validURL = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};
