import { array, arrayOf, bool, number, shape, string } from 'prop-types';

import MEDIA_TYPE from './media';
import STATE_TYPE from './state';

export const GIG_RULE = shape({
  Description: string,
  DisplayOrder: number,
  GigId: number,
  GigRuleId: number,
});

export const GIG_PRIZE_TYPE = shape({
  Description: string,
  DisplayOrder: number,
  GigId: number,
  GigPrizeId: number,
  PrizeAmount: number,
  WinnerQty: number,
});

export const AWARDED_GIG_PRIZE_TYPE = shape({
  Description: string,
  DisplayOrder: number,
  GigId: number,
  GigPrizeId: number,
  IsPaid: bool,
  PrizeAmount: number,
  WinnerQty: number,
});

export const NOMINATED_GIG_PRIZE_TYPE = shape({
  Description: string,
  DisplayOrder: number,
  GigId: number,
  GigPrizeId: number,
  NominatedVideoContentKey: string,
  PrizeAmount: number,
  WinnerQty: number,
});

const GIG_TYPE = shape({
  CorporateGroup: shape({
    Address: string,
    AwardedGigPrizes: arrayOf(AWARDED_GIG_PRIZE_TYPE),
    BusinessType: shape({
      Id: number,
      Name: string,
    }),
    BusinessTypeId: number,
    City: string,
    ContentCount: number,
    CorporateGroupId: number,
    Country: string,
    DisplayName: string,
    Distance: string,
    FacebookLink: string,
    FullName: string,
    Gigs: string,
    GooglePlaceId: string,
    GroupCode: string,
    InstagramLink: string,
    IsFollowedByUser: bool,
    IsPublic: bool,
    Latitude: number,
    Logo: MEDIA_TYPE,
    Longitude: number,
    NominatedGigPrizes: arrayOf(NOMINATED_GIG_PRIZE_TYPE),
    PhoneCountry: string,
    PhoneCountryCode: string,
    PhoneNumber: string,
    PostalCode: string,
    Products: array,
    State: string,
    StateIds: string,
    States: arrayOf(STATE_TYPE),
    TagLine: string,
    TwitterLink: string,
    Website: string,
  }),
  Gig: shape({
    CorporateGroupId: number,
    Description: string,
    EndDate: string,
    GigId: number,
    GigPrizes: arrayOf(GIG_PRIZE_TYPE),
    GigRules: array,
    IsSaved: bool,
    Name: string,
    Photo: MEDIA_TYPE,
    PhotoId: number,
    PrizesAwarded: number,
    StartDate: string,
    StatusId: number,
    TotalWinnerQty: number,
    UserGigsEarning: array,
    VideosSubmitted: number,
  }),
});

export default GIG_TYPE;
