import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import { REACT_APP_GOOGLE_ANALYTICS_KEY } from '../config/variables';
import { ANALYTICS_TRACKER_ROUTES } from '../config/tracker-routes';

const useGATracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_KEY);
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      const { pathname, search } = location;
      const routeMatch = route => pathname.startsWith(route);
      if (ANALYTICS_TRACKER_ROUTES.some(routeMatch)) {
        ReactGA.pageview(pathname + search);
      }
    }
  }, [initialized, location]);
};

export default useGATracker;
