import './index.sass';

import { bool, node, number, string } from 'prop-types';
import React from 'react';

import alertSuccessIMG from '../../assets/images/alert-success.svg';

/* eslint-disable react/no-danger */
// Types (success as default): warning, success, error, info
// Position (null as default): fullContent

class AlertMessages extends React.Component {
  AlertMessages = React.createRef();

  state = {
    showAlert: false,
  };

  componentDidMount() {
    this.showHideAlert();
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;
    if (prevProps.show !== show) {
      this.showHideAlert(show);
    }
  }

  showHideAlert = async (boolValue) => {
    const { show } = this.props;
    this.setState({
      showAlert: boolValue || show,
    });

    this.setTimer();
  };

  setTimer = () => {
    const { timer, timerEndCallback } = this.props;

    setTimeout(() => {
      this.setState({ showAlert: false });
      if (timerEndCallback) timerEndCallback();
    }, timer * 1000);
  };

  render() {
    const { position, type, message, className } = this.props;

    const { showAlert } = this.state;

    const icon = () => {
      switch (type) {
        case 'error': {
          return <i className="icon-error" />;
        }

        case 'warning': {
          return <i className="icon-warning" />;
        }

        case 'info': {
          return <i className="icon-info" />;
        }

        default: {
          return <img src={alertSuccessIMG} alt="v" />;
        }
      }
    };

    if (showAlert) {
      return (
        <div
          ref={(el) => {
            this.AlertMessages = el;
          }}
          className={`AlertMessages AlertMessages-${type} ${
            position ? 'AlertMessages-fullContent' : ''
          } ${className}`}
        >
          {icon()}
          <span className="AlertMessages-message" dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      );
    }

    return null;
  }
}

AlertMessages.propTypes = {
  className: string,
  message: node.isRequired,
  position: string,
  show: bool,
  timer: number, // seconds
  type: string,
};

AlertMessages.defaultProps = {
  className: '',
  position: null,
  show: true,
  timer: 4, // seconds
  type: 'success',
};

export default AlertMessages;
