import { bool, func, number, oneOfType, string } from 'prop-types';
import React from 'react';
import uuidv1 from 'uuid/v1';

import SELECT_OPTIONS_TYPE from '../../../types/selectOptions';
import CustomSelect from '../../CustomSelect';

class FiltersModalSelect extends React.Component {
  state = { id: uuidv1() };

  componentDidMount() {
    const { value } = this.props;
    this.setState({ value });
  }

  handleInputChange(value) {
    const { onChange } = this.props;
    this.setState({ value });
    onChange(value);
  }

  render() {
    const { id, value } = this.state;
    const { className, disabled, label, name, options, placeholder, required } = this.props;
    const providedValue = options.find(({ value: optionValue }) => value === optionValue);

    return (
      <fieldset className={className}>
        <legend>
          {label}

          {required && <span className="required-asterisk">*</span>}
        </legend>

        <CustomSelect
          disabled={disabled}
          id={id}
          name={name}
          onChange={({ value: selectedValue }) => this.handleInputChange(selectedValue)}
          options={options}
          required={required}
          placeholder={placeholder}
          value={providedValue}
        />
      </fieldset>
    );
  }
}

FiltersModalSelect.propTypes = {
  className: string,
  disabled: bool,
  label: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  options: SELECT_OPTIONS_TYPE,
  placeholder: string,
  required: bool,
  value: oneOfType([number, string]),
};

FiltersModalSelect.defaultProps = {
  className: '',
  disabled: false,
  options: [],
  placeholder: '',
  required: false,
  value: undefined,
};

export default FiltersModalSelect;
