import './index.sass';

// import { HubConnectionBuilder } from '@microsoft/signalr';
import { isEmpty } from 'lodash';
import { bool, func, number, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

import { getVideoDetails } from '../../../actions/businesses';
import { acceptFlagged } from '../../../actions/flaggedContent';
import flagImg from '../../../assets/images/flag-32.png';
import AsyncContent from '../../../components/AsyncContent';
import CustomSelect from '../../../components/CustomSelect';
import VideoDetailsComponent from '../../../components/FlaggedVideoDetails';
import { Col, Row } from '../../../components/Grid';
import HamburgerMenu from '../../../components/HamburgerMenu';
import LightBox from '../../../components/LightBox';
import LikesCommentsSavings from '../../../components/LikesCommentsSavings';
import Loader from '../../../components/Loader';
import MasterPage from '../../../components/MasterPage';
import VideoDetailsHeader from '../../../components/VideoDetailsHeader';
import { POST_REVIEW_COMMENTS, POST_REVIEW_STATUSES } from '../../../config/gig-status';
// import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../config/pagination';
import USER_ROLES from '../../../config/user-roles';
// import { REACT_APP_SOCKET_URL } from '../../../config/variables';
import HISTORY_TYPE from '../../../types/history';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';
import VIDEO_TYPE from '../../../types/video';
import { APIRequest } from '../../../utils/api';
import FLAG_STATUS from '../../../config/flag-status';

class FlaggedContentVideoDetails extends React.Component {
  state = {
    acceptaleModalOpened: false,
    deleteModalOpened: false,
    loadingComplete: false,
    isUpdatingFlag: false,
    IsUserAllowedToEdit: false,
    flagOptions: [],
    commentOptions: [],
    statusHistory: [],
    currentFlagStatus: null,
    currentCommentStatus: null,
    selectedFlagStatus: null,
    selectedComment: null,
    activeFlagID: null,
    connection: null,
    frankiUserId: localStorage.getItem('frankiUserId'),
    errorMessage: '',
    successMessage: '',
  };

  userRole = localStorage.getItem('frankiRole');

  async componentDidMount() {
    // const { frankiUserId } = this.state;
    // const newConnection = new HubConnectionBuilder()
    //   .withUrl(`${REACT_APP_SOCKET_URL}?user=${frankiUserId}`)
    //   .withAutomaticReconnect()
    //   .build();
    // this.setState({ connection: newConnection });

    const {
      match: {
        params: { ContentKey },
      },
    } = this.props;
    this.getStatusHistory(ContentKey);
    await this.getVideoDetails();
    this.setFlagStatusOptions();
    const {
      videoDetails: { Status, IsUserAllowedToEdit },
    } = this.props;
    if (Status) {
      const { CurrentStatusId: FlaggedStatus, CommentId } = Status;

      this.setState({
        currentFlagStatus: POST_REVIEW_STATUSES.filter((f) => f.value == FlaggedStatus)[0],
        currentCommentStatus: POST_REVIEW_COMMENTS.filter((f) => f.value == CommentId)[0],
        selectedFlagStatus: POST_REVIEW_STATUSES.filter((f) => f.value == FlaggedStatus)[0],
        selectedComment: POST_REVIEW_COMMENTS.filter((f) => f.value == CommentId)[0],
        activeFlagID: FlaggedStatus,
        IsUserAllowedToEdit,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      match: {
        params: { ContentKey },
      },
      // history,
    } = this.props;
    const { 
      // connection, 
      activeFlagID 
    } = this.state;
    if (prevProps.match.params.ContentKey !== ContentKey) {
      this.getVideoDetails(ContentKey);
    }
    // if (prevState.connection !== connection) {
    //   if (connection) {
    //     connection
    //       .start()
    //       .then(() => {
    //         connection.on('ContentDeleted', () => {
    //           history.push(`/admin/flagged-contents/${DEFAULT_PAGE_INDEX}/${DEFAULT_PAGE_SIZE}`);
    //         });
    //       })
    //       .catch((e) => console.error('Connection failed: ', e));
    //   }
    // }
    if (prevState.activeFlagID !== activeFlagID) {
      await APIRequest({
        uri: `Video/${ContentKey}`,
        method: 'GET',
      })
        .then(({ Data }) => {
          const { CurrentStatusId: FlaggedStatus, CommentId } = Data.Status;
          this.setState({
            currentFlagStatus: POST_REVIEW_STATUSES.filter((f) => f.value == FlaggedStatus)[0],
            currentCommentStatus: POST_REVIEW_COMMENTS.filter((f) => f.value == CommentId)[0],
            selectedFlagStatus: POST_REVIEW_STATUSES.filter((f) => f.value == FlaggedStatus)[0],
            selectedComment: POST_REVIEW_COMMENTS.filter((f) => f.value == CommentId)[0],
            activeFlagID: FlaggedStatus,
          });
        })
        .catch(() => {});
    }
  }

  setFlagStatusOptions = (statusId) => {
    const {
      videoDetails: { Status },
    } = this.props;
    if (Status) {
      const { CurrentStatusId } = Status;
      const optionsComment = POST_REVIEW_COMMENTS; // not filtered yet?
      this.setState({
        flagOptions: this.getFlagStatusBasedOnRoles(statusId || CurrentStatusId),
        commentOptions: optionsComment,
        activeFlagID: statusId || CurrentStatusId,
      });
    }
  };

  getVideoDetails = async () => {
    const {
      videoDetails,
      getVideoDetails: getVideoDetailsAction,
      match: {
        params: { ContentKey },
      },
    } = this.props;
    const idChanged = videoDetails.Key && videoDetails.Key !== ContentKey;

    if (isEmpty(videoDetails) || idChanged) {
      // TODO: Replace hard code
      await getVideoDetailsAction(ContentKey);
    }
  };

  onHandleDeleteModal = (value) => {
    this.setState({
      deleteModalOpened: value,
    });
  };

  onHandleAcceptaleModal = (value) => {
    this.setState({
      acceptaleModalOpened: value,
    });
  };

  getStatusHistory = async (contentKey) => {
    await APIRequest({
      uri: `Content/${contentKey}/StatusHistory`,
      method: 'GET',
    })
      .then((res) => {
        const { Success, Data } = res;
        if (Success) {
          this.setState({ statusHistory: Data });
          const dataSize = Data.length;
          if (dataSize > 0) {
            const {
              UpdatedStatus: { StatusId },
            } = Data[dataSize - 1];
            this.setFlagStatusOptions(StatusId);
          }
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: error,
        });
      })
      .finally(() => {
        this.setState({ isUpdatingFlag: false });
      });
  };

  getFlagStatusBasedOnRoles = (CurrentStatusId) => {
    let options = POST_REVIEW_STATUSES;
    let targets = [];
    if (this.userRole === USER_ROLES.contentAdmin) {
      if (CurrentStatusId === FLAG_STATUS.PENDING_REVIEW) {
        targets = [FLAG_STATUS.IN_REVIEW];
      } else if (CurrentStatusId === FLAG_STATUS.IN_REVIEW) {
        targets = [FLAG_STATUS.FINAL_REVIEW, FLAG_STATUS.ACCEPTABLE];
      }
    } else if (this.userRole === USER_ROLES.saleAdmin) {
      if (
        [
          FLAG_STATUS.PENDING_REVIEW,
          FLAG_STATUS.IN_REVIEW,
          FLAG_STATUS.FINAL_REVIEW,
          FLAG_STATUS.ACCEPTABLE,
        ].includes(CurrentStatusId)
      ) {
        targets = [FLAG_STATUS.IN_FINAL_REVIEW];
      } else if (CurrentStatusId === FLAG_STATUS.IN_FINAL_REVIEW) {
        targets = [FLAG_STATUS.ADMIN_REVIEW, FLAG_STATUS.ACCEPTABLE];
      }
    } else if (this.userRole === USER_ROLES.admin) {
      if (
        [
          FLAG_STATUS.PENDING_REVIEW,
          FLAG_STATUS.IN_REVIEW,
          FLAG_STATUS.FINAL_REVIEW,
          FLAG_STATUS.IN_FINAL_REVIEW,
          FLAG_STATUS.ADMIN_REVIEW,
          FLAG_STATUS.ACCEPTABLE,
        ].includes(CurrentStatusId)
      ) {
        targets = [FLAG_STATUS.IN_ADMIN_REVIEW];
      } else if (CurrentStatusId === FLAG_STATUS.IN_ADMIN_REVIEW) {
        targets = [FLAG_STATUS.ACCEPTABLE, FLAG_STATUS.REMOVE_FOREVER];
      }
    }
    options = POST_REVIEW_STATUSES.filter((f) => targets.includes(f.value));
    return options;
  };

  onHandleAcceptaleFlag = async () => {
    const {
      videoDetails: { Key },
    } = this.props;
    const { selectedFlagStatus, selectedComment, currentFlagStatus, currentCommentStatus } =
      this.state;
    if ([3, 5, 8].includes(selectedFlagStatus.value) && !selectedComment) {
      this.setState({ errorMessage: 'Please select comment' });
      return;
    }
    this.setState({ isUpdatingFlag: true });
    let commentId_ = null;
    if (selectedComment && selectedFlagStatus.value != 7) commentId_ = selectedComment.value;
    await APIRequest({
      uri: `Content/${Key}/AdjustStatus`,
      method: 'POST',
      data: {
        NewStatusId: selectedFlagStatus.value,
        CommentId: commentId_,
      },
    })
      .then((res) => {
        const { Success, Message } = res;
        if (Success) {
          this.setState({
            currentFlagStatus: selectedFlagStatus,
            currentCommentStatus: selectedComment,
            successMessage: Message,
            flagOptions: this.getFlagStatusBasedOnRoles(selectedFlagStatus.value),
          });
          this.getStatusHistory(Key);
        }
        // history.push('/admin/flagged-contents');
      })
      .catch((error) => {
        this.setState({
          errorMessage: error,
          selectedFlagStatus: currentFlagStatus,
          selectedComment: currentCommentStatus,
        });
      })
      .finally(() => {
        this.setState({ isUpdatingFlag: false });
        this.setState({ deleteModalOpened: false });
        this.setState({ acceptaleModalOpened: false });
      });
  };

  onChangeFlagStatus = async (val) => {
    this.setState({
      selectedFlagStatus: POST_REVIEW_STATUSES.filter((f) => f.value == val)[0],
    });
  };

  onChangeComment = async (val) => {
    this.setState({
      selectedComment: POST_REVIEW_COMMENTS.filter((f) => f.value == val)[0],
    });
  };

  renderFlagContent = () => {
    const {
      videoDetails: { Status, IsUserAllowedToEdit },
    } = this.props;
    if (Status) {
      const { flagOptions, selectedFlagStatus, isUpdatingFlag } = this.state;

      if (isUpdatingFlag) return <Loader />;

      if (IsUserAllowedToEdit) {
        return (
          <CustomSelect
            id="contentStatusIds"
            name="contentStatusIds"
            label="Flag Status"
            onChange={({ value }) => this.onChangeFlagStatus(value)}
            options={flagOptions}
            placeholder={selectedFlagStatus != null ? selectedFlagStatus.label : 'Review Status'}
            value={selectedFlagStatus}
            isSearchable={false}
          />
        );
      }
      return (
        <span className="GigSubmissions__Prizes__TotalPrize">
          {selectedFlagStatus != null ? selectedFlagStatus.label : 'Unknown'}
        </span>
      );
    }
  };

  renderStatusCommentContent = () => {
    const {
      videoDetails: { Status, IsUserAllowedToEdit },
    } = this.props;
    if (Status) {
      const { commentOptions, selectedComment, selectedFlagStatus, isUpdatingFlag } = this.state;
      const isDisabled = selectedFlagStatus && selectedFlagStatus.value == 7;
      if (isUpdatingFlag) return <Loader />;

      if (IsUserAllowedToEdit) {
        return (
          <CustomSelect
            disabled={isDisabled}
            id="commentStatus"
            name="comment"
            label="Defination"
            onChange={({ value }) => this.onChangeComment(value)}
            options={commentOptions}
            placeholder={selectedComment != null ? selectedComment.label : 'Select Comment'}
            value={selectedComment}
            isSearchable={false}
          />
        );
      }
      return (
        <span className="GigSubmissions__Prizes__TotalPrize">
          {selectedComment != null ? selectedComment.label : 'No Comment'}
        </span>
      );
    }
  };

  renderUpdateStatusButton = () => {
    const { selectedFlagStatus, currentFlagStatus, IsUserAllowedToEdit, loadingComplete } =
      this.state;
    if (
      [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.contentAdmin].includes(this.userRole) &&
      selectedFlagStatus
    ) {
      const isdisabled = currentFlagStatus.value == selectedFlagStatus.value;
      return (
        <button
          type="button"
          className="button small orange-theme"
          onClick={() => this.onHandleAcceptaleFlag()}
          disabled={loadingComplete || !IsUserAllowedToEdit || isdisabled}
          style={{ whiteSpace: 'nowrap' }}
        >
          Update Status
        </button>
      );
    }
  };

  render() {
    const {
      auth,
      match: {
        params: { ItemId },
      },
      videoDetails,
      videoDetailsError,
      isFetchingVideoDetails,
      history,
    } = this.props;
    const PrizeAwarded = videoDetails.PrizeAwardedAmount ? videoDetails.PrizeAwardedAmount : 0;
    const {
      deleteModalOpened,
      acceptaleModalOpened,
      currentFlagStatus,
      statusHistory,
      errorMessage,
      successMessage,
    } = this.state;
    const videoContentId = videoDetails.ContentId || 'not found';
    return (
      <>
        <Helmet title="Franki Admin: video details" />
        <MasterPage
          auth={auth}
          headerBreadcrumb={[
            { name: 'Flagged content', url: '/admin/flagged-contents' },
            { name: 'video details', bold: true },
          ]}
        >
          <AsyncContent
            onHandleCloseDialog={() => {
              if (videoDetailsError) {
                if (this.userRole == USER_ROLES.contentAdmin) {
                  history.push(
                    '/admin/flagged-contents/8/1?orderBy=LastFlaggedOn&contentStatusIds=1,2',
                  );
                } else if (this.userRole == USER_ROLES.saleAdmin) {
                  history.push(
                    '/admin/flagged-contents/8/1?orderBy=LastFlaggedOn&contentStatusIds=3,4',
                  );
                } else if (this.userRole == USER_ROLES.admin) {
                  history.push(
                    '/admin/flagged-contents/8/1?orderBy=LastFlaggedOn&contentStatusIds=5,6',
                  );
                }
              }
              if (errorMessage || successMessage) {
                this.setState({ errorMessage: '', successMessage: '' });
              }
            }}
            content={(detaiils) => (
              <div className="flagged-videos-detail">
                <Row>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <Col
                      lg={8}
                      md={8}
                      sm={12}
                      xs={12}
                      // className="HeaderWrapper"
                    >
                      {' '}
                      <VideoDetailsHeader videoDetails={detaiils} additionalText="posted on " />
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className="flagStatusDivWrapper">
                      {' '}
                      {currentFlagStatus && (
                        <div className="flagStatusDiv">
                          <img
                            alt="flag status"
                            color="white"
                            style={{
                              backgroundColor: `${currentFlagStatus.color || ''}`,
                              padding: 5,
                              border: `solid 2px ${currentFlagStatus.color}`,
                              borderRadius: '10px',
                            }}
                            width={24}
                            height={24}
                            src={flagImg}
                          />
                          <span> {currentFlagStatus.label}</span>
                        </div>
                      )}
                    </Col>
                  </Col>
                  <HamburgerMenu
                    itemClassName="black-text fw400"
                    menuItems={[
                      {
                        innerContent: `Video ID: ${videoContentId}`,
                      },
                    ]}
                  />
                </Row>
                <Row rowSpacing={25} cellSpacing={15}>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <VideoDetailsComponent videoDetails={detaiils} />
                  </Col>

                  <Col lg={6} md={6} sm={12} xs={12} className="RowWrapper">
                    <LikesCommentsSavings
                      likes={videoDetails.Likes}
                      comments={videoDetails.Comments}
                      savingsCount={videoDetails.Saving}
                      statusHistory={statusHistory}
                    />
                    <Row rowSpacing={25} cellSpacing={15}>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <div className="form-group has-tooltip" style={{ width: 'inherit' }}>
                          {this.renderFlagContent()}
                        </div>
                      </Col>

                      <Col lg={6} md={6} sm={12} xs={12}>
                        <div className="form-group has-tooltip" style={{ width: 'inherit' }}>
                          {this.renderStatusCommentContent()}
                        </div>
                      </Col>
                    </Row>
                    <div className="GigSubmissions__Prizes">
                      <div className="ButtomWrapper">
                        <span className="GigSubmissions__Prizes__Title">
                          Prizes awarded
                          <span className="GigSubmissions__Prizes__TotalPrize">
                            {`total = $${PrizeAwarded}`}
                          </span>
                        </span>
                        {this.renderUpdateStatusButton()}
                      </div>
                    </div>
                  </Col>
                </Row>
                <LightBox
                  className="BusinessVideoDetails__Modal"
                  show={deleteModalOpened}
                  title="delete video"
                  style={{
                    width: 400,
                    height: null,
                    marginLeft: -250,
                    marginTop: -200,
                  }}
                  onClose={() => {
                    this.onHandleDeleteModal(false);
                  }}
                >
                  <p className="BusinessVideoDetails__Modal__Description">
                    are you sure you want to delete this video?
                  </p>
                  <p className="BusinessVideoDetails__Modal__SmallerDescription">
                    all the video information will be lost forever
                  </p>

                  <ul className="BusinessVideoDetails__Modal__Actions">
                    <li>
                      <button
                        type="button"
                        className="button small"
                        onClick={() => this.onHandleDeleteModal(false)}
                      >
                        no, cancel
                      </button>
                    </li>

                    <li>
                      <button
                        type="button"
                        className="button small red-theme"
                        onClick={() => this.onHandleAcceptaleFlag(ItemId, false)}
                      >
                        yes, delete
                      </button>
                    </li>
                  </ul>
                </LightBox>

                <LightBox
                  className="BusinessVideoDetails__Modal"
                  show={acceptaleModalOpened}
                  title="Update Status"
                  style={{
                    width: 400,
                    height: null,
                    marginLeft: -250,
                    marginTop: -200,
                  }}
                  onClose={() => {
                    this.onHandleAcceptaleModal(false);
                  }}
                >
                  <p className="BusinessVideoDetails__Modal__Description">
                    Are you sure you want to update status of this video?
                  </p>

                  <ul className="BusinessVideoDetails__Modal__Actions">
                    <li>
                      <button
                        type="button"
                        className="button small"
                        onClick={() => this.onHandleAcceptaleModal(false)}
                      >
                        No, cancel
                      </button>
                    </li>

                    <li>
                      <button
                        type="button"
                        className="button small red-theme"
                        onClick={() => this.onHandleAcceptaleFlag(ItemId, true)}
                      >
                        Yes
                      </button>
                    </li>
                  </ul>
                </LightBox>
              </div>
            )}
            errorMessage={videoDetailsError || errorMessage}
            successMessage={successMessage}
            expectedData={videoDetails}
            loading={isFetchingVideoDetails}
          />
        </MasterPage>
      </>
    );
  }
}

FlaggedContentVideoDetails.propTypes = {
  acceptFlagged: func.isRequired,
  auth: bool.isRequired,
  getVideoDetails: func.isRequired,
  history: HISTORY_TYPE.isRequired,
  match: ROUTER_MATCH_TYPE({
    ItemId: number,
    ContentKey: number,
  }).isRequired,
  videoDetails: VIDEO_TYPE.isRequired,
  videoDetailsError: string.isRequired,
};

const mapStateToProps = (state) => ({
  videoDetails: state.businesses.videoDetails,
  videoDetailsError: state.businesses.videoDetailsError,
  isFetchingVideoDetails: state.businesses.isFetchingVideoDetails,
});

export default connect(mapStateToProps, {
  getVideoDetails,
  acceptFlagged,
})(FlaggedContentVideoDetails);
