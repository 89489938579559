import './index.sass';

import { isEmpty } from 'lodash';
import { func, number, oneOfType, string } from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';

import { COLORS } from '../../../config/colors';
import SELECT_OPTIONS_TYPE from '../../../types/selectOptions';
import filtersOrderBySelectBoxStyles from './filtersOrderBySelectBoxStyles';

const DROP_DOWN_ACTIVE_BACKGROUND_COLOR = '#F7F7F7';

export default function FiltersOrderBy(props) {
  const { options, value: selectedValue, onChange, labelText } = props;

  const selectedOption = useMemo(
    () => options.find(({ value }) => selectedValue === value),
    [options, selectedValue],
  );

  useEffect(() => {
    if (selectedOption === undefined && options.length) {
      onChange(options[0]);
    }
  }, [selectedOption]);

  if (isEmpty(options)) {
    return null;
  }

  return (
    <>
      <label className="FiltersOrderBy__Label" htmlFor="orderBy">
        {labelText}
      </label>

      <Select
        classNamePrefix="FiltersOrderBy__Select"
        id="orderBy"
        onChange={onChange}
        options={options}
        styles={filtersOrderBySelectBoxStyles}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: DROP_DOWN_ACTIVE_BACKGROUND_COLOR,
            primary25: DROP_DOWN_ACTIVE_BACKGROUND_COLOR,
            primary50: DROP_DOWN_ACTIVE_BACKGROUND_COLOR,
            neutral50: COLORS.gray,
            neutral80: COLORS.gray,
          },
          spacing: {
            ...theme.spacing,
            controlHeight: 35,
          },
        })}
        value={selectedOption}
      />
    </>
  );
}

FiltersOrderBy.propTypes = {
  onChange: func.isRequired,
  options: SELECT_OPTIONS_TYPE,
  value: oneOfType([number, string]),
  labelText: string,
};

FiltersOrderBy.defaultProps = {
  options: [],
  value: null,
  labelText: 'Sort by',
};
