import './index.sass';

import { isEmpty, keys } from 'lodash';
import { bool, func, number, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import uuidv1 from 'uuid/v1';

import {
  cleanDeleteVideo as cleanDeleteVideoAction,
  deleteVideo as deleteVideoAction,
  getVideos as getVideosAction,
} from '../../../actions/videos';
import AsyncContent from '../../../components/AsyncContent';
import VideoCard from '../../../components/Cards/VideoCard';
import Filters from '../../../components/Filters';
import { Col, Row } from '../../../components/Grid';
import LightBox from '../../../components/LightBox';
import MasterPage from '../../../components/MasterPage';
import Paginator from '../../../components/Paginator';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../config/pagination';
import USER_ROLES from '../../../config/user-roles';
import str from '../../../helpers/string';
import HISTORY_TYPE from '../../../types/history';
import LOCATION_TYPE from '../../../types/location';
import RESPONSE_LIST_OF_TYPE from '../../../types/responseListOf';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';
import VIDEO_TYPE from '../../../types/video';
import asyncLocalStorage from '../../../utils/asyncLocalStorage';
import { isFoursquareData } from '../../../utils/placeId';
import VideoDetails from '../VideoDetails';

const BUSINESS_SOURCES = ['FrankiLocalBusiness', 'GooglePlaceBusiness', 'FoursquarePlaceVideos'];

const getQueryParams = (searchQueryParams) => {
  const params = {};

  const query = new URLSearchParams(searchQueryParams);

  ['businessSource', 'search', 'sortingType', 'users', 'contentStatusIds'].forEach((term) => {
    const termValue = query.get(term);
    if (termValue) {
      params[term] = termValue;
    }
  });

  const hasGigs = query.get('hasGigs');
  switch (hasGigs) {
    case 'true':
      params.HasGig = true;
      break;
    case 'false':
      params.HasGig = false;
      break;
    default:
      delete params.HasGig;
      break;
  }

  const rating = query.get('rating');
  switch (rating) {
    case 'true':
      params.Rating = true;
      break;
    case 'false':
      params.Rating = false;
      break;
    default:
      delete params.Rating;
      break;
  }

  return params;
};

/* global window */
class Videos extends React.Component {
  state = {
    userRole: null,
    selectedVideo: null,
    confirmingDeleteVideo: null,
  };

  popStateListener(event) {
    const el = document.querySelectorAll('.pagination > .active a');
    if (el.length > 0) {
      const txt = Number(el[0].textContent);
      if (txt === 1) window.history.go(-3);
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;

    this.getUserRole();

    if (!pageNumber || !itemsCountPerPage) {
      this.updatePagination(
        Number(itemsCountPerPage) || DEFAULT_PAGE_SIZE,
        Number(pageNumber) || DEFAULT_PAGE_INDEX,
      );
    }

    this.getVideos();
    window.addEventListener('popstate', this.popStateListener);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.popStateListener);
  }

  componentWillUpdate(nextProps) {
    const { isDeletingVideoSuccess } = this.props;

    if (!isDeletingVideoSuccess && nextProps.isDeletingVideoSuccess) {
      this.onDeleteVideoSuccess();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search },
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;

    if (prevProps.location.search !== search) {
      if (Number(pageNumber) !== DEFAULT_PAGE_INDEX) {
        this.updatePagination(Number(itemsCountPerPage), DEFAULT_PAGE_INDEX);
        return;
      }
      this.getVideos();
    } else if (
      prevProps.match.params.pageNumber !== pageNumber ||
      prevProps.match.params.itemsCountPerPage !== itemsCountPerPage
    ) {
      this.getVideos();
    }
  }

  onDeleteVideoSuccess() {
    const {
      videos: { Results },
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
      cleanDeleteVideo,
    } = this.props;

    if (Results.length === 1 && pageNumber > 1) {
      this.updatePagination(itemsCountPerPage, pageNumber - 1);
    } else {
      this.getVideos();
    }

    cleanDeleteVideo();
  }

  async getUserRole() {
    const userRole = await asyncLocalStorage.getItem(str.localStorage.role);
    this.setState({ userRole });
  }

  async getVideos() {
    const {
      getVideos,
      location: { search },
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;
    const params = getQueryParams(search);
    if (isEmpty(params) || !params.sortingType) {
      return;
    }

    if (!params.businessSource) {
      this.setBusinessSource(BUSINESS_SOURCES[0]);
      return;
    }
    const businessSource =
      BUSINESS_SOURCES.findIndex((item) => item === params.businessSource) + 1 || 1;
    const revisedSearchQuery = `?${keys(params)
      .map((key) => {
        if (key === 'users') {
          return params.users
            .split(',')
            .map((user) => `userIds=${user.split(';')[1]}`)
            .join('&');
        }
        if (key === 'businessSource' && params.businessSource === 'FoursquarePlaceVideos') {
          return `BusinessSource=${businessSource}`;
        }
        return `${key}=${params[key]}`;
      })
      .join('&')}`;
    await getVideos(revisedSearchQuery, itemsCountPerPage, pageNumber);
  }

  setBusinessSource(businessSource) {
    const {
      history,
      location: { search },
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;
    const params = getQueryParams(search);

    history.push({
      pathname: `/admin/videos/${itemsCountPerPage}/${pageNumber}`,
      search: `sortingType=${params.sortingType}&businessSource=${businessSource}`,
    });
  }

  updatePagination(itemsCountPerPage, pageNumber) {
    const {
      history,
      location: { search },
    } = this.props;

    history.push({
      pathname: `/admin/videos/${itemsCountPerPage}/${pageNumber}`,
      search,
    });
  }

  render() {
    const {
      auth,
      isFetchingVideos,
      location: { search },
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
      videos: { Results, TotalItems },
      videosError,
      deleteVideo,
      deletingErrorMessage,
      isDeletingError,
    } = this.props;

    const showFoursquareData = isFoursquareData();
    const queryParams = getQueryParams(search);
    const { selectedVideo, userRole, confirmingDeleteVideo } = this.state;
    const masterPageConfig = { auth };
    const lightBoxWidth = Math.min(1373, window.innerWidth - 30);
    const lightBoxHeight = Math.min(750, window.innerHeight - 30);

    if (TotalItems > 0) {
      masterPageConfig.headerBreadcrumb = [{ name: 'Home', url: '/' }, { name: 'All Posts' }];
    }

    return (
      <>
        <Helmet title="Franki Admin: all videos" />

        <MasterPage {...masterPageConfig}>
          <Filters
            sorterItems={[
              { label: 'recently posted', value: '1' },
              { label: 'likes', value: '2' },
              { label: 'comments', value: '3' },
            ]}
            activeFilterNames={[
              'sortingType',
              'search',
              'users',
              'rating',
              'hasGigs',
              'contentStatusIds',
            ]}
            content={
              <fieldset className="Videos__TypeFilter">
                <label>
                  <input
                    checked={queryParams.businessSource === BUSINESS_SOURCES[0]}
                    name="businessSource"
                    onChange={() => this.setBusinessSource(BUSINESS_SOURCES[0])}
                    type="radio"
                  />
                  Franki business videos
                </label>

                {showFoursquareData ? (
                  <label>
                    <input
                      checked={queryParams.businessSource === BUSINESS_SOURCES[2]}
                      name="businessSource"
                      onChange={() => this.setBusinessSource(BUSINESS_SOURCES[2])}
                      type="radio"
                    />
                    Foursquare Place Videos
                  </label>
                ) : (
                    <label>
                      <input
                        checked={queryParams.businessSource === BUSINESS_SOURCES[1]}
                        name="businessSource"
                        onChange={() => this.setBusinessSource(BUSINESS_SOURCES[1])}
                        type="radio"
                      />
                    Google Place Videos
                    </label>
                  )}
              </fieldset>
            }
            modalHeight={null}
          />
          <Paginator
            isTopPaginator
            itemsCountPerPage={Number(itemsCountPerPage)}
            onHandlePage={(...params) => this.updatePagination(...params)}
            onHandleShow={(...params) => this.updatePagination(...params)}
            pageIndex={Number(pageNumber)}
            totalItems={TotalItems}
          />

          <AsyncContent
            content={(list) => (
              <Row cellSpacing={10} rowSpacing={10} verticalAlign="stretch">
                {list.map((video) => (
                  <Col lg={4} md={6} sm={12} xs={12} key={uuidv1()}>
                    <VideoCard
                      getVideos={() => this.getVideos()}
                      disabled={video.Disabled}
                      click={() => {
                        this.setState({ selectedVideo: video });
                      }}
                      item={video}
                      hamburgerMenuItems={[
                        {
                          innerContent: 'Flag',
                          url: '/',
                        },
                        {
                          innerContent: 'Promote',
                        },
                        ...(userRole !== USER_ROLES.businessManager
                          ? [
                            {
                              innerContent: 'Download',
                            },
                          ]
                          : []),
                        {
                          innerContent: 'Delete',
                          url: '/',
                        },
                      ]}
                    />
                  </Col>
                ))}
              </Row>
            )}
            errorMessage={videosError}
            expectedData={Results}
            loading={isFetchingVideos}
          />
        </MasterPage>

        <LightBox
          height={lightBoxHeight}
          onClose={() => {
            this.setState({ selectedVideo: null });
            this.getVideos();
          }}
          show={!!selectedVideo}
          width={lightBoxWidth}
        >
          {selectedVideo && (
            <VideoDetails
              item={selectedVideo}
              contentKey={selectedVideo.Key}
              onDeleted={() =>
                this.setState({
                  selectedVideo: null,
                  confirmingDeleteVideo: selectedVideo.Key,
                })
              }
            />
          )}
        </LightBox>

        <LightBox show={!!confirmingDeleteVideo} title="Delete Video" onClose={() => { }}>
          <p className="AsyncContent__SuccessMessage">Are you sure to delete this video?</p>
          <div>
            <button
              className="button small purple-theme"
              onClick={() => {
                this.setState({ confirmingDeleteVideo: null });
              }}
              type="button"
            >
              Cancel
            </button>

            <button
              className="button small orange-theme"
              type="button"
              onClick={() => {
                deleteVideo(confirmingDeleteVideo);
                this.setState({ confirmingDeleteVideo: null });
              }}
            >
              Delete
            </button>
          </div>
        </LightBox>

        <LightBox show={isDeletingError} title="Delete Video Failed" onClose={() => { }}>
          <p className="AsyncContent__ErrorMessage">{deletingErrorMessage}</p>
        </LightBox>
      </>
    );
  }
}

Videos.propTypes = {
  auth: bool.isRequired,
  cleanDeleteVideo: func.isRequired,
  getVideos: func.isRequired,
  history: HISTORY_TYPE.isRequired,
  isFetchingVideos: bool.isRequired,
  location: LOCATION_TYPE.isRequired,
  match: ROUTER_MATCH_TYPE({ pageNumber: string, itemsCountPerPage: string }).isRequired,
  totalItems: number,
  videos: RESPONSE_LIST_OF_TYPE(VIDEO_TYPE).isRequired,
  videosError: string.isRequired,
  deleteVideo: func.isRequired,
  deletingErrorMessage: string.isRequired,
  isDeletingError: bool.isRequired,
  isDeletingVideoSuccess: bool.isRequired,
};

Videos.defaultProps = {
  totalItems: null,
};

const mapStateToProps = (state) => {
  const {
    isFetchingVideos,
    videos,
    videosError,
    isDeletingError,
    isDeletingVideoSuccess,
    deletingErrorMessage,
  } = state.videos;

  return {
    isFetchingVideos,
    totalItems: videos ? videos.TotalItems : 0,
    videos,
    videosError,
    isDeletingVideoSuccess,
    isDeletingError,
    deletingErrorMessage,
  };
};

export default connect(mapStateToProps, {
  getVideos: getVideosAction,
  deleteVideo: deleteVideoAction,
  cleanDeleteVideo: cleanDeleteVideoAction,
})(Videos);
