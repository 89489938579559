import './index.sass';

import React from 'react';

const RegisterFooter = () => (
  <div className="RegisterFooter">
    <div className="wrapper">
      <span className="RegisterFooter__Copyright">© 2021 Franki. ALL RIGHTS RESERVED</span>

      <div>
        <a href="https://befranki.com/privacy-policy" className="RegisterFooter__Links">
          privacy and policy
        </a>
        <a
          href="https://befranki.com/terms-of-use"
          className="RegisterFooter__Links"
          target="_blank"
        >
          terms of service
        </a>
      </div>
    </div>
  </div>
);

export default RegisterFooter;
