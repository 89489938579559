import './index.sass';
import 'react-datepicker/dist/react-datepicker.css';

import { bool, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import WelcomeImg from '../../../../assets/images/welcome3.jpg';
import AlertMessages from '../../../../components/AlertMessages';
import MasterPage from '../../../../components/MasterPage';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../../config/pagination';
import ROUTER_MATCH_TYPE from '../../../../types/routerMatch';

const BusinessGigCreateSuccessfully = (props) => {
  const {
    auth,
    match: {
      params: { corporateGroupId },
    },
  } = props;
  const link = `/admin/business-gigs/${corporateGroupId}/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`;
  return (
    <>
      <Helmet title="Franki Admin: create gig" />

      <MasterPage auth={auth}>
        <AlertMessages
          className="BusinessGigCreateSuccessfully__AlertMessages"
          show
          type="success"
          message="Your payment method was confirmed"
        />

        <div className="BusinessGigCreateSuccessfully__Container">
          <figure className="BusinessGigCreateSuccessfully__Image">
            <img src={WelcomeImg} alt="Your payment method was confirmed" width="225px" />
          </figure>

          <h1 className="BusinessGigCreateSuccessfully__Title">Your Gig is Live!</h1>

          <div className="BusinessGigCreateSuccessfully__Content">
            You can see all of your invoices in the 'Billing' Tab. Please be sure to check your GiG
            status regularly so you can award prizes once your GiG is completed.
          </div>

          <Link to={link} className="button orange-theme BusinessGigCreateSuccessfully__Button">
            GOT IT
          </Link>
        </div>
      </MasterPage>
    </>
  );
};

BusinessGigCreateSuccessfully.propTypes = {
  auth: bool.isRequired,
  match: ROUTER_MATCH_TYPE({ corporateGroupId: string.isRequired }).isRequired,
};

export default BusinessGigCreateSuccessfully;
