import './index.sass';

import { arrayOf, bool, func, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import uuidv1 from 'uuid/v1';

import {
  deletePromotion,
  getPromotionContents as getPromotionContentsAction,
  getPromotions as getPromotionsAction,
} from '../../../actions/promotions';
import AsyncContent from '../../../components/AsyncContent';
import { Col, Row } from '../../../components/Grid';
import LightBox from '../../../components/LightBox';
import Loader from '../../../components/Loader';
import MasterPage from '../../../components/MasterPage';
import NotFound from '../../../components/NotFound';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../config/pagination';
import HISTORY_TYPE from '../../../types/history';
import PROMOTION_TYPE from '../../../types/promotion';
import PROMOTION_CONTENT_TYPE from '../../../types/promotionContent';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';

class PromotionDetails extends React.Component {
  state = {
    deletePromotionModalVisible: false,
    deleting: false,
  };

  componentDidMount() {
    const {
      getPromotions,
      getPromotionContents,
      match: {
        params: { promotionId },
      },
    } = this.props;

    getPromotions();
    getPromotionContents(promotionId);
  }

  async deletePromotion() {
    const {
      history,
      match: {
        params: { promotionId },
      },
    } = this.props;
    this.setState({ deleting: true });
    await deletePromotion(promotionId);
    this.setState({ deletePromotionModalVisible: false });
    history.push(`/admin/promoted-content/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`);
  }

  render() {
    const {
      auth,
      history,
      isFetchingPromotionContents,
      isFetchingPromotions,
      match: {
        params: { promotionId },
      },
      promotionContents,
      promotionContentsError,
      promotions,
      promotionsError,
    } = this.props;

    const { deletePromotionModalVisible, deleting } = this.state;

    const masterPageConfig = {
      auth,
      headerBreadcrumb: [
        {
          name: 'promoted content',
          url: `/admin/promoted-content/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`,
        },
        { name: 'promotion detail' },
      ],
    };

    return (
      <>
        <Helmet title="Franki Admin: Edit promotion" />

        <MasterPage {...masterPageConfig}>
          <AsyncContent
            content={(details) => {
              return (
                <>
                  <Row cellSpacing={8} rowSpacing={15} className="PromotionDetails__Container">
                    <Col
                      lg={3}
                      md={3}
                      sm={4}
                      xs={12}
                      horizontalAlign="flex-start"
                      verticalAlign="center"
                    >
                      <div className="PromotionCard">
                        <div>
                          <div className="PromotionCard__Header">
                            <h3 className="PromotionCard__Subtitle">Discover Promoted</h3>
                          </div>

                          <div className="PromotionCard__Count">
                            <span>{details.PromotionId}</span>
                            <i className="icon-play" />
                          </div>

                          <div className="PromotionCard__Content">
                            <h2 className="PromotionCard__Title">{details.PromotionTitle}</h2>

                            <address className="PromotionCard__Address">
                              {
                                <span title={details.CityNames}>
                                  {' '}
                                  {details.CityNames
                                    ? `${details.CityNames.substr(0, 250)}${
                                        details.CityNames.length > 250 ? '...' : ''
                                      }`
                                    : null}
                                </span>
                              }
                              {
                                <span title={details.StateNames}>
                                  {' '}
                                  {details.StateNames
                                    ? `${details.StateNames.substr(0, 250)}${
                                        details.StateNames.length > 250 ? '...' : ''
                                      }`
                                    : null}
                                </span>
                              }
                            </address>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col
                      lg={9}
                      md={9}
                      sm={8}
                      xs={12}
                      horizontalAlign="flex-start"
                      verticalAlign="center"
                      className="PromotionDetails__Image"
                    >
                      <img
                        alt={details.PromotionTitle}
                        src={details.Photo && details.Photo.FullResolutionUrl}
                      />
                    </Col>
                  </Row>

                  <AsyncContent
                    content={(contents) => (
                      <Row cellSpacing={8} rowSpacing={38} className="PromotionDetails__Content">
                        {contents.map((content) => (
                          <Col
                            lg={3}
                            md={3}
                            sm={4}
                            xs={12}
                            horizontalAlign="flex-start"
                            verticalAlign="center"
                            key={uuidv1()}
                          >
                            <div
                              className="PromotionDetails__Content__Thumbnail"
                              style={{
                                backgroundImage: `url(${
                                  content.VideoThumbnails &&
                                  content.VideoThumbnails[0] &&
                                  content.VideoThumbnails[0].SmallUrl
                                })`,
                              }}
                            />

                            <h3 className="PromotionDetails__Content__BusinessName">
                              {content.BusinessName}
                            </h3>
                          </Col>
                        ))}
                      </Row>
                    )}
                    emptyPageContent={null}
                    errorMessage={promotionContentsError}
                    expectedData={promotionContents}
                    expectNoData
                    loading={isFetchingPromotionContents}
                  />

                  <div className="PromotionDetails__Actions">
                    <button
                      className="button small orange-theme"
                      onClick={() => history.push(`/admin/edit-promotion/${promotionId}`)}
                      type="button"
                    >
                      EDIT
                    </button>

                    <button
                      className="button small red-theme"
                      onClick={() => this.setState({ deletePromotionModalVisible: true })}
                      type="button"
                    >
                      DELETE
                    </button>
                  </div>
                </>
              );
            }}
            emptyPageContent={
              <NotFound description="We cannot find the promotion" title="No results" />
            }
            errorMessage={promotionsError}
            expectedData={
              promotions &&
              promotions.find(({ PromotionId }) => PromotionId === Number(promotionId))
            }
            loading={isFetchingPromotions}
          />

          <LightBox
            className="PromotionDetails__DeleteModal"
            style={{
              width: 600,
              height: null,
              marginLeft: -300,
              marginTop: -200,
            }}
            onClose={() => this.setState({ deletePromotionModalVisible: false })}
            show={deletePromotionModalVisible}
            title="Attention!"
            width={435}
          >
            {deleting ? (
              <Loader />
            ) : (
              <>
                <p className="PromotionDetails__DeleteModal__Question">
                  Are you sure you want to delete this promotion?
                </p>

                <p className="PromotionDetails__DeleteModal__Description">This cannot be undone.</p>
              </>
            )}

            <ul className="PromotionDetails__DeleteModal__Actions">
              <li>
                <button
                  className="button small"
                  disabled={deleting}
                  onClick={() => this.setState({ deletePromotionModalVisible: false })}
                  type="button"
                >
                  no
                </button>
              </li>

              <li>
                <button
                  className="button small red-theme"
                  disabled={deleting}
                  onClick={() => this.deletePromotion()}
                  type="button"
                >
                  yes
                </button>
              </li>
            </ul>
          </LightBox>
        </MasterPage>
      </>
    );
  }
}

PromotionDetails.propTypes = {
  auth: bool.isRequired,
  getPromotionContents: func.isRequired,
  getPromotions: func.isRequired,
  history: HISTORY_TYPE.isRequired,
  isFetchingPromotionContents: bool.isRequired,
  isFetchingPromotions: bool.isRequired,
  match: ROUTER_MATCH_TYPE({ promotionId: string.isRequired }).isRequired,
  promotionContents: arrayOf(PROMOTION_CONTENT_TYPE).isRequired,
  promotionContentsError: string.isRequired,
  promotions: arrayOf(PROMOTION_TYPE).isRequired,
  promotionsError: string.isRequired,
};

const mapStateToProps = (state) => {
  const {
    isFetchingPromotionContents,
    isFetchingPromotions,
    promotionContents,
    promotionContentsError,
    promotions,
    promotionsError,
  } = state.promotions;

  return {
    isFetchingPromotionContents,
    isFetchingPromotions,
    promotionContents,
    promotionContentsError,
    promotions,
    promotionsError,
  };
};

export default connect(mapStateToProps, {
  getPromotions: getPromotionsAction,
  getPromotionContents: getPromotionContentsAction,
})(PromotionDetails);
