import './index.sass';

import { bool, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import ReactInputMask from 'react-input-mask';

import checkSvg from '../../assets/images/check-contact.svg';
import AlertMessages from '../../components/AlertMessages';
import AsyncContent from '../../components/AsyncContent';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import {
  REACT_APP_SF_CONTACT_URL,
  REACT_APP_SF_ENQUIRY_ID,
  REACT_APP_SF_ENQUIRY_NAME,
  REACT_APP_SF_OID,
} from '../../config/variables';
import { validEmailAddress } from '../../helpers';
import HISTORY_TYPE from '../../types/history';

/* global window */
class ContactSF extends React.Component {
  state = {
    CompanyName: '',
    ContactName: '',
    PhoneNumber: '',
    Email: '',
    Enquiry: '',
    submitted: false,
    displaySuccessScreen: false,
    returnUrl: `${window.origin}/contact-sales-force?status=1`,
  };

  componentDidMount() {
    const {
      history: { location },
    } = this.props;
    this.setState({
      displaySuccessScreen: location.search.includes('status=1'),
    });
  }

  componentDidUpdate(prevProps) {
    const { isSendingContactMessage, contactSuccessMessage } = this.props;
    if (!isSendingContactMessage && prevProps.isSendingContactMessage && contactSuccessMessage) {
      this.setState({ displaySuccessScreen: true });
    }
  }

  onHandleChange(name, value) {
    this.setState({ [name]: value, submitted: false });
  }

  getError() {
    const { CompanyName, ContactName, PhoneNumber, Email, Enquiry } = this.state;
    if (!CompanyName || !ContactName || !PhoneNumber || !Email || !Enquiry) {
      return {
        companyName: !CompanyName && 'Required',
        contactName: !ContactName && 'Required',
        phoneNumber: !PhoneNumber && 'Required',
        email: !Email && 'Required',
        enquiry: !Enquiry && 'Required',
        alert: 'Please complete all of the required fields, in order to submit the form.',
      };
    }

    const validEmail = validEmailAddress(Email);
    if (!validEmail.result) {
      return {
        email: 'Invalid email',
        alert: 'Email format is invalid. Please try again.',
      };
    }

    // const validPhone = parsePhoneNumberFromString(
    //   `+${String(PhoneNumber).replace('+', '')}`,
    // );
    // const isPhoneValid = !validPhone || !validPhone.isValid();
    const isPhoneValid = PhoneNumber.length > 8 && PhoneNumber.length < 16;

    if (!isPhoneValid) {
      return {
        phoneNumber: 'Invalid phone',
        alert: 'Phone number is invalid. Please try again.',
      };
    }

    return {
      alert: '',
    };
  }

  render() {
    const {
      CompanyName,
      ContactName,
      PhoneNumber,
      Email,
      Enquiry,
      displaySuccessScreen,
      messageType,
      submitted,
      returnUrl,
    } = this.state;

    const { isSendingContactMessage, contactErrorMessage, history } = this.props;
    const formError = this.getError();

    return (
      <div className="AuthWrapper">
        <Header auth={false} global />

        <AsyncContent
          content={() => (
            <main className="Contact">
              <AlertMessages
                className="BusinessGigCreateSuccessfully__AlertMessages"
                show={submitted && !!formError.alert}
                type="error"
                message={formError.alert}
              />

              <div className="Auth border-none">
                {!displaySuccessScreen ? (
                  <form
                    action={REACT_APP_SF_CONTACT_URL}
                    method="POST"
                    onSubmit={(e) => {
                      if (formError.alert) {
                        this.setState({ submitted: true });
                        e.preventDefault();
                      }
                    }}
                  >
                    <input type="hidden" name="orgid" value={REACT_APP_SF_OID} />
                    <input type="hidden" name="retURL" value={returnUrl} />
                    <input id="lead_source" name="lead_source" type="hidden" value="Website" />
                    <input
                      id="FNK_Lead_Created_via_Integration__c"
                      name="FNK_Lead_Created_via_Integration__c"
                      type="hidden"
                      value={1}
                    />

                    <div className="Auth__Header">
                      <h1 className="Auth__Title">Contact Us</h1>
                      <div className="Contact__Description">
                        Please contact our support team with the business information (name,
                        address, phone number), and our team will reach out to you.
                      </div>
                    </div>
                    <fieldset>
                      <label className="Auth__Label" htmlFor="company">
                        Business Name
                        <em>*</em>
                        &nbsp;
                        {submitted && formError.companyName}
                      </label>

                      <input
                        maxLength={20}
                        autoComplete="off"
                        className={`full ${submitted && formError.companyName && 'error'}`}
                        disabled={isSendingContactMessage}
                        id="company"
                        name="company"
                        onChange={({ target }) => this.onHandleChange('CompanyName', target.value)}
                        type="text"
                        value={CompanyName}
                      />
                    </fieldset>
                    <fieldset>
                      <label className="Auth__Label" htmlFor="name">
                        Contact Name
                        <em>*</em>
                        &nbsp;
                        {submitted && formError.contactName}
                      </label>

                      <input
                        maxLength={20}
                        autoComplete="off"
                        className={`full ${submitted && formError.contactName && 'error'}`}
                        disabled={isSendingContactMessage}
                        id="name"
                        name="name"
                        onChange={({ target }) => this.onHandleChange('ContactName', target.value)}
                        type="text"
                        value={ContactName}
                      />
                    </fieldset>

                    <fieldset>
                      <label className="Auth__Label" htmlFor="email">
                        Email
                        <em>*</em>
                        &nbsp;
                        {submitted && formError.email}
                        &nbsp;
                      </label>

                      <input
                        maxLength={80}
                        className={`full ${submitted && formError.email && 'error'}`}
                        disabled={isSendingContactMessage}
                        id="email"
                        name="email"
                        onChange={({ target }) => this.onHandleChange('Email', target.value)}
                        type="text"
                        value={Email}
                      />
                    </fieldset>
                    <fieldset className="Contact__Label">
                      <label className="Auth__Label" htmlFor="phone">
                        Phone
                        <em>*</em>
                        &nbsp;
                        {submitted && formError.phoneNumber}
                        &nbsp;
                      </label>

                      {/* <input
                        maxLength={40}
                        autoComplete="off"
                        className="full"
                        disabled={isSendingContactMessage}
                        id="phone"
                        name="phone"
                        placeholder="+1 xxxxxxxxxx"
                        onChange={({ target }) => this.onHandleChange('PhoneNumber', target.value)
                        }
                        type="text"
                        // pattern="^[+][0-9]{9,15}"
                        value={PhoneNumber}
                      /> */}

                      <ReactInputMask
                        className="full ReactInputMask"
                        id="txtBusinessPhoneNumber"
                        name="phone"
                        mask="999999999999999"
                        maskChar=""
                        onChange={({ target }) => this.onHandleChange('PhoneNumber', target.value)}
                        placeholder="1 xxxxxxxxxx"
                        value={PhoneNumber}
                      />
                    </fieldset>

                    <fieldset>
                      <label className="Auth__Label" htmlFor="description">
                        Enquiry
                        <em>*</em>
                        &nbsp;
                        {submitted && formError.enquiry}
                      </label>

                      <textarea
                        rows={5}
                        className={`full ${submitted && formError.enquiry && 'error'}`}
                        disabled={isSendingContactMessage}
                        id="description"
                        name="description"
                        onChange={({ target }) => this.onHandleChange('Enquiry', target.value)}
                        type={messageType}
                        value={Enquiry}
                      />
                    </fieldset>
                    <input
                      id={REACT_APP_SF_ENQUIRY_ID}
                      name={REACT_APP_SF_ENQUIRY_NAME}
                      type="hidden"
                      value="Yes"
                    />
                    <input id="origin" name="origin" type="hidden" value="Can't find my business" />

                    <div className="Contact__Action">
                      <button
                        className="button orange-theme"
                        disabled={isSendingContactMessage}
                        type="submit"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </form>
                ) : (
                  <>
                    <h1 className="Contact__SuccessTitle">
                      Your message has been sent &nbsp;
                      <span role="img" aria-label="success">
                        🎉{' '}
                      </span>
                    </h1>

                    <div className="Contact__SuccessDescription">
                      Your information has been passed on to our team, someone will reach out within
                      the next business day
                    </div>

                    <img
                      className="Contact__SuccessCheck"
                      src={checkSvg}
                      alt="selected"
                      width="90"
                    />

                    <div className="Contact__Action">
                      <button
                        className="button orange-theme"
                        onClick={() => history.push('/claim-my-business')}
                        type="button"
                      >
                        RETURN TO FRANKI
                      </button>
                    </div>
                  </>
                )}
              </div>
            </main>
          )}
          errorMessage={contactErrorMessage}
          expectNoData
        />

        {!displaySuccessScreen && (
          <button
            // eslint-disable-next-line no-undef
            onClick={() => window.history.back()}
            className="button Contact__Back"
            type="button"
          >
            <span>BACK</span>
            <i className="icon-chevron-left" />
          </button>
        )}

        <Footer />
      </div>
    );
  }
}

ContactSF.propTypes = {
  history: HISTORY_TYPE.isRequired,
  isSendingContactMessage: bool,
  contactSuccessMessage: string,
  contactErrorMessage: string,
};

ContactSF.defaultProps = {
  isSendingContactMessage: null,
  contactSuccessMessage: null,
  contactErrorMessage: null,
};

const mapStateToProps = (state) => ({
  contactErrorMessage: state.businesses.contactErrorMessage,
  contactSuccessMessage: state.businesses.contactSuccessMessage,
  isSendingContactMessage: state.businesses.isSendingContactMessage,
});

export default compose(withRouter, connect(mapStateToProps, null))(ContactSF);
