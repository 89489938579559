import { bool, func, string } from 'prop-types';
import React from 'react';
import MediaQuery from 'react-responsive';

import Plans from '../../../../components/Plans';
import StepsHeader from '../../../../components/StepsHeader';
import { cannabisPlans, hospitalityPlans } from './plans.json';
import ResponsivePlans from './responsive';

class SecondStep extends React.Component {
  state = {
    plans: [],
  };

  componentDidMount() {
    this.plans();
  }

  componentDidUpdate(prevProps) {
    const { activePlan } = this.props;

    if (prevProps.activePlan !== activePlan) {
      this.plans();
    }
  }

  plans = () => {
    const { activePlan, businessType } = this.props;
    const selectPlan = businessType === 'cannabis' ? cannabisPlans : hospitalityPlans;

    this.setState({
      plans: selectPlan.map((item) => ({
        ...item,
        active: activePlan === item.title,
      })),
    });
  };

  onHandleMousePlanHover = (planName, planPrice) => {
    const { activePlan, onHandleFormChange } = this.props;

    if (activePlan !== planName) {
      onHandleFormChange('plan', planName);
      onHandleFormChange('planPrice', planPrice);
    }
  };

  onHandleSelectPlan = () => {
    const { onHandleChange, planHasBeingChanged } = this.props;

    if (planHasBeingChanged) {
      onHandleChange('step', 3);
      onHandleChange('planHasBeingChanged', false);
    } else {
      onHandleChange('step', 2);
    }
  };

  render() {
    const { plans } = this.state;
    const { activePlan } = this.props;
    const activeIndex = plans.findIndex((plan) => plan === activePlan);

    return (
      <div className="SecondStep">
        <StepsHeader
          step={2}
          title="select a membership."
          subtitle="Take advantage of our introductory discounts, its a great deal! </br>
          Remember, you won’t be charged for the first 30 days."
        />

        <MediaQuery query="(max-width: 812px)">
          <ResponsivePlans
            plans={plans}
            onHandleSelectPlan={this.onHandleSelectPlan}
            onHandleMousePlanHover={this.onHandleMousePlanHover}
          />
        </MediaQuery>

        <MediaQuery query="(min-width: 813px)">
          <Plans
            activeIndex={activeIndex}
            features={[
              { title: 'profile management' },
              { title: 'gigs' },
              {
                // subtitle: 'per month',
                title: 'gig winners',
              },
              { title: 'service fee' },
              {
                // subtitle: 'per month',
                title: 'gig match credit',
              },
            ]}
            onActivate={this.onHandleMousePlanHover}
            onInactivate={() => this.onHandleSelectPlan()}
            plans={plans}
          />
        </MediaQuery>
      </div>
    );
  }
}

SecondStep.propTypes = {
  onHandleChange: func.isRequired,
  onHandleFormChange: func.isRequired,
  activePlan: string.isRequired,
  planHasBeingChanged: bool.isRequired,
  businessType: string.isRequired,
};

export default SecondStep;
