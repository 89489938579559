import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { mockData } from './mockData';

const mockAxios = axios.create();

export const registerMockAxios = () => {
  const mock = new MockAdapter(mockAxios);
  mockData.forEach((item) => {
    const { path, method, status, data } = item;
    switch (method) {
      case 'POST':
        mock.onPost(path).reply(status, data);
        break;
      case 'GET':
        mock.onGet(path).reply(status, data);
        break;
      default:
        break;
    }
  });
};

export default mockAxios;
