import '../index.sass';

import { bool, func, instanceOf, string } from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'redux';

import { checkResetPasswordToken, resetPassword } from '../../../actions/auth';
import AsyncContent from '../../../components/AsyncContent';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import PasswordRequirements from '../../../components/PasswordRequirements';
import { validPasswordReset } from '../../../helpers';
import AlertMessages from '../../../components/AlertMessages';

class ResetPassword extends React.Component {
  state = {
    ConfirmPassword: '',
    errorMessage: null,
    isTokenValid: null,
    isValidatingToken: true,
    loading: false,
    NewPassword: '',
    showConfirmPassword: false,
    showNewPassword: false,
    successMessage: null,
    redirectLoginPage: false,
    submitted: false,
  };

  componentDidMount() {
    this.checkToken();
  }

  componentDidUpdate(prevProps) {
    const { errorMessage, resetPasswordTokenIsValid, resetPasswordSuccessMessage } = this.props;

    if (prevProps.errorMessage !== errorMessage) {
      this.errorMessage();
    }

    if (prevProps.resetPasswordTokenIsValid !== resetPasswordTokenIsValid) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        ConfirmPassword: '',
        isTokenValid: resetPasswordTokenIsValid,
        isValidatingToken: false,
        loading: false,
        NewPassword: '',
      });
    }

    if (prevProps.resetPasswordSuccessMessage !== resetPasswordSuccessMessage) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        ConfirmPassword: '',
        loading: false,
        NewPassword: '',
        successMessage: resetPasswordSuccessMessage,
      });
    }
  }

  checkToken = async () => {
    const { location, checkResetPasswordToken: checkResetPasswordTokenAction } = this.props;
    const getToken = queryString.parse(location.search).token;

    if (getToken) {
      await checkResetPasswordTokenAction(getToken);
    }
  };

  onHandleSubmit = async (e) => {
    e.preventDefault();

    const formError = this.getError();
    if (formError.alert) {
      this.setState({ submitted: true });
    } else {
      const { location, resetPassword: resetPasswordAction } = this.props;
      const { NewPassword } = this.state;
      const getToken = queryString.parse(location.search).token;

      this.setState({ loading: true });

      await resetPasswordAction({ Token: getToken, Password: NewPassword });

      this.errorMessage();
    }
  };

  errorMessage = () => {
    const { errorMessage, resetPasswordSuccessMessage } = this.props;
    this.setState({
      ConfirmPassword: '',
      errorMessage,
      isValidatingToken: false,
      loading: false,
      NewPassword: '',
      redirectLoginPage: resetPasswordSuccessMessage == 'Your password has been updated.',
      successMessage: isMobile ? this.renderSuccessJsx() : resetPasswordSuccessMessage,
    });
  };

  getError() {
    const { NewPassword, ConfirmPassword } = this.state;
    if (!NewPassword || !ConfirmPassword) {
      return {
        newPassword: !NewPassword && 'Required',
        confirmPassword: !ConfirmPassword && 'Required',
        alert: 'Please complete both of the required fields, in order to reset your password.',
      };
    }

    const validPassword = validPasswordReset(NewPassword);
    const validPasswordLength = NewPassword.length > 7 && NewPassword.length < 61;
    if (!validPassword.result || !validPasswordLength) {
      return {
        newPassword: 'Invalid password',
        alert: 'Password is invalid. Please try again.',
      };
    }

    const validConfirmationPassword = NewPassword === ConfirmPassword;
    if (!validConfirmationPassword) {
      return {
        confirmPassword: 'Passwords do not match',
        alert: 'Your password and confirmation password do not match.',
      };
    }

    return {
      alert: '',
    };
  }

  renderSuccessJsx = () => (
    <div>
      Your password has been reset. You can now login to Franki app. Click <Link to="/">here</Link>{' '}
      to access admin portal
    </div>
  );

  handleClose = () => {
    if (!isMobile) {
      document.location.href = '/';
    }
  };

  onHandleChange = (name, value) => {
    this.setState({
      [name]: value,
      errorMessage: null,
      successMessage: null,
      submitted: false,
    });
  };

  render() {
    const {
      ConfirmPassword,
      errorMessage,
      isTokenValid,
      isValidatingToken,
      loading,
      NewPassword,
      showConfirmPassword,
      showNewPassword,
      successMessage,
      submitted,
    } = this.state;

    const formError = this.getError();

    const { auth } = this.props;

    return (
      <>
        <Header auth={auth} />

        <AsyncContent
          content={() => (
            <main className="AuthMain">
              <AlertMessages
                className="BusinessGigCreateSuccessfully__AlertMessages"
                show={submitted && !!formError.alert}
                type="error"
                message={formError.alert}
              />
              <div className="wrapper">
                <div className="Auth">
                  <form onSubmit={(e) => this.onHandleSubmit(e)}>
                    <div className="Auth__Header">
                      <h1 className="Auth__Title">Reset your password</h1>
                      <p className="Auth__Subtitle">
                        we get it, sometimes you forget things - it’s fine. go ahead and get a new
                        one.
                      </p>
                    </div>

                    {isTokenValid ? (
                      <>
                        <fieldset>
                          <label className="Auth__Label" htmlFor="txtNewPassword">
                            New password: &nbsp;
                            {submitted && formError.newPassword}
                          </label>
                          <input
                            autoComplete="off"
                            className="full"
                            disabled={loading || !!successMessage}
                            id="txtNewPassword"
                            name="NewPassword"
                            onChange={({ target }) =>
                              this.onHandleChange(target.name, target.value)
                            }
                            type={showNewPassword ? 'text' : 'password'}
                            value={NewPassword}
                          />

                          {showNewPassword ? (
                            <i
                              className="icon-eye-slash"
                              onClick={() => this.onHandleChange('showNewPassword', false)}
                              onKeyPress={() => this.onHandleChange('showNewPassword', false)}
                              role="button"
                              tabIndex={0}
                            />
                          ) : (
                            <i
                              className="icon-eye"
                              onClick={() => this.onHandleChange('showNewPassword', true)}
                              onKeyPress={() => this.onHandleChange('showNewPassword', true)}
                              role="button"
                              tabIndex={0}
                            />
                          )}
                        </fieldset>

                        <fieldset>
                          <label className="Auth__Label" htmlFor="txtConfirmPassword">
                            Confirm new password: &nbsp;
                            {submitted && formError.confirmPassword}
                          </label>
                          <input
                            autoComplete="off"
                            className="full"
                            disabled={loading || !!successMessage}
                            id="txtConfirmPassword"
                            name="ConfirmPassword"
                            onChange={({ target }) =>
                              this.onHandleChange(target.name, target.value)
                            }
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={ConfirmPassword}
                          />

                          {showConfirmPassword ? (
                            <i
                              className="icon-eye-slash"
                              onClick={() => this.onHandleChange('showConfirmPassword', false)}
                              onKeyPress={() => this.onHandleChange('showConfirmPassword', false)}
                              role="button"
                              tabIndex={0}
                            />
                          ) : (
                            <i
                              className="icon-eye"
                              onClick={() => this.onHandleChange('showConfirmPassword', true)}
                              onKeyPress={() => this.onHandleChange('showConfirmPassword', true)}
                              role="button"
                              tabIndex={0}
                            />
                          )}
                        </fieldset>

                        <PasswordRequirements password={NewPassword} />

                        <div className="Auth__Action">
                          <button
                            className="button small orange-theme"
                            disabled={loading || !!successMessage}
                            type="submit"
                          >
                            {loading ? 'loading...' : 'reset'}
                          </button>
                        </div>
                      </>
                    ) : null}
                  </form>
                </div>
              </div>
            </main>
          )}
          errorMessage={errorMessage}
          expectNoData
          loading={isValidatingToken}
          successMessage={successMessage}
          onHandleCloseDialog={() => this.handleClose()}
        />
        <Footer />
      </>
    );
  }
}

ResetPassword.propTypes = {
  auth: bool.isRequired,
  checkResetPasswordToken: func.isRequired,
  errorMessage: string,
  location: instanceOf(Object).isRequired,
  resetPassword: func.isRequired,
  resetPasswordSuccessMessage: string,
  resetPasswordTokenIsValid: bool,
};

ResetPassword.defaultProps = {
  errorMessage: null,
  resetPasswordSuccessMessage: null,
  resetPasswordTokenIsValid: null,
};

const mapStateToProps = (state) => ({
  errorMessage: state.auth.errorMessage,
  resetPasswordSuccessMessage: state.auth.resetPasswordSuccessMessage,
  resetPasswordTokenIsValid: state.auth.resetPasswordTokenIsValid,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { checkResetPasswordToken, resetPassword }),
)(ResetPassword);
