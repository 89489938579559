import { forEach, intersection, isEmpty, keys, reduce } from 'lodash';

import { FILTER_ITEMS } from '../../../types/filters';

const isFilterUsedForModal = (filterName, activeFilterNames) => intersection(keys(FILTER_ITEMS), activeFilterNames).indexOf(filterName) > -1;

export const filtersAfterClosingModal = (
  modalFilters,
  currentFilters,
  defaultFilters,
  activeFilterNames,
) => {
  const updatedFilters = { ...currentFilters };
  forEach(modalFilters, (filterValue, filterName) => {
    if (isFilterUsedForModal(filterName, activeFilterNames)) {
      if (['number', 'boolean'].indexOf(typeof filterValue) > -1) {
        updatedFilters[filterName] = filterValue;
      } else {
        updatedFilters[filterName] = isEmpty(filterValue)
          ? defaultFilters[filterName]
          : filterValue;
      }
    }
  });
  return updatedFilters;
};

export const createModalState = (currentFilters, activeFilterNames) => {
  const modalState = reduce(
    currentFilters,
    (state, filterValue, filterName) => ({
      ...state,
      ...(isFilterUsedForModal(filterName, activeFilterNames) ? { [filterName]: filterValue } : {}),
    }),
    {},
  );
  return modalState;
};
