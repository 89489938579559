import './index.sass';

import { bool, instanceOf, node, number, shape, string } from 'prop-types';
import React, { useState } from 'react';
import Helmet from 'react-helmet';

import MEDIA_TYPE from '../../types/media';
import ContentPage from '../ContentPage';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';

export default function MasterPage(props) {
  const {
    auth,
    businessMobileTitle,
    businessSidebarData,
    businessSidebarDataError,
    businessSidebarOn,
    children,
    className,
    hasFooter,
    headerBreadcrumb,
    headerButtons,
    membership,
    pageTitle,
    sidebar,
    headerComponent,
    noWrapper,
    flex,
  } = props;

  const [businessItemMenu, setBusinessItemMenu] = useState(false);

  function onHandleBusinessItemMenu(val) {
    setBusinessItemMenu(val || !businessItemMenu);
  }

  return (
    <>
      {pageTitle ? <Helmet title={pageTitle} /> : null}

      <Header auth={auth} />

      <main className={flex ? 'display-flex' : ''}>
        {headerComponent}
        <div className={noWrapper ? '' : 'wrapper'}>
          {businessSidebarOn && (
            <Sidebar
              businessItemMenu={businessItemMenu}
              businessSidebarData={businessSidebarData}
              businessSidebarDataError={businessSidebarDataError}
              membership={membership}
              onHandleBusinessItemMenu={onHandleBusinessItemMenu}
            />
          )}
          {sidebar}

          <div
            className={`${
              businessSidebarOn || sidebar ? 'content-sidebar' : 'content'
            } ${className}`}
          >
            {(businessSidebarOn || sidebar) && businessMobileTitle && (
              <div className="content-sidebar-header">
                <i
                  className="icon-hamburger"
                  onClick={onHandleBusinessItemMenu}
                  onKeyPress={onHandleBusinessItemMenu}
                  role="button"
                  tabIndex={0}
                />

                <span className="content-sidebar-header-title">{businessMobileTitle}</span>
              </div>
            )}

            <ContentPage
              businessPage={businessMobileTitle}
              headerBreadcrumb={headerBreadcrumb}
              headerButtons={headerButtons}
            >
              {children}
            </ContentPage>
          </div>

          {hasFooter ? <Footer /> : null}
        </div>
      </main>
    </>
  );
}

MasterPage.propTypes = {
  auth: bool,
  businessMobileTitle: string,
  businessSidebarData: shape({
    DisplayName: string,
    Logo: MEDIA_TYPE,
  }),
  businessSidebarDataError: string,
  businessSidebarOn: bool,
  children: node,
  className: string,
  hasFooter: bool,
  headerBreadcrumb: instanceOf(Array),
  headerButtons: node,
  membership: number,
  pageTitle: string,
  sidebar: node,
  headerComponent: node,
  noWrapper: bool,
  flex: bool,
};

MasterPage.defaultProps = {
  auth: null,
  businessMobileTitle: null,
  businessSidebarData: null,
  businessSidebarDataError: null,
  businessSidebarOn: null,
  children: null,
  className: null,
  hasFooter: null,
  headerBreadcrumb: null,
  headerButtons: null,
  membership: null,
  pageTitle: null,
  sidebar: null,
  headerComponent: null,
  noWrapper: false,
  flex: false,
};
