import './index.sass';

import { isEmpty, keys, reduce } from 'lodash';
import { arrayOf, bool, func, number, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import uuidv1 from 'uuid/v1';

import { getBusinesses as getBusinessesAction } from '../../../actions/businesses';
import { getUSStateList as getUSStateListAction } from '../../../actions/usStates';
import AsyncContent from '../../../components/AsyncContent';
import Filters from '../../../components/Filters';
import { Col, Row } from '../../../components/Grid';
import MasterPage from '../../../components/MasterPage';
import Paginator from '../../../components/Paginator';
import BUSINESS_TYPES from '../../../config/business-types';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../config/pagination';
import { BUSINESS_DETAILS_TYPE } from '../../../types/business';
import HISTORY_TYPE from '../../../types/history';
import LOCATION_TYPE from '../../../types/location';
import RESPONSE_LIST_OF_TYPE from '../../../types/responseListOf';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';
import US_STATE_TYPE from '../../../types/usStates';
import { isFoursquareData } from '../../../utils/placeId';
import BusinessCard from './BusinessCard';

/* global  document window */

const ORDER_BY_OPTIONS = [
  { label: 'recently created', value: 'recentlyCreated' },
  { label: 'name (a-z)', value: 'Name' },
];

const THIRD_PARTY_ORDER_BY_OPTIONS = [
  { label: 'recently active', value: 'recentlyActive' },
  { label: 'date created', value: 'dateCreated' },
];

const getQueryParams = (searchQueryParams) => {
  const params = {};
  const query = new URLSearchParams(searchQueryParams);
  const businessTypes = query.get('businessTypes');
  const users = query.get('users');
  const hasGigs = query.get('hasGigs');
  const onlyThirdPartyBusiness = query.get('onlyThirdPartyBusiness');
  const showDeactivatedBusiness = query.get('showDeactivatedBusiness');
  const state = query.get('state');
  ['search', 'orderBy', 'location', 'gigStatus', 'verificationStatus'].forEach((term) => {
    const termValue = query.get(term);
    if (termValue) {
      params[term] = termValue;
    }
  });

  if (users) {
    params.users = users.split(',').join('&users=');
  }

  if (hasGigs === 'true') {
    params.hasGigs = true;
  }

  if (onlyThirdPartyBusiness === 'true') {
    params.onlyThirdPartyBusiness = true;
  }

  if (showDeactivatedBusiness === 'true') {
    params.showDeactivatedBusiness = true;
  }

  if (state) {
    params.stateId = Number(state);
  }

  if (businessTypes) {
    params.businessTypes = reduce(
      businessTypes.split(','),
      (revisedBusinessType, type) => {
        const typeValue = Number(type.split(';')[1]);
        const matchedBusinessType = BUSINESS_TYPES.find(({ value }) => value === typeValue);
        if (matchedBusinessType) {
          revisedBusinessType.push(matchedBusinessType.value);
        }
        return revisedBusinessType;
      },
      [],
    ).join('&businessTypes=');
  }
  return params;
};

class Businesses extends React.Component {
  componentDidMount() {
    const {
      getUSStateList,
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;
    if (!pageNumber || !itemsCountPerPage) {
      this.updatePagination(
        Number(itemsCountPerPage) || DEFAULT_PAGE_SIZE,
        Number(pageNumber) || DEFAULT_PAGE_INDEX,
      );
    }

    getUSStateList();
    this.getBusinesses();
    window.addEventListener('popstate', this.popStateListener);
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search },
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;

    if (prevProps.location.search !== search) {
      if (Number(pageNumber) !== DEFAULT_PAGE_INDEX) {
        this.updatePagination(Number(itemsCountPerPage), DEFAULT_PAGE_INDEX);
      }
      this.getBusinesses();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.popStateListener);
  }

  async getBusinesses(forcedPageNumber, values) {
    const filterField = values || this.props;
    const {
      getBusinesses,
      location: { search },
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = filterField;

    const params = getQueryParams(search);
    if (isEmpty(params) || !params.orderBy) {
      return;
    }
    let revisedSearchQuery = `?PageSize=${itemsCountPerPage}&pageNum=${forcedPageNumber || pageNumber}`;
    revisedSearchQuery += `&${keys(params)
      .map((key) => {
        if (key === 'verificationStatus') {
          return params.verificationStatus
            .split(',')
            .map(status => `verificationStatus=${status}`)
            .join('&');
        }

        return `${key}=${params[key]}`;
      })
      .join('&')}`;
    await getBusinesses(revisedSearchQuery, {
      ...params,
      pageNumber: forcedPageNumber || pageNumber,
      itemsCountPerPage,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  popStateListener() {
    const el = document.querySelectorAll('.pagination > .active a');
    if (el.length > 0) {
      const txt = Number(el[0].textContent);
      if (txt === 1) window.history.go(-1);
    }
  }

  updatePagination(itemsCountPerPage, pageNumber) {
    const {
      location: { search },
      history,
    } = this.props;
    history.push({
      pathname: `/admin/businesses/${itemsCountPerPage}/${pageNumber}`,
      search,
    });
    // setTimeout(() => this.getBusinesses(pageNumber));
  }

  toggleOnlyShowGoogleOrFourSquareBusiness(onlyThirdPartyBusiness) {
    const {
      history,
      match: {
        params: { itemsCountPerPage },
      },
    } = this.props;
    if (onlyThirdPartyBusiness) {
      history.push({
        pathname: `/admin/businesses/${itemsCountPerPage}/1`,
        search: 'orderBy=recentlyActive&onlyThirdPartyBusiness=true',
      });
    } else {
      history.push({
        pathname: `/admin/businesses/${itemsCountPerPage}/1`,
        search: 'orderBy=recentlyActive&onlyThirdPartyBusiness=false',
      });
    }
  }

  registerBusiness() {
    const { history } = this.props;
    history.push({ pathname: '/admin/register-business' });
  }

  render() {
    const {
      auth,
      businesses,
      businessesError,
      isFetchingBusinesses,
      location: { search },
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
      usStateList,
    } = this.props;
    const queryParams = getQueryParams(search);

    const usStateOptions = usStateList.map(({ Name, StateId }) => ({
      label: Name,
      value: StateId,
    }));

    const masterPageConfig = {
      auth,
      headerBreadcrumb: [{ name: 'Home', url: '/' }, { name: 'Businesses' }],
    };
    const showFoursquareData = isFoursquareData();
    const totalItemsCount = !queryParams.onlyThirdPartyBusiness ? businesses.TotalCount : businesses.TotalItems;

    return (
      <>
        <Helmet title="Franki Admin: businesses" />

        <MasterPage {...masterPageConfig}>
          <>
            <Filters
              activeFilterNames={[
                'businessTypes',
                'hasGigs',
                'orderBy',
                'search',
                'state',
                'verificationStatus',
              ]}
              content={(
                <>
                  <fieldset className="Businesses__TypeFilter">
                    <label>
                      <input
                        checked={!queryParams.onlyThirdPartyBusiness}
                        name="onlyThirdPartyBusiness"
                        onChange={() => this.toggleOnlyShowGoogleOrFourSquareBusiness(false)}
                        type="radio"
                      />
                      Franki Businesses
                    </label>

                    <label>
                      <input
                        checked={queryParams.onlyThirdPartyBusiness}
                        name="onlyThirdPartyBusiness"
                        onChange={() => this.toggleOnlyShowGoogleOrFourSquareBusiness(true)}
                        type="radio"
                      />
                      {showFoursquareData
                        ? 'Foursquare Place Businesses'
                        : 'Google Place Businesses'}
                    </label>
                  </fieldset>
                  <Link
                    to="/admin/register-business"
                    className="button small yellow-theme filterBtn"
                  >
                    Add a Business
                  </Link>
                </>
              )}
              filterDisabled={queryParams.onlyThirdPartyBusiness}
              modalHeight={null}
              sorterItems={
                queryParams.onlyThirdPartyBusiness ? THIRD_PARTY_ORDER_BY_OPTIONS : ORDER_BY_OPTIONS
              }
              usStateOptions={usStateOptions}
            />
            {totalItemsCount > 0 && !isFetchingBusinesses && (
              <Paginator
                isTopPaginator
                itemsCountPerPage={Number(itemsCountPerPage)}
                onHandlePage={(...params) => this.updatePagination(...params)}
                onHandleShow={(...params) => this.updatePagination(...params)}
                pageIndex={Number(pageNumber)}
                totalItems={totalItemsCount}
              />
            )}

            {
              <AsyncContent
                content={businessList => (
                  <Row cellSpacing={10} rowSpacing={10}>
                    {businessList.map((item, index) => (
                      <Col lg={3} md={4} sm={6} xs={12} key={uuidv1()}>
                        <BusinessCard
                          isThirdPartyBusiness={!!queryParams.onlyThirdPartyBusiness}
                          item={item}
                          index={index}
                          onSuccess={() => this.getBusinesses(1, this.props)}
                        />
                      </Col>
                    ))}
                  </Row>
                )}
                errorMessage={businessesError}
                expectedData={queryParams.onlyThirdPartyBusiness ? businesses.Results : businesses.Businesses}
                loading={isFetchingBusinesses}
              />
            }
          </>
        </MasterPage>
      </>
    );
  }
}

Businesses.propTypes = {
  auth: bool.isRequired,
  businesses: RESPONSE_LIST_OF_TYPE(BUSINESS_DETAILS_TYPE).isRequired,
  businessesError: string.isRequired,
  getBusinesses: func.isRequired,
  getUSStateList: func.isRequired,
  history: HISTORY_TYPE.isRequired,
  isFetchingBusinesses: bool.isRequired,
  location: LOCATION_TYPE.isRequired,
  match: ROUTER_MATCH_TYPE({ pageNumber: string, itemsCountPerPage: string }).isRequired,
  totalItems: number,
  usStateList: arrayOf(US_STATE_TYPE),
};

Businesses.defaultProps = {
  totalItems: 0,
  usStateList: [],
};

const mapStateToProps = (state) => {
  const { businesses, businessesError, isFetchingBusinesses } = state.businesses;
  return {
    businesses,
    businessesError,
    isFetchingBusinesses,
    totalItems: businesses ? businesses.TotalItems : 0,
    usStateList: state.usStates.usStates,
  };
};

export default connect(mapStateToProps, {
  getBusinesses: getBusinessesAction,
  getUSStateList: getUSStateListAction,
})(Businesses);
