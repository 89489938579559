import { number, string } from 'prop-types';
import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import './CircularProgress.scss';

const CircularProgress = ({ text, value }) => (
  <div className="CircularProgress">
    <CircularProgressbar value={value} />
    <div className="CircularProgress__text">{text}</div>
  </div>
);

CircularProgress.propTypes = {
  value: number.isRequired,
  text: string,
};

CircularProgress.defaultProps = {
  text: '',
};

export default CircularProgress;
