export const {
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_API_V2_URL,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_SOCKET_URL,
  REACT_APP_SF_CONTACT_URL,
  REACT_APP_SF_OID,
  REACT_APP_SF_ENQUIRY_ID,
  REACT_APP_SF_ENQUIRY_NAME,
  REACT_APP_GOOGLE_ANALYTICS_KEY,
  REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
} = process.env;

export const env = {
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_API_V2_URL,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_SOCKET_URL,
  REACT_APP_SF_CONTACT_URL,
  REACT_APP_SF_OID,
  REACT_APP_SF_ENQUIRY_ID,
  REACT_APP_SF_ENQUIRY_NAME,
  REACT_APP_GOOGLE_ANALYTICS_KEY,
  REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
};
