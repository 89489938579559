import adSvg from '../../assets/images/flags/ad.svg';
import aeSvg from '../../assets/images/flags/ae.svg';
import afSvg from '../../assets/images/flags/af.svg';
import agSvg from '../../assets/images/flags/ag.svg';
import aiSvg from '../../assets/images/flags/ai.svg';
import alSvg from '../../assets/images/flags/al.svg';
import amSvg from '../../assets/images/flags/am.svg';
import aoSvg from '../../assets/images/flags/ao.svg';
import arSvg from '../../assets/images/flags/ar.svg';
import asSvg from '../../assets/images/flags/as.svg';
import atSvg from '../../assets/images/flags/at.svg';
import auSvg from '../../assets/images/flags/au.svg';
import awSvg from '../../assets/images/flags/aw.svg';
import azSvg from '../../assets/images/flags/az.svg';
import baSvg from '../../assets/images/flags/ba.svg';
import bbSvg from '../../assets/images/flags/bb.svg';
import bdSvg from '../../assets/images/flags/bd.svg';
import beSvg from '../../assets/images/flags/be.svg';
import bfSvg from '../../assets/images/flags/bf.svg';
import bgSvg from '../../assets/images/flags/bg.svg';
import bhSvg from '../../assets/images/flags/bh.svg';
import biSvg from '../../assets/images/flags/bi.svg';
import bjSvg from '../../assets/images/flags/bj.svg';
import bmSvg from '../../assets/images/flags/bm.svg';
import boSvg from '../../assets/images/flags/bo.svg';
import brSvg from '../../assets/images/flags/br.svg';
import bsSvg from '../../assets/images/flags/bs.svg';
import btSvg from '../../assets/images/flags/bt.svg';
import bwSvg from '../../assets/images/flags/bw.svg';
import bySvg from '../../assets/images/flags/by.svg';
import bzSvg from '../../assets/images/flags/bz.svg';
import caSvg from '../../assets/images/flags/ca.svg';
import cdSvg from '../../assets/images/flags/cd.svg';
import cfSvg from '../../assets/images/flags/cf.svg';
import cgSvg from '../../assets/images/flags/cg.svg';
import chSvg from '../../assets/images/flags/ch.svg';
import ciSvg from '../../assets/images/flags/ci.svg';
import ckSvg from '../../assets/images/flags/ck.svg';
import clSvg from '../../assets/images/flags/cl.svg';
import cmSvg from '../../assets/images/flags/cm.svg';
import cnSvg from '../../assets/images/flags/cn.svg';
import coSvg from '../../assets/images/flags/co.svg';
import crSvg from '../../assets/images/flags/cr.svg';
import cuSvg from '../../assets/images/flags/cu.svg';
import cvSvg from '../../assets/images/flags/cv.svg';
import cySvg from '../../assets/images/flags/cy.svg';
import czSvg from '../../assets/images/flags/cz.svg';
import deSvg from '../../assets/images/flags/de.svg';
import djSvg from '../../assets/images/flags/dj.svg';
import dkSvg from '../../assets/images/flags/dk.svg';
import dmSvg from '../../assets/images/flags/dm.svg';
import doSvg from '../../assets/images/flags/do.svg';
import dzSvg from '../../assets/images/flags/dz.svg';
import ecSvg from '../../assets/images/flags/ec.svg';
import eeSvg from '../../assets/images/flags/ee.svg';
import egSvg from '../../assets/images/flags/eg.svg';
import erSvg from '../../assets/images/flags/er.svg';
import esSvg from '../../assets/images/flags/es.svg';
import etSvg from '../../assets/images/flags/et.svg';
import fiSvg from '../../assets/images/flags/fi.svg';
import fjSvg from '../../assets/images/flags/fj.svg';
import fkSvg from '../../assets/images/flags/fk.svg';
import fmSvg from '../../assets/images/flags/fm.svg';
import foSvg from '../../assets/images/flags/fo.svg';
import frSvg from '../../assets/images/flags/fr.svg';
import gaSvg from '../../assets/images/flags/ga.svg';
import gbSvg from '../../assets/images/flags/gb.svg';
import gdSvg from '../../assets/images/flags/gd.svg';
import geSvg from '../../assets/images/flags/ge.svg';
import ggSvg from '../../assets/images/flags/gg.svg';
import ghSvg from '../../assets/images/flags/gh.svg';
import giSvg from '../../assets/images/flags/gi.svg';
import glSvg from '../../assets/images/flags/gl.svg';
import gmSvg from '../../assets/images/flags/gm.svg';
import gnSvg from '../../assets/images/flags/gn.svg';
import gqSvg from '../../assets/images/flags/gq.svg';
import grSvg from '../../assets/images/flags/gr.svg';
import gtSvg from '../../assets/images/flags/gt.svg';
import guSvg from '../../assets/images/flags/gu.svg';
import gwSvg from '../../assets/images/flags/gw.svg';
import hkSvg from '../../assets/images/flags/hk.svg';
import hnSvg from '../../assets/images/flags/hn.svg';
import hrSvg from '../../assets/images/flags/hr.svg';
import htSvg from '../../assets/images/flags/ht.svg';
import huSvg from '../../assets/images/flags/hu.svg';
import idSvg from '../../assets/images/flags/id.svg';
import ieSvg from '../../assets/images/flags/ie.svg';
import ilSvg from '../../assets/images/flags/il.svg';
import imSvg from '../../assets/images/flags/im.svg';
import inSvg from '../../assets/images/flags/in.svg';
import ioSvg from '../../assets/images/flags/io.svg';
import iqSvg from '../../assets/images/flags/iq.svg';
import irSvg from '../../assets/images/flags/ir.svg';
import isSvg from '../../assets/images/flags/is.svg';
import itSvg from '../../assets/images/flags/it.svg';
import jeSvg from '../../assets/images/flags/je.svg';
import jmSvg from '../../assets/images/flags/jm.svg';
import joSvg from '../../assets/images/flags/jo.svg';
import jpSvg from '../../assets/images/flags/jp.svg';
import keSvg from '../../assets/images/flags/ke.svg';
import kgSvg from '../../assets/images/flags/kg.svg';
import khSvg from '../../assets/images/flags/kh.svg';
import kiSvg from '../../assets/images/flags/ki.svg';
import kmSvg from '../../assets/images/flags/km.svg';
import knSvg from '../../assets/images/flags/kn.svg';
import kpSvg from '../../assets/images/flags/kp.svg';
import krSvg from '../../assets/images/flags/kr.svg';
import kwSvg from '../../assets/images/flags/kw.svg';
import kySvg from '../../assets/images/flags/ky.svg';
import kzSvg from '../../assets/images/flags/kz.svg';
import laSvg from '../../assets/images/flags/la.svg';
import lbSvg from '../../assets/images/flags/lb.svg';
import liSvg from '../../assets/images/flags/li.svg';
import lkSvg from '../../assets/images/flags/lk.svg';
import lrSvg from '../../assets/images/flags/lr.svg';
import lsSvg from '../../assets/images/flags/ls.svg';
import ltSvg from '../../assets/images/flags/lt.svg';
import luSvg from '../../assets/images/flags/lu.svg';
import lvSvg from '../../assets/images/flags/lv.svg';
import lySvg from '../../assets/images/flags/ly.svg';
import maSvg from '../../assets/images/flags/ma.svg';
import mcSvg from '../../assets/images/flags/mc.svg';
import mdSvg from '../../assets/images/flags/md.svg';
import meSvg from '../../assets/images/flags/me.svg';
import mgSvg from '../../assets/images/flags/mg.svg';
import mhSvg from '../../assets/images/flags/mh.svg';
import mkSvg from '../../assets/images/flags/mk.svg';
import mlSvg from '../../assets/images/flags/ml.svg';
import mmSvg from '../../assets/images/flags/mm.svg';
import mnSvg from '../../assets/images/flags/mn.svg';
import moSvg from '../../assets/images/flags/mo.svg';
import mpSvg from '../../assets/images/flags/mp.svg';
import mqSvg from '../../assets/images/flags/mq.svg';
import mrSvg from '../../assets/images/flags/mr.svg';
import msSvg from '../../assets/images/flags/ms.svg';
import mtSvg from '../../assets/images/flags/mt.svg';
import muSvg from '../../assets/images/flags/mu.svg';
import mvSvg from '../../assets/images/flags/mv.svg';
import mwSvg from '../../assets/images/flags/mw.svg';
import mxSvg from '../../assets/images/flags/mx.svg';
import mySvg from '../../assets/images/flags/my.svg';
import mzSvg from '../../assets/images/flags/mz.svg';
import naSvg from '../../assets/images/flags/na.svg';
import neSvg from '../../assets/images/flags/ne.svg';
import nfSvg from '../../assets/images/flags/nf.svg';
import ngSvg from '../../assets/images/flags/ng.svg';
import niSvg from '../../assets/images/flags/ni.svg';
import nlSvg from '../../assets/images/flags/nl.svg';
import noSvg from '../../assets/images/flags/no.svg';
import npSvg from '../../assets/images/flags/np.svg';
import nrSvg from '../../assets/images/flags/nr.svg';
import nuSvg from '../../assets/images/flags/nu.svg';
import nzSvg from '../../assets/images/flags/nz.svg';
import omSvg from '../../assets/images/flags/om.svg';
import paSvg from '../../assets/images/flags/pa.svg';
import peSvg from '../../assets/images/flags/pe.svg';
import pfSvg from '../../assets/images/flags/pf.svg';
import pgSvg from '../../assets/images/flags/pg.svg';
import phSvg from '../../assets/images/flags/ph.svg';
import pkSvg from '../../assets/images/flags/pk.svg';
import plSvg from '../../assets/images/flags/pl.svg';
import pnSvg from '../../assets/images/flags/pn.svg';
import prSvg from '../../assets/images/flags/pr.svg';
import psSvg from '../../assets/images/flags/ps.svg';
import ptSvg from '../../assets/images/flags/pt.svg';
import pwSvg from '../../assets/images/flags/pw.svg';
import pySvg from '../../assets/images/flags/py.svg';
import qaSvg from '../../assets/images/flags/qa.svg';
import roSvg from '../../assets/images/flags/ro.svg';
import rsSvg from '../../assets/images/flags/rs.svg';
import ruSvg from '../../assets/images/flags/ru.svg';
import rwSvg from '../../assets/images/flags/rw.svg';
import saSvg from '../../assets/images/flags/sa.svg';
import sbSvg from '../../assets/images/flags/sb.svg';
import scSvg from '../../assets/images/flags/sc.svg';
import sdSvg from '../../assets/images/flags/sd.svg';
import seSvg from '../../assets/images/flags/se.svg';
import sgSvg from '../../assets/images/flags/sg.svg';
import siSvg from '../../assets/images/flags/si.svg';
import skSvg from '../../assets/images/flags/sk.svg';
import slSvg from '../../assets/images/flags/sl.svg';
import smSvg from '../../assets/images/flags/sm.svg';
import snSvg from '../../assets/images/flags/sn.svg';
import soSvg from '../../assets/images/flags/so.svg';
import srSvg from '../../assets/images/flags/sr.svg';
import stSvg from '../../assets/images/flags/st.svg';
import svSvg from '../../assets/images/flags/sv.svg';
import sySvg from '../../assets/images/flags/sy.svg';
import szSvg from '../../assets/images/flags/sz.svg';
import tcSvg from '../../assets/images/flags/tc.svg';
import tdSvg from '../../assets/images/flags/td.svg';
import tgSvg from '../../assets/images/flags/tg.svg';
import thSvg from '../../assets/images/flags/th.svg';
import tjSvg from '../../assets/images/flags/tj.svg';
import tkSvg from '../../assets/images/flags/tk.svg';
import tmSvg from '../../assets/images/flags/tm.svg';
import tnSvg from '../../assets/images/flags/tn.svg';
import toSvg from '../../assets/images/flags/to.svg';
import trSvg from '../../assets/images/flags/tr.svg';
import ttSvg from '../../assets/images/flags/tt.svg';
import tvSvg from '../../assets/images/flags/tv.svg';
import twSvg from '../../assets/images/flags/tw.svg';
import tzSvg from '../../assets/images/flags/tz.svg';
import uaSvg from '../../assets/images/flags/ua.svg';
import ugSvg from '../../assets/images/flags/ug.svg';
import usSvg from '../../assets/images/flags/us.svg';
import uySvg from '../../assets/images/flags/uy.svg';
import uzSvg from '../../assets/images/flags/uz.svg';
import veSvg from '../../assets/images/flags/ve.svg';
import viSvg from '../../assets/images/flags/vi.svg';
import vnSvg from '../../assets/images/flags/vn.svg';
import vuSvg from '../../assets/images/flags/vu.svg';
import wsSvg from '../../assets/images/flags/ws.svg';
import yeSvg from '../../assets/images/flags/ye.svg';
import zaSvg from '../../assets/images/flags/za.svg';
import zmSvg from '../../assets/images/flags/zm.svg';
import zwSvg from '../../assets/images/flags/zw.svg';

const FLAG_SELECTOR_OPTION_LIST = [
  {
    name: 'Afghanistan',
    displayText: '+93',
    areaCode: 93,
    id: 'af',
    flag: afSvg,
  },
  {
    name: 'Albania',
    displayText: '+355',
    areaCode: 355,
    id: 'al',
    flag: alSvg,
  },
  {
    name: 'Algeria',
    displayText: '+213',
    areaCode: 213,
    id: 'dz',
    flag: dzSvg,
  },
  {
    name: 'AmericanSamoa',
    displayText: '+1 684',
    areaCode: 1684,
    id: 'as',
    flag: asSvg,
  },
  {
    name: 'Andorra',
    displayText: '+376',
    areaCode: 376,
    id: 'ad',
    flag: adSvg,
  },
  {
    name: 'Angola',
    displayText: '+244',
    areaCode: 244,
    id: 'ao',
    flag: aoSvg,
  },
  {
    name: 'Anguilla',
    displayText: '+1 264',
    areaCode: 1264,
    id: 'ai',
    flag: aiSvg,
  },
  {
    name: 'Antigua and Barbuda',
    displayText: '+1268',
    areaCode: 1268,
    id: 'ag',
    flag: agSvg,
  },
  {
    name: 'Argentina',
    displayText: '+54',
    areaCode: 54,
    id: 'ar',
    flag: arSvg,
  },
  {
    name: 'Armenia',
    displayText: '+374',
    areaCode: 374,
    id: 'am',
    flag: amSvg,
  },
  {
    name: 'Aruba',
    displayText: '+297',
    areaCode: 297,
    id: 'aw',
    flag: awSvg,
  },
  {
    name: 'Australia',
    displayText: '+61',
    areaCode: 61,
    id: 'au',
    flag: auSvg,
  },
  {
    name: 'Austria',
    displayText: '+43',
    areaCode: 43,
    id: 'at',
    flag: atSvg,
  },
  {
    name: 'Azerbaijan',
    displayText: '+994',
    areaCode: 994,
    id: 'az',
    flag: azSvg,
  },
  {
    name: 'Bahamas',
    displayText: '+1 242',
    areaCode: 1242,
    id: 'bs',
    flag: bsSvg,
  },
  {
    name: 'Bahrain',
    displayText: '+973',
    areaCode: 973,
    id: 'bh',
    flag: bhSvg,
  },
  {
    name: 'Bangladesh',
    displayText: '+880',
    areaCode: 880,
    id: 'bd',
    flag: bdSvg,
  },
  {
    name: 'Barbados',
    displayText: '+1 246',
    areaCode: 1246,
    id: 'bb',
    flag: bbSvg,
  },
  {
    name: 'Belarus',
    displayText: '+375',
    areaCode: 375,
    id: 'by',
    flag: bySvg,
  },
  {
    name: 'Belgium',
    displayText: '+32',
    areaCode: 32,
    id: 'be',
    flag: beSvg,
  },
  {
    name: 'Belize',
    displayText: '+501',
    areaCode: 501,
    id: 'bz',
    flag: bzSvg,
  },
  {
    name: 'Benin',
    displayText: '+229',
    areaCode: 229,
    id: 'bj',
    flag: bjSvg,
  },
  {
    name: 'Bermuda',
    displayText: '+1 441',
    areaCode: 1441,
    id: 'bm',
    flag: bmSvg,
  },
  {
    name: 'Bhutan',
    displayText: '+975',
    areaCode: 975,
    id: 'bt',
    flag: btSvg,
  },
  {
    name: 'Bosnia and Herzegovina',
    displayText: '+387',
    areaCode: 387,
    id: 'ba',
    flag: baSvg,
  },
  {
    name: 'Botswana',
    displayText: '+267',
    areaCode: 267,
    id: 'bw',
    flag: bwSvg,
  },
  {
    name: 'Brazil',
    displayText: '+55',
    areaCode: 55,
    id: 'br',
    flag: brSvg,
  },
  {
    name: 'British Indian Ocean Territory',
    displayText: '+246',
    areaCode: 246,
    id: 'io',
    flag: ioSvg,
  },
  {
    name: 'Bulgaria',
    displayText: '+359',
    areaCode: 359,
    id: 'bg',
    flag: bgSvg,
  },
  {
    name: 'Burkina Faso',
    displayText: '+226',
    areaCode: 226,
    id: 'bf',
    flag: bfSvg,
  },
  {
    name: 'Burundi',
    displayText: '+257',
    areaCode: 257,
    id: 'bi',
    flag: biSvg,
  },
  {
    name: 'Cambodia',
    displayText: '+855',
    areaCode: 855,
    id: 'kh',
    flag: khSvg,
  },
  {
    name: 'Cameroon',
    displayText: '+237',
    areaCode: 237,
    id: 'cm',
    flag: cmSvg,
  },
  {
    name: 'Canada',
    displayText: '+1',
    areaCode: 1,
    id: 'ca',
    flag: caSvg,
  },
  {
    name: 'Cape Verde',
    displayText: '+238',
    areaCode: 238,
    id: 'cv',
    flag: cvSvg,
  },
  {
    name: 'Cayman Islands',
    displayText: '+ 345',
    areaCode: 345,
    id: 'ky',
    flag: kySvg,
  },
  {
    name: 'Central African Republic',
    displayText: '+236',
    areaCode: 236,
    id: 'cf',
    flag: cfSvg,
  },
  {
    name: 'Chad',
    displayText: '+235',
    areaCode: 235,
    id: 'td',
    flag: tdSvg,
  },
  {
    name: 'Chile',
    displayText: '+56',
    areaCode: 56,
    id: 'cl',
    flag: clSvg,
  },
  {
    name: 'China',
    displayText: '+86',
    areaCode: 86,
    id: 'cn',
    flag: cnSvg,
  },
  {
    name: 'Colombia',
    displayText: '+57',
    areaCode: 57,
    id: 'co',
    flag: coSvg,
  },
  {
    name: 'Comoros',
    displayText: '+269',
    areaCode: 269,
    id: 'km',
    flag: kmSvg,
  },
  {
    name: 'Congo',
    displayText: '+242',
    areaCode: 242,
    id: 'cg',
    flag: cgSvg,
  },
  {
    name: 'Cook Islands',
    displayText: '+682',
    areaCode: 682,
    id: 'ck',
    flag: ckSvg,
  },
  {
    name: 'Costa Rica',
    displayText: '+506',
    areaCode: 506,
    id: 'cr',
    flag: crSvg,
  },
  {
    name: 'Croatia',
    displayText: '+385',
    areaCode: 385,
    id: 'hr',
    flag: hrSvg,
  },
  {
    name: 'Cuba',
    displayText: '+53',
    areaCode: 53,
    id: 'cu',
    flag: cuSvg,
  },
  {
    name: 'Cyprus',
    displayText: '+537',
    areaCode: 537,
    id: 'cy',
    flag: cySvg,
  },
  {
    name: 'Czech Republic',
    displayText: '+420',
    areaCode: 420,
    id: 'cz',
    flag: czSvg,
  },
  {
    name: 'Denmark',
    displayText: '+45',
    areaCode: 45,
    id: 'dk',
    flag: dkSvg,
  },
  {
    name: 'Djibouti',
    displayText: '+253',
    areaCode: 253,
    id: 'dj',
    flag: djSvg,
  },
  {
    name: 'Dominica',
    displayText: '+1 767',
    areaCode: 1767,
    id: 'dm',
    flag: dmSvg,
  },
  {
    name: 'Dominican Republic',
    displayText: '+1 849',
    areaCode: 1849,
    id: 'do',
    flag: doSvg,
  },
  {
    name: 'Ecuador',
    displayText: '+593',
    areaCode: 593,
    id: 'ec',
    flag: ecSvg,
  },
  {
    name: 'Egypt',
    displayText: '+20',
    areaCode: 20,
    id: 'eg',
    flag: egSvg,
  },
  {
    name: 'El Salvador',
    displayText: '+503',
    areaCode: 503,
    id: 'sv',
    flag: svSvg,
  },
  {
    name: 'Equatorial Guinea',
    displayText: '+240',
    areaCode: 240,
    id: 'gq',
    flag: gqSvg,
  },
  {
    name: 'Eritrea',
    displayText: '+291',
    areaCode: 291,
    id: 'er',
    flag: erSvg,
  },
  {
    name: 'Estonia',
    displayText: '+372',
    areaCode: 372,
    id: 'ee',
    flag: eeSvg,
  },
  {
    name: 'Ethiopia',
    displayText: '+251',
    areaCode: 251,
    id: 'et',
    flag: etSvg,
  },
  {
    name: 'Faroe Islands',
    displayText: '+298',
    areaCode: 298,
    id: 'fo',
    flag: foSvg,
  },
  {
    name: 'Fiji',
    displayText: '+679',
    areaCode: 679,
    id: 'fj',
    flag: fjSvg,
  },
  {
    name: 'Finland',
    displayText: '+358',
    areaCode: 358,
    id: 'fi',
    flag: fiSvg,
  },
  {
    name: 'France',
    displayText: '+33',
    areaCode: 33,
    id: 'fr',
    flag: frSvg,
  },
  {
    name: 'French Polynesia',
    displayText: '+689',
    areaCode: 689,
    id: 'pf',
    flag: pfSvg,
  },
  {
    name: 'Gabon',
    displayText: '+241',
    areaCode: 241,
    id: 'ga',
    flag: gaSvg,
  },
  {
    name: 'Gambia',
    displayText: '+220',
    areaCode: 220,
    id: 'gm',
    flag: gmSvg,
  },
  {
    name: 'Georgia',
    displayText: '+995',
    areaCode: 995,
    id: 'ge',
    flag: geSvg,
  },
  {
    name: 'Germany',
    displayText: '+49',
    areaCode: 49,
    id: 'de',
    flag: deSvg,
  },
  {
    name: 'Ghana',
    displayText: '+233',
    areaCode: 233,
    id: 'gh',
    flag: ghSvg,
  },
  {
    name: 'Gibraltar',
    displayText: '+350',
    areaCode: 350,
    id: 'gi',
    flag: giSvg,
  },
  {
    name: 'Greece',
    displayText: '+30',
    areaCode: 30,
    id: 'gr',
    flag: grSvg,
  },
  {
    name: 'Greenland',
    displayText: '+299',
    areaCode: 299,
    id: 'gl',
    flag: glSvg,
  },
  {
    name: 'Grenada',
    displayText: '+1 473',
    areaCode: 1473,
    id: 'gd',
    flag: gdSvg,
  },
  {
    name: 'Guam',
    displayText: '+1 671',
    areaCode: 1671,
    id: 'gu',
    flag: guSvg,
  },
  {
    name: 'Guatemala',
    displayText: '+502',
    areaCode: 502,
    id: 'gt',
    flag: gtSvg,
  },
  {
    name: 'Guinea',
    displayText: '+224',
    areaCode: 224,
    id: 'gn',
    flag: gnSvg,
  },
  {
    name: 'Guinea-Bissau',
    displayText: '+245',
    areaCode: 245,
    id: 'gw',
    flag: gwSvg,
  },
  {
    name: 'Haiti',
    displayText: '+509',
    areaCode: 509,
    id: 'ht',
    flag: htSvg,
  },
  {
    name: 'Honduras',
    displayText: '+504',
    areaCode: 504,
    id: 'hn',
    flag: hnSvg,
  },
  {
    name: 'Hungary',
    displayText: '+36',
    areaCode: 36,
    id: 'hu',
    flag: huSvg,
  },
  {
    name: 'Iceland',
    displayText: '+354',
    areaCode: 354,
    id: 'is',
    flag: isSvg,
  },
  {
    name: 'India',
    displayText: '+91',
    areaCode: 91,
    id: 'in',
    flag: inSvg,
  },
  {
    name: 'Indonesia',
    displayText: '+62',
    areaCode: 62,
    id: 'id',
    flag: idSvg,
  },
  {
    name: 'Iraq',
    displayText: '+964',
    areaCode: 964,
    id: 'iq',
    flag: iqSvg,
  },
  {
    name: 'Ireland',
    displayText: '+353',
    areaCode: 353,
    id: 'ie',
    flag: ieSvg,
  },
  {
    name: 'Israel',
    displayText: '+972',
    areaCode: 972,
    id: 'il',
    flag: ilSvg,
  },
  {
    name: 'Italy',
    displayText: '+39',
    areaCode: 39,
    id: 'it',
    flag: itSvg,
  },
  {
    name: 'Jamaica',
    displayText: '+1 876',
    areaCode: 1876,
    id: 'jm',
    flag: jmSvg,
  },
  {
    name: 'Japan',
    displayText: '+81',
    areaCode: 81,
    id: 'jp',
    flag: jpSvg,
  },
  {
    name: 'Jordan',
    displayText: '+962',
    areaCode: 962,
    id: 'jo',
    flag: joSvg,
  },
  {
    name: 'Kazakhstan',
    displayText: '+77',
    areaCode: 77,
    id: 'kz',
    flag: kzSvg,
  },
  {
    name: 'Kenya',
    displayText: '+254',
    areaCode: 254,
    id: 'ke',
    flag: keSvg,
  },
  {
    name: 'Kiribati',
    displayText: '+686',
    areaCode: 686,
    id: 'ki',
    flag: kiSvg,
  },
  {
    name: 'Kuwait',
    displayText: '+965',
    areaCode: 965,
    id: 'kw',
    flag: kwSvg,
  },
  {
    name: 'Kyrgyzstan',
    displayText: '+996',
    areaCode: 996,
    id: 'kg',
    flag: kgSvg,
  },
  {
    name: 'Latvia',
    displayText: '+371',
    areaCode: 371,
    id: 'lv',
    flag: lvSvg,
  },
  {
    name: 'Lebanon',
    displayText: '+961',
    areaCode: 961,
    id: 'lb',
    flag: lbSvg,
  },
  {
    name: 'Lesotho',
    displayText: '+266',
    areaCode: 266,
    id: 'ls',
    flag: lsSvg,
  },
  {
    name: 'Liberia',
    displayText: '+231',
    areaCode: 231,
    id: 'lr',
    flag: lrSvg,
  },
  {
    name: 'Liechtenstein',
    displayText: '+423',
    areaCode: 423,
    id: 'li',
    flag: liSvg,
  },
  {
    name: 'Lithuania',
    displayText: '+370',
    areaCode: 370,
    id: 'lt',
    flag: ltSvg,
  },
  {
    name: 'Luxembourg',
    displayText: '+352',
    areaCode: 352,
    id: 'lu',
    flag: luSvg,
  },
  {
    name: 'Madagascar',
    displayText: '+261',
    areaCode: 261,
    id: 'mg',
    flag: mgSvg,
  },
  {
    name: 'Malawi',
    displayText: '+265',
    areaCode: 265,
    id: 'mw',
    flag: mwSvg,
  },
  {
    name: 'Malaysia',
    displayText: '+60',
    areaCode: 60,
    id: 'my',
    flag: mySvg,
  },
  {
    name: 'Maldives',
    displayText: '+960',
    areaCode: 960,
    id: 'mv',
    flag: mvSvg,
  },
  {
    name: 'Mali',
    displayText: '+223',
    areaCode: 223,
    id: 'ml',
    flag: mlSvg,
  },
  {
    name: 'Malta',
    displayText: '+356',
    areaCode: 356,
    id: 'mt',
    flag: mtSvg,
  },
  {
    name: 'Marshall Islands',
    displayText: '+692',
    areaCode: 692,
    id: 'mh',
    flag: mhSvg,
  },
  {
    name: 'Martinique',
    displayText: '+596',
    areaCode: 596,
    id: 'mq',
    flag: mqSvg,
  },
  {
    name: 'Mauritania',
    displayText: '+222',
    areaCode: 222,
    id: 'mr',
    flag: mrSvg,
  },
  {
    name: 'Mauritius',
    displayText: '+230',
    areaCode: 230,
    id: 'mu',
    flag: muSvg,
  },
  {
    name: 'Mexico',
    displayText: '+52',
    areaCode: 52,
    id: 'mx',
    flag: mxSvg,
  },
  {
    name: 'Monaco',
    displayText: '+377',
    areaCode: 377,
    id: 'mc',
    flag: mcSvg,
  },
  {
    name: 'Mongolia',
    displayText: '+976',
    areaCode: 976,
    id: 'mn',
    flag: mnSvg,
  },
  {
    name: 'Montenegro',
    displayText: '+382',
    areaCode: 382,
    id: 'me',
    flag: meSvg,
  },
  {
    name: 'Montserrat',
    displayText: '+1664',
    areaCode: 1664,
    id: 'ms',
    flag: msSvg,
  },
  {
    name: 'Morocco',
    displayText: '+212',
    areaCode: 212,
    id: 'ma',
    flag: maSvg,
  },
  {
    name: 'Myanmar',
    displayText: '+95',
    areaCode: 95,
    id: 'mm',
    flag: mmSvg,
  },
  {
    name: 'Namibia',
    displayText: '+264',
    areaCode: 264,
    id: 'na',
    flag: naSvg,
  },
  {
    name: 'Nauru',
    displayText: '+674',
    areaCode: 674,
    id: 'nr',
    flag: nrSvg,
  },
  {
    name: 'Nepal',
    displayText: '+977',
    areaCode: 977,
    id: 'np',
    flag: npSvg,
  },
  {
    name: 'Netherlands',
    displayText: '+31',
    areaCode: 31,
    id: 'nl',
    flag: nlSvg,
  },
  {
    name: 'New Zealand',
    displayText: '+64',
    areaCode: 64,
    id: 'nz',
    flag: nzSvg,
  },
  {
    name: 'Nicaragua',
    displayText: '+505',
    areaCode: 505,
    id: 'ni',
    flag: niSvg,
  },
  {
    name: 'Niger',
    displayText: '+227',
    areaCode: 227,
    id: 'ne',
    flag: neSvg,
  },
  {
    name: 'Nigeria',
    displayText: '+234',
    areaCode: 234,
    id: 'ng',
    flag: ngSvg,
  },
  {
    name: 'Niue',
    displayText: '+683',
    areaCode: 683,
    id: 'nu',
    flag: nuSvg,
  },
  {
    name: 'Norfolk Island',
    displayText: '+672',
    areaCode: 672,
    id: 'nf',
    flag: nfSvg,
  },
  {
    name: 'Northern Mariana Islands',
    displayText: '+1 670',
    areaCode: 1670,
    id: 'mp',
    flag: mpSvg,
  },
  {
    name: 'Norway',
    displayText: '+47',
    areaCode: 47,
    id: 'no',
    flag: noSvg,
  },
  {
    name: 'Oman',
    displayText: '+968',
    areaCode: 968,
    id: 'om',
    flag: omSvg,
  },
  {
    name: 'Pakistan',
    displayText: '+92',
    areaCode: 92,
    id: 'pk',
    flag: pkSvg,
  },
  {
    name: 'Palau',
    displayText: '+680',
    areaCode: 680,
    id: 'pw',
    flag: pwSvg,
  },
  {
    name: 'Panama',
    displayText: '+507',
    areaCode: 507,
    id: 'pa',
    flag: paSvg,
  },
  {
    name: 'Papua New Guinea',
    displayText: '+675',
    areaCode: 675,
    id: 'pg',
    flag: pgSvg,
  },
  {
    name: 'Paraguay',
    displayText: '+595',
    areaCode: 595,
    id: 'py',
    flag: pySvg,
  },
  {
    name: 'Peru',
    displayText: '+51',
    areaCode: 51,
    id: 'pe',
    flag: peSvg,
  },
  {
    name: 'Philippines',
    displayText: '+63',
    areaCode: 63,
    id: 'ph',
    flag: phSvg,
  },
  {
    name: 'Poland',
    displayText: '+48',
    areaCode: 48,
    id: 'pl',
    flag: plSvg,
  },
  {
    name: 'Portugal',
    displayText: '+351',
    areaCode: 351,
    id: 'pt',
    flag: ptSvg,
  },
  {
    name: 'Puerto Rico',
    displayText: '+1 939',
    areaCode: 1939,
    id: 'pr',
    flag: prSvg,
  },
  {
    name: 'Qatar',
    displayText: '+974',
    areaCode: 974,
    id: 'qa',
    flag: qaSvg,
  },
  {
    name: 'Romania',
    displayText: '+40',
    areaCode: 40,
    id: 'ro',
    flag: roSvg,
  },
  {
    name: 'Rwanda',
    displayText: '+250',
    areaCode: 250,
    id: 'rw',
    flag: rwSvg,
  },
  {
    name: 'Samoa',
    displayText: '+685',
    areaCode: 685,
    id: 'ws',
    flag: wsSvg,
  },
  {
    name: 'San Marino',
    displayText: '+378',
    areaCode: 378,
    id: 'sm',
    flag: smSvg,
  },
  {
    name: 'Saudi Arabia',
    displayText: '+966',
    areaCode: 966,
    id: 'sa',
    flag: saSvg,
  },
  {
    name: 'Senegal',
    displayText: '+221',
    areaCode: 221,
    id: 'sn',
    flag: snSvg,
  },
  {
    name: 'Serbia',
    displayText: '+381',
    areaCode: 381,
    id: 'rs',
    flag: rsSvg,
  },
  {
    name: 'Seychelles',
    displayText: '+248',
    areaCode: 248,
    id: 'sc',
    flag: scSvg,
  },
  {
    name: 'Sierra Leone',
    displayText: '+232',
    areaCode: 232,
    id: 'sl',
    flag: slSvg,
  },
  {
    name: 'Singapore',
    displayText: '+65',
    areaCode: 65,
    id: 'sg',
    flag: sgSvg,
  },
  {
    name: 'Slovakia',
    displayText: '+421',
    areaCode: 421,
    id: 'sk',
    flag: skSvg,
  },
  {
    name: 'Slovenia',
    displayText: '+386',
    areaCode: 386,
    id: 'si',
    flag: siSvg,
  },
  {
    name: 'Solomon Islands',
    displayText: '+677',
    areaCode: 677,
    id: 'sb',
    flag: sbSvg,
  },
  {
    name: 'South Africa',
    displayText: '+27',
    areaCode: 27,
    id: 'za',
    flag: zaSvg,
  },
  {
    name: 'Spain',
    displayText: '+34',
    areaCode: 34,
    id: 'es',
    flag: esSvg,
  },
  {
    name: 'Sri Lanka',
    displayText: '+94',
    areaCode: 94,
    id: 'lk',
    flag: lkSvg,
  },
  {
    name: 'Sudan',
    displayText: '+249',
    areaCode: 249,
    id: 'sd',
    flag: sdSvg,
  },
  {
    name: 'Suriname',
    displayText: '+597',
    areaCode: 597,
    id: 'sr',
    flag: srSvg,
  },
  {
    name: 'Swaziland',
    displayText: '+268',
    areaCode: 268,
    id: 'sz',
    flag: szSvg,
  },
  {
    name: 'Sweden',
    displayText: '+46',
    areaCode: 46,
    id: 'se',
    flag: seSvg,
  },
  {
    name: 'Switzerland',
    displayText: '+41',
    areaCode: 41,
    id: 'ch',
    flag: chSvg,
  },
  {
    name: 'Tajikistan',
    displayText: '+992',
    areaCode: 992,
    id: 'tj',
    flag: tjSvg,
  },
  {
    name: 'Thailand',
    displayText: '+66',
    areaCode: 66,
    id: 'th',
    flag: thSvg,
  },
  {
    name: 'Togo',
    displayText: '+228',
    areaCode: 228,
    id: 'tg',
    flag: tgSvg,
  },
  {
    name: 'Tokelau',
    displayText: '+690',
    areaCode: 690,
    id: 'tk',
    flag: tkSvg,
  },
  {
    name: 'Tonga',
    displayText: '+676',
    areaCode: 676,
    id: 'to',
    flag: toSvg,
  },
  {
    name: 'Trinidad and Tobago',
    displayText: '+1 868',
    areaCode: 1868,
    id: 'tt',
    flag: ttSvg,
  },
  {
    name: 'Tunisia',
    displayText: '+216',
    areaCode: 216,
    id: 'tn',
    flag: tnSvg,
  },
  {
    name: 'Turkey',
    displayText: '+90',
    areaCode: 90,
    id: 'tr',
    flag: trSvg,
  },
  {
    name: 'Turkmenistan',
    displayText: '+993',
    areaCode: 993,
    id: 'tm',
    flag: tmSvg,
  },
  {
    name: 'Turks and Caicos Islands',
    displayText: '+1 649',
    areaCode: 1649,
    id: 'tc',
    flag: tcSvg,
  },
  {
    name: 'Tuvalu',
    displayText: '+688',
    areaCode: 688,
    id: 'tv',
    flag: tvSvg,
  },
  {
    name: 'Uganda',
    displayText: '+256',
    areaCode: 256,
    id: 'ug',
    flag: ugSvg,
  },
  {
    name: 'Ukraine',
    displayText: '+380',
    areaCode: 380,
    id: 'ua',
    flag: uaSvg,
  },
  {
    name: 'United Arab Emirates',
    displayText: '+971',
    areaCode: 971,
    id: 'ae',
    flag: aeSvg,
  },
  {
    name: 'United Kingdom',
    displayText: '+44',
    areaCode: 44,
    id: 'gb',
    flag: gbSvg,
  },
  {
    name: 'United States',
    displayText: '+1',
    areaCode: 1,
    id: 'us',
    flag: usSvg,
  },
  {
    name: 'Uruguay',
    displayText: '+598',
    areaCode: 598,
    id: 'uy',
    flag: uySvg,
  },
  {
    name: 'Uzbekistan',
    displayText: '+998',
    areaCode: 998,
    id: 'uz',
    flag: uzSvg,
  },
  {
    name: 'Vanuatu',
    displayText: '+678',
    areaCode: 678,
    id: 'vu',
    flag: vuSvg,
  },
  {
    name: 'Yemen',
    displayText: '+967',
    areaCode: 967,
    id: 'ye',
    flag: yeSvg,
  },
  {
    name: 'Zambia',
    displayText: '+260',
    areaCode: 260,
    id: 'zm',
    flag: zmSvg,
  },
  {
    name: 'Zimbabwe',
    displayText: '+263',
    areaCode: 263,
    id: 'zw',
    flag: zwSvg,
  },
  {
    name: 'land Islands',
    dial_code: '',
    code: 'AX',
  },
  {
    name: 'Antarctica',
    dial_code: null,
    code: 'AQ',
  },
  {
    name: 'Bolivia, Plurinational State of',
    displayText: '+591',
    areaCode: 591,
    id: 'bo',
    flag: boSvg,
  },
  {
    name: 'Congo, The Democratic Republic of the',
    displayText: '+243',
    areaCode: 243,
    id: 'cd',
    flag: cdSvg,
  },
  {
    name: "Cote d'Ivoire",
    displayText: '+225',
    areaCode: 225,
    id: 'ci',
    flag: ciSvg,
  },
  {
    name: 'Falkland Islands (Malvinas)',
    displayText: '+500',
    areaCode: 500,
    id: 'fk',
    flag: fkSvg,
  },
  {
    name: 'Guernsey',
    displayText: '+44',
    areaCode: 44,
    id: 'gg',
    flag: ggSvg,
  },
  {
    name: 'Hong Kong',
    displayText: '+852',
    areaCode: 852,
    id: 'hk',
    flag: hkSvg,
  },
  {
    name: 'Iran, Islamic Republic of',
    displayText: '+98',
    areaCode: 98,
    id: 'ir',
    flag: irSvg,
  },
  {
    name: 'Isle of Man',
    displayText: '+44',
    areaCode: 44,
    id: 'im',
    flag: imSvg,
  },
  {
    name: 'Jersey',
    displayText: '+44',
    areaCode: 44,
    id: 'je',
    flag: jeSvg,
  },
  {
    name: "Korea, Democratic People's Republic of",
    displayText: '+850',
    areaCode: 850,
    id: 'kp',
    flag: kpSvg,
  },
  {
    name: 'Korea, Republic of',
    displayText: '+82',
    areaCode: 82,
    id: 'kr',
    flag: krSvg,
  },
  {
    name: "Lao People's Democratic Republic",
    displayText: '+856',
    areaCode: 856,
    id: 'la',
    flag: laSvg,
  },
  {
    name: 'Libyan Arab Jamahiriya',
    displayText: '+218',
    areaCode: 218,
    id: 'ly',
    flag: lySvg,
  },
  {
    name: 'Macao',
    displayText: '+853',
    areaCode: 853,
    id: 'mo',
    flag: moSvg,
  },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    displayText: '+389',
    areaCode: 389,
    id: 'mk',
    flag: mkSvg,
  },
  {
    name: 'Micronesia, Federated States of',
    displayText: '+691',
    areaCode: 691,
    id: 'fm',
    flag: fmSvg,
  },
  {
    name: 'Moldova, Republic of',
    displayText: '+373',
    areaCode: 373,
    id: 'md',
    flag: mdSvg,
  },
  {
    name: 'Mozambique',
    displayText: '+258',
    areaCode: 258,
    id: 'mz',
    flag: mzSvg,
  },
  {
    name: 'Palestinian Territory, Occupied',
    displayText: '+970',
    areaCode: 970,
    id: 'ps',
    flag: psSvg,
  },
  {
    name: 'Pitcairn',
    displayText: '+872',
    areaCode: 872,
    id: 'pn',
    flag: pnSvg,
  },
  {
    name: 'Russia',
    displayText: '+7',
    areaCode: 7,
    id: 'ru',
    flag: ruSvg,
  },
  {
    name: 'Saint Kitts and Nevis',
    displayText: '+1 869',
    areaCode: 1869,
    id: 'kn',
    flag: knSvg,
  },
  {
    name: 'Sao Tome and Principe',
    displayText: '+239',
    areaCode: 239,
    id: 'st',
    flag: stSvg,
  },
  {
    name: 'Somalia',
    displayText: '+252',
    areaCode: 252,
    id: 'so',
    flag: soSvg,
  },
  {
    name: 'Syrian Arab Republic',
    displayText: '+963',
    areaCode: 963,
    id: 'sy',
    flag: sySvg,
  },
  {
    name: 'Taiwan, Province of China',
    displayText: '+886',
    areaCode: 886,
    id: 'tw',
    flag: twSvg,
  },
  {
    name: 'Tanzania, United Republic of',
    displayText: '+255',
    areaCode: 255,
    id: 'tz',
    flag: tzSvg,
  },
  {
    name: 'Venezuela, Bolivarian Republic of',
    displayText: '+58',
    areaCode: 58,
    id: 've',
    flag: veSvg,
  },
  {
    name: 'Viet Nam',
    displayText: '+84',
    areaCode: 84,
    id: 'vn',
    flag: vnSvg,
  },
  {
    name: 'Virgin Islands, U.S.',
    displayText: '+1 340',
    areaCode: 1340,
    id: 'vi',
    flag: viSvg,
  },
];

export default FLAG_SELECTOR_OPTION_LIST;
