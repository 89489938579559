import { reduce } from 'lodash';
import queryString from 'query-string';

const generateQueryValue = (filterValue, filterName) => {
  switch (filterName) {
    case 'reviewType':
    case 'userRoles':
    case 'prizeAmount':
    case 'ratings':
    case 'verificationStatus':
    case 'contentStatusIds':
      return filterValue.join(',');

    case 'businessName':
    case 'businessTypes':
    case 'creators':
    case 'products':
    case 'users':
      return filterValue.map(({ value, label }) => `${label};${value}`).join(',');
    case 'gigStatus':
      return filterValue.filter(value => value !== '0').join(',');
    case 'searchList':
      return '';

    default:
      return filterValue;
  }
};

export const getLocationQueryFromFilters = (filters) => {
  const locationQuery = reduce(
    filters,
    (query, filterValue, filterName) => {
      const updatedQuery = { ...query };
      const filterQueryValue = generateQueryValue(filterValue, filterName);
      if (filterName === 'rating') {
        if (filterQueryValue !== null) {
          updatedQuery[filterName] = filterQueryValue;
        }
        return updatedQuery;
      }

      if (filterQueryValue) {
        updatedQuery[filterName] = filterQueryValue;
      }

      return updatedQuery;
    },
    {},
  );

  return new URLSearchParams(locationQuery).toString();
};

const updateSingleFilter = (currentFilters, defaultFilter, filterName, filtersFromQuery) => {
  let filterValue;
  const filterValueFromQuery = filtersFromQuery[filterName];
  switch (filterName) {
    case 'verificationStatus':
    case 'contentStatusIds':
    case 'gigStatus':
    case 'reviewType':
    case 'userRoles':
      filterValue = filterValueFromQuery ? filterValueFromQuery.split(',') : [];
      break;

    case 'businessName':
    case 'businessTypes':
    case 'creators':
    case 'products':
    case 'users':
      filterValue = filterValueFromQuery
        ? filterValueFromQuery.split(',').map((query) => {
          const label = query.split(';')[0];
          const value = query.split(';')[1] || label;
          return { label, value };
        })
        : [];
      break;

    case 'prizeAmount':
    case 'ratings':
      filterValue = filterValueFromQuery
        ? filterValueFromQuery.split(',').map(item => Number(item))
        : [];
      break;

    case 'state':
      filterValue = filterValueFromQuery && Number(filterValueFromQuery);
      break;

    case 'searchList':
      filterValue = filtersFromQuery.search ? filtersFromQuery.search.split(',') : [];
      break;

    case 'hasGigs':
    case 'showDeactivatedBusiness':
      filterValue = filtersFromQuery[filterName] === 'true';
      break;

    default:
      filterValue = filterValueFromQuery;
      break;
  }

  const updatedFilters = { ...currentFilters };
  if (filterName === 'rating') {
    updatedFilters.rating = null;
    if (filtersFromQuery.rating === 'true') {
      updatedFilters.rating = true;
    } else if (filtersFromQuery.rating === 'false') {
      updatedFilters.rating = false;
    }
  } else {
    updatedFilters[filterName] = filterValue || defaultFilter[filterName];
  }
  return updatedFilters;
};

const getFiltersFromQueryParams = (currentFilters, defaultFilter, queryParams = {}) => reduce(
  defaultFilter,
  (updatedFilters, _, filterName) => updateSingleFilter(updatedFilters, defaultFilter, filterName, queryParams),
  { ...currentFilters },
);

export const applyFiltersFromLocation = (currentFilters, defaultFilter, location) => {
  if (location.search) {
    return getFiltersFromQueryParams(
      currentFilters,
      defaultFilter,
      queryString.parse(location.search),
    );
  }
  return { ...currentFilters };
};
