import { APIRequest } from '../utils/api';

export const GET_US_STATE_LIST_SUCCESS = 'GET_US_STATE_LIST_SUCCESS';
export const GET_US_STATE_LIST_ERROR = 'GET_US_STATE_LIST_ERROR';

export function getUSStateList(data) {
  return async (dispatch) => {
    await APIRequest({
      uri: 'GroupManager/States',
      method: 'GET',
      data,
    })
      .then(({ Data }) => dispatch({
        type: GET_US_STATE_LIST_SUCCESS,
        payload: { data: Data },
      }))
      .catch(error => dispatch({
        type: GET_US_STATE_LIST_ERROR,
        payload: { error },
      }));
  };
}
