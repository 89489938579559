const FLAG_STATUS = {
  PENDING_REVIEW: 1,
  IN_REVIEW: 2,
  FINAL_REVIEW: 3,
  IN_FINAL_REVIEW: 4,
  ADMIN_REVIEW: 5,
  IN_ADMIN_REVIEW: 6,
  ACCEPTABLE: 7,
  REMOVE_FOREVER: 8,
};

export default FLAG_STATUS;
