import './index.sass';

import { string } from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { resendVerificationCode, signUp, verifyIdentifier } from '../../actions/auth';
import { getBusinessSummaryFourSquare, getBusinessSummaryFranki } from '../../actions/businesses';
import { updateProfile } from '../../actions/users';
import orangeCircleCheck from '../../assets/images/orange_circle_check.svg';
import redCircleTimes from '../../assets/images/red_circle_times.svg';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import LightBox from '../../components/LightBox';
import USER_ROLES from '../../config/user-roles';
import encrypt from '../../helpers/crypto';
import HISTORY_TYPE from '../../types/history';
import ROUTER_MATCH_TYPE from '../../types/routerMatch';
import { getPlaceIdProp } from '../../utils/placeId';
import ProgressBar from '../../components/ProgressBar';

/* global atob, localStorage */
function EmailCodeVerification(props) {
  const {
    history,
    match: {
      params: { email },
    },
  } = props;
  const { state = {} } = history.location;
  const { nonFourSquare, profileCreated, businessId, thirdPartySourceId } = state;
  const placeIdProp = getPlaceIdProp();
  const placeId = thirdPartySourceId;
  let password;
  try {
    if (!localStorage.getItem('pw')) throw new Error('No Password');
    password = atob(localStorage.getItem('pw'));
  } catch (_) {
    history.push({
      pathname: `/sign-up/${businessId}?${placeIdProp.query}=${placeId}`,
      state: {
        businessId,
        thirdPartySourceId: placeId,
        nonFourSquare,
        profileCreated,
      },
    });
  }

  const inputEl0 = useRef(null);
  const inputEl1 = useRef(null);
  const inputEl2 = useRef(null);
  const inputEl3 = useRef(null);

  const [isResendDialogVisible, setIsResendDialogVisible] = useState(false);
  const [isVerifyingIdentifier, setIsVerifyingIdentifier] = useState(false);
  const [verifyIdentifierData, setVerifyIdentifierData] = useState(null);
  const [isResendingVerificationCode, setIsResendingVerificationCode] = useState(false);
  const [resendingVerificationCodeSuccess, setResendingVerificationCodeSuccess] = useState(null);
  const [isFetchingBusinessSummary, setIsFetchingBusinessSummary] = useState(false);
  const [businessSummary, setBusinessSummary] = useState(null);
  const [isUpdatingBusinessProfile, setIsUpdatingBusinessProfile] = useState(false);
  const [updateBusinessProfileSuccess, setUpdateBusinessProfileSuccess] = useState(null);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [signUpData, setSignUpData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [verifyingIdentifierErrorMessage, setVerifyingIdentifierErrorMessage] = useState(null);
  const [verificationInput0, setVerificationInput0] = useState(null);
  const [verificationInput1, setVerificationInput1] = useState(null);
  const [verificationInput2, setVerificationInput2] = useState(null);
  const [verificationInput3, setVerificationInput3] = useState(null);

  useEffect(() => {
    setErrorMessage(null);
    setVerifyingIdentifierErrorMessage(null);

    if (
      [verificationInput0, verificationInput1, verificationInput2, verificationInput3].join('')
        .length === 4
    ) {
      setIsVerifyingIdentifier(true);

      verifyIdentifier({
        Identifier: email,
        VerificationCode: [
          verificationInput0,
          verificationInput1,
          verificationInput2,
          verificationInput3,
        ].join(''),
      }).subscribe(
        (response) => {
          setVerifyIdentifierData(response);
        },
        (err) => {
          setVerifyingIdentifierErrorMessage(err);
          setIsVerifyingIdentifier(false);
        },
        () => setIsVerifyingIdentifier(false),
      );
    } else {
      if (!verificationInput0) {
        inputEl0.current.focus();
        return;
      }
      if (!verificationInput1) {
        inputEl1.current.focus();
        return;
      }
      if (!verificationInput2) {
        inputEl2.current.focus();
        return;
      }
      inputEl3.current.focus();
    }
  }, [verificationInput0, verificationInput1, verificationInput2, verificationInput3]);

  useEffect(() => {
    if (verifyIdentifierData) {
      const encryptedPassword = encrypt(password);
      setIsSigningUp(true);
      let callData = { [placeIdProp.api]: placeId };
      if (nonFourSquare) callData = { BusinessId: businessId };

      signUp(encryptedPassword.iv64, {
        ...callData,
        JWTToken: verifyIdentifierData,
        Password: encryptedPassword.password64,
      }).subscribe(
        setSignUpData,
        (err) => {
          setErrorMessage(err);
          setIsSigningUp(false);
        },
        () => setIsSigningUp(false),
      );
    }
  }, [verifyIdentifierData, nonFourSquare]);

  useEffect(() => {
    if (signUpData) {
      const FirstName = localStorage.getItem('firstName');
      const LastName = localStorage.getItem('lastName');

      localStorage.setItem('frankiToken', signUpData.Token.Value);
      localStorage.setItem('frankiUser', signUpData.Profile.UserName);
      localStorage.setItem('frankiRole', USER_ROLES.businessManager);
      localStorage.setItem('frankiName', signUpData.Profile.Fullname || `${FirstName} ${LastName}`);
      localStorage.setItem('frankiUserId', signUpData.Profile.UserId);

      setIsUpdatingBusinessProfile(true);
      updateProfile({
        FirstName,
        LastName,
        Email: email,
      }).subscribe(
        () => setUpdateBusinessProfileSuccess(true),
        (err) => {
          setErrorMessage(err);
          setIsUpdatingBusinessProfile(false);
        },
        () => setIsUpdatingBusinessProfile(false),
      );
    }
  }, [signUpData]);

  useEffect(() => {
    if (updateBusinessProfileSuccess) {
      setIsFetchingBusinessSummary(true);
      let callPlaceId = placeId;
      if (nonFourSquare) {
        callPlaceId = businessId;
      }
      (nonFourSquare ? getBusinessSummaryFranki : getBusinessSummaryFourSquare)(
        callPlaceId,
      ).subscribe(
        (response) => {
          setBusinessSummary(response);
        },
        (err) => {
          setErrorMessage(err);
          setIsFetchingBusinessSummary(false);
        },
        () => setIsFetchingBusinessSummary(false),
      );
    }
  }, [updateBusinessProfileSuccess]);

  useEffect(() => {
    if (businessSummary) {
      history.push({
        pathname: `/mobile-code-verification/${businessSummary.BusinessId}}?${placeIdProp.query}=${placeId}`,
        state: {
          nonFourSquare,
          businessId: businessSummary.BusinessId,
          thirdPartySourceId,
          profileCreated,
        },
      });
    }
  }, [businessSummary]);

  useEffect(() => {
    if (resendingVerificationCodeSuccess) {
      setIsResendDialogVisible(true);
      setTimeout(
        () =>
          setVerifyingIdentifierErrorMessage(
            'Code expired, please click resend to get new verification code',
          ),
        60 * 1000,
      );
    } else {
      setIsResendDialogVisible(false);
    }
  }, [resendingVerificationCodeSuccess]);

  function onHandleResend(e) {
    e.preventDefault();
    setErrorMessage(null);
    setResendingVerificationCodeSuccess(null);
    setVerifyingIdentifierErrorMessage(null);
    setIsResendingVerificationCode(true);
    resendVerificationCode({ Identifier: email }).subscribe(
      setResendingVerificationCodeSuccess,
      (err) => {
        setErrorMessage(err);
        setIsResendingVerificationCode(false);
      },
      () => setIsResendingVerificationCode(false),
    );
    setVerificationInput0('');
    setVerificationInput1('');
    setVerificationInput2('');
    setVerificationInput3('');
  }

  const isFetching =
    isResendingVerificationCode ||
    isVerifyingIdentifier ||
    isFetchingBusinessSummary ||
    isUpdatingBusinessProfile ||
    isSigningUp;

  const error = useMemo(() => {
    const message = errorMessage || verifyingIdentifierErrorMessage;
    return message ? <div className="EmailCodeVerification__Message error">{message}</div> : null;
  }, [errorMessage, verifyingIdentifierErrorMessage]);

  return (
    <div className="EmailCodeVerification">
      <Header auth={false} global />

      <main className="EmailCodeVerification__Main">
        <ProgressBar
          className="EmailCodeVerification__ProgressBar"
          totalSteps={profileCreated ? 5 : 4}
          activeStep={profileCreated ? 3 : 2}
        />
        {/*
        <div className="EmailCodeVerification__Stepper">
          <div className="EmailCodeVerification__Stepper__Step EmailCodeVerification__Stepper__Step--Active">1</div>
          <div className="EmailCodeVerification__Stepper__StepConnector" />
          <div className="EmailCodeVerification__Stepper__Step">2</div>
          <div className="EmailCodeVerification__Stepper__StepConnector" />
          <div className="EmailCodeVerification__Stepper__Step">3</div>
        </div> */}

        <h1>Email Verification</h1>
        <p className="EmailCodeVerification__Subtitle">
          We sent you an email with a 4 digit PIN to make sure you are a human
        </p>

        <form>
          <fieldset>
            <img
              src={verifyingIdentifierErrorMessage ? redCircleTimes : orangeCircleCheck}
              alt="checked"
            />

            <div className="EmailCodeVerification__InputTitle">
              Verification Code
              <span>*</span>
            </div>

            <div className="EmailCodeVerification__Inputs">
              <input
                className={verifyingIdentifierErrorMessage && 'EmailCodeVerification__Input--Error'}
                onChange={({ target: { value } }) => setVerificationInput0(value)}
                maxLength="1"
                ref={inputEl0}
                type="text"
                value={verificationInput0 || ''}
              />

              <input
                className={verifyingIdentifierErrorMessage && 'EmailCodeVerification__Input--Error'}
                onChange={({ target: { value } }) => setVerificationInput1(value)}
                maxLength="1"
                ref={inputEl1}
                type="text"
                value={verificationInput1 || ''}
              />

              <input
                className={verifyingIdentifierErrorMessage && 'EmailCodeVerification__Input--Error'}
                onChange={({ target: { value } }) => setVerificationInput2(value)}
                maxLength="1"
                ref={inputEl2}
                type="text"
                value={verificationInput2 || ''}
              />

              <input
                className={verifyingIdentifierErrorMessage && 'EmailCodeVerification__Input--Error'}
                onChange={({ target: { value } }) => setVerificationInput3(value)}
                maxLength="1"
                ref={inputEl3}
                type="text"
                value={verificationInput3 || ''}
              />
            </div>
          </fieldset>

          {error}

          <button
            onClick={onHandleResend}
            className="button orange EmailCodeVerification__Resend"
            disabled={isFetching || !verifyingIdentifierErrorMessage}
            type="button"
          >
            {isFetching ? 'loading...' : 'RESEND'}
          </button>
        </form>
      </main>

      <button onClick={history.goBack} className="button EmailCodeVerification__Back" type="button">
        <span>BACK</span>
        <i className="icon-chevron-left" />
      </button>

      <Footer />

      <LightBox
        style={{
          width: 500,
          height: null,
          marginLeft: -250,
          marginTop: -200,
        }}
        onClose={() => setIsResendDialogVisible(false)}
        show={isResendDialogVisible}
        title="Email Verification Resent"
      >
        <p className="EmailCodeVerification__LightBoxContent">
          You have 60 seconds to add the code before it times out.
        </p>
      </LightBox>
    </div>
  );
}

EmailCodeVerification.propTypes = {
  match: ROUTER_MATCH_TYPE({
    businessId: string.isRequired,
    email: string.isRequired,
  }).isRequired,
  history: HISTORY_TYPE.isRequired,
};

export default EmailCodeVerification;
