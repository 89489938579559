import { req } from '../utils/api';

export function createGroupManagerUser(data) {
  return req({
    uri: 'Login/CreateGroupManagerUser',
    method: 'POST',
    data,
  });
}

export const dummyExport = {};
