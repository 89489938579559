import './index.sass';

import ProductsTagsList from 'friendly-words';
import { forEach, includes, keys } from 'lodash';
import { arrayOf, bool, func, node, number, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  clearUsers as clearUsersAction,
  searchUsers as searchUsersAction,
} from '../../../actions/users';
import happySvg from '../../../assets/images/happy.svg';
import sadSvg from '../../../assets/images/sad.svg';
import BUSINESS_TYPES from '../../../config/business-types';
import { DEFAULT_PRIZE_AMOUNT, DEFAULT_RATINGS } from '../../../config/filter-params';
import GIG_STATUSES, {
  POST_REVIEW_STATUSES,
  VERIFICATION_STATUSES,
} from '../../../config/gig-status';
import { REVIEW_TYPES } from '../../../config/review-types';
import USER_ROLES from '../../../config/user-roles';
import FILTERS_TYPE from '../../../types/filters';
import SELECT_OPTIONS_TYPE from '../../../types/selectOptions';
import { SEARCHED_USER_TYPE } from '../../../types/users';
import LightBox from '../../LightBox';
import FiltersModalButtonSelect from './FiltersModalButtonSelect';
import FiltersModalCheckbox from './FiltersModalCheckbox';
import FiltersModalFromToDate from './FiltersModalFromToDate';
import FiltersModalLocation from './FiltersModalLocation';
import FiltersModalRangeSlider from './FiltersModalRangeSlider';
import FiltersModalSelect from './FiltersModalSelect';
import FiltersModalTagsInput from './FiltersModalTagsInput';

//import { searchBussinesses as searchBussinessesAction } from '../../../actions/businesses';
class FiltersModal extends React.Component {
  // eslint-disable-next-line react/destructuring-assignment
  state = this.props.modalState;
  userRole = localStorage.getItem('frankiRole');

  componentDidUpdate(prevProps) {
    const { modalState } = this.props;
    const { ...state } = this.state;
    let isInputStateUpdated = false;

    forEach(prevProps.modalState, (value, name) => {
      if (modalState[name] !== value) {
        isInputStateUpdated = true;
        state[name] = modalState[name];
      }
    });

    if (isInputStateUpdated) {
      this.updateState(state);
    }

    this.baseState = this.state;
  }

  onHandleChange(name, value) {
    if (['businessName', 'businessTypes', 'creators', 'products', 'users'].includes(name)) {
      this.setState({ [name]: value, disabled: false });
      return;
    }

    this.setState({ [name]: value, disabled: false });
  }

  onHandleCancelFilters() {
    this.setState(this.baseState);
  }

  onHandleSubmit() {
    const { onModalHandleSubmit } = this.props;
    this.baseState = this.state;
    onModalHandleSubmit(this.state);
  }

  isFilterActive(filterName) {
    return includes(keys(this.state), filterName);
  }

  updateState(state) {
    this.setState(state);
  }

  updateUserSearchTerm(userSearchTerm) {
    if (userSearchTerm.length >= 2) {
      const { searchUsers } = this.props;
      searchUsers(userSearchTerm);
    } else {
      const { clearUsers } = this.props;
      clearUsers();
    }
  }
  updateBussinessSearchTerm(searchTerm) {
    if (searchTerm.length >= 2) {
    } else {
    }
  }

  filterFlagStatusOnRoles = () => {
    const loc = window.location.pathname;

    let options = POST_REVIEW_STATUSES;
    if (this.userRole == USER_ROLES.contentAdmin) {
      options = POST_REVIEW_STATUSES.filter((f) => [1, 2].includes(f.value));
    } else if (this.userRole == USER_ROLES.saleAdmin) {
      options = POST_REVIEW_STATUSES.filter((f) => [1, 2, 3, 4, 5, 6].includes(f.value));
    } else if (this.userRole == USER_ROLES.admin) {
      options = POST_REVIEW_STATUSES.filter((f) => [1, 2, 3, 4, 5, 6].includes(f.value));
    }
    if (
      loc.includes('/admin/videos') &&
      (this.userRole === USER_ROLES.admin || this.userRole === USER_ROLES.saleAdmin)
    ) {
      options.push(POST_REVIEW_STATUSES.filter((f) => f.value == 7)[0]);
    }
    return options;
  };

  render() {
    const {
      content,
      disabled,
      height,
      isSearchingUsers,
      isSearchingBussinesses,
      onModalHandleClose,
      showModal,
      userRoleOptions,
      searchedUsers,
      searchedUsersError,
      usStateOptions,
      width,
    } = this.props;

    const {
      businessName,
      businessTypes,
      closingDateFrom,
      closingDateTo,
      creators,
      fromDate,
      gigStatus,
      hasGigs,
      location,
      prizeAmount,
      products,
      rating,
      ratings,
      reviewType,
      showDeactivatedBusiness,
      state,
      toDate,
      userRoles,
      users,
      verificationStatus,
      contentStatusIds,
    } = this.state;

    let rowClass = 1;

    const increaseRowClass = () => {
      rowClass += 1;
      return true;
    };

    return (
      <LightBox
        show={showModal}
        width={width}
        height={height ? height + 50 : null}
        style={
          height < 400
            ? {
                width: 700,
                height: null,
                marginLeft: -350,
                marginTop: -300,
              }
            : { marginTop: -300 }
        }
        title="Filters"
        onClose={() => {
          this.onHandleCancelFilters();
          onModalHandleClose();
        }}
      >
        <form
          className="FiltersModal"
          onSubmit={(e) => {
            e.preventDefault();
            this.onHandleSubmit();
          }}
        >
          {this.isFilterActive('users') && increaseRowClass() && (
            <FiltersModalTagsInput
              className={`row-${rowClass}`}
              disabled={disabled}
              error={searchedUsersError}
              fetching={isSearchingUsers}
              itemListAsync
              label="Users"
              limit={5}
              listOfTags={searchedUsers.map(({ DisplayName, UserId }) => ({
                label: DisplayName,
                value: UserId,
              }))}
              name="users"
              onChange={(name, value) => this.onHandleChange(name, value)}
              onSearchTermUpdate={(userSearchTerm) => this.updateUserSearchTerm(userSearchTerm)}
              // TODO
              placeholder="users"
              value={users}
            />
          )}

          {this.isFilterActive('businessTypes') && increaseRowClass() && (
            <FiltersModalTagsInput
              className={`row-${rowClass}`}
              disabled={disabled}
              label="Business Types"
              listOfTags={BUSINESS_TYPES}
              name="businessTypes"
              onChange={(name, value) => this.onHandleChange(name, value)}
              placeholder="business types"
              value={businessTypes}
            />
          )}

          {this.isFilterActive('state') && increaseRowClass() && (
            <FiltersModalSelect
              className={`row-${rowClass}`}
              disabled={disabled}
              label="Location"
              name="state"
              onChange={(value) => this.onHandleChange('state', value)}
              options={usStateOptions}
              placeholder="state"
              value={state}
            />
          )}

          {this.isFilterActive('location') && increaseRowClass() && (
            <FiltersModalLocation
              className={`row-${rowClass}`}
              disabled={disabled}
              label="Location"
              onChange={(value) => this.onHandleChange('location', value)}
              placeholder="location"
              value={location}
            />
          )}

          {this.isFilterActive('gigStatus') && increaseRowClass() && (
            <FiltersModalButtonSelect
              className={`row-${rowClass} FiltersModalButtonSelect--3Columns`}
              disabled={disabled}
              label="By Gig Status"
              name="gigStatus"
              onChange={(value) => this.onHandleChange('gigStatus', value)}
              options={GIG_STATUSES}
              value={gigStatus}
            />
          )}

          {this.isFilterActive('userRoles') && increaseRowClass() && (
            <FiltersModalButtonSelect
              className={`row-${rowClass} FiltersModalButtonSelect--2Columns`}
              disabled={disabled}
              label="By Role"
              name="userRoles"
              onChange={(value) => this.onHandleChange('userRoles', value)}
              options={userRoleOptions}
              value={userRoles}
            />
          )}

          {this.isFilterActive('reviewType') && increaseRowClass() && (
            <FiltersModalButtonSelect
              className={`row-${rowClass} FiltersModalButtonSelect--3Columns`}
              disabled={disabled}
              label="By Review Type"
              name="reviewType"
              onChange={(value) => this.onHandleChange('reviewType', value)}
              options={REVIEW_TYPES}
              value={reviewType}
            />
          )}

          {this.isFilterActive('fromDate') && increaseRowClass() && (
            <FiltersModalFromToDate
              className={`row-${rowClass}`}
              disabled={disabled}
              fromDate={fromDate}
              fromId="fromDate"
              label="By Date Created"
              onChange={(name, value) => this.onHandleChange(name, value)}
              toDate={toDate}
              toId="toDate"
            />
          )}

          {this.isFilterActive('creators') && increaseRowClass() && (
            <FiltersModalTagsInput
              className={`row-${rowClass}`}
              disabled={disabled}
              error={searchedUsersError}
              fetching={isSearchingUsers}
              itemListAsync
              label="Creator"
              listOfTags={searchedUsers.map(({ DisplayName, UserId }) => ({
                label: DisplayName,
                value: UserId,
              }))}
              name="creators"
              onChange={(name, value) => this.onHandleChange(name, value)}
              onSearchTermUpdate={(userSearchTerm) => this.updateUserSearchTerm(userSearchTerm)}
              placeholder="search user"
              value={creators}
            />
          )}

          {this.isFilterActive('products') && increaseRowClass() && (
            <FiltersModalTagsInput
              className={`row-${rowClass}`}
              disabled={disabled}
              label="Products"
              listOfTags={ProductsTagsList.predicates.map((item) => ({ label: item, value: item }))}
              name="products"
              onChange={(name, value) => this.onHandleChange(name, value)}
              placeholder="search product"
              value={products}
            />
          )}

          {this.isFilterActive('ratings') && increaseRowClass() && (
            <FiltersModalRangeSlider
              className={`row-${rowClass}`}
              disabled={disabled}
              defaultValue={DEFAULT_RATINGS}
              label="Rating"
              max={5}
              min={0}
              onChange={(value) => this.onHandleChange('ratings', value)}
              value={ratings}
            />
          )}

          {this.isFilterActive('rating') && increaseRowClass() && (
            <fieldset className={`row-${rowClass}`}>
              <legend>Rating</legend>

              <button
                className={`FiltersModal__RatingButton ${
                  rating ? 'FiltersModal__RatingButton--Selected' : null
                }`}
                onClick={() => this.onHandleChange('rating', true)}
                type="button"
              >
                <img alt="happy" src={happySvg} />
              </button>

              <button
                className={`FiltersModal__RatingButton ${
                  rating === false ? 'FiltersModal__RatingButton--Selected' : null
                }`}
                onClick={() => this.onHandleChange('rating', false)}
                type="button"
              >
                <img alt="sad" src={sadSvg} />
              </button>
            </fieldset>
          )}

          {this.isFilterActive('closingDateFrom') && increaseRowClass() && (
            <FiltersModalFromToDate
              className={`row-${rowClass}`}
              disabled={disabled}
              fromDate={closingDateFrom}
              fromId="closingDateFrom"
              label="By Closing Date"
              onChange={(name, value) => this.onHandleChange(name, value)}
              toDate={closingDateTo}
              toId="closingDateTo"
            />
          )}

          {this.isFilterActive('businessName') && increaseRowClass() && (
            <FiltersModalTagsInput
              className={`row-${rowClass}`}
              error={searchedUsersError}
              fetching={isSearchingBussinesses}
              itemListAsync
              disabled={disabled}
              label="Business Name"
              listOfTags={ProductsTagsList.predicates.map((item) => ({ label: item, value: item }))}
              name="businessName"
              onSearchTermUpdate={(userSearchTerm) =>
                this.updateBussinessSearchTerm(userSearchTerm)
              }
              onChange={(name, value) => this.onHandleChange(name, value)}
              placeholder="business name"
              value={businessName}
            />
          )}

          {this.isFilterActive('prizeAmount') && increaseRowClass() && (
            <FiltersModalRangeSlider
              className={`row-${rowClass}`}
              disabled={disabled}
              defaultValue={DEFAULT_PRIZE_AMOUNT}
              label="Prize Amount"
              max={10000}
              min={5}
              onChange={(value) => this.onHandleChange('prizeAmount', value)}
              step={10}
              value={prizeAmount}
            />
          )}

          {this.isFilterActive('hasGigs') && increaseRowClass() && (
            <FiltersModalCheckbox
              className={`row-${rowClass}`}
              disabled={disabled}
              label="Has gigs"
              name="hasGigs"
              onChange={(value) => this.onHandleChange('hasGigs', value)}
              value={hasGigs}
            />
          )}

          {this.isFilterActive('showDeactivatedBusiness') && increaseRowClass() && (
            <FiltersModalCheckbox
              className={`row-${rowClass}`}
              disabled={disabled}
              label="Show deactivated businesses"
              name="showDeactivatedBusiness"
              onChange={(value) => this.onHandleChange('showDeactivatedBusiness', value)}
              value={showDeactivatedBusiness}
            />
          )}
          {this.isFilterActive('verificationStatus') && increaseRowClass() && (
            <FiltersModalButtonSelect
              className={`row-${rowClass} FiltersModalButtonSelect--4Columns`}
              disabled={disabled}
              label="Verification Status"
              name="verificationStatus"
              onChange={(value) => this.onHandleChange('verificationStatus', value)}
              options={VERIFICATION_STATUSES}
              value={verificationStatus}
            />
          )}
          {this.isFilterActive('contentStatusIds') && increaseRowClass() && (
            <FiltersModalButtonSelect
              className={`row-${rowClass} FiltersModalButtonSelect--4Columns`}
              disabled={disabled}
              label="Review Status"
              name="contentStatusIds"
              onChange={(value) => this.onHandleChange('contentStatusIds', value)}
              options={this.filterFlagStatusOnRoles()}
              value={contentStatusIds}
            />
          )}

          {content}

          <div className="buttons-actions" style={{ marginTop: 40, marginBottom: 30 }}>
            <button
              className="button small purple-theme"
              onClick={() => {
                this.onHandleCancelFilters();
                onModalHandleClose();
              }}
              type="button"
            >
              cancel
            </button>

            <button className="button small orange-theme" type="submit">
              apply filters
            </button>
          </div>
        </form>
      </LightBox>
    );
  }
}

FiltersModal.propTypes = {
  clearUsers: func.isRequired,
  content: node,
  disabled: bool,
  height: number,
  searchUsers: func.isRequired,
  isSearchingUsers: bool,
  modalState: FILTERS_TYPE.isRequired,
  onModalHandleClose: func.isRequired,
  onModalHandleSubmit: func.isRequired,
  showModal: bool.isRequired,
  userRoleOptions: SELECT_OPTIONS_TYPE,
  searchedUsers: arrayOf(SEARCHED_USER_TYPE),
  searchedUsersError: string,
  usStateOptions: SELECT_OPTIONS_TYPE,
  width: number,
};

FiltersModal.defaultProps = {
  content: null,
  disabled: false,
  height: 780,
  isSearchingUsers: false,
  userRoleOptions: [],
  searchedUsers: [],
  searchedUsersError: '',
  usStateOptions: [],
  width: 500,
};

const mapStateToProps = (state) => ({
  isSearchingUsers: state.users.isSearchingUsers,
  searchedUsers: state.users.searchedUsers,
  searchedUsersError: state.users.searchedUsersError,
});

export default connect(mapStateToProps, {
  searchUsers: searchUsersAction,
  clearUsers: clearUsersAction,
})(FiltersModal);
export * from './utils';
