import './index.sass';

import { isEmpty } from 'lodash';
import moment from 'moment';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import commentIcon from '../../../assets/images/comment.svg';
import ratingIcon from '../../../assets/images/smiley.svg';
import CustomSelect from '../../CustomSelect';
import Loader from '../../Loader';
import { POST_REVIEW_STATUSES } from '../../../config/gig-status';
import USER_ROLES from '../../../config/user-roles';
import VIDEO_TYPE from '../../../types/video';
import { APIRequest } from '../../../utils/api';
import ClickableImage from '../../ClickableImage';
import HamburgerMenu from '../../HamburgerMenu';

class VideoCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flagOptions: [],
      selectedOption: 0,
      IsUserAllowedToEdit: false,
      isLoading: false,
      errorMessage: '',
      successMessage: '',
    };
    this.userRole = localStorage.getItem('frankiRole');
  }

  componentDidMount() {
    const {
      item: {
        Status: { StatusId },
        IsUserAllowedToEdit,
      },
    } = this.props;

    this.setState({
      flagOptions: this.getFlagStatusBasedOnRoles(StatusId),
      selectedOption: POST_REVIEW_STATUSES.filter((f) => f.value === StatusId)[0],
      IsUserAllowedToEdit,
    });
  }

  getFlagStatusBasedOnRoles = (CurrentStatusId) => {
    let options = POST_REVIEW_STATUSES;
    let targets = [];
    if (this.userRole === USER_ROLES.contentAdmin) {
      if (CurrentStatusId === 1) {
        targets = [2];
      } else if (CurrentStatusId == 2) {
        targets = [];
      }
    } else if (this.userRole === USER_ROLES.saleAdmin) {
      if ([1, 2, 3, 7].includes(CurrentStatusId)) {
        targets = [4];
      } else if (CurrentStatusId === 4) {
        targets = [];
      }
    } else if (this.userRole == USER_ROLES.admin) {
      if ([1, 2, 3, 4, 5, 7].includes(CurrentStatusId)) {
        targets = [6];
      } else if (CurrentStatusId == 6) {
        targets = [];
      }
    }
    options = POST_REVIEW_STATUSES.filter((f) => targets.includes(f.value));
    return options;
  };

  onChangeFlagStatus = async (val) => {
    this.setState({ isLoading: true });
    const {
      item: { Key },
      getVideos,
    } = this.props;
    await APIRequest({
      uri: `Content/${Key}/AdjustStatus`,
      method: 'POST',
      data: {
        NewStatusId: val,
      },
    })
      .then((res) => {
        getVideos();
        this.setState({ selectedOption: POST_REVIEW_STATUSES[val] }); // not need this line..?
      })
      .catch((error) => {
        this.setState({ errorMessage: error });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  renderFlagContent = () => {
    const {
      item: { IsUserAllowedToEdit },
    } = this.props;
    const { flagOptions, selectedOption, isLoading } = this.state;

    if (isLoading) return <Loader />;

    if (IsUserAllowedToEdit) {
      return (
        <CustomSelect
          disabled={flagOptions.length === 0}
          id="contentStatusIds"
          name="contentStatusIds"
          label="Flag Status"
          onChange={({ value }) => this.onChangeFlagStatus(value)}
          options={flagOptions}
          placeholder={selectedOption != null ? selectedOption.label : 'Review Status'}
          value={selectedOption}
          isSearchable={false}
        />
      );
    }
    return (
      <span className="GigSubmissions__Prizes__TotalPrize">
        {selectedOption != null ? selectedOption.label : 'Unknown'}
      </span>
    );
  };

  renderPostBody = (videoDetails) => {
    const caption = videoDetails.Text;
    const taggedUser = /\[UserId:(\d+)\]/g;
    const matchedUsers = caption.match(taggedUser);

    const taggedUsersInVideoData = videoDetails.TaggedUsers || [];

    const taggedUsers = matchedUsers
      ? matchedUsers.map((userId) => {
          const userIdNumber = parseInt(userId.replace('[UserId:', '').replace(']', ''), 10);
          const foundUser = taggedUsersInVideoData.find((user) => user.UserId === userIdNumber);
          return foundUser;
        })
      : [];

    const replacedCaption = taggedUsers
      .filter((user) => user !== undefined)
      .reduce(
        (acc, user) => acc.replace(`[UserId:${user.UserId}]`, `@${user.DisplayName}`),
        caption,
      );

    if (matchedUsers && taggedUsers.length) {
      return `${replacedCaption.substr(0, 150)}${replacedCaption.length > 150 ? '...' : ''}`;
    }

    return `${caption.substr(0, 150)}${caption.length > 150 ? '...' : ''}`;
  };

  render() {
    const { bookmarkItems, item, click, disabled } = this.props;

    return (
      <div
        className={`VideoCard ${item.IsDeleted && 'DeletedItem'} ${
          disabled ? 'VideoCard--Disabled' : ''
        }`}
      >
        {!isEmpty(bookmarkItems) && (
          <HamburgerMenu
            menuItems={bookmarkItems}
            className="VideoCard__Bookmarks"
            icon="icon-bookmark_default"
            position="Left"
          />
        )}

        <div className="VideoCard__Content">
          <div className="VideoCard__Left">
            <figure
              className={`VideoCard__MainImg ${item.GigId && 'VideoCard__MainImg--HasGigId'}`}
            >
              {item.VideoThumbnail && (
                <ClickableImage alt={item.Text} click={click} src={item.VideoThumbnail} />
              )}

              {item.isFeatured && <figcaption className="VideoCard__Featured">featured</figcaption>}
            </figure>

            <div className="VideoCard__Business">
              {item.Business && item.Business.PhotoUrl && (
                <figure className="VideoCard__Avatar">
                  <img src={item.Business.PhotoUrl} alt="avatar" />
                </figure>
              )}

              <div className="VideoCard__Business__Info">
                {
                  <div className="VideoCard__Business__Info__Tag">
                    {(item.Business.BusinessType && item.Business.BusinessType.Name) || 'Hotel'}
                  </div>
                }

                <div className="VideoCard__Business__Info__Title">{item.Business.DisplayName}</div>
              </div>
            </div>
          </div>

          <div className="VideoCard__Wrapper">
            <div className="form-group has-tooltip">{this.renderFlagContent()}</div>
            <div className="VideoCard__PostedBy">
              {item.User && item.User.ProfilePhotoUrl && (
                <figure className="VideoCard__Avatar">
                  <img src={item.User.ProfilePhotoUrl} alt="avatar" />
                </figure>
              )}

              <div className="VideoCard__PostedBy__Info">
                {item.User && item.User.DisplayName && (
                  <span className="VideoCard__PostedBy__Info__User">{item.User.DisplayName}</span>
                )}

                {item.Author && (
                  <span className="VideoCard__PostedBy__Info__User">{item.Author}</span>
                )}

                {item.Created && (
                  <span className="VideoCard__PostedBy__Info__Date">
                    {moment(new Date(item.Created)).format('L - LT')}
                  </span>
                )}
              </div>
            </div>

            {item.Text && (
              <span className="VideoCard__Description">
                {item.Text ? this.renderPostBody(item) : null}
              </span>
            )}
          </div>
        </div>

        <div className="VideoCard__Footer">
          <ul>
            <li>
              <img src={ratingIcon} alt="rating" height="14px" />
              <span className="VideoCard__Footer__Item">{item.Rating || 0}</span>
            </li>

            <li>
              <i className="icon-heart" />
              <span className="VideoCard__Footer__Item">{item.LikeCount || 0}</span>
            </li>

            <li>
              <img src={commentIcon} alt="comment" height="14px" />
              <span className="VideoCard__Footer__Item">{item.CommentCount || 0}</span>
            </li>

            <li>
              <i className="icon-bookmark_selected" />
              <span className="VideoCard__Footer__Item">
                {item.FavoriteCount || item.SaveCount || 0}
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

VideoCard.propTypes = {
  bookmarkItems: instanceOf(Array),
  item: VIDEO_TYPE.isRequired,
  click: func,
  disabled: bool,
};

VideoCard.defaultProps = {
  bookmarkItems: [],
  click: null,
  disabled: false,
};

export default withRouter(VideoCard);
