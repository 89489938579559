import { combineReducers } from 'redux';

import authReducer from './auth';
import businessesReducer from './businesses';
import flaggedContentReducer from './flaggedContent';
import gigsReducer from './gigs';
import pathReducer from './path';
import promotionsReducer from './promotions';
import registerReducer from './register';
import usersReducer from './users';
import usStatesReducer from './usStates';
import videosReducer from './videos';
import businessPromotionsReducer from './businessPromotions';

export default combineReducers({
  auth: authReducer,
  businesses: businessesReducer,
  businessPromotions: businessPromotionsReducer,
  flaggedContent: flaggedContentReducer,
  gigs: gigsReducer,
  promotions: promotionsReducer,
  path: pathReducer,
  register: registerReducer,
  users: usersReducer,
  usStates: usStatesReducer,
  videos: videosReducer,
});
