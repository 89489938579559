import { bool, number, oneOfType, string } from 'prop-types';
import { useCallback, useState } from 'react';

const useFormField = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const onChange = useCallback(e => setValue(e.target.value), []);
  const forceValue = newValue => setValue(newValue);
  return { value, onChange, forceValue };
};

useFormField.propTypes = {
  initialValue: oneOfType([string, number, bool]),
};

useFormField.defaultProps = {
  initialValue: null,
};

export default useFormField;
