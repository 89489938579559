/* eslint-disable react/no-danger */
import './index.sass';

import { bool, func, instanceOf, number, oneOfType, string } from 'prop-types';
import React from 'react';
import uuidv1 from 'uuid/v1';

import Times from '../../../assets/images/times.png';

const mobileArrayColumns = [
  'profile management',
  'gigs',
  'gig winners',
  'service fee',
  'gig match credit',
];

const activeClassName = (className, isActive) => {
  const classList = [className];
  if (isActive) {
    classList.push(`${className}--Active`);
  }
  return classList.join(' ');
};

const PlanBoxes = (props) => {
  const {
    active,
    items,
    onActivate,
    onClick,
    onInactivate,
    period,
    price,
    priceDetails,
    priceWithDiscount,
    title,
  } = props;

  return (
    <div
      className={activeClassName('PlanBoxes', active)}
      onFocus={onActivate}
      onMouseOver={onActivate}
      onMouseLeave={onInactivate}
    >
      <div className={activeClassName('PlanBoxes__Background', active)} />

      <div className={activeClassName('PlanBoxes__Header', active)}>
        <h2 className={activeClassName('PlanBoxes__Title', active)}>{title}</h2>
      </div>

      <ul className={activeClassName('PlanBoxes__Items', active)}>
        {items.map((item, index) => {
          const isBool = typeof item === 'boolean';

          if (!isBool) {
            return (
              <li key={uuidv1()}>
                <i className="icon-success icon-success-column" />

                <span className="PlanBoxes__Items-column">{mobileArrayColumns[index]}</span>

                <span dangerouslySetInnerHTML={{ __html: item }} />
              </li>
            );
          }

          return (
            <li key={uuidv1()}>
              {item === true && (
                <>
                  <i className="icon-success" />

                  <span className="PlanBoxes__Items-column">{mobileArrayColumns[index]}</span>
                </>
              )}

              {item === false && (
                <>
                  <img src={Times} alt="" />

                  <span className="PlanBoxes__Items-column">{mobileArrayColumns[index]}</span>
                </>
              )}
            </li>
          );
        })}
      </ul>

      <div className={activeClassName('PlanBoxes__Footer', active)}>
        <h3 className="PlanBoxes__Price">
          <strong>
            {priceWithDiscount !== 'no charge' ? `$${priceWithDiscount}` : 'no charge'}
          </strong>

          {period && <span className="PlanBoxes__Price__Period">{`/${period}`}</span>}

          {priceDetails && <span className="PlanBoxes__Price__Details">{priceDetails}</span>}
        </h3>

        {price && (
          <span className="PlanBoxes__OldPrice">
            {`$${price}`}
            &nbsp;
            {`/${period}`}
          </span>
        )}

        <button type="button" className="button orange-theme small" onClick={onClick}>
          {priceWithDiscount !== 'no charge' ? 'select plan' : 'CONTINUE WITH FREE'}
        </button>
      </div>
    </div>
  );
};

PlanBoxes.propTypes = {
  active: bool,
  items: instanceOf(Array).isRequired,
  onClick: func.isRequired,
  onInactivate: func,
  onActivate: func,
  period: string,
  price: oneOfType([number, string]).isRequired,
  priceDetails: string,
  priceWithDiscount: oneOfType([number, string]).isRequired,
  title: string.isRequired,
};

PlanBoxes.defaultProps = {
  period: 'month',
  priceDetails: '',
  active: false,
  onInactivate: () => {},
  onActivate: () => {},
};

export default PlanBoxes;
