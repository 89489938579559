import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useTable } from 'react-table';

import {
  GET_BILLING_HISTORY_ERROR,
  getBillingHistoryForGrid,
  getBusinessDetails,
} from '../../../actions/businesses';
import LightBox from '../../../components/LightBox';
import MasterPage from '../../../components/MasterPage';

// console.warn = console.error = () => {};
const columns = [
  {
    Header: 'ID',
    accessor: 'col1', // accessor is the "key" in the data
  },
  {
    Header: 'Date',
    accessor: 'col2',
  },
  {
    Header: 'Transaction Type',
    accessor: 'col3',
  },
  {
    Header: 'Total',
    accessor: 'col4',
  },
  {
    Header: 'Invoice',
    accessor: 'col5',
  },
  {
    Header: 'Status',
    accessor: 'col6',
  },
];
const BusinessesBillingHistory = (props) => {
  const {
    auth,
    match: {
      params: { corporateGroupId },
    },
  } = props;

  const {
    businessBillings,
    businessDetails,
    isFetchingBusinessDetails,
    isFetchingBillingList,
    businessBillingsError,
  } = useSelector((state) => state.businesses);
  const [pageNum, setPageNum] = useState(0);
  const [maxPageNum, setMaxPageNum] = useState(0);
  const dispatch = useDispatch();

  const data = React.useMemo(() => {
    let result = [];
    if (businessBillings.Payments && businessBillings.Payments.length > 0) {
      result = businessBillings.Payments.map(
        ({
          Amount,
          Date: transactionDate,
          PaymentId,
          ReceiptUrl,
          Status: { Name },
          TransactionType,
        }) => ({
          col1: PaymentId,
          col2: moment(new Date(transactionDate)).format('MM/DD/YYYY - HH:mm'),
          col3: TransactionType,
          col4: `$${Amount}`,
          col5:
            Name == 'Free' ? (
              'N/A'
            ) : (
              <a target="_blank" href={ReceiptUrl}>
                Invoice
              </a>
            ),
          col6: Name,
        }),
      );
      setMaxPageNum(Math.floor(businessBillings.Payments.length / 8));
    }
    return result;
  }, [businessBillings]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  useEffect(() => {
    getBillingDataForGrid();
    dispatch(getBusinessDetails(corporateGroupId));
  }, []);

  const getBillingDataForGrid = async () => {
    dispatch(getBillingHistoryForGrid({}, corporateGroupId, 49, ''));
  };

  const incrementPageNum = (val) => {
    setPageNum(pageNum + val);
  };

  const skipTakeRows = (skip, take) => {
    const skippedRows = rows.filter((x, i) => {
      if (i > skip * 8 - 1) {
        return true;
      }
    });
    const result = skippedRows.filter((x, i) => {
      if (i <= take - 1) {
        return true;
      }
    });
    return result;
  };
  const renderPaginator = () => {
    const { Payments } = businessBillings;
    if (Payments && Payments.length == 0) return <></>;
    return (
      <div style={{ paddingTop: '1rem' }}>
        {pageNum > 0 && (
          <button className="button small orange-theme" onClick={() => incrementPageNum(-1)}>
            Back
          </button>
        )}
        {pageNum < maxPageNum && (
          <button className="button small orange-theme" onClick={() => incrementPageNum(+1)}>
            Next
          </button>
        )}
      </div>
    );
  };

  const checkRenderTable = () => {
    const { Payments } = businessBillings;
    if (Payments && Payments.length == 0) return false;
    return true;
  };

  return (
    <>
      <Helmet title="Franki Admin: all gigs" />
      {!isFetchingBusinessDetails && (
        <MasterPage
          auth={auth}
          businessSidebarOn
          businessSidebarData={businessDetails}
          businessMobileTitle="Gigs"
          // {...masterPageConfigs}
        >
          {!checkRenderTable() && (
            <div>
              <p
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '18px',
                  paddingTop: '2rem',
                }}
              >
                This is where all of your invoices will sit. If you feel there is an error, please
                reach out to us at <a href="mailto:billing@befranki.com">billing@befranki.com</a>.
              </p>
            </div>
          )}
          {!isFetchingBillingList && checkRenderTable() && (
            <table className="billingTable" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={{
                          color: '#828282',
                          fontWeight: 'bold',
                          backgroundColor: '#F0F0F0',
                        }}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {skipTakeRows(pageNum, 8).map((row) => {
                  prepareRow(row);
                  return (
                    <tr>
                      {row.cells.map((cell) => (
                        <td
                          style={{
                            padding: '10px !important',
                            color: '#828282',
                            fontSize: '1.4rem',
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {renderPaginator()}
        </MasterPage>
      )}
      <LightBox
        style={{
          width: 500,
          height: null,
          marginLeft: -250,
          marginTop: -200,
        }}
        onClose={() => {
          dispatch({
            type: GET_BILLING_HISTORY_ERROR,
            payload: '',
          });
        }}
        show={businessBillingsError}
        title="Error"
      >
        <>
          <div className="GigSubmissions__AwardConfirm">
            <div className="AsyncContent__ErrorMessage">{businessBillingsError}</div>
          </div>
        </>
      </LightBox>
    </>
  );
};

export default BusinessesBillingHistory;
