import { bool, string } from 'prop-types';
import React from 'react';

import PlanCard from '../../../components/Cards/PlanCard';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';
import BusinessesInfo from '../BusinessInfo';

const BusinessProfileFree = (props) => {
  const {
    auth,
    match: {
      params: { corporateGroupId },
    },
    userRole,
  } = props;
  return (
    <BusinessesInfo
      auth={auth}
      corporateGroupId={corporateGroupId}
      membership={null}
      plan={
        <PlanCard
          bgDeep
          classModification="Small"
          cta
          heading={
            'You are currently using the free version of <span class="Highlight">franki business</span>'
          }
          text="to unlock the full power of what franki can do for you - try upgrading to one of our paid plans to harness the hustle and build your community."
        />
      }
      userRole={userRole}
    />
  );
};

BusinessProfileFree.propTypes = {
  auth: bool.isRequired,
  match: ROUTER_MATCH_TYPE({ corporateGroupId: string.isRequired }).isRequired,
  userRole: string.isRequired,
};

export default BusinessProfileFree;
