import GIG_STATUSES, {
  POST_REVIEW_STATUSES,
  VERIFICATION_STATUSES,
} from '../../../config/gig-status';
import { REVIEW_TYPES } from '../../../config/review-types';

const generateGigStatusTags = gigStatusValues => gigStatusValues.reduce((gigStatusLabels, gigStatusValue) => {
  const gigStatus = GIG_STATUSES.find(({ value }) => value === Number(gigStatusValue));
  return gigStatus ? [...gigStatusLabels, gigStatus.label] : gigStatusLabels;
}, []);

const generateVerificationStatusTags = statusValues => statusValues.reduce((verStatusLabels, statusValue) => {
  const verStatus = VERIFICATION_STATUSES.find(({ value }) => value === Number(statusValue));
  return verStatus ? [...verStatusLabels, verStatus.label] : verStatusLabels;
}, []);

const generateFlagStatusTags = statusValues => statusValues.reduce((verStatusLabels, statusValue) => {
  const verStatus = POST_REVIEW_STATUSES.find(({ value }) => value === Number(statusValue));
  return verStatus ? [...verStatusLabels, verStatus.label] : verStatusLabels;
}, []);

const generateUserRoleTags = (userRoleIds, userRoleOptions) => userRoleIds.reduce((userNames, userRoleId) => {
  const role = userRoleOptions.find(({ value }) => value === Number(userRoleId));
  return role ? [...userNames, role.label] : userNames;
}, []);

const generateStateTag = (stateId, usStateOptions) => {
  const matchedState = usStateOptions.find(({ value }) => value === stateId);
  return matchedState ? [matchedState.label] : [];
};

const generateReviewTypeTags = reviewTypeValues => reviewTypeValues.reduce((reviewTypeLabels, reviewTypeValue) => {
  const reviewType = REVIEW_TYPES.find(({ value }) => value === reviewTypeValue);
  return reviewType ? [...reviewTypeLabels, reviewType.label] : reviewTypeLabels;
}, []);

const generateRatingTag = ([min, max]) => [`${min}-${max} stars`];

const generateFromDateTag = (value, toDate, prefix = '') => {
  if (toDate) {
    return [`${prefix}${value}-${toDate}`];
  }
  return [`${prefix}from ${value}`];
};

const generateToDateTag = (value, fromDate, prefix = '') => {
  if (fromDate) {
    return [];
  }
  return [`${prefix}to ${value}`];
};

const generateTagAndTermMap = (key, value, userRoleOptions, usStateOptions) => {
  switch (key) {
    case 'gigStatus': {
      const matchedStatus = GIG_STATUSES.find(({ label }) => label === value);
      return { key, value: matchedStatus ? String(matchedStatus.value) : '' };
    }
    case 'verificationStatus': {
      const matchedStatus = VERIFICATION_STATUSES.find(({ label }) => label === value);
      return { key, value: matchedStatus ? String(matchedStatus.value) : '' };
    }
    case 'contentStatusIds': {
      const matchedStatus = POST_REVIEW_STATUSES.find(({ label }) => label === value);
      return { key, value: matchedStatus ? String(matchedStatus.value) : '' };
    }
    case 'reviewType': {
      const matchedReviewType = REVIEW_TYPES.find(({ label }) => label === value);
      return { key, value: matchedReviewType.value };
    }
    case 'state': {
      const matchedRole = usStateOptions.find(({ label }) => label === value);
      return { key, value: String(matchedRole.value) };
    }
    case 'userRoles': {
      const matchedRole = userRoleOptions.find(({ label }) => label === value);
      return { key, value: String(matchedRole.value) };
    }
    default:
      return { key, value };
  }
};

const generateTag = (
  value,
  key,
  { tagList, tagAndTermMap, filters, userRoleOptions, usStateOptions },
) => {
  let newTags;
  switch (key) {
    case 'businessName':
    case 'businessTypes':
    case 'creators':
    case 'products':
    case 'searchList':
    case 'users':
      newTags = value;
      break;

    case 'hasGigs':
      newTags = value ? ['has gigs'] : [];
      break;

    case 'rating':
      newTags = value ? ['**happy**'] : ['**sad**'];
      break;

    case 'location':
      newTags = [value];
      break;

    case 'showDeactivatedBusiness':
      newTags = value ? ['show deactivated business'] : [];
      break;

    case 'prizeAmount':
      newTags = [value.map(amount => `$${amount}`).join('-')];
      break;

    case 'gigStatus':
      newTags = generateGigStatusTags(value);
      break;

    case 'userRoles':
      newTags = generateUserRoleTags(value, userRoleOptions);
      break;

    case 'state':
      newTags = generateStateTag(value, usStateOptions);
      break;

    case 'reviewType':
      newTags = generateReviewTypeTags(value);
      break;

    case 'ratings':
      newTags = generateRatingTag(value);
      break;

    case 'fromDate':
      newTags = generateFromDateTag(value, filters.toDate);
      break;

    case 'toDate':
      newTags = generateToDateTag(value, filters.fromDate);
      break;

    case 'closingDateFrom':
      newTags = generateFromDateTag(value, filters.closingDateTo, 'closing ');
      break;

    case 'closingDateTo':
      newTags = generateToDateTag(value, filters.closingDateFrom, 'closing ');
      break;

    case 'verificationStatus':
      newTags = generateVerificationStatusTags(value);
      break;
    case 'contentStatusIds':
      newTags = generateFlagStatusTags(value);
      break;
    default:
      newTags = [];
      break;
  }

  const newItem = {
    tagList: newTags,
    tagAndTermMap: newTags.map(tagValue => generateTagAndTermMap(key, tagValue, userRoleOptions, usStateOptions)),
  };

  return {
    tagList: [...tagList, ...newItem.tagList],
    tagAndTermMap: [...tagAndTermMap, ...newItem.tagAndTermMap],
  };
};

export default generateTag;
