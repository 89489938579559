import axios from 'axios';
import XMLParser from 'react-xml-parser';

import countryCodes from './countryCodes.json';

export const DEFAULT_COUNTRY_CODE = 1;

export const getCountryCode = () => new Promise(
  async (subscriber) => {
    const { data } = await axios('https://api.hostip.info');
    try {
      const xml = new XMLParser().parseFromString(data);
      const featureMember = xml.children.find(({ name }) => name === 'gml:featureMember');
      const countryAbbrev = featureMember.children[0].children.find(
        ({ name }) => name === 'countryAbbrev',
      );
      const countryCode = countryCodes.find(({ code }) => code === countryAbbrev.value);
      subscriber(countryCode && Number(countryCode.dial_code));
    } catch (e) {
      subscriber(DEFAULT_COUNTRY_CODE);
    }
  },
  subscriber => subscriber(DEFAULT_COUNTRY_CODE),
);
