import './index.sass';
import { func, object, array, oneOfType } from 'prop-types';
import React from 'react';
import Toggle from 'react-toggle';
import CustomSelect from '../../../../components/CustomSelect';
import { valuesToOptions } from '../../../../utils/common';


const BusinessAmenities = (props) => {
  const {
    formState: { BusinessOption },
    businessOptionParamaters,
    onHandleChange,
  } = props;

  const { Options = [] } = businessOptionParamaters.find(item => item.Name === 'AmenitiesAndMore') || {};
  const { Values = [] } = Options.find(item => item.Name === 'Parking') || {};

  return (
    <div className="BusinessAmenities BusinessAmenities--BorderBottom">
      <h4 className="BusinessOptions__Title">
        Amenities and more
      </h4>
      <div className="BusinessAmenities__Grid">
        <div className="BusinessAmenities__Grid">
          {Options.filter(item => item.ValueType === 'Boolean').map(({ DisplayString, Name }) => (
            <label key={Name} className="mb-20 display-flex" htmlFor={`txt${Name}`}>
              <p className="BusinessOptions__Label">{DisplayString}</p>
              <Toggle
                defaultChecked={BusinessOption[Name]}
                icons={false}
                onChange={() => onHandleChange(Name, !BusinessOption[Name])}
              />
            </label>
          ))}
        </div>
        <div>
          <label htmlFor="txtParking" className="BusinessOptions__Label mb-10">
            Parking
          </label>

          <CustomSelect
            id="txtParking"
            showDisabledValue
            name="Parking"
            onChange={selections => onHandleChange('Parking', selections)}
            options={valuesToOptions(Values)}
            placeholder="select multiple options"
            value={valuesToOptions(BusinessOption.Parking || [])}
            closeMenuOnSelect={false}
            isMulti
            isSearchable
          />
        </div>
      </div>
    </div>
  );
};

BusinessAmenities.propTypes = {
  formState: oneOfType([object]),
  businessOptionParamaters: oneOfType([array]),
  onHandleChange: func.isRequired,
};

BusinessAmenities.defaultProps = {
  formState: {},
  businessOptionParamaters: null,
};

export default BusinessAmenities;
