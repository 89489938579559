import { number, shape, string } from 'prop-types';

import MEDIA_TYPE from './media';

const PROMOTION_TYPE = shape({
  CityNames: string.isRequired,
  Photo: MEDIA_TYPE.isRequired,
  PromotionId: number.isRequired,
  PromotionTitle: string.isRequired,
});

export default PROMOTION_TYPE;
