import './index.sass';

import { isEmpty } from 'lodash';
import { instanceOf, string } from 'prop-types';
import React from 'react';
import uuidv1 from 'uuid/v1';

import IMG from '../../assets/images/not_found.png';

const NotFound = (props) => {
  const { imageURI, title, description, links } = props;

  return (
    <div className="notFound">
      <figure className="notFound-img">
        {imageURI ? <img src={imageURI} alt="" /> : <img src={IMG} alt="" />}
      </figure>

      {title && <h1 className="notFound-title">{title}</h1>}

      {description && <p className="notFound-desc">{description}</p>}

      {!isEmpty(links) && (
        <dl className="notFound-links">
          <dt>here are some useful links</dt>
          {links.map((item) => (
            <dd key={uuidv1()}>{item}</dd>
          ))}
        </dl>
      )}
    </div>
  );
};

NotFound.propTypes = {
  imageURI: string,
  title: string,
  description: string,
  links: instanceOf(Array),
};

NotFound.defaultProps = {
  imageURI: null,
  title: '',
  links: [],
  description: '',
};

export default NotFound;
