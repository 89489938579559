import './index.sass';
import { func, object, array, oneOfType } from 'prop-types';
import React from 'react';

import Booking from './Booking';
import Dietary from './Dietary';
import Payment from './Payment';

const BusinessOptions = (props) => {
  const {
    formState,
    businessOptionParamaters,
    onHandleChange,
  } = props;

  return (
    <div className="BusinessOptions BusinessOptions--BorderBottom">
      <Booking
        formState={formState}
        businessOptionParamaters={businessOptionParamaters}
        onHandleChange={onHandleChange}
      />
      <div>
        <Dietary
          formState={formState}
          businessOptionParamaters={businessOptionParamaters}
          onHandleChange={onHandleChange}
        />
      </div>
      <div>
        <Payment
          formState={formState}
          businessOptionParamaters={businessOptionParamaters}
          onHandleChange={onHandleChange}
        />
      </div>
    </div>
  );
};

BusinessOptions.propTypes = {
  formState: oneOfType([object]),
  businessOptionParamaters: oneOfType([array]),
  onHandleChange: func.isRequired,
};

BusinessOptions.defaultProps = {
  formState: {},
  businessOptionParamaters: null,
};

export default BusinessOptions;
