import {
  ACCEPT_FLAGGED_CONTENT,
  GET_ALL_FLAGGED_CONTENT,
  GET_ALL_FLAGGED_CONTENT_ERROR,
  GET_ALL_FLAGGED_CONTENT_SUCCESS,
  GET_FLAGGED_DETAIL,
} from '../actions/flaggedContent';

const initialState = {
  flaggedContents: {},
  flaggedContentsError: '',
  isFetchingFlaggedContents: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_ALL_FLAGGED_CONTENT: {
      return {
        ...state,
        flaggedContentsError: '',
        flaggedContents: {},
        isFetchingFlaggedContents: true,
      };
    }

    case GET_ALL_FLAGGED_CONTENT_SUCCESS: {
      return {
        ...state,
        flaggedContents: payload.Data || {},
        isFetchingFlaggedContents: false,
      };
    }

    case GET_ALL_FLAGGED_CONTENT_ERROR: {
      return {
        ...state,
        flaggedContentsError: payload.error || '',
        isFetchingFlaggedContents: false,
      };
    }

    case ACCEPT_FLAGGED_CONTENT: {
      return {
        ...state,
        complete: payload.data || {},
        completeError: payload.error || '',
      };
    }

    case GET_FLAGGED_DETAIL: {
      return {
        ...state,
        videoDetails: payload || {},
        videoDetailsError: payload.error || '',
      };
    }

    default:
      return state;
  }
}
