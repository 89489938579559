import './index.sass';

import React from 'react';

const iconList = [
  'icon-htmlBullet',
  'icon-back',
  'icon-forward',
  'icon-calendar',
  'icon-call',
  'icon-checkmark',
  'icon-directions',
  'icon-website',
  'icon-add',
  'icon-award',
  'icon-close',
  'icon-warning',
  'icon-error',
  'icon-help',
  'icon-success',
  'icon-eye',
  'icon-eye',
  'icon-bookmark_default',
  'icon-bookmark_selected',
  'icon-search',
  'icon-signout',
  'icon-listview',
  'icon-gridview',
  'icon-gigs',
  'icon-business',
  'icon-ellipsis',
  'icon-flag',
  'icon-settings',
  'icon-user',
  'icon-users',
  'icon-basket',
  'icon-comment',
  'icon-eye',
  'icon-hamburger',
  'icon-heart',
  'icon-location',
  'icon-ribbon',
  'icon-save',
  'icon-star_off',
  'icon-star_on',
  'icon-web',
  'icon-info',
  'icon-lock',
  'icon-info',
  'icon-chevron-down',
  'icon-chevron-left',
  'icon-chevron-up',
  'icon-chevron-right',
  'icon-chevrons-down',
  'icon-chevrons-left',
  'icon-chevrons-up',
  'icon-chevrons-right',
  'icon-image',
  'icon-picture',
  'icon-play',
  'icon-film',
  'icon-files-empty',
];

const Icons = () => (
  <div className="icons-page">
    <h1>Icon list</h1>
    {iconList.map((icon) => (
      <span className="icon-group">
        <span className="icon-container">
          <i className={icon} />
        </span>
        <strong>{`[${icon}]`}</strong>
      </span>
    ))}
  </div>
);

export default Icons;
