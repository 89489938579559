import { bool } from 'prop-types';
import React from 'react';

import { createUser } from '../../../actions/users';
import UserCreateOrEdit from '../CreateOrEdit';

const UserCreate = (props) => {
  const { auth } = props;
  return (
    <UserCreateOrEdit
      showDeleteButton={false}
      auth={auth}
      callBackOnSubmit={createUser}
      pageSubtitle="we will send them an invite, from there they will setup there credentials"
      pageTitle="create user"
      submitButtonCaption="Create User"
    />
  );
};

UserCreate.propTypes = {
  auth: bool.isRequired,
};

export default UserCreate;
