import uuidv1 from 'uuid/v1';
import { uniqueId } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../config/pagination';
import USER_ROLES from '../config/user-roles';
import { REACT_APP_API_V2_URL, REACT_APP_API_URL } from '../config/variables';
import { APIRequest, req } from '../utils/api';
import { isFoursquareData } from '../utils/placeId';

export const ASSIGN_BUSINESS_VIDEO_TO_PRIZES = 'ASSIGN_BUSINESS_VIDEO_TO_PRIZES';
export const CREATE_BUSINESS_PROFILE = 'CREATE_BUSINESS_PROFILE';
export const CONTACT = 'CONTACT';
export const CONTACT_ERROR = 'CONTACT_ERROR';
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS';
export const GET_BUSINESS_CLAIM_DETAILS = 'GET_BUSINESS_CLAIM_DETAILS';
export const GET_BUSINESS_CLAIM_DETAILS_ERROR = 'GET_BUSINESS_CLAIM_DETAILS_ERROR';
export const GET_BUSINESS_CLAIM_DETAILS_SUCCESS = 'GET_BUSINESS_CLAIM_DETAILS_SUCCESS';
export const GET_BUSINESS_DETAILS = 'GET_BUSINESS_DETAILS';
export const GET_BUSINESS_DETAILS_ERROR = 'GET_BUSINESS_DETAILS_ERROR';
export const GET_BUSINESS_DETAILS_SUCCESS = 'GET_BUSINESS_DETAILS_SUCCESS';
export const GET_BUSINESS_GIGS = 'GET_BUSINESS_GIGS';
export const GET_BUSINESS_GIGS_ERROR = 'GET_BUSINESS_GIGS_ERROR';
export const GET_BUSINESS_GIGS_SUCCESS = 'GET_BUSINESS_GIGS_SUCCESS';
export const GET_BUSINESS_PROFILE = 'GET_BUSINESS_PROFILE';
export const GET_BUSINESS_PROFILE_ERROR = 'GET_BUSINESS_PROFILE_ERROR';
export const GET_BUSINESS_PROFILE_SUCCESS = 'GET_BUSINESS_PROFILE_SUCCESS';
export const GET_BUSINESS_HOURS = 'GET_BUSINESS_HOURS';
export const GET_BUSINESS_HOURS_ERROR = 'GET_BUSINESS_HOURS_ERROR';
export const GET_BUSINESS_HOURS_SUCCESS = 'GET_BUSINESS_HOURS_SUCCESS';
export const GET_BUSINESS_SOCIAL_MEDIA = 'GET_BUSINESS_SOCIAL_MEDIA';
export const GET_BUSINESS_SOCIAL_MEDIA_ERROR = 'GET_BUSINESS_SOCIAL_MEDIA_ERROR';
export const GET_BUSINESS_SOCIAL_MEDIA_SUCCESS = 'GET_BUSINESS_SOCIAL_MEDIA_SUCCESS';
export const GET_BUSINESS_USERS = 'GET_BUSINESS_USERS';
export const GET_BUSINESS_USERS_ERROR = 'GET_BUSINESS_USERS_ERROR';
export const GET_BUSINESS_USERS_SUCCESS = 'GET_BUSINESS_USERS_SUCCESS';
export const GET_BUSINESS_VIDEO_DETAILS = 'GET_BUSINESS_VIDEO_DETAILS';
export const GET_BUSINESS_VIDEO_DETAILS_ERROR = 'GET_BUSINESS_VIDEO_DETAILS_ERROR';
export const GET_BUSINESS_VIDEO_DETAILS_SUCCESS = 'GET_BUSINESS_VIDEO_DETAILS_SUCCESS';
export const GET_BUSINESS_VIDEOS = 'GET_BUSINESS_VIDEOS';
export const GET_BUSINESS_VIDEOS_ERROR = 'GET_BUSINESS_VIDEOS_ERROR';
export const GET_BUSINESS_VIDEOS_SUCCESS = 'GET_BUSINESS_VIDEOS_SUCCESS';
export const GET_BUSINESSES = 'GET_BUSINESSES';
export const GET_BUSINESSES_ERROR = 'GET_BUSINESSES_ERROR';
export const GET_BUSINESSES_SUCCESS = 'GET_BUSINESSES_SUCCESS';
export const GET_BUSINESS_NOMINATED_VIDEOS = 'GET_BUSINESS_NOMINATED_VIDEOS';
export const GET_BUSINESS_NOMINATED_VIDEOS_ERROR = 'GET_BUSINESS_NOMINATED_VIDEOS_ERROR';
export const GET_BUSINESS_NOMINATED_VIDEOS_SUCCESS = 'GET_BUSINESS_NOMINATED_VIDEOS_SUCCESS';
export const GET_NOMINATED_VIDEOS = 'GET_NOMINATED_VIDEOS';
export const GET_NOMINATED_VIDEOS_ERROR = 'GET_NOMINATED_VIDEOS_ERROR';
export const GET_NOMINATED_VIDEOS_SUCCESS = 'GET_NOMINATED_VIDEOS_SUCCESS';
export const GET_BILLING_HISTORY = 'GET_BILLING_HISTORY';
export const GET_BILLING_HISTORY_SUCCESS = 'GET_BILLING_HISTORY_SUCCESS';
export const GET_BILLING_HISTORY_ERROR = 'GET_BILLING_HISTORY_ERROR';
export const SEARCH_BUSINESS_VIDEOS = 'SEARCH_BUSINESS_VIDEOS';
export const UPDATE_NOMINATED_VIDEOS = 'UPDATE_NOMINATED_VIDEOS';
export const VERIFY_BUSINESS_PHONE_NUMBER_ERROR = 'VERIFY_BUSINESS_PHONE_NUMBER_ERROR';
export const VERIFY_BUSINESS_PHONE_NUMBER_SUCCESS = 'VERIFY_BUSINESS_PHONE_NUMBER_SUCCESS';
export const VERIFY_BUSINESS_SUCCESS = 'VERIFY_BUSINESS_SUCCESS';

const getQueryParams = (searchQueryParams) => {
  const query = new URLSearchParams(searchQueryParams);
  const params = new URLSearchParams();
  const termMapping = {
    PageSize: 'pageSize',
    pageNum: 'pageNumber',
  };

  ['PageSize', 'pageNum', 'orderBy'].forEach((term) => {
    const termValue = query.get(term);
    if (termValue) {
      const termKey = termMapping[term] || term;

      params.append(termKey, termValue);
    }
  });

  return params;
};

export const verifyBusinessManager = (businessId, status) =>
  req({
    uri: `Businesses/${businessId}/BusinessManager/VerifyByAdmin`,
    method: 'POST',
    data: { approved: status },
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const getBillingHistoryForGrid =
  (filter, businessId, pageSize = 5, nextPageToken = '') =>
    async (dispatch) => {
      try {
        dispatch({
          type: GET_BILLING_HISTORY,
        });
        const response = await APIRequest({
          uri: `BusinessPayment/Business/${businessId}/History?pageSize=${pageSize}&nextPageToken=${nextPageToken}`,
          method: 'GET',
        });
        dispatch({
          type: GET_BILLING_HISTORY_SUCCESS,
          payload: response.Data,
        });
      } catch (error) {
        dispatch({
          type: GET_BILLING_HISTORY_ERROR,
          payload: error,
        });
      }
    };

export function getBusinesses(
  queryString = '',
  config = {
    onlyThirdPartyBusiness: false,
  },
) {
  const params = getQueryParams(queryString);
  const showFourSquareData = isFoursquareData();
  return async (dispatch) => {
    const token = uniqueId();
    dispatch({ type: GET_BUSINESSES, token });
    await APIRequest({
      api: config.onlyThirdPartyBusiness ? REACT_APP_API_URL : REACT_APP_API_V2_URL,
      uri: config.onlyThirdPartyBusiness
        ? `${showFourSquareData ? 'Businesses/ThirdParty' : 'V2/Businesses/GooglePlace'
        }?${params.toString()}`
        : `Businesses${queryString}`,
      method: 'GET',
    })
      .then((result) =>
        dispatch({
          type: GET_BUSINESSES_SUCCESS,
          payload: config.onlyThirdPartyBusiness
            ? {
              CurrentPage: Number(config.pageNumber),
              Results: result.Data.Results,
              TotalItems: result.Data.TotalItems,
              TotalPages: result.Data.TotalPages,
            }
            : result.Data,
          token,
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_BUSINESSES_ERROR,
          payload: { error },
          token,
        }),
      );
  };
}

export function getBusinessesForBusinessManager() {
  return req({
    uri: 'Businesses/ManagedByUser',
    method: 'GET',
  });
}

export function getBusinessProfileApi(businessId) {
  return req({
    uri: `Businesses/V2/${businessId}`,
    method: 'GET',
    api: REACT_APP_API_V2_URL,
  });
}

export function getBusinessProfileNew(businessId) {
  return req({
    baseApiUri: '/Api',
    uri: `Businesses/${businessId}/Details?excludeOpenNow=true`,
    method: 'GET',
  });
}

export function getBusinessProfile(businessId) {
  return async (dispatch) => {
    dispatch({ type: GET_BUSINESS_PROFILE });
    await APIRequest({
      baseApiUri: isFoursquareData() ? '/Api/V2' : '/Api',
      uri: `Businesses/${businessId}/Details?excludeOpenNow=true`,
      method: 'GET',
    })
      .then((payload) =>
        dispatch({
          type: GET_BUSINESS_PROFILE_SUCCESS,
          payload,
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_BUSINESS_PROFILE_ERROR,
          payload: { error },
        }),
      );
  };
}

export function getBusinessHours(businessId) {
  return async (dispatch) => {
    dispatch({ type: GET_BUSINESS_HOURS });
    await APIRequest({
      uri: `Businesses/${businessId}/BusinessHours`,
      method: 'GET',
    })
      .then((payload) =>
        dispatch({
          type: GET_BUSINESS_HOURS_SUCCESS,
          payload,
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_BUSINESS_HOURS_ERROR,
          payload: { error },
        }),
      );
  };
}

export function getGetCorporateGroup(businessId) {
  return async (dispatch) => {
    dispatch({ type: GET_BUSINESS_PROFILE });
    await APIRequest({
      uri: `GroupManager/GetCorporateGroup?corporateGroupId=${businessId}`,
      method: 'GET',
    })
      .then((payload) =>
        dispatch({
          type: GET_BUSINESS_PROFILE_SUCCESS,
          payload,
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_BUSINESS_PROFILE_ERROR,
          payload: { error },
        }),
      );
  };
}

export function getBusinessSocialMedia(businessId) {
  return async (dispatch) => {
    dispatch({ type: GET_BUSINESS_SOCIAL_MEDIA });
    await APIRequest({
      uri: `Businesses/${businessId}/SocialMedia`,
      method: 'GET',
    })
      .then(({ Data }) =>
        dispatch({
          type: GET_BUSINESS_SOCIAL_MEDIA_SUCCESS,
          payload: { data: Data },
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_BUSINESS_SOCIAL_MEDIA_ERROR,
          payload: { error },
        }),
      );
  };
}

export function getBusinessSocialMediaNew(businessId) {
  return req({
    uri: `Businesses/${businessId}/SocialMedia`,
    method: 'GET',
  });
}

export function getBusinessDetails(businessId) {
  return async (dispatch) => {
    dispatch({ type: GET_BUSINESS_DETAILS });
    await APIRequest({
      uri: `GroupManager/GetCorporateGroup?corporateGroupId=${businessId}`,
      method: 'GET',
    })
      .then(({ Data }) =>
        dispatch({
          type: GET_BUSINESS_DETAILS_SUCCESS,
          payload: { data: Data },
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_BUSINESS_DETAILS_ERROR,
          payload: { error },
        }),
      );
  };
}

export function getBusinessVideos(
  businessId,
  queryString,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNum = DEFAULT_PAGE_INDEX,
) {
  return async (dispatch) => {
    dispatch({ type: GET_BUSINESS_VIDEOS });
    APIRequest({
      method: 'GET',
      uri: `Video/GetCorporateGroupVideos${queryString || '?'
        }&corporateGroupId=${businessId}&pageSize=${pageSize}&pageNum=${pageNum}`,
    })
      .then(({ Data }) =>
        dispatch({
          type: GET_BUSINESS_VIDEOS_SUCCESS,
          payload: {
            id: businessId,
            data: Data,
          },
        }),
      )
      .catch((error) => {
        dispatch({
          type: GET_BUSINESS_VIDEOS_ERROR,
          payload: error,
        });
      });
  };
}

export function getBusinessUsers(businessId, pageIndex, pageSize) {
  return async (dispatch) => {
    dispatch({ type: GET_BUSINESS_USERS });
    APIRequest({
      // method: 'GET',
      // uri: `Video/GetCorporateGroupUsers?corporateGroupId=${businessId}`,
      uri: 'UserAdmin/UserAdminGetUserList',
      method: 'POST',
      data: {
        PageNum: pageIndex,
        RowsPerPage: pageSize,
      },
    })
      .then(({ Data }) =>
        dispatch({
          type: GET_BUSINESS_USERS_SUCCESS,
          payload: {
            id: businessId,
            data: Data.UserList,
          },
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_BUSINESS_USERS_ERROR,
          payload: error,
        }),
      );
  };
}

export function getVideoDetails(videoKey) {
  return async (dispatch) => {
    dispatch({ type: GET_BUSINESS_VIDEO_DETAILS });
    await APIRequest({
      uri: `Video/${videoKey}`, // GetVideoDetailsV2?contentKey=
      method: 'GET',
    })
      .then((res) => {
        dispatch({
          type: GET_BUSINESS_VIDEO_DETAILS_SUCCESS,
          payload: {
            data: res.Data,
          },
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_BUSINESS_VIDEO_DETAILS_ERROR,
          payload: { error },
        }),
      );
  };
}

export function getBusinessGigs(businessId, search = '') {
  const searchQuery = search ? `&${search}` : '';
  return async (dispatch) => {
    dispatch({ type: GET_BUSINESS_GIGS });
    await APIRequest({
      uri: `GigManager/GetGigsByCorporateGroup?corporateGroupId=${businessId}${searchQuery}`,
      method: 'GET',
    })
      .then((res) => {
        dispatch({
          type: GET_BUSINESS_GIGS_SUCCESS,
          payload: {
            data: res.Data,
          },
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_BUSINESS_GIGS_ERROR,
          payload: { error },
        }),
      );
  };
}

export function getListOfNominatedVideos(gigId) {
  return async (dispatch) => {
    dispatch({ type: GET_NOMINATED_VIDEOS });
    await APIRequest({
      uri: `V2/NominatedVideos/GetListOfNominatedVideosBygigId?theGigId=${gigId}`,
      method: 'GET',
    })
      .then((payload) => {
        dispatch({
          type: GET_NOMINATED_VIDEOS_SUCCESS,
          payload,
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_NOMINATED_VIDEOS_ERROR,
          payload: { error },
        }),
      );
  };
}

export function searchBusinessVideos() {
  return async (dispatch) => {
    await APIRequest({
      uri: 'https://5cbf5ac906a6810014c66602.mockapi.io/api/businessesVideos',
      method: 'GET',
    }).then((res) =>
      dispatch({
        type: SEARCH_BUSINESS_VIDEOS,
        payload: res,
      }),
    );
  };
}

export async function assignBusinessVideoToPrizes(gigPrizeIds, contentKey) {
  const gigPrizeIdsQuery = String(gigPrizeIds)
    .split(',')
    .map((gigId) => `gigPrizeIds=${gigId}`)
    .join('&');

  const result = await APIRequest({
    uri: `GigManager/AssignGigPrize?${gigPrizeIdsQuery}&contentKey=${contentKey}`,
    method: 'POST',
  })
    .then((res) => res)
    .catch((err) => ({
      Message: err,
      Success: false,
    }));

  return result;
}

export function updateNominatedVideos(dispatch, nomineeId) {
  return dispatch({
    type: UPDATE_NOMINATED_VIDEOS,
    payload: nomineeId,
  });
}

export async function createBusinessProfile(data) {
  const result = await APIRequest({
    uri: isFoursquareData() ? 'Businesses' : 'GroupManager/Createcorporategroup',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => res)
    .catch((err) => ({
      Message: err,
      Success: false,
    }));

  return result;
}

export async function createBusinessProfileV2(data) {
  const result = await APIRequest({
    baseApiUri: '/AdminApi/V2',
    uri: 'Businesses',
    method: 'POST',
    api: REACT_APP_API_V2_URL,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => res)
    .catch((err) => ({
      Message: err,
      Success: false,
    }));

  return result;
}

export async function createVideoNomination(data) {
  const result = await APIRequest({
    uri: 'NominatedVideos/CreateVideoNomination',
    method: 'POST',
    data,
  })
    .then((res) => res)
    .catch((err) => ({
      Message: err,
      Success: false,
    }));

  return result;
}

export async function deleteNominatedVideo(data) {
  const result = await APIRequest({
    uri: 'NominatedVideos/DeleteNominatedVideo',
    method: 'DELETE',
    data,
  })
    .then((res) => res)
    .catch((err) => ({
      Message: err,
      Success: false,
    }));

  return result;
}

export async function updateBusiness(businessId, data) {
  const result = await APIRequest({
    headers: {
      'Content-Type': 'multipart/form-data; charset=utf-8',
    },
    uri: `${isFoursquareData() ? '' : 'GroupManager/'}Businesses/${businessId}`,
    method: 'PATCH',
    data,
  })
    .then((res) => res)
    .catch((err) => ({
      Message: err,
      Success: false,
    }));

  return result;
}

export async function updateBusinessV2(businessId, data) {
  const result = await APIRequest({
    headers: {
      'Content-Type': `multipart/form-data; charset=utf-8; boundary=${uuidv1()}`,
    },
    baseApiUri: '/AdminApi/V2',
    uri: `Businesses/${businessId}`,
    method: 'PATCH',
    api: REACT_APP_API_V2_URL,
    data,
  })
    .then((res) => res)
    .catch((err) => ({
      Message: err,
      Success: false,
    }));

  return result;
}

export const updateBusinessV2Observable = (businessId, data) =>
  req({
    uri: `Businesses/${businessId}`,
    method: 'PATCH',
    data,
    api: REACT_APP_API_V2_URL,
    headers: {
      'Content-Type': 'multipart/form-data; charset=utf-8',
    },
  });

export function getNominatedVideos(videoId) {
  return async (dispatch) => {
    dispatch({ type: GET_BUSINESS_NOMINATED_VIDEOS });
    await APIRequest({
      uri: `NominatedVideos/GetNominatedVideosByVideoId?theVideoId=${videoId}`,
      method: 'GET',
    })
      .then((res) => {
        dispatch({
          type: GET_BUSINESS_NOMINATED_VIDEOS_SUCCESS,
          payload: res.Data,
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_BUSINESS_NOMINATED_VIDEOS_ERROR,
          payload: { error },
        }),
      );
  };
}

export function getBusinessSummary(GooglePlaceId) {
  return req({
    baseApiUri: '/Api',
    uri: `Businesses/GooglePlaceId/${GooglePlaceId}/Summary`,
    method: 'GET',
  });
}

export function getBusinessSummaryFranki(businessId) {
  return req({
    baseApiUri: '/Api',
    uri: `Businesses/${businessId}/Summary`,
    method: 'GET',
  });
}

export function getBusinessSummaryFourSquare(ThirdPartyBusinessSourceId) {
  return req({
    baseApiUri: '/Api',
    uri: `Businesses/ThirdPartyBusinessSourceId/${ThirdPartyBusinessSourceId}/Summary`,
    method: 'GET',
  });
}

// GET_BUSINESS_CLAIM_DETAILS

export function getBusinessClaimDetails(BusinessId) {
  return req({
    uri: `Businesses/${BusinessId}/ClaimDetails`,
    method: 'GET',
  });
}

export function assignUserAsBusinessManager(data, nonFourSquare) {
  return req({
    baseApiUri: nonFourSquare ? '/AdminApi/V3/' : '/AdminApi/V2',
    uri: 'GroupManager/AssignUserAsBusinessManager',
    method: 'POST',
    data,
  });
}

export function initiateVerificationCall(businessId) {
  return req({
    uri: `Businesses/${businessId}/BusinessManager/InitiateVerificationCall`,
    method: 'POST',
  });
}

export function verify(businessId, verificationCode) {
  return req({
    uri: `Businesses/${businessId}/BusinessManager/Verify`,
    method: 'POST',
    data: { verificationCode },
  });
}

export function verificationPhoneNumber(businessId) {
  return req({
    uri: `Businesses/${businessId}/BusinessManager/VerificationPhoneNumber`,
    method: 'GET',
  });
}

export function getBusinessManagerVerificationStatus(businessId) {
  const frankiRole = localStorage.getItem('frankiRole');
  if (![USER_ROLES.businessManager].includes(frankiRole)) {
    return of(null);
  }

  return new Observable((subscriber) =>
    req({
      uri: `Businesses/${businessId}/BusinessManager/Details`,
      method: 'GET',
    }).subscribe((data) => {
      subscriber.next(data && data.VerificationStatus);
    }),
  );
}

/* global localStorage */
export function verifyPermission(businessId, history, withNavigation) {
  if (localStorage.getItem('frankiRole') !== USER_ROLES.businessManager) {
    return of(true);
  }

  return req({
    uri: `Businesses/${businessId}/BusinessManager/Details`,
    method: 'GET',
  }).pipe(
    map((data) => data.VerificationStatus === 'Verified'),
    tap((verified) => {
      if (!verified && withNavigation) {
        history.push('/admin');
      }
    }),
  );
}

export function contact(data) {
  return async (dispatch) => {
    dispatch({ type: CONTACT });
    await APIRequest({
      uri: 'Businesses/ContactForm',
      method: 'POST',
      data,
    })
      .then(async (res) =>
        dispatch({
          type: CONTACT_SUCCESS,
          payload: res.Message,
        }),
      )
      .catch((err) =>
        dispatch({
          type: CONTACT_ERROR,
          payload: err,
        }),
      );
  };
}

export function businessSearch(searchTerm, cityName = '', isAll) {
  let url = isAll
    ? `Businesses/Search?SearchTerm=${searchTerm}`
    : `V2/Businesses/Search?SearchTerm=${searchTerm}`;
  url += `&City=${encodeURIComponent(cityName)}`;

  return req(
    {
      baseApiUri: isAll ? '/AdminApi' : '/Api',
      uri: url,
      method: 'GET',
    },
    { dataProp: 'self' },
  );
}

export function verifyByIdentification(businessId, data) {
  return req({
    uri: `Businesses/${businessId}/BusinessManager/VerifyByIdentification`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function createClaimBusinessProfile(data) {
  return req({
    uri: 'BusinessClaim/Businesses',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function createClaimBusinessProfileMock(data) {
  const result = await APIRequest({
    uri: 'Businesses/CreateProfile',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    isMock: true,
  })
    .then((res) => res)
    .catch((err) => ({
      Message: err,
      Success: false,
    }));

  return result;
}

export const getBusinessOptions = () =>
  req({
    uri: 'Businesses/Options',
    method: 'GET',
    api: REACT_APP_API_V2_URL,
  });

export const getBusinessCategories = (BusinessTypeId) =>
  req({
    uri: `Businesses/Categories${BusinessTypeId !== null ? `?BusinessTypeId=${BusinessTypeId}` : ''
      }`,
    method: 'GET',
    api: REACT_APP_API_V2_URL,
  });
