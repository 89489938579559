import './index.sass';

import { func, instanceOf, node, string } from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

class ClickableImage extends React.PureComponent {
  render() {
    const { alt, click, content, pathname, src, state } = this.props;
    return (
      <>
        {pathname && (
          <Link to={{ pathname, state }}>
            <img src={src} alt={alt} />
            {content}
          </Link>
        )}

        {click && (
          <button className="ClickableImage__Button" onClick={click} type="button">
            <img alt={alt} src={src} />
            {content}
          </button>
        )}

        {!click && !pathname && (
          <>
            <img alt={alt} src={src} />
            {content}
          </>
        )}
      </>
    );
  }
}

ClickableImage.propTypes = {
  alt: string,
  click: func,
  content: node,
  pathname: string,
  src: string.isRequired,
  state: instanceOf(Object),
};

ClickableImage.defaultProps = {
  alt: '',
  click: null,
  content: null,
  pathname: null,
  state: {},
};

export default withRouter(ClickableImage);
