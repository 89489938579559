/* eslint-disable react/no-danger */
import './index.sass';

import { bool, func, node, number, oneOf, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const EmptyPage = (props) => {
  const {
    border,
    buttonCallback,
    buttonText,
    buttonURI,
    ctaContent,
    description,
    img,
    imgWidth,
    size,
    title,
  } = props;

  return (
    <div
      className={`EmptyPage EmptyPage--Size--${size} ${border && 'EmptyPage--Bordered'} ${
        ctaContent && 'EmptyPage--CTA'
      }`}
    >
      {img && (
        <figure
          className={`EmptyPage__Image ${img && 'EmptyPage__Image--Filled'}`}
          style={{ width: imgWidth }}
        >
          <img src={img} alt={title} />
        </figure>
      )}

      {title && <h1 className="EmptyPage__Title">{title}</h1>}

      {description && (
        <p className="EmptyPage__Description" dangerouslySetInnerHTML={{ __html: description }} />
      )}

      {ctaContent && (
        <>
          <div className="EmptyPage__CTACover" />
          <div className="EmptyPage__CTAContentContainer">
            <div className="EmptyPage__CTAContentArrow" />
            <div className="EmptyPage__CTAContent">{ctaContent}</div>
          </div>
        </>
      )}

      {buttonText &&
        (buttonCallback ? (
          <button
            className="button small EmptyPage__Button yellow-theme"
            onClick={buttonCallback}
            type="button"
          >
            {buttonText}
          </button>
        ) : (
          <Link to={buttonURI} className="button small EmptyPage__Button yellow-theme">
            {buttonText}
          </Link>
        ))}
    </div>
  );
};

EmptyPage.propTypes = {
  border: bool,
  buttonCallback: func,
  buttonText: string,
  buttonURI: string,
  ctaContent: node,
  description: string,
  img: string,
  imgWidth: number,
  size: oneOf(['Small', 'Normal', 'Big']),
  title: string,
};

EmptyPage.defaultProps = {
  border: false,
  buttonCallback: null,
  buttonText: '',
  buttonURI: '/',
  ctaContent: null,
  description: '',
  img: '',
  imgWidth: 240,
  size: 'Normal',
  title: '',
};

export default EmptyPage;
