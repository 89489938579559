import './index.sass';

import { isEmpty } from 'lodash';
import { arrayOf, bool, func, instanceOf, number, oneOfType, shape, string } from 'prop-types';
import React from 'react';

import AutoComplete from '../Autocomplete';

class TagsInput extends React.Component {
  TagsInput = React.createRef();

  state = {
    existTags: [],
    newTag: '',
  };

  componentDidMount() {
    const { value } = this.props;

    if (value.length) {
      this.updateTags(value);
    }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (prevProps.value !== value) {
      this.updateTags(value);
    }
  }

  onHandleChange(e) {
    const newValue = e.target ? e.target.value : e;
    this.setState({ newTag: newValue });
    const { onSearchTermUpdate } = this.props;
    if (onSearchTermUpdate) {
      onSearchTermUpdate(newValue);
    }
  }

  onHandleDeleteTag(value) {
    const { existTags } = this.state;
    const { name, onChange } = this.props;
    const newTagList = existTags.filter((item) => item.value !== value);
    onChange(name, newTagList);
    this.setState({ existTags: newTagList });
  }

  updateTags(value) {
    this.setState({ existTags: value });
  }

  addTag(tag) {
    const { onChange, name } = this.props;
    const { existTags } = this.state;
    const newTagList = existTags.concat([tag]);
    onChange(name, newTagList);

    this.setState({ existTags: newTagList, newTag: '' });
  }

  render() {
    const { itemListAsync, disabled, fetching, limit, listOfTags, placeholder } = this.props;

    const { newTag, existTags } = this.state;

    return (
      <div
        className={`TagsInput ${disabled ? 'TagsInput--Disabled' : ''}`}
        ref={(el) => {
          this.TagsInput = el;
        }}
      >
        <ul className="TagsInput__List">
          {!isEmpty(existTags) &&
            existTags.map((item) => (
              <li key={item.value} className="tags-add">
                {item.label}
                <i
                  role="button"
                  tabIndex="0"
                  className="icon-close"
                  title="delete item"
                  onClick={() => this.onHandleDeleteTag(item.value)}
                  onKeyPress={() => this.onHandleDeleteTag(item.value)}
                />
              </li>
            ))}

          {existTags.length < limit && (
            <AutoComplete
              disabled={disabled}
              fetching={fetching}
              itemListAsync={itemListAsync}
              listOfItems={listOfTags.filter(
                ({ value }) => !existTags.find((tag) => tag.value === value),
              )}
              onChange={(e) => this.onHandleChange(e)}
              onHandleItemSelect={(tag) => this.addTag(tag)}
              placeholder={placeholder}
              value={newTag}
            />
          )}
          <li />
        </ul>
      </div>
    );
  }
}

TagsInput.propTypes = {
  itemListAsync: bool,
  disabled: bool,
  fetching: bool,
  limit: number,
  listOfTags: arrayOf(
    shape({
      label: string,
      value: oneOfType([string, number]),
    }),
  ),
  name: string.isRequired,
  onChange: func.isRequired,
  onSearchTermUpdate: instanceOf(Function),
  placeholder: string,
  value: arrayOf(
    shape({
      label: string,
      value: oneOfType([string, number]),
    }),
  ).isRequired,
};

TagsInput.defaultProps = {
  itemListAsync: false,
  disabled: false,
  fetching: false,
  limit: 999999,
  listOfTags: [],
  onSearchTermUpdate: null,
  placeholder: null,
};

export default TagsInput;
