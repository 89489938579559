import React from 'react';
import { Route, Switch } from 'react-router-dom';
import uuidv1 from 'uuid/v1';

import NonPrivateRoute from '../components/NonPrivateRoute';
import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';
import USER_ROLES from '../config/user-roles';
import LoggedNotFoundPageBusinessSales from '../containers/404/LoggedNotFoundBusinessSales';
import LoggedNotFoundPageSuperAdmin from '../containers/404/LoggedNotFoundSuperAdmin';
import UnloggedNotFound from '../containers/404/UnloggedNotFound';
import useClearCache from '../hooks/useClearCache';
import useGATracker from '../hooks/useGATracker';
import myRoutes from './routes';

/* eslint-disable no-undef */
const userRole = localStorage.getItem('frankiRole');
const userToken = localStorage.getItem('frankiToken');

const Routers = () => {
  useClearCache();
  useGATracker();
  return (
    <Switch>
      {myRoutes
        .filter((route) => route.active)
        .map((item) => {
          if (item.private) {
            return (
              <PrivateRoute
                key={uuidv1()}
                exact
                path={item.path}
                component={item.component}
                permission={item.permission}
                routerProps={item.routerProps}
              />
            );
          }

          if (item.public) {
            return (
              <PublicRoute
                key={uuidv1()}
                exact
                path={item.path}
                component={item.component}
                permission={item.permission}
                routerProps={item.routerProps}
              />
            );
          }

          return (
            <NonPrivateRoute
              key={uuidv1()}
              exact
              path={item.path}
              component={item.component}
              permission={item.permission}
              routerProps={item.routerProps}
            />
          );
        })}

      {userToken &&
        ([USER_ROLES.admin, USER_ROLES.saleAdmin].indexOf(userRole) > -1 ? (
          <Route component={() => <LoggedNotFoundPageSuperAdmin auth />} />
        ) : (
          <Route component={() => <LoggedNotFoundPageBusinessSales auth />} />
        ))}

      {!userToken && <Route component={() => <UnloggedNotFound auth={false} />} />}
    </Switch>
  );
};

export default Routers;
