/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import videojs from 'video.js';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'video.js/dist/video-js.css';
import PropTypes from 'prop-types';

const VideoJs = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  React.useEffect(() => {
    if (!playerRef.current) {
      // eslint-disable-next-line no-undef
      const videoElement = document.createElement('video-js');
      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      // eslint-disable-next-line no-multi-assign
      const player = (playerRef.current = videojs(videoElement, options, () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        if (onReady) {
          onReady(player);
        }
      }));
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div className="h-full w-full" data-vjs-player>
      <div className="h-full w-full" ref={videoRef} />
    </div>
  );
};

export default VideoJs;

VideoJs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  onReady: PropTypes.func.isRequired,
};
