import './index.sass';

import { isEmpty } from 'lodash';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import React, { Component } from 'react';
import uuidv1 from 'uuid/v1';

import { Col, Row } from '../Grid';
import PlanBoxes from './PlanBoxes';
import PLAN_TYPE from './planType';

class Plans extends Component {
  state = { activeIndex: 0 };

  componentDidMount() {
    const { activeIndex } = this.props;
    this.setState({ activeIndex });
  }

  activate(title, priceWithDiscount, planIndex) {
    const { onActivate } = this.props;
    this.setState({ activeIndex: planIndex });
    onActivate(title, priceWithDiscount);
  }

  inactivate(planIndex) {
    const { activeIndex } = this.state;
    const { activeIndex: defaultActiveIndex, onInactivate } = this.props;
    if (planIndex === activeIndex && planIndex !== defaultActiveIndex) {
      this.setState({ activeIndex: defaultActiveIndex });
      onInactivate(planIndex);
    }
  }

  render() {
    const { activeIndex } = this.state;
    const { features, isLarge, onClick, plans } = this.props;

    return (
      !isEmpty(plans) && (
        <div className={`Plans ${isLarge ? 'Plans--Large' : ''}`}>
          <Row className="Plans__Container">
            <Col lg={3} md={3} sm={6} xs={12}>
              <ul className="Plans__Features">
                {features.map(({ title, substitles }) => (
                  <li key={uuidv1()}>
                    <span className="Plans__Features__Title">{title}</span>

                    {substitles && <span className="Plans__Features__Subtitles">{substitles}</span>}
                  </li>
                ))}
              </ul>
            </Col>

            {plans.map(
              ({ items, period, price, priceDetails, priceWithDiscount, title }, planIndex) => (
                <Col lg={3} md={3} sm={6} xs={12} key={uuidv1()}>
                  <PlanBoxes
                    active={activeIndex === planIndex}
                    items={items}
                    onClick={onClick}
                    onInactivate={() => this.inactivate(planIndex)}
                    onActivate={() => this.activate(title, priceWithDiscount, planIndex)}
                    period={period}
                    price={price}
                    priceDetails={priceDetails}
                    priceWithDiscount={priceWithDiscount}
                    title={title}
                  />
                </Col>
              ),
            )}
          </Row>

          <p className="Plans__Terms">
            Franki currently services selected areas in the United States. By clicking 'Confirm' you
            are agreeing to franki&rsquo;s&nbsp;
            <a href="https://befranki.com/terms-of-service/" target="_blank">
              Terms of Service
            </a>
            .
          </p>
        </div>
      )
    );
  }
}

Plans.propTypes = {
  activeIndex: number.isRequired,
  features: arrayOf(
    shape({
      substitles: string,
      title: string,
    }),
  ).isRequired,
  isLarge: bool.isRequired,
  onActivate: func,
  onClick: func,
  onInactivate: func,
  plans: arrayOf(PLAN_TYPE).isRequired,
};

Plans.defaultProps = {
  onActivate: () => {},
  onClick: () => {},
  onInactivate: () => {},
};

export default Plans;
