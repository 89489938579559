import { number, shape, string } from 'prop-types';

import MEDIA_TYPE from './media';

const POSTER_PROFILE = shape({
  DisplayName: string,
  ProfilePhoto: MEDIA_TYPE,
  UserId: number,
});

export default POSTER_PROFILE;
