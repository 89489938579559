import './index.sass';
import ReactTooltip from 'react-tooltip';

import { isEmpty } from 'lodash';
import { bool, node, number, object, oneOfType, string } from 'prop-types';
import React, { useState } from 'react';
import Helmet from 'react-helmet';

import NoBusinessesIMG from '../../../assets/images/img_nobusinesses.jpg';
import AsyncContent from '../../../components/AsyncContent';
import Breadcrumb from '../../../components/Breadcrumb';
import EmptyPage from '../../../components/EmptyPage';
import MasterPage from '../../../components/MasterPage';
import USER_ROLES from '../../../config/user-roles';
import { BUSINESS_PROFILE_TYPE } from '../../../types/business';
import BusinessInfoAbout from './BusinessInfoAbout';
import BusinessInfoAppPreview from './BusinessInfoAppPreview';
import BusinessInfoContact from './BusinessInfoContact';
import BusinessInfoDeactivate from './BusinessInfoDeactivate';
import Amenities from './BusinessInfoContact/Amenities';
import HISTORY_TYPE from '../../../types/history';

const BusinessInfo = (props) => {
  const {
    auth,
    businessProfile,
    businessProfileError,
    corporateGroupId,
    isFetchingBusinessProfile,
    membership,
    children,
    showDetail,
    userRole,
    verificationStatus,
    businessOptionLabel,
    history,
  } = props;

  const isEditDisabled = [USER_ROLES.businessManager].includes(userRole) && verificationStatus && verificationStatus !== 'Verified';

  // TODO: No state change is observed on the next two?
  const [isAppPreviewModalShown, setIsAppPreviewModalShown] = useState(false);
  const [isDeactivateModalShown, setIsDeactivateModalShown] = useState(false);

  return (
    <>
      <Helmet title={`Franki Admin: ${businessProfile.DisplayName || 'business profile'}`} />

      <MasterPage
        auth={auth}
        businessMobileTitle="Profile"
        businessSidebarData={businessProfile}
        businessSidebarDataError={businessProfileError}
        businessSidebarOn={!isEmpty(businessProfile)}
        membership={membership}
      >
        {userRole !== USER_ROLES.businessManager && (
        <Breadcrumb
          items={[
            { url: '/', name: 'Home' },
            { url: '/', name: 'All Businesses' },
            { name: businessProfile.Name },
          ]}
        />
        )}
        {userRole === USER_ROLES.businessManager && (
        <>
          <br />
          <br />
        </>
        )}

        <div className="BusinessInfo">
          <AsyncContent
            content={details => (
              <div className="contentPage">
                <div className="BusinessInfo__Container">
                  <div className="BusinessInfo__Content">
                    <BusinessInfoAbout
                      showVerificationStatus={[USER_ROLES.businessManager, USER_ROLES.admin].includes(
                        userRole,
                      )}
                      businessProfile={details}
                      showDetail={showDetail}
                      verificationStatus={verificationStatus}
                    >
                      <div
                        className="BusinessInfo__Actions"
                      >
                        {/* {
                            [USER_ROLES.admin, USER_ROLES.saleAdmin].includes(userRole)
                              ? (
                                <button
                                  className="button red-theme small BusinessInfo__Button"
                                  onClick={() => this.setState({
                                    isDeactivateModalShown: true,
                                  })}
                                  type="button"
                                >
                                  DEACTIVATE
                                </button>
                              ) : null
                          } */}

                        {/* <Link
                          className="button yellow-theme small BusinessInfo__Button"
                          to={`/admin/business-edit/${corporateGroupId}`}
                        >
                          EDIT
                        </Link> */}
                        <div data-tip="You need to verify your business to edit your profile">
                          <button
                            disabled={isEditDisabled}
                            type="button"
                            className="button yellow-theme small BusinessInfo__Button"
                            onClick={() => {
                              history.push(`/admin/business-edit/${corporateGroupId}`);
                            }}
                          >
                            EDIT

                          </button>
                        </div>
                        {isEditDisabled && <ReactTooltip />}
                      </div>
                    </BusinessInfoAbout>

                    {showDetail ? (
                      <>
                        <BusinessInfoContact
                          businessProfile={details}
                          businessOptionLabel={businessOptionLabel}
                        />

                        <BusinessInfoAppPreview
                          onChange={() => setIsAppPreviewModalShown(false)}
                          show={isAppPreviewModalShown}
                        />

                        <BusinessInfoDeactivate
                          onChange={() => setIsDeactivateModalShown(false)}
                          show={isDeactivateModalShown}
                        />
                      </>
                    ) : <></>}

                    {children}
                  </div>
                  {(showDetail && businessProfile && businessProfile.BusinessOptions && businessOptionLabel) ? (

                    <div className="BusinessInfoContact__Info">
                      <Amenities
                        options={businessProfile.BusinessOptions}
                        businessOptionLabel={businessOptionLabel}
                      />
                    </div>


                  ) : <></>}
                  {/* removed as per https://franki.atlassian.net/browse/FRAN-8803 */}
                  {/* <BusinessInfoFeature /> */}
                </div>
              </div>
            )}
            emptyPageContent={(
              <EmptyPage
                buttonText="create business"
                buttonURI="/admin/register-business"
                description="let’s add your first business now"
                img={NoBusinessesIMG}
                title="looks like you have no businesses"
              />
              )}
            errorMessage={businessProfileError}
            expectedData={businessProfile}
            loading={isFetchingBusinessProfile}
          />
        </div>
      </MasterPage>
    </>
  );
};

BusinessInfo.propTypes = {
  auth: bool.isRequired,
  corporateGroupId: string.isRequired,
  membership: number,
  children: node,
  userRole: string.isRequired,
  showDetail: bool,
  verificationStatus: string,
  isFetchingBusinessProfile: bool.isRequired,
  businessProfile: BUSINESS_PROFILE_TYPE.isRequired,
  businessProfileError: string,
  businessOptionLabel: oneOfType([object]),
  history: HISTORY_TYPE.isRequired,
};

BusinessInfo.defaultProps = {
  membership: null,
  children: {},
  showDetail: true,
  businessProfileError: null,
  verificationStatus: null,
  businessOptionLabel: null,
};


export default BusinessInfo;
