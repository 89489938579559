import './index.sass';

import { arrayOf, func, node, number, shape, string } from 'prop-types';
import React from 'react';
import uuidv1 from 'uuid/v1';

import toggleOffSvg from '../../../../../assets/images/toggle-off.svg';
import toggleOnSvg from '../../../../../assets/images/toggle-on.svg';
import checkSvg from './check.svg';
import disabledCheckSvg from './disabledCheck2.svg';
import emptySvg from './empty.svg';

const FREE = 'Free';
const UNAVAILABLE = 'Unavailable';
const SELECTED = 'Selected';

export const DEFAULT_OPENING_TIME = '08:00';
export const DEFAULT_CLOSING_TIME = '22:00';
const HOUR_LIST = [];
for (let i = 0; i < 24; i += 1) {
  for (let j = 0; j < 4; j += 1) {
    HOUR_LIST.push([i < 10 ? `0${i}` : i, j < 1 ? '00' : j * 15].join(':'));
  }
}

const isPeriodMatched = (period, dowIndex) => period.DOWs.includes(dowIndex);

const timeToMinute = (time) => {
  if (!time) {
    return 0;
  }
  const timeArr = time.split(':').map(Number);
  return timeArr[0] * 60 + timeArr[1];
};

const minuteToTime = minute => `${Math.floor(minute / 60)}:${minute % 60 < 10 ? '0' : ''}${minute % 60}`;

const timeOf = (timeStr) => {
  if (!timeStr) {
    return '';
  }

  const [baseHour, min] = timeStr.split(':').map(Number);
  let apm = '';
  let hourStr = `${baseHour}`;
  let minStr = `${min}`;
  let hour = baseHour;
  if (baseHour >= 12) {
    apm = ' pm';
    if (hour > 12) {
      hour -= 12;
    }
  } else {
    apm = ' am';
    if (hour === 0) {
      hour = 12;
    }
  }

  if (hour < 10) {
    hourStr = `0${hour}`;
  } else {
    hourStr = `${hour}`;
  }

  if (min < 10) {
    minStr = `0${min}`;
  } else {
    minStr = `${min}`;
  }

  return `${hourStr}:${minStr}${apm}`;
};

class BusinessHoursGroup extends React.Component {
  onHandleDOWClick(dowIndex) {
    const { periods, currentIndex, onHandleChange } = this.props;
    const state = this.getState(dowIndex);

    if (state === FREE) {
      periods[currentIndex].DOWs.push(dowIndex);
    } else if (state === SELECTED) {
      periods[currentIndex].DOWs = periods[currentIndex].DOWs.filter(dow => dow !== dowIndex);
    }

    onHandleChange(periods);
  }

  onSplit() {
    const { periods, currentIndex, onHandleChange } = this.props;
    if (periods[currentIndex].OpeningTime2) {
      delete periods[currentIndex].OpeningTime2;
      delete periods[currentIndex].ClosingTime2;
    } else {
      periods[currentIndex].OpeningTime2 = minuteToTime(
        timeToMinute(periods[currentIndex].ClosingTime) + 15,
      );
      periods[currentIndex].ClosingTime2 = timeToMinute(periods[currentIndex].OpeningTime2) < timeToMinute(DEFAULT_CLOSING_TIME) - 15
        ? DEFAULT_CLOSING_TIME
        : '23:45';
    }
    onHandleChange(periods);
  }

  getState(dowIndex) {
    const { periods, currentIndex } = this.props;

    return periods.reduce((sum, period, periodIndex) => {
      if (isPeriodMatched(period, dowIndex) || (period.DOWs[0] === 0 && dowIndex === 7)) {
        if (periodIndex === currentIndex) {
          return SELECTED;
        }

        if (sum !== SELECTED) {
          return UNAVAILABLE;
        }
      }
      return sum;
    }, FREE);
  }

  toggle24Hours() {
    const { periods, currentIndex, onHandleChange } = this.props;

    if (!this.is24Hours()) {
      periods[currentIndex].OpeningTime = '00:00';
      periods[currentIndex].ClosingTime = '23:59';
      delete periods[currentIndex].OpeningTime2;
      delete periods[currentIndex].ClosingTime2;
    } else {
      periods[currentIndex].OpeningTime = DEFAULT_OPENING_TIME;
      periods[currentIndex].ClosingTime = DEFAULT_CLOSING_TIME;
    }
    onHandleChange(periods);
  }

  updateTime(key, value) {
    const { periods, currentIndex, onHandleChange } = this.props;

    periods[currentIndex][key] = value;
    onHandleChange(periods);
  }

  is24Hours() {
    const { periods, currentIndex } = this.props;
    return (
      periods[currentIndex].OpeningTime === '00:00'
      && timeToMinute(periods[currentIndex].ClosingTime) === timeToMinute('23:59')
    );
  }

  isSplitHourDisable() {
    const { periods, currentIndex } = this.props;
    return (
      this.is24Hours() || timeToMinute(periods[currentIndex].ClosingTime) >= timeToMinute('23:30')
    );
  }

  isSelected(groupIndex, option) {
    const { periods, currentIndex } = this.props;
    switch (groupIndex) {
      case 2:
        return timeToMinute(periods[currentIndex].ClosingTime) >= timeToMinute(option);
      default:
        if (periods[currentIndex].OpeningTime2) {
          return timeToMinute(periods[currentIndex].OpeningTime2) <= timeToMinute(option);
        }
    }

    return false;
  }

  isDisabled(groupIndex, option, isOpeningTime) {
    const { periods, currentIndex } = this.props;
    let openingTime;
    let closingTime;
    switch (groupIndex) {
      case 2:
        openingTime = periods[currentIndex].OpeningTime2;
        closingTime = periods[currentIndex].ClosingTime2;
        break;
      default:
        openingTime = periods[currentIndex].OpeningTime;
        closingTime = periods[currentIndex].ClosingTime;
        break;
    }
    return isOpeningTime
      ? (timeToMinute(closingTime) || timeToMinute('23:45')) <= timeToMinute(option)
      : timeToMinute(openingTime) >= timeToMinute(option);
  }

  render() {
    const { footer, value } = this.props;

    return (
      <div className="BusinessHoursGroup">
        <div className="BusinessHoursGroup__Header">
          {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((dow, dowIndex) => {
            const state = this.getState(dowIndex + 1);
            let iconSrc;

            switch (state) {
              case UNAVAILABLE:
                iconSrc = disabledCheckSvg;
                break;

              case SELECTED:
                iconSrc = checkSvg;
                break;

              default:
                iconSrc = emptySvg;
                break;
            }

            return (
              <button
                className={`BusinessHoursGroup__Header__Dow BusinessHoursGroup__Header__Dow--${state}`}
                onClick={() => this.onHandleDOWClick(dowIndex + 1)}
                type="button"
                key={dow}
              >
                <img src={iconSrc} alt="dow" />
                <div
                  className={`
                    BusinessHoursGroup__Header__Dow__Name
                    ${`BusinessHoursGroup__Header__Dow__Name--${state}`}
                  `}
                >
                  {dow}
                </div>
              </button>
            );
          })}
        </div>

        <div className="BusinessHoursGroup__Hours">
          <div className="BusinessHoursGroup__Hours__Table">
            <div className="BusinessHoursGroup__Hours__Row">
              <div className="BusinessHoursGroup__Hours__Row__Column">
                <div className="BusinessHoursGroup__Hours__Row__Column__Title">24 hours</div>
                <button
                  className="link-button BusinessHoursGroup__Hours__Row__Column__Switch"
                  type="button"
                  onClick={() => this.toggle24Hours()}
                >
                  <img src={this.is24Hours() ? toggleOnSvg : toggleOffSvg} alt="24 hours" />
                </button>
              </div>

              <div className="BusinessHoursGroup__Hours__Row__Column">
                <div className="BusinessHoursGroup__Hours__Row__Column__Title">Opens</div>
                <div className="BusinessHoursGroup__Hours__Row__Column__Time">
                  {!this.is24Hours() && (
                    <select
                      className="full"
                      name="OpeningTime"
                      onChange={({ target }) => this.updateTime('OpeningTime', target.value)}
                      value={value.OpeningTime}
                    >
                      {HOUR_LIST.map(time => (
                        <option
                          key={uuidv1()}
                          value={time}
                          disabled={this.isSelected(1, time) || this.isDisabled(1, time, true)}
                        >
                          {timeOf(time)}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>

              <div className="BusinessHoursGroup__Hours__Row__Column">
                <div className="BusinessHoursGroup__Hours__Row__Column__Title">Closes</div>
                <div className="BusinessHoursGroup__Hours__Row__Column__Time">
                  {!this.is24Hours() && (
                    <select
                      className={`full ${
                        this.is24Hours()
                          ? 'BusinessHoursGroup__Hours__Row__Column__Time__Select--Show24'
                          : ''
                      }`}
                      name="ClosingTime"
                      onChange={({ target }) => this.updateTime('ClosingTime', target.value)}
                      value={value.ClosingTime}
                    >
                      {HOUR_LIST.map(time => (
                        <option
                          key={uuidv1()}
                          value={time}
                          disabled={this.isSelected(1, time) || this.isDisabled(1, time, false)}
                        >
                          {timeOf(time)}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
            </div>

            <div className="BusinessHoursGroup__Hours__Row">
              <div className="BusinessHoursGroup__Hours__Row__Column">
                <button
                  disabled={this.isSplitHourDisable()}
                  className="link-button"
                  type="button"
                  onClick={() => this.onSplit()}
                >
                  <div className="BusinessHoursGroup__Hours__Row__Column__Title">Split hours</div>
                  {value.OpeningTime2 ? (
                    <div className="BusinessHoursGroup__Hours__Row__Column__Minus">-</div>
                  ) : (
                    <div
                      className={`BusinessHoursGroup__Hours__Row__Column__Plus ${
                        this.isSplitHourDisable()
                          ? 'BusinessHoursGroup__Hours__Row__Column__Plus--Disabled'
                          : null
                      }`}
                    >
                      +
                    </div>
                  )}
                </button>
              </div>

              <div className="BusinessHoursGroup__Hours__Row__Column">
                <div className="BusinessHoursGroup__Hours__Row__Column__Time BusinessHoursGroup__Hours__Row__Column__Time--Splitted">
                  {value.OpeningTime2 && (
                    <select
                      className={`full ${
                        this.is24Hours()
                          ? 'BusinessHoursGroup__Hours__Row__Column__Time__Select--Show24'
                          : null
                      }`}
                      name="OpeningTime2"
                      onChange={({ target }) => this.updateTime('OpeningTime2', target.value)}
                      value={value.OpeningTime2}
                    >
                      {HOUR_LIST.map(time => (
                        <option
                          key={uuidv1()}
                          value={time}
                          disabled={this.isSelected(2, time) || this.isDisabled(2, time, true)}
                        >
                          {timeOf(time)}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>

              <div className="BusinessHoursGroup__Hours__Row__Column">
                <div className="BusinessHoursGroup__Hours__Row__Column__Time BusinessHoursGroup__Hours__Row__Column__Time--Splitted">
                  {value.OpeningTime2 && (
                    <select
                      className={`full ${
                        this.is24Hours()
                          ? 'BusinessHoursGroup__Hours__Row__Column__Time__Select--Show24'
                          : null
                      }`}
                      name="ClosingTime2"
                      onChange={({ target }) => this.updateTime('ClosingTime2', target.value)}
                      value={value.ClosingTime2}
                    >
                      {HOUR_LIST.map(time => (
                        <option
                          key={uuidv1()}
                          value={time}
                          disabled={this.isSelected(2, time) || this.isDisabled(2, time, false)}
                        >
                          {timeOf(time)}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="BusinessHoursGroup__Footer">{footer}</div>
      </div>
    );
  }
}

const PERIOD_TYPE = shape({
  DOWs: arrayOf(number).isRequired,
  OpeningTime: string.isRequired,
  ClosingTime: string.isRequired,
  OpeningTime2: string,
  ClosingTime2: string,
});

BusinessHoursGroup.propTypes = {
  periods: arrayOf(PERIOD_TYPE).isRequired,
  currentIndex: number.isRequired,
  footer: node.isRequired,
  value: PERIOD_TYPE.isRequired,
  onHandleChange: func.isRequired,
};

export default BusinessHoursGroup;
