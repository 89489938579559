import './index.sass';

import { isEmpty } from 'lodash';
import { bool, func, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getBusinessDetails } from '../../../actions/businesses';
import MasterPage from '../../../components/MasterPage';
import { BUSINESS_DETAILS_TYPE } from '../../../types/business';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';

class PaymentMethodPage extends React.Component {
  componentDidMount() {
    const { match } = this.props;
    this.getBusinessDetails(match.params.corporateGroupId);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { corporateGroupId },
      },
    } = this.props;

    if (prevProps.match.params.corporateGroupId !== corporateGroupId) {
      this.getBusinessDetails(corporateGroupId);
    }
  }

  getBusinessDetails = async (_id) => {
    const { businessDetails, getBusinessDetails: getBusinessDetailsAction } = this.props;

    const idChanged =
      businessDetails.CorporateGroupId && businessDetails.CorporateGroupId !== Number(_id);

    if (isEmpty(businessDetails) || idChanged) {
      await getBusinessDetailsAction(_id);
    } else {
      this.enableLoader(false);
    }
  };

  render() {
    const { businessDetails, businessDetailsError, auth } = this.props;

    const masterPageConfigs = {
      headerBreadcrumb: [{ name: 'my subscription' }, { name: 'payment methods', bold: true }],
      headerButtons: (
        <Link to="/" className="button small orange-theme">
          add new
        </Link>
      ),
    };

    return (
      <>
        <Helmet title={`Franki Admin: ${businessDetails.DisplayName || 'payment methods'}`} />

        <MasterPage
          auth={auth}
          businessSidebarOn
          businessSidebarData={businessDetails}
          businessSidebarDataError={businessDetailsError}
          businessMobileTitle="payment methods"
          {...masterPageConfigs}
        >
          <div className="payment-title">
            <h2>payment methods</h2>

            <div className="payment-guide">
              <span className="orange-theme" />
              Default Card
            </div>
          </div>

          <ul className="payment-list">
            <li>
              <span className="name">VISA **** **** **** 1234</span>
              <span className="stock">02/24</span>
              <span className="number">123</span>
              <div className="cta">
                <span className="cta delete">DELETE</span>
                <span className="cta set">SET AS DEFAULT</span>
              </div>
            </li>

            <li className="active">
              <span className="name">VISA **** **** **** 1234</span>
              <span className="stock">02/24</span>
              <span className="number">123</span>
              <div className="cta">
                <span className="cta delete">DELETE</span>
              </div>
            </li>
          </ul>
        </MasterPage>
      </>
    );
  }
}

PaymentMethodPage.propTypes = {
  auth: bool.isRequired,
  businessDetails: BUSINESS_DETAILS_TYPE.isRequired,
  businessDetailsError: string.isRequired,
  getBusinessDetails: func.isRequired,
  match: ROUTER_MATCH_TYPE({ corporateGroupId: string.isRequired }).isRequired,
  userRole: string.isRequired,
};

const mapStateToProps = (state) => ({
  businessDetails: state.businesses.businessDetails,
  businessDetailsError: state.businesses.businessDetailsError,
});

export default connect(mapStateToProps, { getBusinessDetails })(PaymentMethodPage);
