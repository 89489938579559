import './index.sass';

import React, { useMemo } from 'react';
import { array, object, oneOfType } from 'prop-types';
import { businessOptions, flattenedOptions, getHealthScoreString, getParkingString } from '../../../../../config/business-options';


const Amenities = (props) => {
  const { options, businessOptionLabel } = props;

  const businessIconOptions = useMemo(() => {
    const iconOptions = [];
    const optionLabels = flattenedOptions(businessOptionLabel);
    if (!options || !optionLabels || optionLabels.length < 1) return iconOptions;

    businessOptions.forEach(({ key, values, icon }) => {
      const newIconOption = options.reduce(
        (sumOption, { Name, Value }) => {
          if (values.includes(Name)) {
            const newSumOption = { ...sumOption };
            const foundLabel = optionLabels.find(item => item.Name === Name);
            if (foundLabel && foundLabel.DisplayString) {
              let displayString = foundLabel.DisplayString;
              switch (Name) {
                case 'HealthScore':
                  displayString += ` ${getHealthScoreString(Value)}`;
                  break;
                case 'Parking':
                  displayString = ` ${getParkingString(Value)}`;
                  break;
                default:
                  break;
              }
              if (newSumOption.displayString) {
                newSumOption.displayString += `, ${displayString}`;
              } else {
                newSumOption.displayString = displayString;
              }
            }
            return newSumOption;
          }
          return sumOption;
        },
        {
          icon,
          id: key,
        },
      );

      iconOptions.push(newIconOption);
    });

    const activeIconOptions = iconOptions.filter(item => item.displayString);

    return activeIconOptions;
  }, [options, businessOptionLabel]);

  return (
    <div className="Amenities">
      {businessIconOptions.map(({ id, icon, displayString }) => (
        <div key={id} className="Amenities__Option">
          <img src={icon} alt={id} />
          <span>{displayString}</span>
        </div>
      ))}
    </div>
  );
};

Amenities.propTypes = {
  options: oneOfType([array]),
  businessOptionLabel: oneOfType([object]),
};

Amenities.defaultProps = {
  options: {},
  businessOptionLabel: null,
};

export default Amenities;
