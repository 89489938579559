import {
  GET_PROMOTION_CONTENTS,
  GET_PROMOTION_CONTENTS_ERROR,
  GET_PROMOTION_CONTENTS_SUCCESS,
  GET_PROMOTIONS,
  GET_PROMOTIONS_ERROR,
  GET_PROMOTIONS_SUCCESS,
} from '../actions/promotions';

const initialState = {
  promotions: [],
  promotionsError: '',
  isFetchingPromotions: false,

  promotionContents: [],
  promotionContentsError: '',
  isFetchingPromotionContents: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_PROMOTIONS: {
      return {
        ...state,
        isFetchingPromotions: true,
        promotions: [],
        promotionsError: '',
      };
    }

    case GET_PROMOTIONS_SUCCESS: {
      return {
        ...state,
        isFetchingPromotions: false,
        promotions: payload.data || [],
      };
    }

    case GET_PROMOTIONS_ERROR: {
      return {
        ...state,
        isFetchingPromotions: false,
        promotionsError: payload.error || 'Error getting promotions',
      };
    }

    case GET_PROMOTION_CONTENTS: {
      return {
        ...state,
        isFetchingPromotionContents: true,
        promotionContents: [],
        promotionContentsError: '',
      };
    }

    case GET_PROMOTION_CONTENTS_SUCCESS: {
      return {
        ...state,
        isFetchingPromotionContents: false,
        promotionContents: payload.data.Results || {},
      };
    }

    case GET_PROMOTION_CONTENTS_ERROR: {
      return {
        ...state,
        isFetchingPromotionContents: false,
        promotionContentsError: payload.error || 'Error getting promotion contents',
      };
    }

    default:
      return state;
  }
}
