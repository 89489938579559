import './index.sass';

import { func, instanceOf, number } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getGigSubmissions } from '../../../../../actions/gigs';
import NoSubmissionsIMG from '../../../../../assets/images/img_nosubmissions.jpg';
import AsyncContent from '../../../../../components/AsyncContent';
import EmptyPage from '../../../../../components/EmptyPage';
import VideoList from '../VideoList';

class Submissions extends React.PureComponent {
  state = {
    loading: true,
    sortby: 'nameASC',
  };

  baseState = this.state;

  componentDidMount() {
    this.getGigSubmissions();
  }

  componentDidUpdate(prevProps) {
    const { gigSubmissions } = this.props;

    if (prevProps.gigSubmissions !== gigSubmissions) {
      this.submissionLoader();
    }
  }

  onHandleChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  getGigSubmissions() {
    const { gigId, videosSubmitted, getGigSubmissions: getGigSubmissionsAction } = this.props;
    if (videosSubmitted) {
      getGigSubmissionsAction(gigId);
    } else {
      this.submissionLoader();
    }
  }

  submissionLoader() {
    this.setState({ loading: false });
  }

  render() {
    const { loading, sortby } = this.state;
    const { gigSubmissions } = this.props;

    return (
      <AsyncContent
        content={() => (
          <div className="GigSubmissions">
            <div className="GigSubmissions__Filter">
              <div className="GigSubmissions__Filter__SortBy">
                <label htmlFor="sortby">sort by</label>
                <select
                  id="sortby"
                  name="sortby"
                  onChange={({ target }) => this.onHandleChange(target.name, target.value)}
                  value={sortby}
                >
                  <option value="nameASC">name (a-z)</option>
                  <option value="nameDESC">name (z-a)</option>
                </select>
              </div>
            </div>

            <VideoList playable type="submissions" videos={gigSubmissions.Results} />
          </div>
        )}
        loading={loading}
        emptyPageContent={
          <EmptyPage
            img={NoSubmissionsIMG}
            imgWidth={170}
            title="No submissions yet?"
            description="Don’t worry, it’s just a matter of time. If you would like help making your GiGs as successful as possible, reach out to us at customersupport@befranki.com"
          />
        }
        expectedData={gigSubmissions.Results}
      />
    );
  }
}

Submissions.propTypes = {
  getGigSubmissions: func.isRequired,
  gigId: number.isRequired,
  gigSubmissions: instanceOf(Object),
  videosSubmitted: number,
};

Submissions.defaultProps = {
  gigSubmissions: [],
  videosSubmitted: 0,
};

const mapStateToProps = (state) => ({
  gigSubmissions: state.gigs.submissions,
});

export default connect(mapStateToProps, {
  getGigSubmissions,
})(Submissions);
