import './index.sass';

import { func } from 'prop-types';
import React from 'react';

import { createPromotion } from '../../../../actions/promotions';
import AddressTagsInput from '../../../../components/AddressTagsInput';
import AsyncContent from '../../../../components/AsyncContent';
import FileUpload from '../../../../components/FileUpload';
import Loader from '../../../../components/Loader';

const generateCityAddressTag = (searchTerm, matchedAddress) => {
  const { city } = matchedAddress;
  if (!city) {
    return {
      error: `${searchTerm} is not a city`,
    };
  }

  return { value: city };
};

const generateStateAddressTag = (searchTerm, matchedAddress) => {
  const { state } = matchedAddress;
  if (!state) {
    return {
      error: `${searchTerm} is not a state`,
    };
  }

  return { value: state };
};

class DiscoverPromotion extends React.Component {
  state = {
    Cities: '',
    States: '',
    error: '',
    Image: '',
    Title: '',
    ShowEveryone: false,
    showDesc: true,
    IsLoading: false,
  };

  onHandleChange(name, value) {
    this.setState({ [name]: value });
    if (name === 'ShowEveryone' && value) {
      this.setState({ Cities: '' });
      this.setState({ States: '' });
    } else if (name === 'Image' && value) {
      this.setState({ showDesc: false });
    }
  }

  async onHandleSubmit(e) {
    e.preventDefault();
    this.setState({ error: '' });

    const { Cities, States, Image, Title, ShowEveryone } = this.state;

    const { onSubmitSuccess } = this.props;
    if (!ShowEveryone) {
      if (!Cities && !States) {
        this.setState({ error: 'At least 1 city or state needs to be added' });
        return;
      }
    }

    if (!Image) {
      this.setState({ error: 'Image is required' });
      return;
    }

    const requestParams = {
      Cities,
      States,
      Image,
      Title,
      ShowEveryone,
    };
    this.setState({ IsLoading: true });
    const newPromotion = await createPromotion(requestParams);

    if (newPromotion.Success) {
      onSubmitSuccess(newPromotion.Data.PromotionId);
    } else {
      this.setState({ error: newPromotion.Message, IsLoading: false });
    }
  }

  render() {
    const { Cities, States, error, Image, Title, ShowEveryone, showDesc, IsLoading } = this.state;

    const { onCancel } = this.props;

    return (
      <AsyncContent
        content={() => (
          <>
            <form method="POST" onSubmit={(e) => this.onHandleSubmit(e)}>
              <div className="DiscoverPromotion__Section">
                <h3>Step 2: Promotion Title</h3>

                <div className="DiscoverPromotion__FormGroup">
                  <label htmlFor="Title">
                    Promotional Title
                    <span className="required-asterisk">*</span>
                  </label>

                  <input
                    autoComplete="off"
                    className="full"
                    id="Title"
                    maxLength={60}
                    minLength={3}
                    name="Title"
                    onChange={({ target }) => this.onHandleChange('Title', target.value)}
                    placeholder="Enter Promotion title here"
                    required
                    type="text"
                    value={Title}
                  />
                </div>
              </div>

              <div className="DiscoverPromotion__Section">
                <h3>Step 3: Select cities</h3>

                <div className="DiscoverPromotion__Section__Subtitle">
                  The promotion will only be displayed to app users that are currently in the cities
                  you select.
                </div>

                <div className="DiscoverPromotion__FormGroup">
                  <label htmlFor="Cities">
                    Add Cities
                    {!ShowEveryone && !Cities && !States && (
                      <span className="required-asterisk">*</span>
                    )}
                  </label>

                  <AddressTagsInput
                    disabled={ShowEveryone}
                    multiple
                    generateAddressTag={(searchTerm, matchedAddress) =>
                      generateCityAddressTag(searchTerm, matchedAddress)
                    }
                    listOfTags={Cities}
                    name="Cities"
                    onChange={(value) => this.onHandleChange('Cities', value)}
                    value={Cities}
                    filter={{
                      types: ['(cities)'],
                    }}
                  />
                  {/* componentRestrictions: {
                        country: ['us', 'au'],
                      }, */}
                </div>

                <div className="DiscoverPromotion__FormGroup">
                  <label htmlFor="States">
                    Add States
                    {!ShowEveryone && !Cities && !States && (
                      <span className="required-asterisk">*</span>
                    )}
                  </label>

                  <AddressTagsInput
                    disabled={ShowEveryone}
                    multiple
                    generateAddressTag={(searchTerm, matchedAddress) =>
                      generateStateAddressTag(searchTerm, matchedAddress)
                    }
                    listOfTags={States}
                    name="States"
                    onChange={(value) => this.onHandleChange('States', value)}
                    value={States}
                  />
                </div>

                <fieldset className="DiscoverPromotion__ShowEveryone">
                  <label>
                    <input
                      checked={ShowEveryone}
                      name="ShowEveryone"
                      onChange={(event) =>
                        this.onHandleChange('ShowEveryone', event.target.checked)
                      }
                      type="checkbox"
                    />
                    <span />
                    Show to everyone
                  </label>
                </fieldset>
              </div>

              <div className="DiscoverPromotion__Section">
                <h3>Step 4: Add Promotion Image</h3>

                <div className="DiscoverPromotion__Section__Subtitle">
                  This image will appear as the main promotion image
                </div>

                <div className="DiscoverPromotion__FormGroup">
                  <FileUpload
                    accept=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG"
                    aspect={16 / 9}
                    description={
                      <>
                        {showDesc && (
                          <div className="DiscoverPromotion__FileUpload">
                            <span className="DiscoverPromotion__FileUpload__Link">Click here</span>
                            to add an image from your device
                          </div>
                        )}
                      </>
                    }
                    id="txtFile"
                    fullWidth
                    maxFileSize={4}
                    name="Image"
                    onChange={(name, value) => this.onHandleChange(name, value)}
                    placeholder={`
                      <div class="DiscoverPromotion__FileUpload">
                        <span class="DiscoverPromotion__FileUpload__PlusButton">+</span>
                        <span class="DiscoverPromotion__FileUpload__CTA">Drag and drop an image</span>
                      </div>
                    `}
                    type="image"
                    value={Image}
                  />
                </div>

                <div className="DiscoverPromotion__FormActions">
                  <button type="submit" className="button small orange-theme" disabled={IsLoading}>
                    Save
                  </button>

                  <button
                    className="button small red-theme"
                    onClick={onCancel}
                    type="button"
                    disabled={IsLoading}
                  >
                    Cancel
                  </button>
                </div>
                {IsLoading && <Loader />}
              </div>
            </form>

            <div className="DiscoverPromotion__Section">
              <h3>Step 5: Select content to promote</h3>
              <div className="DiscoverPromotion__Section__Subtitle">
                Select up to 10 pieces of video content to add to a Promotion. Follow the
                instructions below.
              </div>

              <ul className="DiscoverPromotion__Section__List">
                <li>Visit the business profile where the content you want to add can be found</li>
                <li>Click the Videos tab</li>
                <li>Click the video that you want to add</li>
                <li>
                  Click on the Promote button on the video detail screen to reveal a list of
                  promotions to add the content
                </li>
                <li>Select the Promotion you want to add the video to</li>
              </ul>
            </div>
          </>
        )}
        errorMessage={error}
        onHandleCloseDialog={() => this.setState({ error: '' })}
        expectNoData
      />
    );
  }
}

DiscoverPromotion.propTypes = {
  onSubmitSuccess: func.isRequired,
  onCancel: func.isRequired,
};

export default DiscoverPromotion;
