import './index.sass';

import { bool, func, number } from 'prop-types';
import React from 'react';
import Pagination from 'react-js-pagination';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../config/pagination';
import { Col, Row } from '../Grid';

class Paginator extends React.Component {
  Paginator = React.createRef();

  state = { itemsCountPerPage: DEFAULT_PAGE_SIZE };

  componentDidMount() {
    const { itemsCountPerPage } = this.props;
    this.setState({ itemsCountPerPage });
  }

  async onHandleChangeItems(value) {
    const { onHandleShow } = this.props;
    this.setState({ itemsCountPerPage: value });
    onHandleShow(value, DEFAULT_PAGE_INDEX);
  }

  render() {
    const { isTopPaginator, pageIndex, totalItems, onHandlePage } = this.props;
    const { itemsCountPerPage } = this.state;

    if (!totalItems) {
      return null;
    }

    if (!totalItems) {
      return null;
    }

    return (
      <div className={`Paginator ${isTopPaginator && 'Paginator--Top'}`} ref={this.Paginator}>
        <Row cellSpacing={15} rowSpacing={15}>
          <Col
            lg={6}
            md={6}
            sm={6}
            xs={12}
            verticalAlign="center"
            className="pagination-controllers"
          >
            <div className="pagination-controllers-blocks">
              <label className="pagination-controllers-label" htmlFor="txtShow">
                Show
              </label>
              <select
                id="txtShowedItems"
                name="showedItems"
                onChange={({ target }) => this.onHandleChangeItems(Number(target.value))}
                value={itemsCountPerPage}
              >
                <option value="8">8</option>
                <option value="16">16</option>
              </select>
            </div>

            <div className="pagination-controllers-blocks">
              <span className="pagination-controllers-label">Showing</span>
              <span className="pagination-controllers-value">
                {(pageIndex - 1) * itemsCountPerPage + 1}-
                {Math.min(pageIndex * itemsCountPerPage, totalItems)}
              </span>
            </div>

            <div className="pagination-controllers-blocks">
              <span className="pagination-controllers-label">Items of</span>
              <span className="pagination-controllers-value">{totalItems}</span>
            </div>
          </Col>

          <Col lg={6} md={6} sm={6} xs={12} horizontalAlign="flex-end">
            <Pagination
              activePage={pageIndex}
              firstPageText={<span className="control icon-chevrons-left" />}
              itemsCountPerPage={itemsCountPerPage}
              lastPageText={<span className="control icon-chevrons-right" />}
              nextPageText={<span className="control icon-chevron-right" />}
              onChange={(page) => onHandlePage(itemsCountPerPage, page)}
              pageRangeDisplayed={5}
              prevPageText={<span className="control icon-chevron-left" />}
              totalItemsCount={totalItems}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

Paginator.propTypes = {
  isTopPaginator: bool,
  itemsCountPerPage: number,
  onHandlePage: func,
  onHandleShow: func,
  pageIndex: number,
  totalItems: number,
};

Paginator.defaultProps = {
  isTopPaginator: false,
  itemsCountPerPage: DEFAULT_PAGE_SIZE,
  onHandlePage: () => {},
  onHandleShow: () => {},
  pageIndex: 1,
  totalItems: null,
};

export default Paginator;
