import './index.sass';

import { func, string } from 'prop-types';
import React from 'react';
import MaskInput from 'react-maskinput';

import { isNumber } from '../../helpers';
import CustomSelect from '../CustomSelect';
import { Col, Row } from '../Grid';

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
const amexpRegEx = /^(?:3[47][0-9]{13})$/;
const discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
class CreditCardForm extends React.Component {
  state = {
    currentYear: new Date().getFullYear(),
    arrayOfYears: [],
    arrayOfMonths: [],
    isCreditCardValid: false,
    mask: '0000 0000 0000 0000',
  };

  componentDidMount() {
    this.getYears();
    this.getMonths();
  }

  componentDidUpdate(prevProps) {
    const { expirationYear } = this.props;

    if (prevProps.expirationYear !== expirationYear) {
      this.getMonths();
    }
  }

  onChangeCardNumber = (e) => {
    if (e.target.value.indexOf('34') === 0 || e.target.value.indexOf('37') === 0) {
      this.setState({ mask: '0000 000000 00000' });
    } else {
      this.setState({ mask: '0000 0000 0000 0000' });
    }
    const val = e.target.value;
    this.props.onHandleChange('cardNumber', val);
    const num = val.replaceAll('-', '').replaceAll(' ', '');
    if (
      visaRegEx.test(num) ||
      mastercardRegEx.test(num) ||
      amexpRegEx.test(num) ||
      discovRegEx.test(num)
    ) {
      this.onHandleChange('isCreditCardValid', true);
    } else {
      this.onHandleChange('isCreditCardValid', false);
    }
  };

  onHandleChange(name, value) {
    this.setState({ [name]: value });
  }

  getYears() {
    const { currentYear } = this.state;
    const arrayOfYears = [];

    for (let i = currentYear; i < currentYear + 20; i += 1) {
      arrayOfYears.push(i);
    }

    this.setState({
      arrayOfYears,
    });
  }

  getMonths() {
    const { expirationYear } = this.props;
    const { currentYear } = this.state;
    const arrayOfMonths = [];

    const currentMonth = expirationYear !== currentYear.toString() ? 1 : new Date().getMonth() + 1;

    for (let i = currentMonth; i <= 12; i += 1) {
      arrayOfMonths.push(`0${i}`.slice(-2));
    }

    this.setState({
      arrayOfMonths,
    });
  }

  render() {
    const { cardNumber, expirationYear, expirationMonth, cvv, onHandleChange } = this.props;

    const { arrayOfMonths, arrayOfYears, isCreditCardValid, mask } = this.state;
    return (
      <>
        <div className="CreditCardForm__Block">
          <label htmlFor="txtCardNumber">
            Card Number
            <span className="required-asterisk">*</span>
          </label>
          <Row cellSpacing={5} rowSpacing={5} className="CreditCardForm__Row">
            <div className="CreditCardForm__Block__Card">
              <MaskInput
                value={cardNumber}
                autoComplete="off"
                name="cvv"
                id="txtCardNumber"
                pattern={isCreditCardValid ? null : '^(true)$'}
                className="full cardInput CreditCardForm__CreditCardField"
                onChange={this.onChangeCardNumber}
                maskChar="•"
                mask={mask}
                size={20}
                placeholder="4444 5555 6666 7777"
              />
            </div>
          </Row>
        </div>

        <div className="CreditCardForm__Block">
          <label htmlFor="txtExpirationMonth">
            Expiration Date
            <span className="required-asterisk">*</span>
          </label>

          <Row cellSpacing={5} rowSpacing={5} className="CreditCardForm__Row">
            <Col lg={6} md={6} sm={6} xs={12}>
              <CustomSelect
                className="full CreditCardForm__CalendarField"
                name="expirationMonth"
                id="txtExpirationMonth"
                onChange={({ value: selectedValue }) =>
                  onHandleChange('expirationMonth', selectedValue)
                }
                options={arrayOfMonths.map((item) => ({
                  label: String(item),
                  value: String(item),
                }))}
                placeholder="MM"
                required
                value={
                  expirationMonth
                    ? {
                        label: expirationMonth,
                        value: expirationMonth,
                      }
                    : null
                }
              />
            </Col>

            <Col lg={6} md={6} sm={6} xs={12}>
              <CustomSelect
                className="full CreditCardForm__CalendarField"
                name="expirationYear"
                id="txtExpirationYear"
                onChange={({ value: selectedValue }) =>
                  onHandleChange('expirationYear', selectedValue)
                }
                options={arrayOfYears.map((item) => ({
                  label: String(item),
                  value: String(item),
                }))}
                placeholder="YYYY"
                required
                value={
                  expirationYear
                    ? {
                        label: expirationYear,
                        value: expirationYear,
                      }
                    : null
                }
              />
            </Col>
          </Row>
        </div>

        <div className="CreditCardForm__Block">
          <label htmlFor="txtCVV">
            CVV
            <span className="required-asterisk">*</span>
          </label>

          <input
            type="text"
            className="full"
            name="cvv"
            id="txtCVV"
            onChange={({ target }) => {
              if (target.value === '' || isNumber(target.value)) {
                return onHandleChange(target.name, target.value);
              }

              return false;
            }}
            value={cvv}
            minLength={3}
            maxLength={3}
            required
          />
        </div>
      </>
    );
  }
}

CreditCardForm.propTypes = {
  cardNumber: string.isRequired,
  expirationYear: string.isRequired,
  expirationMonth: string.isRequired,
  cvv: string.isRequired,
  onHandleChange: func.isRequired,
};

export default CreditCardForm;
