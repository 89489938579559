import './index.sass';

import { bool, func, node, number, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import IconCloseSvg from '../../assets/images/close.svg';

/* eslint-disable react/no-danger */
/* eslint-disable no-undef */

export default function LightBox(props) {
  const {
    show,
    width,
    height,
    onClose,
    title,
    children,
    className,
    spacing,
    showCloseButton,
    style,
    maxX,
    maxY,
    offSetX,
    offSetY,
  } = props;

  const self = useRef(null);

  const [display, setDisplay] = useState(show);
  const [displayedWidth, setDisplayedWidth] = useState(width);
  const [displayedHeight, setDisplayedHeight] = useState(height);

  useEffect(() => {
    setDisplay(show);
  }, [show]);

  function closeModal() {
    setDisplay(false);
    onClose();
  }

  function keyDownListener(e) {
    if (display) {
      if (e.keyCode === 27 || e.charCode === 27) {
        closeModal();
      }
    }
  }

  function updateDimensions() {
    const maxHeight = window.innerHeight - offSetY;
    const maxWidth = window.innerWidth - offSetX;

    if (maxY) {
      setDisplayedHeight(Math.min(maxHeight, maxY));
    } else if (maxHeight < height) {
      setDisplayedHeight(maxHeight);
    }

    if (maxX) {
      setDisplayedWidth(Math.min(maxWidth, maxX));
    } else if (maxWidth < width) {
      setDisplayedWidth(maxWidth);
    }
  }

  useEffect(() => {
    setDisplay(show);
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    window.addEventListener('keydown', keyDownListener);

    return () => {
      window.removeEventListener('resize', updateDimensions);
      window.removeEventListener('keydown', keyDownListener);
    };
  }, []);

  if (display) {
    document.body.classList.add('body-overflow');

    return (
      <div className={`LightBox ${className}`} ref={self}>
        <div
          className="LightBox-bg"
          role="presentation"
          onClick={closeModal}
          onKeyPress={closeModal}
        />

        <div
          className="LightBox-main"
          style={{
            width: displayedWidth,
            height: displayedHeight,
            marginLeft: (displayedWidth / 2) * -1,
            marginTop: (displayedHeight / 2) * -1,
            padding: spacing,
            ...style,
          }}
        >
          {title && (
            <h1
              className="LightBox-title"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          )}

          {showCloseButton && (
            <button type="button" className="LightBox-close" onClick={closeModal}>
              <img src={IconCloseSvg} alt="close" />
            </button>
          )}

          <div className="LightBox-content">{children}</div>
        </div>
      </div>
    );
  }
  document.body.classList.remove('body-overflow');
  return null;
}

LightBox.propTypes = {
  children: node,
  className: string,
  height: number,
  onClose: func.isRequired,
  show: bool,
  showCloseButton: bool,
  spacing: number,
  title: string,
  width: number,
  offSetX: number,
  offSetY: number,
  maxX: number,
  maxY: number,
};

LightBox.defaultProps = {
  children: null,
  className: '',
  height: 500,
  show: false,
  showCloseButton: true,
  spacing: 30,
  title: '',
  width: 640,
  offSetX: 40,
  offSetY: 90,
  maxX: null,
  maxY: null,
};
