import './index.sass';

import { func, instanceOf, string } from 'prop-types';
import React, { useEffect } from 'react';

import initialGigBackground from '../../../../../assets/images/gigs/circlebtn.PNG';
import gigPreviewImg from '../../../../../assets/images/gigtemplate-large.png';
import StepsHeader from '../../../../../components/StepsHeader';

export default function BusinessGigCreateStepZero(props) {
  const { onHandleNextStep } = props;

  useEffect(() => {
    const el = document.querySelectorAll('.LightBox-content > header');
    if (el.length > 0) {
      el[0].remove();
    }
  }, []);

  return (
    <>
      <StepsHeader
        maxSteps={5}
        step={0}
        subtitle="You can do a lot with GiGs. Below are some templates to get you started."
        title="What type of GiG would you like to run?"
        tooltip={
          <>
            <p>this is what your gig will look like in franki, be sure its enticing!</p>
            <figure>
              <img src={gigPreviewImg} alt="" />
            </figure>
          </>
        }
        tooltipTitle="gig details"
      />

      <div className="BusinessGigCreateStepZero">
        <span onClick={onHandleNextStep} className="control icon-chevron-right initialGigArrow" />
        <img width="100%" height="100%" src={initialGigBackground} alt="" />
        <div className="containerZeroStepDiv">
          <div className="containerZeroStep">
            GiGs are designed to put your marketing dollars straight back into your business. It's
            risk-free promotion, and a great way to get customer feedback.
          </div>
          <button
            style={{ marginTop: '1rem' }}
            onClick={onHandleNextStep}
            className="button small button--primary startBtn"
          >
            Let's Get Started
          </button>
        </div>
      </div>
    </>
  );
}

BusinessGigCreateStepZero.propTypes = {
  businessID: string.isRequired,
  formState: instanceOf(Object).isRequired,
  onHandleNextStep: func.isRequired,
  onHandleFormChange: func.isRequired,
};
