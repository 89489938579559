/* global window */
/* eslint-disable no-underscore-dangle */
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { env } from '../config/variables';
import reducers from '../reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function getStore() {
  if (env.REACT_APP_ENV !== 'prod') {
    return createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
  }

  return createStore(reducers, compose(applyMiddleware(thunk)));
}

export const store = getStore();

export default store;
