import { bool, func, oneOfType, string } from 'prop-types';
import React from 'react';
import AsyncSelect from 'react-select/async';
import SELECT_OPTIONS_TYPE, { SELECT_OPTION_TYPE } from '../../types/selectOptions';
import CustomSelectRequiredInput from './CustomSelectRequiredInput';
import customSelectStyles from './customSelectStyle';
import customSelectTheme from './customSelectTheme';
import DropdownIndicator from './DropdownIndicator';
import './index.sass';

const AsyncCustomSelect = (props) => {
  const { isMulti, name, onChange, options, placeholder, required, value } = props;

  return (
    <>
      <AsyncSelect
        classNamePrefix="CustomSelect"
        components={{ DropdownIndicator }}
        defaultOptions
        isMulti={isMulti}
        loadOptions={options}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        styles={customSelectStyles}
        theme={customSelectTheme}
        value={value}
      />

      <CustomSelectRequiredInput required={required} hasValue={!!value} />
    </>
  );
};

AsyncCustomSelect.propTypes = {
  isMulti: bool,
  name: string.isRequired,
  onChange: func.isRequired,
  options: func.isRequired,
  placeholder: string.isRequired,
  required: bool,
  value: oneOfType([SELECT_OPTION_TYPE, SELECT_OPTIONS_TYPE]),
};

AsyncCustomSelect.defaultProps = {
  isMulti: false,
  required: false,
  value: '',
};

export default AsyncCustomSelect;
