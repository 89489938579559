import { bool, func, string } from 'prop-types';
import React from 'react';

import AutocompleteGooglePlaces from '../../AutocompleteGooglePlaces';

class FiltersModalLocation extends React.Component {
  state = { value: '' };

  componentDidMount() {
    const { value } = this.props;
    this.updateState(value);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.updateState(value);
    }
  }

  updateState(value) {
    this.setState({ value });
  }

  render() {
    const { value } = this.state;
    const { className, disabled, label, onChange, placeholder } = this.props;

    return (
      <fieldset className={className}>
        <legend>{label}</legend>
        <AutocompleteGooglePlaces
          disabled={disabled}
          inputClassName="full"
          onChange={(newValue) => onChange(newValue)}
          onSelect={(newValue) => onChange(newValue)}
          placeholder={placeholder}
          value={value}
        />
      </fieldset>
    );
  }
}

FiltersModalLocation.propTypes = {
  className: string,
  disabled: bool,
  label: string.isRequired,
  onChange: func.isRequired,
  placeholder: string.isRequired,
  value: string,
};

FiltersModalLocation.defaultProps = {
  className: '',
  disabled: false,
  value: '',
};

export default FiltersModalLocation;
