import { isEmpty } from 'lodash';
import moment from 'moment';
import STATES from '../config/country-states';

/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
export const orderByDate = (arr = [], propName, order = 'asc') => arr.slice(0).sort((a, b) => {
  const firstDate = new Date(a[propName]);
  const secondDate = new Date(b[propName]);
  let orderDesc = 1;
  let orderAsc = -1;

  if (order === 'desc') {
    orderDesc = -1;
    orderAsc = 1;
  }

  if (firstDate > secondDate) return orderAsc;
  if (firstDate < secondDate) return orderDesc;
  return 0;
});

export const clearEmptyObjectProperties = (obj) => {
  const newObj = Object.keys(obj).reduce((objItem, key) => {
    if (obj[key]) {
      if (typeof obj[key] === 'string' || typeof obj[key] === 'number') {
        objItem[key] = obj[key];
      } else if (typeof obj[key] === 'object') {
        if (
          (obj[key].constructor === Array || obj[key].constructor === Object)
          && !isEmpty(obj[key])
        ) {
          objItem[key] = obj[key];
        }
      }
    }

    return objItem;
  }, {});

  return newObj;
};

export const validEmailAddress = (email) => {
  const regex = /^(\w)+((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,}$/;

  return {
    regexAsString: regex.source,
    result: regex.test(email),
  };
};

export const validPasswordStraight = (password) => {
  const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  return {
    regexAsString: regex.source,
    lowercase: /(?=.*?[a-z])/.test(password),
    uppercase: /(?=.*?[A-Z])/.test(password),
    number: /(?=.*?[0-9])/.test(password),
    specialChar: /(?=.*?[#?!@$%^&*-])/.test(password),
    length: password.length >= 8,
    result: regex.test(password),
  };
};

export const validPasswordReset = (password) => {
  const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-/:;()$&@“.,?!’{}#%^*+=_|~<>£€¥₩•"×÷`~'\[\]\\]).{8,}$/;

  return {
    regexAsString: regex.source,
    lowercase: /(?=.*?[a-z])/.test(password),
    uppercase: /(?=.*?[A-Z])/.test(password),
    number: /(?=.*?[0-9])/.test(password),
    specialChar: /(?=.*?[-/:;()$&@“.,?!’{}#%^*+=_|~<>£€¥₩•"×÷`~'\[\]\\])/.test(password),
    length: password.length >= 8,
    result: regex.test(password),
  };
};

export const validWebsite = (website) => {
  const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

  return {
    regexAsString: regex.source,
    result: regex.test(website),
  };
};

export const isNumber = _string => /^[0-9]*$/.test(_string);
export const isVisa = _string => /^([0-9]{4}( |\-)){3}[0-4]{4}$/.test(_string);

export const getStatusName = (statusID) => {
  switch (statusID) {
    case 0:
      return {
        label: 'draft',
        slug: 'draft',
        number: statusID,
      };
    case 1:
      return {
        label: 'active',
        slug: 'active',
        number: statusID,
      };
    case 2:
      return {
        label: 'in review',
        slug: 'review',
        number: statusID,
      };
    case 3:
      return {
        label: 'closed',
        slug: 'closed',
        number: statusID,
      };
    default:
      return {
        label: 'expired',
        slug: 'expired',
        number: statusID,
      };
  }
};

export const convertObjToFormData = (obj) => {
  // eslint-disable-next-line no-undef
  const formData = new FormData();
  const convertObjToArray = Object.entries(obj);

  if (obj && !isEmpty(convertObjToArray)) {
    for (let i = 0; i < convertObjToArray.length; i += 1) {
      const value = convertObjToArray[i][1];
      if (value instanceof Blob && value.name) {
        formData.append(convertObjToArray[i][0], value, value.name);
      } else {
        formData.append(convertObjToArray[i][0], value);
      }
    }
  }

  return formData;
};

export const copyText = (text) => {
  const tempInput = document.createElement('input');
  document.body.append(tempInput);
  tempInput.value = text;

  tempInput.select();
  tempInput.setSelectionRange(0, 99999);

  document.execCommand('copy');
  tempInput.remove();
  return text;
};

export const countryCodeToName = (countryCode) => {
  const countryInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
  try {
    return countryInEnglish.of(countryCode);
  } catch (error) {
    return countryCode;
  }
};

export const stateCodeToName = (stateCode) => {
  const matchingValue = STATES.find(item => item.Shortcode === stateCode);
  return matchingValue ? matchingValue.Name : stateCode;
};

const Helpers = {
  clearEmptyObjectProperties,
  copyText,
  convertObjToFormData,
  getStatusName,
  isNumber,
  orderByDate,
  validEmailAddress,
  validPasswordStraight,
  validWebsite,
  countryCodeToName,
  stateCodeToName,
};

export const getQuarterList = () => {
  const currentQ = moment().quarter();
  const currentYear = new Date().getFullYear();
  const list = [];
  for (let index = currentQ; index <= 4; index += 1) {
    const startDateOfQ = moment(currentYear, 'YYYY').quarter(index).startOf('quarter');
    const endDateOfQ = moment(currentYear, 'YYYY').quarter(index).endOf('quarter');

    list.push({
      label: `${index}.Quarter ${startDateOfQ.format('DD MMM YYYY')} -
      ${endDateOfQ.format('DD MMM YYYY')}`,
      value: {
        startDate: `${startDateOfQ.month() + 1}/${startDateOfQ.year()}`,
        endDate: `${endDateOfQ.month() + 1}/${endDateOfQ.year()}`,
      },
    });
  }
  return list;
};

export default Helpers;
