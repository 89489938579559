import './assets/styles/main.sass';
import 'bootstrap/dist/css/bootstrap-grid.css';

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Routers from './routers';
import { store } from './store';
import { registerMockAxios } from './mock/mockRegister';
import { REACT_APP_ENV } from './config/variables';

const App = () => {
  useEffect(() => {
    if (REACT_APP_ENV === 'dev') {
      registerMockAxios();
    }
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routers />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
