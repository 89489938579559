import './index.sass';

import { string } from 'prop-types';
import React from 'react';

const HamburgerMenuIcon = (props) => {
  const { icon } = props;

  if (!icon) {
    return null;
  }

  return (
    <span className="HamburgerMenuIcon">
      <i className={icon} />
    </span>
  );
};

HamburgerMenuIcon.propTypes = {
  icon: string.isRequired,
};

export default HamburgerMenuIcon;
