import './index.sass';

import { instanceOf, string } from 'prop-types';
import React from 'react';

import facebookIMG from '../../assets/images/facebook.png';
import instagramIMG from '../../assets/images/instagram.png';
import twitterIMG from '../../assets/images/twitter.png';

/* eslint-disable no-lone-blocks */
const SocialMedia = ({ className, items }) => {
  function socialMediaRender() {
    return (items || []).map((item) => {
      switch (item.socialType) {
        case 'twitter':
          return (
            item.url && (
              <li key="twitter">
                <a href={item.url} target="_blank">
                  <img src={twitterIMG} alt="twitter" />
                  <span className="SocialMediaList__Text">{item.socialType}</span>
                </a>
              </li>
            )
          );

        case 'facebook':
          return (
            item.url && (
              <li key="facebook">
                <a href={item.url} target="_blank">
                  <img src={facebookIMG} alt="facebook" />
                  <span className="SocialMediaList__Text">{item.socialType}</span>
                </a>
              </li>
            )
          );

        case 'instagram':
          return (
            item.url && (
              <li key="instagram">
                <a href={item.url} target="_blank">
                  <img src={instagramIMG} alt="instagram" />
                  <span className="SocialMediaList__Text">{item.socialType}</span>
                </a>
              </li>
            )
          );
        default:
          return null;
      }
    });
  }

  if (items.length) {
    return <ul className={`SocialMediaList ${className}`}>{socialMediaRender()}</ul>;
  }

  return (
    <ul className={`SocialMediaList ${className}`}>
      <li>
        <img src={twitterIMG} alt="twitter" />
      </li>

      <li>
        <img src={facebookIMG} alt="facebook" />
      </li>

      <li>
        <img src={instagramIMG} alt="instagram" />
      </li>
    </ul>
  );
};

SocialMedia.propTypes = {
  className: string,
  items: instanceOf(Array),
};

SocialMedia.defaultProps = {
  className: '',
  items: [],
};

export default SocialMedia;
