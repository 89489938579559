import './index.sass';

import { bool, func, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getBusinessDetails as getBusinessDetailsAction } from '../../../actions/businesses';
import EmptyGig from '../../../assets/images/empty_gig.jpg';
import NoBusinessesIMG from '../../../assets/images/img_nobusinesses.jpg';
import AsyncContent from '../../../components/AsyncContent';
import PlanCard from '../../../components/Cards/PlanCard';
import EmptyPage from '../../../components/EmptyPage';
import LightBox from '../../../components/LightBox';
import MasterPage from '../../../components/MasterPage';
import USER_ROLES from '../../../config/user-roles';
import { BUSINESS_DETAILS_TYPE } from '../../../types/business';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';
import LoggedNotFoundBusinessSales from '../../404/LoggedNotFoundBusinessSales';
import LoggedNotFoundSuperAdmin from '../../404/LoggedNotFoundSuperAdmin';
import BusinessGigCreate from '../BusinessGig/BusinessGigCreate';

class BusinessProfileIllustrations extends React.Component {
  state = {
    isCreateGigOpen: false,
    createGigError: '',
    ctaContent: (
      <>
        <div className="BusinessGigs__EmptyPage__CTATitle">create gig</div>

        <div className="BusinessGigs__EmptyPage__CTAContent">
          create a gig and make you loved
          <br />
          and known by your community
        </div>

        <button
          className="BusinessGigs__EmptyPage__CTAButton"
          onClick={() => this.setState({ ctaContent: null })}
          type="button"
        >
          GOT IT!
        </button>
      </>
    ),
  };

  componentDidMount() {
    const {
      getBusinessDetails,
      match: {
        params: { corporateGroupId },
      },
    } = this.props;
    getBusinessDetails(corporateGroupId);
  }

  createGigError(msg) {
    this.setState({
      createGigError: (
        <>
          {msg || (
            <>
              To create a GiG, edit your profile, or view content you first need to finish the
              verification process. If you feel you&rsquo;ve received this message in error or if a
              franki representative hasn&rsquo;t contacted you, please email us at{' '}
              <a href="mailto:customersupport@befranki.com">customersupport@befranki.com</a>
            </>
          )}
        </>
      ),
    });
  }

  render() {
    const {
      businessDetails,
      businessDetailsError,
      isFetchingBusinessDetails,
      auth,
      match: {
        params: { corporateGroupId },
      },
      userRole,
    } = this.props;

    const { ctaContent, isCreateGigOpen, createGigError } = this.state;

    const createGigAction = {
      buttonCallback: () => {
        if (businessDetails.BusinessSourceId !== 1) {
          this.createGigError(
            'You cannot create a gig for this business until it is verified and converted to a local Franki business. Please contact customersupport@befranki.com for any queries.',
          );
        } else {
          this.openCreateGigModal();
        }
      },
    };

    return (
      <>
        <Helmet title={`Franki Admin: ${businessDetails.DisplayName || 'Manage Plan'}`} />

        {businessDetailsError ? (
          <>
            {userRole === USER_ROLES.admin ? (
              <LoggedNotFoundSuperAdmin auth={auth} />
            ) : (
              <LoggedNotFoundBusinessSales auth={auth} />
            )}
          </>
        ) : (
          <>
            <MasterPage
              auth={auth}
              businessMobileTitle="Profile"
              businessSidebarData={businessDetails}
              businessSidebarDataError={businessDetailsError}
              businessSidebarOn
            >
              <div className="BusinessProfileIllustrations">
                <AsyncContent
                  content={() => (
                    <>
                      <PlanCard
                        bgDeep
                        heading={
                          'franki free trial <span class="Subtitles">ends on May 18th 2019</span>'
                        }
                        text="take advantage and run some gigs to see what franki can do for you!"
                        thumbSubtitles="days left"
                        thumbTitle="12"
                      />

                      <div className="BusinessProfileIllustrations__Main">
                        <div className="BusinessProfileIllustrations__Main__Card">
                          <span className="BusinessProfileIllustrations__Main__Card__Caption">
                            current plan
                          </span>

                          <h3 className="BusinessProfileIllustrations__Main__Card__Title">
                            <strong>Standard Membership</strong>
                          </h3>

                          <p className="BusinessProfileIllustrations__Main__Card__Description">
                            $225 a month &nbsp;&nbsp;|&nbsp;&nbsp; unlimited gigs
                            &nbsp;&nbsp;|&nbsp;&nbsp; $75/month gig credits
                          </p>

                          <div className="BusinessProfileIllustrations__Main__Card__Actions">
                            <Link
                              className="button button-outline BusinessProfileIllustrations__Main__Card__CTA"
                              to={`/admin/business-manage-plan/${corporateGroupId}`}
                            >
                              MANAGE PLAN
                            </Link>

                            <Link
                              className="button button-outline
                                    BusinessProfileIllustrations__Main__Card__CTA
                                    BusinessProfileIllustrations__Main__Card__CTA--Warn"
                              to="/"
                            >
                              CANCEL SUBCRIPTION
                            </Link>
                          </div>
                        </div>

                        <div className="BusinessProfileIllustrations__Main__Card">
                          <span className="BusinessProfileIllustrations__Main__Card__Caption">
                            next charge
                          </span>

                          <h3 className="BusinessProfileIllustrations__Main__Card__Title">
                            <strong>$225</strong>
                            &nbsp;on May 19, 2019
                          </h3>

                          <p className="BusinessProfileIllustrations__Main__Card__Description">
                            charging to VISA *1234
                          </p>

                          <div className="BusinessProfileIllustrations__Main__Card__Actions">
                            <Link
                              className="button button-outline BusinessProfileIllustrations__Main__Card__CTA"
                              to="/"
                            >
                              UPDATE PAYMENT METHOD
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="contentPage card-empty">
                        <span className="BusinessProfileIllustrations__Main__Card__Caption">
                          transaction history
                        </span>

                        <EmptyPage
                          buttonText="Create your first gig"
                          ctaContent={ctaContent}
                          description="A great way to get your first video reviews and bring new customers through your door is to run a GiG!"
                          img={EmptyGig}
                          title="No posts? No worries!"
                          {...createGigAction}
                        />
                      </div>
                    </>
                  )}
                  emptyPageContent={
                    <EmptyPage
                      buttonText="create business"
                      buttonURI="/admin/register-business"
                      description="let’s add your first business now"
                      img={NoBusinessesIMG}
                      title="looks like you have no businesses"
                    />
                  }
                  expectedData={businessDetails}
                  loading={isFetchingBusinessDetails}
                  error={createGigError}
                />
              </div>
            </MasterPage>

            <LightBox
              className="BusinessGigs__CreateGigLightBox"
              show={isCreateGigOpen}
              spacing={0}
              width={1318}
              height={715}
              style={{
                marginLeft: -650,
              }}
              onClose={() => {
                this.closeCreateGigModal();
              }}
            >
              <BusinessGigCreate {...this.props} />
            </LightBox>
          </>
        )}
      </>
    );
  }
}

BusinessProfileIllustrations.propTypes = {
  auth: bool.isRequired,
  businessDetails: BUSINESS_DETAILS_TYPE.isRequired,
  businessDetailsError: string.isRequired,
  getBusinessDetails: func.isRequired,
  isFetchingBusinessDetails: bool.isRequired,
  match: ROUTER_MATCH_TYPE({
    corporateGroupId: string.isRequired,
    gigId: string.isRequired,
    step: string.isRequired,
  }).isRequired,
  userRole: string.isRequired,
};

const mapStateToProps = (state) => ({
  businessDetails: state.businesses.businessDetails,
  businessDetailsError: state.businesses.businessDetailsError,
  isFetchingBusinessDetails: state.businesses.isFetchingBusinessDetails,
});

export default connect(mapStateToProps, {
  getBusinessDetails: getBusinessDetailsAction,
})(BusinessProfileIllustrations);
