import { COLORS } from '../../../config/colors';

const filtersOrderBySelectBoxStyles = {
  control: () => ({
    color: COLORS.black,
    cursor: 'pointer',
    display: 'flex',
    marginLeft: '-5px',
    marginRight: '-5px',
  }),
  indicatorsContainer: () => ({
    margin: '0 -8px',
    padding: 0,
    transform: 'scale(.75)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: () => ({
    backgroundColor: COLORS.white,
    boxShadow: '0 2px 8px 2px rgba(0, 0, 0, 0.1)',
    marginBottom: -5,
    minWidth: 140,
    padding: 0,
    position: 'absolute',
    left: 0,
    zIndex: 1,
  }),
  option: provided => ({
    ...provided,
    color: '#767676',
    fontSize: 14,
    fontWeight: 200,
    lineHeight: '18px',
    padding: '11px 15px 13px 10px',
  }),
  placeholder: () => ({
    fontSize: 14,
    lineHeight: '18px',
  }),
  singleValue: () => ({
    fontSize: 14,
    minWidth: 65,
  }),
};

export default filtersOrderBySelectBoxStyles;
