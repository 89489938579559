import './index.sass';

import { isEmpty } from 'lodash';
import moment from 'moment';
import { bool, instanceOf } from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import ClickableImage from '../../ClickableImage';
import HamburgerMenu from '../../HamburgerMenu';

class VideosCard extends React.PureComponent {
  render() {
    const { accessible, bookmarkItems, hamburgerMenuItems, item, match } = this.props;

    return (
      <div className={`videosCard ${item.IsDeleted && 'DeletedItem'}`}>
        {accessible && !isEmpty(bookmarkItems) && (
          <HamburgerMenu
            menuItems={bookmarkItems}
            className="videosCard-bookmarks"
            icon="icon-bookmark_default"
            position="Left"
          />
        )}

        {accessible && !isEmpty(hamburgerMenuItems) && (
          <HamburgerMenu menuItems={hamburgerMenuItems} />
        )}

        <figure className="videosCard-mainImg">
          {item.FullResolutionUrl && (
            <ClickableImage
              alt={item.PhotoDescription}
              pathname={
                accessible
                  ? `/admin/business-video-details/${match.params.corporateGroupId}/${item.Key}`
                  : null
              }
              src={item.FullResolutionUrl}
              state={{ data: item }}
            />
          )}

          {item.featured && <figcaption className="videosCard-featured">featured</figcaption>}
        </figure>

        <div className="videosCard-wrapper">
          <div className="videosCard-postedby">
            {item.Avatar && (
              <figure className="videosCard-avatar">
                <img src={item.Avatar} alt="avatar" />
              </figure>
            )}

            {item.PosterProfile &&
              Object.entries(item.PosterProfile).length > 1 &&
              item.PosterProfile.ProfilePhoto &&
              item.PosterProfile.ProfilePhoto.FullResolutionUrl && (
                <figure className="videosCard-avatar">
                  <img src={item.PosterProfile.ProfilePhoto.FullResolutionUrl} alt="avatar" />
                </figure>
              )}

            <div className="videosCard-postedby-info">
              {item.PosterProfile &&
                Object.entries(item.PosterProfile).length > 1 &&
                item.PosterProfile.DisplayName && (
                  <span className="videosCard-postedby-info-user">
                    {item.PosterProfile.DisplayName}
                  </span>
                )}

              {item.Author && <span className="videosCard-postedby-info-user">{item.Author}</span>}

              {item.Posted && (
                <span className="videosCard-postedby-info-date">
                  {moment(new Date(item.Posted)).format('L - LT')}
                </span>
              )}
            </div>
          </div>

          {item.caption && <p className="videosCard-description">{item.caption}</p>}

          <div className="videosCard-footer">
            <ul>
              <li>
                <span className="videosCard-footer-item">{item.LikeCount}</span>
                <i className="icon-heart" />
              </li>

              <li>
                <span className="videosCard-footer-item">{item.CommentCount}</span>
                <i className="icon-comment" />
              </li>

              <li>
                <span className="videosCard-footer-item">{item.FavoriteCount}</span>
                <i className="icon-save" />
              </li>

              <li>
                <span className="videosCard-footer-item">{item.Rating}</span>
                <i className="icon-star_on" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

VideosCard.propTypes = {
  bookmarkItems: instanceOf(Array),
  hamburgerMenuItems: instanceOf(Array),
  item: instanceOf(Object).isRequired,
  match: instanceOf(Object).isRequired,
  accessible: bool,
};

VideosCard.defaultProps = {
  bookmarkItems: [],
  hamburgerMenuItems: [],
  accessible: true,
};

export default withRouter(VideosCard);
