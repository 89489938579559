/**
 * Synchronize with assets/styles/colors.sass
 */

export const COLORS = {
  darkGray: '#4A4A4A',
  darkPurple: '#601AEA',

  // STANDARD
  black: '#333333',
  purple: '#6C22FF',
  white: '#FFFFFF',
  orange: '#FFB30F',
  orangeDeep: '#EE6E30',
  red: '#FF6B43',
  gray: '#767676',
  green: '#ADE270',
  blue: '#46A6EA',
  red2: '#FC5334',

  // LIGHT
  lightGray: '#949494',
  lightPurple: '#F0E9FF',
  lightPurple2: '#F7F5FB',
  lightOrange: '#FFD987',
  lightRed: '#FFDAD0',

  // OTHER
  borderColor: '#E8E8E8',
  inputBorderColor: '#CCCCCC',
};

export default COLORS;
