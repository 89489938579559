import {
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_ERROR,
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_STATES_ERROR,
  GET_PROMOTION_DETAIL,
  GET_PROMOTION_DETAIL_ERROR,
  GET_PROMOTION_DETAIL_SUCCESS,
  GET_PROMOTION_LIST,
  GET_PROMOTION_LIST_SUCCESS,
  GET_PROMOTION_LIST_ERROR,
  GET_PROMOTION_STAT,
  GET_PROMOTION_STAT_SUCCESS,
  GET_PROMOTION_STAT_ERROR,
} from '../actions/businessPromotions';

const SET_PROMOTION_VALUE = 'SET_PROMOTION_VALUE';
const CLEAR_PROMOTION_VALUE = 'CLEAR_PROMOTION_VALUE';

const initialState = {
  countryInfo: {
    countries: [],
    isLoading: false,
    error: '',
    success: false,
  },
  statsInfo: {
    list: { Businesses: [] },
    isLoading: false,
    error: '',
    success: false,
  },
  promotionInfo: {
    promotionList: {
      Campaigns: [],
    },
    isLoading: false,
    error: '',
    success: false,
  },
  promotion: {
    Name: '',
    StartDate: '',
    EndDate: '',
    CountryId: '',
    Country: { id: 0, value: '' },
    States: [],
    PricePerProfileVisit: null,
    ConversionRate: null,
  },
  isLoading: true,
  getPromotionError: '',
  stateList: {
    states: [],
    isLoading: true,
    error: '',
    success: false,
  },

};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_COUNTRY_LIST: {
      return {
        ...state,
        countryInfo: { ...state.countryInfo, isLoading: true },
      };
    }
    case CLEAR_PROMOTION_VALUE: {
      return {
        ...state,
        promotion: initialState.promotion,
        stateList: initialState.stateList,
      };
    }
    case GET_COUNTRY_LIST_SUCCESS: {
      return {
        ...state,
        countryInfo: { ...state.countryInfo, countries: payload.data, isLoading: false, success: true },
      };
    }

    case GET_COUNTRY_LIST_ERROR: {
      return {
        ...state,
        countryInfo: { ...state.countryInfo, countries: payload.Data || [], isLoading: false, success: false, error: payload || '' },

      };
    }
    case GET_PROMOTION_STAT: {
      return {
        ...state,
        promotion: initialState.promotion,
        stateList: initialState.stateList,
      };
    }
    case GET_PROMOTION_STAT_SUCCESS: {
      return {
        ...state,
        statsInfo: { ...state.statsInfo, list: payload.data, isLoading: false, success: true },
      };
    }

    case GET_PROMOTION_STAT_ERROR: {
      return {
        ...state,
        statsInfo: { ...state.statsInfo, list: payload.Data || [], isLoading: false, success: false, error: payload || '' },

      };
    }
    case GET_STATES: {
      return {
        ...state,
        stateList: { ...state.stateList, isLoading: true },
      };
    }

    case GET_STATES_SUCCESS: {
      return {
        ...state,
        stateList: { ...state.stateList, states: payload.data, isLoading: false, success: true },
      };
    }

    case GET_STATES_ERROR: {
      return {
        ...state,
        stateList: { ...state.stateList, states: payload.Data || [], isLoading: false, success: false, error: payload || '' },
      };
    }

    case SET_PROMOTION_VALUE: {
      return {
        ...state,
        promotion: { ...state.promotion, [payload.field]: payload.value },
      };
    }

    case GET_PROMOTION_DETAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_PROMOTION_DETAIL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        promotion: payload.data,
      };
    }
    case GET_PROMOTION_DETAIL_ERROR: {
      return {
        ...state,
        isLoading: false,
        promotion: initialState.promotion,
        getPromotionError: payload,
      };
    }

    case GET_PROMOTION_LIST: {
      return {
        ...state,
        promotionInfo: { ...state.promotionInfo, isLoading: true },
      };
    }
    case GET_PROMOTION_LIST_SUCCESS: {
      return {
        ...state,
        promotionInfo: { ...state.promotionInfo, promotionList: payload.data || [], isLoading: false, success: true },
      };
    }
    case GET_PROMOTION_LIST_ERROR: {
      return {
        ...state,
        promotionInfo: { ...state.promotionInfo, isLoading: false, success: false, error: payload || '' },
      };
    }

    default:
      return state;
  }
}
