import './index.sass';

import { keys } from 'lodash';
import { bool, func, number, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import uuidv1 from 'uuid/v1';

import {
  getBusinessDetails as getBusinessDetailsAction,
  getBusinessVideos as getBusinessVideosAction,
  verifyPermission,
} from '../../../actions/businesses';
import NoVideosIMG from '../../../assets/images/img_novideos.jpg';
import AsyncContent from '../../../components/AsyncContent';
import VideosCard from '../../../components/Cards/Videos';
import EmptyPage from '../../../components/EmptyPage';
import Filters from '../../../components/Filters';
import { Col, Row } from '../../../components/Grid';
import LightBox from '../../../components/LightBox';
import MasterPage from '../../../components/MasterPage';
import Paginator from '../../../components/Paginator';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../config/pagination';
import USER_ROLES from '../../../config/user-roles';
import str from '../../../helpers/string';
import { BUSINESS_DETAILS_TYPE } from '../../../types/business';
import HISTORY_TYPE from '../../../types/history';
import LOCATION_TYPE from '../../../types/location';
import RESPONSE_LIST_OF_TYPE from '../../../types/responseListOf';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';
import VIDEO_TYPE from '../../../types/video';
import asyncLocalStorage from '../../../utils/asyncLocalStorage';
import BusinessGigCreate from '../BusinessGig/BusinessGigCreate';

const getQueryParams = (searchQueryParams) => {
  const params = {};
  const query = new URLSearchParams(searchQueryParams);
  [
    'creators',
    'fromDate',
    'products',
    'ratings',
    'reviewType',
    'search',
    'orderBy',
    'toDate',
    'includeDeletedContent',
  ].forEach((term) => {
    const termValue = query.get(term);
    if (termValue) {
      params[term] = termValue;
    }
  });

  return params;
};

class BusinessesVideos extends React.Component {
  state = {
    hideCreateGigButton: false,
    createGigError: '',
    isCreateGigOpen: false,

    userRole: null,
    verified: null,
  };

  componentDidMount() {
    const {
      history,
      getBusinessDetails,
      location: { search },
      match: {
        params: { corporateGroupId, pageNumber, itemsCountPerPage },
      },
    } = this.props;

    verifyPermission(corporateGroupId, history).subscribe(
      (verified) => {
        this.setState({ verified, hideCreateGigButton: !verified });
      },
      (error) => {
        this.setState({ hideCreateGigButton: true });
      },
    );

    this.getUserRole();
    getBusinessDetails(corporateGroupId);

    if (!pageNumber || !itemsCountPerPage) {
      this.updatePagination(
        Number(itemsCountPerPage) || DEFAULT_PAGE_SIZE,
        Number(pageNumber) || DEFAULT_PAGE_INDEX,
      );
    }
    this.getVideos(search);
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search },
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;

    if (prevProps.location.search !== search) {
      if (Number(pageNumber) !== DEFAULT_PAGE_INDEX) {
        this.updatePagination(Number(itemsCountPerPage), DEFAULT_PAGE_INDEX);
        return;
      }
      this.getVideos(search);
    } else if (
      prevProps.match.params.pageNumber !== pageNumber
      || prevProps.match.params.itemsCountPerPage !== itemsCountPerPage
    ) {
      this.getVideos(search);
    }
  }

  async getUserRole() {
    const userRole = await asyncLocalStorage.getItem(str.localStorage.role);
    this.setState({ userRole });
  }

  async getVideos(search) {
    const {
      getBusinessVideos,
      match: {
        params: { corporateGroupId, pageNumber, itemsCountPerPage },
      },
    } = this.props;

    if (!search) {
      return;
    }
    const params = getQueryParams(search);
    const revisedSearchQuery = `?${keys(params)
      .map((key) => {
        if (key === 'creators') {
          return params.creators
            .split(',')
            .map(creator => `creators=${creator.split(';')[1]}`)
            .join('&');
        }
        return `${key}=${params[key]}`;
      })
      .join('&')}`;

    await getBusinessVideos(corporateGroupId, revisedSearchQuery, itemsCountPerPage, pageNumber);
  }

  getBusinessVideoEditPath(videoId) {
    const {
      match: {
        params: { corporateGroupId },
      },
    } = this.props;
    return `/admin/business-video-edit/${corporateGroupId}/${videoId}`;
  }

  getMenuItemsForBusinessManager(videoId) {
    const { verified } = this.state;
    if (!verified) {
      return [];
    }

    return [
      {
        innerContent: 'Flag',
        url: '/',
      },
      {
        innerContent: 'Edit',
        url: this.getBusinessVideoEditPath(videoId),
      },
      {
        innerContent: 'Promote',
      },
      {
        innerContent: 'Delete',
        url: '/',
      },
    ];
  }

  getMenuItemsForNonBusinessManager(videoId) {
    return [
      {
        innerContent: 'Flag',
        url: '/',
      },
      {
        innerContent: 'Edit',
        url: this.getBusinessVideoEditPath(videoId),
      },
      {
        innerContent: 'Promote',
      },
      {
        innerContent: 'Download',
      },
      {
        innerContent: 'Delete',
        url: '/',
      },
    ];
  }

  updatePagination(itemsCountPerPage, pageNumber) {
    const {
      history,
      location: { search },
      match: {
        params: { corporateGroupId },
      },
    } = this.props;

    history.push({
      pathname: `/admin/business-videos/${corporateGroupId}/${itemsCountPerPage}/${pageNumber}`,
      search,
    });
  }

  updateIncludeDeletedContentStatus(includeDeletedContent) {
    const {
      history,
      location: { search },
      match: { url },
    } = this.props;

    const urlQuery = search.split('?')[1];

    const queries = urlQuery
      ? urlQuery.split('&').filter(query => !query.includes('includeDeletedContent'))
      : [];

    queries.push(`includeDeletedContent=${includeDeletedContent}`);

    const newUrlQuery = queries.join('&');

    history.push({
      pathname: url,
      search: newUrlQuery,
    });
  }

  openCreateGigModal() {
    const { VerificationStatus, BusinessSourceId } = this.props.businessDetails;
    if ([USER_ROLES.admin, USER_ROLES.saleAdmin].includes(this.state.userRole)) {
      if (BusinessSourceId == 1) {
        this.setState({ isCreateGigOpen: true });
      } else {
        this.createGigError();
      }
    } else if (VerificationStatus == 1 && BusinessSourceId == 1) {
      this.setState({ isCreateGigOpen: true });
    } else {
      this.createGigError();
    }
  }

  closeCreateGigModal() {
    this.setState({ isCreateGigOpen: false });
  }

  createGigError() {
    const { history } = this.props;
    let errorMessage = 'You cannot create a gig for this business until it is converted to a local Franki business.';
    let showButtons = false;
    const { VerificationStatus, CorporateGroupId, BusinessSourceId } = this.props.businessDetails;
    const userRole = localStorage.getItem('frankiRole');
    if (userRole == USER_ROLES.businessManager) {
      errorMessage += ' Please contact customersupport@befranki.com for any queries.';
      if (VerificationStatus != 1) {
        errorMessage = errorMessage.replace('it is', 'it is verified and ');
        showButtons = true;
      }
      if (BusinessSourceId == 1) {
        errorMessage = 'Your account needs to be verified for Gig Creation/Edit Profile. Please contact customersupport@befranki.com for any queries.';
      }
    }

    const result = (
      <>
        {errorMessage}
        {showButtons && (
          <div className="BusinessVideos__ButtonContainer VerifyButtonContainer">
            <button
              onClick={() => this.setState({ createGigError: '' })}
              className="button small"
              type="button"
            >
              Close
            </button>

            <button
              onClick={() => {
                history.push(`/business-phone-verification/${CorporateGroupId}`);
              }}
              type="button"
              className="button orange-theme small"
            >
              Verify Business
            </button>
          </div>
        )}
      </>
    );
    this.setState({
      createGigError: result,
    });
  }

  render() {
    const {
      auth,
      businessDetails,
      businessVideos: { Results, TotalItems, CurrentPage },
      businessVideosError,
      isFetchingBusinessDetails,
      isFetchingBusinessVideos,
      location: { search },
      match: {
        params: { itemsCountPerPage },
      },
    } = this.props;

    const { ctaContent, userRole, verified, isCreateGigOpen, createGigError } = this.state;
    const masterPageConfig = {
      auth,
      businessMobileTitle: 'Videos',
      businessSidebarData: businessDetails,
      businessSidebarOn: true,
    };

    const includeDeletedContent = new URLSearchParams(search).get('includeDeletedContent');

    const createGigAction = {
      buttonCallback: () => {
        this.openCreateGigModal();
      },
    };

    if (TotalItems > 0) {
      masterPageConfig.headerBreadcrumb = [
        // { name: `${USER_ROLES.businessManager == userRole ? 'all videos' : ''}` },
      ];
    }

    return (
      <>
        <Helmet title="Franki Admin: all videos" />

        {!isFetchingBusinessDetails && (
          <>
            <MasterPage {...masterPageConfig} flex>
              <Filters
                sorterItems={[
                  { label: 'date created', value: 'DateCreated' },
                  { label: 'likes', value: 'Likes' },
                  { label: 'comments', value: 'Comments' },
                ]}
                activeFilterNames={[
                  'creators',
                  'fromDate',
                  'orderBy',
                  'products',
                  'ratings',
                  'reviewType',
                  'search',
                  'toDate',
                ]}
                content={(
                  <>
                    {[USER_ROLES.admin, USER_ROLES.saleAdmin].indexOf(userRole) > -1 && (
                      <label className="SwitchContainer">
                        <span className="switch">
                          <input
                            checked={includeDeletedContent === 'true'}
                            onChange={(event) => {
                              this.updateIncludeDeletedContentStatus(event.target.checked);
                            }}
                            type="checkbox"
                          />
                          <span className="slider round" />
                        </span>
                        deleted content
                      </label>
                    )}

                    <button
                      type="button"
                      className="button small yellow-theme"
                      onClick={() => this.openCreateGigModal()}
                    >
                      Create
                    </button>
                  </>
                )}
              />

              <AsyncContent
                content={list => (
                  <Row cellSpacing={10} rowSpacing={10}>
                    {list.map(video => (
                      <Col lg={3} md={4} sm={6} xs={12} key={uuidv1()}>
                        <VideosCard
                          accessible={verified}
                          item={video}
                          // hamburgerMenuItems={
                          //   userRole !== USER_ROLES.businessManager
                          //     ? this.getMenuItemsForNonBusinessManager(video.id)
                          //     : this.getMenuItemsForBusinessManager(video.id)
                          // }
                        />
                      </Col>
                    ))}
                  </Row>
                )}
                emptyPageContent={
                  !search.includes('&') && (
                    <EmptyPage
                      buttonText="Create gig"
                      ctaContent={ctaContent}
                      description="A great way to get your first video reviews and bring new customers through your door is to run a GiG!"
                      img={NoVideosIMG}
                      title="No posts? No worries!"
                      {...createGigAction}
                    />
                  )
                }
                errorMessage={businessVideosError || createGigError}
                expectedData={Results}
                loading={isFetchingBusinessVideos || isFetchingBusinessDetails}
                onHandleCloseDialog={() => this.setState({ createGigError: '' })}
              />

              <Paginator
                onHandlePage={(...params) => this.updatePagination(...params)}
                onHandleShow={(...params) => this.updatePagination(...params)}
                pageIndex={CurrentPage}
                totalItems={TotalItems}
                itemsCountPerPage={Number(itemsCountPerPage)}
              />
            </MasterPage>

            <LightBox
              className="BusinessGigs__CreateGigLightBox"
              show={isCreateGigOpen}
              spacing={0}
              width={1318}
              height={715}
              style={{
                marginLeft: -650,
              }}
              onClose={() => {
                this.closeCreateGigModal();
              }}
            >
              <BusinessGigCreate {...this.props} />
            </LightBox>
          </>
        )}
      </>
    );
  }
}

BusinessesVideos.propTypes = {
  auth: bool.isRequired,
  businessDetails: BUSINESS_DETAILS_TYPE.isRequired,
  businessVideos: RESPONSE_LIST_OF_TYPE(VIDEO_TYPE).isRequired,
  businessVideosError: string.isRequired,
  getBusinessDetails: func.isRequired,
  businessGigsError: string.isRequired,
  getBusinessVideos: func.isRequired,
  history: HISTORY_TYPE.isRequired,
  isFetchingBusinessDetails: bool.isRequired,
  isFetchingBusinessVideos: bool.isRequired,
  location: LOCATION_TYPE.isRequired,
  match: ROUTER_MATCH_TYPE({
    corporateGroupId: string.isRequired,
    pageNumber: string,
    itemsCountPerPage: string,
  }).isRequired,
  totalItems: number,
};

BusinessesVideos.defaultProps = {
  totalItems: null,
};

const mapStateToProps = (state) => {
  const {
    businessDetails,
    businessVideos,
    businessVideosError,
    businessGigsError,
    isFetchingBusinessDetails,
    isFetchingBusinessVideos,
  } = state.businesses;

  return {
    businessDetails,
    businessVideos,
    businessVideosError,
    businessGigsError,
    isFetchingBusinessDetails,
    isFetchingBusinessVideos,
    totalItems: businessVideos ? businessVideos.TotalItems : 0,
  };
};

export default connect(mapStateToProps, {
  getBusinessDetails: getBusinessDetailsAction,
  getBusinessVideos: getBusinessVideosAction,
})(BusinessesVideos);
