import { number, shape, string } from 'prop-types';

const OPTIMIZED_PHOTO_TYPE = shape({
  BasePhotoId: number,
  LargeUrl: string,
  MediumUrl: string,
  Role: number,
  SmallUrl: string,
});

export default OPTIMIZED_PHOTO_TYPE;
