/* eslint-disable react/jsx-filename-extension */
import React, { memo } from 'react';
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer';

const RenderVideo = ({ videoDetails }) => {
  let result = (
    <VideoPlayer ratio="16:9" url={videoDetails.StreamingVideoUrl} />
  );
  if (!videoDetails.StreamingVideoUrl) {
    result = (
      <div className="player-wrapper">
        <VideoPlayer ratio="16:9" url={videoDetails.VideoUrl} />
      </div>
    );
  }
  return result;
};

export default memo(RenderVideo);
