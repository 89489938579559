import {
  CONTACT,
  CONTACT_ERROR,
  CONTACT_SUCCESS,
  GET_BILLING_HISTORY,
  GET_BILLING_HISTORY_ERROR,
  GET_BILLING_HISTORY_SUCCESS,
  GET_BUSINESS_CLAIM_DETAILS,
  GET_BUSINESS_CLAIM_DETAILS_ERROR,
  GET_BUSINESS_CLAIM_DETAILS_SUCCESS,
  GET_BUSINESS_DETAILS,
  GET_BUSINESS_DETAILS_ERROR,
  GET_BUSINESS_DETAILS_SUCCESS,
  GET_BUSINESS_GIGS,
  GET_BUSINESS_GIGS_ERROR,
  GET_BUSINESS_GIGS_SUCCESS,
  GET_BUSINESS_HOURS,
  GET_BUSINESS_HOURS_ERROR,
  GET_BUSINESS_HOURS_SUCCESS,
  GET_BUSINESS_NOMINATED_VIDEOS,
  GET_BUSINESS_NOMINATED_VIDEOS_ERROR,
  GET_BUSINESS_NOMINATED_VIDEOS_SUCCESS,
  GET_BUSINESS_PROFILE,
  GET_BUSINESS_PROFILE_ERROR,
  GET_BUSINESS_PROFILE_SUCCESS,
  GET_BUSINESS_SOCIAL_MEDIA,
  GET_BUSINESS_SOCIAL_MEDIA_ERROR,
  GET_BUSINESS_SOCIAL_MEDIA_SUCCESS,
  GET_BUSINESS_USERS,
  GET_BUSINESS_USERS_ERROR,
  GET_BUSINESS_USERS_SUCCESS,
  GET_BUSINESS_VIDEO_DETAILS,
  GET_BUSINESS_VIDEO_DETAILS_ERROR,
  GET_BUSINESS_VIDEO_DETAILS_SUCCESS,
  GET_BUSINESS_VIDEOS,
  GET_BUSINESS_VIDEOS_ERROR,
  GET_BUSINESS_VIDEOS_SUCCESS,
  GET_BUSINESSES,
  GET_BUSINESSES_ERROR,
  GET_BUSINESSES_SUCCESS,
  GET_NOMINATED_VIDEOS_ERROR,
  GET_NOMINATED_VIDEOS_SUCCESS,
  SEARCH_BUSINESS_VIDEOS,
  UPDATE_NOMINATED_VIDEOS,
} from '../actions/businesses';

const initialState = {
  businesses: {},
  businessesError: '',
  businessesToken: null,
  isFetchingBusinesses: false,

  businessClaimDetails: {},
  businessClaimDetailsError: '',
  isFetchingBusinessClaimDetails: false,

  businessDetails: {},
  businessDetailsError: '',
  isFetchingBusinessDetails: false,

  businessGigs: [],
  businessGigsError: '',
  isFetchingBusinessGigs: false,

  businessBillings: [],
  businessBillingsError: '',
  isFetchingBillingList: false,

  businessHours: [],
  businessHoursError: '',
  isFetchingBusinessHours: true,

  businessProfile: {},
  businessProfileError: '',
  isFetchingBusinessProfile: false,

  businessSocialMedia: {},
  businessSocialMediaError: '',
  isFetchingBusinessSocialMedia: false,

  businessUsers: {},
  businessUsersError: '',
  isFetchingBusinessUsers: false,

  businessVideos: {},
  businessVideosError: '',
  isFetchingBusinessVideos: false,

  contactErrorMessage: '',
  contactSuccessMessageMessage: '',
  isSendingContactMessage: false,

  gigDetails: {},
  gigDetailsError: '',

  videoDetails: {},
  videoDetailsError: '',
  isFetchingVideoDetails: false,

  NominatedList: [],
  NominatedListError: '',
  isFetchingNominatedVideos: true,

  businessNominatedVideos: [],
  businessNominatedVideosError: '',
  isFetchingBusinessNominatedVideos: true,
};

export default function (state = initialState, { type, payload, token }) {
  switch (type) {
    case CONTACT: {
      return {
        ...state,
        contactErrorMessage: '',
        contactSuccessMessageMessage: '',
        isSendingContactMessage: true,
      };
    }

    case CONTACT_SUCCESS: {
      return {
        ...state,
        contactErrorMessage: '',
        contactSuccessMessage: payload,
        isSendingContactMessage: false,
      };
    }

    case CONTACT_ERROR: {
      return {
        ...state,
        contactErrorMessage: payload || 'Error sending message',
        contactSuccessMessage: '',
        isSendingContactMessage: false,
      };
    }

    case GET_BUSINESSES: {
      return {
        ...state,
        businesses: {},
        businessesError: '',
        businessesToken: token,
        isFetchingBusinesses: true,
      };
    }

    case GET_BUSINESSES_SUCCESS: {
      if (token !== state.businessesToken) {
        return state;
      }
      return {
        ...state,
        businesses: payload,
        isFetchingBusinesses: false,
      };
    }

    case GET_BUSINESSES_ERROR: {
      if (token !== state.businessesToken) {
        return state;
      }
      return {
        ...state,
        businessesError: payload.error || 'Error getting businesses',
        isFetchingBusinesses: false,
      };
    }

    case GET_BUSINESS_DETAILS: {
      return {
        ...state,
        businessDetails: {},
        businessDetailsError: '',
        isFetchingBusinessDetails: true,
      };
    }

    case GET_BUSINESS_DETAILS_SUCCESS: {
      return {
        ...state,
        businessDetails: payload.data || {},
        isFetchingBusinessDetails: false,
      };
    }

    case GET_BUSINESS_DETAILS_ERROR: {
      return {
        ...state,
        businessDetailsError: payload.error || "Error getting business's details",
        isFetchingBusinessDetails: false,
      };
    }

    case GET_BUSINESS_HOURS: {
      return {
        ...state,
        businessHours: [],
        businessHoursError: '',
        isFetchingBusinessHours: true,
      };
    }

    case GET_BUSINESS_HOURS_SUCCESS: {
      return {
        ...state,
        businessHours: payload.Data || [],
        isFetchingBusinessHours: false,
      };
    }

    case GET_BUSINESS_HOURS_ERROR: {
      return {
        ...state,
        businessHoursError: payload.error || "Error getting business's profile",
        isFetchingBusinessHours: false,
      };
    }

    case GET_BUSINESS_PROFILE: {
      return {
        ...state,
        businessProfile: {},
        businessProfileError: '',
        isFetchingBusinessProfile: true,
      };
    }

    case GET_BUSINESS_PROFILE_SUCCESS: {
      return {
        ...state,
        businessProfile: payload.Data || {},
        isFetchingBusinessProfile: false,
      };
    }

    case GET_BUSINESS_PROFILE_ERROR: {
      return {
        ...state,
        businessProfileError: payload.error || "Error getting business's profile",
        isFetchingBusinessProfile: false,
      };
    }

    case GET_BUSINESS_SOCIAL_MEDIA: {
      return {
        ...state,
        businessSocialMedia: {},
        businessSocialMediaError: '',
        isFetchingBusinessSocialMedia: true,
      };
    }

    case GET_BUSINESS_SOCIAL_MEDIA_SUCCESS: {
      return {
        ...state,
        businessSocialMedia: payload.data || {},
        isFetchingBusinessSocialMedia: false,
      };
    }

    case GET_BUSINESS_SOCIAL_MEDIA_ERROR: {
      return {
        ...state,
        businessSocialMediaError: payload.error || "Error getting business's social media",
        isFetchingBusinessSocialMedia: false,
      };
    }

    case GET_BUSINESS_CLAIM_DETAILS: {
      return {
        ...state,
        businessClaimDetails: {},
        businessClaimDetailsError: '',
        isFetchingBusinessClaimDetails: true,
      };
    }

    case GET_BUSINESS_CLAIM_DETAILS_SUCCESS: {
      return {
        ...state,
        businessClaimDetails: payload || {},
        isFetchingBusinessClaimDetails: false,
      };
    }

    case GET_BUSINESS_CLAIM_DETAILS_ERROR: {
      return {
        ...state,
        businessClaimDetailsError: payload.error || "Error getting business's claim details",
        isFetchingBusinessClaimDetails: false,
      };
    }

    case GET_BUSINESS_USERS: {
      return {
        ...state,
        businessUsers: {},
        businessUsersError: '',
        isFetchingBusinessUsers: true,
      };
    }

    case GET_BUSINESS_USERS_SUCCESS: {
      return {
        ...state,
        businessUsers: payload.data || {},
        isFetchingBusinessUsers: false,
      };
    }

    case GET_BUSINESS_USERS_ERROR: {
      return {
        ...state,
        businessUsersError: payload.error || "Error getting business's videos",
        isFetchingBusinessUsers: false,
      };
    }

    case GET_BUSINESS_VIDEOS: {
      return {
        ...state,
        businessVideos: {},
        businessVideosError: '',
        isFetchingBusinessVideos: true,
      };
    }

    case GET_BUSINESS_VIDEOS_SUCCESS: {
      return {
        ...state,
        businessVideos: payload.data || {},
        isFetchingBusinessVideos: false,
      };
    }

    case GET_BUSINESS_VIDEOS_ERROR: {
      return {
        ...state,
        businessVideosError: payload || "Error getting business's videos",
        isFetchingBusinessVideos: false,
      };
    }

    case GET_BUSINESS_VIDEO_DETAILS: {
      return {
        ...state,
        isFetchingVideoDetails: true,
        videoDetails: {},
        videoDetailsError: '',
      };
    }

    case GET_BUSINESS_VIDEO_DETAILS_SUCCESS: {
      return {
        ...state,
        isFetchingVideoDetails: false,
        videoDetails: payload.data || {},
      };
    }

    case GET_BUSINESS_VIDEO_DETAILS_ERROR: {
      return {
        ...state,
        isFetchingVideoDetails: false,
        videoDetailsError: payload.error || "Error getting business video's details",
      };
    }

    case GET_BUSINESS_GIGS: {
      return {
        ...state,
        businessGigs: [],
        businessGigsError: '',
        isFetchingBusinessGigs: true,
      };
    }

    case GET_BUSINESS_GIGS_SUCCESS: {
      return {
        ...state,
        businessGigs: payload.data || {},
        isFetchingBusinessGigs: false,
      };
    }

    case GET_BUSINESS_GIGS_ERROR: {
      return {
        ...state,
        businessGigsError: payload.error || "Error getting business's gigs",
        isFetchingBusinessGigs: false,
      };
    }

    case GET_BILLING_HISTORY: {
      return {
        ...state,
        businessBillings: [],
        businessBillingsError: '',
        isFetchingBillingList: true,
      };
    }

    case GET_BILLING_HISTORY_SUCCESS: {
      return {
        ...state,
        businessBillings: payload || {},
        isFetchingBillingList: false,
        businessBillingsError: '',
      };
    }

    case GET_BILLING_HISTORY_ERROR: {
      return {
        ...state,
        businessBillingsError: payload,
        isFetchingBillingList: false,
      };
    }

    case GET_BUSINESS_NOMINATED_VIDEOS: {
      return {
        ...state,
        isFetchingBusinessNominatedVideos: true,
        businessNominatedVideosError: '',
        businessNominatedVideos: [],
      };
    }

    case GET_BUSINESS_NOMINATED_VIDEOS_SUCCESS: {
      return {
        ...state,
        businessNominatedVideos: payload || [],
        isFetchingBusinessNominatedVideos: false,
      };
    }

    case GET_BUSINESS_NOMINATED_VIDEOS_ERROR: {
      return {
        ...state,
        businessNominatedVideosError: payload,
        isFetchingBusinessNominatedVideos: false,
      };
    }

    case GET_NOMINATED_VIDEOS_SUCCESS: {
      return {
        ...state,
        isFetchingNominatedVideos: false,
        NominatedList: payload.Data || [],
      };
    }

    case GET_NOMINATED_VIDEOS_ERROR: {
      return {
        ...state,
        isFetchingNominatedVideos: false,
        NominatedListError: payload,
      };
    }

    case SEARCH_BUSINESS_VIDEOS: {
      return {
        ...state,
        details: {
          ...state.details,
          Videos: payload,
        },
      };
    }

    case UPDATE_NOMINATED_VIDEOS: {
      return {
        ...state,
        NominatedList: state.NominatedList.filter(n => n.id.toString() === payload.tostring()),
      };
    }

    default:
      return state;
  }
}
