import { arrayOf, bool, number, shape, string } from 'prop-types';

import OPTIMIZED_PHOTO_TYPE from './optimizedPhoto';
import POSTER_PROFILE from './posterProfile';
import PRODUCT_TYPE from './product';

const VIDEO_TYPE = shape({
  CommentCount: number,
  ContentId: number,
  DurationSeconds: number,
  FullResolutionUrl: string,
  GigId: number,
  Height: number,
  IsDeleted: bool,
  IsFeatured: bool,
  IsSponsored: bool,
  Key: string,
  LikeCount: number,
  MediaType: number,
  OptimizedPhotos: arrayOf(OPTIMIZED_PHOTO_TYPE),
  PhotoDescription: string,
  PhotoId: number,
  PostBody: string,
  Posted: string,
  PosterProfile: POSTER_PROFILE,
  PrizeAwardedAmount: number,
  Products: arrayOf(PRODUCT_TYPE),
  Rating: number,
  TaggedUsers: arrayOf(Number),
  VideoUrl: string,
  Width: number,
});

export default VIDEO_TYPE;
