import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getUserDetails as getUserDetailsAction, updateUserAdmin } from '../../../actions/users';
import CreateOrEditUser from '../CreateOrEdit';

class UserEdit extends React.Component {
  componentDidMount() {
    const { getUserDetails, match } = this.props;
    getUserDetails(match.params.username);
  }

  render() {
    const { auth, userProfiles } = this.props;

    return (
      <CreateOrEditUser
        auth={auth}
        breadcrumbs={[{ name: 'all users' }, { name: 'edit user', bold: true }]}
        callBackOnSubmit={updateUserAdmin}
        className="EditUser"
        submitButtonCaption="Save Changes"
        pageTitle="Edit User"
        userProfiles={userProfiles}
      />
    );
  }
}

UserEdit.propTypes = {
  auth: bool.isRequired,
  getUserDetails: func.isRequired,
  match: instanceOf(Object).isRequired,
  userProfiles: instanceOf(Object),
};

UserEdit.defaultProps = {
  userProfiles: {},
};

const mapStateToProps = (state) => ({
  userProfiles: state.users.userProfiles,
});

export default connect(mapStateToProps, { getUserDetails: getUserDetailsAction })(UserEdit);
