import { isEmpty, keys } from 'lodash';
import { bool, func, number, string } from 'prop-types';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import uuidv1 from 'uuid/v1';

import { getFlaggedContents as getFlaggedContentsAction } from '../../actions/flaggedContent';
import AsyncContent from '../../components/AsyncContent';
import Filters from '../../components/Filters';
import { Col, Row } from '../../components/Grid';
import MasterPage from '../../components/MasterPage';
import Paginator from '../../components/Paginator';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../config/pagination';
import FLAGGED_CONTENT_TYPE from '../../types/flaggedContent';
import HISTORY_TYPE from '../../types/history';
import LOCATION_TYPE from '../../types/location';
import RESPONSE_LIST_OF_TYPE from '../../types/responseListOf';
import ROUTER_MATCH_TYPE from '../../types/routerMatch';
import FlaggedContentCard from './FlaggedContentCard';

/* global localStorage document window */

const popStateListener = () => {
  const el = document.querySelectorAll('.pagination > .active a');
  if (el.length > 0) {
    const txt = Number(el[0].textContent);
    if (txt === 1) window.history.go(-1);
  }
};

const getQueryParams = (searchQueryParams) => {
  const params = {};
  const query = new URLSearchParams(searchQueryParams);

  ['search', 'orderBy', 'businessName', 'contentStatusIds'].forEach((term) => {
    const termValue = query.get(term);
    if (termValue) {
      params[term] = termValue;
    }
  });
  return params;
};

class FlaggedContent extends Component {
  constructor(props) {
    super(props);
    this.userRole = localStorage.getItem('frankiRole');
  }

  componentDidMount() {
    const {
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;

    if (!pageNumber || !itemsCountPerPage) {
      this.updatePagination(
        Number(itemsCountPerPage) || DEFAULT_PAGE_SIZE,
        Number(pageNumber) || DEFAULT_PAGE_INDEX,
      );
    }

    this.getFlaggedContents();
    window.addEventListener('popstate', popStateListener);
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search },
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;

    if (prevProps.location.search !== search) {
      if (Number(pageNumber) !== DEFAULT_PAGE_INDEX) {
        this.updatePagination(Number(itemsCountPerPage), DEFAULT_PAGE_INDEX);
      }
      this.getFlaggedContents();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', popStateListener);
  }

  getFlaggedContents = async (forcedPageNumber) => {
    const {
      getFlaggedContent,
      location: { search },
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;
    const params = getQueryParams(search);

    if (isEmpty(params) || !params.orderBy) {
      return;
    }
    let revisedSearchQuery = `?pageSize=${itemsCountPerPage}&pageNumber=${
      forcedPageNumber || pageNumber
    }`;
    revisedSearchQuery += `&${keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&')}`;

    await getFlaggedContent(revisedSearchQuery, {
      ...params,
      pageNumber: forcedPageNumber || pageNumber,
      itemsCountPerPage,
    });
  };

  updatePagination(itemsCountPerPage, pageNumber) {
    const {
      location: { search },
      history,
    } = this.props;
    history.push({
      pathname: `/admin/flagged-contents/${itemsCountPerPage}/${pageNumber}`,
      search,
    });
    // setTimeout(() => this.getFlaggedContents(pageNumber));
  }

  render() {
    const {
      auth,
      flaggedContents,
      flaggedContentsError,
      isFetchingFlaggedContents,
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;

    const masterPageConfig = {
      auth,
      headerBreadcrumb: [{ name: 'Home', url: '/' }, { name: 'Flagged Content' }],
    };

    return (
      <>
        <Helmet title="Franki Admin: flagged content" />

        <MasterPage {...masterPageConfig}>
          <Filters
            activeFilterNames={['orderBy', 'search', 'businessName', 'contentStatusIds']}
            modalHeight={280}
            sorterItems={[
              { label: 'recently flagged', value: 'LastFlaggedOn' },
              { label: 'likes', value: 'LikeCount' },
              { label: 'comments', value: 'CommentCount' },
            ]}
          />
          {flaggedContents.TotalItems > 0 && (
            <Paginator
              isTopPaginator
              itemsCountPerPage={Number(itemsCountPerPage)}
              onHandlePage={(...params) => this.updatePagination(...params)}
              onHandleShow={(...params) => this.updatePagination(...params)}
              pageIndex={Number(pageNumber)}
              totalItems={flaggedContents.TotalItems}
            />
          )}

          <AsyncContent
            content={list => (
              <Row cellSpacing={10} rowSpacing={10} verticalAlign="stretch">
                {list.map((item) => {
                  const { CorporateGroup, ...rest } = item;
                  return (
                    <Col lg={4} md={6} sm={12} xs={12} key={uuidv1()}>
                      <FlaggedContentCard
                        getFlaggedContents={this.getFlaggedContents}
                        item={{ ...rest }}
                        CorporateGroup={CorporateGroup || {}}
                      />
                    </Col>
                  );
                })}
              </Row>
            )}
            errorMessage={flaggedContentsError}
            expectedData={flaggedContents.Results}
            loading={isFetchingFlaggedContents}
          />
        </MasterPage>
      </>
    );
  }
}

FlaggedContent.propTypes = {
  auth: bool.isRequired,
  flaggedContents: RESPONSE_LIST_OF_TYPE(FLAGGED_CONTENT_TYPE).isRequired,
  flaggedContentsError: string.isRequired,
  getFlaggedContent: func.isRequired,
  history: HISTORY_TYPE.isRequired,
  isFetchingFlaggedContents: bool.isRequired,
  location: LOCATION_TYPE.isRequired,
  match: ROUTER_MATCH_TYPE({ pageNumber: string, itemsCountPerPage: string }).isRequired,
  totalItems: number,
};

FlaggedContent.defaultProps = {
  totalItems: 0,
};

const mapStateToProps = (state) => {
  const { flaggedContents, flaggedContentsError, isFetchingFlaggedContents } = state.flaggedContent;
  return {
    flaggedContents,
    flaggedContentsError,
    isFetchingFlaggedContents,
    totalItems: flaggedContents ? flaggedContents.TotalItems : 0,
  };
};

export default connect(mapStateToProps, {
  getFlaggedContent: getFlaggedContentsAction,
})(FlaggedContent);
