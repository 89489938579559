import './index.sass';

import { bool } from 'prop-types';
import React, { useState } from 'react';

import HamburgerMenu from '../../HamburgerMenu';
import HamburgerMenuIcon from '../../HamburgerMenu/HamburgerMenuIcon';
import { logout } from '../../../actions/auth';

/* global localStorage window */

const UnloggedHeader = ({ global }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(localStorage.getItem('frankiToken'));

  const onLogout = () => {
    logout().subscribe(() => {
      localStorage.removeItem('frankiRole');
      localStorage.removeItem('frankiToken');
      localStorage.removeItem('frankiUser');
      localStorage.removeItem('frankiName');
      setIsUserLoggedIn(false);
      window.location.reload();
    });
  };

  return (
    <nav className="Header__Nav">
      <ul>
        {global && (
          <>
            <li>
              <a
                className="UnloggedHeader__GlobalMenu"
                href="https://www.befranki.com/explore"
                type="button"
                target="_blank"
              >
                <i className="icon-users" />
                <span>Explore</span>
              </a>
            </li>

            <li>
              <a
                className="UnloggedHeader__GlobalMenu"
                href="https://www.befranki.com/share"
                type="button"
                target="_blank"
              >
                <i className="icon-users" />
                <span>Share</span>
              </a>
            </li>

            <li>
              <a
                className="UnloggedHeader__GlobalMenu"
                href="https://www.befranki.com/business"
                type="button"
                target="_blank"
              >
                <i className="icon-users" />
                <span>For Business</span>
              </a>
            </li>

            {isUserLoggedIn && (
              <li>
                <HamburgerMenu
                  className="Header__User no-margin-left"
                  icon="icon-user"
                  text={localStorage.getItem('frankiName') || ''}
                  textClass="UnloggedHeader__GlobalMenu"
                  menuItems={[
                    {
                      innerContent: (
                        <button onClick={() => onLogout()} type="button">
                          <HamburgerMenuIcon icon="icon-signout" />
                          logout
                        </button>
                      ),
                    },
                  ]}
                />
              </li>
            )}
          </>
        )}

        {!global && (
          <li>
            <a href="https://befranki.com/faq" target="_blank">
              Need help?
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

UnloggedHeader.propTypes = {
  global: bool.isRequired,
};

export default UnloggedHeader;
