import './index.sass';

import { isEmpty, keys } from 'lodash';
import { arrayOf, bool, func, number, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import uuidv1 from 'uuid/v1';

import {
  addContentToPromotions,
  getPromotions as getPromotionsAction,
} from '../../../actions/promotions';
import AsyncContent from '../../../components/AsyncContent';
import Filters from '../../../components/Filters';
import { Col, Row } from '../../../components/Grid';
import LightBox from '../../../components/LightBox';
import MasterPage from '../../../components/MasterPage';
import NotFound from '../../../components/NotFound';
import Paginator from '../../../components/Paginator';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../config/pagination';
import HISTORY_TYPE from '../../../types/history';
import LOCATION_TYPE from '../../../types/location';
import PROMOTION_TYPE from '../../../types/promotion';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';
import PromotionCard from './PromotionCard';

const ORDER_BY_OPTIONS = [
  { label: 'name (a-z)', value: 'Name' },
  { label: 'date created', value: 'dateCreated' },
];

const getQueryParams = (searchQueryParams) => {
  const params = {};

  const query = new URLSearchParams(searchQueryParams);

  ['search', 'orderBy'].forEach((term) => {
    const termValue = query.get(term);
    if (termValue) {
      params[term] = termValue;
    }
  });

  return params;
};

class Promotions extends React.Component {
  state = {
    promoteError: null,
    selectedPromotion: null,
  };

  popStateListener(event) {
    const el = document.querySelectorAll('.pagination > .active a');
    if (el.length > 0) {
      const txt = Number(el[0].textContent);
      if (txt === 1) window.history.go(-1);
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;

    if (!pageNumber || !itemsCountPerPage) {
      this.updatePagination(
        Number(itemsCountPerPage) || DEFAULT_PAGE_SIZE,
        Number(pageNumber) || DEFAULT_PAGE_INDEX,
      );
    }

    this.getPromotions();
    window.addEventListener('popstate', this.popStateListener);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.popStateListener);
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search },
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;

    if (prevProps.location.search !== search) {
      if (Number(pageNumber) !== DEFAULT_PAGE_INDEX) {
        this.updatePagination(Number(itemsCountPerPage), DEFAULT_PAGE_INDEX);
      }
      this.getPromotions();
    }
  }

  onHandlePromotionSelect(value) {
    this.setState({ selectedPromotion: value });
  }

  async getPromotions() {
    const {
      getPromotions,
      location: { search },
    } = this.props;
    const params = getQueryParams(search);
    if (isEmpty(params)) {
      return;
    }
    const revisedSearchQuery = `?${keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&')}`;
    await getPromotions(revisedSearchQuery);
  }

  getBasePath() {
    const {
      match: { url },
    } = this.props;
    return url.split('/').slice(0, 2).join('/');
  }

  gotoPromotionDetails(promotionId) {
    const { history } = this.props;
    history.push({
      pathname: `/admin/promotion-details/${promotionId}`,
    });
  }

  async promote() {
    const {
      history,
      location: { search },
      match: {
        params: { corporateGroupId, contentKey, videoId },
      },
    } = this.props;
    const { selectedPromotion } = this.state;
    this.setState({ promoteError: null });
    const result = await addContentToPromotions({
      PromotionId: selectedPromotion.PromotionId,
      ContentIds: [Number(videoId)],
    });

    if (!result.Success) {
      this.setState({ promoteError: result.Message, selectedPromotion: null });
    } else {
      history.push({
        pathname: `/admin/business-video-details/${corporateGroupId}/${contentKey}`,
        search,
      });
    }
  }

  updatePagination(itemsCountPerPage, pageNumber) {
    const {
      history,
      location: { search },
      match: {
        params: { corporateGroupId, contentKey, videoId },
        url,
      },
    } = this.props;

    const pathname = url.includes('/admin/business-video-promote/')
      ? `/admin/business-video-promote/${corporateGroupId}/${contentKey}/${videoId}/${itemsCountPerPage}/${pageNumber}`
      : `/admin/promoted-content/${itemsCountPerPage}/${pageNumber}`;

    history.push({ pathname, search });
  }

  render() {
    const {
      auth,
      isFetchingPromotions,
      match: {
        params: { corporateGroupId, contentKey, pageNumber, itemsCountPerPage },
        url,
      },
      promotions,
      promotionsError,
    } = this.props;
    const { promoteError, selectedPromotion } = this.state;

    const isPromotingVideo = url.includes('admin/business-video-promote/');

    const limits = [(pageNumber - 1) * itemsCountPerPage, pageNumber * itemsCountPerPage];

    const masterPageConfig = isPromotingVideo
      ? {
        auth,
        headerBreadcrumb: [{ name: 'Select a promotion to add the content to' }],
        headerButtons: (
          <Link
            to={`/admin/business-video-details/${corporateGroupId}/${contentKey}`}
            className="button small orange-theme PromotedContent__Close"
          >
            Cancel
          </Link>
        ),
      }
      : {
        auth,
        headerBreadcrumb: [{ name: 'Home', url: '/' }, { name: 'Promoted Content' }],
      };

    return (
      <>
        <Helmet title="Franki Admin: Promoted Content" />

        <MasterPage {...masterPageConfig}>
          <>
            <Filters
              activeFilterNames={['orderBy', 'search']}
              content={
                isPromotingVideo ? null : (
                  <Link
                    to="/admin/create-promotion"
                    className="button small yellow-theme filterBtn"
                  >
                    CREATE A PROMOTION
                  </Link>
                )
              }
              modalHeight={180}
              sorterItems={ORDER_BY_OPTIONS}
            />

            {!isEmpty(promotions) && (
              <Paginator
                isTopPaginator
                itemsCountPerPage={Number(itemsCountPerPage)}
                onHandlePage={(...params) => this.updatePagination(...params)}
                onHandleShow={(...params) => this.updatePagination(...params)}
                pageIndex={Number(pageNumber)}
                totalItems={promotions.length}
              />
            )}

            <AsyncContent
              content={list => (
                <Row cellSpacing={7} rowSpacing={7} className="PromotedContent__Container">
                  {list.map(item => (
                    <Col lg={3} md={4} sm={6} xs={12} key={uuidv1()}>
                      <div className="PromotedContent__Card">
                        <button
                          onClick={() => (isPromotingVideo
                            ? this.setState({ selectedPromotion: item })
                            : this.gotoPromotionDetails(item.PromotionId))
                          }
                          type="button"
                        />
                        <PromotionCard item={item} />
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
              emptyPageContent={(
                <NotFound
                  description="We cannot find anything based on your search criteria"
                  title="No results"
                />
              )}
              errorMessage={promotionsError || promoteError}
              expectedData={promotions.slice(...limits)}
              loading={isFetchingPromotions}
            />

            <LightBox
              style={{
                width: 500,
                marginLeft: -250,
                marginTop: -250,
              }}
              onClose={() => this.setState({ selectedPromotion: null })}
              show={!!selectedPromotion}
            >
              {selectedPromotion ? (
                <div className="PromoteVideo__Modal PromotionCard">
                  <div className="PromotionCard__Content">
                    <h2 className="PromotionCard__Title">
                      Are you sure you want to add this video to
                      {` "${selectedPromotion.PromotionTitle}"?`}
                    </h2>

                    {selectedPromotion.Photo && selectedPromotion.Photo.FullResolutionUrl && (
                      <figure>
                        <img
                          alt={selectedPromotion.PromotionTitle}
                          src={selectedPromotion.Photo.FullResolutionUrl}
                        />
                      </figure>
                    )}

                    <address className="PromotionCard__CityNames">
                      {selectedPromotion.CityNames}
                    </address>
                  </div>

                  <ul className="BusinessVideoDetails__Modal__Actions">
                    <li>
                      <button
                        className="button small red-theme"
                        onClick={() => this.promote()}
                        type="button"
                      >
                        Add video
                      </button>
                    </li>

                    <li>
                      <button
                        className="button small"
                        onClick={() => this.setState({ selectedPromotion: null })}
                        type="button"
                      >
                        Cancel
                      </button>
                    </li>
                  </ul>
                </div>
              ) : (
                <></>
              )}
            </LightBox>
          </>
        </MasterPage>
      </>
    );
  }
}

Promotions.propTypes = {
  auth: bool.isRequired,
  getPromotions: func.isRequired,
  history: HISTORY_TYPE.isRequired,
  isFetchingPromotions: bool.isRequired,
  location: LOCATION_TYPE.isRequired,
  match: ROUTER_MATCH_TYPE({
    corporateGroupId: string,
    contentKey: string,
    itemsCountPerPage: string,
    pageNumber: string,
    videoId: string,
  }).isRequired,
  promotions: arrayOf(PROMOTION_TYPE).isRequired,
  promotionsError: string.isRequired,
  totalItems: number,
};

Promotions.defaultProps = {
  totalItems: 0,
};

const mapStateToProps = (state) => {
  const { promotions, promotionsError, isFetchingPromotions } = state.promotions;
  return {
    isFetchingPromotions,
    promotions,
    promotionsError,
    totalItems: promotions.Data ? promotions.Data.length : 0,
  };
};

export default connect(mapStateToProps, { getPromotions: getPromotionsAction })(Promotions);
