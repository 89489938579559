import './index.sass';
import 'react-datepicker/dist/react-datepicker.css';

import { setHours, setMinutes } from 'date-fns';
import ProductsTagsList from 'friendly-words';
import { isEmpty } from 'lodash';
import { bool, func, string } from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getBusinessDetails, getBusinessVideos } from '../../../../actions/businesses';
import FileUpload from '../../../../components/FileUpload';
import MasterPage from '../../../../components/MasterPage';
import TagsInput from '../../../../components/TagsInput';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../../config/pagination';
import { BUSINESS_DETAILS_TYPE } from '../../../../types/business';
import ROUTER_MATCH_TYPE from '../../../../types/routerMatch';

class BusinessVideoForm extends React.Component {
  state = {
    editing: false,
    form: {
      video: '',
      caption: '',
      products: [],
      schedulePost: 'asap',
      schedulePostDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      setExpirationDate: false,
      expirationDate: new Date(new Date().setDate(new Date().getDate() + 7)),
    },
  };

  baseState = this.state;

  componentDidMount() {
    const { match } = this.props;

    if (match.url.match(/edit/)) {
      this.setState({ editing: true });
      this.editMode();
    } else {
      this.getBusinessDetails();
    }
  }

  componentDidUpdate(prevProps) {
    const { match, businessDetails } = this.props;

    if (prevProps.match.params.corporateGroupId !== this.getBusinessID()) {
      this.getBusinessDetails();
    }

    if (match.url.match(/edit/) && businessDetails) {
      if (
        prevProps.businessDetails.Videos !== businessDetails.Videos ||
        prevProps.businessDetails !== businessDetails
      ) {
        this.editMode();
      }
    }
  }

  getBusinessID = () => {
    const {
      match: {
        params: { corporateGroupId },
      },
    } = this.props;
    return corporateGroupId;
  };

  getBusinessDetails = async () => {
    const businessId = this.getBusinessID();
    const { businessDetails, getBusinessDetails: getBusinessDetailsAction } = this.props;

    const idChanged =
      businessDetails.CorporateGroupId &&
      businessDetails.CorporateGroupId !== Number(this.getBusinessID());

    if (isEmpty(businessDetails) || idChanged) {
      await getBusinessDetailsAction(businessId);
    }
  };

  onHandleChange = (name, value) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  };

  editMode = async () => {
    const { match, businessDetails, getBusinessVideos: getBusinessVideosAction } = this.props;

    const businessId = this.getBusinessID();

    if (isEmpty(businessDetails)) {
      this.getBusinessDetails();
    } else if (!businessDetails.Videos) {
      await getBusinessVideosAction(businessId);
    } else if (businessDetails.Videos) {
      const getVideo = businessDetails.Videos.Results.find(
        (item) => item.id === match.params.videoId,
      );

      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          ...(getVideo
            ? {
                video: getVideo.video || this.baseState.form.video,
                caption: getVideo.caption || this.baseState.form.caption,
                products: getVideo.products || this.baseState.form.products,
                schedulePost: getVideo.schedulePost || this.baseState.form.schedulePost,
                schedulePostDate:
                  new Date(getVideo.schedulePostDate) || this.baseState.form.schedulePostDate,
                setExpirationDate:
                  getVideo.setExpirationDate || this.baseState.form.setExpirationDate,
                expirationDate:
                  new Date(getVideo.expirationDate) || this.baseState.form.expirationDate,
              }
            : {}),
        },
      }));
    }
  };

  onHandleSubmit = () => {};

  render() {
    const { businessDetails, match, auth } = this.props;
    const {
      editing,
      form: {
        video,
        caption,
        products,
        schedulePost,
        schedulePostDate,
        setExpirationDate,
        expirationDate,
      },
    } = this.state;

    const masterPageConfig = {
      auth,
      businessSidebarOn: true,
      businessSidebarData: businessDetails,
      headerBreadcrumb: [
        {
          name: 'all videos',
          url: `/admin/business-videos/${() =>
            this.getBusinessID()}/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`,
        },
        { name: 'new video' },
      ],
    };

    if (editing) {
      masterPageConfig.headerBreadcrumb = [
        {
          name: 'all videos',
          url: `/admin/business-videos/${() =>
            this.getBusinessID()}/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`,
        },
        {
          name: 'video details',
          url: `/admin/business-video-details/${() => this.getBusinessID()}/${
            match.params.videoId
          }`,
        },
        { name: 'edit video' },
      ];
    }

    let currentMinutes = 0;
    let currentHours = 0;

    if (new Date().getDay() === schedulePostDate.getDay()) {
      currentMinutes = new Date().getMinutes();
      currentHours = new Date().getHours();
    }

    return (
      <>
        <Helmet title={`Franki Admin: ${editing ? ' edit video' : ' create video'}`} />

        <MasterPage {...masterPageConfig}>
          <form method="POST" onSubmit={(e) => this.onHandleSubmit(e)}>
            <div className="videoForm">
              <div className="videoForm-left">
                <fieldset>
                  <legend>Video</legend>
                  <span className="label">
                    Sit explicabo or voluptatem for amet accusantium magna do.
                  </span>
                  <FileUpload
                    fullWidth
                    height={330}
                    id="txtFile"
                    maxFileSize={100}
                    name="video"
                    onChange={this.onHandleChange}
                    placeholder="upload video"
                    required
                    type="video"
                    value={video}
                  />
                </fieldset>
              </div>

              <div className="videoForm-right">
                <fieldset>
                  <legend>Caption</legend>
                  <label htmlFor="txtCaption">Get everybody’s attention with a cool caption</label>
                  <textarea
                    id="txtCaption"
                    className="full"
                    name="caption"
                    value={caption}
                    placeholder="insert a cool caption"
                    rows="3"
                    onChange={({ target }) => this.onHandleChange(target.name, target.value)}
                  />
                </fieldset>

                <fieldset>
                  <legend>Products</legend>
                  <label htmlFor="txtProducts">
                    Sit explicabo or voluptatem for amet accusantium.
                  </label>
                  <TagsInput
                    id="txtProducts"
                    name="products"
                    className="full"
                    value={products}
                    placeholder="insert a cool product"
                    onChange={this.onHandleChange}
                    listOfTags={ProductsTagsList.predicates.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  />
                </fieldset>

                <hr />

                <fieldset>
                  <legend>Schedule Post</legend>
                  <span className="label">When would you like this post to drop?</span>
                  <div className="radio-checkbox-group">
                    <label>
                      <input
                        type="radio"
                        name="schedulePost"
                        checked={schedulePost === 'asap'}
                        value="asap"
                        onChange={({ target }) => this.onHandleChange(target.name, target.value)}
                      />
                      asap
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="schedulePost"
                        checked={schedulePost === 'later'}
                        value="later"
                        onChange={({ target }) => this.onHandleChange(target.name, target.value)}
                      />
                      later
                    </label>

                    {schedulePost === 'later' && (
                      <DatePicker
                        className="datepicker-video datepicker-dateTime"
                        calendarClassName="Franki-DatePicker"
                        disabledKeyboardNavigation
                        selected={schedulePostDate}
                        onChange={(_value) => this.onHandleChange('schedulePostDate', _value)}
                        showTimeSelect
                        minDate={new Date()}
                        minTime={setHours(setMinutes(new Date(), currentMinutes), currentHours)}
                        maxTime={setHours(setMinutes(new Date(), 0), 23)}
                        timeFormat="HH:mm"
                        timeIntervals={60}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        timeCaption="time"
                      />
                    )}
                  </div>
                </fieldset>

                <fieldset>
                  <span className="label">
                    Would you like to remove this post automatically? We got you!
                  </span>
                  <div className="radio-checkbox-group">
                    <label>
                      <input
                        type="checkbox"
                        name="setExpirationDate"
                        value={!setExpirationDate}
                        checked={setExpirationDate}
                        onChange={({ target }) => {
                          this.onHandleChange(target.name, !setExpirationDate);
                        }}
                      />
                      set expiration date
                    </label>

                    {setExpirationDate && (
                      <DatePicker
                        className="datepicker-video datepicker-dateTime"
                        calendarClassName="Franki-DatePicker"
                        disabledKeyboardNavigation
                        selected={expirationDate}
                        onChange={(_value) => this.onHandleChange('expirationDate', _value)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        minDate={new Date(schedulePostDate)}
                        timeIntervals={60}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        timeCaption="time"
                      />
                    )}
                  </div>
                </fieldset>
              </div>
            </div>

            <div className="buttons-actions">
              {editing ? (
                <Link
                  to={`/admin/business-videos/${() =>
                    this.getBusinessID()}/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`}
                  className="button small"
                >
                  Cancel
                </Link>
              ) : (
                <Link
                  to={`/admin/business-profile/${() => this.getBusinessID()}`}
                  className="button small"
                >
                  Cancel
                </Link>
              )}

              <button type="submit" className="button small orange-theme">
                Save
              </button>
            </div>
          </form>
        </MasterPage>
      </>
    );
  }
}

BusinessVideoForm.propTypes = {
  auth: bool.isRequired,
  businessDetails: BUSINESS_DETAILS_TYPE.isRequired,
  getBusinessDetails: func.isRequired,
  getBusinessVideos: func.isRequired,
  match: ROUTER_MATCH_TYPE({ corporateGroupId: string.isRequired }).isRequired,
};

const mapStateToProps = (state) => ({
  businessDetails: state.businesses.businessDetails,
});

export default connect(mapStateToProps, {
  getBusinessDetails,
  getBusinessVideos,
})(BusinessVideoForm);
