import './index.sass';

import { isEmpty } from 'lodash';
import moment from 'moment';
import { bool, instanceOf } from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import HamburgerMenu from '../../../../../components/HamburgerMenu';
import { getStatusName } from '../../../../../helpers';

class BusinessGigCard extends React.PureComponent {
  render() {
    const { item, bookmarkItems, match, accessible } = this.props;
    const GigStatusName = getStatusName(item.StatusId);
    const GigPrizesValue = item.GigPrizes && item.GigPrizes.map((prize) => prize.PrizeAmount);

    return (
      <div className="BusinessGigCard">
        {accessible && !isEmpty(bookmarkItems) && (
          <HamburgerMenu
            className="BusinessGigCard__Bookmarks"
            icon="icon-bookmark_default"
            menuItems={bookmarkItems}
            position="Left"
          />
        )}

        <HamburgerMenu
          menuItems={[
            {
              innerContent: `ID: ${item.GigId}`,
            },
          ]}
        />

        <figure className="BusinessGigCard__MainImg">
          {accessible ? (
            <Link
              to={{
                pathname: `/admin/business-gig-details/${match.params.corporateGroupId}/${item.GigId}`,
                state: { data: item },
              }}
            >
              {item.Photo && item.Photo.FullResolutionUrl && (
                <img src={item.Photo.FullResolutionUrl} alt="" />
              )}
            </Link>
          ) : (
            <>
              {item.Photo && item.Photo.FullResolutionUrl ? (
                <img src={item.Photo.FullResolutionUrl} alt="" />
              ) : null}
            </>
          )}

          {item.featured && <figcaption className="BusinessGigCard__Featured">featured</figcaption>}
        </figure>

        <div className="BusinessGigCard__Wrapper">
          <div className="BusinessGigCard__PostedBy">
            <div className="BusinessGigCard__PostedBy__Status">
              {item.EndDate && (
                <span className="BusinessGigCard__PostedBy__Status__Date">
                  {'Expires '}
                  {moment(new Date(item.EndDate)).format('MMMM D, YYYY')}
                </span>
              )}

              <span
                className={`BusinessGigCard__PostedBy__Status__Type BusinessGigCard__PostedBy__Status__Type--${GigStatusName.slug}`}
              >
                {GigStatusName.label}
              </span>
            </div>
            <div className="BusinessGigCard__PostedBy__Info">
              {item.PosterProfile &&
                Object.entries(item.PosterProfile).length > 1 &&
                item.PosterProfile.DisplayName && (
                  <span className="BusinessGigCard__PostedBy__Info__Name">
                    {item.PosterProfile.DisplayName}
                  </span>
                )}

              {item.Name && (
                <span className="BusinessGigCard__PostedBy__Info__Name">{item.Name}</span>
              )}
            </div>
          </div>

          {item.Description && <p className="BusinessGigCard__Description">{item.Description}</p>}

          {!isEmpty(GigPrizesValue) && (
            <span className="BusinessGigCard__Prize">
              {`$${Math.min(...GigPrizesValue)} - $${Math.max(...GigPrizesValue)}`}
            </span>
          )}
        </div>
      </div>
    );
  }
}

BusinessGigCard.propTypes = {
  item: instanceOf(Object).isRequired,
  match: instanceOf(Object).isRequired,
  bookmarkItems: instanceOf(Array),
  accessible: bool,
};

BusinessGigCard.defaultProps = {
  bookmarkItems: [],
  accessible: null,
};

export default withRouter(BusinessGigCard);
