import './index.sass';

import { isEmpty, max } from 'lodash';
import { arrayOf, bool, func, number, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import GIG_PACKAGES from '../../containers/Business/BusinessGig/BusinessGigCreate/GigPackages';
import { GIG_RULE } from '../../types/gig';

const MAX_RULES = 4;

function GigRules(props) {
  const { dirty, gigType, onChange, rules, name, gigId, removeRule } = props;
  const [newRuleDescription, setNewRuleDescription] = useState('');
  const activeGig = useMemo(() => {
    if (name) {
      return GIG_PACKAGES.find((item) => item.type === name);
    }
    return GIG_PACKAGES.find((item) => item.type === gigType);
  }, [gigType]);
  const defaultRules = useMemo(
    () =>
      activeGig &&
      activeGig.rules.map((rule, index) => ({
        Description: rule,
        DisplayOrder: index,
        GigRuleId: index,
      })),
    [activeGig],
  );

  useEffect(() => {
    if (isEmpty(rules) && !isEmpty(defaultRules)) {
      onChange(defaultRules);
    }
  }, []);

  const onHandleRuleChange = useCallback(
    (index, description) => {
      onChange(
        rules.map((rule, i) => (i === index ? { ...rule, Description: description } : rule)),
      );
    },
    [onChange, rules],
  );

  const getNewRuleId = useCallback(
    () => max(rules.map(({ GigRuleId }) => GigRuleId || 0)) + 1,
    [rules],
  );

  const onHandleAddRule = useCallback(() => {
    const updatedRules = [
      ...rules,
      {
        Description: newRuleDescription,
        DisplayOrder: rules.length,
        GigRuleId: getNewRuleId(),
        GigId: gigId,
      },
    ];

    onChange(updatedRules);
    setNewRuleDescription('');
  }, [rules, newRuleDescription, getNewRuleId, onChange, setNewRuleDescription]);

  return (
    <ol className="GigRules">
      {rules.length
        ? rules.map((item, index) => (
            <li
              key={`rule-${item.GigRuleId}`}
              className={dirty && index === 0 && !item.Description ? 'error' : ''}
            >
              <input
                autoComplete="off"
                className="full"
                maxLength={60}
                name="Description"
                onChange={({ target }) => onHandleRuleChange(index, target.value)}
                placeholder="Add your own rules..."
                type="text"
                value={item.Description}
              />

              {dirty && index === 0 && !item.Description ? 'The first rule is required' : ''}

              {index !== 0 && (
                <i
                  className="icon-error BusinessGigCreateFourthStep__Delete GigRules"
                  onClick={() => removeRule(item.GigRuleId)}
                  role="button"
                  tabIndex={0}
                />
              )}
            </li>
          ))
        : dirty && <div className="error GigRules__Error">The first rule is required</div>}

      {rules.length < MAX_RULES ? (
        <button className="button button--outline small" onClick={onHandleAddRule} type="button">
          + ADD RULE
        </button>
      ) : null}
    </ol>
  );
}

GigRules.propTypes = {
  gigType: string.isRequired,
  onChange: func.isRequired,
  rules: arrayOf(GIG_RULE).isRequired,
  dirty: bool,
  gigId: number,
};

GigRules.defaultProps = {
  dirty: false,
};

export default GigRules;
