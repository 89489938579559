import Axios from 'axios';
import { from, Subject } from 'rxjs';

import { REACT_APP_API_URL, REACT_APP_ENV } from '../config/variables';
import { convertObjToFormData } from '../helpers';
import mockAxios from '../mock/mockRegister';
import asyncLocalStorage from './asyncLocalStorage';

/* global window localStorage Blob XMLHttpRequest FileReader atob */
export const API = REACT_APP_API_URL;

const axios = Axios.create();

export const APIRequest = async (config) => {
  const { frankiToken } = (await localStorage) || null;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(config.headers || null),
  };

  let requestURI = config.uri;

  // REGEX is checking if we're requesting mocks API
  // It will be removed soon
  if (!/^https?/.test(requestURI)) {
    requestURI = `${
      config.baseApiUri
        ? (config.api || API).split('/AdminApi').join(config.baseApiUri)
        : config.api || API
    }/${requestURI}`;
    headers[
      'Access-Control-Allow-Origin'
    ] = `https://${REACT_APP_ENV}-adminpanel.azurewebsites.net`;

    if (frankiToken) {
      headers.Authorization = `Bearer ${frankiToken}`;
    }
  }

  const requestConfig = () => {
    const settings = {
      method: config.method,
      headers,
    };

    if (
      config.method === 'POST'
      || config.method === 'PATCH'
      || config.method === 'PUT'
      || config.method === 'DELETE'
    ) {
      if (settings.headers['Content-Type'].indexOf('multipart') !== -1) {
        settings.data = convertObjToFormData(config.data || {});
      } else {
        settings.data = JSON.stringify(config.data || {});
      }
    }

    return settings;
  };

  const getErrorMessage = (data) => {
    let message = data;
    if (typeof data === 'object') {
      message = data.Message;
    }

    if (message === 'Invalid Token') {
      asyncLocalStorage.clear();
      window.location.href = `/?from=${window.location.pathname}`;
    }

    return message || 'Something went wrong.';
  };

  const promiseRequestAPI = async axiosInstance => new Promise((resolve, reject) => {
    axiosInstance(requestURI, requestConfig())
      .then(({ data }) => {
        if (typeof data !== 'object' || data.Success !== false) {
          resolve(data);
        } else {
          reject(getErrorMessage(data));
        }
      })
      .catch(({ response }) => {
        reject(getErrorMessage(response && response.data));
      });
  });

  if (REACT_APP_ENV === 'dev' && config.isMock) {
    return promiseRequestAPI(mockAxios);
  }

  return promiseRequestAPI(axios);
};

export const req = (request, configs = {}) => {
  const revisedConfigs = { ...configs };
  if (!revisedConfigs.successStateProp) {
    revisedConfigs.successStateProp = 'Success';
  }
  if (!revisedConfigs.dataProp) {
    revisedConfigs.dataProp = 'Data';
  }
  if (!revisedConfigs.errorMessageProp) {
    revisedConfigs.errorMessageProp = 'Message';
  }
  const sbj = new Subject();
  from(APIRequest(request)).subscribe(
    (response) => {
      if (revisedConfigs.dataProp === 'self') {
        sbj.next(response);
        sbj.complete();
      } else if (response[revisedConfigs.successStateProp]) {
        if (revisedConfigs.addMessage) {
          sbj.next({
            ...response[revisedConfigs.dataProp],
            [revisedConfigs.errorMessageProp]: response[revisedConfigs.errorMessageProp],
          });
        } else {
          sbj.next(response[revisedConfigs.dataProp]);
        }
        sbj.complete();
      } else {
        sbj.error(response[revisedConfigs.errorMessageProp]);
        sbj.complete();
      }
    },
    (err) => {
      sbj.error(err || 'Unknown error');
      sbj.complete();
    },
  );
  return sbj;
};

export const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const pathToBlob = async (path) => {
  const fileBlobPromise = new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', path, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const res = event.target.result;
        resolve(DataURIToBlob(res));
      };
      const file = xhr.response;
      reader.readAsDataURL(file);
    };
    xhr.send();
  });
  const result = await fileBlobPromise;
  return result;
};

export const getBlob = async (file) => {
  let fileValue = null;
  try {
    fileValue = DataURIToBlob(file);
  } catch (_) {
    fileValue = await pathToBlob(file);
  }
  return fileValue;
};
window.getBlob = getBlob;
export default { API, APIRequest, req };
