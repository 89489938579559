import './index.sass';

import { instanceOf } from 'prop-types';
import React from 'react';

import defaultProfilePhoto from '../../../assets/images/default-profile-photo.png';

const LikesCard = (props) => {
  const { item } = props;
  let avatar = item.UserPhoto ? item.UserPhoto.FullResolutionUrl : item.image;
  const displayName = item.UserDisplayName ? item.UserDisplayName : item.name;
  if (!avatar) avatar = defaultProfilePhoto;

  return (
    <li className="likesCard-item">
      <figure className="likesCard-img">
        <img src={avatar} alt="" />
      </figure>

      <div className="likesCard-content">{displayName}</div>
    </li>
  );
};

LikesCard.propTypes = {
  item: instanceOf(Object).isRequired,
};

export default LikesCard;
