/* eslint-disable max-len */
/* global window */
import './index.sass';

import { bool, node, number, string } from 'prop-types';
import React from 'react';

class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.Tooltip = React.createRef();

    this.state = {
      open: false,
      tooltipPosition: props.position, // positions available: right and left
      tooltipSpace: 0,
      width: props.width,
      height: props.height,
    };
  }

  componentDidMount() {
    this.updatePosition();
    this.updateDimensions();
    this.updateTooltipConfigs();
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('mousedown', this.onHandleClickAway);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('mousedown', this.onHandleClickAway);
  }

  onHandleClickAway = (e) => {
    if (this.Tooltip.current && !this.Tooltip.current.contains(e.target)) {
      this.setState({
        open: false,
      });
    }
  };

  updateTooltipConfigs = () => {
    const { width, height } = this.props;

    this.setState({
      width,
      height,
    });
  };

  updateDimensions = () => {
    const { width, height, position } = this.props;
    const margin = 35;

    const tooltipInfo = this.Tooltip.current.getBoundingClientRect();
    const widthWithMargin = width + margin;
    const heightWithMargin = height + margin;

    const screenCenterPositionW = window.innerWidth / 2;
    const screenCenterPositionH = window.innerHeight / 2;

    const remainingSpaceOnRight = window.innerWidth - tooltipInfo.left;
    const remainingSpaceOnLeft = tooltipInfo.left - screenCenterPositionW + screenCenterPositionW;

    const remainingSpaceOnTop = tooltipInfo.top - screenCenterPositionH + screenCenterPositionH;
    const remainingSpaceOnBottom = window.innerHeight - tooltipInfo.top;

    if (position === 'right') {
      if (remainingSpaceOnRight < widthWithMargin && remainingSpaceOnLeft > widthWithMargin) {
        this.setState({
          tooltipPosition: 'left',
        });
      } else {
        this.setState({
          tooltipPosition: 'right',
        });
      }
    } else if (remainingSpaceOnLeft < widthWithMargin && remainingSpaceOnRight > widthWithMargin) {
      this.setState({
        tooltipPosition: 'right',
      });
    } else {
      this.setState({
        tooltipPosition: 'left',
      });
    }

    if (
      heightWithMargin / 2 > remainingSpaceOnTop
      && heightWithMargin / 2 < remainingSpaceOnBottom
    ) {
      this.setState({
        tooltipSpace: heightWithMargin / 2 - remainingSpaceOnTop,
      });
    }

    if (
      heightWithMargin / 2 > remainingSpaceOnBottom
      && heightWithMargin / 2 < remainingSpaceOnTop
    ) {
      this.setState({
        tooltipSpace: (heightWithMargin / 2 - remainingSpaceOnBottom) * -1,
      });
    }
  };

  updatePosition = () => {
    const { position } = this.props;

    if (position !== 'right') {
      this.setState({
        tooltipPosition: position,
      });
    }
  };

  onHandleTooltip = (_bool) => {
    this.setState(prevState => ({
      open: _bool || !prevState.open,
    }));
  };

  render() {
    const { icon, theme, children, title, compact } = this.props;

    const { open, tooltipPosition, tooltipSpace, width, height } = this.state;

    return (
      <div
        className={`tooltip ${theme === 'dark' ? 'tooltip-dark' : 'tooltip-light'}`}
        ref={this.Tooltip}
      >
        <button
          className="tooltip-indicator"
          onClick={() => this.onHandleTooltip()}
          onKeyPress={() => this.onHandleTooltip()}
          tabIndex={0}
          type="button"
        >
          {icon ? (
            <img src={icon} alt="Tooltip icon" height={compact ? '16px' : '30px'} />
          ) : (
            <i className="tooltip-icon" />
          )}
        </button>

        <div
          className={`tooltip-container ${
            tooltipPosition ? `tooltip-container-${tooltipPosition}` : ''
          }`}
          style={{
            display: open ? 'flex' : 'none',
            width,
            height,
            marginTop: tooltipSpace,
          }}
        >
          <span className="tooltip-arrow" style={{ marginTop: tooltipSpace * -1 }} />
          <div className="tooltip-container-content">
            <div className="tooltip-close">
              <span
                className="tooltip-close-button"
                onClick={() => this.onHandleTooltip()}
                onKeyPress={() => this.onHandleTooltip()}
                role="button"
                tabIndex={0}
              >
                X
              </span>
            </div>

            {title && <span className="tooltip-container-content-title">{title}</span>}

            <div className="tooltip-container-content-main">{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

Tooltip.propTypes = {
  theme: string,
  children: node.isRequired,
  width: number,
  height: number,
  title: string,
  position: string,
  icon: string,
  compact: bool,
};

Tooltip.defaultProps = {
  theme: 'dark', // dark or light
  width: 300,
  height: 580,
  title: '',
  position: 'right',
  icon: null,
  compact: true,
};

export default Tooltip;
