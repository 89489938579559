import { map } from 'lodash';
import uuidv1 from 'uuid/v1';

import { APIRequest, req } from '../utils/api';

export const CHARGE_GIG_PAYMENT = 'CHARGE_GIG_PAYMENT';
export const CHARGE_GIG_PAYMENT_ERROR = 'CHARGE_GIG_PAYMENT_ERROR';
export const CHARGE_GIG_PAYMENT_SUCCESS = 'CHARGE_GIG_PAYMENT_SUCCESS';
export const COMPLETE_GIG = 'COMPLETE_GIG';
export const CREATE_GIG_PRIZES = 'CREATE_GIG_PRIZES';
export const CREATE_GIG_PRIZES_ERROR = 'CREATE_GIG_PRIZES_ERROR';
export const CREATE_GIG_PRIZES_SUCCESS = 'CREATE_GIG_PRIZES_SUCCESS';
export const DELETE_GIG = 'DELETE_GIG';
export const DELETE_GIG_ERROR = 'DELETE_GIG_ERROR';
export const DELETE_GIG_SUCCESS = 'DELETE_GIG_SUCCESS';
export const GET_ALL_GIGS = 'GET_ALL_GIGS';
export const GET_ALL_GIGS_ERROR = 'GET_ALL_GIGS_ERROR';
export const GET_ALL_GIGS_SUCCESS = 'GET_ALL_GIGS_SUCCESS';
export const GET_GIG_DETAILS = 'GET_GIG_DETAILS';
export const GET_GIG_DETAILS_ERROR = 'GET_GIG_DETAILS_ERROR';
export const GET_GIG_DETAILS_SUCCESS = 'GET_GIG_DETAILS_SUCCESS';
export const GET_GIG_SUBMISSIONS = 'GET_GIG_SUBMISSIONS';
export const RESET_GIG_ACTION = 'RESET_GIG_ACTION';
export const UPDATE_GIG = 'UPDATE_GIG';
export const UPDATE_GIG_ERROR = 'UPDATE_GIG_ERROR';
export const UPDATE_GIG_SUCCESS = 'UPDATE_GIG_SUCCESS';

export function getAllGigs(filters) {
  const filterParams = map(filters, (value, key) => `${key}=${value}`);
  const uri = `GigManager/GetGigAll?${filterParams.join('&')}`;
  return async (dispatch) => {
    dispatch({ type: GET_ALL_GIGS });
    await APIRequest({
      uri,
      method: 'GET',
    })
      .then(payload => dispatch({
        type: GET_ALL_GIGS_SUCCESS,
        payload,
      }))
      .catch((error) => {
        dispatch({
          type: GET_ALL_GIGS_ERROR,
          payload: { error },
        });
      });
  };
}

export function getGigSubmissions(gigId) {
  return async (dispatch) => {
    await APIRequest({
      uri: `Video/GetGigVideos?gigId=${gigId}`,
      method: 'GET',
    }).then(payload => dispatch({
      type: GET_GIG_SUBMISSIONS,
      payload,
    }));
  };
}

export function createGig(data) {
  return req({
    headers: {
      'Content-Type': `multipart/form-data; charset=utf-8; boundary=${uuidv1()}`,
    },
    uri: 'GigManager/CreateGig',
    method: 'POST',
    data,
  });
}

export function updateNewGig(data) {
  return req({
    headers: {
      'Content-Type': `multipart/form-data; charset=utf-8; boundary=${uuidv1()}`,
    },
    uri: 'GigManager/EditGig',
    method: 'POST',
    data,
  });
}

export function updateGig(data) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_GIG });
    await APIRequest({
      headers: {
        'Content-Type': `application/json; multipart/form-data; charset=utf-8; boundary=${uuidv1()}`,
      },
      uri: 'GigManager/EditGig',
      method: 'POST',
      data,
    })
      .then(res => dispatch({
        type: UPDATE_GIG_SUCCESS,
        payload: res.Message,
      }))
      .catch(error => dispatch({
        type: UPDATE_GIG_ERROR,
        payload: { error },
      }));
  };
}

export function deleteGig(gigId) {
  return async (dispatch) => {
    dispatch({ type: DELETE_GIG });
    await APIRequest({
      uri: `GigManager/DeleteGig?gigId=${gigId}`,
      method: 'POST',
    })
      .then(res => dispatch({
        type: DELETE_GIG_SUCCESS,
        payload: res,
      }))
      .catch(error => dispatch({
        type: DELETE_GIG_ERROR,
        payload: { error },
      }));
  };
}

export function createGigRules(data) {
  return req(
    {
      uri: 'GigManager/CreateGigRules',
      method: 'POST',
      data,
    },
    {
      dataProp: 'self',
    },
  );
}

export function getGig(gigId) {
  return req({
    uri: `GigManager/GetGigDetail?GigId=${gigId}`,
    method: 'GET',
  });
}

export function getGigDetails(gigId) {
  return async (dispatch) => {
    dispatch({ type: GET_GIG_DETAILS });
    await APIRequest({
      uri: `GigManager/GetGigDetail?GigId=${gigId}`,
      method: 'GET',
    })
      .then((res) => {
        dispatch({
          type: GET_GIG_DETAILS_SUCCESS,
          payload: { data: res.Data },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_GIG_DETAILS_ERROR,
          payload: { error },
        });
      });
  };
}

export function createGigPrizesNew(data) {
  return req(
    {
      uri: 'GigManager/CreateGigPrizes',
      method: 'POST',
      data,
    },
    {
      dataProp: 'self',
    },
  );
}

export function createGigPrizes(data) {
  return async (dispatch) => {
    dispatch({ type: CREATE_GIG_PRIZES });
    await APIRequest({
      uri: 'GigManager/CreateGigPrizes',
      method: 'POST',
      data,
    })
      .then(payload => dispatch({
        type: CREATE_GIG_PRIZES_SUCCESS,
        payload,
      }))
      .catch(error => dispatch({
        type: CREATE_GIG_PRIZES_ERROR,
        payload: { error },
      }));
  };
}

export function completeGig(gigId) {
  return async (dispatch) => {
    await APIRequest({
      uri: `GigManager/CompleteGig?gigId=${gigId}`,
      method: 'POST',
    })
      .then(res => dispatch({
        type: COMPLETE_GIG,
        payload: { data: res.Success },
      }))
      .catch(error => dispatch({
        type: COMPLETE_GIG,
        payload: { error },
      }));
  };
}

export function chargeGigPayment(gigId, paymentProfileId, amount, email) {
  return async (dispatch) => {
    dispatch({ type: CHARGE_GIG_PAYMENT });
    await APIRequest({
      uri: 'BusinessPayment/ChargeGigPayment',
      method: 'POST',
      data: {
        Amount: amount,
        GigId: gigId,
        PaymentProfileId: paymentProfileId,
        Email: email,
      },
    })
      .then(res => dispatch({
        type: CHARGE_GIG_PAYMENT_SUCCESS,
        payload: { data: res.Message },
      }))
      .catch(error => dispatch({
        type: CHARGE_GIG_PAYMENT_ERROR,
        payload: { error },
      }));
  };
}

export function resetGig() {
  return async (dispatch) => {
    dispatch({ type: RESET_GIG_ACTION });
  };
}
