import './index.sass';

import { bool, func } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import AsyncContent from '../../../../components/AsyncContent';
import CustomSelect from '../../../../components/CustomSelect';
import RemainCharacters from '../../../../components/RemainCharacters';
import BUSINESS_TYPES from '../../../../config/business-types';
import { BUSINESS_DETAILS_TYPE } from '../../../../types/business';
import { getBusinessCategories } from '../../../../actions/businesses';

const MAX_DESCRIPTION_TEXTAREA_LENGTH = 250;
export default function BusinessBasicForm(props) {
  const {
    formState: { Type, Name, Description, Category },
    onHandleChange,
    onFormErrorStateUpdate,
    disabled,
  } = props;
  const [logoError, setLogoError] = useState(null);
  const [businessCategories, setBusinessCategories] = useState([]);

  const descLength = useMemo(() => (Description ? Description.length : 0), [Description]);
  const categoryOptions = useMemo(() => businessCategories.map(item => ({ label: item.Name, value: item.Id })), [businessCategories]);

  const onUpdateType = (updatedValue) => {
    onHandleChange('Type', {
      Id: updatedValue.value,
      Name: updatedValue.label,
    });
    onHandleChange('Category', '');
    onHandleChange('CategoryId', '');
  };

  useEffect(() => {
    getBusinessCategories(Type ? Type.Id : null).subscribe(({ Categories }) => {
      const formattedCategories = Categories.map((item) => {
        const newItem = { ...item };
        if (newItem.DisplayString) {
          newItem.Name = newItem.DisplayString;
        } else if (newItem.Name) {
          newItem.DisplayString = newItem.Name;
        }
        return newItem;
      });
      setBusinessCategories(formattedCategories);
    });
  }, [Type]);

  useEffect(() => {
    const descRemainingCharacters = MAX_DESCRIPTION_TEXTAREA_LENGTH - descLength;
    onFormErrorStateUpdate(descRemainingCharacters < 0);
  }, [Description]);

  return (
    <AsyncContent
      content={() => (
        <>
          <div className="BusinessBasicForm__Content">
            <div className="BusinessEdit__BlockGroup">
              <div className="mb-0 BusinessEdit__Blocks BusinessBasicForm__BusinessName">
                <label htmlFor="txtBusinessName">
                  Business name
                  <span className="required-asterisk">*</span>
                </label>

                <input
                  autoComplete="none"
                  className="full"
                  id="txtBusinessName"
                  disabled={disabled}
                  maxLength={60}
                  minLength={3}
                  name="Name"
                  onChange={({ target }) => onHandleChange(target.name, target.value)}
                  required
                  type="text"
                  value={Name || ''}
                />
              </div>

              <div className="mb-0 BusinessEdit__Blocks BusinessBasicForm__BusinessIndustry">
                <label htmlFor="txtBusinessType">
                  Business type
                  <span className="required-asterisk">*</span>
                </label>

                <CustomSelect
                  className="full"
                  id="txtBusinessType"
                  disabled={disabled}
                  showDisabledValue
                  name="Type"
                  onChange={value => onUpdateType(value)}
                  options={BUSINESS_TYPES}
                  placeholder="select your business type..."
                  required
                  value={BUSINESS_TYPES.find(
                    ({ value }) => value === (Type ? Type.Id : null),
                  )}
                />
              </div>

              <div className="mb-0 BusinessEdit__Blocks BusinessBasicForm__BusinessIndustry">
                <label htmlFor="txtBusinessCategory">
                  Add category
                  <span className="required-asterisk">*</span>
                </label>

                <CustomSelect
                  className="full"
                  id="txtBusinessCategory"
                  disabled={disabled}
                  showDisabledValue
                  name="Category"
                  onChange={({ label, value }) => {
                    onHandleChange('Category', {
                      Id: value,
                      Name: label,
                    });
                  }}
                  options={categoryOptions}
                  placeholder="select your business category..."
                  value={categoryOptions.find(
                    ({ value }) => value === (Category ? Category.Id : null),
                  )}
                  required
                />
              </div>
            </div>

            <div className="mt-10 BusinessEdit__Blocks">
              <label htmlFor="txtBusinessTagLine">
                Describe your business, what do you want people to know about you?
                <span className="required-asterisk">*</span>
              </label>

              <textarea
                className="full"
                id="txtBusinessTagLine"
                name="Description"
                disabled={disabled}
                onChange={({ target }) => onHandleChange(target.name, target.value)}
                placeholder="We pride ourselves on using organic produced source from local farms..."
                required
                rows={3}
                value={Description}
              />

              <RemainCharacters
                label="Business's description"
                length={descLength}
                maxLength={MAX_DESCRIPTION_TEXTAREA_LENGTH}
              />
            </div>
          </div>
        </>
      )}
      errorMessage={logoError}
      expectNoData
      onHandleCloseDialog={() => setLogoError('')}
    />
  );
}

BusinessBasicForm.propTypes = {
  formState: BUSINESS_DETAILS_TYPE.isRequired,
  onFormErrorStateUpdate: func.isRequired,
  onHandleChange: func.isRequired,
  disabled: bool,
};

BusinessBasicForm.defaultProps = {
  disabled: false,
};
