import './index.sass';

import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { validateAccountIdentifier } from '../../../actions/auth';
import eye from '../../../assets/images/eye.svg';
import AlertMessages from '../../../components/AlertMessages';
import AsyncContent from '../../../components/AsyncContent';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import { validEmailAddress, validPasswordStraight } from '../../../helpers';
import HISTORY_TYPE from '../../../types/history';
import { getPlaceId, getPlaceIdProp } from '../../../utils/placeId';
import ProgressBar from '../../../components/ProgressBar';
import googleReCaptchaHoc from '../../../hoc/googleReCaptchaHoc';

/* global localStorage, btoa */
function Signup(props) {
  const { history } = props;
  const { businessId, thirdPartySourceId, nonFourSquare, profileCreated } =
    history.location.state || {};
  const placeIdProp = getPlaceIdProp();
  const placeId = thirdPartySourceId || getPlaceId(props);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [reEnterPassword, setReEnterPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [ready, setReady] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [reEnterPasswordType, setReEnterPasswordType] = useState('password');
  const [isValidatingAccountIdentifier, setIsValidatingAccountIdentifier] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formState, setFormState] = useState({});
  const [alert, setAlert] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const validate = () => {
    if (!submitted) {
      setFormState({});
      setAlert(null);
      return;
    }

    if (errorMessage) {
      setFormState({ emailError: errorMessage, valid: false });
      setAlert(errorMessage);
      setErrorMessage(null);
      setReady(false);
      return;
    }

    const validEmail = validEmailAddress(email);
    if (email && !validEmail.result) {
      setFormState({ emailError: 'Invalid email', valid: false });
      setAlert('Email address is invalid. Please try again.');
      setReady(false);
      return;
    }

    if (!firstName || !lastName || !email || !password || !reEnterPassword) {
      setFormState({
        firstNameError: !firstName && 'Required',
        lastNameError: !lastName && 'Required',
        emailError: !email && 'Required',
        passwordError: !password && 'Required',
        reEnterPasswordError: !reEnterPassword && 'Required',
        valid: false,
      });
      setAlert('Mandatory fields have not been completed. Please try again.');
      setReady(false);
      return;
    }

    const validPassword = validPasswordStraight(password);
    if (!validPassword.result) {
      setFormState({ passwordError: 'Invalid password', valid: false });
      setAlert('Password format is invalid. Please try again.');
      setReady(false);
      return;
    }

    if (password !== reEnterPassword) {
      setFormState({
        passwordError: 'Invalid password combination',
        reEnterPasswordError: 'Invalid password combination',
        valid: false,
      });
      setAlert('Password combination invalid. Please try again.');
      setReady(false);
      return;
    }
    setAlert('');
    setFormState({ valid: true });
  };

  const navigateAfterSignUp = () => {
    history.push({
      pathname: `/email-code-verification/${email}/${businessId}?${placeIdProp.query}=${placeId}`,
      state: {
        nonFourSquare,
        businessId,
        thirdPartySourceId,
        profileCreated,
      },
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setReady(true);
  };

  const renderBackButton = () => (
    <button type="button" className="button EmailCodeVerification__Back" onClick={history.goBack}>
      BACK
    </button>
  );

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('signUp');
    setIsValidatingAccountIdentifier(true);
    setErrorMessage(null);
    validateAccountIdentifier(email, token).subscribe(
      (response) => {
        navigateAfterSignUp(response);
      },
      (err) => {
        setErrorMessage(err);
        setIsValidatingAccountIdentifier(false);
        setReady(false);
      },
      () => {
        setIsValidatingAccountIdentifier(false);
        setReady(false);
      },
    );
  }, [executeRecaptcha, email]);

  useEffect(validate, [submitted, firstName, lastName, password, reEnterPassword, email, ready]);

  useEffect(() => {
    if (ready && formState.valid) {
      handleReCaptchaVerify();
    }
  }, [ready, formState, handleReCaptchaVerify]);

  return (
    <div className="AuthWrapper">
      <Header auth={false} global />

      <AsyncContent
        content={() => (
          <main className="Signup">
            <AlertMessages
              className="BusinessGigCreateSuccessfully__AlertMessages"
              show={alert}
              type="error"
              message={alert}
            />
            {renderBackButton()}
            <div className="Auth border-none">
              <form onSubmit={onSubmit}>
                <div className="Auth__Header">
                  <ProgressBar
                    className="Auth__ProgressBar"
                    totalSteps={profileCreated ? 5 : 4}
                    activeStep={profileCreated ? 2 : 1}
                  />
                  <h1 className="Auth__Title">Next, let’s create a business manager account</h1>
                  <div className="Auth__Description Auth__Description__Subtitle">
                    Your business manager account allows you to manage your listing, run marketing
                    campaigns, and much more!
                    <br />
                    Already on Franki?
                    <strong>
                      Sign in
                      <Link
                        to={`/?${placeIdProp.query}=${placeId}&businessId=${businessId}`}
                        className="orange-theme"
                      >
                        here
                      </Link>
                    </strong>
                    .
                  </div>

                  <small className="Auth__Description Auth__Description--Small">
                    By signing up, you confirm that you’ve read and agree to our
                    <br />
                    <a
                      href="https://befranki.com/terms-of-use"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      terms of use
                    </a>
                    ,
                    <a
                      href="https://befranki.com/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy policy
                    </a>
                    , and
                    <a
                      href="https://befranki.com/community"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      community guidelines
                    </a>
                    .
                  </small>
                </div>

                <fieldset>
                  <label className="Auth__Label" htmlFor="txtFirstName">
                    First name
                    <em>*</em>
                    &nbsp;
                    {submitted && formState.firstNameError}
                  </label>

                  <input
                    autoComplete="off"
                    className={`full ${submitted && formState.firstNameError && 'error'}`}
                    disabled={isValidatingAccountIdentifier}
                    id="txtFirstName"
                    maxLength="50"
                    onChange={({ target: { value } }) => {
                      setFirstName(value);
                      localStorage.setItem('firstName', value);
                    }}
                    type="text"
                    value={firstName}
                  />
                </fieldset>

                <fieldset>
                  <label className="Auth__Label" htmlFor="txtLastName">
                    Last name
                    <em>*</em>
                    &nbsp;
                    {submitted && formState.lastNameError}
                  </label>

                  <input
                    autoComplete="off"
                    className={`full ${submitted && formState.lastNameError && 'error'}`}
                    disabled={isValidatingAccountIdentifier}
                    id="txtLastName"
                    maxLength="50"
                    onChange={({ target: { value } }) => {
                      setLastName(value);
                      localStorage.setItem('lastName', value);
                    }}
                    type="text"
                    value={lastName}
                  />
                </fieldset>

                <fieldset>
                  <label className="Auth__Label" htmlFor="txtEmail">
                    Email
                    <em>*</em>
                    &nbsp;
                    {submitted && formState.emailError}
                  </label>

                  <input
                    autoComplete="off"
                    className={`full ${submitted && formState.emailError && 'error'}`}
                    disabled={isValidatingAccountIdentifier}
                    id="txtEmail"
                    maxLength={60}
                    onChange={({ target: { value } }) => setEmail(value)}
                    type="text"
                    value={email}
                  />
                </fieldset>

                <fieldset>
                  <label className="Auth__Label" htmlFor="txtPassword">
                    Password
                    <em>*</em>
                    &nbsp;
                    {submitted && formState.passwordError}
                    &nbsp;
                  </label>

                  <input
                    className={`full ${submitted && formState.passwordError && 'error'}`}
                    disabled={isValidatingAccountIdentifier}
                    id="txtPassword"
                    onChange={({ target: { value } }) => {
                      setPassword(value);
                      localStorage.setItem('pw', btoa(value));
                    }}
                    type={passwordType}
                    value={password}
                  />

                  <button
                    className="Signup__InputButton"
                    onClick={() =>
                      setPasswordType(passwordType === 'password' ? 'text' : 'password')
                    }
                    type="button"
                  >
                    <img src={eye} alt="Show/Hide" />
                  </button>
                </fieldset>

                <fieldset>
                  <label className="Auth__Label" htmlFor="txtReEnterPassword">
                    Re-Enter Password
                    <em>*</em>
                    &nbsp;
                    {submitted && formState.reEnterPasswordError}
                  </label>

                  <input
                    className={`full ${submitted && formState.reEnterPasswordError && 'error'}`}
                    disabled={isValidatingAccountIdentifier}
                    id="txtReEnterPassword"
                    name="ReEnterPassword"
                    onChange={({ target: { value } }) => setReEnterPassword(value)}
                    type={reEnterPasswordType}
                    value={reEnterPassword}
                  />

                  <button
                    className="Signup__InputButton"
                    onClick={() =>
                      setReEnterPasswordType(
                        reEnterPasswordType === 'password' ? 'text' : 'password',
                      )
                    }
                    type="button"
                  >
                    <img src={eye} alt="Show/Hide" />
                  </button>
                </fieldset>

                <div className="Signup__Password__Description">
                  Password must be 8 or more characters with a mix of uppercase, lowercase, numbers
                  and special characters.
                </div>

                <div className="Auth__Action">
                  <button
                    className="button orange-theme"
                    disabled={isValidatingAccountIdentifier}
                    type="submit"
                  >
                    {isValidatingAccountIdentifier ? 'SIGNING UP...' : 'CREATE ACCOUNT'}
                  </button>
                </div>
              </form>
            </div>
          </main>
        )}
        expectNoData
      />

      <Footer />
    </div>
  );
}

Signup.propTypes = {
  history: HISTORY_TYPE.isRequired,
};

export default googleReCaptchaHoc(Signup);
