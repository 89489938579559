import './index.sass';

import { bool, func, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  getBusinessDetails as getBusinessDetailsAction,
  getBusinessUsers as getBusinessUsersAction,
} from '../../../actions/businesses';
import AsyncContent from '../../../components/AsyncContent';
import HamburgerMenu from '../../../components/HamburgerMenu';
import MasterPage from '../../../components/MasterPage';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../config/pagination';
import { BUSINESS_DETAILS_TYPE } from '../../../types/business';
import HISTORY_TYPE from '../../../types/history';
import LOCATION_TYPE from '../../../types/location';
import RESPONSE_LIST_OF_TYPE from '../../../types/responseListOf';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';
import VIDEO_TYPE from '../../../types/video';

class BusinessUsers extends React.Component {
  componentDidMount() {
    const {
      getBusinessDetails,
      match: {
        params: { corporateGroupId },
      },
    } = this.props;

    getBusinessDetails(corporateGroupId);
    this.getUsers();
  }

  async getUsers() {
    const {
      getBusinessUsers,
      match: {
        params: { corporateGroupId },
      },
    } = this.props;

    await getBusinessUsers(corporateGroupId, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX);
  }

  render() {
    const {
      auth,
      businessDetails,
      businessUsers: { Results },
      businessUsersError,
      isFetchingBusinessDetails,
      isFetchingBusinessUsers,
    } = this.props;

    const masterPageConfig = {
      auth,
      businessMobileTitle: 'Managers',
      businessSidebarData: businessDetails,
      businessSidebarOn: true,
    };

    return (
      <div className="BusinessUsers">
        <Helmet title="Franki Admin: Managers" />

        {!isFetchingBusinessDetails && (
          <MasterPage {...masterPageConfig}>
            <AsyncContent
              content={(list) => (
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>User Name</th>
                      <th>Phone Number</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>
                        <Link to="/admin/user-create" className="button small orange-theme">
                          Add User
                        </Link>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item) => (
                      <tr>
                        <td>{item.FullName}</td>
                        <td>{item.Username}</td>
                        <td>{item.PhoneNumber}</td>
                        <td>{item.Email}</td>
                        <td>{item.Role}</td>
                        <td>
                          <HamburgerMenu
                            menuItems={[
                              {
                                innerContent: 'Edit User Information',
                                url: '/',
                              },
                              {
                                innerContent: 'Add User',
                                url: '/',
                              },
                              {
                                innerContent: 'Deactivate',
                                highlight: true,
                              },
                              {
                                innerContent: `ID: ${item.Id || '56DF963-36A'}`,
                                hasTopBorder: true,
                              },
                            ]}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              errorMessage={businessUsersError}
              expectedData={Results}
              loading={isFetchingBusinessUsers}
            />
          </MasterPage>
        )}
      </div>
    );
  }
}

BusinessUsers.propTypes = {
  auth: bool.isRequired,
  businessDetails: BUSINESS_DETAILS_TYPE.isRequired,
  businessUsers: RESPONSE_LIST_OF_TYPE(VIDEO_TYPE).isRequired,
  businessUsersError: string.isRequired,
  getBusinessDetails: func.isRequired,
  getBusinessUsers: func.isRequired,
  history: HISTORY_TYPE.isRequired,
  isFetchingBusinessDetails: bool.isRequired,
  isFetchingBusinessUsers: bool.isRequired,
  location: LOCATION_TYPE.isRequired,
  match: ROUTER_MATCH_TYPE({ corporateGroupId: string.isRequired }).isRequired,
};

const mapStateToProps = (state) => {
  const {
    businessDetails,
    businessUsers,
    businessUsersError,
    isFetchingBusinessDetails,
    isFetchingBusinessUsers,
  } = state.businesses;

  return {
    businessDetails,
    businessUsers,
    businessUsersError,
    isFetchingBusinessDetails,
    isFetchingBusinessUsers,
  };
};

export default connect(mapStateToProps, {
  getBusinessDetails: getBusinessDetailsAction,
  getBusinessUsers: getBusinessUsersAction,
})(BusinessUsers);
