import './index.sass';

import { isEmpty } from 'lodash';
import { arrayOf, bool, func, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import {
  addPaymentProfile,
  getPaymentProfile,
  getServiceFee,
  removePaymentProfile,
  setDefaultPaymentProfile,
} from '../../../../../actions/payment';
import AsyncContent from '../../../../../components/AsyncContent';
import CreditCardForm from '../../../../../components/CreditCardForm';
import CustomSelect from '../../../../../components/CustomSelect';
import PaymentBox from '../../../../../components/PaymentBox';
import StepsHeader from '../../../../../components/StepsHeader';
import USER_ROLES from '../../../../../config/user-roles';
import PRIZE_TYPE from '../../../../../types/prize';
import GigPackageCard from '../GigPackageCard';
import GIG_PACKAGES from '../GigPackages';
import DotsLoader from '../../../../../assets/images/icons8-dots-loading.gif';

/* global localStorage */
const hasFrankiFundedCheckbox = () =>
  [USER_ROLES.admin, USER_ROLES.saleAdmin].includes(localStorage.getItem('frankiRole'));
export default function BusinessGigCreateFifthStep(props) {
  const {
    corporateGroupId,
    onHandlePaymentSelect,
    chargeError,
    isChargingGig,
    isGigCostCovered,
    onHandleSubmit,
    onHandleBack,
    prizes,
    setIsGigCostCoveredState,
    setTotal,
    loading,
    gigType,
    updateFrankiFunded,
    setEmail,
  } = props;
  const toolTipRef = useRef(null);

  const [cardNumber, setCardNumber] = useState('');
  const [cvv, setCvv] = useState('');
  const [defaultCard, setDefaultCard] = useState(false);
  const [expirationMonth, setExpirationMonth] = useState('');
  const [expirationYear, setExpirationYear] = useState('');
  const [frankiFunded, setFrankiFunded] = useState(false);
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [creditCardArray, setCreditCardArray] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('');

  const [newPaymentProfileError, setNewPaymentProfileError] = useState('');
  const [paymentProfiles, setPaymentProfiles] = useState({});
  const [paymentProfilesError, setPaymentProfilesError] = useState('');
  const [showDefaultButton, setShowDefaultButton] = useState(false);
  const [serviceFee, setServiceFee] = useState(20);
  const [isAddEnabled, setIsAddEnabled] = useState(false);
  const [showLearnMore, setShowLearnMore] = useState(false);
  const [showPaymentProfileLoader, setShowPaymentProfileLoader] = useState(false);

  const cancel = useCallback(() => {
    setDialogVisibility(false);
    setCardNumber('');
    setCvv('');
    setDefaultCard(false);
    setExpirationMonth('');
    setExpirationYear('');
    setFrankiFunded(false);
    updateFrankiFunded(false);
  }, [
    setDialogVisibility,
    setCardNumber,
    setCvv,
    setDefaultCard,
    setExpirationMonth,
    setExpirationYear,
    setFrankiFunded,
    updateFrankiFunded,
  ]);

  const onSelectPaymentMethod = useCallback(
    (value) => {
      setPaymentMethod(value);
      if (value === 'addPaymentMethod') {
        setDialogVisibility(true);
      } else {
        cancel();
        onHandlePaymentSelect(value);
      }
    },
    [cancel, setPaymentMethod, setDialogVisibility, onHandlePaymentSelect],
  );

  const toggleLearnMoreText = () => {
    setShowLearnMore(!showLearnMore);
    ReactTooltip.rebuild(toolTipRef.current);
    setTimeout(() => {
      ReactTooltip.show(toolTipRef.current);
    }, 100);
  };

  const tooltipStyle = {
    width: '200px',
    maxWidth: '400px',
    pointerEvents: 'auto', // enable click/selection etc. events inside tooltip
    overflowY: 'auto', // make content scrollable,
    fontSize: '11px',
    lineHeight: '14px',
  };
  const tooltipStyle2 = {
    width: '400px',
    maxWidth: '400px',
    pointerEvents: 'auto', // enable click/selection etc. events inside tooltip
    overflowY: 'auto', // make content scrollable,
    fontSize: '11px',
    lineHeight: '14px',
    textAlign: 'center',
  };
  const toolTipContent = () => (
    <div style={tooltipStyle} onClick={onClickTooltipContent}>
      Payment is only processed once the GiG is completed.{' '}
      <span style={{ color: '#2F80ED', cursor: 'pointer' }} onClick={() => toggleLearnMoreText()}>
        Learn more
      </span>
    </div>
  );
  const toolTipContentExtended = () => (
    <div style={tooltipStyle2} onClick={onClickTooltipContent}>
      Payment is only processed once the GiG is completed.{' '}
      <span style={{ color: '#2F80ED', cursor: 'pointer' }} onClick={() => toggleLearnMoreText()}>
        Hide
      </span>
      <p>
        Once your GiG is confirmed, we will place a hold on your selected payment method 7 days
        prior to the GiG end date.
      </p>
      <p>
        You are only charged upon awarding the prizes at the end of the GiG. You will not be charged
        for any prizes you do not award. However, if a user successfully completes your GiG, you are
        obligated to award the prize.
      </p>
      <p>
        <strong>Example:</strong> You create a GiG with 10 prizes, but only receive 7 submissions
        that successfully complete your GiG.You will only be charged for the 7 prizes you award, not
        the 10 you offered.
      </p>
    </div>
  );

  useEffect(() => {
    setShowPaymentProfileLoader(true);
    ReactTooltip.show(toolTipRef.current);
    getPaymentProfile(corporateGroupId).subscribe(
      (profiles) => {
        const defaultPaymentMethod = profiles.CustomerPaymentProfiles.find(
          ({ IsDefault }) => IsDefault,
        );

        setPaymentProfiles(profiles);
        if (defaultPaymentMethod) {
          onSelectPaymentMethod(defaultPaymentMethod.CustomerPaymentProfileId);
        }

        setCreditCardArray(
          profiles.CustomerPaymentProfiles.map((profile) => ({
            cardNumber: profile.Payment.CardNumber,
            customerPaymentProfileId: profile.CustomerPaymentProfileId,
          })),
        );

        setShowPaymentProfileLoader(false);
      },
      (err) => {
        setPaymentProfilesError(err);
        setShowPaymentProfileLoader(false);
      },
    );
  }, []);

  useEffect(() => {
    getServiceFee().subscribe((fee) => {
      setServiceFee(fee);
    }, setPaymentProfilesError);
    return () => {
      sessionStorage.clear();
    };
  }, []);

  const checkShowDefaultButton = () => {
    if (paymentProfiles.hasOwnProperty('CustomerPaymentProfiles')) {
      const profile = paymentProfiles.CustomerPaymentProfiles.filter(
        (f) => f.CustomerPaymentProfileId == paymentMethod,
      );
      if (profile[0]) setShowDefaultButton(!profile[0].IsDefault);
    }
  };

  useEffect(() => {
    checkShowDefaultButton();
  }, [paymentMethod, paymentProfiles]);

  const onHandleChange = (prop, value) => {
    sessionStorage.setItem(prop, value);
    switch (prop) {
      case 'cardNumber':
        setCardNumber(value);
        break;
      case 'expirationMonth':
        setExpirationMonth(value);
        break;
      case 'cvv':
        setCvv(value);
        break;
      case 'expirationYear':
        // change due to value as 2 digit as stripe validation
        setExpirationYear(value.slice(2, 4));
        break;
      default:
        break;
    }
    checkCardButtonStatus();
  };

  const checkCardButtonStatus = () => {
    const cardNumber = sessionStorage.getItem('cardNumber');
    const cvv = sessionStorage.getItem('cvv');
    const expirationMonth = sessionStorage.getItem('expirationMonth');
    const expirationYear = sessionStorage.getItem('expirationYear');
    const status =
      cardNumber &&
      cardNumber.length === 19 &&
      !isNaN(cardNumber.replaceAll(' ', '')) &&
      cvv &&
      cvv.length === 3 &&
      expirationMonth &&
      expirationMonth.length === 2 &&
      expirationYear &&
      expirationYear.length === 4;
    setIsAddEnabled(status);
    setDefaultCard(status);
  };

  const onLightBoxSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setNewPaymentProfileError('');

      addPaymentProfile(
        corporateGroupId,
        cardNumber,
        expirationMonth,
        expirationYear,
        cvv,
        defaultCard,
      ).subscribe((profile) => {
        paymentProfiles.CustomerPaymentProfiles.push({
          CustomerPaymentProfileId: profile.CustomerPaymentProfileId,
          IsDefault: defaultCard,
          Payment: {
            CardNumber: cardNumber,
            CardType: 'Visa',
            ExpirationDate: expirationYear,
          },
        });
        setPaymentProfiles(paymentProfiles);
        setCreditCardArray([
          ...creditCardArray,
          {
            cardNumber,
            customerPaymentProfileId: profile.CustomerPaymentProfileId,
          },
        ]);

        onSelectPaymentMethod(profile.CustomerPaymentProfileId);
        sessionStorage.clear();
        setCardNumber('');
        setExpirationMonth('');
        setExpirationYear('');
        setCvv('');
        checkCardButtonStatus();
      }, setNewPaymentProfileError);
    },
    [
      addPaymentProfile,
      creditCardArray,
      corporateGroupId,
      cardNumber,
      expirationMonth,
      expirationYear,
      cvv,
      defaultCard,
      setCreditCardArray,
      onSelectPaymentMethod,
      setNewPaymentProfileError,
    ],
  );

  const cardList = useMemo(
    () =>
      creditCardArray.map((creditCard) => ({
        label: `•••• •••• •••• ${creditCard.cardNumber.slice(-4)}`,
        value: creditCard.customerPaymentProfileId,
      })),
    [creditCardArray],
  );

  const selectedPaymentMethod = useMemo(
    () => (paymentMethod ? cardList.find(({ value }) => value === paymentMethod) : null),
    [paymentMethod, cardList],
  );

  const defaultPaymentMethod = useMemo(
    () =>
      paymentProfiles &&
      paymentProfiles.CustomerPaymentProfiles &&
      paymentProfiles.CustomerPaymentProfiles.find(({ IsDefault }) => IsDefault),
    [paymentProfiles],
  );

  const defaultPaymentId = useMemo(
    () => defaultPaymentMethod && defaultPaymentMethod.CustomerPaymentProfileId,
    [defaultPaymentMethod],
  );

  const defaultPaymentIndex = useMemo(
    () => (defaultPaymentId ? cardList.findIndex(({ value }) => value === defaultPaymentId) : -1),
    [defaultPaymentId, cardList],
  );

  const gigCost = useMemo(
    () =>
      prizes.length &&
      prizes.map((item) => item.PrizeAmount * item.WinnerQty).reduce((a, b) => Number(a + b)),
    [prizes],
  );

  const activeGig = useMemo(() => GIG_PACKAGES.find((item) => item.type === gigType), [gigType]);

  const isConfirmButtonDisabled = useMemo(
    () => isChargingGig || (!paymentMethod && !isGigCostCovered && !frankiFunded) || loading,
    [isChargingGig, paymentMethod, isGigCostCovered, frankiFunded, loading],
  );

  const removeSelectedPaymentMethod = () => {
    setPaymentProfilesError('');
    removePaymentProfile(corporateGroupId, paymentMethod).subscribe(() => {
      if (paymentProfiles.CustomerPaymentProfiles.length > 0) {
        paymentProfiles.CustomerPaymentProfiles = paymentProfiles.CustomerPaymentProfiles.filter(
          (f) => f.CustomerPaymentProfileId != paymentMethod,
        );
        const _defaultPaymentMethod = paymentProfiles.CustomerPaymentProfiles.find(
          ({ IsDefault }) => IsDefault,
        );

        setPaymentProfiles(paymentProfiles);
        if (_defaultPaymentMethod) {
          onSelectPaymentMethod(_defaultPaymentMethod.CustomerPaymentProfileId);
        }

        setCreditCardArray(
          paymentProfiles.CustomerPaymentProfiles.map((profile) => ({
            cardNumber: profile.Payment.CardNumber,
            customerPaymentProfileId: profile.CustomerPaymentProfileId,
          })),
        );
      }
    }, setPaymentProfilesError);
  };

  const setAsDefaultSelectedPayment = () => {
    setDefaultPaymentProfile(paymentMethod, corporateGroupId).subscribe(() => {
      paymentProfiles.CustomerPaymentProfiles.map((f) => {
        if (f.IsDefault) {
          f.IsDefault = false;
        }
        if (f.CustomerPaymentProfileId == paymentMethod) {
          f.IsDefault = true;
          onSelectPaymentMethod(f.CustomerPaymentProfileId);
          setShowDefaultButton(false);
        }
      });
      setPaymentProfiles(paymentProfiles);
    }, setNewPaymentProfileError);
  };

  const onClickTooltipContent = (e) => {
    // do not hide tooltip when clicking inside tooltip content area
    e.stopPropagation();
  };

  return (
    <>
      <StepsHeader
        maxSteps={5}
        step={5}
        subtitle="Time to publish your GiG. Last step - fund it!"
        title={<div>Launch your GiG</div>}
      />
      <AsyncContent
        content={() => (
          <div className="BusinessGigCreateFifthStep">
            <div className="BusinessGigCreateFifthStep__Content">
              <GigPackageCard {...activeGig} key={gigType} active />
              <div className="BusinessGigCreateFifthStep__Content__Main">
                <div className="BusinessGigCreateFifthStep__Content__Main__Group">
                  <fieldset>
                    <legend className="BusinessGigCreateFifthStep__Label">Summary</legend>
                    <span className="label BusinessGigCreateFifthStep__Subtitle">
                      Please review the cost breakdown below
                    </span>

                    <PaymentBox
                      setIsGigCostCoveredState={setIsGigCostCoveredState}
                      setTotal={setTotal}
                      prizes={prizes}
                      totalCostItems={frankiFunded ? 0 : gigCost}
                      serviceFeePercent={serviceFee}
                    />
                  </fieldset>
                  {hasFrankiFundedCheckbox() && (
                    <fieldset className="BusinessGigCreateFifthStep__Checkbox">
                      <label>
                        <input
                          checked={frankiFunded}
                          name="frankiFunded"
                          onChange={(event) => {
                            setFrankiFunded(event.target.checked);
                            updateFrankiFunded(event.target.checked);
                          }}
                          type="checkbox"
                        />
                        <span>Does not require payment!</span>
                      </label>
                    </fieldset>
                  )}
                </div>

                <div className="BusinessGigCreateFifthStep__Content__Main__Group">
                  {!frankiFunded && (
                    <div className="BusinessGigCreateFifthStep__PaymentMethod">
                      <fieldset>
                        <legend className="BusinessGigCreateFifthStep__Label">
                          Add New Payment Method
                          {!isGigCostCovered && <span className="required-asterisk">*</span>}
                        </legend>
                        <span className="label BusinessGigCreateFifthStep__Subtitle">
                          Select a card or add a new one
                        </span>

                        <div className="BusinessGigCreateFifthStep__AddPaymentMethod">
                          <button
                            className="BusinessGigCreateFifthStep__AddPaymentMethodButton"
                            onClick={() => setDialogVisibility(!dialogVisibility)}
                            type="button"
                          >
                            + ADD PAYMENT METHOD
                          </button>

                          <span className="BusinessGigCreateFifthStep__CreditcardInfo">
                            Your card will be secure for future use.
                          </span>
                          <div
                            className={`BusinessGigCreateFifthStep__AddPaymentMethodForm ${dialogVisibility ? 'visible' : ''
                              }`}
                          >
                            <br />
                            <form
                              method="POST"
                              onSubmit={onLightBoxSubmit}
                              className="CreditCardForm"
                            >
                              <div className="CreditCardForm__Fields">
                                <CreditCardForm
                                  cardNumber={cardNumber}
                                  expirationYear={expirationYear}
                                  expirationMonth={expirationMonth}
                                  cvv={cvv}
                                  onHandleChange={onHandleChange}
                                />

                                <div className="BusinessGigCreateFifthStep__SetDefaultCard">
                                  <label className="BusinessGigCreateFifthStep__LightBox__Label">
                                    <input
                                      checked={defaultCard}
                                      name="defaultCard"
                                      onChange={() => setDefaultCard(!defaultCard)}
                                      type="checkbox"
                                      value={defaultCard}
                                    />
                                    Use this card as default
                                  </label>
                                </div>
                              </div>

                              <div className="BusinessGigCreateFifthStep__LightBox__Buttons">
                                <button
                                  disabled={!isAddEnabled}
                                  className="button small button--primary"
                                  type="submit"
                                >
                                  Add
                                </button>
                                <button
                                  className="button small button--text"
                                  onClick={cancel}
                                  type="button"
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </fieldset>

                      {isEmpty(creditCardArray) ? (
                        <div className="BusinessGigCreateFifthStep__Label">
                          {showPaymentProfileLoader && (
                            <fieldset>
                              <p>
                                <img src={DotsLoader} width={48} />
                              </p>
                            </fieldset>
                          )}
                        </div>
                      ) : (
                        <fieldset>
                          <legend className="BusinessGigCreateFifthStep__Label">
                            Select Payment Method
                            {!isGigCostCovered && <span className="required-asterisk">*</span>}
                          </legend>

                          <div className="BusinessGigCreateFifthStep__AddPaymentMethod">
                            <div className="BusinessGigCreateFifthStep__AddPaymentMethod__divEmail">
                              <form id="formPaymentEmail">
                                <label htmlFor="txtPaymentEmail">Send Me Email:</label>
                                <input
                                  type="email"
                                  className="full"
                                  name="email"
                                  id="txtPaymentEmail"
                                  onChange={({ target }) => setEmail(target.value)}
                                />
                              </form>
                            </div>
                            {
                              <CustomSelect
                                additionalStyles={`
                                    .BusinessGigCreateFifthStep__PaymentMethodSelect .CustomSelect__option--is-selected:nth-of-type(${defaultPaymentIndex + 1
                                  }):after {
                                      color: #ADE270;
                                      content: "\\ea10";
                                      font-family: 'Franki-Admin' !important;
                                      position: absolute;
                                      right: 15px;
                                      top: 8px;
                                    }
                                  `}
                                className="full BusinessGigCreateFifthStep__PaymentMethodSelect"
                                id="txtPaymentMethod"
                                name="paymentMethod"
                                onChange={({ value: val }) => onSelectPaymentMethod(val)}
                                options={[...cardList]}
                                placeholder="payment method"
                                required={!isGigCostCovered}
                                value={selectedPaymentMethod}
                              />
                            }
                            <div className="BusinessGigCreateFifthStep__DeleteBtn">
                              <button
                                type="button"
                                className="button small red-theme"
                                onClick={() => removeSelectedPaymentMethod()}
                              >
                                <i className="IconClass icon-close" />
                                Remove
                              </button>
                              {showDefaultButton && (
                                <button
                                  type="button"
                                  className="button small button--primary--green"
                                  onClick={() => setAsDefaultSelectedPayment()}
                                >
                                  <i className="IconClass icon-success" />
                                  Use This as Default
                                </button>
                              )}
                            </div>
                          </div>
                        </fieldset>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="buttons-actions">
              <div className="BusinessGigCreateFifthStep__Actions">
                <button
                  type="button"
                  className="button small button--outline"
                  onClick={onHandleBack}
                >
                  BACK
                </button>
                <button
                  ref={toolTipRef}
                  data-tip
                  data-for="paymentToolTip"
                  data-event="-"
                  disabled={isConfirmButtonDisabled}
                  onClick={onHandleSubmit}
                  type="button"
                  className="button small button--primary button--primary--green"
                >
                  {isChargingGig ? 'Loading...' : 'Confirm'}
                </button>
                {!showLearnMore && (
                  <ReactTooltip
                    textColor="#4F4F4F"
                    borderColor="#E0E0E0"
                    border="1px solid"
                    backgroundColor="#FFFFFF"
                    id="paymentToolTip"
                    type="info"
                    effect="solid"
                    globalEventOff="click"
                    getContent={() => toolTipContent()}
                  />
                )}
                {showLearnMore && (
                  <ReactTooltip
                    textColor="#4F4F4F"
                    borderColor="#E0E0E0"
                    border="1px solid"
                    backgroundColor="#FFFFFF"
                    id="paymentToolTip"
                    type="info"
                    effect="solid"
                    globalEventOff="click"
                    getContent={() => toolTipContentExtended()}
                  />
                )}
              </div>
              <p className="gigsForm-legal">
                Franki currently services selected areas in the United States.
                <br />
                By clicking 'Confirm' you are agreeing to franki&rsquo;s&nbsp;
                <a href="https://befranki.com/terms-of-service/" target="_blank">
                  Terms of Service
                </a>
                {'.'}
              </p>
            </div>
          </div>
        )}
        errorMessage={paymentProfilesError || chargeError || newPaymentProfileError}
        expectNoData
      />
    </>
  );
}

BusinessGigCreateFifthStep.propTypes = {
  chargeError: string.isRequired,
  corporateGroupId: string.isRequired,
  isChargingGig: bool.isRequired,
  isGigCostCovered: bool.isRequired,
  onHandlePaymentSelect: func.isRequired,
  onHandleSubmit: func.isRequired,
  prizes: arrayOf(PRIZE_TYPE).isRequired,
  setIsGigCostCoveredState: func.isRequired,
  setTotal: func.isRequired,
  updateFrankiFunded: func.isRequired,
  loading: bool,
  gigType: string,
  onHandleBack: func.isRequired,
};

BusinessGigCreateFifthStep.defaultProps = {
  gigType: null,
  loading: null,
};
