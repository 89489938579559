import { bool } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import MasterPage from '../../../components/MasterPage';
import NotFound from '../../../components/NotFound';
import USER_ROLES from '../../../config/user-roles';

const Forbidden404 = (props) => {
  const { auth } = props;
  const userRole = localStorage.getItem('frankiRole');
  const corporateGroupId = localStorage.getItem('corporateGroupId');

  return (
    <>
      <Helmet title="Franki Admin: 404" />

      <MasterPage auth={auth}>
        <NotFound
          title="page not found"
          description="we know where the party's at but we don't know where you are! sorry!"
          links={[
            <Link
              to={`${
                userRole == USER_ROLES.businessManager
                  ? `/admin/business-profile/${corporateGroupId}`
                  : '/admin/businesses/8/1?orderBy=recentlyCreated'
              }`}
              className="button purple-theme"
            >
              Home
            </Link>,
            <a href="mailto:customersupport@befranki.com" className="button purple-theme">
              Contact Support
            </a>,
          ]}
        />
      </MasterPage>
    </>
  );
};

Forbidden404.propTypes = {
  auth: bool.isRequired,
};

export default Forbidden404;
