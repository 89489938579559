const getCurrentQuery = () => {
  // eslint-disable-next-line no-undef
  const { innerWidth } = window;
  if (innerWidth > 1024) {
    return 'lg';
  }

  if (innerWidth > 812) {
    return 'md';
  }

  if (innerWidth > 425) {
    return 'sm';
  }

  return 'xs';
};

export default getCurrentQuery;
