import './index.sass';

import React from 'react';
import { string } from 'prop-types';

const Footer = (props) => {
  const { className } = props;
  return (
    <footer className={`footer ${className}`}>
      <div className="footer-copyright">
        <div className="wrapper">
          <span>
            © {new Date().getFullYear()} Franki Global Inc, all rights reserved. franki® is a
            registered trademark of Franki Global Inc
          </span>

          <div className="footer-copyright-links">
            <a href="https://befranki.com/terms-of-service/" target="_blank">
              Terms of Service
            </a>
            <a href="https://befranki.com/privacy-policy" target="_blank">
              Privacy Policy
            </a>
            <a href="https://befranki.com/terms-of-use" target="_blank">
              Terms Of Use
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: string,
};

Footer.defaultProps = {
  className: '',
};

export default Footer;
