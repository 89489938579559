import './index.sass';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  assignUserAsBusinessManager,
  getBusinessClaimDetails,
  getBusinessSummaryFourSquare,
  getBusinessSummaryFranki,
} from '../../actions/businesses';
import AddressTagsInput from '../../components/AddressTagsInput';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import LightBox from '../../components/LightBox';
import HISTORY_TYPE from '../../types/history';
import { getPlaceIdProp } from '../../utils/placeId';
import { Col, Row } from '../../components/Grid';
import BusinessAutocomplete from '../../components/BusinessAutocomplete';
import addBusinessImg from '../../assets/images/add-business.svg';
import { logout } from '../../actions/auth';

/* global localStorage, navigator */
function ClaimMyBusiness(props) {
  const { history } = props;
  const [placeId, setPlaceId] = useState(null);
  const [submitted, setSubmitted] = useState(null);
  const [isDialogVisible, setIsDialogVisible] = useState(null);
  const [isBusinessExistDialogVisible, setIsBusinessExistDialogVisible] = useState(false);
  const [isUserLoginDialogVisible, setIsUserLoginDialogVisible] = useState(false);
  const [businessSummary, setBusinessSummary] = useState(null);
  const [isFetchingBusinessSummary, setIsFetchingBusinessSummary] = useState(false);
  const [businessClaimDetails, setBusinessClaimDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [businessFieldEnabled, setBusinessFieldEnabled] = useState(false);
  const [isFetchingBusinessClaimDetails, setIsFetchingBusinessClaimDetails] = useState(false);
  const [clearValue, setClearValue] = useState(false);
  const placeIdProp = getPlaceIdProp();
  const [nonFourSquare, setNonFourSquare] = useState(false);
  const [businessExist, setBusinessExist] = useState(false);

  const { IsClaimed, Claimers } = businessClaimDetails || {};
  const isFetching = isFetchingBusinessClaimDetails || isFetchingBusinessSummary;

  const callPlaceAPI = (selectedBusiness) => {
    const { ThirdPartyBusinessSourceId, BusinessId } = selectedBusiness;
    let callPlaceId = ThirdPartyBusinessSourceId;
    let hasFourSquareID = true;
    setPlaceId(callPlaceId);

    if (!ThirdPartyBusinessSourceId) {
      setNonFourSquare(true);
      hasFourSquareID = false;
      callPlaceId = BusinessId;
    }

    if (BusinessId) {
      setBusinessExist(true);
    }

    setIsFetchingBusinessSummary(true);
    setBusinessSummary(null);
    setErrorMessage(null);
    setIsDialogVisible(false);
    setSubmitted(false);
    (hasFourSquareID ? getBusinessSummaryFourSquare : getBusinessSummaryFranki)(
      callPlaceId,
    ).subscribe(
      (response) => {
        setBusinessSummary(response);
      },
      (err) => {
        setErrorMessage(err);
        setIsFetchingBusinessSummary(false);
      },
      () => setIsFetchingBusinessSummary(false),
    );
  };

  const goToSignUp = () => {
    if (!businessSummary) return;
    const { BusinessId } = businessSummary;
    history.push({
      pathname: `/sign-up/${BusinessId}?${placeIdProp.query}=${placeId}`,
      state: {
        businessId: BusinessId,
        thirdPartySourceId: placeId,
        nonFourSquare,
      },
    });
  };

  const restartForm = () => {
    history.push('claim-my-business');
  };

  const onCreateNewUser = () => {
    logout().subscribe(() => {
      localStorage.removeItem('frankiRole');
      localStorage.removeItem('frankiToken');
      localStorage.removeItem('frankiUser');
      localStorage.removeItem('frankiName');
      goToSignUp();
    });
  };

  const onUseCurrentUser = () => {
    const { BusinessId } = businessSummary;
    assignUserAsBusinessManager(
      nonFourSquare ? { BusinessId } : { [placeIdProp.api]: placeId },
      nonFourSquare,
    ).subscribe(
      ({ BusinessId: id }) => {
        history.push({
          pathname: `/business-phone-verification/${id}?${placeIdProp.query}=${
            businessSummary[placeIdProp.api]
          }`,
          state: { businessId: id, hideStep: true, nonFourSquare },
        });
      },
      () => {
        history.push({
          pathname: `/business-phone-verification/${BusinessId}?${placeIdProp.query}=${
            businessSummary[placeIdProp.api]
          }`,
          state: { businessId: BusinessId, hideStep: true, nonFourSquare },
        });
      },
    );
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  const onChangeCity = (val) => {
    localStorage.setItem('cityName', val);
    setCityName(val);
  };

  const ResultsFooter = () => (
    <div className="ResultsFooter">
      <p className="ResultsFooter__Text simple-text text-left my-3">
        Didn’t find your business?
        <br />
        Add it to franki for free.
      </p>
      <button
        className="ResultsFooter__AddButton button small orange-theme"
        onClick={() => history.push('/create-business-profile')}
        type="button"
      >
        ADD TO FRANKI
      </button>
    </div>
  );

  const NotFoundContent = () => (
    <div className="NotFoundContent">
      <img src={addBusinessImg} alt="" className="NotFoundContent__Img" />
      <span className="NotFoundContent__Title">Add your business to franki</span>
      <p className="NotFoundContent__Text">
        We couldn’t find this business.
        <br />
        Is the business name correct? If so, you can add your business to franki for free.
      </p>
      <button
        className="NotFoundContent__AddButton button small orange-theme"
        onClick={() => history.push('/create-business-profile')}
        type="button"
      >
        ADD TO FRANKI
      </button>
    </div>
  );

  useEffect(() => {
    if (
      businessSummary &&
      !isBusinessExistDialogVisible &&
      !isUserLoginDialogVisible &&
      !businessClaimDetails
    ) {
      setIsFetchingBusinessClaimDetails(true);
      setBusinessClaimDetails(null);
      setIsDialogVisible(true);
      getBusinessClaimDetails(businessSummary.BusinessId).subscribe(
        (claimResponse) => {
          setBusinessClaimDetails(claimResponse);
          if ((nonFourSquare || businessExist) && !claimResponse.IsClaimed) {
            setIsBusinessExistDialogVisible(true);
          }
        },
        (err) => {
          setErrorMessage(err);
          setIsFetchingBusinessClaimDetails(false);
        },
        () => setIsFetchingBusinessClaimDetails(false),
      );
    }
    const storedError = localStorage.getItem('errorMessage');
    if (storedError) {
      setErrorAlert(storedError);
      localStorage.removeItem('errorMessage');
    }
  }, [
    businessSummary,
    isBusinessExistDialogVisible,
    isUserLoginDialogVisible,
    businessClaimDetails,
  ]);

  useEffect(() => {
    if (!isDialogVisible) {
      setSubmitted(false);
      setErrorMessage(null);
    }
  }, [isDialogVisible]);

  useEffect(() => {
    if (submitted && businessClaimDetails && !businessClaimDetails.IsClaimed) {
      if (!localStorage.getItem('frankiToken')) {
        goToSignUp();
      } else {
        setIsUserLoginDialogVisible(true);
      }
    }
  }, [submitted, businessClaimDetails]);

  useEffect(() => {
    setBusinessFieldEnabled(!!cityName);
    setClearValue(!clearValue);
  }, [cityName]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        localStorage.setItem('latitude', latitude);
        localStorage.setItem('longitude', longitude);
      },
      () => {
        localStorage.removeItem('latitude');
        localStorage.removeItem('longitude');
      },
    );
  }, []);

  return (
    <div className="ClaimMyBusiness">
      <Header auth={false} global />

      <main className="ClaimMyBusiness__Main">
        {errorAlert && (
          <div className="errorAlert">
            <span className="AllGigsCard__Informations__Status__Type">{errorAlert}</span>
          </div>
        )}

        <h1>Welcome! First, let’s start by looking up your business</h1>
        <p className="ClaimMyBusiness__Subtitle">
          We’ll use this information to help you claim your franki listing. Your business will come
          up automatically, if you can’t find it, you can add it.
        </p>

        <form onSubmit={(e) => onHandleSubmit(e)}>
          <Row cellSpacing={15} rowSpacing={10}>
            <Col lg={6} md={6} sm={6} xs={12}>
              <fieldset>
                <div className="ClaimMyBusiness__InputTitle">
                  <label htmlFor="txtBusinessName">
                    City Name
                    <span className="required-asterisk">*</span>
                  </label>
                </div>
                <AddressTagsInput
                  id="searchCity"
                  disabled={false}
                  placeholder="type the location of your business here"
                  className="round-corner"
                  listOfTags={cityName}
                  name="cityName"
                  onChange={onChangeCity}
                  value={cityName}
                  filter={{
                    types: ['(cities)'],
                  }}
                  noBrowserComplete
                />
              </fieldset>
            </Col>

            <Col lg={6} md={6} sm={6} xs={12}>
              <fieldset>
                <div className="ClaimMyBusiness__InputTitle">
                  <label htmlFor="txtBusinessName">
                    Business Name
                    <span className="required-asterisk">*</span>
                  </label>
                  <button onClick={() => history.push('/contact-sales-force')} type="button">
                    Can’t find your business?
                  </button>
                </div>

                <BusinessAutocomplete
                  disabled={!businessFieldEnabled}
                  clearValue={clearValue}
                  allBusinesses
                  setBusinessLocationFourSquare={({ value }) => callPlaceAPI(value)}
                  placeholder="type your business name here"
                  resultsFooter={ResultsFooter}
                  notFoundContent={NotFoundContent}
                />
              </fieldset>
            </Col>
          </Row>

          <button
            className="button orange-theme"
            disabled={
              (isFetching && submitted) ||
              !businessSummary ||
              !businessFieldEnabled ||
              (businessClaimDetails && businessClaimDetails.IsClaimed)
            }
            type="submit"
          >
            {isFetching ? 'loading...' : 'Continue'}
          </button>

          {errorMessage && <div className="ClaimMyBusiness__Error">{errorMessage}</div>}
        </form>
      </main>

      <Footer />

      <LightBox
        onClose={restartForm /* () => setIsDialogVisible(false) */}
        show={IsClaimed === true && isDialogVisible && !isFetching}
        title="This business page has already been claimed"
        className="ClaimMyBusiness__LightBox"
        style={{
          width: 600,
          height: null,
          marginLeft: -300,
          marginTop: -200,
        }}
      >
        <div className="ClaimMyBusiness__LightBox__Text">
          <p>
            This business page already has been claimed by&nbsp;
            {Claimers && Claimers.join(', ')}
          </p>

          <p>
            If this is you, please <Link to="/">log in</Link> or{' '}
            <Link to="/forgot-password">reset password</Link>
          </p>

          <p className="mb-5">
            If you do not know who this is please{' '}
            <Link to="/contact-sales-force">contact support.</Link>
          </p>
        </div>
      </LightBox>

      <LightBox
        onClose={restartForm /* () => setIsBusinessExistDialogVisible(false) */}
        show={isBusinessExistDialogVisible}
        title="Business profile already exists on franki"
        className="ClaimMyBusiness__LightBox"
        style={{
          width: 600,
          height: null,
          marginLeft: -300,
          marginTop: -200,
        }}
      >
        <div className="ClaimMyBusiness__LightBox__Text">
          <p>This business profile already exists, do you want to claim this business?</p>

          <div>
            <button
              type="button"
              className="button small orange-theme"
              onClick={() => {
                setIsBusinessExistDialogVisible(false);
                setSubmitted(true);
              }}
            >
              YES
            </button>

            <button type="button" className="button small gray-theme" onClick={restartForm}>
              NO
            </button>
          </div>

          <p className="mb-5">
            If you are unsure please <Link to="/contact-sales-force">contact support.</Link>
          </p>
        </div>
      </LightBox>

      <LightBox
        onClose={() => setIsUserLoginDialogVisible(false)}
        show={isUserLoginDialogVisible}
        title="It looks like you are already logged in"
        className="ClaimMyBusiness__LightBox"
        style={{
          width: 600,
          height: null,
          marginLeft: -300,
          marginTop: -200,
        }}
      >
        <div className="ClaimMyBusiness__LightBox__Text">
          <p>Would you like to use this email for sign up or create a new user?</p>

          <div>
            <button type="button" className="button small orange-theme" onClick={onCreateNewUser}>
              CREATE
            </button>
            <button type="button" className="button small gray-theme" onClick={onUseCurrentUser}>
              USE EMAIL
            </button>
          </div>
          <p className="mb-5">
            If you are unsure please <Link to="/contact-sales-force">contact support.</Link>
          </p>
        </div>
      </LightBox>
    </div>
  );
}

ClaimMyBusiness.propTypes = {
  history: HISTORY_TYPE.isRequired,
};

export default ClaimMyBusiness;
