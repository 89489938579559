import './index.sass';
import { bool, func, object, oneOfType, string } from 'prop-types';
import React from 'react';
import BusinessLogo from './BusinessLogo';
import BusinessBanner from './BusinessBanner';
import BusinessTitle from './BusinessTitle';


const BusinessHeader = (props) => {
  const {
    logoUrl,
    bannerUrl,
    onHandleChange,
    onLogoUrlChange,
    onBannerUrlChange,
    businessProfile,
    nonFourSquare,
  } = props;


  return (
    <div className="BusinessHeader">
      <div className="wrapper">
        <BusinessBanner
          bannerUrl={bannerUrl}
          onHandleChange={onHandleChange}
          onBannerUrlChange={onBannerUrlChange}
          nonFourSquare={nonFourSquare}
        />
        <div className="BusinessHeader__Info">
          <BusinessLogo
            logoUrl={logoUrl}
            onHandleChange={onHandleChange}
            onLogoUrlChange={onLogoUrlChange}
          />
          <BusinessTitle
            businessProfile={businessProfile}
          />
        </div>
      </div>
    </div>
  );
};

BusinessHeader.propTypes = {
  logoUrl: string.isRequired,
  bannerUrl: string.isRequired,
  onHandleChange: func.isRequired,
  onLogoUrlChange: func.isRequired,
  onBannerUrlChange: func.isRequired,
  businessProfile: oneOfType([object]).isRequired,
  nonFourSquare: bool.isRequired,
};


export default BusinessHeader;
