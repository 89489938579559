import './index.sass';

import { bool, func } from 'prop-types';
import React from 'react';

import facebookIMG from '../../../../assets/images/facebook.png';
import instagramIMG from '../../../../assets/images/instagram.png';
import otherMediaIMG from '../../../../assets/images/other-media.svg';
import tiktokIMG from '../../../../assets/images/tiktok.svg';
import twitterIMG from '../../../../assets/images/twitter.png';
import AsyncContent from '../../../../components/AsyncContent';
import SocialAccountsSelect from '../../../../components/SocialAccountsSelect';
import { validWebsite } from '../../../../helpers';
import { BUSINESS_DETAILS_TYPE } from '../../../../types/business';

const SOCIAL_MEDIA_ACCOUNTS = [
  { label: 'Facebook link', value: 'Facebook', icon: facebookIMG },
  { label: 'Instagram link', value: 'Instagram', icon: instagramIMG },
  { label: 'Twitter link', value: 'Twitter', icon: twitterIMG },
  { label: 'Tiktok link', value: 'Tiktok', icon: tiktokIMG },
  {
    label: 'Other (Youtube, Linkedin, Indeed, Pinterest, Snapchat) link',
    value: 'OtherSocialMediaLink',
    icon: otherMediaIMG,
  },
];

/* IMAGES */
class BusinessSocialInformationForm extends React.PureComponent {
  state = {
    selectedAccounts: [],
  };

  componentDidMount() {
    const {
      formState: { Facebook, Instagram, Twitter, Tiktok, OtherSocialMediaLink },
    } = this.props;

    const accounts = { Facebook, Instagram, Twitter, Tiktok, OtherSocialMediaLink };

    const accountsNames = Object.keys(accounts);

    const selectedAccountNames = accountsNames.filter((name) => !!accounts[name]);

    this.setState({ selectedAccounts: selectedAccountNames });
  }

  handleChange = (values, actionMeta) => {
    const { onHandleChange } = this.props;

    this.setState({ selectedAccounts: values });

    if (actionMeta && actionMeta.action === 'deselect-option') {
      onHandleChange(actionMeta.option.value, '');
    }
  };

  handleRemoveLink = (name) => {
    const { onHandleChange } = this.props;
    const { selectedAccounts } = this.state;

    const updatedSelectedAccounts = selectedAccounts.filter((value) => value !== name);

    this.setState({ selectedAccounts: updatedSelectedAccounts });

    onHandleChange(name, '');
  };

  render() {
    const {
      formState: { Facebook, Instagram, Twitter, Tiktok, OtherSocialMediaLink },
      isPhoneNumberInvalid,
      onHandleChange,
    } = this.props;

    const { selectedAccounts } = this.state;

    return (
      <AsyncContent
        content={() => (
          <>
            <h2 className="BusinessEdit__Title BusinessEdit__Title--SocialMedia">
              Link social media accounts
            </h2>
            <label htmlFor="socialMediaAccounts" className="simple-text mt-10">
              Social Media Accounts
            </label>
            <SocialAccountsSelect
              id="socialMediaAccounts"
              onChange={(values, actionMeta) => {
                this.handleChange(values, actionMeta);
              }}
              options={SOCIAL_MEDIA_ACCOUNTS}
              values={selectedAccounts}
            />
            {selectedAccounts.includes('Facebook') && (
              <div className="mb-20 BusinessEdit__Blocks BusinessSocialInformationForm__Input">
                <label htmlFor="txtBusinessFacebook">
                  <img src={facebookIMG} alt="facebook" />
                </label>

                <input
                  autoComplete="none"
                  id="txtBusinessFacebook"
                  maxLength="150"
                  ref={(input) => {
                    if (input != null && input.value === '') {
                      input.focus();
                    }
                  }}
                  name="Facebook"
                  onChange={({ target }) => onHandleChange(target.name, target.value)}
                  pattern={validWebsite(Facebook).regexAsString}
                  placeholder="Facebook link"
                  type="text"
                  value={Facebook || ''}
                />

                <button
                  type="button"
                  className="BusinessSocialInformationForm__Input__RemoveButton"
                  onClick={() => this.handleRemoveLink('Facebook')}
                >
                  x
                </button>
              </div>
            )}

            {selectedAccounts.includes('Instagram') && (
              <div className="mb-20 BusinessEdit__Blocks BusinessSocialInformationForm__Input">
                <label htmlFor="txtBusinessInstagram">
                  <img src={instagramIMG} alt="instagram" />
                </label>

                <input
                  autoComplete="none"
                  id="txtBusinessInstagram"
                  maxLength="150"
                  name="Instagram"
                  ref={(input) => {
                    if (input != null && input.value === '') {
                      input.focus();
                    }
                  }}
                  onChange={({ target }) => onHandleChange(target.name, target.value)}
                  pattern={validWebsite(Instagram).regexAsString}
                  placeholder="Instagram link"
                  type="text"
                  value={Instagram || ''}
                />

                <button
                  type="button"
                  className="BusinessSocialInformationForm__Input__RemoveButton"
                  onClick={() => this.handleRemoveLink('Instagram')}
                >
                  x
                </button>
              </div>
            )}

            {selectedAccounts.includes('Twitter') && (
              <div className="mb-20 BusinessEdit__Blocks BusinessSocialInformationForm__Input">
                <label htmlFor="txtBusinessTwitter">
                  <img src={twitterIMG} alt="twitter" />
                </label>

                <input
                  autoComplete="none"
                  id="txtBusinessTwitter"
                  maxLength="150"
                  name="Twitter"
                  ref={(input) => {
                    if (input != null && input.value === '') {
                      input.focus();
                    }
                  }}
                  onChange={({ target }) => onHandleChange(target.name, target.value)}
                  pattern={validWebsite(Twitter).regexAsString}
                  placeholder="Twitter link"
                  type="text"
                  value={Twitter || ''}
                />

                <button
                  type="button"
                  className="BusinessSocialInformationForm__Input__RemoveButton"
                  onClick={() => this.handleRemoveLink('Twitter')}
                >
                  x
                </button>
              </div>
            )}

            {selectedAccounts.includes('Tiktok') && (
              <div className="mb-20 BusinessEdit__Blocks BusinessSocialInformationForm__Input">
                <label htmlFor="txtBusinessTiktok">
                  <img src={tiktokIMG} alt="tiktok" />
                </label>

                <input
                  autoComplete="none"
                  id="txtBusinessTiktok"
                  maxLength="150"
                  name="Tiktok"
                  ref={(input) => {
                    if (input != null && input.value === '') {
                      input.focus();
                    }
                  }}
                  onChange={({ target }) => onHandleChange(target.name, target.value)}
                  pattern={validWebsite(Tiktok).regexAsString}
                  placeholder="Tiktok link"
                  type="text"
                  value={Tiktok || ''}
                />

                <button
                  type="button"
                  className="BusinessSocialInformationForm__Input__RemoveButton"
                  onClick={() => this.handleRemoveLink('Tiktok')}
                >
                  x
                </button>
              </div>
            )}

            {selectedAccounts.includes('OtherSocialMediaLink') && (
              <div className="mb-20 BusinessEdit__Blocks BusinessSocialInformationForm__Input">
                <label htmlFor="txtBusinessOther">
                  <img src={otherMediaIMG} alt="tiktok" />
                </label>

                <input
                  autoComplete="none"
                  id="txtBusinessOther"
                  maxLength="150"
                  name="OtherSocialMediaLink"
                  ref={(input) => {
                    if (input != null && input.value === '') {
                      input.focus();
                    }
                  }}
                  onChange={({ target }) => onHandleChange(target.name, target.value)}
                  pattern={validWebsite(OtherSocialMediaLink).regexAsString}
                  placeholder="Other (Youtube, Linkedin, Indeed, Pinterest, Snapchat) link"
                  type="text"
                  value={OtherSocialMediaLink || ''}
                />

                <button
                  type="button"
                  className="BusinessSocialInformationForm__Input__RemoveButton"
                  onClick={() => this.handleRemoveLink('OtherSocialMediaLink')}
                >
                  x
                </button>
              </div>
            )}
          </>
        )}
        errorMessage={isPhoneNumberInvalid ? 'Phone number is invalid' : ''}
        expectNoData
        type="error"
      />
    );
  }
}

BusinessSocialInformationForm.propTypes = {
  formState: BUSINESS_DETAILS_TYPE.isRequired,
  isPhoneNumberInvalid: bool,
  onHandleChange: func.isRequired,
};

BusinessSocialInformationForm.defaultProps = {
  isPhoneNumberInvalid: null,
};

export default BusinessSocialInformationForm;
