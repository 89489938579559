import '../index.sass';

import { bool, func, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { forgotPassword } from '../../../actions/auth';
import AsyncContent from '../../../components/AsyncContent';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import { validEmailAddress } from '../../../helpers';

class ForgotPassword extends React.Component {
  state = {
    Identifier: '',
    errorMessage: null,
    successMessage: null,
    loading: false,
  };

  componentDidUpdate(prevProps) {
    const { errorMessage, forgotPasswordSuccessMessage } = this.props;

    if (prevProps.errorMessage !== errorMessage) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        errorMessage,
        loading: false,
      });
    }

    if (prevProps.forgotPasswordSuccessMessage !== forgotPasswordSuccessMessage) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        successMessage: forgotPasswordSuccessMessage,
        loading: false,
        Identifier: '',
      });
    }
  }

  onHandleSubmit = (e) => {
    e.preventDefault();

    const { forgotPassword: forgotPasswordAction } = this.props;
    const { Identifier } = this.state;

    this.setState({ loading: true });

    forgotPasswordAction({ Identifier });
  };

  onHandleChange = (name, value) => {
    this.setState({
      [name]: value,
      errorMessage: null,
      successMessage: null,
    });
  };

  render() {
    const { Identifier, errorMessage, successMessage, loading } = this.state;

    const { auth } = this.props;

    const validEmail = validEmailAddress(Identifier);

    return (
      <>
        <Header auth={auth} />

        <AsyncContent
          content={() => (
            <main className="AuthMain">
              <div className="wrapper">
                <div className="Auth">
                  <form onSubmit={(e) => this.onHandleSubmit(e)}>
                    <div className="Auth__Header">
                      <h1 className="Auth__Title">Forgot password</h1>
                      <p className="Auth__Subtitle">
                        no problem, just type in your email and follow the instructions
                      </p>
                    </div>

                    <fieldset>
                      <label className="Auth__Label" htmlFor="txtEmail">
                        Email:
                      </label>
                      <input
                        autoComplete="off"
                        className="full"
                        disabled={loading}
                        id="txtEmail"
                        maxLength={60}
                        name="Identifier"
                        onChange={({ target }) => this.onHandleChange(target.name, target.value)}
                        pattern={validEmail.regexAsString}
                        required
                        type="email"
                        value={Identifier}
                      />
                    </fieldset>

                    <div className="Auth__Action">
                      <Link to="/" className="button small purple-theme">
                        Cancel
                      </Link>

                      <button
                        className="button small orange-theme"
                        disabled={loading}
                        type="submit"
                      >
                        {loading ? 'loading...' : 'send'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </main>
          )}
          errorMessage={errorMessage}
          expectNoData
          successMessage={successMessage}
        />
        <Footer />
      </>
    );
  }
}

ForgotPassword.propTypes = {
  auth: bool.isRequired,
  errorMessage: string,
  forgotPassword: func.isRequired,
  forgotPasswordSuccessMessage: string,
};

ForgotPassword.defaultProps = {
  errorMessage: null,
  forgotPasswordSuccessMessage: null,
};

const mapStateToProps = (state) => ({
  errorMessage: state.auth.errorMessage,
  forgotPasswordSuccessMessage: state.auth.forgotPasswordSuccessMessage,
});

export default compose(withRouter, connect(mapStateToProps, { forgotPassword }))(ForgotPassword);
