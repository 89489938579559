import USER_ROLES from '../config/user-roles';

/* global localStorage */
export function isAdminOrSaleAdmin() {
  return [USER_ROLES.admin, USER_ROLES.saleAdmin].indexOf(localStorage.getItem('frankiRole')) > -1;
}

export function isAdmin() {
  return USER_ROLES.admin === localStorage.getItem('frankiRole');
}

export function isSaleAdmin() {
  return USER_ROLES.saleAdmin === localStorage.getItem('frankiRole');
}
