import { instanceOf } from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

import USER_ROLES from '../../config/user-roles';
import Page404 from '../../containers/404';

/* global localStorage */
const PrivateRoute = ({ component: Component, permission, routerProps, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const { history } = props;
      const userRole = localStorage.getItem('frankiRole');
      const userToken = localStorage.getItem('frankiToken');
      if (history.action == 'REPLACE' && history.location.pathname == '/admin/register-business') {
        if (userRole == USER_ROLES.businessManager) {
          const corporateGroupId = localStorage.getItem('corporateGroupId');
          history.push(`/admin/business-profile/${corporateGroupId}`);
          return;
        } else if (userRole == USER_ROLES.contentAdmin) {
          history.push(
            `/admin/videos/8/1?sortingType=1&businessSource=FrankiLocalBusiness&contentStatusIds=1,2`,
          );
          return;
        }
      }

      if (userToken) {
        if (permission.includes(userRole)) {
          return <Component auth userRole={userRole} routerProps={routerProps} {...props} />;
        }

        return (
          <Page404
            auth
            routerPermission={permission}
            userRole={userRole}
            routerProps={routerProps}
            {...props}
          />
        );
      }

      return (
        <Page404 auth={false} routerPermission={permission} routerProps={routerProps} {...props} />
      );
    }}
  />
);

PrivateRoute.propTypes = {
  component: instanceOf(Object).isRequired,
  permission: instanceOf(Array).isRequired,
  routerProps: instanceOf(Object),
};

PrivateRoute.defaultProps = {
  routerProps: {},
};

export default PrivateRoute;
