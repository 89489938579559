import './index.sass';

import { bool, func, string } from 'prop-types';
import React from 'react';

import LightBox from '../../../../components/LightBox';

const BusinessInfoAppPreview = (props) => {
  const { businessPreviewImg, onChange, show } = props;

  return (
    <LightBox
      className="BusinessInfoAppPreview"
      height={580}
      onClose={() => onChange('appPreviewLightBox', false)}
      show={show}
      title="Business Profile app preview"
      width={380}
    >
      <p className="BusinessInfoAppPreview__Description">
        This is what your profile will look like in franki app!
      </p>

      {businessPreviewImg && (
        <figure className="BusinessInfoAppPreview__Image">
          <img src={businessPreviewImg} alt="Business Profile app preview" />
        </figure>
      )}
    </LightBox>
  );
};

BusinessInfoAppPreview.propTypes = {
  businessPreviewImg: string,
  onChange: func.isRequired,
  show: bool.isRequired,
};

BusinessInfoAppPreview.defaultProps = {
  businessPreviewImg: undefined,
};

export default BusinessInfoAppPreview;
