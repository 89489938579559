import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';

export const FILTER_ITEMS = {
  businessName: arrayOf(shape({ label: string, value: string })),
  businessTypes: arrayOf(shape({ label: string, value: oneOfType([string, number]) })),
  closingDateFrom: string,
  closingDateTo: string,
  creators: arrayOf(shape({ label: string, value: oneOfType([string, number]) })),
  fromDate: string,
  gigStatus: arrayOf(string),
  hasGigs: bool,
  location: string,
  prizeAmount: arrayOf(number),
  products: arrayOf(shape({ label: string, value: string })),
  ratings: arrayOf(number),
  rating: bool,
  reviewType: arrayOf(string),
  showDeactivatedBusiness: bool,
  state: number,
  toDate: string,
  userRoles: arrayOf(string),
  users: arrayOf(shape({ label: string, value: oneOfType([string, number]) })),
  verificationStatus: arrayOf(string),
  contentStatusIds: arrayOf(string),
};

const FILTERS_TYPE = shape(FILTER_ITEMS);

export default FILTERS_TYPE;
