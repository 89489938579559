import './index.sass';

import { bool, elementType, func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import uuidv1 from 'uuid/v1';

import { businessSearch } from '../../actions/businesses';
import googleBusiness from '../../assets/images/google-business.svg';
import { isFoursquareData } from '../../utils/placeId';
import AutoComplete from '../Autocomplete';
import GoogleLocationAutoComplete from '../GoogleLocationAutoComplete';

function renderSearchedItem(business) {
  return (
    <>
      <div className="BusinessAutocomplete__BusinessSuggestion__Icon">
        <img src={googleBusiness} alt="Business Icon" />
      </div>

      <div className="BusinessAutocomplete__BusinessSuggestion__Details">
        <div className="BusinessAutocomplete__BusinessSuggestion__Details__Name">
          {business.BusinessName}
        </div>
        <div className="BusinessAutocomplete__BusinessSuggestion__Details__Address">
          {business.FormattedAddress}
        </div>
      </div>
    </>
  );
}

function renderSuggestions(_activeSuggestion, suggestions, onSelectSuggestion) {
  return (
    <div className="BusinessAutocomplete__BusinessSuggestionList">
      {suggestions.map(suggestion => (
        <button
          className="BusinessAutocomplete__BusinessSuggestion"
          key={suggestion.place_id || uuidv1()}
          onClick={() => onSelectSuggestion(suggestion)}
          type="button"
        >
          <div className="BusinessAutocomplete__BusinessSuggestion__Icon">
            <img src={googleBusiness} alt="Business Icon" />
          </div>

          <div className="BusinessAutocomplete__BusinessSuggestion__Details">
            <div className="BusinessAutocomplete__BusinessSuggestion__Details__Name">
              {suggestion.structured_formatting.main_text}
            </div>
            <div className="BusinessAutocomplete__BusinessSuggestion__Details__Address">
              {suggestion.structured_formatting.secondary_text}
            </div>
          </div>
        </button>
      ))}
    </div>
  );
}

export default function BusinessAutocomplete(props) {
  const {
    className,
    placeholder,
    required,
    setBusinessLocation,
    setBusinessLocationFourSquare,
    icon,
    disabled,
    clearValue,
    resultsFooter,
    notFoundContent,
    allBusinesses,
  } = props;

  const [suggestions, setSuggestions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [address, setAddress] = useState('');
  const showFoursquareData = isFoursquareData();

  useEffect(() => {
    setAddress('');
  }, [clearValue]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        localStorage.setItem('latitude', latitude);
        localStorage.setItem('longitude', longitude);
      },
      (err) => {
        localStorage.removeItem('latitude');
        localStorage.removeItem('longitude');
      },
    );
  }, []);

  const onFourSquareSearch = (searchTerm) => {
    const cityName = localStorage.getItem('cityName');
    if (searchTerm.length > 2) {
      setFetching(true);
      businessSearch(searchTerm, cityName, allBusinesses).subscribe((businesses) => {
        setSuggestions(
          businesses.map(business => ({
            label: renderSearchedItem(business),
            value: business,
          })),
        );
        setFetching(false);
      });
    } else {
      setSuggestions([]);
      setFetching(false);
    }
  };

  return (
    <div className={`BusinessAutocomplete ${className}`}>
      {showFoursquareData ? (
        <>
          {icon && <img alt="search" className="search-icon" src={icon} />}

          <AutoComplete
            disabled={disabled}
            key={clearValue}
            className={icon ? 'with-icon' : null}
            displayingProp="value.FormattedAddress"
            itemListAsync
            fetching={fetching}
            listOfItems={suggestions}
            onChange={onFourSquareSearch}
            onHandleItemSelect={(location) => {
              setBusinessLocationFourSquare(location);
              setAddress(location.value.BusinessName);
            }}
            placeholder={placeholder}
            value={address}
            resultsFooter={resultsFooter}
            notFoundContent={notFoundContent}
          />
        </>
      ) : (
        <GoogleLocationAutoComplete
          className="full"
          icon={icon}
          id="txtAutoCompleteAddress"
          onSelect={setBusinessLocation}
          placeholder={placeholder}
          renderSuggestions={renderSuggestions}
          required={required}
        />
      )}
    </div>
  );
}

BusinessAutocomplete.propTypes = {
  className: string,
  placeholder: string.isRequired,
  required: bool,
  setBusinessLocation: func.isRequired,
  setBusinessLocationFourSquare: func.isRequired,
  icon: string,
  disabled: bool,
  clearValue: bool,
  resultsFooter: elementType,
  notFoundContent: elementType,
  allBusinesses: bool,
};

BusinessAutocomplete.defaultProps = {
  className: null,
  required: false,
  icon: null,
  disabled: false,
  clearValue: false,
  resultsFooter: null,
  notFoundContent: null,
  allBusinesses: false,
};
