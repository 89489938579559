import { bool } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import MasterPage from '../../../components/MasterPage';
import NotFound from '../../../components/NotFound';

/* eslint-disable no-undef */
const PrivatePage404 = (props) => {
  const { auth } = props;

  return (
    <>
      <Helmet title="Franki Admin: 404" />

      <MasterPage auth={auth}>
        <NotFound
          title="page not found"
          description="we know where the party's at but we don't know where you are! sorry!"
          links={[
            <Link
              to={{ pathname: '/', state: { from: window.location.pathname } }}
              className="button purple-theme"
            >
              log in
            </Link>,
            <a href="mailto:customersupport@befranki.com" className="button purple-theme">
              contact support
            </a>,
          ]}
        />
      </MasterPage>
    </>
  );
};

PrivatePage404.propTypes = {
  auth: bool.isRequired,
};

export default PrivatePage404;
