import './index.sass';
import 'react-datepicker/dist/react-datepicker.css';

import { bool, func, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Prompt, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import {
  chargeGigPayment as chargeGigPaymentAction,
  createGig,
  createGigPrizesNew,
  createGigRules,
  updateGig as updateGigAction,
} from '../../../../actions/gigs';
import LightBox from '../../../../components/LightBox';
import MasterPage from '../../../../components/MasterPage';
import HISTORY_TYPE from '../../../../types/history';
import ROUTER_MATCH_TYPE from '../../../../types/routerMatch';
import { DataURIToBlob } from '../../../../utils/api';
import BusinessGigCreateFifthStep from './BusinessGigCreateFifthStep';
import BusinessGigCreateFirstStep from './BusinessGigCreateFirstStep';
import BusinessGigCreateFourthStep from './BusinessGigCreateFourthStep';
import BusinessGigCreateSecondStep from './BusinessGigCreateSecondStep';
import BusinessGigCreateStepZero from './BusinessGigCreateStepZero';
import BusinessGigCreateThirdStep from './BusinessGigCreateThirdStep';

const GIG_CREATE_STORAGE_KEY = 'gigCreateState';

/* global localStorage */
class BusinessGigCreate extends React.Component {
  state = {
    form: {
      caption: '',
      endDate: new Date(new Date().setDate(new Date().getDate() + 21)),
      gigId: null,
      gigType: null,
      headline: '',
      image: '',
      prizes: [],
      rules: [],
      startDate: new Date(new Date().setDate(new Date().getDate())),
    },
    loading: false,
    frankiFunded: false,
    isGigCostCovered: false,
    paymentProfileId: null,
    email: '',
    step: 0,
    total: 0,
    error: null,
  };

  componentDidMount() {
    const savedState = localStorage.getItem(GIG_CREATE_STORAGE_KEY);
    if (savedState) {
      const state = JSON.parse(savedState);
      this.cacheState({
        ...state,
        startDate: new Date(state.startDate),
        endDate: new Date(state.endDate),
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      chargeSuccess,
      history,
      isChargingGig,
      match: {
        params: { corporateGroupId },
      },
    } = this.props;
    if (chargeSuccess && !isChargingGig && prevProps.isChargingGig) {
      setTimeout(
        () => history.push(`/admin/business-gig-create-successfully/${corporateGroupId}`),
        1000,
      );
    }
  }

  componentWillUnmount() {
    localStorage.removeItem('gigCreateState');
  }

  onHandleChange(name, value) {
    this.cacheState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));

    if (name === 'gigType') {
      localStorage.setItem('gigType', value);
    }
  }

  onHandleBack(event) {
    const { step } = this.state;
    event.preventDefault();
    this.cacheState({ step: step - 1 });
  }

  onHandleNextStep() {
    const { step } = this.state;
    this.cacheState({ step: step + 1 });
  }

  async onHandleSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    const {
      updateGig,
      history,
      match: {
        params: { corporateGroupId },
      },
    } = this.props;

    const { form, frankiFunded, paymentProfileId, email, isGigCostCovered, total } = this.state;

    // show browser validation message, and stop process
    const txtPaymentEmail = document.getElementById('formPaymentEmail');
    if (txtPaymentEmail && !txtPaymentEmail.reportValidity()) return;
    const image_ = form.image ? DataURIToBlob(form.image) : form.image;

    const { chargeGigPayment } = this.props;
    this.setState({ loading: true }, () => {
      createGig({
        Name: form.headline,
        Description: form.caption,
        StartDate: new Date(form.startDate).toISOString(),
        EndDate: new Date(form.endDate).toISOString(),
        CorporateGroupId: corporateGroupId,
        Image: image_,
      }).subscribe(
        (gigId) => {
          const rulesDescription = form.rules
            .filter(rule => rule.Description)
            .map(rule => ({
              DisplayOrder: rule.GigRuleId,
              Description: rule.Description,
              GigId: gigId,
            }));

          createGigRules(rulesDescription).subscribe(
            () => {
              const prizesDescription = form.prizes.map(prize => ({
                DisplayOrder: prize.GigPrizeId,
                Description: prize.Description,
                PrizeAmount: prize.PrizeAmount,
                WinnerQty: prize.WinnerQty,
                CorporateGroupId: corporateGroupId,
                GigId: gigId,
              }));

              createGigPrizesNew(prizesDescription).subscribe(
                () => {
                  if (frankiFunded) {
                    updateGig({
                      Name: form.headline,
                      Description: form.caption,
                      StartDate: new Date(form.startDate).toISOString(),
                      EndDate: new Date(form.endDate).toISOString(),
                      CorporateGroupId: corporateGroupId,
                      Image: image_,
                      GigId: gigId,
                      PaymentStatusId: 3,
                    });
                    this.setState({ frankiFunded: true }, () => {
                      history.push(`/admin/business-gig-create-successfully/${corporateGroupId}`);
                    });

                    localStorage.removeItem(GIG_CREATE_STORAGE_KEY);
                  } else {
                    if (!isGigCostCovered) {
                      chargeGigPayment(gigId, paymentProfileId, total, email);
                    }
                    updateGig({
                      Name: form.headline,
                      Description: form.caption,
                      StartDate: new Date(form.startDate).toISOString(),
                      EndDate: new Date(form.endDate).toISOString(),
                      CorporateGroupId: corporateGroupId,
                      Image: image_,
                      GigId: gigId,
                      PaymentStatusId: 2,
                    });
                    if (isGigCostCovered) {
                      this.setState({ frankiFunded: true }, () => {
                        history.push(`/admin/business-gig-create-successfully/${corporateGroupId}`);
                      });
                    }
                  }
                },
                (err) => {
                  this.setState({
                    error: err,
                    loading: false,
                  });
                },
              );
            },
            (err) => {
              this.setState({
                error: err,
                loading: false,
              });
            },
          );
        },
        (err) => {
          this.setState({
            error: err,
            loading: false,
          });
        },
      );
    });
  }

  setTotal(total) {
    this.cacheState({ total });
  }

  setIsGigCostCoveredState(isGigCostCovered) {
    this.cacheState({ isGigCostCovered });
  }

  cacheState(state) {
    this.setState(state, () => {
      localStorage.setItem('gigCreateState', JSON.stringify(this.state));
    });
  }

  removeRule = (gigRuleId) => {
    const { form } = this.state;
    const filtered = form.rules.filter(f => f.GigRuleId != gigRuleId);
    this.setState({ form: { ...form, rules: filtered } });
  };

  render() {
    const {
      auth,
      chargeSuccess,
      chargeError,
      isChargingGig,
      match: {
        params: { corporateGroupId },
      },
    } = this.props;

    const {
      form: { gigId, gigType, headline, caption, endDate, startDate, image, prizes, rules },
      frankiFunded,
      isGigCostCovered,
      step,
      loading,
      error,
    } = this.state;
    return (
      <>
        <Helmet title="Franki Admin: create gig" />

        <MasterPage auth={auth} className="BusinessGigCreate" noWrapper>
          <div className="gigsForm">
            <Prompt
              message="By leaving this page, your gig will be saved in drafts,
              and you’ll be redirected to the first step of creating a gig."
              when={!isGigCostCovered && !frankiFunded && step > 5}
            />
            {step === 0 && (
              <BusinessGigCreateStepZero
                gigType={gigType}
                businessID={corporateGroupId}
                onHandleNextStep={() => this.onHandleNextStep()}
              />
            )}
            {step === 1 && (
              <BusinessGigCreateFirstStep
                businessID={corporateGroupId}
                formState={{ gigType }}
                onHandleNextStep={() => this.onHandleNextStep()}
                onHandleFormChange={(...params) => this.onHandleChange(...params)}
                onHandleBack={e => this.onHandleBack(e)}
              />
            )}

            {step === 2 && (
              <BusinessGigCreateSecondStep
                formState={{
                  caption,
                  endDate: new Date(endDate),
                  headline,
                  image,
                  startDate: new Date(startDate),
                }}
                gigType={gigType}
                onHandleBack={e => this.onHandleBack(e)}
                onHandleNextStep={() => this.onHandleNextStep()}
                onHandleFormChange={(...params) => this.onHandleChange(...params)}
              />
            )}

            {step === 3 && gigType && (
              <BusinessGigCreateThirdStep
                gigId={gigId}
                gigType={gigType}
                onHandleBack={e => this.onHandleBack(e)}
                onHandleChange={(...params) => this.onHandleChange(...params)}
                onHandleNextStep={() => this.onHandleNextStep()}
                removeRule={this.removeRule}
                rules={rules}
              />
            )}

            {step === 4 && gigType && (
              <BusinessGigCreateFourthStep
                gigId={gigId}
                gigType={gigType}
                onHandleBack={e => this.onHandleBack(e)}
                onHandleChange={(...params) => this.onHandleChange(...params)}
                onHandleNextStep={() => this.onHandleNextStep()}
                prizes={prizes}
              />
            )}

            {step === 5 && gigType && (
              <BusinessGigCreateFifthStep
                chargeError={chargeError}
                chargeSuccess={chargeSuccess}
                corporateGroupId={corporateGroupId}
                gigType={gigType}
                isChargingGig={isChargingGig}
                isGigCostCovered={isGigCostCovered}
                onHandleChange={(...params) => this.onHandleChange(...params)}
                onHandlePaymentSelect={paymentProfileId => this.cacheState({ paymentProfileId })}
                onHandleSubmit={e => this.onHandleSubmit(e)}
                prizes={prizes}
                setIsGigCostCoveredState={isCovered => this.setIsGigCostCoveredState(isCovered)}
                setTotal={total => this.setTotal(total)}
                setEmail={email => this.setState({ email })}
                updateFrankiFunded={funded => this.cacheState({ frankiFunded: funded })}
                loading={loading}
                onHandleBack={e => this.onHandleBack(e)}
              />
            )}
          </div>
        </MasterPage>
        <LightBox
          style={{
            width: 500,
            height: null,
            marginLeft: -250,
            marginTop: -200,
          }}
          onClose={() => this.setState({ error: '' })}
          show={error}
          title="Error"
        >
          <>
            <div className="GigSubmissions__AwardConfirm">
              <div className="AsyncContent__ErrorMessage">{error}</div>
            </div>
          </>
        </LightBox>
      </>
    );
  }
}

BusinessGigCreate.propTypes = {
  auth: bool.isRequired,
  chargeError: string.isRequired,
  chargeGigPayment: func.isRequired,
  chargeSuccess: string.isRequired,
  history: HISTORY_TYPE.isRequired,
  isChargingGig: bool.isRequired,
  match: ROUTER_MATCH_TYPE({ corporateGroupId: string.isRequired, gigId: string }).isRequired,
  updateGig: func.isRequired,
};

const mapStateToProps = (state) => {
  const { chargeError, chargeSuccess, isChargingGig } = state.gigs;

  return {
    chargeError,
    chargeSuccess,
    isChargingGig,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    chargeGigPayment: chargeGigPaymentAction,
    updateGig: updateGigAction,
  }),
)(BusinessGigCreate);
