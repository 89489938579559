export const DEFAULT_PAGE_SIZE = 8;
export const DEFAULT_PAGE_INDEX = 1;

export const ITEM_COUNT_LIST = [
  // { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
  // { label: 250, value: 250 },
  // { label: 500, value: 500 },
];

export const ITEM_COUNT_LIST_BASE = [
  { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];
