import './index.sass';

import { func, instanceOf, string } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { withRouter } from 'react-router-dom';

import gigPreviewImg from '../../../../../assets/images/gig_preview.png';
import AsyncContent from '../../../../../components/AsyncContent';
import FileUpload from '../../../../../components/FileUpload';
import RemainCharacters from '../../../../../components/RemainCharacters';
import StepsHeader from '../../../../../components/StepsHeader';
import HISTORY_TYPE from '../../../../../types/history';
import GigPackageCard from '../GigPackageCard';
import GIG_PACKAGES from '../GigPackages';

const NAME_MAX_LENGTH = 50;
const NAME_MIN_LENGTH = 3;
const DESCRIPTION_MAX_LENGTH = 200;
const DESCRIPTION_MIN_LENGTH = 3;

function BusinessGigCreateSecondStep(props) {
  const {
    formState: { headline, caption, startDate, endDate, image },
    gigType,
    onHandleBack,
    onHandleNextStep,
    onHandleFormChange,
  } = props;

  const onHandleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (headline.length > NAME_MAX_LENGTH || caption.length > DESCRIPTION_MAX_LENGTH) {
        return;
      }

      onHandleNextStep();
    },
    [headline, caption],
  );

  const activeGig = useMemo(() => GIG_PACKAGES.find(item => item.type === gigType), [gigType]);

  return (
    <AsyncContent
      content={() => (
        <form method="POST" onSubmit={onHandleSubmit}>
          <StepsHeader
            step={2}
            maxSteps={5}
            title={(
              <div>
                $
                {activeGig.value}
                {' '}
                <strong>{activeGig.type}</strong>
              </div>
            )}
            subtitle="Want to promote a special/offer you’re running? Do it here."
            tooltipTitle="gig details"
            tooltip={(
              <>
                <p>this is what your gig will look like in franki, be sure its enticing!</p>
                <figure>
                  <img src={gigPreviewImg} alt="" />
                </figure>
              </>
            )}
          />

          <div className="BusinessGigCreateSecondStep__Content">
            <GigPackageCard {...activeGig} active />

            <div className="BusinessGigCreateSecondStep__Content__Main">
              <fieldset>
                <legend>
                  Time to name your GiG
                  <span className="required-asterisk">*</span>
                </legend>

                <span className="label">
                  This is the first thing users will see of your GiG. Make sure it’s catchy to grab
                  their attention.
                </span>

                <input
                  id="name"
                  type="text"
                  name="headline"
                  className="full"
                  value={headline}
                  placeholder="Show off the ambience"
                  onChange={({ target }) => onHandleFormChange(target.name, target.value)}
                  minLength={NAME_MIN_LENGTH}
                  required
                />

                <RemainCharacters
                  label="Gig's name"
                  length={headline.length}
                  maxLength={NAME_MAX_LENGTH}
                />
              </fieldset>

              <fieldset>
                <legend>
                  What’s this GiG about?
                  <span className="required-asterisk">*</span>
                </legend>

                <span className="label">
                  This is the description of your GiG. Let users know what you’re looking for
                </span>

                <textarea
                  className="full"
                  id="txtCaption"
                  minLength={DESCRIPTION_MIN_LENGTH}
                  name="caption"
                  onChange={({ target }) => onHandleFormChange(target.name, target.value)}
                  placeholder="Come in, capture the atmosphere, show the Franki community our venue. And if you're looking to win the jackpot
                  - get creative!"
                  required
                  rows="3"
                  value={caption}
                />

                <RemainCharacters
                  label="Gig's description"
                  length={caption.length}
                  maxLength={DESCRIPTION_MAX_LENGTH}
                />
              </fieldset>

              <fieldset className="BusinessGigCreateSecondStep__Row">
                <div>
                  <legend>
                    When would you like this gig to drop?
                    <span className="required-asterisk">*</span>
                  </legend>

                  <span className="label">Select how long you want your GiG to be live.</span>

                  <div className="DatePickerContainer">
                    <label htmlFor="startDate">
                      From
                      <span className="required-asterisk">*</span>
                    </label>
                    <label>
                      <DatePicker
                        calendarClassName="Franki-DatePicker"
                        className="datepicker-dateTime"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        disabledKeyboardNavigation
                        endDate={endDate}
                        id="startDate"
                        minDate={new Date()}
                        onChange={_value => onHandleFormChange('startDate', _value)}
                        onChangeRaw={({ currentTarget }) => onHandleFormChange('startDate', new Date(currentTarget.value))
                        }
                        required
                        selected={startDate}
                        selectsStart
                        showTimeSelect
                        timeCaption="time"
                        timeFormat="HH:mm"
                        popperPlacement="top-start"
                        timeIntervals={60}
                      />
                    </label>
                  </div>

                  <div className="DatePickerContainer ml-12">
                    <label htmlFor="endDate">
                      To
                      <span className="required-asterisk">*</span>
                    </label>
                    <label>
                      <DatePicker
                        calendarClassName="Franki-DatePicker"
                        className="datepicker-dateTime"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        disabledKeyboardNavigation
                        id="endDate"
                        minDate={new Date()}
                        onChange={_value => onHandleFormChange('endDate', _value)}
                        onChangeRaw={({ currentTarget }) => onHandleFormChange('endDate', new Date(currentTarget.value))
                        }
                        required
                        selected={endDate}
                        selectsEnd
                        showTimeSelect
                        startDate={startDate}
                        popperPlacement="top-start"
                        timeCaption="time"
                        timeFormat="HH:mm"
                        timeIntervals={60}
                      />
                    </label>
                  </div>

                  {startDate > endDate && (
                    <div className="error">Start date cannot be after end date</div>
                  )}
                </div>

                <FileUpload
                  accept=".png, .PNG, .jpg, .JPG, .jpeg, .JPEG"
                  fileTypeMessage=""
                  description={(
                    <div className="FileUpload__Content">
                      <small>Drag &amp; drop your picture here.</small>
                      <small>
                        .jpeg or .png recommended dimensions 1200x1200px maximum file size 4mb
                      </small>
                    </div>
                  )}
                  height={102}
                  id="upload"
                  maxFileSize={4}
                  name="image"
                  onChange={onHandleFormChange}
                  placeholder="UPLOAD IMAGE"
                  type="image"
                  value={image}
                />
              </fieldset>
            </div>
          </div>

          <div className="buttons-actions">
            <button
              type="button"
              className="button small button--outline"
              onClick={e => onHandleBack(e)}
            >
              BACK
            </button>

            <button
              type="submit"
              className="button small button--primary"
              disabled={startDate > endDate}
            >
              NEXT
            </button>
          </div>
        </form>
      )}
      expectNoData
    />
  );
}

BusinessGigCreateSecondStep.propTypes = {
  formState: instanceOf(Object).isRequired,
  gigType: string.isRequired,
  match: instanceOf(Object).isRequired,
  onHandleBack: func.isRequired,
  onHandleNextStep: func.isRequired,
  onHandleFormChange: func.isRequired,
  history: HISTORY_TYPE.isRequired,
};

export default withRouter(BusinessGigCreateSecondStep);
