import 'react-phone-input-2/lib/style.css';

import { bool, func } from 'prop-types';
import React, { Component } from 'react';
import ReactInputMask from 'react-input-mask';

import AsyncContent from '../../../../components/AsyncContent';
import InputWithIcon from '../../../../components/InputWithIcon';
import { validWebsite } from '../../../../helpers';
import { BUSINESS_DETAILS_TYPE } from '../../../../types/business';
import { DEFAULT_COUNTRY_CODE, getCountryCode } from '../../../../utils/getCountryCode';

/* IMAGES */
class BusinessContactInformationForm extends Component {
  state = {
    dialCode: DEFAULT_COUNTRY_CODE,
  };

  componentDidMount() {
    getCountryCode().then((dialCode) => {
      this.setState({ dialCode });
    });
  }

  render() {
    const {
      formState: { PhoneNumber, Website },
      isPhoneNumberInvalid,
      onHandleCloseDialog,
      onHandleChange,
    } = this.props;

    const { dialCode } = this.state;
    return (
      <AsyncContent
        content={() => (
          <div className="BusinessBasicForm__Content">
            <div className="BusinessEdit__BlockGroup">
              <div className="mb-0 BusinessEdit__Blocks">
                <label htmlFor="txtBusinessWebsite">
                  Website
                  <span className="required-asterisk">*</span>
                </label>

                <InputWithIcon className="full" iconClassName="icon-website">
                  <input
                    autoComplete="none"
                    className="full"
                    id="txtBusinessWebsite"
                    name="Website"
                    onChange={({ target }) => onHandleChange(target.name, target.value)}
                    pattern={validWebsite(Website).regexAsString}
                    placeholder="https:/www."
                    required
                    title="Your website URL is invalid"
                    type="text"
                    value={Website || ''}
                  />
                </InputWithIcon>
              </div>

              <div className="mb-0 BusinessEdit__Blocks">
                <label htmlFor="txtBusinessPhoneNumber">
                  Business phone number
                  <span className="required-asterisk">*</span>
                </label>

                <div className="InputWithIcon">
                  <ReactInputMask
                    className="full ReactInputMask"
                    autoComplete="none"
                    id="txtBusinessPhoneNumber"
                    mask="+9999 9999 9999 9999"
                    maskChar=""
                    onChange={e => onHandleChange('PhoneNumber', e.target.value)}
                    placeholder="+ 1"
                    required
                    value={PhoneNumber || dialCode}
                  />

                  <i className="icon-call" />
                </div>
              </div>

            </div>
          </div>
        )}
        errorMessage={isPhoneNumberInvalid ? 'Phone number is invalid' : ''}
        onHandleCloseDialog={onHandleCloseDialog}
        expectNoData
        type="error"
      />
    );
  }
}

BusinessContactInformationForm.propTypes = {
  formState: BUSINESS_DETAILS_TYPE.isRequired,
  isPhoneNumberInvalid: bool.isRequired,
  onHandleChange: func.isRequired,
};

export default BusinessContactInformationForm;
