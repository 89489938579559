import './index.sass';

// import { HubConnectionBuilder } from '@microsoft/signalr';
import { isEmpty } from 'lodash';
import { arrayOf, bool, func, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import uuidv1 from 'uuid/v1';

import {
  getBusinessDetails as getBusinessDetailsAction,
  getVideoDetails as getVideoDetailsAction,
  verifyPermission,
} from '../../../../actions/businesses';
import { getPromotions as getPromotionsAction } from '../../../../actions/promotions';
import {
  cleanDeleteVideo as cleanDeleteVideoAction,
  deleteVideo as deleteVideoAction,
} from '../../../../actions/videos';
import AsyncContent from '../../../../components/AsyncContent';
import BusinessVideoDetailsComponent from '../../../../components/BusinessVideoDetails';
import { Col, Row } from '../../../../components/Grid';
import LightBox from '../../../../components/LightBox';
import LikesCommentsSavings from '../../../../components/LikesCommentsSavings';
import MasterPage from '../../../../components/MasterPage';
import NotFound from '../../../../components/NotFound';
import VideoDetailsHeader from '../../../../components/VideoDetailsHeader';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../../config/pagination';
import USER_ROLES from '../../../../config/user-roles';
// import { REACT_APP_SOCKET_URL } from '../../../../config/variables';
import str from '../../../../helpers/string';
import { BUSINESS_DETAILS_TYPE } from '../../../../types/business';
import HISTORY_TYPE from '../../../../types/history';
import PROMOTION_TYPE from '../../../../types/promotion';
import ROUTER_MATCH_TYPE from '../../../../types/routerMatch';
import VIDEO_TYPE from '../../../../types/video';
import asyncLocalStorage from '../../../../utils/asyncLocalStorage';
import { isAdminOrSaleAdmin } from '../../../../utils/isAdmin';
import PromotionCard from '../../../PromotedContent/Promotions/PromotionCard';

class BusinessVideoDetails extends React.Component {
  state = {
    deleteVideoModalVisible: false,
    userRole: null,
    connection: null,
    frankiUserId: localStorage.getItem('frankiUserId'),
    contentKey: '',
  };

  componentDidMount() {
    const {
      getBusinessDetails,
      getPromotions,
      getVideoDetails,
      history,
      match: {
        params: { contentKey },
      },
    } = this.props;

    this.setState({ contentKey });
    // const newConnection = new HubConnectionBuilder()
    //   .withUrl(`${REACT_APP_SOCKET_URL}?user=${this.state.frankiUserId}`)
    //   .withAutomaticReconnect()
    //   .build();
    // this.setState({ connection: newConnection });

    const businessId = this.getBusinessID();
    verifyPermission(businessId, history, true).subscribe((verified) => {
      if (verified) {
        getBusinessDetails(businessId);
        getVideoDetails(contentKey);
        getPromotions();
        this.getUserRole();
      }
    });
  }

  // componentDidUpdate(prevProps, prevState) {
  //   const { connection, contentKey } = this.state;
  //   const { history } = this.props;

  //   if (prevState.connection !== connection) {
  //     if (connection) {
  //       connection
  //         .start()
  //         .then(() => {
  //           connection.on('ContentDeleted', (response) => {
  //             const resp = JSON.parse(response);
  //             if (contentKey == resp.ContentKey) { history.push(`/admin/flagged-contents/${DEFAULT_PAGE_INDEX}/${DEFAULT_PAGE_SIZE}`); }
  //           });
  //         })
  //         .catch(e => console.error('Connection failed: ', e));
  //     }
  //   }
  // }

  componentWillUpdate(nextProps) {
    const { isDeletingVideoSuccess } = this.props;

    if (!isDeletingVideoSuccess && nextProps.isDeletingVideoSuccess) {
      this.onDeleteVideoSuccess();
    }
  }

  onDeleteVideoSuccess() {
    this.setState({ deleteVideoModalVisible: false });
  }

  async getUserRole() {
    const userRole = await asyncLocalStorage.getItem(str.localStorage.role);
    this.setState({ userRole });
  }

  getBusinessID() {
    const {
      match: {
        params: { corporateGroupId },
      },
    } = this.props;
    return Number(corporateGroupId);
  }

  gotoPromotionDetails(promotionId) {
    const { history } = this.props;
    history.push({
      pathname: `/admin/promotion-details/${promotionId}`,
    });
  }

  hasDownloadButton() {
    const { userRole } = this.state;
    return [USER_ROLES.admin, USER_ROLES.saleAdmin].includes(userRole);
  }

  canSeePromoteButton() {
    const { userRole } = this.state;
    return [USER_ROLES.admin, USER_ROLES.saleAdmin].includes(userRole);
  }

  hasDeleteButton() {
    const { userRole } = this.state;
    const { videoDetails, businessDetails } = this.props;
    const isBusinessContent = videoDetails.IsBusinessContent;
    const isBusinessManager = userRole === USER_ROLES.businessManager;
    const isOwnedByBusiness = businessDetails.CorporateGroupId === videoDetails.CorporateGroup.CorporateGroupId;

    const isBusinessPost = isBusinessContent && isBusinessManager && isOwnedByBusiness;

    return isAdminOrSaleAdmin() || isBusinessPost;
  }

  render() {
    const {
      auth,
      businessDetails,
      cleanDeleteVideo,
      deleteVideo,
      deletingErrorMessage,
      isDeletingError,
      isDeletingVideoSuccess,
      isFetchingBusinessDetails,
      isFetchingPromotions,
      isFetchingVideoDetails,
      match: {
        params: { contentKey, corporateGroupId },
      },
      promotions,
      promotionsError,
      videoDetails,
      videoDetailsError,
    } = this.props;

    const { deleteVideoModalVisible } = this.state;

    return (
      <>
        <Helmet title="Franki Admin: video details" />

        <MasterPage
          auth={auth}
          businessSidebarOn
          businessSidebarData={businessDetails}
        // headerBreadcrumb={[
        //   { name: 'all videos', url: `/admin/business-videos/${() => this.getBusinessID()}/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}` },
        //   { name: 'details' },
        // ]}
        >
          <AsyncContent
            content={details => (
              <>
                <VideoDetailsHeader onHandleSetFeaturedVideo={() => { }} videoDetails={details} />

                <Row rowSpacing={20} cellSpacing={15}>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <BusinessVideoDetailsComponent videoDetails={details} />
                  </Col>

                  <Col lg={6} md={6} sm={12} xs={12}>
                    <LikesCommentsSavings
                      comments={details.Comments}
                      likes={details.Likes}
                      savingsCount={details.FavoriteCount}
                      height={300}
                    />
                  </Col>
                </Row>

                <ul className="BusinessVideoDetails__Actions">
                  {!isDeletingVideoSuccess && !details.IsDeleted && this.hasDeleteButton() && (
                    <li>
                      <button
                        className="button small red-theme"
                        onClick={() => this.setState({ deleteVideoModalVisible: true })}
                        type="button"
                      >
                        delete
                      </button>
                    </li>
                  )}

                  {this.hasDownloadButton() && (
                    <li>
                      <a
                        className="button small black-theme"
                        download={details.VideoUrl.split('/').slice(-1)}
                        href={details.VideoUrl}
                      >
                        download
                      </a>
                    </li>
                  )}

                  {!details.IsDeleted && (
                    <>
                      {this.canSeePromoteButton() && (
                        <li>
                          <Link
                            className="button small orange-theme"
                            to={`/admin/business-video-promote/${corporateGroupId}/${contentKey}/${details.ContentId}/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`}
                          >
                            promote
                          </Link>
                        </li>
                      )}
                    </>
                  )}
                </ul>

                {
                  // TODO: Remove false && and add videoId to getPromotion function
                  false && !isEmpty(promotions) && (
                    <>
                      <div className="BusinessVideoDetails__Promote">
                        <span className="BusinessVideoDetails__Promote__Title">Promotions</span>

                        <div className="BusinessVideoDetails__Promote__Content">
                          <AsyncContent
                            content={list => (
                              <Row
                                cellSpacing={7}
                                className="PromotedContent__Container"
                                rowSpacing={7}
                              >
                                {list.map(item => (
                                  <Col lg={3} md={4} sm={6} xs={12} key={uuidv1()}>
                                    <button
                                      onClick={() => this.gotoPromotionDetails(item.PromotionId)}
                                      className="PromotedContent__Card"
                                      type="button"
                                    >
                                      <PromotionCard item={item} />
                                    </button>
                                  </Col>
                                ))}
                              </Row>
                            )}
                            emptyPageContent={(
                              <NotFound
                                title="No results"
                                description="There're no existing promotions"
                              />
                            )}
                            errorMessage={promotionsError}
                            expectedData={promotions}
                            loading={isFetchingPromotions}
                          />
                        </div>
                      </div>
                    </>
                  )
                }
              </>
            )}
            errorMessage={videoDetailsError}
            expectedData={videoDetails}
            loading={isFetchingBusinessDetails || isFetchingVideoDetails}
          />

          <LightBox
            className="BusinessVideoDetails__Modal"
            onClose={() => this.setState({ deleteVideoModalVisible: false })}
            show={deleteVideoModalVisible}
            title="Delete video"
            style={{
              width: 400,
              height: null,
              marginLeft: -250,
              marginTop: -200,
            }}
          >
            <p className="BusinessVideoDetails__Modal__Description">
              Are you sure you want to delete this video?
            </p>

            <p className="BusinessVideoDetails__Modal__SmallerDescription">
              All the video information will be lost forever.
            </p>

            <ul className="BusinessVideoDetails__Modal__Actions">
              <li>
                <button
                  type="button"
                  className="button small purple-theme"
                  onClick={() => this.setState({ deleteVideoModalVisible: false })}
                >
                  No, Cancel
                </button>
              </li>

              <li>
                <button
                  type="button"
                  className="button small red-theme"
                  onClick={() => {
                    deleteVideo(videoDetails.Key);
                    this.setState({ deleteVideoModalVisible: false });
                  }}
                >
                  Yes, Delete!
                </button>
              </li>
            </ul>
          </LightBox>
          <LightBox
            style={{
              width: 400,
              height: null,
              marginLeft: -250,
              marginTop: -200,
            }}
            show={isDeletingVideoSuccess}
            title="Delete Video"
            onClose={() => {
              cleanDeleteVideo();
            }}
          >
            <p className="BusinessVideoDetails__Modal__Description">This video has been deleted!</p>
          </LightBox>
          <LightBox
            style={{
              width: 400,
              height: null,
              marginLeft: -250,
              marginTop: -200,
            }}
            show={isDeletingError}
            title="Delete Video Failed"
            onClose={() => { }}
          >
            <p className="AsyncContent__ErrorMessage">{deletingErrorMessage}</p>
          </LightBox>
        </MasterPage>
      </>
    );
  }
}

BusinessVideoDetails.propTypes = {
  auth: bool.isRequired,
  businessDetails: BUSINESS_DETAILS_TYPE.isRequired,
  cleanDeleteVideo: func.isRequired,
  getBusinessDetails: func.isRequired,
  getPromotions: func.isRequired,
  getVideoDetails: func.isRequired,
  history: HISTORY_TYPE.isRequired,
  isFetchingBusinessDetails: bool.isRequired,
  isFetchingPromotions: bool.isRequired,
  isFetchingVideoDetails: bool.isRequired,
  match: ROUTER_MATCH_TYPE({ corporateGroupId: string, contentKey: string }).isRequired,
  promotions: arrayOf(PROMOTION_TYPE).isRequired,
  promotionsError: string.isRequired,
  videoDetails: VIDEO_TYPE.isRequired,
  videoDetailsError: string.isRequired,
  deleteVideo: func.isRequired,
  deletingErrorMessage: string.isRequired,
  isDeletingError: bool.isRequired,
  isDeletingVideoSuccess: bool.isRequired,
};

const mapStateToProps = state => ({
  businessDetails: state.businesses.businessDetails,
  isFetchingBusinessDetails: state.businesses.isFetchingBusinessDetails,
  isFetchingPromotions: state.promotions.isFetchingPromotions,
  isFetchingVideoDetails: state.businesses.isFetchingVideoDetails,
  promotions: state.promotions.promotions,
  promotionsError: state.promotions.promotionsError,
  videoDetails: state.businesses.videoDetails,
  videoDetailsError: state.businesses.videoDetailsError,
  isDeletingError: state.videos.isDeletingError,
  isDeletingVideoSuccess: state.videos.isDeletingVideoSuccess,
  deletingErrorMessage: state.videos.deletingErrorMessage,
});

export default connect(mapStateToProps, {
  getBusinessDetails: getBusinessDetailsAction,
  getVideoDetails: getVideoDetailsAction,
  getPromotions: getPromotionsAction,
  deleteVideo: deleteVideoAction,
  cleanDeleteVideo: cleanDeleteVideoAction,
})(BusinessVideoDetails);
