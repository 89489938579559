import './index.sass';

import { bool, oneOf } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import MasterPage from '../../../components/MasterPage';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../config/pagination';
import HISTORY_TYPE from '../../../types/history';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';
import DiscoverPromotion from './DiscoverPromotion';
import LOCATION_TYPE from '../../../types/location';

class CreatePromotion extends React.Component {
  componentDidMount() {
    this.onHandleSelect('discover-promotion');
  }

  onHandleSelect(type) {
    const {
      history,
      location: { pathname },
    } = this.props;

    if (!pathname.endsWith(type)) {
      history.push({
        pathname: `/admin/create-promotion/${type}`,
      });
    }
  }

  render() {
    const {
      auth,
      history,
      match: {
        params: { type },
      },
    } = this.props;

    const masterPageConfig = {
      auth,
      headerBreadcrumb: [
        {
          name: 'promoted content',
          url: `/admin/promoted-content/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`,
        },
        { name: 'create promotion' },
      ],
    };

    return (
      <>
        <Helmet title="Franki Admin: Create promotion" />

        <MasterPage {...masterPageConfig}>
          <div className="CreatePromotion__Section">
            <h3>Step 1: Select Promotion Type</h3>
            <button
              className={`button small ${
                type === 'discover-promotion' ? 'orange-theme' : 'purple-theme'
              }`}
              onClick={() => this.onHandleSelect('discover-promotion')}
              type="button"
            >
              Discover Promotion
            </button>

            {/* <button
              className={`button small ${type === 'discover-carousel' ? 'orange-theme' : 'purple-theme'}`}
              onClick={() => this.onHandleSelect('discover-carousel')}
              type="submit"
            >
              Discover Carousel
            </button> */}
          </div>

          {type === 'discover-promotion' && (
            <DiscoverPromotion
              onSubmitSuccess={() =>
                history.push(`/admin/promoted-content/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`)
              }
              onCancel={() =>
                history.push(`/admin/promoted-content/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`)
              }
            />
          )}
        </MasterPage>
      </>
    );
  }
}

CreatePromotion.propTypes = {
  auth: bool.isRequired,
  history: HISTORY_TYPE.isRequired,
  location: LOCATION_TYPE.isRequired,
  match: ROUTER_MATCH_TYPE({
    type: oneOf(['discover-promotion', 'discover-carousel']),
  }).isRequired,
};

export default CreatePromotion;
