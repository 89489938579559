import './index.sass';

import { func, bool } from 'prop-types';
import React from 'react';

import { BUSINESS_DETAILS_TYPE } from '../../../../types/business';
import Address from './Address';
import USER_ROLES from '../../../../config/user-roles';

const BusinessAddressForm = (props) => {
  const { onHandleFormChange, formState, addressDisabled } = props;
  const userRole = localStorage.getItem('frankiRole');

  const clearBusinessLocation = () => {
    onHandleFormChange('Name', '');
    onHandleFormChange('Address', '');
    onHandleFormChange('Country', '');
    onHandleFormChange('City', '');
    onHandleFormChange('State', '');
    onHandleFormChange('Zipcode', '');
    onHandleFormChange('Neighborhood', '');

    if (![USER_ROLES.businessManager].includes(userRole)) {
      onHandleFormChange('Latitude', '');
      onHandleFormChange('Longitude', '');
    }
  };

  return (
    <div className="BusinessAddressForm">
      <Address
        clearBusinessLocation={() => clearBusinessLocation()}
        formState={formState}
        onHandleFormChange={onHandleFormChange}
        addressDisabled={addressDisabled}
      />
    </div>
  );
};

BusinessAddressForm.propTypes = {
  onHandleFormChange: func.isRequired,
  formState: BUSINESS_DETAILS_TYPE.isRequired,
  addressDisabled: bool,
};

BusinessAddressForm.defaultProps = {
  addressDisabled: false,
};

export default BusinessAddressForm;
