/* eslint-disable react/destructuring-assignment */
import './index.sass';
import 'react-custom-flag-select/lib/react-custom-flag-select.min.css';

import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import { arrayOf, bool, func, object, string } from 'prop-types';
import React from 'react';
import ReactCustomFlagSelect from 'react-custom-flag-select';

import dropdownIndicator from '../../assets/images/dropdown-indicator.svg';
import FLAG_SELECTOR_OPTION_LIST from './OptionList';

class PhoneWithFlagInput extends React.Component {
  state = {
    locale: 'us',
    nationalNumber: '',
    countryCallingCode: '1',
    status: 'empty',
    optionList: this.props.countryList || FLAG_SELECTOR_OPTION_LIST,
  };

  onChange() {
    const { onChange } = this.props;
    const { countryCallingCode, nationalNumber } = this.state;
    const phoneNumberStr = `+${countryCallingCode}${nationalNumber}`;
    let status = 'empty';
    if (nationalNumber) {
      const phoneNumber = parsePhoneNumberFromString(phoneNumberStr);
      status = phoneNumber && phoneNumber.isValid() ? 'valid' : 'invalid';
    }

    this.setState({ status });
    onChange({
      number: phoneNumberStr,
      status,
    });
  }

  handleCountryChange(id) {
    const country = FLAG_SELECTOR_OPTION_LIST.find((item) => item.id === id);
    if (country) {
      this.setState({ countryCallingCode: country.areaCode }, () => this.onChange());
    }
    this.setState({ locale: id });
  }

  handlePhoneNumberChange(nationalNumber) {
    this.setState({ nationalNumber }, () => this.onChange());
  }

  render() {
    const { locale, status } = this.state;
    const { title, displayError } = this.props;
    const { displayText } = FLAG_SELECTOR_OPTION_LIST.find((item) => item.id === locale);

    return (
      <div className="PhoneWithFlagInput">
        <div
          className={`PhoneWithFlagInput__Title ${
            displayError && status === 'invalid' ? 'error' : ''
          }`}
        >
          {title}
        </div>

        <div className="PhoneWithFlagInput__Inputs">
          <div className="PhoneWithFlagInput__DisplayText">
            {displayText}

            <img
              src={dropdownIndicator}
              alt="dropdown indicator"
              width="10"
              className="PhoneWithFlagInput__DropDownIndicator"
            />
          </div>

          <ReactCustomFlagSelect
            animate
            classNameButton="PhoneWithFlagInput__Button"
            classNameWrapper="PhoneWithFlagInput__Wrapper"
            onChange={(id) => this.handleCountryChange(id)}
            // eslint-disable-next-line react/destructuring-assignment
            optionList={this.state.optionList}
            value={locale}
          />

          <input
            className={`PhoneWithFlagInput__PhoneNumber ${
              displayError && status !== 'valid' ? 'PhoneWithFlagInput__PhoneNumber--Error' : null
            }`}
            type="text"
            onChange={(evt) => this.handlePhoneNumberChange(evt.target.value)}
          />
        </div>
      </div>
    );
  }
}

PhoneWithFlagInput.propTypes = {
  displayError: bool,
  onChange: func.isRequired,
  title: string,
  countryList: arrayOf(object),
};

PhoneWithFlagInput.defaultProps = {
  displayError: null,
  title: null,
  countryList: null,
};

export default PhoneWithFlagInput;
