import './index.sass';

import { bool, func, instanceOf, string } from 'prop-types';
import React from 'react';

const NomineesCard = (props) => {
  const { item, onHandleAward, className, onClick, ...rest } = props;

  return (
    <div className={`nomineesCard ${item.IsDeleted && 'DeletedItem'} ${className}`} {...rest}>
      <figure>
        {item.VideoInfo.FullResolutionUrl && (
          <button onClick={onClick} onKeyPress={onClick} type="button">
            <img src={item.VideoInfo.FullResolutionUrl} alt={item.UserDisplayName} />
          </button>
        )}
      </figure>

      <div className="nomineesCard-content">
        <div className="nomineesCard-content-user">
          <figure className="nomineesCard-content-user-avatar">
            {item.UserProfilePhotoInfo && (
              <img src={item.UserProfilePhotoInfo.FullResolutionUrl} alt="" />
            )}
          </figure>

          <span className="nomineesCard-content-user-name">
            <span>{item.UserDisplayName}</span>
          </span>
        </div>

        {onHandleAward && (
          <div className="nomineesCard-content-award">
            <button type="button" onClick={onHandleAward}>
              award
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

NomineesCard.propTypes = {
  className: string,
  grabbing: bool,
  item: instanceOf(Object),
  onClick: func,
  onHandleAward: func,
};

NomineesCard.defaultProps = {
  className: '',
  grabbing: null,
  item: {},
  onClick: () => {},
  onHandleAward: null,
};

export default NomineesCard;
