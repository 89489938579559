import { arrayOf, number, oneOfType, shape, string } from 'prop-types';

export const SELECT_OPTION_TYPE = shape({
  label: string,
  value: oneOfType([string, number]),
});

const SELECT_OPTIONS_TYPE = arrayOf(SELECT_OPTION_TYPE);

export default SELECT_OPTIONS_TYPE;
