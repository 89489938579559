import { req } from '../utils/api';

export function addPaymentProfile(
  businessId,
  cardNumber,
  expirationMonth,
  expirationYear,
  cvv,
  isDefaultCard,
) {
  return req({
    uri: 'BusinessPayment/Card',
    method: 'POST',
    data: {
      BusinessId: businessId,
      CardNumber: cardNumber,
      ExpirationMonth: expirationMonth,
      ExpirationYear: expirationYear,
      Cvc: cvv,
      IsDefaultCard: isDefaultCard,
    },
  });
}

export function getPaymentProfile(businessId) {
  return req({
    uri: `BusinessPayment/Business/${businessId}/Cards`,
    method: 'GET',
  });
}

export function removePaymentProfile(businessId, cardId) {
  return req({
    uri: `BusinessPayment/BusinessId/${businessId}/Card/${cardId}`,
    method: 'DELETE',
  });
}

export function setDefaultPaymentProfile(paymentProfileId, businessId) {
  return req({
    uri: `BusinessPayment/BusinessId/${businessId}/DefaultCard/${paymentProfileId}`,
    method: 'POST',
    data: {},
  });
}

export function getServiceFee() {
  return req({
    uri: 'BusinessPayment/ServiceFee',
    method: 'GET',
  });
}
