import '../index.sass';

import { isEmpty } from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { authenticate } from '../../../actions/auth';
import {
  assignUserAsBusinessManager,
  getBusinessesForBusinessManager,
} from '../../../actions/businesses';
import AsyncContent from '../../../components/AsyncContent';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../config/pagination';
import USER_ROLES from '../../../config/user-roles';
import HISTORY_TYPE from '../../../types/history';
import { getPlaceId, getPlaceIdProp } from '../../../utils/placeId';

/* global localStorage */
function Login(props) {
  const { history } = props;
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [businessesForBusinessManager, setBusinessesForBusinessManager] = useState(null);
  const [role, setRole] = useState(null);
  const [token, setToken] = useState(null);
  const placeIdProp = getPlaceIdProp();
  const placeId = getPlaceId(props);
  useEffect(() => {
    if (!isEmpty(businessesForBusinessManager)) {
      history.push(`/admin/business-profile/${businessesForBusinessManager[0]}`);
    } else if (businessesForBusinessManager && businessesForBusinessManager.length == 0) {
      localStorage.setItem('errorMessage', 'Account has no business to manage');
      history.push('/claim-my-business/');
    }
  }, [businessesForBusinessManager]);

  function navigateAfterLogin() {
    if (placeId) {
      setLoading(true);
      const query = new URLSearchParams(history.location.search);
      const businessIdParam = query.get('businessId');

      assignUserAsBusinessManager(
        placeId === 'null' ? { BusinessId: businessIdParam } : { [placeIdProp.api]: placeId },
        placeId === 'null',
      ).subscribe(
        ({ BusinessId }) => {
          const businessId =
            BusinessId ||
            businessIdParam ||
            (history.location.state && history.location.state.businessId);
          history.push({
            pathname: `/business-phone-verification/${businessId}?${placeIdProp.query}=${placeId}`,
            state: {
              businessId,
              hideStep: true,
              nonFourSquare: placeId === 'null',
            },
          });
        },
        (err) => {
          setErrorMessage(err);
          setLoading(false);
        },
        setLoading(false),
      );
      return;
    }
    if (history.location.state && history.location.state.from) {
      history.push(history.location.state.from);
      return;
    }

    const queryGoBack = history.location.search ? queryString.parse(history.location.search) : null;
    if (history.location.search && queryGoBack) {
      history.push(queryGoBack.from);
      return;
    }

    if (role === USER_ROLES.businessManager) {
      setLoading(true);
      getBusinessesForBusinessManager().subscribe(
        setBusinessesForBusinessManager,
        (err) => {
          setErrorMessage(err);
          setLoading(false);
        },
        () => setLoading(false),
      );
      return;
    }

    if ([USER_ROLES.admin, USER_ROLES.saleAdmin].includes(role)) {
      history.push(`/admin/businesses/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`);
      return;
    }

    if (role == USER_ROLES.contentAdmin) {
      history.push('/admin/videos/8/1?sortingType=1&businessSource=FrankiLocalBusiness');
      return;
    }
    history.push('/admin');
  }

  useEffect(() => {
    if (token) {
      navigateAfterLogin();
    }
  }, [token, role]);

  useEffect(() => {
    if (!errorMessage) {
      setLoading(false);
    }
  }, [errorMessage]);

  function onHandleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    authenticate({ Identifier: identifier, Password: password }).subscribe(
      (data) => {
        const frankiToken = data.Token.Value;
        const frankiRole = data.SecurityRole;
        const frankiUser = data.Profile.UserName;
        const frankiName = data.Profile.Fullname;
        const frankiUserId = data.Profile.UserId;

        localStorage.setItem('frankiToken', frankiToken);
        localStorage.setItem('frankiRole', frankiRole);
        localStorage.setItem('frankiUser', frankiUser);
        localStorage.setItem('frankiName', frankiName);
        localStorage.setItem('frankiUserId', frankiUserId);

        setRole(frankiRole);
        setToken(frankiToken);
      },
      (err) => {
        setErrorMessage(err);
        setLoading(false);
      },
      () => setLoading(false),
    );
  }

  const renderBackButton = () => {
    if (history.location.search.includes('businessId')) {
      return (
        <button
          type="button"
          className="button EmailCodeVerification__Back"
          onClick={() => window.history.back()}
        >
          BACK
        </button>
      );
    }
  };

  return (
    <div className="AuthWrapper">
      <Header auth={false} />

      <AsyncContent
        content={() => (
          <main className="AuthMain">
            <div className="Auth">
              <form onSubmit={onHandleSubmit}>
                <div className="Auth__Header">
                  <h1 className="Auth__Title">Login</h1>
                  <div className="Auth__Description">
                    Rock & Roll lets start making some noise...
                  </div>
                </div>

                {renderBackButton()}
                <fieldset>
                  <label className="Auth__Label" htmlFor="txtUsername">
                    Username
                    <em>*</em>
                  </label>

                  <input
                    id="txtUsername"
                    value={identifier}
                    type="text"
                    className="full"
                    required
                    onChange={({ target: { value } }) => setIdentifier(value)}
                    disabled={loading}
                  />
                </fieldset>

                <fieldset>
                  <label className="Auth__Label" htmlFor="txtPassword">
                    Password
                    <em>*</em>
                  </label>

                  <input
                    id="txtPassword"
                    value={password}
                    type="password"
                    required
                    className="full"
                    disabled={loading}
                    onChange={({ target: { value } }) => setPassword(value)}
                  />
                </fieldset>

                <div className="Auth__Forgot">
                  Forgot your <Link to="/forgot-password">Password?</Link>
                </div>

                <div className="Auth__Action">
                  <button type="submit" className="button orange-theme" disabled={loading}>
                    {loading ? 'loading...' : 'login'}
                  </button>
                </div>
              </form>
            </div>
          </main>
        )}
        errorMessage={errorMessage}
        onHandleCloseDialog={() => setErrorMessage('')}
        expectNoData
      />

      <Footer />
    </div>
  );
}

Login.propTypes = {
  history: HISTORY_TYPE.isRequired,
};

export default Login;
