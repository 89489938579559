import React from 'react';
import LoaderIMG from '../../assets/images/loading.gif';

const Loader = (props) => (
  <div {...props}>
    <img src={LoaderIMG} alt="loading..." />
  </div>
);

export default Loader;
