import './index.sass';

import { isEmpty } from 'lodash';
import {
  arrayOf,
  bool,
  elementType,
  func,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import uuidv1 from 'uuid/v1';
import { useDebounce } from 'use-debounce';

const ENTER_KEY_CODE = 13;

export default function AutoComplete(props) {
  const {
    className,
    disabled,
    fetching,
    itemListAsync,
    listOfItems,
    onChange,
    onHandleItemSelect,
    placeholder,
    value,
    resultsFooter: ResultsFooter,
    notFoundContent: NotFoundContent,
  } = props;

  const [autoCompleteInput, setAutoCompleteInput] = useState(null);
  const [pause, setPause] = useState(false);
  const AutoCompleteInput = useRef(null);
  const [debouncedValue] = useDebounce(autoCompleteInput, 600);


  useEffect(() => {
    if (debouncedValue !== null) {
      onChange(debouncedValue);
    }
  }, [debouncedValue]);


  function onHandleItemClick(item) {
    setAutoCompleteInput(null);
    AutoCompleteInput.current.focus();
    onHandleItemSelect(item);
  }

  function handleEnterKeyPressIfNecessary(e) {
    setPause(false);
    if (e.keyCode === ENTER_KEY_CODE || e.charCode === ENTER_KEY_CODE) {
      e.preventDefault();
      const isThereInTheList = autoCompleteInput
        && listOfItems.filter(item => item.value.toLowerCase() === autoCompleteInput.toLowerCase());

      if (isThereInTheList.length) {
        onHandleItemSelect(isThereInTheList[0]);
        setAutoCompleteInput(null);
      }
    }
  }

  const autoCompleteItems = itemListAsync
    ? listOfItems
    : listOfItems.filter(
      ({ label }) => label
          && label.toLowerCase().match(autoCompleteInput ? autoCompleteInput.toLowerCase() : ''),
    );

  return (
    <div className="Autocomplete">
      <input
        autoComplete="none"
        className={`Autocomplete__Input ${className}`}
        disabled={disabled}
        onChange={({ target }) => setAutoCompleteInput(target.value)}
        onKeyPress={e => handleEnterKeyPressIfNecessary(e)}
        placeholder={placeholder}
        ref={AutoCompleteInput}
        type="text"
        value={autoCompleteInput === null ? value : autoCompleteInput}
      />

      {autoCompleteInput && !pause && (!itemListAsync || autoCompleteInput.length >= 2) && (
        <>
          {fetching && (
            <div className="Autocomplete__ModalList display-flex">
              <span className="Autocomplete__Item text-center my-3">loading...</span>
            </div>
          )}

          {!fetching
            && (!isEmpty(autoCompleteItems) ? (
              <div className="Autocomplete__ModalList">
                <ul className="Autocomplete__ModalList__Results">
                  {autoCompleteItems.map(item => (
                    <li className="Autocomplete__Item" key={uuidv1()}>
                      <button
                        onClick={async () => onHandleItemClick(item)}
                        onKeyPress={async () => onHandleItemClick(item)}
                        tabIndex="0"
                        type="button"
                      >
                        {item.label}
                      </button>
                    </li>
                  ))}
                </ul>
                {ResultsFooter && <ResultsFooter />}
              </div>
            ) : (
              <div className="Autocomplete__ModalList display-flex">
                {NotFoundContent ? (
                  <NotFoundContent />
                ) : (
                  <span className="Autocomplete__Item text-center my-3">no results found</span>
                )}
              </div>
            ))}
        </>
      )}
    </div>
  );
}

AutoComplete.propTypes = {
  className: string,
  disabled: bool,
  fetching: bool,
  itemListAsync: bool,
  listOfItems: arrayOf(
    shape({
      label: oneOfType([object, string, number]),
      value: oneOfType([object, string, number]),
    }),
  ).isRequired,
  onChange: func.isRequired,
  onHandleItemSelect: func.isRequired,
  placeholder: string,
  value: string,
  resultsFooter: elementType,
  notFoundContent: elementType,
};

AutoComplete.defaultProps = {
  className: '',
  disabled: false,
  fetching: false,
  itemListAsync: false,
  placeholder: '',
  value: '',
  resultsFooter: null,
  notFoundContent: null,
};
