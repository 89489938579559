import './index.sass';

// import { HubConnectionBuilder } from '@microsoft/signalr';
import { isEmpty } from 'lodash';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getVideoDetails as getVideoDetailsAction } from '../../../actions/businesses';
import {
  removeLink as removeLinkAction,
  shareLink as shareLinkAction,
} from '../../../actions/videos';
import copyIcon from '../../../assets/images/files-empty.svg';
import flagImg from '../../../assets/images/flag-32.png';
import CustomSelect from '../../../components/CustomSelect';
import { Col, Row } from '../../../components/Grid';
import LightBox from '../../../components/LightBox';
import LikesCommentsSavings from '../../../components/LikesCommentsSavings';
import Loader from '../../../components/Loader';
import TagsList from '../../../components/TagsList';
import VideoDetailsHeader from '../../../components/VideoDetailsHeader';
import { POST_REVIEW_COMMENTS, POST_REVIEW_STATUSES } from '../../../config/gig-status';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../config/pagination';
import USER_ROLES from '../../../config/user-roles';
// import { REACT_APP_SOCKET_URL } from '../../../config/variables';
import { copyText } from '../../../helpers';
import str from '../../../helpers/string';
import VIDEO_TYPE from '../../../types/video';
import { APIRequest } from '../../../utils/api';
import asyncLocalStorage from '../../../utils/asyncLocalStorage';
import { isAdminOrSaleAdmin } from '../../../utils/isAdmin';
import RenderVideo from './renderVideo';
import HamburgerMenu from '../../../components/HamburgerMenu';

class VideoDetails extends React.Component {
  state = {
    isUpdatingFlag: false,
    linkIsCopied: false,
    userRole: null,
    contentKey: '',
    connection: null,
    flagOptions: [],
    commentOptions: [],
    statusHistory: [],
    currentFlagStatus: null,
    currentCommentStatus: null,
    selectedFlagStatus: null,
    selectedComment: null,
    errorMessage: '',
    successMessage: '',
    frankiUserId: localStorage.getItem('frankiUserId'),
  };

  async componentDidMount() {
    const {
      contentKey,
      item: {
        Status: { StatusId },
      },
      getVideoDetails,
    } = this.props;
    const optionsComment = POST_REVIEW_COMMENTS; // not filtered yet?
    await this.getUserRole();
    this.setState({
      contentKey,
      flagOptions: this.getFlagStatusBasedOnRoles(StatusId),
      commentOptions: optionsComment,
    });
    this.getStatusHistory(contentKey);
    await getVideoDetails(contentKey);

    const {
      videoDetails: { Status },
    } = this.props;
    if (Status) {
      const { CommentId } = Status;
      this.setState({
        currentFlagStatus: POST_REVIEW_STATUSES.filter((f) => f.value == StatusId)[0],
        currentCommentStatus: POST_REVIEW_COMMENTS.filter((f) => f.value == CommentId)[0],
        selectedFlagStatus: POST_REVIEW_STATUSES.filter((f) => f.value == StatusId)[0],
        selectedComment: POST_REVIEW_COMMENTS.filter((f) => f.value == CommentId)[0],
      });
    }
    // const newConnection = new HubConnectionBuilder()
    //   .withUrl(`${REACT_APP_SOCKET_URL}?user=${this.state.frankiUserId}`)
    //   .withAutomaticReconnect()
    //   .build();
    // this.setState({ connection: newConnection });

    setTimeout(() => {
      const x = document.getElementsByClassName('vjs-big-play-button');
      if (x.length > 0) x[0].click();
    }, 1000);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   const { connection, contentKey } = this.state;

  //   if (prevState.connection !== connection) {
  //     if (connection) {
  //       connection
  //         .start()
  //         .then(() => {
  //           connection.on('ContentDeleted', (response) => {
  //             const resp = JSON.parse(response);
  //             if (contentKey == resp.ContentKey) {
  //               window.location.reload();
  //             }
  //           });
  //         })
  //         .catch((e) => console.error('Connection failed: ', e));
  //     }
  //   }
  // }

  componentWillUnmount() {
    const { removeLink } = this.props;
    removeLink();
  }

  getStatusHistory = async (contentKey) => {
    await APIRequest({
      uri: `Content/${contentKey}/StatusHistory`,
      method: 'GET',
    })
      .then((res) => {
        const { Success, Data } = res;
        if (Success) this.setState({ statusHistory: Data });
      })
      .catch((error) => {
        this.setState({
          errorMessage: error,
        });
      })
      .finally(() => {
        this.setState({ isUpdatingFlag: false });
      });
  };

  getPublicLink() {
    const { shareLink, videoDetails } = this.props;
    shareLink(videoDetails.Key);
  }

  async getUserRole() {
    const userRole = await asyncLocalStorage.getItem(str.localStorage.role);
    this.setState({ userRole });
  }

  updateFlagStatus = async () => {
    const { contentKey } = this.props;
    const { selectedFlagStatus, selectedComment, currentFlagStatus, currentCommentStatus } =
      this.state;
    if ([3, 5, 8].includes(selectedFlagStatus.value) && !selectedComment) {
      this.setState({ errorMessage: 'Please select comment' });
      return;
    }
    this.setState({ isUpdatingFlag: true });
    let commentId_ = null;
    if (selectedComment && selectedFlagStatus.value != 7) commentId_ = selectedComment.value;
    await APIRequest({
      uri: `Content/${contentKey}/AdjustStatus`,
      method: 'POST',
      data: {
        NewStatusId: selectedFlagStatus.value,
        CommentId: commentId_,
      },
    })
      .then((res) => {
        const { Success, Message } = res;
        if (Success) {
          this.setState({
            currentFlagStatus: selectedFlagStatus,
            currentCommentStatus: selectedComment,
            successMessage: Message,
            errorMessage: '',
            flagOptions: this.getFlagStatusBasedOnRoles(selectedFlagStatus.value),
          });
          this.getStatusHistory(contentKey);
        }
        // history.push('/admin/flagged-contents');
      })
      .catch((error) => {
        this.setState({
          successMessage: '',
          errorMessage: error,
          selectedFlagStatus: currentFlagStatus,
          selectedComment: currentCommentStatus,
        });
      })
      .finally(() => {
        this.setState({ isUpdatingFlag: false });
      });
  };

  renderPostBody = () => {
    const {
      videoDetails: { PostBody, TaggedUsers },
    } = this.props;
    let result = PostBody;
    const userIds = [...PostBody.matchAll(/\[UserId:(\d+)\]/g)].map((m) => +m[1]);
    userIds.forEach((userid) => {
      const user = TaggedUsers.filter((f) => f.UserId == userid);
      if (user.length > 0) result = result.replace(`[UserId:${userid}]`, user[0].DisplayName);
    });
    return result;
  };

  getFlagStatusBasedOnRoles = (CurrentStatusId) => {
    let options = POST_REVIEW_STATUSES;
    const { userRole } = this.state;
    let targets = [];
    if (userRole === USER_ROLES.contentAdmin) {
      if (CurrentStatusId === 1) {
        targets = [2];
      } else if (CurrentStatusId == 2) {
        targets = [3, 7];
      }
    } else if (userRole === USER_ROLES.saleAdmin) {
      if ([1, 2, 3, 7].includes(CurrentStatusId)) {
        targets = [4];
      } else if (CurrentStatusId === 4) {
        targets = [5, 7];
      }
    } else if (userRole == USER_ROLES.admin) {
      if ([1, 2, 3, 4, 5, 7].includes(CurrentStatusId)) {
        targets = [6];
      } else if (CurrentStatusId == 6) {
        targets = [7, 8];
      }
    }
    options = POST_REVIEW_STATUSES.filter((f) => targets.includes(f.value));
    return options;
  };

  onChangeFlagStatus = (val) => {
    this.setState({
      selectedFlagStatus: POST_REVIEW_STATUSES.filter((f) => f.value == val)[0],
    });
  };

  onChangeComment = (val) => {
    this.setState({
      selectedComment: POST_REVIEW_COMMENTS.filter((f) => f.value == val)[0],
    });
  };

  hasDownloadButton() {
    const { userRole } = this.state;
    const { videoDetails } = this.props;
    return videoDetails.VideoUrl && [USER_ROLES.admin, USER_ROLES.saleAdmin].includes(userRole);
  }

  hasDeleteButton() {
    const { videoDetails } = this.props;
    const isBusinessContent = videoDetails.IsBusinessContent;
    return isBusinessContent && isAdminOrSaleAdmin();
  }

  copyText(link) {
    copyText(link);
    this.setState({ linkIsCopied: true });
    setTimeout(() => this.setState({ linkIsCopied: false }), 1000);
  }

  renderFlagContent = () => {
    const {
      item: { IsUserAllowedToEdit },
    } = this.props;
    const { flagOptions, selectedFlagStatus, isUpdatingFlag } = this.state;

    if (isUpdatingFlag) return <Loader />;

    if (IsUserAllowedToEdit) {
      return (
        <CustomSelect
          id="contentStatusIds"
          name="contentStatusIds"
          label="Flag Status"
          onChange={({ value }) => this.onChangeFlagStatus(value)}
          options={flagOptions}
          placeholder={selectedFlagStatus != null ? selectedFlagStatus.label : 'Review Status'}
          value={selectedFlagStatus}
          isSearchable={false}
        />
      );
    }
    return (
      <span className="GigSubmissions__Prizes__TotalPrize">
        {selectedFlagStatus != null ? selectedFlagStatus.label : 'Unknown'}
      </span>
    );
  };

  renderFlagBar = () => {
    const { currentFlagStatus } = this.state;
    if (currentFlagStatus) {
      return (
        <div className="flagStatusDiv">
          <img
            alt="flag status"
            color="white"
            style={{
              backgroundColor: `${currentFlagStatus.color || ''}`,
              padding: 5,
              border: `solid 2px ${currentFlagStatus.color}`,
              borderRadius: '10px',
            }}
            width={24}
            height={24}
            src={flagImg}
          />
          <span> {currentFlagStatus.label}</span>
        </div>
      );
    }
  };

  renderStatusCommentContent = () => {
    const {
      item: { IsUserAllowedToEdit, Status },
    } = this.props;
    if (Status) {
      const { commentOptions, selectedComment, selectedFlagStatus, isUpdatingFlag } = this.state;
      const isDisabled = selectedFlagStatus && selectedFlagStatus.value == 7;
      if (isUpdatingFlag) return <Loader />;

      if (IsUserAllowedToEdit) {
        return (
          <CustomSelect
            disabled={isDisabled}
            id="commentStatus"
            name="comment"
            label="Defination"
            onChange={({ value }) => this.onChangeComment(value)}
            options={commentOptions}
            placeholder={selectedComment != null ? selectedComment.label : 'Select Comment'}
            value={selectedComment}
            isSearchable={false}
          />
        );
      }
      return (
        <span className="GigSubmissions__Prizes__TotalPrize">
          {selectedComment != null ? selectedComment.label : 'No Comment'}
        </span>
      );
    }
  };

  renderUpdateStatusButton = () => {
    const { selectedFlagStatus, currentFlagStatus, isUpdatingFlag } = this.state;

    if (
      [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.contentAdmin].includes(
        this.state.userRole,
      ) &&
      selectedFlagStatus
    ) {
      const isdisabled = currentFlagStatus.value == selectedFlagStatus.value;

      return (
        <li>
          <button
            type="button"
            disabled={isUpdatingFlag || isdisabled}
            className="button small orange-theme"
            onClick={() => this.updateFlagStatus()}
          >
            Update Status
          </button>
        </li>
      );
    }
  };

  renderPromoteButton = () => {
    const { videoDetails } = this.props;
    const { userRole } = this.state;
    if (
      [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager].includes(userRole) &&
      videoDetails.CorporateGroup
    ) {
      return (
        <li>
          <Link
            className="button small orange-theme"
            to={`/admin/business-video-promote/${videoDetails.CorporateGroup.CorporateGroupId}/${videoDetails.Key}/${videoDetails.ContentId}/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`}
          >
            promote
          </Link>
        </li>
      );
    }
  };

  render() {
    const { isFetchingLink, link, linkError, videoDetails, onDeleted } = this.props;

    const { linkIsCopied, statusHistory, successMessage, errorMessage } = this.state;

    const videoContentId = videoDetails.ContentId || 'not found';

    return (
      <>
        <LightBox
          style={{
            width: 600,
            height: null,
            marginLeft: -250,
            marginTop: -200,
          }}
          onClose={() => this.setState({ successMessage: '', errorMessage: '' })}
          show={successMessage || errorMessage}
          title={(successMessage && 'Success') || (errorMessage && 'Error')}
        >
          <p className={`popupMessage ${errorMessage ? 'error' : ''}`}>
            {successMessage || errorMessage}
          </p>
        </LightBox>
        <Row rowSpacing={20} cellSpacing={15}>
          <Col lg={6} md={6} sm={12} xs={12} className="headerWrapper">
            <Col lg={8} md={8} sm={12} xs={12}>
              <VideoDetailsHeader
                additionalText={
                  videoDetails.CorporateGroup && (
                    <strong className="VideoDetails__CorporateGroupName">
                      {videoDetails.CorporateGroup.DisplayName}
                    </strong>
                  )
                }
                onHandleSetFeaturedVideo={() => {}}
                videoDetails={videoDetails}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="flagStatusDivWrapper">
              {this.renderFlagBar()}
            </Col>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12} className="hamburgerWrapper">
            <HamburgerMenu
              className="Hamburger_Popup_Page"
              itemClassName="black-text fw400"
              menuItems={[
                {
                  innerContent: `Video ID: ${videoContentId}`,
                },
              ]}
            />
          </Col>
        </Row>

        <Row rowSpacing={20} cellSpacing={15}>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="VideoDetails__Container">
              <RenderVideo videoDetails={videoDetails} />
              <div className="VideoDetails__Social">
                <div className="icon-button VideoDetails__PublicLink">
                  {link && (
                    <>
                      <button
                        className="icon-button"
                        onClick={() => this.copyText(link)}
                        type="button"
                      >
                        <img src={copyIcon} alt="copy" />
                      </button>

                      {linkIsCopied ? (
                        <div className="BusinessVideoDetails__PublicLink__Copied">Link copied!</div>
                      ) : (
                        <a href={link} target="blank">
                          {link}
                        </a>
                      )}
                    </>
                  )}

                  {!link && (
                    <button
                      className="button small BusinessVideoDetails__PublicLinkButton"
                      onClick={() => this.getPublicLink()}
                      type="button"
                    >
                      <span>
                        {isFetchingLink ? 'getting link...' : 'get public link to this video'}
                      </span>
                    </button>
                  )}
                </div>

                {!linkError && <div className="error">{linkError}</div>}
              </div>

              {videoDetails.PostBody && (
                <p className="VideoDetails__Description">{this.renderPostBody()}</p>
              )}

              {videoDetails.Products && (
                <TagsList className="VideoDetails__Tags" tags={videoDetails.Products} />
              )}

              {!isEmpty(videoDetails.HashTags) && (
                <div className="VideoDetails__Tags">
                  {videoDetails.HashTags.map((tag) => (
                    <span>{tag}</span>
                  ))}
                </div>
              )}
            </div>
          </Col>

          <Col lg={6} md={6} sm={12} xs={12}>
            <LikesCommentsSavings
              comments={videoDetails.Comments}
              likes={videoDetails.Likes}
              savingsCount={videoDetails.FavoriteCount}
              statusHistory={statusHistory}
            />
            <Row rowSpacing={25} cellSpacing={15}>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div className="form-group has-tooltip" style={{ width: 'inherit' }}>
                  {this.renderFlagContent()}
                </div>
              </Col>

              <Col lg={6} md={6} sm={12} xs={12}>
                <div className="form-group has-tooltip" style={{ width: 'inherit' }}>
                  {this.renderStatusCommentContent()}
                </div>
              </Col>
            </Row>

            {!isEmpty(videoDetails.AwardedPrizes) && (
              <div className="VideoDetails__Awarded">
                <div className="VideoDetails__Awarded__Title">PRIZES ARWARDED</div>
                {videoDetails.AwardedPrizes.map((prize) => (
                  <span>{prize}</span>
                ))}
              </div>
            )}
          </Col>
        </Row>

        <ul className="VideoDetails__Actions">
          {this.hasDeleteButton() ? (
            <li>
              <button
                className="button button-outline small"
                onClick={() => {
                  onDeleted();
                }}
                type="button"
              >
                Delete
              </button>
            </li>
          ) : null}
          {/* {
            !videoDetails.IsDeleted && (
              <li>
                <button
                  className="button small red-theme"
                  onClick={() => { }}
                  type="button"
                >
                  flag
                </button>
              </li>
            )
          } */}

          {this.hasDownloadButton() && (
            <li>
              <a
                className="button small black-theme"
                download={videoDetails.VideoUrl.split('/').slice(-1)}
                href={videoDetails.VideoUrl}
              >
                download
              </a>
            </li>
          )}

          {this.renderPromoteButton()}
          {this.renderUpdateStatusButton()}
        </ul>
      </>
    );
  }
}

VideoDetails.propTypes = {
  contentKey: string.isRequired,
  getVideoDetails: func.isRequired,
  isFetchingLink: bool.isRequired,
  link: string.isRequired,
  linkError: string.isRequired,
  removeLink: func.isRequired,
  shareLink: func.isRequired,
  videoDetails: VIDEO_TYPE.isRequired,
  onDeleted: func.isRequired,
};

const mapStateToProps = (state) => ({
  isFetchingLink: state.videos.isFetchingLink,
  link: state.videos.link,
  linkError: state.videos.linkError,
  videoDetails: state.businesses.videoDetails,
  videoDetailsError: state.businesses.videoDetailsError,
});

export default connect(mapStateToProps, {
  getVideoDetails: getVideoDetailsAction,
  removeLink: removeLinkAction,
  shareLink: shareLinkAction,
})(VideoDetails);
