import { APIRequest, req } from '../utils/api';

/* eslint-disable import/prefer-default-export */
export async function getPlaceDetails(placeID) {
  const result = await APIRequest({
    uri: `GooglePlace/GetDetails?placeId=${placeID}`,
    method: 'GET',
  })
    .then((res) => {
      if (res.Data) {
        const fullLocation = {
          placeID,
          latitude: res.Data.Latitude || null,
          longitude: res.Data.Longitude || null,
          address: res.Data.Vicinity || '',
        };
        for (let i = 0; i < res.Data.AddressComponents.length; i += 1) {
          if (res.Data.AddressComponents[i].types.includes('postal_code')) {
            fullLocation.zipCode = res.Data.AddressComponents[i].short_name;
          }

          if (res.Data.AddressComponents[i].types.includes('locality')) {
            fullLocation.city = res.Data.AddressComponents[i].long_name;
          }

          if (
            !fullLocation.city
            && res.Data.AddressComponents[i].types.includes('administrative_area_level_2')
          ) {
            fullLocation.city = res.Data.AddressComponents[i].short_name;
          }

          if (res.Data.AddressComponents[i].types.includes('administrative_area_level_1')) {
            fullLocation.state = res.Data.AddressComponents[i].short_name;
          }
        }

        return fullLocation;
      }

      return null;
    })
    .catch(err => err);
  return result;
}

export function geocode(address) {
  return req({
    uri: `Geocode/Forward?address=${address}`,
    method: 'GET',
  });
}
