/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './index.sass';

import { bool, func, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import AsyncContent from '../../../../../components/AsyncContent';
import Loader from '../../../../../components/Loader';
import infoIcon from '../../../../../assets/images/info.png';
import LightBox from '../../../../../components/LightBox';

const ACCEPTED_BANNER_FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
const BANNER_MINIMUM_WIDTH_IN_PX = 250;
const BANNER_MINIMUM_HEIGHT_IN_PX = 250;
const MAXIMUM_FILE_SIZE_IN_MB = 5;
const MAXIMUM_FILE_SIZE = MAXIMUM_FILE_SIZE_IN_MB * 1024 * 1024;

/* global FileReader */
const BusinessBanner = (props) => {
  const {
    bannerUrl,
    onHandleChange,
    onBannerUrlChange,
    nonFourSquare,
  } = props;

  const [businessBannerLoading, setBusinessBannerLoading] = useState(false);
  const [isDialog, setIsDialog] = useState(false);
  const [bannerError, setBannerError] = useState(null);
  const [banner, setBanner] = useState(bannerUrl);
  const [defaultBanner, setDefaultBanner] = useState(bannerUrl);
  const bannerImg = useRef(null);
  const inputFileBanner = useRef(null);

  const markBannerInputErrorAndReset = (errorMessage) => {
    setBanner(defaultBanner || banner);
    setBannerError(errorMessage);
    onHandleChange('businessBanner', '');
    if (inputFileBanner) {
      inputFileBanner.current.value = '';
    }
  };

  const bannerFileTypeAndFileSizeValid = (file) => {
    if (ACCEPTED_BANNER_FILE_TYPES.indexOf(file.type) === -1) {
      markBannerInputErrorAndReset('Please select a .jpeg or .png file.');
      return false;
    }

    if (file.size > MAXIMUM_FILE_SIZE) {
      markBannerInputErrorAndReset(`Please select a file smaller than ${MAXIMUM_FILE_SIZE_IN_MB}MB.`);
      return false;
    }
    return true;
  };

  const onHandleBanner = async (name, file) => {
    if (!file) {
      return;
    }

    if (bannerFileTypeAndFileSizeValid(file)) {
      setBusinessBannerLoading(true);
      await onHandleChange(name, file);

      const reader = new FileReader();

      reader.onloadend = () => {
        setBanner(reader.result);
        setBannerError('');
        setBusinessBannerLoading(false);
      };
      reader.readAsDataURL(file);
    }
  };


  useEffect(() => {
    onBannerUrlChange(banner);
  }, [banner]);

  useEffect(() => {
    setTimeout(() => {
      if (banner && banner.indexOf('http') === -1) {
        setTimeout(() => {
          if (
            bannerImg
            && (bannerImg.offsetHeight < BANNER_MINIMUM_HEIGHT_IN_PX
              || bannerImg.offsetWidth < BANNER_MINIMUM_WIDTH_IN_PX)
          ) {
            markBannerInputErrorAndReset(
              `Please select a file larger than ${BANNER_MINIMUM_WIDTH_IN_PX}x${BANNER_MINIMUM_HEIGHT_IN_PX} px.`,
            );
          }
        }, 200);
      } else {
        setDefaultBanner(banner);
      }
    }, 10);
  }, [banner, bannerImg]);


  return (
    <AsyncContent
      content={() => (
        <>
          <div className="BusinessBanner">
            <img
              className="BusinessBanner__ImageSizeTest"
              ref={bannerImg}
              src={bannerUrl}
              alt="Business banner"
            />

            <figure
              className={`BusinessBanner__Figure ${
                businessBannerLoading ? 'BusinessBanner__Figure--Loading' : ''
              }`}
            >
              {businessBannerLoading ? (
                <Loader />
              ) : (
                bannerUrl && <img src={bannerUrl} alt="Business banner" />
              )}
            </figure>
            <div className="BusinessBanner__FileInputGroup">
              <div className="BusinessBanner__FileInput">
                <label htmlFor="Banner" className="upload-banner">

                  EDIT PROFILE BANNER
                </label>
                <input
                  accept=".png, .jpg, .jpeg"
                  id="Banner"
                  name="Banner"
                  onChange={({ target }) => onHandleBanner(target.name, target.files[0])}
                  ref={inputFileBanner}
                  type="file"
                />
              </div>
              {!nonFourSquare && (
              <img
                className="infoElement"
                src={infoIcon}
                alt=""
                height={30}
                width={30}
                onClick={() => {
                  setIsDialog(true);
                }}
              />
              )}
            </div>
            {/* <span className="BusinessBanner__Limit">
              .jpeg or .png,
              {' '}
              {`${BANNER_MINIMUM_WIDTH_IN_PX}x${BANNER_MINIMUM_HEIGHT_IN_PX}px minimum`}
              <br />
              maximum file size 4mb
            </span> */}
          </div>
          <LightBox
            style={
                {
                  width: 400,
                  height: 200,
                  marginLeft: -200,
                  marginTop: -100,
                }
              }
            onClose={() => setIsDialog(false)}
            show={isDialog}
          >
            <div className="DialogTitle">Edit Profile Banner</div>
            <div className="DialogContent">
              The picture currently on your profile has been
              <br />
              posted on third party review sites.
              <br />
              You can update this image by clicking
              <br />
              “Edit profile banner”

            </div>
          </LightBox>

          <LightBox
            style={
                {
                  width: 400,
                  height: 150,
                  marginLeft: -200,
                  marginTop: -75,
                }
              }
            onClose={() => setBannerError('')}
            show={bannerError}
          >
            <div className="DialogTitle">There is an error</div>
            <div className="DialogContent">
              {bannerError}
            </div>
          </LightBox>
        </>
      )}
      expectNoData
    />
  );
};

BusinessBanner.propTypes = {
  bannerUrl: string.isRequired,
  onHandleChange: func.isRequired,
  nonFourSquare: bool.isRequired,
  onBannerUrlChange: func.isRequired,
};

export default BusinessBanner;
