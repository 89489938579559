import './index.sass';

import { string } from 'prop-types';
import React from 'react';

import { validPasswordReset } from '../../helpers';

const PasswordRequirements = ({ password }) => {
  const passStraight = validPasswordReset(password);

  return (
    <div className="passwordRequirements">
      password must be +8 characters long and:
      <ul className="passwordRequirements-details">
        <li className={`${passStraight.uppercase ? 'password-item-correct' : ''}`}>
          {passStraight.uppercase ? (
            <i className="icon-success" />
          ) : (
            <i className="icon-htmlBullet" />
          )}
          1 uppercase
        </li>
        <li className={`${passStraight.lowercase ? 'password-item-correct' : ''}`}>
          {passStraight.lowercase ? (
            <i className="icon-success" />
          ) : (
            <i className="icon-htmlBullet" />
          )}
          1 lowercase
        </li>
        <li className={`${passStraight.number ? 'password-item-correct' : ''}`}>
          {passStraight.number ? <i className="icon-success" /> : <i className="icon-htmlBullet" />}
          1 number
        </li>
        <li className={`${passStraight.specialChar ? 'password-item-correct' : ''}`}>
          {passStraight.specialChar ? (
            <i className="icon-success" />
          ) : (
            <i className="icon-htmlBullet" />
          )}
          1 special character
        </li>
      </ul>
    </div>
  );
};

PasswordRequirements.propTypes = {
  password: string.isRequired,
};

export default PasswordRequirements;
