import './index.sass';

import { flatMap, orderBy, uniq } from 'lodash';
import { arrayOf, func } from 'prop-types';
import React from 'react';
import uuidv1 from 'uuid/v1';

import moment from 'moment';
import { BUSINESS_HOUR_TYPE } from '../../../../types/business';
import BusinessHoursGroup, {
  DEFAULT_CLOSING_TIME,
  DEFAULT_OPENING_TIME,
} from './BusinessHoursGroup';

const daysOfWeek = { Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 7 };

export const dbToHoursModal = data => Object.entries(data).map(([key, value]) => {
  const sortedValues = orderBy(value, 'OpeningTime', 'asc');
  let hourData = { DayOfTheWeek: Number(moment().day(key).format('d')) };
  if (sortedValues && sortedValues.length > 0 && sortedValues[0]) {
    hourData = { DayOfTheWeek: daysOfWeek[key], ...sortedValues[0] };
    if (sortedValues[1]) {
      hourData.OpeningTime2 = sortedValues[1].OpeningTime;
      hourData.ClosingTime2 = sortedValues[1].ClosingTime;
    }
  }

  return hourData;
});

export const hoursModaltoDb = (data) => {
  const dbModal = {};

  data.forEach(({ DayOfTheWeek, OpeningTime, ClosingTime, OpeningTime2, ClosingTime2 }) => {
    const times = [];
    if (OpeningTime && ClosingTime) {
      times.push({ OpeningTime, ClosingTime });
    }
    if (OpeningTime2 && ClosingTime2) {
      times.push({ OpeningTime: OpeningTime2, ClosingTime: ClosingTime2 });
    }
    const day = moment().day(DayOfTheWeek).format('dddd');
    dbModal[day] = times;
  });

  return dbModal;
};

const timeToKey = period => [
  `${period.OpeningTime}-${period.ClosingTime}`,
  `${period.OpeningTime2}-${period.ClosingTime2}`,
].join('_');

const shortenTime = time => (time ? time.split(':').slice(0, 2).join(':') : undefined);

const createDefault = () => [
  {
    DOWs: [1, 2, 3, 4, 5],
    OpeningTime: DEFAULT_OPENING_TIME,
    ClosingTime: DEFAULT_CLOSING_TIME,
  },
];

class BusinessHours extends React.Component {
  state = {
    periodGroups: [],
  };

  componentDidMount() {
    const { businessHours } = this.props;
    if (businessHours) {
      const periodGroups = {};
      businessHours.forEach((period) => {
        if (!period || !period.OpeningTime || !period.ClosingTime) {
          return;
        }
        const key = timeToKey(period);
        if (!periodGroups[key]) {
          periodGroups[key] = {
            DOWs: [],
            OpeningTime: shortenTime(period.OpeningTime),
            ClosingTime: shortenTime(period.ClosingTime),
            OpeningTime2: shortenTime(period.OpeningTime2),
            ClosingTime2: shortenTime(period.ClosingTime2),
          };
        }

        periodGroups[key].DOWs.push(period.DayOfTheWeek);
      });

      this.setState({ periodGroups: Object.entries(periodGroups).map(period => period[1]) });
    }
  }

  onHandleAlwaysOpenChange(alwaysOpen) {
    this.setState(
      {
        periodGroups: alwaysOpen ? [] : createDefault(),
      },
      () => this.onHandleChange(),
    );
  }

  onHandleGroupChange(periodGroups) {
    this.setState({ periodGroups }, () => this.onHandleChange());
  }

  onHandleChange() {
    const { onHandleChange } = this.props;
    const { periodGroups } = this.state;
    const businessHours = periodGroups.reduce(
      (sum, period) => [
        ...sum,
        ...period.DOWs.map(dow => ({
          DayOfTheWeek: dow,
          OpeningTime: period.OpeningTime,
          ClosingTime: period.ClosingTime,
          ...(period.OpeningTime2 && period.ClosingTime2
            ? {
              OpeningTime2: period.OpeningTime2,
              ClosingTime2: period.ClosingTime2,
            }
            : {}),
        })),
      ],
      [],
    );
    onHandleChange('BusinessHours', businessHours);
  }

  onRemove(index) {
    const { periodGroups } = this.state;
    periodGroups.splice(index, 1);
    this.onHandleGroupChange(periodGroups);
  }

  onAdd() {
    const { periodGroups } = this.state;
    periodGroups.push({
      DOWs: [],
      OpeningTime: DEFAULT_OPENING_TIME,
      ClosingTime: DEFAULT_CLOSING_TIME,
    });
    this.onHandleGroupChange(periodGroups);
  }

  getPeriodGroups() {
    let { businessHours } = this.props;
    if (!businessHours || !businessHours.length) {
      businessHours = createDefault();
    }

    const periodGroups = {};
    businessHours.forEach((period, index) => {
      if (!period || !period.OpeningTime || !period.ClosingTime) {
        return;
      }
      const key = timeToKey(period);
      if (!periodGroups[key]) {
        periodGroups[key] = [];
      }

      periodGroups[key][index] = period;
    });

    return Object.entries(periodGroups).map(period => period[1]);
  }

  noFreeDay() {
    const { periodGroups } = this.state;
    return uniq(flatMap(periodGroups.map(group => group.DOWs))).length === 7;
  }

  render() {
    const { periodGroups } = this.state;
    const alwaysOpen = !periodGroups || !periodGroups.length;

    return (
      <div className="BusinessEdit__Blocks BusinessHours">
        <label
          className={`BusinessHours__Option ${!alwaysOpen ? '' : 'BusinessHours__Option--Active'}`}
        >
          <input
            type="radio"
            name="schedulePost"
            checked={alwaysOpen}
            onChange={() => this.onHandleAlwaysOpenChange(true)}
          />
          <div className="BusinessHours__Option__Content">
            <div className="BusinessHours__Option__Content__Title">Always open</div>
            <div className="BusinessHours__Option__Content__Description">
              Visitors can come to your business 24/7
            </div>
          </div>
        </label>

        <label
          className={`BusinessHours__Option ${alwaysOpen ? '' : 'BusinessHours__Option--Active'}`}
        >
          <input
            type="radio"
            name="schedulePost"
            checked={!alwaysOpen}
            onChange={() => this.onHandleAlwaysOpenChange(false)}
          />
          <div className="BusinessHours__Option__Content">
            <div className="BusinessHours__Option__Content__Title">Open during selected hours</div>
            <div className="BusinessHours__Option__Content__Description">Input your own hours</div>
          </div>
        </label>

        {periodGroups.map((group, index) => (
          <BusinessHoursGroup
            value={group}
            onHandleChange={val => this.onHandleGroupChange(val)}
            currentIndex={index}
            periods={periodGroups}
            key={uuidv1()}
            footer={
              index ? (
                <>
                  <button
                    className="BusinessHoursGroup__Footer__Remove"
                    onClick={() => this.onRemove(index)}
                    type="button"
                  >
                    - Remove
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="BusinessHoursGroup__Footer__AddMoreHours"
                    onClick={() => this.onAdd()}
                    type="button"
                    disabled={this.noFreeDay()}
                  >
                    + Add more hours
                  </button>

                  <div className="BusinessHoursGroup__Footer__Description">
                    If your business hours are different on other days
                  </div>
                </>
              )
            }
          />
        ))}
      </div>
    );
  }
}

BusinessHours.propTypes = {
  businessHours: arrayOf(BUSINESS_HOUR_TYPE),
  onHandleChange: func.isRequired,
};

BusinessHours.defaultProps = {
  businessHours: null,
};

export default BusinessHours;
