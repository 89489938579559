import {
  CREATE_VIDEO,
  CREATE_VIDEO_ERROR,
  CREATE_VIDEO_SUCCESS,
  DELETE_VIDEO,
  DELETE_VIDEO_ERROR,
  DELETE_VIDEO_INIT,
  DELETE_VIDEO_SUCCESS,
  GET_VIDEOS,
  GET_VIDEOS_ERROR,
  GET_VIDEOS_SUCCESS,
  REMOVE_LINK,
  SHARE_LINK,
  SHARE_LINK_ERROR,
  SHARE_LINK_SUCCESS,
} from '../actions/videos';

const initialState = {
  videos: {},
  videosError: '',
  isFetchingVideos: false,

  createdVideo: {},
  createVideoError: '',
  isCreatingVideo: false,

  link: '',
  linkError: '',
  isFetchingLink: false,

  isDeletingVideo: false,
  isDeletingError: false,
  isDeletingVideoSuccess: false,
  deletingErrorMessage: '',
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_VIDEOS: {
      return {
        ...state,
        videos: {},
        videosError: '',
        isFetchingVideos: true,
      };
    }

    case GET_VIDEOS_SUCCESS: {
      return {
        ...state,
        videos: payload.data || {},
        isFetchingVideos: false,
      };
    }

    case GET_VIDEOS_ERROR: {
      return {
        ...state,
        videosError: payload.error || 'Error getting videos',
        isFetchingVideos: false,
      };
    }

    case CREATE_VIDEO: {
      return {
        ...state,
        isCreatingVideo: true,
      };
    }

    case CREATE_VIDEO_SUCCESS: {
      return {
        ...state,
        createdVideo: payload ? payload.map(({ Data }) => Data) : {},
        isCreatingVideo: false,
      };
    }

    case CREATE_VIDEO_ERROR: {
      return {
        ...state,
        createVideoError: payload.error || '',
        isCreatingVideo: false,
      };
    }

    case REMOVE_LINK: {
      return {
        ...state,
        link: '',
        linkError: '',
        isFetchingLink: false,
      };
    }

    case SHARE_LINK: {
      return {
        ...state,
        link: '',
        linkError: '',
        isFetchingLink: true,
      };
    }

    case SHARE_LINK_SUCCESS: {
      return {
        ...state,
        link: payload.Data || '',
        isFetchingLink: false,
      };
    }

    case SHARE_LINK_ERROR: {
      return {
        ...state,
        linkError: payload.error || 'Error getting link',
        isFetchingLink: false,
      };
    }

    case DELETE_VIDEO: {
      return {
        ...state,
        isDeletingVideo: true,
        isDeletingError: false,
        isDeletingVideoSuccess: false,
        deletingErrorMessage: '',
      };
    }

    case DELETE_VIDEO_SUCCESS: {
      return {
        ...state,
        isDeletingVideo: false,
        isDeletingVideoSuccess: true,
        videos: {
          ...state.videos,
          Results:
            state.videos.Results
            && state.videos.Results.length
            && state.videos.Results.map((video) => {
              if (video.Key === payload) {
                return {
                  ...video,
                  Disabled: true,
                };
              }

              return video;
            }),
        },
      };
    }

    case DELETE_VIDEO_ERROR: {
      return {
        ...state,
        isDeletingVideo: false,
        isDeletingError: true,
        isDeletingVideoSuccess: false,
        deletingErrorMessage: payload,
      };
    }

    case DELETE_VIDEO_INIT: {
      return {
        ...state,
        isDeletingVideo: false,
        isDeletingError: false,
        isDeletingVideoSuccess: false,
        deletingErrorMessage: '',
      };
    }

    default:
      return state;
  }
}
