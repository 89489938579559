import './index.sass';

import { string } from 'prop-types';
import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

export default function GoogleLocationAutoComplete(props) {
  const { icon } = props;
  return (
    <div className={`Autocomplete ${icon ? 'Autocomplete--WithIcon' : ''}`}>
      {icon && <img alt="search" className="Autocomplete__Icon" src={icon} />}

      <GooglePlacesAutocomplete
        autocompletionRequest={{
          types: ['establishment'],
        }}
        className="full"
        suggestionsClassNames={{
          container: 'GooglePlacesAutocomplete__Container',
          suggestion: 'GooglePlacesAutocomplete__Option',
        }}
        {...props}
      />
    </div>
  );
}

GoogleLocationAutoComplete.propTypes = {
  icon: string,
};

GoogleLocationAutoComplete.defaultProps = {
  icon: null,
};
