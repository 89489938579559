import './index.sass';
import { func } from 'prop-types';
import React from 'react';

import { validWebsite } from '../../../../helpers';
import { BUSINESS_DETAILS_TYPE } from '../../../../types/business';

const BusinessMenuForm = (props) => {
  const {
    formState: { MenuUrl },
    onHandleChange,
  } = props;

  return (
    <div className="pb-10 BusinessEdit__Blocks">
      <h4 className="BusinessMenuForm__Title">
        Menu Link
      </h4>
      <h3 className="BusinessMenuForm__Subtitle">
        Let your customers see what’s on the menu
      </h3>

      <label htmlFor="txtBusinessMenuUrl">
        Menu Link
      </label>

      <input
        autoComplete="none"
        className="full"
        id="txtBusinessMenuUrl"
        name="MenuUrl"
        onChange={({ target }) => onHandleChange(target.name, target.value)}
        pattern={validWebsite(MenuUrl).regexAsString}
        placeholder="https:/www.companyname.com/menu.html"
        title="Your menu URL is invalid"
        type="text"
        value={MenuUrl || ''}
      />
    </div>
  );
};

BusinessMenuForm.propTypes = {
  formState: BUSINESS_DETAILS_TYPE.isRequired,
  onHandleChange: func.isRequired,
};

export default BusinessMenuForm;
