import './index.sass';

import { isEmpty } from 'lodash';
import moment from 'moment';
import { bool, func, instanceOf, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import uuidv1 from 'uuid/v1';

import { getBusinessDetails as getBusinessDetailsAction } from '../../../../actions/businesses';
import {
  completeGig,
  deleteGig as deleteGigAction,
  getGigDetails as getGigDetailsAction,
  resetGig as resetGigAction,
} from '../../../../actions/gigs';
import AsyncContent from '../../../../components/AsyncContent';
import { Col, Row } from '../../../../components/Grid';
import HamburgerMenu from '../../../../components/HamburgerMenu';
import LightBox from '../../../../components/LightBox';
import MasterPage from '../../../../components/MasterPage';
import USER_ROLES from '../../../../config/user-roles';
import { getStatusName } from '../../../../helpers';
import { BUSINESS_DETAILS_TYPE } from '../../../../types/business';
import HISTORY_TYPE from '../../../../types/history';
import LOCATION_TYPE from '../../../../types/location';
import ROUTER_MATCH_TYPE from '../../../../types/routerMatch';
import BusinessGigCreate from '../BusinessGigCreate';
import Nominees from './Nominees';
import Submissions from './Submissions';

const TABS = {
  0: 'details',
  1: 'submissions',
  2: 'nominees',
};

class BusinessGigDetails extends React.Component {
  state = {
    loading: true,
    loadingComplete: false,
    isCreateGigOpen: false,
    showDeleteModal: false,
    createGigError: '',
    userRole: localStorage.getItem('frankiRole'),
  };

  componentDidMount() {
    const { resetGig } = this.props;
    this.getBusinessDetails();
    resetGig();
  }

  componentDidUpdate(prevProps) {
    const {
      businessDetails,
      gigDetails,
      match: {
        params: { corporateGroupId, gigId },
      },
      complete,
    } = this.props;

    if (prevProps.match.params.corporateGroupId !== corporateGroupId) {
      this.getBusinessDetails();
    }

    if (prevProps.match.params.gigId !== gigId) {
      this.getGigDetails();
    }

    if (prevProps.businessDetails !== businessDetails) {
      this.getGigDetails();
    }

    if (prevProps.gigDetails !== gigDetails || prevProps.businessDetails !== businessDetails) {
      this.enableLoader(false);
    }

    if (prevProps.complete !== complete) {
      this.getGigDetails();
    }
  }

  async getBusinessDetails() {
    const { match, businessDetails, getBusinessDetails } = this.props;
    const businessId = Number(match.params.corporateGroupId);
    const idChanged = businessDetails.CorporateGroupId && businessDetails.CorporateGroupId !== businessId;

    if (isEmpty(businessDetails) || idChanged) {
      await getBusinessDetails(businessId);
    } else {
      this.getGigDetails();
    }
  }

  getGigDetails = async () => {
    const { getGigDetails, gigDetails, match } = this.props;

    const idChanged = gigDetails.GigId && gigDetails.GigId !== match.params.gigId;

    if (isEmpty(gigDetails) || idChanged) {
      this.enableLoader();
      await getGigDetails(match.params.gigId);
      this.enableLoader(false);
    }
  };

  onHandleCompleteGig = async (id) => {
    const { completeGig: completeGigAction } = this.props;
    this.setState({ loadingComplete: true });
    await completeGigAction(id);
    this.setState({ loadingComplete: false });
  };

  getActiveTabIndex() {
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    switch (query.get('view')) {
      case TABS[1]:
        return 1;

      case TABS[2]:
        return 2;

      default:
        return 0;
    }
  }

  setActiveTabIndex(tabIndex) {
    const { history, match } = this.props;

    history.push({
      pathname: match.url,
      search: `view=${TABS[tabIndex]}`,
    });
  }

  enableLoader = (value = true) => {
    this.setState({
      loading: value,
    });
  };

  createGigError(msg) {
    const { history } = this.props;
    const userRole = localStorage.getItem('frankiRole');
    const { VerificationStatus, CorporateGroupId } = this.props.businessDetails;
    const result = (
      <>
        {msg || (
          <>
            To create a GiG, edit your profile, or view content you first need to finish the
            verification process. If you feel you&rsquo;ve received this message in error or if a
            franki representative hasn&rsquo;t contacted you, please email us at
            {' '}
            <a href="mailto:customersupport@befranki.com">customersupport@befranki.com</a>
          </>
        )}
        {userRole == USER_ROLES.businessManager && VerificationStatus != 1 && (
          <div className="GigSubmissions__AwardConfirm__Actions">
            <button
              onClick={() => this.setState({ createGigError: '' })}
              className="button small"
              type="button"
            >
              Close
            </button>

            <button
              onClick={() => {
                history.push(`/business-phone-verification/${CorporateGroupId}`);
              }}
              type="button"
              className="button orange-theme small"
            >
              Verify Business
            </button>
          </div>
        )}
      </>
    );

    this.setState({
      createGigError: result,
    });
  }

  openCreateGigModal() {
    this.setState({ isCreateGigOpen: true });
  }

  closeCreateGigModal() {
    this.setState({ isCreateGigOpen: false });
  }

  render() {
    const {
      auth,
      businessDetails,
      completeError,
      deleted,
      deletedError,
      deleteGig,
      gigDetails,
      gigDetailsError,
      history,
      location,
      match,
      isFetchingBusinessDetails,
      isFetchingGigDetails,
    } = this.props;

    const { isCreateGigOpen, createGigError } = this.state;

    const { loading, loadingComplete, userRole, showDeleteModal } = this.state;

    let GigStatusName = null;

    if (!isEmpty(gigDetails)) {
      GigStatusName = getStatusName(gigDetails.StatusId);
    }
    const activeTabIndex = this.getActiveTabIndex();

    if (deleted) {
      if ([USER_ROLES.admin, USER_ROLES.saleAdmin].includes(userRole)) { setTimeout(() => history.push('/admin/gigs'), 1000); } else {
        setTimeout(
          () => history.push(`/admin/business-gigs/${businessDetails.CorporateGroupId}/8/1`),
          1000,
        );
      }
    }

    return (
      <>
        <Helmet title="Franki Admin: gig details" />

        <MasterPage
          auth={auth}
          businessSidebarOn
          businessSidebarData={businessDetails}
          // headerBreadcrumb={[
          //   { name: 'all gigs', url: `/admin/business-gigs/${match.params.corporateGroupId}/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}` },
          //   { name: 'details' },
          // ]}
          headerButtons={(
            <button
              type="button"
              className="button small yellow-theme"
              onClick={() => {
                if (businessDetails.BusinessSourceId !== 1) {
                  this.createGigError(
                    'You cannot create a gig for this business until it is verified and converted to a local Franki business. Please contact customersupport@befranki.com for any queries.',
                  );
                } else {
                  this.openCreateGigModal();
                }
              }}
            >
              Create
            </button>
          )}
        >
          <AsyncContent
            content={details => (
              <div className="GigDetails">
                <Row rowSpacing={10} cellSpacing={10} className="GigDetails__Header">
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="relative full">
                      <h2 className="GigDetails__Title">
                        {`${details.Name} `}
                        <span
                          className={`GigDetails__Status GigDetails__Status--${GigStatusName.slug}`}
                        >
                          {GigStatusName.label}
                        </span>
                      </h2>

                      <small className="GigDetails__ChallengeDates">
                        {moment(new Date(details.StartDate)).format('MMMM D, YYYY')}
                        {' - '}
                        {moment(new Date(details.EndDate)).format('MMMM D, YYYY')}
                      </small>

                      <HamburgerMenu
                        className="GigDetails__Ellipsis"
                        menuItems={[
                          {
                            innerContent: `ID: ${details.GigId}`,
                          },
                        ]}
                      />
                    </div>
                  </Col>
                </Row>

                <Tabs
                  className="frankiTabs"
                  onSelect={tabIndex => this.setActiveTabIndex(tabIndex)}
                  selectedIndex={activeTabIndex}
                >
                  <TabList>
                    <Tab>details</Tab>

                    <Tab>submissions</Tab>

                    <Tab>nominees</Tab>
                  </TabList>

                  <TabPanel>
                    <Row cellSpacing={25} rowSpacing={15} className="tab-row">
                      <Col lg={6} md={6} sm={12} xs={12} className="tab-col">
                        <figure className="tab-mainImg">
                          {details.Photo && details.Photo.FullResolutionUrl && (
                            <img src={details.Photo.FullResolutionUrl} alt="" />
                          )}
                        </figure>

                        <p className="GigDetails__Description">{details.Description}</p>
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12} className="tab-col">
                        {false
                          && !!(details.Views || details.GigTotalSaves || details.VideosSubmitted) && (
                            <div className="GigDetails__Blocks">
                              <h3 className="GigDetails__Subtitle">Engagement</h3>
                              <Row
                                rowSpacing={10}
                                cellSpacing={10}
                                className="GigDetails__EngagementDetails"
                              >
                                <Col lg={4} md={4} sm={4} xs={12}>
                                  <span className="GigDetails__EngagementDetails__Label">
                                    views
                                  </span>
                                  <span className="GigDetails__EngagementDetails__Text">
                                    {details.Views || 0}
                                  </span>
                                </Col>
                                <Col lg={4} md={4} sm={4} xs={12}>
                                  <span className="GigDetails__EngagementDetails__Label">
                                    saves
                                  </span>
                                  <span className="GigDetails__EngagementDetails__Text">
                                    {details.GigTotalSaves || 0}
                                  </span>
                                </Col>
                                <Col lg={4} md={4} sm={4} xs={12}>
                                  <span className="GigDetails__EngagementDetails__Label">
                                    submissions
                                  </span>
                                  <span className="GigDetails__EngagementDetails__Text">
                                    {details.VideosSubmitted > 0}
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          )}

                        {!isEmpty(details.GigPrizes) && (
                          <div className="GigDetails__Blocks">
                            <h3 className="GigDetails__Subtitle">
                              Prizes
                              <span className="GigDetails__TotalPrize">
                                {`total = $${details.TotalPrizeAmount}`}
                              </span>
                            </h3>

                            <Row
                              rowSpacing={10}
                              cellSpacing={10}
                              className="GigDetails__PrizeDetails"
                            >
                              {details.GigPrizes.map(item => (
                                <Col lg={4} md={4} sm={4} xs={12} key={uuidv1()}>
                                  <span className="GigDetails__PrizeDetails__Amount">
                                    {`$${item.PrizeAmount}`}
                                  </span>

                                  <span className="GigDetails__PrizeDetails__Description">
                                    {item.Description}
                                  </span>

                                  <span className="GigDetails__PrizeDetails__Qty">
                                    {item.RemainingWinnerQty}
                                    {'/'}
                                    {item.WinnerQty}
                                    {' remaining'}
                                  </span>
                                </Col>
                              ))}
                            </Row>
                          </div>
                        )}

                        {!isEmpty(details.GigRules) && (
                          <div className="GigDetails__Blocks">
                            <h3 className="GigDetails__Subtitle">Rules</h3>
                            <ol className="GigDetails__RuleDetails">
                              {details.GigRules.map(item => (
                                <li key={uuidv1()}>{item.Description}</li>
                              ))}
                            </ol>
                          </div>
                        )}
                      </Col>
                    </Row>

                    <ul className="GigDetails__Actions">
                      <li>
                        <button
                          className="button small red-theme"
                          disabled={
                            details.VideosSubmitted > 0
                            || getStatusName(details.StatusId).slug !== 'draft'
                          }
                          onClick={() => this.setState({ showDeleteModal: true })}
                          type="button"
                        >
                          delete
                        </button>
                      </li>

                      <li>
                        {details.VideosSubmitted > 0
                          || getStatusName(details.StatusId).slug === 'closed' ? (
                          <button type="button" className="button small orange-theme" disabled>
                            edit
                          </button>
                        ) : (
                          <Link
                            className="button small orange-theme"
                            to={`/admin/business-gig-edit/${match.params.corporateGroupId}/${match.params.gigId}`}
                          >
                            edit
                          </Link>
                        )}
                      </li>

                      {getStatusName(details.StatusId).slug === 'review' && (
                        <li>
                          <button
                            type="button"
                            className="button small black-theme"
                            onClick={() => this.onHandleCompleteGig(details.GigId)}
                            disabled={loadingComplete}
                          >
                            {loadingComplete ? 'loading...' : 'complete'}
                          </button>
                        </li>
                      )}
                    </ul>
                    <LightBox
                      style={
                        {
                          width: 450,
                          height: 200,
                          marginLeft: -225,
                          marginTop: -100,
                        }
                      }
                      onClose={() => this.setState({ showDeleteModal: false })}
                      show={showDeleteModal}
                    >
                      <div className="DialogTitle">Are you sure?</div>
                      <div className="DialogContent">
                        Are you sure you want to delete gig?
                      </div>

                      <div className="d-flex-center" style={{ marginTop: '2rem' }}>
                        <button
                          type="button"
                          className="button small w100"
                          onClick={() => {
                            this.setState({ showDeleteModal: false });
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="button small orange-theme w100"
                          onClick={() => {
                            this.setState({ showDeleteModal: false });
                            deleteGig(details.GigId);
                          }}
                        >
                          Ok
                        </button>
                      </div>
                    </LightBox>
                  </TabPanel>

                  <TabPanel>
                    <Submissions
                      gigId={details.GigId}
                      gigPrizes={details.GigPrizes}
                      history={history}
                      location={location}
                      match={match}
                      videosSubmitted={details.VideosSubmitted}
                    />
                  </TabPanel>

                  <TabPanel>
                    <Nominees
                      gigId={details.GigId}
                      gigPrizes={details.GigPrizes}
                      userGigsEarning={details.UserGigsEarning}
                      onUpdate={() => this.getGigDetails()}
                    />
                  </TabPanel>
                </Tabs>
              </div>
            )}
            errorMessage={gigDetailsError || completeError || deletedError || createGigError}
            successMessage={deleted}
            expectedData={gigDetails}
            loading={loading || isFetchingBusinessDetails || isFetchingGigDetails}
          />
        </MasterPage>

        <LightBox
          className="BusinessGigs__CreateGigLightBox"
          show={isCreateGigOpen}
          spacing={0}
          width={1318}
          height={715}
          style={{
            marginLeft: -650,
          }}
          onClose={() => {
            this.closeCreateGigModal();
          }}
        >
          <BusinessGigCreate {...this.props} />
        </LightBox>
      </>
    );
  }
}

BusinessGigDetails.propTypes = {
  auth: bool.isRequired,
  isFetchingBusinessDetails: bool.isRequired,
  isFetchingGigDetails: bool.isRequired,
  businessDetails: BUSINESS_DETAILS_TYPE.isRequired,
  complete: string,
  completeError: string,
  completeGig: func.isRequired,
  resetGig: func.isRequired,
  deleted: string,
  deletedError: string,
  deleteGig: func.isRequired,
  getBusinessDetails: func.isRequired,
  getGigDetails: func.isRequired,
  gigDetails: instanceOf(Object),
  gigDetailsError: string,
  history: HISTORY_TYPE.isRequired,
  location: LOCATION_TYPE.isRequired,
  match: ROUTER_MATCH_TYPE({
    corporateGroupId: string.isRequired,
    gigId: string.isRequired,
  }).isRequired,
};

BusinessGigDetails.defaultProps = {
  complete: {},
  completeError: null,
  deleted: '',
  deletedError: '',
  gigDetails: {},
  gigDetailsError: '',
};

const mapStateToProps = state => ({
  businessDetails: state.businesses.businessDetails,
  isFetchingBusinessDetails: state.businesses.isFetchingBusinessDetails,
  businessDetailsError: state.businesses.businessDetailsError,
  complete: state.gigs.complete,
  completeError: state.gigs.completeError,
  deleted: state.gigs.deleted,
  deletedError: state.gigs.deletedError,
  gigDetails: state.gigs.details,
  gigDetailsError: state.gigs.detailsError,
  isFetchingGigDetails: state.gigs.isFetchingGigDetails,
});

export default connect(mapStateToProps, {
  completeGig,
  deleteGig: deleteGigAction,
  getBusinessDetails: getBusinessDetailsAction,
  getGigDetails: getGigDetailsAction,
  resetGig: resetGigAction,
})(BusinessGigDetails);
