const GIG_STATUSES = [
  { label: 'active', value: 1 },
  { label: 'in review', value: 2 },
  { label: 'closed', value: 3 },
];

export default GIG_STATUSES;

export const VERIFICATION_STATUSES = [
  { label: 'Not Verified', value: 0 },
  { label: 'Verified', value: 1 },
  { label: 'Pending', value: 2 },
  { label: 'Rejected', value: 3 },
];

export const POST_REVIEW_STATUSES = [
  { label: 'Pending Review', value: 1, color: '#AEAEAE' },
  { label: 'In Review', value: 2, color: '#FF0000' },
  { label: 'Final Review', value: 3, color: '#FAB542' },
  { label: 'In Final Review', value: 4, color: '#FAB542' },
  { label: 'Admin Review', value: 5, color: '#FADE57' },
  { label: 'In Admin Review', value: 6, color: '#FADE57' },
  { label: 'Acceptable', value: 7, color: '#58A500' },
  { label: 'Remove Forever', value: 8, color: '#FF0000' },
];

export const POST_REVIEW_COMMENTS = [
  { label: 'Fi - Wrong Business', value: 1 },
  { label: 'Fi - Fake review', value: 2 },
  { label: 'Fi - Disgruntled employee', value: 3 },
  { label: 'Fi - Competitor', value: 4 },
  { label: 'Sp - Personal promotion', value: 5 },
  { label: 'Sp - Promoting non-franki Products', value: 6 },
  { label: 'Sp - Promoting non-franki business', value: 7 },
  { label: 'Sp - Phishing', value: 8 },
  { label: 'N - Purposeful/blatant nudity', value: 9 },
  { label: 'N - Pornography', value: 10 },
  { label: 'N - Accidental nudity', value: 11 },
  { label: 'H - Bigotry', value: 12 },
  { label: 'H - Hateful symbols', value: 13 },
  { label: 'H - Racist', value: 14 },
  { label: 'H - Sexist', value: 15 },
  { label: 'B - Harassment of individual or user', value: 16 },
  { label: 'B - Harassment of business', value: 17 },
  { label: 'B - Harassment in-store', value: 18 },
  { label: 'V - Fighting', value: 19 },
  { label: 'V - Physical abusive behavior', value: 20 },
  { label: 'D - Stealing Data', value: 21 },
  { label: 'D - Crime Organizations', value: 22 },
  { label: 'D - Organizations against humanity', value: 23 },
  { label: 'IP - Song Choice', value: 24 },
  { label: 'IP - Stolen Video Clip (franki user)', value: 25 },
  { label: 'IP - Stolen Video Clip (other)', value: 26 },
  { label: 'S - Not a Review', value: 27 },
  { label: 'S - Meme', value: 28 },
  { label: 'S - Blank video', value: 29 },
  { label: 'S - Other (unspecified)', value: 30 },
];

export const POST_REVIEW_REASONING = [
  { label: 'False information', value: 1 },
  { label: "It's spam", value: 2 },
  { label: 'Nudity or sexual activity', value: 3 },
  { label: 'Hate speech or symbols', value: 4 },
  { label: 'Bullying or harassment', value: 5 },
  { label: 'Violence or dangerous organization', value: 6 },
  { label: 'Intellectual property violation', value: 7 },
  { label: 'Something Else', value: 8 },
];
