import './index.sass';

import { bool, func, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { contact as contactAction } from '../../actions/businesses';
import checkSvg from '../../assets/images/check-contact.svg';
import AlertMessages from '../../components/AlertMessages';
import AsyncContent from '../../components/AsyncContent';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { validEmailAddress } from '../../helpers';
import HISTORY_TYPE from '../../types/history';

/* global window */
class Contact extends React.Component {
  state = {
    Name: '',
    BusinessName: '',
    PhoneNumber: '',
    Email: '',
    Message: '',
    submitted: false,
    displaySuccessScreen: false,
  };

  componentDidUpdate(prevProps) {
    const { isSendingContactMessage, contactSuccessMessage } = this.props;
    if (!isSendingContactMessage && prevProps.isSendingContactMessage && contactSuccessMessage) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ displaySuccessScreen: true });
    }
  }

  async onHandleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });

    const formError = this.getError();
    if (formError.alert) {
      return;
    }

    const { contact } = this.props;
    const { Name, BusinessName, PhoneNumber, Email, Message } = this.state;
    contact({ Name, BusinessName, PhoneNumber, Email, Message });
  }

  onHandleChange(name, value) {
    this.setState({ [name]: value, submitted: false });
  }

  getError() {
    const { Name, BusinessName, Email, Message } = this.state;
    if (!Name || !BusinessName || !Email || !Message) {
      return {
        name: !Name && 'Required',
        businessName: !BusinessName && 'Required',
        email: !Email && 'Required',
        message: !Message && 'Required',
        alert: 'Mandatory fields have not been completed. Please try again.',
      };
    }

    const validEmail = validEmailAddress(Email);
    if (!validEmail.result) {
      return {
        email: 'Invalid email',
        alert: 'Email format is invalid. Please try again.',
      };
    }

    return {
      alert: '',
    };
  }

  render() {
    const {
      Name,
      BusinessName,
      PhoneNumber,
      Email,
      Message,
      displaySuccessScreen,
      messageType,
      submitted,
    } = this.state;

    const { isSendingContactMessage, contactErrorMessage, history } = this.props;
    const formError = this.getError();

    return (
      <div className="AuthWrapper">
        <Header auth={false} global />

        <AsyncContent
          content={() => (
            <main className="AuthMain Contact">
              <AlertMessages
                className="BusinessGigCreateSuccessfully__AlertMessages"
                show={submitted && !!formError.alert}
                type="error"
                message={formError.alert}
              />

              <div className="Auth">
                {!displaySuccessScreen ? (
                  <form onSubmit={(e) => this.onHandleSubmit(e)}>
                    <div className="Auth__Header">
                      <h1 className="Auth__Title">Contact Us</h1>
                      <div className="Contact__Description">
                        We are here to help. Please check out our&nbsp;
                        <a href="https://befranki.com/faq" className="orange-theme" target="_blank">
                          FAQs
                        </a>
                        &nbsp;or fill out the form and someone
                        <br />
                        from the Franki team will get back to you.
                      </div>
                    </div>

                    <fieldset>
                      <label className="Auth__Label" htmlFor="txtName">
                        Name
                        <em>*</em>
                        &nbsp;
                        {submitted && formError.name}
                      </label>

                      <input
                        autoComplete="off"
                        className={`full ${submitted && formError.name && 'error'}`}
                        disabled={isSendingContactMessage}
                        id="txtName"
                        name="Name"
                        onChange={({ target }) => this.onHandleChange(target.name, target.value)}
                        type="text"
                        value={Name}
                      />
                    </fieldset>

                    <fieldset>
                      <label className="Auth__Label" htmlFor="txtBusinessName">
                        Business name
                        <em>*</em>
                        &nbsp;
                        {submitted && formError.businessName}
                      </label>

                      <input
                        autoComplete="off"
                        className={`full ${submitted && formError.businessName && 'error'}`}
                        disabled={isSendingContactMessage}
                        id="txtBusinessName"
                        name="BusinessName"
                        onChange={({ target }) => this.onHandleChange(target.name, target.value)}
                        type="text"
                        value={BusinessName}
                      />
                    </fieldset>

                    <fieldset className="Contact__PhoneInput">
                      <label className="Auth__Label" htmlFor="txtPhone">
                        Phone
                      </label>

                      <input
                        autoComplete="off"
                        className="full"
                        disabled={isSendingContactMessage}
                        id="txtPhone"
                        maxLength={60}
                        name="PhoneNumber"
                        onChange={({ target }) => this.onHandleChange(target.name, target.value)}
                        type="text"
                        value={PhoneNumber}
                      />
                    </fieldset>

                    <fieldset>
                      <label className="Auth__Label" htmlFor="txtEmail">
                        Email
                        <em>*</em>
                        &nbsp;
                        {submitted && formError.email}
                        &nbsp;
                      </label>

                      <input
                        className={`full ${submitted && formError.email && 'error'}`}
                        disabled={isSendingContactMessage}
                        id="txtEmail"
                        name="Email"
                        onChange={({ target }) => this.onHandleChange(target.name, target.value)}
                        type="text"
                        value={Email}
                      />
                    </fieldset>

                    <fieldset>
                      <label className="Auth__Label" htmlFor="txtMessage">
                        Message
                        <em>*</em>
                        &nbsp;
                        {submitted && formError.message}
                      </label>

                      <textarea
                        className={`full ${submitted && formError.message && 'error'}`}
                        disabled={isSendingContactMessage}
                        id="txtMessage"
                        name="Message"
                        onChange={({ target }) => this.onHandleChange(target.name, target.value)}
                        type={messageType}
                        value={Message}
                      />
                    </fieldset>

                    <div className="Contact__Action">
                      <button
                        className="button orange-theme"
                        disabled={isSendingContactMessage}
                        type="submit"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </form>
                ) : (
                  <>
                    <h1 className="Contact__SuccessTitle">
                      Your message has been sent &nbsp;
                      <span role="img" aria-label="success">
                        🎉{' '}
                      </span>
                    </h1>

                    <div className="Contact__SuccessDescription">We will get back to you soon!</div>

                    <img
                      className="Contact__SuccessCheck"
                      src={checkSvg}
                      alt="selected"
                      width="90"
                    />

                    <div className="Contact__Action">
                      <button
                        className="button orange-theme"
                        onClick={() => history.push('/claim-my-business')}
                        type="button"
                      >
                        RETURN TO FRANKI
                      </button>
                    </div>
                  </>
                )}
              </div>
            </main>
          )}
          errorMessage={contactErrorMessage}
          expectNoData
        />

        {!displaySuccessScreen && (
          <button
            // eslint-disable-next-line no-undef
            onClick={() => window.history.back()}
            className="button Contact__Back"
            type="button"
          >
            <span>BACK</span>
            <i className="icon-chevron-left" />
          </button>
        )}

        <Footer />
      </div>
    );
  }
}

Contact.propTypes = {
  history: HISTORY_TYPE.isRequired,
  isSendingContactMessage: bool,
  contact: func.isRequired,
  contactSuccessMessage: string,
  contactErrorMessage: string,
};

Contact.defaultProps = {
  isSendingContactMessage: null,
  contactSuccessMessage: null,
  contactErrorMessage: null,
};

const mapStateToProps = (state) => ({
  contactErrorMessage: state.businesses.contactErrorMessage,
  contactSuccessMessage: state.businesses.contactSuccessMessage,
  isSendingContactMessage: state.businesses.isSendingContactMessage,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    contact: contactAction,
  }),
)(Contact);
