import './index.sass';

import React from 'react';

import PROMOTION_TYPE from '../../../../types/promotion';

class PromotionCard extends React.PureComponent {
  render() {
    const { item } = this.props;
    return (
      <div className="PromotionCard">
        <div>
          <div className="PromotionCard__Header">
            <h3 className="PromotionCard__Subtitle">Discover Promoted</h3>
          </div>

          <div className="PromotionCard__Count">
            <span>{item.PromotionId}</span>
            <i className="icon-play" />
          </div>

          <div className="PromotionCard__Content">
            <h2 className="PromotionCard__Title">{item.PromotionTitle}</h2>
            {item.Photo && item.Photo.FullResolutionUrl && (
              <figure>
                <img alt={item.PromotionTitle} src={item.Photo.FullResolutionUrl} />
              </figure>
            )}

            <address className="PromotionCard__Address">
              {
                <span title={item.CityNames}>
                  {' '}
                  {item.CityNames
                    ? `${item.CityNames.substr(0, 200)}${item.CityNames.length > 200 ? '...' : ''}`
                    : null}
                </span>
              }
              {
                <span title={item.StateNames}>
                  {' '}
                  {item.StateNames
                    ? `${item.StateNames.substr(0, 200)}${
                        item.StateNames.length > 200 ? '...' : ''
                      }`
                    : null}
                </span>
              }
            </address>
          </div>
        </div>
      </div>
    );
  }
}

PromotionCard.propTypes = {
  item: PROMOTION_TYPE.isRequired,
};

export default PromotionCard;
