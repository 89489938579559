import './index.sass';

import moment from 'moment';
import React, { memo, useEffect } from 'react';

import defaultBusinessLogo from '../../assets/images/default-business-logo.png';
import defaultProfilePhoto from '../../assets/images/default-profile-photo.png';
import VIDEO_TYPE from '../../types/video';
import { Col, Row } from '../Grid';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

const FlaggedVideoDetailsComponent = (props) => {
  const { videoDetails } = props;

  // const products = ['California cake', 'Capuccino'];
  // const taggedUser = videoDetails.TaggedUsers.map(item => (
  //   <li key={uuidv1()}>{item.DisplayName}</li>
  // ));
  let imgSrc1 =
    videoDetails.CorporateGroup && videoDetails.CorporateGroup.Logo
      ? videoDetails.CorporateGroup.Logo.FullResolutionUrl
      : '';
  let imgSrc2 =
    videoDetails.PosterProfile && videoDetails.PosterProfile.ProfilePhoto
      ? videoDetails.PosterProfile.ProfilePhoto.FullResolutionUrl
      : '';
  if (!imgSrc1) imgSrc1 = defaultBusinessLogo;
  if (!imgSrc2) imgSrc2 = defaultProfilePhoto;

  const renderPostBody = () => {
    const {
      videoDetails: { PostBody, TaggedUsers },
    } = props;
    let result = PostBody;
    const userIds = [...PostBody.matchAll(/\[UserId:(\d+)\]/g)].map((m) => +m[1]);
    userIds.forEach((userid) => {
      const user = TaggedUsers.filter((f) => f.UserId == userid);
      if (user.length > 0) result = result.replace(`[UserId:${userid}]`, user[0].DisplayName);
    });
    return result;
  };

  const renderVideo = () => {
    const { videoDetails } = props;
    let result = (
      <VideoPlayer ratio="16:9" url={videoDetails.StreamingVideoUrl} />
    );
    if (!videoDetails.StreamingVideoUrl) {
      result = (
        <VideoPlayer ratio="16:9" url={videoDetails.VideoUrl} />
      );
    }
    return result;
  };

  useEffect(() => {
    setTimeout(() => {
      const x = document.getElementsByClassName('vjs-big-play-button');
      if (x.length > 0) x[0].click();
    }, 300);
  }, []);

  return (
    <div className="BusinessVideoDetails FlaggedVideoDetails">
      {renderVideo()}

      <div className="FlaggedVideoDetails__Social BusinessVideoDetails__Social">
        <Row cellSpacing={15} rowSpacing={15}>
          <Col lg={8} md={8} sm={12} xs={12}>
            <figure className="VideoDetailsHeader__Img">
              <img src={imgSrc1} alt="" style={{ tintColor: 'white' }} />
            </figure>
            <div className="VideoDetailsHeader__Info">
              {videoDetails.CorporateGroup.BusinessType.Name && (
                <span className="brand">{videoDetails.CorporateGroup.BusinessType.Name}</span>
              )}
              {videoDetails.CorporateGroup.FullName && (
                <span className="name">{videoDetails.CorporateGroup.FullName}</span>
              )}
              {!videoDetails.CorporateGroup.FullName && videoDetails.CorporateGroup.DisplayName && (
                <span className="name">{videoDetails.CorporateGroup.DisplayName}</span>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="FlaggedVideoDetails__Body BusinessVideoDetails__Body">
        <div className="VideoDetailsHeader">
          <figure className="VideoDetailsHeader__Img">
            <img src={imgSrc2} alt="" style={{ tintColor: 'white' }} />
          </figure>

          <div className="VideoDetailsHeader__Info">
            <strong>
              {videoDetails.PosterProfile ? videoDetails.PosterProfile.DisplayName : ''}
            </strong>
            {videoDetails.Posted && (
              <small>posted on {moment(new Date(videoDetails.Posted)).format('LLL')}</small>
            )}
          </div>
        </div>
        {videoDetails.PostBody && (
          <p className="FlaggedVideoDetails__Desc BusinessVideoDetails__Desc">{renderPostBody()}</p>
        )}
      </div>
    </div>
  );
};

FlaggedVideoDetailsComponent.propTypes = {
  videoDetails: VIDEO_TYPE.isRequired,
};

export default memo(FlaggedVideoDetailsComponent);
