import { APIRequest } from '../utils/api';

export const GET_PROMOTIONS = 'GET_PROMOTIONS';
export const GET_PROMOTIONS_SUCCESS = 'GET_PROMOTIONS_SUCCESS';
export const GET_PROMOTIONS_ERROR = 'GET_PROMOTIONS_ERROR';

export const GET_PROMOTION_CONTENTS = 'GET_PROMOTION_CONTENTS';
export const GET_PROMOTION_CONTENTS_SUCCESS = 'GET_PROMOTION_CONTENTS_SUCCESS';
export const GET_PROMOTION_CONTENTS_ERROR = 'GET_PROMOTION_CONTENTS_ERROR';

export function getPromotions(queryString = '') {
  return async (dispatch) => {
    dispatch({ type: GET_PROMOTIONS });
    await APIRequest({
      uri: `Marketing/Promotions${queryString}`,
      method: 'GET',
    })
      .then(({ Data }) => dispatch({
        type: GET_PROMOTIONS_SUCCESS,
        payload: { data: Data },
      }))
      .catch(error => dispatch({
        type: GET_PROMOTIONS_ERROR,
        payload: { error },
      }));
  };
}

export function getPromotionContents(promotionId) {
  return async (dispatch) => {
    dispatch({ type: GET_PROMOTION_CONTENTS });
    await APIRequest({
      uri: `Marketing/Promotions/${promotionId}/PromotionContents`,
      method: 'GET',
    })
      .then(({ Data }) => dispatch({
        type: GET_PROMOTION_CONTENTS_SUCCESS,
        payload: { data: Data },
      }))
      .catch(error => dispatch({
        type: GET_PROMOTION_CONTENTS_ERROR,
        payload: { error },
      }));
  };
}

export async function createPromotion(data) {
  const result = await APIRequest({
    uri: 'Marketing/Promotions',
    method: 'POST',
    data,
  })
    .then(res => res)
    .catch(err => ({
      Message: err,
      Success: false,
    }));

  return result;
}

export async function editPromotion(promotionId, data) {
  const result = await APIRequest({
    uri: `Marketing/Promotions/${promotionId}`,
    method: 'PATCH',
    data,
  })
    .then(res => res)
    .catch(err => ({
      Message: err,
      Success: false,
    }));

  return result;
}

export async function addContentToPromotions(data) {
  const result = await APIRequest({
    uri: `Marketing/Promotions/${data.PromotionId}/PromotionContents`,
    method: 'POST',
    data,
  })
    .then(res => res)
    .catch(err => ({
      Message: err,
      Success: false,
    }));

  return result;
}

export async function deletePromotion(promotionId) {
  const result = await APIRequest({
    uri: 'Marketing/Promotions/',
    method: 'DELETE',
    data: {
      PromotionIds: [promotionId],
    },
  })
    .then(res => res)
    .catch(err => ({
      Message: err,
      Success: false,
    }));

  return result;
}

export async function deletePromotionContent(promotionId, promotionContentId) {
  const result = await APIRequest({
    uri: `Marketing/Promotions/${promotionId}/PromotionContents`,
    method: 'DELETE',
    data: {
      PromotionContentIds: [promotionContentId],
    },
  })
    .then(res => res)
    .catch(err => ({
      Message: err,
      Success: false,
    }));

  return result;
}
