import { APIRequest, req } from '../utils/api';
import { REACT_APP_API_V2_URL } from '../config/variables';

export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST';
export const GET_COUNTRY_LIST_SUCCESS = 'GET_COUNTRY_LIST_SUCCESS';
export const GET_COUNTRY_LIST_ERROR = 'GET_COUNTRY_LIST_ERROR';

export const GET_STATES = 'GET_STATES';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_ERROR = 'GET_STATES_ERROR';

export const GET_PROMOTION_DETAIL = 'GET_PROMOTION_DETAIL';
export const GET_PROMOTION_DETAIL_SUCCESS = 'GET_PROMOTION_DETAIL_SUCCESS';
export const GET_PROMOTION_DETAIL_ERROR = 'GET_PROMOTION_DETAIL_ERROR';

export const GET_PROMOTION_LIST = 'GET_PROMOTION_LIST';
export const GET_PROMOTION_LIST_SUCCESS = 'GET_PROMOTION_LIST_SUCCESS';
export const GET_PROMOTION_LIST_ERROR = 'GET_PROMOTION_LIST_ERROR';

export const GET_PROMOTION_STAT = 'GET_PROMOTION_STAT_DETAIL';
export const GET_PROMOTION_STAT_SUCCESS = 'GET_PROMOTION_STAT_SUCCESS';
export const GET_PROMOTION_STAT_ERROR = 'GET_PROMOTION_STAT_ERROR';

export function getCountryList() {
  return async (dispatch) => {
    dispatch({ type: GET_COUNTRY_LIST });
    await APIRequest({
      uri: 'Location/Countries',
      method: 'GET',
      api: REACT_APP_API_V2_URL,

    })
      .then(({ Data }) => dispatch({
        type: GET_COUNTRY_LIST_SUCCESS,
        payload: { data: Data },
      }))
      .catch(error => dispatch({
        type: GET_COUNTRY_LIST_ERROR,
        payload: { error },
      }));
  };
}

export function getStates(id) {
  return async (dispatch) => {
    dispatch({ type: GET_STATES });
    await APIRequest({
      uri: `Location/Country/${id}/States`,
      method: 'GET',
      api: REACT_APP_API_V2_URL,

    })
      .then(({ Data }) => dispatch({
        type: GET_STATES_SUCCESS,
        payload: { data: Data },
      }))
      .catch(error => dispatch({
        type: GET_STATES_ERROR,
        payload: { error },
      }));
  };
}

export function getPromotionDetail(id) {
  return async (dispatch) => {
    dispatch({ type: GET_PROMOTION_DETAIL });
    await APIRequest({
      uri: `ContentPromotion/Pricing/${id}`,
      method: 'GET',
      api: REACT_APP_API_V2_URL,

    })
      .then(({ Data }) => dispatch({
        type: GET_PROMOTION_DETAIL_SUCCESS,
        payload: { data: Data },
      }))
      .catch(error => dispatch({
        type: GET_PROMOTION_DETAIL_ERROR,
        payload: { error },
      }));
  };
}


export function updatePromotion(id, data) {
  return req({
    api: REACT_APP_API_V2_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    uri: `ContentPromotion/Pricing/${id}`,
    method: 'PATCH',
    data,
  });
}


export function getPromotionList({ pageNumber, pageSize, searchText, isActive, sortBy, orderBy }) {
  return async (dispatch) => {
    dispatch({ type: GET_PROMOTION_LIST });
    await APIRequest({
      uri: `ContentPromotion/Pricings?pageSize=${pageSize}&pageNum=${pageNumber}&searchText=${searchText}&sortBy=${sortBy}&orderBy=${orderBy}&isActive=${isActive}`,
      method: 'GET',
      api: REACT_APP_API_V2_URL,

    })
      .then(({ Data }) => dispatch({
        type: GET_PROMOTION_LIST_SUCCESS,
        payload: { data: Data },
      }))
      .catch(error => dispatch({
        type: GET_PROMOTION_LIST_ERROR,
        payload: { error },
      }));
  };
}


export function createNewPromotion(data) {
  return req({
    api: REACT_APP_API_V2_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    uri: 'ContentPromotion/Pricing',
    method: 'POST',
    data,
  });
}

export function getPromotionStats({ id, pageNumber, pageSize, searchText, orderBy, sortBy }) {
  return async (dispatch) => {
    dispatch({ type: GET_PROMOTION_STAT });
    await APIRequest({
      uri:
        `ContentPromotion/Pricing/${id}/BusinessList?pagesize=${pageSize}&pageNum=${pageNumber}&BusinessName=${searchText}&orderBy=${orderBy}&sortBy=${sortBy}`,
      method: 'GET',
      api: REACT_APP_API_V2_URL,

    })
      .then(({ Data }) => dispatch({
        type: GET_PROMOTION_STAT_SUCCESS,
        payload: { data: Data },
      }))
      .catch(error => dispatch({
        type: GET_PROMOTION_STAT_ERROR,
        payload: { error },
      }));
  };
}
