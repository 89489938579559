import './index.sass';

import moment from 'moment';
import { func, node } from 'prop-types';
import React from 'react';

import defaultProfileLogo from '../../assets/images/default-profile-photo.png';
import VIDEO_TYPE from '../../types/video';
import { Col, Row } from '../Grid';
import LightBox from '../LightBox';

class VideoDetailsHeader extends React.Component {
  state = {
    featuredVideoModalStatus: false,
  };

  onHandleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { additionalText, onHandleSetFeaturedVideo, videoDetails } = this.props;

    const { featuredVideoModalStatus } = this.state;

    const user = videoDetails.PosterProfile || videoDetails.User;
    const avatar =
      (user && user.ProfilePhoto && user.ProfilePhoto.FullResolutionUrl) || defaultProfileLogo; //defaultBusinessLogo;

    return (
      <div className="VideoDetailsHeader">
        <Row cellSpacing={15} rowSpacing={10}>
          <Col lg={8} md={8} sm={12} xs={12}>
            {avatar && (
              <figure className="VideoDetailsHeader__Img">
                <img src={avatar} alt="" />
              </figure>
            )}

            <div className="VideoDetailsHeader__Info">
              <strong>{user ? user.DisplayName : ''}</strong>
              {(videoDetails.Posted || videoDetails.Created) && (
                <small>
                  {additionalText}
                  {videoDetails.IsDeleted ? (
                    <>
                      <i className="icon-basket VideoDetailsHeader__DeleteIcon" />
                      <span>{`Deleted ${moment(new Date(videoDetails.ExpirationDate)).format(
                        'LLL',
                      )}`}</span>
                    </>
                  ) : (
                    <span>
                      {moment(new Date(videoDetails.Posted || videoDetails.Created)).format('LLL')}
                    </span>
                  )}
                </small>
              )}
            </div>
          </Col>

          <Col
            lg={4}
            md={4}
            sm={12}
            xs={12}
            show={
              !!videoDetails.IsMostCreative ||
              !!videoDetails.IsFeatured ||
              !!onHandleSetFeaturedVideo
            }
          >
            {videoDetails.IsMostCreative && (
              <div className="VideoDetailsHeader__MostCreative">
                <span>most creative</span>
                <i className="icon-award" />
              </div>
            )}

            {videoDetails.IsFeatured && (
              <div className="VideoDetailsHeader__Featured">
                <span>featured</span>
                <i className="icon-ribbon" />
              </div>
            )}

            {!!onHandleSetFeaturedVideo && (
              <>
                <LightBox
                  className="VideoDetailsHeader__FeatureLightBox"
                  show={featuredVideoModalStatus}
                  width={750}
                  height={261}
                  onClose={() => this.onHandleChange('featuredVideoModalStatus', false)}
                >
                  <Row
                    rowSpacing={15}
                    cellSpacing={20}
                    className="VideoDetailsHeader__FeatureLightBox__Row"
                  >
                    <Col lg={6} md={6} sm={12} xs={12} className="relative">
                      <i className="icon-ribbon" />
                      <figure className="VideoDetailsHeader__FeatureLightBox__Figure">
                        <img
                          src={
                            videoDetails.PosterProfile && videoDetails.PosterProfile.ProfilePhoto
                              ? videoDetails.PosterProfile.ProfilePhoto.FullResolutionUrl
                              : ''
                          }
                          alt={videoDetails.PhotoDescription}
                        />
                        <figcaption>
                          <strong>currently featured</strong>
                          <br />
                          {videoDetails.PhotoDescription}
                        </figcaption>
                      </figure>
                    </Col>

                    <Col
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="VideoDetailsHeader__FeatureLightBox__Content"
                    >
                      <div className="full">
                        <h2 className="VideoDetailsHeader__FeatureLightBox__Title">
                          set featured video
                        </h2>
                        <p className="VideoDetailsHeader__FeatureLightBox__Description">
                          Are you sure you want to replace the current featured video?
                        </p>
                        <p className="VideoDetailsHeader__FeatureLightBox__Description-smaller">
                          instruction or detailed outcome for this action.
                        </p>
                      </div>

                      <ul className="VideoDetailsHeader__FeatureLightBox__Actions">
                        <li>
                          <button
                            type="button"
                            className="button small"
                            onClick={() => this.onHandleChange('featuredVideoModalStatus', false)}
                          >
                            no, cancel
                          </button>
                        </li>

                        <li>
                          <button
                            type="button"
                            className="button small orange-theme"
                            onClick={() => onHandleSetFeaturedVideo()}
                          >
                            yes, set
                          </button>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </LightBox>
              </>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

VideoDetailsHeader.propTypes = {
  additionalText: node,
  onHandleSetFeaturedVideo: func,
  videoDetails: VIDEO_TYPE.isRequired,
};

VideoDetailsHeader.defaultProps = {
  additionalText: '',
  onHandleSetFeaturedVideo: null,
};

export default VideoDetailsHeader;
