import './index.sass';

import { arrayOf, func, shape, string } from 'prop-types';
import React from 'react';
import Select from 'react-select';

import { COLORS } from '../../config/colors';
import CustomOption from './CustomOption';

const SocialAccountsSelect = ({ values, options, onChange }) => {
  const value = options.filter(item => values.includes(item.value));

  const handleChange = ({ newValue, ...actionMeta }) => {
    const selectedValues = newValue.map(item => item.value);

    onChange(selectedValues, actionMeta);
  };

  return (
    <Select
      className="full mb-20 select-item"
      placeholder="Add accounts"
      options={options}
      value={value}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      isSearchable={false}
      autoFocus={false}
      isClearable={false}
      isMulti
      // closeMenuOnSelect={false}
      onChange={handleChange}
      components={{ Option: CustomOption }}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#FFB30F',
        },
      })}
      styles={{
        placeholder: styles => ({
          ...styles,
          fontSize: '1.4rem',
          color: COLORS.gray,
        }),
      }}
    />
  );
};

SocialAccountsSelect.propTypes = {
  options: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
      icon: string.isRequired,
    }),
  ).isRequired,
  values: arrayOf(string),
  onChange: func.isRequired,
};

SocialAccountsSelect.defaultProps = {
  values: [],
};

export default SocialAccountsSelect;
