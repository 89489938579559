import { arrayOf, bool, number, shape, string } from 'prop-types';

const MEDIA_TYPE = shape({
  Description: string,
  DurationSeconds: number,
  FullResolutionUrl: string,
  Height: number,
  MediaType: number,
  Optimized: arrayOf(
    shape({
      BasePhotoId: number,
      LargeUrl: string,
      MediumUrl: string,
      Role: number,
      SmallUrl: string,
    }),
  ),
  PhotoId: number,
  ShowInSocialFeed: bool,
  VideoUrl: string,
  Width: number,
});

export default MEDIA_TYPE;
