import './index.sass';

import { includes } from 'lodash';
import { arrayOf, bool, node, number, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';

import HISTORY_TYPE from '../../types/history';
import LOCATION_TYPE from '../../types/location';
import ROUTER_MATCH_TYPE from '../../types/routerMatch';
import SELECT_OPTIONS_TYPE from '../../types/selectOptions';
import {
  applyFiltersFromLocation,
  getLocationQueryFromFilters,
} from './filtersLocationQuery.utils';
import FiltersModal, { createModalState, filtersAfterClosingModal } from './FiltersModal';
import FiltersOrderBy from './FiltersOrderBy';
import FiltersSearch from './FiltersSearch';
import addSearchTermToFilters from './FiltersSearch/utils';
import FiltersTagList, { filtersAfterTagsBeingRemoved, filtersForTagList } from './FiltersTagList';

function Filters(props) {
  const {
    activeFilterNames,
    content,
    filterDisabled,
    history,
    location,
    match,
    modalContent,
    modalHeight,
    modalWidth,
    sorterItems,
    userRoleOptions,
    usStateOptions,
  } = props;
  const DEFAULT_FILTERS = {
    orderBy: '',
    sortingType: '',
    search: '',

    businessName: [],
    businessSource: '',
    businessTypes: [],
    closingDateFrom: '',
    closingDateTo: '',
    creators: [],
    fromDate: '',
    gigStatus: [],
    hasGigs: false,
    location: '',
    onlyThirdPartyBusiness: false,
    prizeAmount: [],
    products: [],
    rating: null,
    ratings: [],
    reviewType: [],
    searchList: [],
    showDeactivatedBusiness: false,
    state: undefined,
    toDate: '',
    userRoles: [],
    users: [],
    verificationStatus: [],
    contentStatusIds: [],
  };

  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS });
  const [loading, setLoading] = useState(true);

  const tagListFilters = useMemo(() => {
    const res = filtersForTagList(filters, activeFilterNames);
    return res;
  }, [filters, activeFilterNames]);

  const isFilterModalButtonVisible = useMemo(
    () =>
      activeFilterNames.find((filter) => !['orderBy', 'search', 'sortingType'].includes(filter)),
    [activeFilterNames],
  );

  const locationQuery = useMemo(
    () => (loading ? null : getLocationQueryFromFilters(filters)),
    [filters, loading],
  );

  const [cachedLocationQuery, setCachedLocationQuery] = useState(locationQuery);

  useEffect(() => {
    setFilters(applyFiltersFromLocation(filters, { ...DEFAULT_FILTERS }, location));
    setLoading(false);
  }, [location.search]);

  useEffect(() => {
    if (cachedLocationQuery !== locationQuery) {
      if (cachedLocationQuery !== null) {
        history.push({ pathname: match.url, search: locationQuery });
      }
      setCachedLocationQuery(locationQuery);
    }
  }, [locationQuery]);

  function removeFilterTags(removedTags) {
    setFilters(filtersAfterTagsBeingRemoved(removedTags, filters, { ...DEFAULT_FILTERS }));
  }

  function updateOrderFilter(orderBy) {
    setFilters({ ...filters, orderBy: orderBy.value });
  }

  function updateSortingTypeFilter(sortingType) {
    setFilters({ ...filters, sortingType: sortingType.value });
  }

  function updateFilterWhenModalClose(filtersFromModal) {
    setFilters(
      filtersAfterClosingModal(
        filtersFromModal,
        filters,
        { ...DEFAULT_FILTERS },
        activeFilterNames,
      ),
    );
    setShowModal(false);
  }

  return loading ? null : (
    <div className="Filters">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="Filters__Container">
          {(includes(activeFilterNames, 'orderBy') ||
            includes(activeFilterNames, 'sortingType')) && (
            <div className="Filters__StartGroup">
              {includes(activeFilterNames, 'orderBy') && (
                <FiltersOrderBy
                  onChange={updateOrderFilter}
                  options={sorterItems}
                  value={filters.orderBy}
                />
              )}

              {includes(activeFilterNames, 'sortingType') && (
                <FiltersOrderBy
                  onChange={updateSortingTypeFilter}
                  options={sorterItems}
                  value={filters.sortingType}
                />
              )}
            </div>
          )}
          <div className="Filters__EndGroup">
            <div className="Filters__Search">
              {isFilterModalButtonVisible && (
                <button
                  className="Filters__SearchLabel"
                  disabled={filterDisabled}
                  onClick={() => setShowModal(true)}
                  type="button"
                >
                  Filter
                </button>
              )}
              {includes(activeFilterNames, 'search') && (
                <FiltersSearch
                  disabled={filterDisabled}
                  onChange={(searchText) => {
                    if (searchText) {
                      setFilters(addSearchTermToFilters(searchText, filters));
                    }
                  }}
                />
              )}
            </div>
            {content && <div className="Filters__Content">{content}</div>}
          </div>
        </div>
      </form>

      <FiltersTagList
        filters={tagListFilters}
        onRemoveTags={removeFilterTags}
        userRoleOptions={userRoleOptions}
        usStateOptions={usStateOptions}
      />

      <FiltersModal
        content={modalContent}
        disabled={filterDisabled}
        height={modalHeight}
        modalState={createModalState(filters, activeFilterNames)}
        onModalHandleClose={() => setShowModal(false)}
        onModalHandleSubmit={updateFilterWhenModalClose}
        showModal={showModal}
        userRoleOptions={userRoleOptions}
        usStateOptions={usStateOptions}
        width={modalWidth}
      />
    </div>
  );
}

Filters.propTypes = {
  activeFilterNames: arrayOf(string).isRequired,
  content: node,
  filterDisabled: bool,
  history: HISTORY_TYPE.isRequired,
  location: LOCATION_TYPE.isRequired,
  match: ROUTER_MATCH_TYPE({ url: string }).isRequired,
  modalContent: node,
  modalHeight: number,
  modalWidth: number,
  sorterItems: arrayOf(shape({ label: string, value: string })),
  userRoleOptions: SELECT_OPTIONS_TYPE,
  usStateOptions: SELECT_OPTIONS_TYPE,
};

Filters.defaultProps = {
  content: null,
  filterDisabled: false,
  modalContent: null,
  modalHeight: 680,
  modalWidth: 500,
  sorterItems: [],
  userRoleOptions: [],
  usStateOptions: [],
};

export default withRouter(Filters);
