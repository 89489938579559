import './index.sass';

import moment from 'moment';
import { number, shape, string } from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import commentIcon from '../../../assets/images/comment.svg';
import ClickableImage from '../../../components/ClickableImage';
import CustomSelect from '../../../components/CustomSelect';
import LightBox from '../../../components/LightBox';
import Loader from '../../../components/Loader';
import { POST_REVIEW_STATUSES } from '../../../config/gig-status';
import USER_ROLES from '../../../config/user-roles';
import { BUSINESS_DETAILS_TYPE } from '../../../types/business';
import MEDIA_TYPE from '../../../types/media';
import { APIRequest } from '../../../utils/api';

class FlaggedContentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flagOptions: [],
      selectedOption: 0,
      IsUserAllowedToEdit: false,
      isLoading: false,
      errorMessage: '',
      successMessage: '',
    };
    this.userRole = localStorage.getItem('frankiRole');
  }

  getFlagStatusBasedOnRoles = (CurrentStatusId) => {
    let options = POST_REVIEW_STATUSES;
    let targets = [];
    if (this.userRole === USER_ROLES.contentAdmin) {
      if (CurrentStatusId === 1) {
        targets = [2];
      } else if (CurrentStatusId == 2) {
        targets = [];
      }
    } else if (this.userRole === USER_ROLES.saleAdmin) {
      if ([1, 2, 3].includes(CurrentStatusId)) {
        targets = [4];
      } else if (CurrentStatusId === 4) {
        targets = [];
      }
    } else if (this.userRole == USER_ROLES.admin) {
      if ([1, 2, 3, 4, 5, 7].includes(CurrentStatusId)) {
        targets = [6];
      } else if (CurrentStatusId == 6) {
        targets = [];
      }
    }
    options = POST_REVIEW_STATUSES.filter((f) => targets.includes(f.value));
    return options;
  };

  componentDidMount() {
    const {
      item: { StatusId, IsUserAllowedToEdit },
    } = this.props;
    this.setState({
      flagOptions: this.getFlagStatusBasedOnRoles(StatusId),
      selectedOption: POST_REVIEW_STATUSES.filter((f) => f.value == StatusId)[0],
      IsUserAllowedToEdit,
    });
  }

  onChangeFlagStatus = async (val) => {
    this.setState({ isLoading: true });
    const {
      item: { ContentKey },
      getFlaggedContents,
    } = this.props;
    await APIRequest({
      uri: `Content/${ContentKey}/AdjustStatus`,
      method: 'POST',
      data: {
        NewStatusId: val,
      },
    })
      .then((res) => {
        getFlaggedContents();
        this.setState({ selectedOption: POST_REVIEW_STATUSES[val] }); // not need this line..?
      })
      .catch((error) => {
        this.setState({ errorMessage: error });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  renderFlagContent = () => {
    const {
      item: { IsUserAllowedToEdit },
    } = this.props;
    const { flagOptions, selectedOption, isLoading } = this.state;

    if (isLoading) return <Loader />;

    if (IsUserAllowedToEdit) {
      return (
        <CustomSelect
          disabled={flagOptions.length == 0}
          id="contentStatusIds"
          name="contentStatusIds"
          label="Flag Status"
          onChange={({ value }) => this.onChangeFlagStatus(value)}
          options={flagOptions}
          placeholder={selectedOption != null ? selectedOption.label : 'Review Status'}
          value={selectedOption}
          isSearchable={false}
        />
      );
    }
    return (
      <span className="GigSubmissions__Prizes__TotalPrize">
        {selectedOption != null ? selectedOption.label : 'Unknown'}
      </span>
    );
  };

  render() {
    const { item, CorporateGroup } = this.props;
    const { errorMessage, successMessage } = this.state;
    const flaggedPath = '/admin/flagged-content-details';
    return (
      <div className="FlaggedContentCard">
        <div className="FlaggedContentCard__Content">
          <div className="FlaggedContentCard__Left">
            <figure className="FlaggedContentCard__MainImg">
              {item.Image && item.Image.FullResolutionUrl && (
                <ClickableImage
                  state={{ IsUserAllowedToEdit: item.IsUserAllowedToEdit }}
                  alt={item.Image.Description}
                  pathname={`${flaggedPath}/${item.ItemId}/${item.ContentKey}`}
                  src={item.Image.FullResolutionUrl}
                />
              )}
            </figure>

            <div className="FlaggedContentCard__Business">
              {CorporateGroup.Logo && CorporateGroup.Logo.FullResolutionUrl && (
                <figure className="FlaggedContentCard__Avatar">
                  <img src={CorporateGroup.Logo.FullResolutionUrl} alt="avatar" />
                </figure>
              )}
              <div className="FlaggedContentCard__Business__Info">
                {
                  <div className="FlaggedContentCard__Business__Info__Tag">
                    {(CorporateGroup.BusinessType && CorporateGroup.BusinessType.Name) || 'Hotel'}
                  </div>
                }
                <div className="FlaggedContentCard__Business__Info__Title">
                  {CorporateGroup.DisplayName}
                </div>
              </div>
            </div>
          </div>

          <div className="FlaggedContentCard__Wrapper">
            <div className="form-group has-tooltip">{this.renderFlagContent()}</div>
            <div className="FlaggedContentCard__PostedBy">
              {item && item.ProfilePhoto && item.ProfilePhoto.FullResolutionUrl && (
                <figure className="FlaggedContentCard__Avatar">
                  <img src={item.ProfilePhoto.FullResolutionUrl} alt="avatar" />
                </figure>
              )}

              <div className="FlaggedContentCard__PostedBy__Info">
                {item.OwnerDisplayName && (
                  <span className="FlaggedContentCard__PostedBy__Info__User">
                    {item.OwnerDisplayName}
                  </span>
                )}

                {item.LastFlaggedOn && (
                  <span className="FlaggedContentCard__PostedBy__Info__Date">
                    {moment(new Date(item.LastFlaggedOn)).format('MMM D, YYYY')}
                  </span>
                )}
              </div>
            </div>

            {item.Text && <span className="FlaggedContentCard__Description">{item.Text}</span>}
          </div>
        </div>

        <div className="FlaggedContentCard__Footer">
          <ul>
            <li>
              <i className="icon-heart" />
              <span className="FlaggedContentCard__Footer__Item">{item.LikeCount || 0}</span>
            </li>

            <li>
              <img src={commentIcon} alt="comment" />
              <span className="FlaggedContentCard__Footer__Item">{item.CommentCount || 0}</span>
            </li>
          </ul>
        </div>
        <LightBox
          style={{
            width: 600,
            height: null,
            marginLeft: -250,
            marginTop: -200,
          }}
          onClose={() => this.setState({ errorMessage: '', successMessage: '' })}
          show={!!errorMessage || !!successMessage}
          title={errorMessage ? 'Error' : 'Success'}
        >
          <div className="AsyncContent__ErrorMessage">{errorMessage}</div>
          <div className="AsyncContent__SuccessMessage">{successMessage}</div>
        </LightBox>
      </div>
    );
  }
}

FlaggedContentCard.propTypes = {
  CorporateGroup: BUSINESS_DETAILS_TYPE.isRequired,
  item: shape({
    CommentCount: number,
    Image: MEDIA_TYPE,
    ItemId: number,
    LikeCount: number,
    OwnerDisplayName: string,
    Posted: string,
    ProfilePhoto: MEDIA_TYPE,
  }).isRequired,
};

export default withRouter(FlaggedContentCard);
