import './index.sass';

import React, { lazy, Suspense, useState } from 'react';
import { bool } from 'prop-types';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import MasterPage from '../../components/MasterPage';
import { Col, Row } from '../../components/Grid';
import Loader from '../../components/Loader';

const CampaignList = lazy(() => import('./CampaignList'));
const CampaignUserList = lazy(() => import('./CampaignUserList'));

const CreatorFund = (props) => {
  const { auth } = props;
  const [activeTab, setActiveTab] = useState(0);

  return (
    <MasterPage pageTitle="Franki Admin: Campaigns" auth={auth}>
      <div className="CampaignsWrapper w100p">
        <Tabs className="frankiCompactTabs mt-3 " onSelect={setActiveTab} selectedIndex={activeTab}>
          <TabList>
            <Tab>Current Campaign</Tab>
            <Tab>All Campaigns</Tab>
          </TabList>

          <TabPanel>
            <Row cellSpacing={25} rowSpacing={15}>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Suspense fallback={Loader}>
                  <CampaignUserList />
                </Suspense>
              </Col>
            </Row>
          </TabPanel>

          <TabPanel>
            <Row cellSpacing={25} rowSpacing={15}>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Suspense fallback={Loader}>
                  <CampaignList />
                </Suspense>
              </Col>
            </Row>
          </TabPanel>
        </Tabs>
      </div>
    </MasterPage>
  );
};

CreatorFund.propTypes = {
  auth: bool.isRequired,
};

CreatorFund.defaultProps = {};

export default CreatorFund;
