import './index.sass';

import { isEmpty } from 'lodash';
import { func, instanceOf, number } from 'prop-types';
import React from 'react';
import Currency from 'react-currency-formatter';
import { connect } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import uuidv1 from 'uuid/v1';

import {
  assignBusinessVideoToPrizes,
  getListOfNominatedVideos,
  updateNominatedVideos,
} from '../../../../../actions/businesses';
import NoNomineesIMG from '../../../../../assets/images/no-nominees.jpg';
import NoWinnersIMG from '../../../../../assets/images/nominees-no-winners.jpg';
import NomineesCard from '../../../../../components/Cards/Nominees';
import EmptyPage from '../../../../../components/EmptyPage';
import { Col, Row } from '../../../../../components/Grid';
import LightBox from '../../../../../components/LightBox';
import VideoList from '../VideoList';

const NOMINEE_STATUS = 1;
const WINNER_STATUS = 2;

class GigDetailsNominees extends React.Component {
  state = {
    awardModalOpened: false,
    draggedNominee: null,
    nomineeDetails: {},
    success: false,
    errorMessage: '',
  };

  baseState = this.state;

  componentDidMount() {
    this.getListOfNominatedVideos();
  }

  componentDidUpdate() {
    const { success } = this.state;

    if (success !== false) {
      this.getListOfNominatedVideos();
      this.resetSuccess();
    }
  }

  onHandleModalAward(value, obj) {
    this.setState((prevState) => ({
      awardModalOpened: value,
      nomineeDetails: obj || prevState.nomineeDetails,
    }));
  }

  async onHandleSubmitAward(e) {
    const {
      nomineeDetails: { GigPrizeId, NominatedVideoContentKey },
    } = this.state;
    e.preventDefault();
    const result = await assignBusinessVideoToPrizes(GigPrizeId, NominatedVideoContentKey);

    const { Message, Success } = result;
    if (Success) {
      this.setState(
        {
          awardModalOpened: false,
          success: true,
        },
        () => {
          const { onUpdate } = this.props;
          onUpdate();
        },
      );
    } else {
      this.setState({
        awardModalOpened: false,
        success: false,
      });
      this.setState({ errorMessage: Message });
    }
  }

  onHandleChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  onDrag(item) {
    const { draggedNominee } = this.state;

    if (!draggedNominee) {
      this.setState({
        draggedNominee: item,
        nomineeDetails: item,
      });
    }
  }

  onDragLeave() {
    this.setState({
      draggedNominee: null,
    });
  }

  onDrop() {
    const { draggedNominee } = this.state;

    if (draggedNominee) {
      this.onHandleModalAward(true, null);
    }
  }

  getListOfNominatedVideos() {
    const { gigId, getListOfNominatedVideos: getListOfNominatedVideosAction } = this.props;
    getListOfNominatedVideosAction(gigId);
  }

  resetSuccess() {
    this.setState({
      success: false,
    });
  }

  onHandleCloseDialog() {
    this.setState({ errorMessage: '' });
  }

  render() {
    const { gigPrizes, NominatedList, onUpdate } = this.props;
    const nomineesWinners =
      NominatedList &&
      NominatedList.filter(({ NominationStatus }) => NominationStatus === WINNER_STATUS);
    const awardList =
      NominatedList &&
      NominatedList.filter(({ NominationStatus }) => NominationStatus === NOMINEE_STATUS);

    const { awardModalOpened, draggedNominee, errorMessage } = this.state;

    return (
      <>
        <Tabs className="nomineesTabs">
          <TabList>
            {gigPrizes.map((item) => (
              <Tab key={uuidv1()}>
                <h4 className="tabList-items-title">
                  {item.Description}
                  <span className="nomineesTabs-tabs-prize">
                    <Currency quantity={item.PrizeAmount} />
                  </span>
                </h4>
                <span className="nomineesTabs-tabs-description">
                  {item.RemainingWinnerQty}/{item.WinnerQty} remaining
                </span>
              </Tab>
            ))}
          </TabList>

          {gigPrizes.map((gigPrize) => {
            const nomineeList =
              awardList && awardList.filter((item) => item.GigPrizeId === gigPrize.GigPrizeId);
            return (
              <TabPanel key={uuidv1()}>
                <section className="nomineesTabsPanel-blocks nomineesTabs-winners">
                  <h5 className="nomineesTabPanel-title">Winners</h5>
                  <div
                    className={`
                      nomineesTabs-winners-content
                      ${draggedNominee ? 'nomineesTabs-winners-content-drop' : ''}
                    `}
                    onDrop={() => this.onDrop()}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <Row
                      cellSpacing={10}
                      rowSpacing={10}
                      className="nomineesTabs-winners-content-row"
                    >
                      {!isEmpty(nomineesWinners) ? (
                        nomineesWinners.map((item) =>
                          item.GigPrizeId === gigPrize.GigPrizeId ? (
                            <Col
                              key={uuidv1()}
                              lg={2}
                              md={3}
                              sm={6}
                              xs={12}
                              className="nomineesTabs-winners-col"
                            >
                              <NomineesCard item={item} />
                            </Col>
                          ) : (
                            []
                          ),
                        )
                      ) : (
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <EmptyPage
                            description="pick up winners for this prize"
                            img={NoWinnersIMG}
                            size="Small"
                            title="No winners yet"
                          />
                        </Col>
                      )}
                    </Row>
                  </div>
                </section>

                <section className="nomineesTabsPanel-blocks">
                  <h5 className="nomineesTabPanel-title">Nominees - {nomineeList.length}</h5>
                  {!isEmpty(nomineeList) ? (
                    <VideoList
                      handleModalAward={(...params) => this.onHandleModalAward(...params)}
                      type="nominees"
                      videos={nomineeList}
                      onUpdate={onUpdate}
                    />
                  ) : (
                    <EmptyPage
                      description="collect your favorites here as you review your submissions.<br /> this will make it easier to select winners later."
                      img={NoNomineesIMG}
                      size="Big"
                      title="No nominees yet"
                    />
                  )}
                </section>

                <LightBox
                  className="nomineesLightBox"
                  style={{
                    width: 500,
                    height: null,
                    marginLeft: -250,
                    marginTop: -200,
                  }}
                  onClose={() => {
                    this.onHandleModalAward(false);
                  }}
                  show={awardModalOpened}
                  title="Award prizes"
                  width={500}
                >
                  <div className="nomineesLightBox-content">
                    <p className="nomineesLightBox-description">
                      Are you sure you want to award this video?
                      <br />
                      <small>Once a video is awarded there is no going back.</small>
                    </p>
                  </div>

                  <div className="nomineesLightBox-actions">
                    <button
                      type="button"
                      className="button small purple-theme"
                      onClick={() => this.onHandleModalAward(false)}
                    >
                      no, cancel
                    </button>

                    <button
                      type="button"
                      className="button small orange-theme"
                      onClick={(e) => this.onHandleSubmitAward(e)}
                    >
                      yes, award
                    </button>
                  </div>
                </LightBox>
                <LightBox
                  style={{
                    width: 600,
                    height: null,
                    marginLeft: -250,
                    marginTop: -200,
                  }}
                  onClose={() => this.onHandleCloseDialog()}
                  show={!!errorMessage}
                  title={'Error'}
                >
                  <div className="AsyncContent__ErrorMessage">{errorMessage}</div>
                </LightBox>
              </TabPanel>
            );
          })}
        </Tabs>
      </>
    );
  }
}

GigDetailsNominees.propTypes = {
  gigId: number.isRequired,
  gigPrizes: instanceOf(Array).isRequired,
  getListOfNominatedVideos: func.isRequired,
  NominatedList: instanceOf(Object).isRequired,
  onUpdate: func.isRequired,
};

const mapStateToProps = (state) => ({
  NominatedList: state.businesses.NominatedList,
});

export default connect(mapStateToProps, {
  getListOfNominatedVideos,
  updateNominatedVideos,
})(GigDetailsNominees);
