import './index.sass';

import { bool, node } from 'prop-types';
import React from 'react';

import checkSvg from '../../../../assets/images/check.svg';
import notVerifiedSvg from '../../../../assets/images/not-verified.svg';
import pendingPng from '../../../../assets/images/pending.png';
import { BUSINESS_PROFILE_TYPE } from '../../../../types/business';

export const VERIFICATION_STATUS = {
  Verified: { // exist in new
    text: 'Business Verified',
    icon: checkSvg,
  },
  Pending: { // exist in new
    text: 'Business Pending Verification',
    icon: pendingPng,
  },
  'Pending Verification': {
    text: 'Business Pending Verification',
    icon: pendingPng,
  },
  Unverified: {
    text: 'Business Not Verified',
    icon: notVerifiedSvg,
  },
  'Not Verified': {
    text: 'Business Not Verified',
    icon: notVerifiedSvg,
  },
  NotVerified: { // exist in new
    text: 'Business Not Verified',
    icon: notVerifiedSvg,
  },
  Rejected: { // exist in new
    text: 'Business Rejected',
    icon: notVerifiedSvg,
  },
};

export default function BusinessInfoAbout(props) {
  const {
    businessProfile: {
      Id,
      Type,
      Category,
      Comments,
      Name,
      FollowerCount,
      Likes,
      SaveCount,
      ShareCount,
      Description,
      VideoCount,
      VerificationStatus,
    },
    children,
    showDetail,
    showVerificationStatus,
  } = props;

  return (
    <div className="BusinessInfoAbout">
      <div className="BusinessInfoAbout__ContentContainer">
        {Type && (
        <h2 className="BusinessInfoAbout__BusinessType">
          {Type.DisplayName || Type.DisplayString || Type.Name}
          {Category && ` - ${Category.DisplayName || Category.DisplayString || Category}`}
        </h2>
        )}

        <h1 className="BusinessInfoAbout__DisplayName">{Name}</h1>

        <div className="BusinessInfoAbout__ID">{Id}</div>

        <div className="BusinessInfoAbout__Status">
          {showVerificationStatus && VerificationStatus ? (
            <>
              <img src={VERIFICATION_STATUS[VerificationStatus].icon} alt="verified" />

              <div className="BusinessInfoAbout__Status__Text">
                {VERIFICATION_STATUS[VerificationStatus].text}
              </div>
            </>
          ) : null}
        </div>
      </div>

      <ul className="BusinessInfoAbout__Metrics">
        {VideoCount !== undefined && (
          <li>
            <span className="BusinessInfoAbout__Metrics__Count">{VideoCount || 0}</span>
            <span className="BusinessInfoAbout__Metrics__Text">Reviews</span>
          </li>
        )}

        {Likes !== undefined && (
          <li>
            <span className="BusinessInfoAbout__Metrics__Count">
              {(Likes && Likes.length) || 0}
            </span>
            <span className="BusinessInfoAbout__Metrics__Text">Likes</span>
          </li>
        )}

        {Comments !== undefined && (
          <li>
            <span className="BusinessInfoAbout__Metrics__Count">
              {(Comments && Comments.length) || 0}
            </span>
            <span className="BusinessInfoAbout__Metrics__Text">Comments</span>
          </li>
        )}

        {FollowerCount !== undefined && (
          <li>
            <span className="BusinessInfoAbout__Metrics__Count">{FollowerCount || 0}</span>
            <span className="BusinessInfoAbout__Metrics__Text">Followers</span>
          </li>
        )}

        {ShareCount !== undefined && (
          <li>
            <span className="BusinessInfoAbout__Metrics__Count">{ShareCount || 0}</span>
            <span className="BusinessInfoAbout__Metrics__Text">Shares</span>
          </li>
        )}

        {SaveCount !== undefined && (
          <li>
            <span className="BusinessInfoAbout__Metrics__Count">{SaveCount || 0}</span>
            <span className="BusinessInfoAbout__Metrics__Text">Saved</span>
          </li>
        )}
      </ul>

      {children}

      {showDetail ? (
        <div className="BusinessInfoAbout__Description">
          <div className="BusinessInfoAbout__Description__Title">Description</div>

          {Description && <div className="BusinessInfoAbout__Description__Content">{Description}</div>}
        </div>
      ) : null}
    </div>
  );
}

BusinessInfoAbout.propTypes = {
  businessProfile: BUSINESS_PROFILE_TYPE.isRequired,
  children: node,
  showDetail: bool,
  showVerificationStatus: bool,
};

BusinessInfoAbout.defaultProps = {
  children: null,
  showDetail: true,
  showVerificationStatus: false,
};
