import './Membership.sass';

import { number } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import MediaQuery from 'react-responsive';

import Header from '../../components/Header';
import Plans from '../../components/Plans';
import RegisterFooter from '../../components/RegisterFooter';
import StepsHeader from '../../components/StepsHeader';
import { cannabisPlans } from './PlanAndAccount/SecondStep/plans.json';
import ResponsivePlans from './PlanAndAccount/SecondStep/responsive';

const Membership = (props) => {
  const { activeIndex } = props;

  return (
    <>
      <Helmet title="Franki Admin: membership" />

      <Header auth={false} />

      <div className="Membership">
        <StepsHeader
          title="select a membership."
          subtitle="Take advantage of our introductory discounts, its a great deal!"
        />

        <MediaQuery query="(max-width: 812px)">
          <ResponsivePlans
            plans={cannabisPlans}
            onHandleSelectPlan={() => {}}
            onHandleMousePlanHover={() => {}}
          />
        </MediaQuery>

        <MediaQuery query="(min-width: 813px)">
          <Plans
            activeIndex={activeIndex}
            features={[
              { title: 'profile management' },
              { title: 'post creation' },
              { title: 'taggable products' },
              { title: 'downloadable videos' },
              { title: 'promotion creation' },
              { title: 'gigs' },
              { title: 'gig winners' },
              { title: 'gig credit' },
              { title: 'gig service fee' },
            ]}
            isLarge
            plans={cannabisPlans}
          />
        </MediaQuery>
      </div>
      <RegisterFooter />
    </>
  );
};

Membership.propTypes = {
  activeIndex: number,
};

Membership.defaultProps = {
  activeIndex: 0,
};

export default Membership;
