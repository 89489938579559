import './index.sass';

import { includes } from 'lodash';
import { bool, instanceOf } from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

const ENTER_KEYCODE = 13;
const isEnterKeyPressed = (keyboardEvent) =>
  includes([keyboardEvent.keyCode, keyboardEvent.charCode], ENTER_KEYCODE);

class FiltersSearch extends React.Component {
  state = { value: '' };

  onHandleKeyDown(keyboardEvent) {
    if (isEnterKeyPressed(keyboardEvent)) {
      setTimeout(() => this.resetAndEmitChange());
    }
  }

  resetAndEmitChange() {
    const { value } = this.state;
    const { onChange } = this.props;
    this.setState({ value: '' }, () => onChange(value));
  }

  render() {
    const { value } = this.state;
    const { disabled } = this.props;
    return (
      <>
        <input
          id="filterSearch"
          className="FiltersSearch"
          disabled={disabled}
          onChange={(event) => this.setState({ value: event.target.value })}
          onKeyDown={(keyboardEvent) => this.onHandleKeyDown(keyboardEvent)}
          placeholder="Search"
          type="text"
          value={value}
        />
        <button
          className="Filters__ShowModalButton button black-theme small margin-left"
          type="submit"
          disabled={disabled}
          onClick={() => this.resetAndEmitChange()}
        >
          Search
        </button>
      </>
    );
  }
}

FiltersSearch.propTypes = {
  disabled: bool,
  onChange: instanceOf(Function).isRequired,
};

FiltersSearch.defaultProps = {
  disabled: false,
};

export default withRouter(FiltersSearch);
