import { instanceOf } from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../config/pagination';
import { isAdminOrSaleAdmin } from '../../utils/isAdmin';

/* global localStorage */
const defaultAdminRoute = `/admin/businesses/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`;

const defaultBusinessId = localStorage.getItem('defaultBusinessId');
const defaultBusinessManagerRoute = defaultBusinessId
  ? `admin/business-profile/${defaultBusinessId}`
  : 'admin/register-business';

const defaultRoute = isAdminOrSaleAdmin() ? defaultAdminRoute : defaultBusinessManagerRoute;

const NonPrivateRoute = ({ component: Component, permission, routerProps, ...rest }) => {
  const { path } = rest;
  if (path == '/reset-password') {
    var url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    if (localStorage.getItem('frankiToken') !== token) {
      localStorage.removeItem('frankiToken');
    }
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('frankiToken') ? (
          <Redirect to={defaultRoute} />
        ) : (
          <Component auth={false} routerProps={routerProps} {...props} />
        )
      }
    />
  );
};

NonPrivateRoute.propTypes = {
  permission: instanceOf(Array),
  component: instanceOf(Object).isRequired,
  routerProps: instanceOf(Object),
};

NonPrivateRoute.defaultProps = {
  routerProps: {},
  permission: [],
};

export default NonPrivateRoute;
