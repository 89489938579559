import './index.sass';

import { arrayOf, bool, func, number, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import uuidv1 from 'uuid/v1';

import {
  getBusinessDetails as getBusinessDetailsAction,
  getBusinessGigs as getBusinessGigsAction,
  verifyPermission,
} from '../../../../actions/businesses';
import EmptyGigIMG from '../../../../assets/images/empty_gig.jpg';
import AsyncContent from '../../../../components/AsyncContent';
import EmptyPage from '../../../../components/EmptyPage';
import Filters from '../../../../components/Filters';
import { Col, Row } from '../../../../components/Grid';
import LightBox from '../../../../components/LightBox';
import MasterPage from '../../../../components/MasterPage';
import Paginator from '../../../../components/Paginator';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../../config/pagination';
import USER_ROLES from '../../../../config/user-roles';
import { BUSINESS_DETAILS_TYPE } from '../../../../types/business';
import BUSINESS_GIG_TYPE from '../../../../types/businessGig';
import HISTORY_TYPE from '../../../../types/history';
import LOCATION_TYPE from '../../../../types/location';
import ROUTER_MATCH_TYPE from '../../../../types/routerMatch';
import BusinessGigCreate from '../BusinessGigCreate';
import BusinessGigCard from './BusinessGigCard';

class BusinessesGigs extends React.Component {
  state = {
    isCreateGigOpen: false,
    createGigError: '',
    ctaContent: (
      <>
        <div className="BusinessGigs__EmptyPage__CTATitle">create gig</div>

        <div className="BusinessGigs__EmptyPage__CTAContent">
          create a gig and make you loved
          <br />
          and known by your community
        </div>

        <button
          className="BusinessGigs__EmptyPage__CTAButton"
          onClick={() => this.setState({ ctaContent: null })}
          type="button"
        >
          GOT IT!
        </button>
      </>
    ),
    verified: null,
    userRole: localStorage.getItem('frankiRole'),
    errorMessage: '',
  };

  componentDidMount() {
    const {
      getBusinessDetails,
      history,
      location: { search },
      match: {
        params: { corporateGroupId, pageNumber, itemsCountPerPage },
      },
    } = this.props;

    verifyPermission(corporateGroupId, history, false).subscribe((verified) => {
      this.setState({ verified });
    });

    getBusinessDetails(corporateGroupId);

    if (!pageNumber || !itemsCountPerPage) {
      this.updatePagination(
        Number(itemsCountPerPage) || DEFAULT_PAGE_SIZE,
        Number(pageNumber) || DEFAULT_PAGE_INDEX,
      );
    } else {
      this.getGigs(search);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search },
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;

    if (prevProps.location.search !== search) {
      if (Number(pageNumber) !== DEFAULT_PAGE_INDEX) {
        this.updatePagination(Number(itemsCountPerPage), DEFAULT_PAGE_INDEX);
      }
      this.getGigs(search);
    }
  }

  async getGigs(search) {
    const {
      getBusinessGigs,
      match: {
        params: { corporateGroupId },
      },
    } = this.props;

    await getBusinessGigs(corporateGroupId, search);
  }

  createGigError() {
    const { history } = this.props;
    let errorMessage = 'You cannot create a gig for this business until it is converted to a local Franki business.';
    let showButtons = false;
    //
    const { VerificationStatus, CorporateGroupId, BusinessSourceId } = this.props.businessDetails;
    const userRole = localStorage.getItem('frankiRole');
    if (userRole == USER_ROLES.businessManager) {
      errorMessage += ' Please contact customersupport@befranki.com for any queries.';
      if (VerificationStatus != 1) {
        errorMessage = errorMessage.replace('it is', 'it is verified and ');
        showButtons = true;
      }
      if (BusinessSourceId == 1) {
        errorMessage = 'Your account needs to be verified for Gig Creation/Edit Profile. Please contact customersupport@befranki.com for any queries.';
      }
    }

    const result = (
      <>
        {errorMessage}
        {showButtons && (
          <div className="BusinessVideos__ButtonContainer VerifyButtonContainer">
            <button
              onClick={() => this.setState({ createGigError: '' })}
              className="button small"
              type="button"
            >
              Close
            </button>

            <button
              onClick={() => {
                history.push(`/business-phone-verification/${CorporateGroupId}`);
              }}
              type="button"
              className="button orange-theme small"
            >
              Verify Business
            </button>
          </div>
        )}
      </>
    );
    this.setState({
      createGigError: result,
    });
  }

  updatePagination(itemsCountPerPage, pageNumber) {
    const {
      history,
      location: { search },
      match: {
        params: { corporateGroupId },
      },
    } = this.props;

    history.push({
      pathname: `/admin/business-gigs/${corporateGroupId}/${itemsCountPerPage}/${pageNumber}`,
      search,
    });
  }

  openCreateGigModal() {
    const { VerificationStatus, BusinessSourceId } = this.props.businessDetails;

    if ([USER_ROLES.admin, USER_ROLES.saleAdmin].includes(this.state.userRole)) {
      if (BusinessSourceId == 1) {
        this.setState({ isCreateGigOpen: true });
      } else {
        this.createGigError();
      }
    } else if (VerificationStatus == 1 && BusinessSourceId == 1) {
      this.setState({ isCreateGigOpen: true });
    } else {
      this.createGigError();
    }
  }

  closeCreateGigModal() {
    this.setState({ isCreateGigOpen: false });
  }

  render() {
    const {
      auth,
      businessDetails,
      businessGigs,
      businessGigsError,
      isFetchingBusinessDetails,
      isFetchingBusinessGigs,
      history,
      match: {
        params: { corporateGroupId, itemsCountPerPage, pageNumber },
      },
    } = this.props;

    const { isCreateGigOpen, createGigError, ctaContent, verified, errorMessage } = this.state;

    const createGigAction = {
      buttonCallback: () => {
        this.openCreateGigModal();
      },
    };

    const resultLimit = [(pageNumber - 1) * itemsCountPerPage, pageNumber * itemsCountPerPage];
    const hideCreateGigModal = businessGigsError == 'The requester does not have right to the given business.';
    return (
      <>
        <Helmet title="Franki Admin: all gigs" />

        {!isFetchingBusinessDetails && (
          <MasterPage
            auth={auth}
            businessSidebarOn
            businessSidebarData={businessDetails}
            businessMobileTitle="Gigs"
            flex
          >
            <Filters
              activeFilterNames={[
                'closingDateFrom',
                'closingDateTo',
                'fromDate',
                'gigStatus',
                'prizeAmount',
                'search',
                'toDate',
              ]}
              content={
                !hideCreateGigModal && (
                  <button
                    type="button"
                    className="button small yellow-theme"
                    onClick={() => {
                      this.openCreateGigModal();
                    }}
                  >
                    Create
                  </button>
                )
              }
              modalHeight={690}
            />

            <AsyncContent
              content={list => (
                <Row cellSpacing={10} rowSpacing={10}>
                  {list.slice(...resultLimit).map(item => (
                    <Col lg={3} md={4} sm={6} xs={12} key={uuidv1()}>
                      <BusinessGigCard item={item} accessible={verified} />
                    </Col>
                  ))}
                </Row>
              )}
              emptyPageContent={(
                <EmptyPage
                  buttonText="create gig"
                  ctaContent={ctaContent}
                  description="Let's create your first gig!"
                  img={EmptyGigIMG}
                  title="Get the word out!"
                  {...createGigAction}
                />
              )}
              errorMessage={businessGigsError || createGigError}
              expectedData={businessGigs}
              loading={isFetchingBusinessGigs}
              onHandleCloseDialog={() => this.setState({ createGigError: '' })}
            />

            <Paginator
              onHandlePage={(...params) => this.updatePagination(...params)}
              onHandleShow={(...params) => this.updatePagination(...params)}
              pageIndex={Number(pageNumber)}
              totalItems={businessGigs.length}
              itemsCountPerPage={Number(itemsCountPerPage)}
            />
          </MasterPage>
        )}
        <LightBox
          className="BusinessGigs__CreateGigLightBox"
          show={isCreateGigOpen}
          spacing={0}
          width={1318}
          height={715}
          style={{
            marginLeft: -650,
          }}
          onClose={() => {
            this.closeCreateGigModal();
          }}
        >
          <BusinessGigCreate {...this.props} />
        </LightBox>

        <LightBox
          style={{
            width: 500,
            height: null,
            marginLeft: -250,
            marginTop: -250,
          }}
          onClose={() => this.setState({ errorMessage: '' })}
          show={errorMessage != ''}
          title="Error"
        >
          <>
            <div className="GigSubmissions__AwardConfirm">
              <div className="AsyncContent__ErrorMessage">{errorMessage}</div>
              <div className="GigSubmissions__AwardConfirm__Actions">
                <button
                  onClick={() => this.setState({ errorMessage: '' })}
                  className="button small"
                  type="button"
                >
                  Close
                </button>

                <button
                  onClick={() => {
                    history.push(`/business-phone-verification/${corporateGroupId}`);
                  }}
                  type="button"
                  className="button orange-theme small"
                >
                  Verify Business
                </button>
              </div>
            </div>
          </>
        </LightBox>
      </>
    );
  }
}

BusinessesGigs.propTypes = {
  auth: bool.isRequired,
  businessDetails: BUSINESS_DETAILS_TYPE.isRequired,
  businessGigs: arrayOf(BUSINESS_GIG_TYPE).isRequired,
  businessGigsError: string.isRequired,
  getBusinessDetails: func.isRequired,
  getBusinessGigs: func.isRequired,
  history: HISTORY_TYPE.isRequired,
  isFetchingBusinessDetails: bool.isRequired,
  isFetchingBusinessGigs: bool.isRequired,
  location: LOCATION_TYPE.isRequired,
  match: ROUTER_MATCH_TYPE({
    corporateGroupId: string.isRequired,
    pageNumber: string,
    itemsCountPerPage: string,
  }).isRequired,
  totalItems: number,
};

BusinessesGigs.defaultProps = {
  totalItems: null,
};

const mapStateToProps = (state) => {
  const {
    businessDetails,
    businessGigs,
    businessGigsError,
    isFetchingBusinessDetails,
    isFetchingBusinessGigs,
  } = state.businesses;

  return {
    businessDetails,
    businessGigs,
    businessGigsError,
    isFetchingBusinessDetails,
    isFetchingBusinessGigs,
    totalItems: businessGigs ? businessGigs.TotalItems : 0,
  };
};

export default connect(mapStateToProps, {
  getBusinessDetails: getBusinessDetailsAction,
  getBusinessGigs: getBusinessGigsAction,
})(BusinessesGigs);
