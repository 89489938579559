import { arrayOf, bool, number, shape, string } from 'prop-types';

import { GIG_PRIZE_TYPE } from './gig';
import MEDIA_TYPE from './media';

const GIG_RULE = shape({
  GigRuleId: number,
  GigId: number,
  Description: string,
  DisplayOrder: number,
});

const BUSINESS_GIG_TYPE = shape({
  CorporateGroupId: number,
  Description: string,
  EndDate: string,
  GigId: number,
  GigPrizes: arrayOf(GIG_PRIZE_TYPE),
  GigRules: arrayOf(GIG_RULE),
  IsSaved: bool,
  Name: string,
  Photo: MEDIA_TYPE,
  PhotoId: number,
  PrizesAwarded: number,
  StartDate: string,
  StatusId: number,
  TotalWinnerQty: number,
  UserGigsEarning: string,
  VideosSubmitted: number,
});

export default BUSINESS_GIG_TYPE;
