import './index.sass';

import { arrayOf, func, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { compose } from 'redux';

import {
  initiateVerificationCall,
  verificationPhoneNumber,
  verify,
} from '../../actions/businesses';
import { back as backAction } from '../../actions/path';
import orangeCircleCheck from '../../assets/images/orange_circle_check.svg';
import redCircleTimes from '../../assets/images/red_circle_times.svg';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import LightBox from '../../components/LightBox';
import Loader from '../../components/Loader';
import HISTORY_TYPE from '../../types/history';
import BusinessVerificationForm from './BusinessVerificationForm';
import ProgressBar from '../../components/ProgressBar';

function BusinessPhoneVerification(props) {
  const { paths, back, history } = props;

  const { state = {} } = history.location;
  const {
    nonFourSquare,
    profileCreated,
    businessId: businessIdState,
    // thirdPartySourceId,
    hideStep,
  } = state;
  const { businessId: businessIdParam } = useParams();
  const businessId = businessIdState || businessIdParam;

  const inputEl0 = useRef(null);
  const inputEl1 = useRef(null);
  const inputEl2 = useRef(null);
  const inputEl3 = useRef(null);
  const inputEl4 = useRef(null);
  const inputEl5 = useRef(null);

  const [loading, setLoading] = useState(false);
  const [isVerifyingPhoneNumber, setIsVerifyingPhoneNumber] = useState(false);
  const [verifyBusinessPhoneNumberSuccess, setVerifyBusinessPhoneNumberSuccess] = useState(null);
  const [verifyBusinessPhoneNumberError, setVerifyBusinessPhoneNumberError] = useState(null);

  const [verifyOption, setVerifyOption] = useState(0);

  const [isVerifyingIdentifier, setIsVerifyingIdentifier] = useState(false);
  const [verifyIdentifierSuccess, setVerifyIdentifierSuccess] = useState(null);

  const [isInitiatingVerificationCall, setIsInitiatingVerificationCall] = useState(false);
  const [hidePhoneSection, setHidePhoneSection] = useState(false);
  const [initiateVerificationCallSuccess, setInitiateVerificationCallSuccess] = useState(null);

  const [businessPhoneNumber, setBusinessPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [verifyingIdentifierErrorMessage, setVerifyingIdentifierErrorMessage] = useState(null);

  const [verificationInput0, setVerificationInput0] = useState(null);
  const [verificationInput1, setVerificationInput1] = useState(null);
  const [verificationInput2, setVerificationInput2] = useState(null);
  const [verificationInput3, setVerificationInput3] = useState(null);
  const [verificationInput4, setVerificationInput4] = useState(null);
  const [verificationInput5, setVerificationInput5] = useState(null);

  const getBusinessPhoneNumber = () => {
    setLoading(true);
    verificationPhoneNumber(businessId).subscribe(
      (response) => {
        setBusinessPhoneNumber(response.PhoneNumber);
        setHidePhoneSection(false);
      },
      (err) => {
        if (
          err ===
          'We can’t find the registered phone number. Please verify your business without a phone number.'
        ) {
          setVerifyOption(1);
          setHidePhoneSection(true);
          setLoading(false);
        }
        setErrorMessage(err);
        setIsVerifyingPhoneNumber(false);
      },
      () => {
        setIsVerifyingPhoneNumber(false);
        setLoading(false);
      },
    );
  };

  const initiateCall = useCallback(() => {
    setIsInitiatingVerificationCall(true);
    initiateVerificationCall(businessId).subscribe(
      () => setInitiateVerificationCallSuccess(true),
      (err) => {
        setVerifyBusinessPhoneNumberError(err);
        setIsInitiatingVerificationCall(false);
      },
      () => setIsInitiatingVerificationCall(false),
    );
  }, [
    businessId,
    initiateVerificationCall,
    setInitiateVerificationCallSuccess,
    setIsInitiatingVerificationCall,
  ]);

  const onHandleCallNow = useCallback(
    (e) => {
      e.preventDefault();
      setErrorMessage(null);
      setVerifyingIdentifierErrorMessage(null);
      initiateCall();
    },
    [
      businessId,
      setErrorMessage,
      setIsVerifyingPhoneNumber,
      setVerifyBusinessPhoneNumberSuccess,
      setVerifyingIdentifierErrorMessage,
      verificationPhoneNumber,
    ],
  );

  const onHandleCallAgain = useCallback(
    (e) => {
      e.preventDefault();
      initiateCall();
    },
    [initiateCall],
  );

  const backButton = useMemo(
    () => (
      <button
        onClick={
          paths.length && paths[paths.length - 1].includes('mobile-code-verification')
            ? back
            : () => history.push('/claim-my-business')
        }
        className="button BusinessPhoneVerification__Back"
        type="button"
      >
        <span>BACK</span>
        <i className="icon-chevron-left" />
      </button>
    ),
    [paths, back],
  );

  const error = useMemo(() => {
    const message = errorMessage || verifyingIdentifierErrorMessage;
    return !initiateVerificationCallSuccess && message ? (
      <div className="EmailCodeVerification__Message error">{message}</div>
    ) : null;
  }, [initiateVerificationCallSuccess, errorMessage, verifyingIdentifierErrorMessage]);

  useEffect(() => {
    setErrorMessage(null);
    setVerifyingIdentifierErrorMessage(null);

    if (
      [
        verificationInput0,
        verificationInput1,
        verificationInput2,
        verificationInput3,
        verificationInput4,
        verificationInput5,
      ].join('').length === 6
    ) {
      setIsVerifyingIdentifier(true);

      verify(
        businessId,
        [
          verificationInput0,
          verificationInput1,
          verificationInput2,
          verificationInput3,
          verificationInput4,
          verificationInput5,
        ].join(''),
      ).subscribe(
        () => setVerifyIdentifierSuccess(true),
        (err) => {
          setVerifyingIdentifierErrorMessage(err);
          setIsVerifyingIdentifier(false);
        },
        () => setIsVerifyingIdentifier(false),
      );
    } else {
      if (!verificationInput0 && inputEl0.current) {
        inputEl0.current.focus();
        return;
      }
      if (!verificationInput1 && inputEl1.current) {
        inputEl1.current.focus();
        return;
      }
      if (!verificationInput2 && inputEl2.current) {
        inputEl2.current.focus();
        return;
      }
      if (!verificationInput3 && inputEl3.current) {
        inputEl3.current.focus();
        return;
      }
      if (!verificationInput4 && inputEl4.current) {
        inputEl4.current.focus();
        return;
      }
      if (inputEl5.current) {
        inputEl5.current.focus();
      }
    }
  }, [
    setErrorMessage,
    setVerifyingIdentifierErrorMessage,
    setIsVerifyingIdentifier,
    verify,
    businessId,
    setVerifyIdentifierSuccess,
    inputEl0.current,
    inputEl1.current,
    inputEl2.current,
    inputEl3.current,
    inputEl4.current,
    inputEl5.current,
    verificationInput0,
    verificationInput1,
    verificationInput2,
    verificationInput3,
    verificationInput4,
    verificationInput5,
  ]);

  useEffect(() => {
    if (verifyIdentifierSuccess) {
      history.push(`/admin/business-profile/${businessId}?displayWelcomeScreen=verified`);
    }
  }, [verifyIdentifierSuccess]);

  useEffect(() => {
    if (nonFourSquare) {
      setVerifyOption(1);
    } else {
      getBusinessPhoneNumber();
    }
  }, [nonFourSquare]);

  return (
    <div className="BusinessPhoneVerification">
      <Header auth={false} global />

      <main className="BusinessPhoneVerification__Main">
        {!hideStep && (
          <ProgressBar
            className="BusinessPhoneVerification__ProgressBar"
            totalSteps={profileCreated ? 5 : 4}
            activeStep={profileCreated ? 5 : 4}
          />
        )}
        {/* <div className="BusinessPhoneVerification__Stepper">
          <div className="BusinessPhoneVerification__Stepper__Step BusinessPhoneVerification__Stepper__Step--Completed">1</div>
          <div className="BusinessPhoneVerification__Stepper__StepConnector BusinessPhoneVerification__Stepper__StepConnector--Completed" />
          <div className="BusinessPhoneVerification__Stepper__Step BusinessPhoneVerification__Stepper__Step--Completed">2</div>
          <div className="BusinessPhoneVerification__Stepper__StepConnector BusinessPhoneVerification__Stepper__StepConnector--Completed" />
          <div className="BusinessPhoneVerification__Stepper__Step BusinessPhoneVerification__Stepper__Step--Active">3</div>
        </div> */}

        <h1 className="BusinessPhoneVerification__Title">
          Lastly, lets verify your relationship to the business
        </h1>
        <p className="BusinessPhoneVerification__Subtitle">
          To ensure no one manages a business they aren’t associated with we need to verify your
          relationship to the business.
          {verifyOption === 0 && (
            <>
              <br />
              We will call your business number and give you a code to input into franki.
              <br />
              Select how you’d like to get verifed.
            </>
          )}
          {verifyOption === 1 && (
            <>
              <br />
              Once you have uploaded your Government ID, someone from the franki team will reach out
              to verify your relationship to the business.
              <br />
              Note: all documentation is stored via a secure third-party verification partner.
            </>
          )}
        </p>

        <div className="BusinessPhoneVerification__VerifyLater mb-5">
          Or&nbsp;
          <u>
            <Link
              style={{ color: '#767676' }}
              to={`/admin/business-profile/${businessId}?displayWelcomeScreen=notVerified`}
            >
              skip and verify later
            </Link>
          </u>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="BusinessPhoneVerification__RadioGroupContainer">
            {!hidePhoneSection && !nonFourSquare && (
              <div className="BusinessPhoneVerification__RadioGroup">
                <button
                  className={`BusinessPhoneVerification__Radio ${
                    verifyOption === 0 && 'BusinessPhoneVerification__Radio--Active'
                  }`}
                  onClick={() => setVerifyOption(0)}
                  type="button"
                >
                  Verify with registered business phone number
                </button>
                <button
                  className={`BusinessPhoneVerification__Radio ${
                    verifyOption === 1 && 'BusinessPhoneVerification__Radio--Active'
                  }`}
                  onClick={() => setVerifyOption(1)}
                  type="button"
                >
                  Verify without a phone number
                  <div className="BusinessPhoneVerification__Radio__SubTitle">
                    If you aren’t at your business currently, you can upload documentation to verify
                    your identity.
                  </div>
                </button>
              </div>
            )}
            {backButton}
          </div>
        )}

        {verifyOption === 0 ? (
          <>
            <div className="BusinessPhoneVerification__Message">
              {!verifyBusinessPhoneNumberSuccess ? (
                <>
                  You will receive an automated call in the next 1 minute to your registered
                  business phone number ({businessPhoneNumber}
                  ).
                </>
              ) : (
                businessPhoneNumber && (
                  <>
                    Business Phone Number:
                    {businessPhoneNumber}
                  </>
                )
              )}
              {error}
            </div>

            <button
              onClick={onHandleCallNow}
              className="button orange BusinessPhoneVerification__CallNow"
              disabled={isVerifyingPhoneNumber || isInitiatingVerificationCall}
              type="button"
            >
              {isVerifyingPhoneNumber || isInitiatingVerificationCall ? 'loading...' : 'CALL NOW'}
            </button>
          </>
        ) : (
          <>
            {!hidePhoneSection && !nonFourSquare && (
              <div className="BusinessPhoneVerification__Message">
                Providing this information will help keep the Franki community safe as well as
                comply with government regulations.
              </div>
            )}

            <BusinessVerificationForm
              businessId={businessId}
              onFinishSubmitting={() =>
                history.push(`/admin/business-profile/${businessId}?displayWelcomeScreen=pending`)
              }
            />
          </>
        )}
      </main>

      <Footer />

      <LightBox
        className="BusinessPhoneVerificationLightBox"
        style={{
          width: 500,
          height: null,
          marginLeft: -250,
          marginTop: -200,
        }}
        show={initiateVerificationCallSuccess}
        onClose={() => {
          setVerifyBusinessPhoneNumberSuccess(false);
          setInitiateVerificationCallSuccess(false);
        }}
      >
        <form>
          <fieldset>
            <img
              src={verifyingIdentifierErrorMessage ? redCircleTimes : orangeCircleCheck}
              alt="checked"
            />
            <div className="BusinessPhoneVerificationLightBox__InputTitle">
              Verification Code
              <span>*</span>
            </div>

            <div className="BusinessPhoneVerificationLightBox__Inputs">
              <input
                className={
                  verifyingIdentifierErrorMessage &&
                  'BusinessPhoneVerificationLightBox__Input--Error'
                }
                onChange={({ target: { value } }) => setVerificationInput0(value)}
                maxLength="1"
                ref={inputEl0}
                type="text"
                value={verificationInput0 || ''}
              />

              <input
                className={
                  verifyingIdentifierErrorMessage &&
                  'BusinessPhoneVerificationLightBox__Input--Error'
                }
                onChange={({ target: { value } }) => setVerificationInput1(value)}
                maxLength="1"
                ref={inputEl1}
                type="text"
                value={verificationInput1 || ''}
              />

              <input
                className={
                  verifyingIdentifierErrorMessage &&
                  'BusinessPhoneVerificationLightBox__Input--Error'
                }
                onChange={({ target: { value } }) => setVerificationInput2(value)}
                maxLength="1"
                ref={inputEl2}
                type="text"
                value={verificationInput2 || ''}
              />

              <input
                className={
                  verifyingIdentifierErrorMessage &&
                  'BusinessPhoneVerificationLightBox__Input--Error'
                }
                onChange={({ target: { value } }) => setVerificationInput3(value)}
                maxLength="1"
                ref={inputEl3}
                type="text"
                value={verificationInput3 || ''}
              />

              <input
                className={
                  verifyingIdentifierErrorMessage &&
                  'BusinessPhoneVerificationLightBox__Input--Error'
                }
                onChange={({ target: { value } }) => setVerificationInput4(value)}
                maxLength="1"
                ref={inputEl4}
                type="text"
                value={verificationInput4 || ''}
              />

              <input
                className={
                  verifyingIdentifierErrorMessage &&
                  'BusinessPhoneVerificationLightBox__Input--Error'
                }
                onChange={({ target: { value } }) => setVerificationInput5(value)}
                maxLength="1"
                ref={inputEl5}
                type="text"
                value={verificationInput5 || ''}
              />
            </div>
          </fieldset>

          <button
            onClick={onHandleCallAgain}
            className="button orange BusinessPhoneVerificationLightBox__CallAgain"
            disabled={isInitiatingVerificationCall || isVerifyingIdentifier}
            type="button"
          >
            {isInitiatingVerificationCall || isVerifyingIdentifier ? 'loading...' : 'CALL AGAIN'}
          </button>

          {verifyingIdentifierErrorMessage ? (
            <div className="BusinessPhoneVerificationLightBox__ErrorMessage">
              {verifyingIdentifierErrorMessage}
            </div>
          ) : null}
        </form>
      </LightBox>

      <LightBox
        className="BusinessPhoneVerificationInvalidPhoneNumberLightBox"
        height={286}
        show={verifyBusinessPhoneNumberError}
        width={320}
        onClose={() => setVerifyBusinessPhoneNumberError(false)}
      >
        <form>
          <fieldset>
            <div className="BusinessPhoneVerificationInvalidPhoneNumberLightBox__Title">
              Ring Ring... No Answer...
            </div>

            <div className="BusinessPhoneVerificationInvalidPhoneNumberLightBox__Content">
              The business phone number provided appears to be invalid.
            </div>

            <div className="BusinessPhoneVerificationInvalidPhoneNumberLightBox__Content">
              Please verify your business without a phone number.
            </div>
          </fieldset>
        </form>
      </LightBox>
    </div>
  );
}

BusinessPhoneVerification.propTypes = {
  history: HISTORY_TYPE.isRequired,
  paths: arrayOf(string).isRequired,
  back: func.isRequired,
};

const mapStateToProps = (state) => state.path;

export default compose(connect(mapStateToProps, { back: backAction }))(BusinessPhoneVerification);
