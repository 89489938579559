import { bool, func, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import uuidv1 from 'uuid/v1';

import { getAllGigs as getAllGigsAction } from '../../actions/gigs';
import AsyncContent from '../../components/AsyncContent';
import Filters from '../../components/Filters';
import { Col, Row } from '../../components/Grid';
import MasterPage from '../../components/MasterPage';
import Paginator from '../../components/Paginator';
import { DEFAULT_PRIZE_AMOUNT } from '../../config/filter-params';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../config/pagination';
import GIG_TYPE from '../../types/gig';
import LOCATION_TYPE from '../../types/location';
import RESPONSE_LIST_OF_TYPE from '../../types/responseListOf';
import AllGigsCard from './AllGigsCard';
import ROUTER_MATCH_TYPE from '../../types/routerMatch';

/* global  document window */

class AllGigs extends React.Component {
  componentDidMount() {
    const {
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;

    if (!pageNumber || !itemsCountPerPage) {
      this.updatePagination(
        Number(itemsCountPerPage) || DEFAULT_PAGE_SIZE,
        Number(pageNumber) || DEFAULT_PAGE_INDEX,
      );
    }
    else if (pageNumber && itemsCountPerPage) {
      this.getAllGigs(pageNumber);
    }
    window.addEventListener('popstate', this.popStateListener);
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search },
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;

    if (prevProps.location.search !== search) {
      if (Number(pageNumber) !== DEFAULT_PAGE_INDEX) {
        this.updatePagination(Number(itemsCountPerPage), DEFAULT_PAGE_INDEX);
      }
      this.getAllGigs();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.popStateListener);
  }

  async getAllGigs(forcedPageNumber) {
    const { getAllGigs } = this.props;
    const requestParams = this.getRequestParams(forcedPageNumber);
    await getAllGigs(requestParams);
  }

  getRequestParams(forcedPageNumber) {
    const {
      location,
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;
    const query = new URLSearchParams(location.search);
    const requestParams = {
      PageSize: itemsCountPerPage,
      PageNum: forcedPageNumber || pageNumber,
    };

    const BusinessName = query.get('businessName') || '';
    const FromDate = query.get('closingDateFrom') || '';
    const Location = query.get('location') || '';
    const OrderBy = Number(query.get('orderBy')) || 0;
    const prizeAmount = query.get('prizeAmount')
      ? query.get('prizeAmount').split(',')
      : DEFAULT_PRIZE_AMOUNT;
    const SearchText = query.get('search') || '';
    const Status = query.get('gigStatus') || [0, 1, 2, 3];
    const ToDate = query.get('closingDateTo') || '';

    return {
      ...requestParams,
      BusinessName,
      FromDate,
      Location,
      OrderBy,
      PrizeAmountMax: prizeAmount[1],
      PrizeAmountMin: prizeAmount[0],
      SearchText,
      Status,
      ToDate,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  popStateListener() {
    const el = document.querySelectorAll('.pagination > .active a');
    if (el.length > 0) {
      const txt = Number(el[0].textContent);
      if (txt === 1) window.history.go(-1);
    }
  }

  updatePagination(pageSize, pageIndex) {
    const {
      location: { search },
      history,
    } = this.props;
    history.push({
      pathname: `/admin/gigs/${pageSize}/${pageIndex}`,
      search,
    });
    // setTimeout(() => this.getAllGigs(pageIndex));
  }

  render() {
    const {
      auth,
      allGigs,
      allGigsError,
      isFetchingGigs,
      match: {
        params: { pageNumber, itemsCountPerPage },
      },
    } = this.props;

    const masterPageConfigs = {
      headerBreadcrumb: [{ name: 'Home', url: '/' }, { name: 'All GiGs' }],
    };

    return (
      <>
        <Helmet title="Franki Admin: All Gigs" />

        <MasterPage auth={auth} {...masterPageConfigs}>
          <Filters
            sorterItems={[
              { label: 'gig status', value: '0' },
              { label: 'business name', value: '1' },
              { label: 'soon to expire', value: '2' },
            ]}
            activeFilterNames={[
              'businessName',
              'closingDateFrom',
              'closingDateTo',
              'gigStatus',
              'location',
              'orderBy',
              'prizeAmount',
              'search',
            ]}
          />

          {allGigs && allGigs.TotalItems > 0 && (
            <Paginator
              isTopPaginator
              itemsCountPerPage={Number(itemsCountPerPage)}
              onHandlePage={(...params) => this.updatePagination(...params)}
              onHandleShow={(...params) => this.updatePagination(...params)}
              pageIndex={Number(pageNumber)}
              totalItems={allGigs.TotalItems}
            />
          )}

          <AsyncContent
            content={gigList => (
              <Row cellSpacing={10} rowSpacing={10}>
                {gigList.map(item => (
                  <Col lg={3} md={4} sm={6} xs={12} key={uuidv1()}>
                    <AllGigsCard item={item} />
                  </Col>
                ))}
              </Row>
            )}
            errorMessage={allGigsError}
            expectedData={allGigs.Results}
            loading={isFetchingGigs}
          />
        </MasterPage>
      </>
    );
  }
}

AllGigs.propTypes = {
  allGigs: RESPONSE_LIST_OF_TYPE(GIG_TYPE).isRequired,
  allGigsError: string.isRequired,
  auth: bool.isRequired,
  getAllGigs: func.isRequired,
  isFetchingGigs: bool.isRequired,
  match: ROUTER_MATCH_TYPE({ pageNumber: string, itemsCountPerPage: string }).isRequired,
  location: LOCATION_TYPE.isRequired,
};

const mapStateToProps = state => ({
  allGigs: state.gigs.allGigs,
  allGigsError: state.gigs.allGigsError,
  isFetchingGigs: state.gigs.isFetchingGigs,
});

export default connect(mapStateToProps, { getAllGigs: getAllGigsAction })(AllGigs);
