import './index.sass';
import 'react-datepicker/dist/react-datepicker.css';

import { isEmpty } from 'lodash';
import moment from 'moment';
import { bool, func, string } from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import uuidv1 from 'uuid/v1';

import { getBusinessDetails as getBusinessDetailsAction } from '../../../../actions/businesses';
import {
  getGigDetails as getGigDetailsAction,
  resetGig as resetGigAction,
  updateGig as updateGigAction,
} from '../../../../actions/gigs';
import AsyncContent from '../../../../components/AsyncContent';
import FileUpload from '../../../../components/FileUpload';
import GigRules from '../../../../components/GigRules';
import { Col, Row } from '../../../../components/Grid';
import MasterPage from '../../../../components/MasterPage';
import RemainCharacters from '../../../../components/RemainCharacters';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../../config/pagination';
import { BUSINESS_DETAILS_TYPE } from '../../../../types/business';
import GIG_TYPE from '../../../../types/gig';
import ROUTER_MATCH_TYPE from '../../../../types/routerMatch';
import { DataURIToBlob } from '../../../../utils/api';

const BASE_FORM_VALUE = {
  description: '',
  endDate: new Date(new Date().setDate(new Date().getDate() + 22)),
  name: '',
  photo: '',
  prizes: [],
  rules: [],
  startDate: new Date(new Date().setDate(new Date().getDate() + 1)),
};
const NAME_MAX_LENGTH = 50;
const NAME_MIN_LENGTH = 3;
const DESCRIPTION_MAX_LENGTH = 200;
const DESCRIPTION_MIN_LENGTH = 3;

class BusinessGigEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { form: BASE_FORM_VALUE, errorMessage: '' };
  }

  componentDidMount() {
    const {
      getBusinessDetails,
      getGigDetails,
      match: {
        params: { corporateGroupId, gigId },
      },
      resetGig,
    } = this.props;

    resetGig();
    getGigDetails(gigId);
    getBusinessDetails(corporateGroupId);
  }

  componentDidUpdate(prevProps) {
    const { gigDetails, gigDetailsError, isFetchingGigDetails } = this.props;

    if (!isFetchingGigDetails && prevProps.isFetchingGigDetails && !gigDetailsError) {
      this.initForm(gigDetails);
    }
  }

  onHandleChange(name, value) {
    if (name == 'rules' && value[0].Description === '') {
      this.setState({ errorMessage: 'You cant remove first rule' });
    } else {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          [name]: value,
        },
      }));
    }
  }

  async onHandleSubmit(e) {
    e.preventDefault();
    const {
      updateGig,
      match: {
        params: { corporateGroupId, gigId },
      },
    } = this.props;

    const {
      form: { description, endDate, name, photo, rules, startDate },
    } = this.state;

    if (name.length > NAME_MAX_LENGTH || description.length > DESCRIPTION_MAX_LENGTH) {
      return;
    }
    const gigRules_ = JSON.stringify(rules.filter(({ Description }) => Description));
    const saveData = {
      CorporateGroupId: corporateGroupId,
      Description: description,
      EndDate: moment(endDate).format(),
      GigId: gigId,
      Name: name,
      ListGigRuleEdit: gigRules_,
      StartDate: moment(startDate).format(),
    };
    if (photo && photo.substring(0, 4) !== 'http') {
      saveData.Image = photo ? DataURIToBlob(photo) : photo;
    }
    updateGig(saveData);
  }

  initForm(gigDetails) {
    const photo =
      gigDetails.Photo && gigDetails.Photo.FullResolutionUrl
        ? gigDetails.Photo.FullResolutionUrl
        : BASE_FORM_VALUE.photo;

    this.setState({
      form: {
        photo,
        name: gigDetails.Name || BASE_FORM_VALUE.name,
        description: gigDetails.Description || BASE_FORM_VALUE.description,
        endDate:
          new Date(
            gigDetails.EndDate.includes('+') ? gigDetails.EndDate : `${gigDetails.EndDate}+00:00`,
          ) || BASE_FORM_VALUE.endDate,
        startDate:
          new Date(
            gigDetails.StartDate.includes('+')
              ? gigDetails.StartDate
              : `${gigDetails.StartDate}+00:00`,
          ) || BASE_FORM_VALUE.startDate,
        prizes: !isEmpty(gigDetails.GigPrizes) ? gigDetails.GigPrizes : BASE_FORM_VALUE.prizes,
        rules: !isEmpty(gigDetails.GigRules) ? gigDetails.GigRules : BASE_FORM_VALUE.rules,
      },
    });
  }

  removeRule = (gigRuleId) => {
    const { form } = this.state;
    const filtered = form.rules.filter((f) => f.GigRuleId != gigRuleId);
    this.setState({ form: { ...form, rules: filtered } });
  };

  render() {
    const {
      auth,
      businessDetails,
      isFetchingGigDetails,
      match: {
        params: { corporateGroupId, gigId },
      },
      updateGigError,
      updateGigSuccess,
    } = this.props;

    const {
      form: { description, endDate, name, photo, prizes, rules, startDate },
      errorMessage,
    } = this.state;

    const masterPageConfig = {
      auth,
      businessSidebarOn: true,
      businessSidebarData: businessDetails,
      headerBreadcrumb: [
        {
          name: 'all gigs',
          url: `/admin/business-gigs/${corporateGroupId}/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`,
        },
        { name: 'gig details', url: `/admin/business-gig-details/${corporateGroupId}/${gigId}` },
        { name: 'edit gig' },
      ],
    };

    const gigCost =
      prizes.length &&
      prizes.map((item) => item.PrizeAmount * item.WinnerQty).reduce((a, b) => Number(a + b));

    return (
      <>
        <Helmet title="Franki Admin: edit gig" />

        <MasterPage {...masterPageConfig}>
          <AsyncContent
            content={() => (
              <form
                className="BusinessGigEdit"
                method="POST"
                onSubmit={(e) => this.onHandleSubmit(e)}
              >
                <div>
                  <Row cellSpacing={30} rowSpacing={7} className="BusinessGigEdit__Row">
                    <Col lg={6} md={12} sm={12} xs={12} className="BusinessGigEdit__Col__Content">
                      <fieldset>
                        <legend>Image</legend>
                        <FileUpload
                          accept=".png, .PNG, .jpg, .JPG, .jpeg, .JPEG"
                          description={<>recommended size is 1200x1200px</>}
                          fullWidth
                          id="upload"
                          maxFileSize={4}
                          name="photo"
                          onChange={(inputName, value) => this.onHandleChange(inputName, value)}
                          placeholder="upload image"
                          type="image"
                          value={photo}
                        />
                      </fieldset>

                      <fieldset>
                        <legend>
                          time to name your gig
                          <span className="required-asterisk">*</span>
                        </legend>

                        <span className="label">
                          make sure to use a catchy name, this is the first thing a user will see
                          about your gig!
                        </span>

                        <input
                          autoComplete="off"
                          className="full"
                          id="name"
                          minLength={NAME_MIN_LENGTH}
                          name="name"
                          onChange={({ target }) => this.onHandleChange(target.name, target.value)}
                          placeholder="title"
                          required
                          type="text"
                          value={name}
                        />

                        <RemainCharacters
                          label="Gig's name"
                          length={name.length}
                          maxLength={NAME_MAX_LENGTH}
                        />
                      </fieldset>

                      <fieldset>
                        <legend>what’s this gig about?</legend>

                        <label htmlFor="txtCaption">
                          highlight your venue as a whole or a particular part
                        </label>

                        <textarea
                          className="full"
                          id="txtCaption"
                          minLength={DESCRIPTION_MIN_LENGTH}
                          name="description"
                          onChange={({ target }) => this.onHandleChange(target.name, target.value)}
                          placeholder="description"
                          rows="3"
                          value={description}
                        />

                        <RemainCharacters
                          label="Gig's description"
                          length={description.length}
                          maxLength={DESCRIPTION_MAX_LENGTH}
                        />
                      </fieldset>

                      <div className="BusinessGigEdit__Date">
                        <fieldset>
                          <legend>when would you like this gig to drop?</legend>
                          <label htmlFor="txtCaption">
                            select the date range, make sure it’s no longer than a week.
                          </label>
                          <Row cellSpacing={15}>
                            <Col lg={6} md={6} sm={12} xs={12}>
                              <label htmlFor="startDate">from</label>
                              <DatePicker
                                calendarClassName="Franki-DatePicker"
                                className="full"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                disabledKeyboardNavigation
                                id="startDate"
                                minDate={new Date()}
                                onChange={(_value) => this.onHandleChange('startDate', _value)}
                                selected={startDate}
                                showTimeSelect
                                timeCaption="time"
                                timeFormat="HH:mm"
                                timeIntervals={60}
                              />
                            </Col>

                            <Col lg={6} md={6} sm={12} xs={12}>
                              <label htmlFor="endDate">to</label>
                              <DatePicker
                                calendarClassName="Franki-DatePicker"
                                className="full"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                disabledKeyboardNavigation
                                id="endDate"
                                minDate={new Date()}
                                onChange={(_value) => this.onHandleChange('endDate', _value)}
                                selected={endDate}
                                showTimeSelect
                                timeCaption="time"
                                timeFormat="HH:mm"
                                timeIntervals={60}
                              />
                            </Col>
                          </Row>

                          {startDate > endDate && (
                            <div className="error">Start date cannot be after end date</div>
                          )}
                        </fieldset>
                      </div>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12} className="BusinessGigEdit__Col__Content">
                      <fieldset>
                        <legend>
                          {'Prizes '}
                          <span className="BusinessGigEdit__TotalPrize">
                            {`total = $${gigCost}`}
                          </span>
                        </legend>

                        {!isEmpty(prizes) ? (
                          <Row
                            rowSpacing={10}
                            cellSpacing={10}
                            className="BusinessGigEdit__PrizeDetails"
                          >
                            {prizes.map((item) => (
                              <Col lg={4} md={3} sm={4} xs={12} key={uuidv1()}>
                                <span className="BusinessGigEdit__PrizeDetails__Amount">
                                  {`$${item.PrizeAmount}`}
                                </span>

                                <span className="BusinessGigEdit__PrizeDetails__Description">
                                  {item.Description}
                                </span>

                                <span className="BusinessGigEdit__PrizeDetails__Qty">
                                  {`${item.WinnerQty} ${item.WinnerQty > 1 ? 'winners' : 'winner'}`}
                                </span>
                              </Col>
                            ))}
                          </Row>
                        ) : (
                          <span className="label">There are no prizes</span>
                        )}
                      </fieldset>

                      <fieldset>
                        <legend>add up to {4 - rules.length} additional rules for your gig</legend>
                        <span className="label">
                          users must follow these rules in order to qualify to win gig rewards, the
                          first rule is required
                        </span>
                        <GigRules
                          gigId={gigId}
                          onChange={(value) => this.onHandleChange('rules', value)}
                          rules={rules}
                          removeRule={this.removeRule}
                          name={name}
                        />
                      </fieldset>
                    </Col>
                  </Row>
                </div>

                <div className="buttons-actions">
                  <Link
                    className="button small"
                    to={`/admin/business-gigs/${businessDetails.CorporateGroupId}/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`}
                  >
                    Cancel
                  </Link>

                  <button
                    className="button small orange-theme"
                    disabled={startDate > endDate}
                    type="submit"
                  >
                    Publish
                  </button>
                </div>
              </form>
            )}
            errorMessage={updateGigError || errorMessage}
            expectNoData
            loading={isFetchingGigDetails}
            successMessage={updateGigSuccess}
            onHandleCloseDialog={() => this.setState({ errorMessage: '' })}
          />
        </MasterPage>
      </>
    );
  }
}

BusinessGigEdit.propTypes = {
  auth: bool.isRequired,
  businessDetails: BUSINESS_DETAILS_TYPE.isRequired,
  getBusinessDetails: func.isRequired,
  getGigDetails: func.isRequired,
  gigDetails: GIG_TYPE,
  gigDetailsError: string,
  isFetchingGigDetails: bool,
  isGigUpdating: bool,
  match: ROUTER_MATCH_TYPE({
    corporateGroupId: string.isRequired,
    gigId: string.isRequired,
  }).isRequired,
  resetGig: func.isRequired,
  updateGigError: string,
  updateGigSuccess: string,
  updateGig: func.isRequired,
};

BusinessGigEdit.defaultProps = {
  gigDetails: {},
  gigDetailsError: '',
  isFetchingGigDetails: true,
  isGigUpdating: false,
  updateGigError: '',
  updateGigSuccess: '',
};

const mapStateToProps = (state) => ({
  businessDetails: state.businesses.businessDetails,
  gigDetails: state.gigs.details,
  gigDetailsError: state.gigs.detailsError,
  isFetchingGigDetails: state.gigs.isFetchingGigDetails,
  isGigUpdating: state.gigs.isGigUpdating,
  updateGigError: state.gigs.updateGigError,
  updateGigSuccess: state.gigs.updateGigSuccess,
});

export default connect(mapStateToProps, {
  getBusinessDetails: getBusinessDetailsAction,
  getGigDetails: getGigDetailsAction,
  updateGig: updateGigAction,
  resetGig: resetGigAction,
})(BusinessGigEdit);
