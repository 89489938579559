import { bool, func, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getBusinessDetails as getBusinessDetailsAction } from '../../../actions/businesses';
import ManagePlan from '../../../components/ManagePlan';
import { BUSINESS_DETAILS_TYPE } from '../../../types/business';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';

class ManagePlanPage extends React.Component {
  componentDidMount() {
    const {
      getBusinessDetails,
      match: {
        params: { corporateGroupId },
      },
    } = this.props;
    getBusinessDetails(corporateGroupId);
  }

  render() {
    const { auth, businessDetails, businessDetailsError, isFetchingBusinessDetails, userRole } =
      this.props;

    return (
      <ManagePlan
        auth={auth}
        businessDetails={businessDetails}
        businessDetailsError={businessDetailsError}
        loading={isFetchingBusinessDetails}
        userRole={userRole}
      />
    );
  }
}

ManagePlanPage.propTypes = {
  auth: bool.isRequired,
  businessDetails: BUSINESS_DETAILS_TYPE.isRequired,
  businessDetailsError: string.isRequired,
  getBusinessDetails: func.isRequired,
  isFetchingBusinessDetails: bool.isRequired,
  match: ROUTER_MATCH_TYPE({ corporateGroupId: string.isRequired }).isRequired,
  userRole: string.isRequired,
};

const mapStateToProps = (state) => ({
  businessDetails: state.businesses.businessDetails,
  businessDetailsError: state.businesses.businessDetailsError,
  isFetchingBusinessDetails: state.businesses.isFetchingBusinessDetails,
});

export default connect(mapStateToProps, { getBusinessDetails: getBusinessDetailsAction })(
  ManagePlanPage,
);
