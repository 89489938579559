import './index.sass';

import { oneOf, string } from 'prop-types';
import React, { useMemo, useState } from 'react';

// import defaultLandingSvg from '../../../assets/images/business-landing.svg';
import notVerifiedPng from '../../../assets/images/business-landing-not-verified.png';
import verifiedPng from '../../../assets/images/business-landing-verified.png';
import pendingPng from '../../../assets/images/business-landing-pending.png';
import HISTORY_TYPE from '../../../types/history';
import LightBox from '../../../components/LightBox';

const WELCOME_SCREEN_STATUS = {
  verified: {
    img: verifiedPng,
    title: (
      <>
        Congrats! Your business
        <br />
        has been added to franki.
      </>
    ),
    content: (
      <>
        Now, don’t forget to update your profile. And, if you want to drive
        <br />
        some more customers through your doors, set up a GiG!
        <br />
        It's quick, easy and risk-free.
      </>
    ),
    buttonAction: { url: '/admin/business-edit', text: 'UPDATE PROFILE' },
  },
  pending: {
    img: pendingPng,
    title: (
      <>
        Congrats! Your account
        <br />
        has been created, now its on us.
      </>
    ),
    content: (
      <>
        Someone from franki will reach out to you to complete the verification process.
        <br />
        From there you can update your profile, view video reviews made in
        <br />
        your business and run GiGs! In the meantime, take a look
        <br />
        around the franki dashboard!
      </>
    ),
    buttonAction: { url: '/admin/business-profile', text: 'VIEW PROFILE' },
  },
  notVerified: {
    img: notVerifiedPng,
    title: (
      <>
        You’re almost there.
        <br />
        All that’s left is to do is get verified.
      </>
    ),
    content: (
      <>
        In order to update your listing, view your videos
        <br />
        and run GiGs you will need to complete the verification process.
      </>
    ),
    buttonAction: { url: '/admin/business-profile', text: 'VIEW PROFILE' },
  },
};

const Welcome = (props) => {
  const { status, businessId, history } = props;
  const [showDialog, setShowDialog] = useState(true);
  const landingData = useMemo(() => WELCOME_SCREEN_STATUS[status], [status]);

  return landingData ? (
    <LightBox
      onClose={() => setShowDialog(false)}
      show={showDialog}
      style={{
        width: 600,
        height: null,
        marginLeft: -300,
        marginTop: -250,
      }}
    >
      <div className="Welcome">
        {landingData.img && (
          <img className={`Welcome__Image__${status}`} src={landingData.img} alt={status} />
        )}

        {landingData.title && <div className="Welcome__Title">{landingData.title}</div>}

        {landingData.content && <div className="Welcome__Content">{landingData.content}</div>}

        {landingData.buttonAction && (
          <button
            onClick={() => {
              history.push(`${landingData.buttonAction.url}/${businessId}`);
            }}
            type="button"
            className="button orange-theme"
          >
            {landingData.buttonAction.text}
          </button>
        )}
      </div>
    </LightBox>
  ) : (
    <></>
  );
};

Welcome.propTypes = {
  status: oneOf(['verified', 'pending', 'notVerified']).isRequired,
  businessId: string.isRequired,
  history: HISTORY_TYPE.isRequired,
};

export default Welcome;
