import './index.sass';

import { func, instanceOf, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';

import gigPreviewImg from '../../../../../assets/images/gig_preview.png';
import StepsHeader from '../../../../../components/StepsHeader';
import GigPackageCard from '../GigPackageCard';
import GIG_PACKAGES from '../GigPackages';

export default function BusinessGigCreateFirstStep(props) {
  const {
    onHandleNextStep,
    onHandleFormChange,
    formState: { gigType },
    onHandleBack,
  } = props;

  const activeGig = useMemo(() => GIG_PACKAGES.find((item) => item.type === gigType), [gigType]);

  useEffect(() => {
    if (activeGig) {
      onHandleFormChange('headline', activeGig.title);
      onHandleFormChange('caption', activeGig.gigDescription);

      onHandleFormChange(
        'rules',
        activeGig.rules.map((rule, index) => ({
          Description: rule,
          DisplayOrder: index,
          GigRuleId: index,
        })),
      );

      onHandleFormChange(
        'prizes',
        activeGig.prizes.map((prize) => ({
          Description: prize.name,
          Disabled: false,
          GigPrizeId: prize.id,
          Label: prize.name,
          MaxPrizeAmount: 500,
          MaxWinners: 25,
          MinPrizeAmount: 5,
          MinWinners: 1,
          Name: prize.name,
          Placeholder: 'most creative runner-up',
          PrizeAmount: prize.amount,
          Removable: true,
          Required: true,
          WinnerQty: prize.winners,
        })),
      );
    }
  }, [activeGig]);

  const onHandleChange = useCallback(
    (value) => {
      onHandleFormChange('gigType', value);
    },
    [onHandleFormChange],
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onHandleNextStep();
    },
    [onHandleNextStep],
  );

  return (
    <form method="POST" onSubmit={onSubmit}>
      <StepsHeader
        maxSteps={5}
        step={1}
        subtitle="You can do a lot with GiGs. Below are some templates to get you started."
        title="What type of GiG would you like to run?"
        tooltip={
          <>
            <p>this is what your gig will look like in franki, be sure its enticing!</p>
            <figure>
              <img src={gigPreviewImg} alt="" />
            </figure>
          </>
        }
        tooltipTitle="gig details"
      />

      <div className="BusinessGigCreateFirstStep">
        <div className="GigPackageCard__Container">
          {GIG_PACKAGES.map((item) => (
            <GigPackageCard
              {...item}
              key={item.type}
              active={gigType === item.type}
              onChange={onHandleChange}
            />
          ))}
        </div>
      </div>

      <div className="buttons-actions">
        <button
          type="button"
          className="button small button--outline"
          onClick={(e) => onHandleBack(e)}
        >
          BACK
        </button>
        <button className="button small button--primary" type="submit" disabled={!gigType}>
          NEXT
        </button>
      </div>
    </form>
  );
}

BusinessGigCreateFirstStep.propTypes = {
  businessID: string.isRequired,
  formState: instanceOf(Object).isRequired,
  onHandleNextStep: func.isRequired,
  onHandleFormChange: func.isRequired,
};
