import { isEqual } from 'lodash';
import { arrayOf, bool, func, string } from 'prop-types';
import React, { Component } from 'react';

import { USER_BUSINESS_TYPE } from '../../types/users';
import { APIRequest } from '../../utils/api';
import AsyncCustomSelect from '../CustomSelect/AsyncCustomSelect';

const convertSelectedBusinessValuesToOptions = (selectedBusinesses) => {
  let selectedOptions = [];
  if (selectedBusinesses) {
    selectedOptions = selectedBusinesses.map(({ CorporateGroupId, DisplayName }) => ({
      label: DisplayName,
      value: CorporateGroupId,
    }));
  }
  return selectedOptions;
};

const convertOptionsToSelectedBusinessValues = (selectedOptions) => {
  let selectedBusinesses = [];
  if (selectedOptions) {
    selectedBusinesses = selectedOptions.map(({ label, value }) => ({
      CorporateGroupId: value,
      DisplayName: label,
    }));
  }
  return selectedBusinesses;
};

const getBusiness = (searchText) =>
  APIRequest({
    uri: 'UserAdmin/UserAdminGetCorporateGroupList',
    method: 'POST',
    data: { SearchText: searchText },
  });

const asyncOptions = () => (searchText) =>
  new Promise((resolve) => {
    getBusiness(searchText).then(({ Data }) => {
      resolve(convertSelectedBusinessValuesToOptions(Data.UserList));
    });
  });

class BusinessSelect extends Component {
  state = {
    selectedOptions: [],
  };

  componentDidMount() {
    const { value } = this.props;
    this.updateSelectedOptions(value);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (!isEqual(value, prevProps.value)) {
      this.updateSelectedOptions(value);
    }
  }

  updateSelectedOptions(value) {
    this.setState({ selectedOptions: convertSelectedBusinessValuesToOptions(value) });
  }

  handleChange(selectedOptions) {
    const { onChange } = this.props;
    this.setState({ selectedOptions });
    onChange(convertOptionsToSelectedBusinessValues(selectedOptions));
  }

  render() {
    const { id, label, name, placeholder, required } = this.props;
    const { selectedOptions } = this.state;

    return (
      <div className="form-group has-tooltip">
        <fieldset>
          <label className="form-label" htmlFor={id}>
            {label}

            {required && <span className="required-asterisk">*</span>}
          </label>

          <AsyncCustomSelect
            id={id}
            isMulti
            name={name}
            onChange={(options) => this.handleChange(options)}
            options={asyncOptions()}
            placeholder={placeholder}
            required={required}
            value={selectedOptions}
          />
        </fieldset>
      </div>
    );
  }
}

BusinessSelect.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  placeholder: string.isRequired,
  required: bool,
  value: arrayOf(USER_BUSINESS_TYPE),
};

BusinessSelect.defaultProps = {
  required: false,
  value: [],
};

export default BusinessSelect;
