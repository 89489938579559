import './index.sass';

import { bool, func } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { geocode } from '../../../../actions/google';
import AddressTagsInput from '../../../../components/AddressTagsInput';
import BusinessAutocomplete from '../../../../components/BusinessAutocomplete';
import { Col, Row } from '../../../../components/Grid';
import { BUSINESS_DETAILS_TYPE } from '../../../../types/business';

const BusinessSearchForm = (props) => {
  const {
    onHandleFormChange,
    checkboxHandler,
    formState,
    nonFourSquare,
    clearValue: parentClearValue,
  } = props;

  const [cityName, setCityName] = useState('');
  const [businessFieldEnabled, setBusinessFieldEnabled] = useState(false);
  const [clearValue, setClearValue] = useState(parentClearValue);

  const setBusinessLocationFourSquare = (location) => {
    if (location && location.value) {
      const {
        ThirdPartyBusinessSourceId,
        BusinessName,
        FormattedAddress,
        Country,
        City,
        Latitude,
        Longitude,
        Neighborhood,
        State,
        PostalCode,
      } = location.value;

      onHandleFormChange('ThirdPartyBusinessSourceId', ThirdPartyBusinessSourceId);
      onHandleFormChange('Name', BusinessName);
      onHandleFormChange('Address', FormattedAddress);
      onHandleFormChange('Country', Country);
      onHandleFormChange('City', City);
      onHandleFormChange('Latitude', Latitude);
      onHandleFormChange('Longitude', Longitude);
      onHandleFormChange('State', State);
      onHandleFormChange('Zipcode', PostalCode);
      onHandleFormChange('Neighborhood', Neighborhood);
    }
  };

  const searchAndApplyAddress = (address) => {
    geocode(address).subscribe(([geocodeAddress]) => {
      try {
        const { Address, Country, City, Latitude, Longitude, State, Zipcode, Neighborhood } =
          geocodeAddress;

        onHandleFormChange('Address', Address);
        onHandleFormChange('Country', Country);
        onHandleFormChange('City', City);
        onHandleFormChange('Latitude', Latitude);
        onHandleFormChange('Longitude', Longitude);
        onHandleFormChange('State', State);
        onHandleFormChange('Zipcode', Zipcode);
        onHandleFormChange('Neighborhood', Neighborhood);
      } catch (_) {
        // Ignore
      }
    });
  };

  const setBusinessLocation = (location) => {
    if (location) {
      onHandleFormChange('GooglePlaceId', location.place_id);
      onHandleFormChange(
        'Name',
        location.structured_formatting && location.structured_formatting.main_text,
      );
      onHandleFormChange('Address', location.description);
      onHandleFormChange('Country', '');
      onHandleFormChange('City', '');
      onHandleFormChange('Latitude', '');
      onHandleFormChange('Longitude', '');
      onHandleFormChange('State', '');
      onHandleFormChange('Zipcode', '');
      onHandleFormChange('Neighborhood', '');

      searchAndApplyAddress(location.description);
    }
  };

  useEffect(() => {
    setCityName('');
  }, [parentClearValue]);

  useEffect(() => {
    setBusinessFieldEnabled(!!cityName);
    setClearValue(!clearValue);
    // TODO - remove localstorage depencency from legacy
    localStorage.setItem('cityName', cityName);
  }, [cityName]);

  return (
    <div className="w100p">
      <div className="BusinessEdit__Blocks">
        <Row cellSpacing={15} rowSpacing={10}>
          <Col lg={4} md={4} sm={6} xs={12}>
            <label htmlFor="txtSearchBusinessAddressCity">
              Search City
              <span className="required-asterisk">*</span>
            </label>

            <AddressTagsInput
              id="txtSearchBusinessAddressCity"
              disabled={nonFourSquare}
              listOfTags={cityName}
              name="cityName"
              onChange={setCityName}
              value={cityName}
              filter={{
                types: ['(cities)'],
              }}
              noBrowserComplete
            />
          </Col>

          <Col lg={8} md={8} sm={8} xs={12}>
            <label htmlFor="txtBusinessAddressState">
              Search for your business name
              <span className="required-asterisk">*</span>
            </label>

            <BusinessAutocomplete
              disabled={!businessFieldEnabled || nonFourSquare}
              className="full"
              clearValue={clearValue}
              setBusinessLocationFourSquare={setBusinessLocationFourSquare}
              setBusinessLocation={setBusinessLocation}
              placeholder="your business name"
              required={!formState.GooglePlaceId || !formState.Address || !formState.Name}
            />
          </Col>
        </Row>
        <Row cellSpacing={7} rowSpacing={7}>
          <Col lg={12} md={12} sm={12} xs={12} className="NonFourSquare">
            <label>
              <input
                name="nonFourSquare"
                onChange={checkboxHandler}
                type="checkbox"
                checked={nonFourSquare}
              />
              <span />
              Not a Foursquare Business
            </label>
          </Col>
        </Row>
        <Row cellSpacing={7} rowSpacing={7}>
          <Col lg={12} md={12} sm={12} xs={12}>
            <span className="noteText">
              Note: If you can’t find the business via search, select ‘Not a Foursquare business’
              and manually add the fields below
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
};

BusinessSearchForm.propTypes = {
  onHandleFormChange: func.isRequired,
  checkboxHandler: func.isRequired,
  nonFourSquare: bool.isRequired,
  formState: BUSINESS_DETAILS_TYPE.isRequired,
  clearValue: bool,
  noBrowserComplete: bool,
};

BusinessSearchForm.defaultProps = {
  clearValue: false,
  noBrowserComplete: false,
};

export default BusinessSearchForm;
