import { bool, instanceOf } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import MasterPage from '../../../components/MasterPage';
import RegisterFooter from '../../../components/RegisterFooter';
import HISTORY_TYPE from '../../../types/history';
import Account from './FirstStep';
import { cannabisPlans, hospitalityPlans } from './FirstStep/plans.json';
import SecondStep from './SecondStep';
import Payment from './ThirdStep';

class PlanAndAccount extends React.Component {
  constructor(props) {
    super(props);

    const {
      routerProps: { businessType },
    } = props;
    const getPlan = businessType === 'cannabis' ? cannabisPlans[0] : hospitalityPlans[0];

    this.state = {
      step: 1,
      planHasBeingChanged: false,
      form: {
        plan: getPlan.title,
        planPrice: getPlan.priceWithDiscount,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        cardNumber: '',
        expirationYear: '',
        expirationMonth: '',
        cvv: '',
      },
    };
  }

  onHandleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onHandleFormChange = (name, value) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  };

  onHandleSubmit = (e) => {
    e.preventDefault();
    const { history } = this.props;
    history.push('/admin/activated-account');
  };

  render() {
    const {
      step,
      planHasBeingChanged,
      form: {
        plan,
        planPrice,
        firstName,
        lastName,
        email,
        password,
        cardNumber,
        expirationYear,
        expirationMonth,
        cvv,
      },
    } = this.state;

    const {
      auth,
      routerProps: { businessType },
    } = this.props;

    return (
      <>
        <Helmet title="Franki Admin: register account" />

        <MasterPage auth={auth}>
          {step === 1 && (
            <Account
              formFields={{
                firstName,
                lastName,
                email,
                password,
              }}
              onHandleFormChange={this.onHandleFormChange}
              onHandleChange={this.onHandleChange}
            />
          )}

          {step === 2 && (
            <SecondStep
              activePlan={plan}
              businessType={businessType}
              onHandleFormChange={this.onHandleFormChange}
              onHandleChange={this.onHandleChange}
              planHasBeingChanged={planHasBeingChanged}
            />
          )}

          {step === 3 && (
            <Payment
              formFields={{
                cardNumber,
                expirationYear,
                expirationMonth,
                cvv,
                plan,
                planPrice,
              }}
              onHandleFormChange={this.onHandleFormChange}
              onHandleChange={this.onHandleChange}
              onHandleSubmit={this.onHandleSubmit}
            />
          )}
        </MasterPage>

        <RegisterFooter />
      </>
    );
  }
}

PlanAndAccount.propTypes = {
  history: HISTORY_TYPE.isRequired,
  auth: bool.isRequired,
  routerProps: instanceOf(Object).isRequired,
};

export default PlanAndAccount;
