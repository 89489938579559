import { APIRequest } from '../utils/api';

export const ACCEPT_FLAGGED_CONTENT = 'ACCEPT_FLAGGED_CONTENT';
export const GET_ALL_FLAGGED_CONTENT = 'GET_ALL_FLAGGED_CONTENT';
export const GET_ALL_FLAGGED_CONTENT_SUCCESS = 'GET_ALL_FLAGGED_CONTENT_SUCCESS';
export const GET_ALL_FLAGGED_CONTENT_ERROR = 'GET_ALL_FLAGGED_CONTENT_ERROR';
export const GET_FLAGGED_DETAIL = 'GET_FLAGGED_DETAIL';
export const UPDATE_FLAGGED_STATUS = 'UPDATE_FLAGGED_STATUS';
export const UPDATE_FLAGGED_STATUS_SUCCESS = 'UPDATE_FLAGGED_STATUS_SUCCESS';
export const UPDATE_FLAGGED_STATUS_ERROR = 'UPDATE_FLAGGED_STATUS_ERROR';

export function getFlaggedContents(queryString = '') {
  const url = `V2/Flag/Content${queryString}`;
  return async (dispatch) => {
    dispatch({ type: GET_ALL_FLAGGED_CONTENT });
    await APIRequest({
      uri: url,
      method: 'GET',
    })
      .then(res => dispatch({
        type: GET_ALL_FLAGGED_CONTENT_SUCCESS,
        payload: res,
      }))
      .catch(error => dispatch({
        type: GET_ALL_FLAGGED_CONTENT_ERROR,
        payload: { error },
      }));
  };
}

export function acceptFlagged(itemId, type) {
  return async (dispatch) => {
    await APIRequest({
      uri: `Flag/AdjustStatus?itemId=${itemId}&isAppropriate=${type}&itemTypeText=1`,
      method: 'POST',
    })
      .then(res => dispatch({
        type: ACCEPT_FLAGGED_CONTENT,
        payload: {
          data: res.Success,
        },
      }))
      .catch(error => dispatch({
        type: ACCEPT_FLAGGED_CONTENT,
        payload: { error },
      }));
  };
}

export function getFlaggedDetails(itemId) {
  return async (dispatch) => {
    await APIRequest({
      uri: `flagreview/getfullcontent?itemId=${itemId}&itemType=1`,
      method: 'GET',
    })
      .then((res) => {
        dispatch({
          type: GET_FLAGGED_DETAIL,
          payload: {
            data: res.Data,
          },
        });
      })
      .catch(error => dispatch({
        type: GET_FLAGGED_DETAIL,
        payload: { error },
      }));
  };
}

export function updateFlagStatus(itemId, status) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FLAGGED_STATUS,
    });
    await APIRequest({
      uri: `Flag/AdjustStatus?itemId=${itemId}&contentStatusIds=${status}`,
      method: 'POST',
    })
      .then(res => dispatch({
        type: UPDATE_FLAGGED_STATUS_SUCCESS,
        payload: {
          data: res.Success,
        },
      }))
      .catch(error => dispatch({
        type: UPDATE_FLAGGED_STATUS_ERROR,
        payload: { error },
      }));
  };
}
