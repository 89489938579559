import './index.sass';
import { func, object, array, oneOfType } from 'prop-types';
import React from 'react';
import Toggle from 'react-toggle';
import CustomSelect from '../../../../components/CustomSelect';
import { valuesToOptionConverter, valuesToOptions } from '../../../../utils/common';


const BusinessHealth = (props) => {
  const {
    formState: { BusinessOption },
    businessOptionParamaters,
    onHandleChange,
  } = props;

  const { Options = [] } = businessOptionParamaters.find(item => item.Name === 'HealthAndSafety') || {};
  const { Values = [] } = Options.find(item => item.Name === 'HealthScore') || {};

  return (
    <div className="BusinessHealth BusinessHealth">
      <h4 className="BusinessOptions__Title">
        Health and safety
      </h4>
      <div className="BusinessHealth__Grid">
        <div className="">
          <CustomSelect
            id="txtHealthScore"
            className="BusinessHealth__Select"
            showDisabledValue
            name="HealthScore"
            onChange={selection => onHandleChange('HealthScore', selection)}
            options={valuesToOptions(Values)}
            placeholder="select one health score"
            value={typeof BusinessOption.HealthScore === 'object' ? valuesToOptionConverter(BusinessOption.HealthScore)
              : valuesToOptions(Values).find(({ value }) => BusinessOption.HealthScore === value)}
            isSearchable
          />
          {Options.filter(item => item.ValueType === 'Boolean').map(({ DisplayString, Name }) => (
            <label key={Name} className="mb-20 display-flex" htmlFor={`txt${Name}`}>
              <p className="BusinessOptions__Label">{DisplayString}</p>
              <Toggle
                defaultChecked={BusinessOption[Name]}
                icons={false}
                onChange={() => onHandleChange(Name, !BusinessOption[Name])}
              />
            </label>
          ))}
        </div>


      </div>
    </div>
  );
};

BusinessHealth.propTypes = {
  formState: oneOfType([object]),
  businessOptionParamaters: oneOfType([array]),
  onHandleChange: func.isRequired,
};

BusinessHealth.defaultProps = {
  formState: {},
  businessOptionParamaters: null,
};

export default BusinessHealth;
