import './index.sass';

import { sortBy } from 'lodash';
import { func } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { compose } from 'redux';

import {
  resendVerificationCode,
  updateIdentifier,
  validateAccountIdentifier,
  verifyIdentifier,
} from '../../actions/auth';
import { addPath as addPathAction } from '../../actions/path';
import orangeCircleCheck from '../../assets/images/orange_circle_check.svg';
import redCircleTimes from '../../assets/images/red_circle_times.svg';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import LightBox from '../../components/LightBox';
import PhoneWithFlagInput from '../../components/PhoneWithFlagInput';
import FLAG_SELECTOR_OPTION_LIST from '../../components/PhoneWithFlagInput/OptionList';
import HISTORY_TYPE from '../../types/history';
import { getPlaceId, getPlaceIdProp } from '../../utils/placeId';
import ProgressBar from '../../components/ProgressBar';

function MobileCodeVerification(props) {
  const { addPath, history } = props;
  const { state = {} } = history.location;
  const { nonFourSquare, profileCreated, businessId: businessIdState, thirdPartySourceId } = state;
  const placeIdProp = getPlaceIdProp();
  const placeId = getPlaceId(props);
  const { businessId: businessIdParam } = useParams();
  const businessId = businessIdState || businessIdParam;

  useEffect(() => {
    addPath();
  }, []);

  const inputEl0 = useRef(null);
  const inputEl1 = useRef(null);
  const inputEl2 = useRef(null);
  const inputEl3 = useRef(null);

  const [isResendDialogVisible, setIsResendDialogVisible] = useState(false);
  const [touched, setTouched] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneNumberStatus, setPhoneNumberStatus] = useState('empty');

  const [isValidatingAccountIdentifier, setIsValidatingAccountIdentifier] = useState(false);
  const [validatingAccountIdentifierSuccess, setValidatingAccountIdentifierSuccess] =
    useState(null);

  const [isVerifyingIdentifier, setIsVerifyingIdentifier] = useState(false);
  const [verifyIdentifierData, setVerifyIdentifierData] = useState(null);

  const [isUpdatingIdentifier, setIsUpdatingIdentifier] = useState(false);
  const [updatingIdentifierSuccess, setUpdatingIdentifierSuccess] = useState(null);

  const [isResendingVerificationCode, setIsResendingVerificationCode] = useState(false);
  const [resendingVerificationCodeSuccess, setResendingVerificationCodeSuccess] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);
  const [verifyingIdentifierErrorMessage, setVerifyingIdentifierErrorMessage] = useState(null);

  const [verificationInput0, setVerificationInput0] = useState(null);
  const [verificationInput1, setVerificationInput1] = useState(null);
  const [verificationInput2, setVerificationInput2] = useState(null);
  const [verificationInput3, setVerificationInput3] = useState(null);

  const optionList = sortBy(
    FLAG_SELECTOR_OPTION_LIST.filter((f) =>
      ['us', 'ca', 'au', 'gb', 'it', 'es', 'de', 'fr', 'sg', 'hk', 'ph'].includes(f.id),
    ),
    'name',
  ).reverse();

  useEffect(() => {
    setErrorMessage(null);
    setVerifyingIdentifierErrorMessage(null);

    if (
      [verificationInput0, verificationInput1, verificationInput2, verificationInput3].join('')
        .length === 4
    ) {
      setIsVerifyingIdentifier(true);

      verifyIdentifier({
        Identifier: phoneNumber.replace('+', ''),
        VerificationCode: [
          verificationInput0,
          verificationInput1,
          verificationInput2,
          verificationInput3,
        ].join(''),
      }).subscribe(
        setVerifyIdentifierData,
        (err) => {
          setVerifyingIdentifierErrorMessage(err);
          setIsVerifyingIdentifier(false);
        },
        setIsVerifyingIdentifier(false),
      );
    } else {
      if (!verificationInput0 && inputEl0.current) {
        inputEl0.current.focus();
        return;
      }
      if (!verificationInput1 && inputEl1.current) {
        inputEl1.current.focus();
        return;
      }
      if (!verificationInput2 && inputEl2.current) {
        inputEl2.current.focus();
        return;
      }
      if (inputEl3.current) {
        inputEl3.current.focus();
      }
    }
  }, [verificationInput0, verificationInput1, verificationInput2, verificationInput3]);

  useEffect(() => {
    if (verifyIdentifierData) {
      setIsUpdatingIdentifier(true);
      updateIdentifier({ JWTToken: verifyIdentifierData }).subscribe(
        () => setUpdatingIdentifierSuccess(true),
        (err) => {
          setErrorMessage(err);
          setIsUpdatingIdentifier(false);
        },
        () => setIsUpdatingIdentifier(false),
      );
    }
  }, [verifyIdentifierData]);

  useEffect(() => {
    if (updatingIdentifierSuccess) {
      history.push({
        pathname: `/business-phone-verification/${businessId}?${placeIdProp.query}=${placeId}`,
        state: {
          nonFourSquare,
          businessId,
          thirdPartySourceId,
          profileCreated,
        },
      });
    }
  }, [updatingIdentifierSuccess]);

  useEffect(() => {
    if (resendingVerificationCodeSuccess) setIsResendDialogVisible(true);
    else setIsResendDialogVisible(false);
  }, [resendingVerificationCodeSuccess]);

  const onHandleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setErrorMessage(null);
      setVerifyingIdentifierErrorMessage(null);
      setTouched(true);
      if (phoneNumberStatus === 'valid') {
        setIsValidatingAccountIdentifier(true);
        validateAccountIdentifier(phoneNumber).subscribe(
          setValidatingAccountIdentifierSuccess,
          (err) => {
            setErrorMessage(err);
            setIsValidatingAccountIdentifier(false);
          },
          () => setIsValidatingAccountIdentifier(false),
        );
      }
    },
    [
      phoneNumber,
      phoneNumberStatus,
      setErrorMessage,
      setIsValidatingAccountIdentifier,
      setTouched,
      setValidatingAccountIdentifierSuccess,
      setVerifyingIdentifierErrorMessage,
      validateAccountIdentifier,
    ],
  );

  const onHandleResend = useCallback(
    (e) => {
      e.preventDefault();
      setIsResendingVerificationCode(true);
      resendVerificationCode({ Identifier: phoneNumber }).subscribe(
        () => {
          setResendingVerificationCodeSuccess(true);
        },
        (err) => {
          setErrorMessage(err);
          setIsResendingVerificationCode(false);
        },
        () => setIsResendingVerificationCode(false),
      );
      setVerificationInput0('');
      setVerificationInput1('');
      setVerificationInput2('');
      setVerificationInput3('');
    },
    [
      resendVerificationCode,
      setErrorMessage,
      setIsResendingVerificationCode,
      setResendingVerificationCodeSuccess,
      setVerificationInput0,
      setVerificationInput1,
      setVerificationInput2,
      setVerificationInput3,
      phoneNumber,
    ],
  );

  const phoneErrorMessage = useMemo(() => {
    if (!touched) {
      return null;
    }

    if (phoneNumberStatus === 'invalid') {
      return 'Mobile number is invalid. Please try again.';
    }

    if (phoneNumberStatus === 'empty') {
      return 'Mobile number is required.';
    }

    return null;
  }, [phoneNumberStatus, touched]);

  const handlePhoneNumberChanged = useCallback(
    (val) => {
      setTouched(false);
      setErrorMessage(null);
      setVerifyingIdentifierErrorMessage(null);
      setPhoneNumber(val.number);
      setPhoneNumberStatus(val.status);
    },
    [
      setTouched,
      setErrorMessage,
      setVerifyingIdentifierErrorMessage,
      setPhoneNumber,
      setPhoneNumberStatus,
    ],
  );

  const isFetching = isResendingVerificationCode || isVerifyingIdentifier || isUpdatingIdentifier;

  const error = useMemo(() => {
    const message = errorMessage || phoneErrorMessage || verifyingIdentifierErrorMessage;
    return message ? <div className="EmailCodeVerification__Message error">{message}</div> : null;
  }, [errorMessage, phoneErrorMessage, verifyingIdentifierErrorMessage]);

  return (
    <div className="MobileCodeVerification">
      <Header auth={false} global />

      <main className="MobileCodeVerification__Main">
        <ProgressBar
          className="MobileCodeVerification__ProgressBar"
          totalSteps={profileCreated ? 5 : 4}
          activeStep={profileCreated ? 4 : 3}
        />
        {/* <div className="MobileCodeVerification__Stepper">
          <div className="MobileCodeVerification__Stepper__Step MobileCodeVerification__Stepper__Step--Completed">1</div>
          <div className="MobileCodeVerification__Stepper__StepConnector MobileCodeVerification__Stepper__StepConnector--Completed" />
          <div className="MobileCodeVerification__Stepper__Step MobileCodeVerification__Stepper__Step--Active">2</div>
          <div className="MobileCodeVerification__Stepper__StepConnector" />
          <div className="MobileCodeVerification__Stepper__Step">3</div>
        </div> */}

        <h1>Add and Verify Mobile Number</h1>

        <p className="MobileCodeVerification__Subtitle">
          Lets confirm your mobile. This allows us to alert you
          <br />
          when customers are looking for your business.
        </p>

        <div className="MobileCodeVerification__VerifyLater">
          Or{' '}
          <u>
            <Link
              to={{
                pathname: `/business-phone-verification/${businessId}?${placeIdProp.query}=${placeId}`,
                state: {
                  nonFourSquare,
                  businessId,
                  thirdPartySourceId,
                  profileCreated,
                },
              }}
            >
              skip and verify later
            </Link>
          </u>
        </div>

        <PhoneWithFlagInput
          displayError={touched}
          onChange={handlePhoneNumberChanged}
          title="Mobile"
          countryList={optionList}
        />

        <div className="MobileCodeVerification__SendVerificationCode">Send verification code.</div>

        {!validatingAccountIdentifierSuccess && (
          <div className="MobileCodeVerification__Confirm">
            <button onClick={onHandleSubmit} className="button" type="button">
              {isValidatingAccountIdentifier ? 'loading...' : 'CONFIRM'}
            </button>

            <div className="error">{error}</div>
          </div>
        )}

        {validatingAccountIdentifierSuccess && (
          <form>
            <fieldset>
              <img
                src={verifyingIdentifierErrorMessage ? redCircleTimes : orangeCircleCheck}
                alt="checked"
              />
              <div className="MobileCodeVerification__InputTitle">
                Mobile Verification Code
                <span>*</span>
              </div>

              <div className="MobileCodeVerification__Inputs">
                <input
                  className={
                    verifyingIdentifierErrorMessage && 'MobileCodeVerification__Input--Error'
                  }
                  onChange={({ target: { value } }) => setVerificationInput0(value)}
                  maxLength="1"
                  ref={inputEl0}
                  type="text"
                  value={verificationInput0 || ''}
                />

                <input
                  className={
                    verifyingIdentifierErrorMessage && 'MobileCodeVerification__Input--Error'
                  }
                  onChange={({ target: { value } }) => setVerificationInput1(value)}
                  maxLength="1"
                  ref={inputEl1}
                  type="text"
                  value={verificationInput1 || ''}
                />

                <input
                  className={
                    verifyingIdentifierErrorMessage && 'MobileCodeVerification__Input--Error'
                  }
                  onChange={({ target: { value } }) => setVerificationInput2(value)}
                  maxLength="1"
                  ref={inputEl2}
                  type="text"
                  value={verificationInput2 || ''}
                />

                <input
                  className={
                    verifyingIdentifierErrorMessage && 'MobileCodeVerification__Input--Error'
                  }
                  onChange={({ target: { value } }) => setVerificationInput3(value)}
                  maxLength="1"
                  ref={inputEl3}
                  type="text"
                  value={verificationInput3 || ''}
                />
              </div>
            </fieldset>

            {error}

            <button
              onClick={onHandleResend}
              className="button orange MobileCodeVerification__Resend"
              disabled={isFetching || verifyingIdentifierErrorMessage}
              type="button"
            >
              {isFetching ? 'loading...' : 'RESEND'}
            </button>
          </form>
        )}
      </main>

      <Footer />

      <LightBox
        style={{
          width: 500,
          height: null,
          marginLeft: -250,
          marginTop: -200,
        }}
        onClose={() => {
          setIsResendDialogVisible(false);
          setResendingVerificationCodeSuccess(false);
        }}
        show={isResendDialogVisible}
        title="Mobile Verification Resent"
      >
        <p className="MobileCodeVerification__LightBoxContent">
          You have 60 seconds to add the code before it times out.
        </p>
      </LightBox>
    </div>
  );
}

MobileCodeVerification.propTypes = {
  history: HISTORY_TYPE.isRequired,
  addPath: func.isRequired,
};

const mapStateToProps = (state) => ({
  allPaths: state.paths,
});

export default compose(connect(mapStateToProps, { addPath: addPathAction }))(
  MobileCodeVerification,
);
