import './index.sass';

import { arrayOf, func, number, string } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import gigPreviewImg from '../../../../../assets/images/gig_preview.png';
import AsyncContent from '../../../../../components/AsyncContent';
import GigRules from '../../../../../components/GigRules';
import StepsHeader from '../../../../../components/StepsHeader';
import { GIG_RULE } from '../../../../../types/gig';
import GigPackageCard from '../GigPackageCard';
import GIG_PACKAGES from '../GigPackages';

function BusinessGigCreateThirdStep(props) {
  const { gigType, onHandleNextStep, onHandleChange, onHandleBack, rules, removeRule } = props;
  const [dirty, setDirty] = useState(null);

  const isValid = useMemo(() => rules[0] && rules[0].Description, [rules]);
  const onHandleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setDirty(true);
      if (isValid) {
        onHandleNextStep();
      }
    },
    [onHandleNextStep, setDirty],
  );

  const activeGig = useMemo(() => GIG_PACKAGES.find((item) => item.type === gigType), [gigType]);

  return (
    <AsyncContent
      content={() => (
        <form method="POST" onSubmit={onHandleSubmit}>
          <StepsHeader
            maxSteps={5}
            step={3}
            subtitle="This is your opportunity to drive your reviewers to show certain products or features that you want to highlight."
            title={
              <div>
                <strong>Set your</strong> GiG <strong>Rules</strong>
              </div>
            }
            tooltip={
              <>
                <p>this is what your gig will look like in franki, be sure its enticing!</p>
                <figure>
                  <img src={gigPreviewImg} alt="" />
                </figure>
              </>
            }
            tooltipTitle="gig details"
          />

          <div className="BusinessGigCreateThirdStep__Content">
            <GigPackageCard {...activeGig} key={gigType} active />

            <div className="BusinessGigCreateThirdStep__Content__Main">
              <fieldset>
                <legend>
                  Add up to 4 additional rules for your GiG.
                  <span className="required-asterisk">*</span>
                </legend>

                <span className="label">
                  Users must follow these rules in order to qualify to win gig rewards, the first
                  rule is required.
                </span>

                <GigRules
                  onChange={(value) => onHandleChange('rules', value)}
                  rules={rules}
                  removeRule={removeRule}
                  gigType={gigType}
                  dirty={dirty}
                />
              </fieldset>
            </div>
          </div>

          <div className="buttons-actions">
            <button
              type="button"
              className="button small button--outline"
              onClick={(e) => onHandleBack(e)}
            >
              BACK
            </button>

            <button
              type="submit"
              className="button small button--primary"
              disabled={dirty && !isValid}
            >
              NEXT
            </button>
          </div>
        </form>
      )}
      expectNoData
    />
  );
}

BusinessGigCreateThirdStep.propTypes = {
  gigId: number,
  gigType: string.isRequired,
  onHandleBack: func.isRequired,
  onHandleChange: func.isRequired,
  onHandleNextStep: func.isRequired,
  rules: arrayOf(GIG_RULE),
};

BusinessGigCreateThirdStep.defaultProps = {
  gigId: null,
  rules: [],
};

export default BusinessGigCreateThirdStep;
