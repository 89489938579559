import { uniqueId } from 'lodash';

import { APIRequest, req } from '../utils/api';

export const CLEAR_USERS = 'CLEAR_USERS';
export const GET_USERS_CORPORATE_GROUP = 'GET_USERS_CORPORATE_GROUP';
export const GET_USERS_DETAILS = 'GET_USERS_DETAILS';
export const SEARCH_USERS = 'SEARCH_USERS';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_ERROR = 'SEARCH_USERS_ERROR';

export function getUsers(data) {
  return req({
    uri: 'UserAdmin/UserAdminGetUserList',
    method: 'POST',
    data,
  });
}

export async function updateUserAdmin(data) {
  const result = await APIRequest({
    uri: 'UserAdmin/UpdateProfile',
    method: 'POST',
    data,
  })
    .then(res => res)
    .catch(err => ({
      Message: err,
      Success: false,
    }));

  return result;
}

export function updateProfile(data) {
  return req({
    baseApiUri: '/Api',
    uri: 'V3/UserProfile/UpdateProfile',
    method: 'POST',
    data,
  });
}

export function getGroupList(data) {
  return async (dispatch) => {
    await APIRequest({
      uri: 'UserAdmin/UserAdminGetCorporateGroupList',
      method: 'POST',
      data,
    })
      .then(res => dispatch({
        type: GET_USERS_CORPORATE_GROUP,
        payload: {
          data: res.Data,
        },
      }))
      .catch(error => dispatch({
        type: GET_USERS_CORPORATE_GROUP,
        payload: { error },
      }));
  };
}

export async function createUser(data) {
  const result = await APIRequest({
    uri: 'UserAdmin/CreateUser',
    method: 'POST',
    data,
  })
    .then(res => res)
    .catch(err => ({
      Message: err,
      Success: false,
    }));

  return result;
}

export function getUserDetails(username) {
  return async (dispatch) => {
    await APIRequest({
      uri: `UserAdmin/GetUserProfile?username=${username}`,
      method: 'GET',
    })
      .then((res) => {
        dispatch({
          type: GET_USERS_DETAILS,
          payload: {
            data: res.Data,
          },
        });
      })
      .catch(error => dispatch({
        type: GET_USERS_DETAILS,
        payload: { error },
      }));
  };
}

export function getUserRoleList() {
  return req({
    uri: 'V2/UserAdmin/GetSecurityGroupList',
    method: 'GET',
  });
}

export function searchUsers(searchTerm) {
  return async (dispatch) => {
    const token = uniqueId();
    dispatch({ type: SEARCH_USERS, token });
    await APIRequest({
      baseApiUri: '/Api',
      uri: `SearchUsers/ByFullnameAndDisplayName?searchTerm=${searchTerm}&pageSize=20`,
      method: 'GET',
    })
      .then(({ Data }) => dispatch({
        type: SEARCH_USERS_SUCCESS,
        payload: { data: Data },
        token,
      }))
      .catch(error => dispatch({
        type: SEARCH_USERS_ERROR,
        payload: { error },
        token,
      }));
  };
}

export function clearUsers() {
  return async (dispatch) => {
    const token = uniqueId();
    dispatch({ type: CLEAR_USERS, token });
  };
}

export function deleteUser(userId) {
  return req({
    baseApiUri: '/Api',
    uri: `Users/${userId}`,
    method: 'DELETE',
  });
}

export function getProfile(userId) {
  return req({
    baseApiUri: '/Api',
    uri: `UserProfile/GetProfile?userId=${userId}`,
    method: 'GET',
  });
}

export function getUserProfile(userName) {
  return req({
    uri: `UserAdmin/GetUserProfile?username=${userName}`,
    method: 'GET',
  });
}

export function getMyProfile() {
  return req({
    uri: 'Profile',
    method: 'GET',
  });
}

export function saveMyProfile(data) {
  return req({
    uri: 'Profile',
    method: 'PATCH',
    data,
  });
}

export function postUserPhoto(photo) {
  return req({
    baseApiUri: '/Api',
    uri: 'Photo/PostUserPhoto?isProfile=true',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: { photo },
  });
}

export function changePassword(ivBase64, data) {
  return req({
    headers: { IVBase64: ivBase64 },
    baseApiUri: '/Api',
    uri: 'Authentication/ChangePassword',
    method: 'POST',
    data,
  });
}

export function identifierIsAvailable(identifier) {
  return req({
    baseApiUri: '/Api',
    uri: `Authentication/IdentifierIsAvailable?identifier=${identifier}`,
    method: 'POST',
    data: {},
  });
}
