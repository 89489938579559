import './index.sass';

import { bool, func, number, oneOfType } from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { verifyBusinessManager } from '../../../../actions/businesses';
import defaultBusinessLogo from '../../../../assets/images/default-business-logo.png';
import { Col, Row } from '../../../../components/Grid';
import HamburgerMenu from '../../../../components/HamburgerMenu';
import LightBox from '../../../../components/LightBox';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../../config/pagination';
import USER_ROLES from '../../../../config/user-roles';
import { BUSINESS_DETAILS_TYPE, GOOGLE_BUSINESS_DETAIL_TYPE, NEW_BUSINESS_DETAILS_TYPE } from '../../../../types/business';
import getCurrentQuery from '../../../../utils/getCurrentQuery';

const getDropdownPosition = (index) => {
  const currentQuery = getCurrentQuery();
  switch (currentQuery) {
    case 'lg':
      return index % 4 === 3 ? 'Left' : 'Right';
    case 'md':
      return index % 3 === 2 ? 'Left' : 'Right';
    case 'sm':
      return index % 2 === 1 ? 'Left' : 'Right';
    default:
      return 'Left';
  }
};

const BusinessCard = (props) => {
  const { index, item, isThirdPartyBusiness, onSuccess = () => { } } = props;

  const { BusinessManagerVerificationStatus } = item;
  const businessId = item.BusinessId;
  const displayName = isThirdPartyBusiness ? item.Displayname : item.DisplayName;
  const businessType = item.BusinessType && item.BusinessType.Name ? item.BusinessType.Name : '';
  const logo = item.Logo || defaultBusinessLogo;
  const isThereAddress = !!item.Address;
  const userRole = localStorage.getItem('frankiRole');
  const [businessManagerVerificationStatus, setBusinessManagerVerificationStatus] = useState(
    BusinessManagerVerificationStatus,
  );
  const [showVerifyDialog, setShowVerifyDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getVerificationStatus = (status) => {
    let statusText = 'Unknown';
    if (status === 0) {
      statusText = 'Not Verified';
    } else if (status === 1) {
      statusText = 'Verified';
    } else if (status === 2) {
      statusText = 'Pending';
    } else if (status === 3) {
      statusText = 'Rejected';
    }
    return statusText;
  };

  const getMenuItems = (selectedBusinessId) => {
    const menuItems = [
      {
        innerContent: 'Edit Profile',
        url: `/admin/business-profile/${selectedBusinessId}`,
      },
      {
        innerContent: 'Posts',
        url: `/admin/business-videos/${selectedBusinessId}/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`,
      },
      {
        innerContent: 'GiGs',
        url: `/admin/business-gigs/${selectedBusinessId}/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`,
      },
      {
        innerContent: 'Hashtags',
      },
      {
        innerContent: 'Managers',
      },
      {
        innerContent: 'Deactivate',
      },
      {
        innerContent: `ID: ${selectedBusinessId}`,
        hasTopBorder: true,
      },
    ];

    if (userRole === USER_ROLES.admin) {
      menuItems.push({
        innerContent: 'Verify Business',
        action: () => {
          setShowVerifyDialog(true);
        },
      });
    }
    return menuItems;
  };

  const submitVerificationStatus = (status) => {
    verifyBusinessManager(businessId, status).subscribe(
      () => {
        setShowVerifyDialog(false);
        setBusinessManagerVerificationStatus(status === 1 ? status : 3);
        onSuccess(1);
      },
      (error) => {
        setShowVerifyDialog(false);
        setTimeout(() => {
          setErrorMessage(error);
        }, 500);
      },
    );
  };

  return (
    <div className="BusinessCard">
      <HamburgerMenu
        dropdownPosition={getDropdownPosition(index)}
        menuItems={getMenuItems(businessId)}
      />
      <Link
        to={{
          pathname: `/admin/business-profile/${businessId}`,
          state: { data: item, businessManagerVerificationStatus },
        }}
      >
        <div className="BusinessCard__Header">
          <figure>
            <img alt={displayName} src={logo} />
          </figure>

          <h3 className="BusinessCard__Subtitle">{businessType}</h3>
          <h2 className="BusinessCard__Title">{displayName}</h2>
        </div>

        <div className="BusinessCard__Content">
          <address className="BusinessCard__Address">
            {isThereAddress && <i className="icon-location" />}

            <span className="BusinessCard__Address__Location">
              {isThereAddress && (
                <Row>
                  <Col lg={item.State ? 6 : 12} md={item.State ? 6 : 12} sm={item.State ? 6 : 12} xs={item.State ? 6 : 12}>
                    <span className="BusinessCard__Address__Location__Description">
                      {item.Address}
                    </span>
                  </Col>

                  <Col lg={6} md={6} sm={6} xs={6} show={!!item.State}>
                    <span className="BusinessCard__Address__Location__Description">
                      <strong>{item.State && ` | ${item.State.Name}`}</strong>
                    </span>
                  </Col>
                </Row>
              )}

              {!item.Address && item.State && (
                <>
                  {'available: '}
                  <strong>{item.State.Name}</strong>
                </>
              )}
            </span>
          </address>
        </div>

        <div
          className={`BusinessCard__Footer ${item.TotalActiveGigs > 0 ? 'BusinessCard__Footer__Gigs' : ''
          }`}
        >
          {item.TotalActiveGigs > 0 && (
            <span className="BusinessCard__Footer__ActiveGigs">
              <strong>{item.TotalActiveGigs}</strong>
              {' active gigs'}
            </span>
          )}
          {item.TotalActiveGigs === 0 && (
            <span className="BusinessCard__Footer__ActiveGigs" style={{ visibility: 'hidden' }} />
          )}
          <span
            className={`BusinessCard__Footer__ActiveGigs status${isThirdPartyBusiness ? businessManagerVerificationStatus : item.VerificationStatus}`}
          >
            <strong>{isThirdPartyBusiness ? getVerificationStatus(businessManagerVerificationStatus) : item.VerificationStatus}</strong>
          </span>
        </div>
      </Link>
      <LightBox
        style={{
          width: 600,
          height: null,
          marginLeft: -250,
          marginTop: -200,
        }}
        onClose={() => setShowVerifyDialog(false)}
        show={showVerifyDialog}
        title="Business Manager Verification"
      >
        <>
          <div className="GigSubmissions__AwardConfirm">
            <span className="GigSubmissions__AwardConfirm__Description">
              Click `Verify` or `Reject` to update verification status of the selected business
            </span>

            <div className="GigSubmissions__AwardConfirm__Actions">
              <button
                onClick={() => submitVerificationStatus(0)}
                className="button red-theme small"
                type="button"
              >
                Reject
              </button>

              <button
                onClick={() => submitVerificationStatus(1)}
                type="button"
                className="button green-theme small"
              >
                Verify
              </button>
            </div>
          </div>
        </>
      </LightBox>
      <LightBox
        style={{
          width: 600,
          height: null,
          marginLeft: -300,
          marginTop: -300,
        }}
        onClose={() => setErrorMessage('')}
        show={errorMessage != ''}
        title="Error"
      >
        <div className="AsyncContent__ErrorMessage">{errorMessage}</div>
      </LightBox>
    </div>
  );
};

BusinessCard.propTypes = {
  item: oneOfType([GOOGLE_BUSINESS_DETAIL_TYPE, BUSINESS_DETAILS_TYPE, NEW_BUSINESS_DETAILS_TYPE]).isRequired,
  isThirdPartyBusiness: bool.isRequired,
  index: number.isRequired,
  onSuccess: func.isRequired,
};

export default BusinessCard;

const x = { Monday: [{ OpeningTime: '08:00', ClosingTime: '22:00' }], Tuesday: [{ OpeningTime: '08:00', ClosingTime: '22:00' }], Wednesday: [{ OpeningTime: '08:00', ClosingTime: '22:00' }], Thursday: [{ OpeningTime: '08:00', ClosingTime: '22:00' }], Friday: [{ OpeningTime: '08:00', ClosingTime: '22:00' }], Saturday: [{ OpeningTime: '08:00', ClosingTime: '22:00' }] };

console.log('🚀 ~ file: index.jsx ~ line 254 ~ x', x);
