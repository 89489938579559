import './index.sass';

import { bool } from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
// import menu from '../../assets/images/menu.svg';
import LoggedHeader from './Logged';
import UnloggedHeader from './Unlogged';

const Header = ({ auth, global }) => (
  <header className={`${auth ? 'Header' : 'Header UnloggedHeader'}`}>
    <div className="wrapper Header__Container">
      {!auth ? (
        <>

          <Link to="/" className="Header__Logo">
            {/* <img src={menu} alt="" className="Header__IconMenu" /> */}
            <img src={logo} alt="Franki Logo" />
          </Link>

          <UnloggedHeader global={global} />
        </>
      ) : (
        <>
          <Link to="/" className="Header__Logo">
            <img src={logo} alt="Franki Logo" />
          </Link>


          <LoggedHeader />
        </>
      )}
    </div>
  </header>
);

Header.propTypes = {
  auth: bool.isRequired,
  global: bool,
};

Header.defaultProps = {
  global: false,
};

export default Header;
