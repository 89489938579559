import { bool } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import MasterPage from '../../components/MasterPage';

const Home = ({ auth }) => (
  <>
    <Helmet title="Franki Admin: Home" />

    <MasterPage auth={auth}>
      <p>home page</p>
    </MasterPage>
  </>
);

Home.propTypes = {
  auth: bool.isRequired,
};

export default Home;
