import { bool, func, node, number, string } from 'prop-types';
import React from 'react';
import { ReactComponent as IconCheck } from '../../../../../assets/images/checked.svg';
import './index.sass';

export default function GigPackageCard(props) {
  const { onChange, active, type, description, info, value } = props;

  return (
    <button
      className={`GigPackageCard ${active ? 'GigPackageCard--active' : ''}`}
      onClick={() => onChange && onChange(type)}
      type="button"
    >
      <div>
        <div className="GigPackageCard__Type">{type}</div>

        <div className="MiddleGroup">
          <div className="GigPackageCard__Check">
            <IconCheck />
          </div>
          <div className="GigPackageCard__Value">${value || '--'}</div>

          {info ? <div className="GigPackageCard__Info">{info}</div> : null}
        </div>
      </div>

      <div className="GigPackageCard__Description">{description}</div>
    </button>
  );
}

GigPackageCard.propTypes = {
  active: bool.isRequired,
  description: string.isRequired,
  info: node,
  onChange: func,
  type: string.isRequired,
  value: number,
};

GigPackageCard.defaultProps = {
  onChange: null,
  info: null,
  value: null,
};
