import './index.sass';
import { func, object, array, oneOfType } from 'prop-types';
import React, { useMemo, useEffect } from 'react';
import Toggle from 'react-toggle';


const Payment = (props) => {
  const {
    formState: { BusinessOption },
    businessOptionParamaters,
    onHandleChange,
  } = props;

  const { Options = [] } = businessOptionParamaters.find(item => item.Name === 'Payment') || {};

  const isCashOnly = useMemo(() => (BusinessOption && BusinessOption.CashOnly), [BusinessOption]);

  useEffect(() => {
    if (isCashOnly) {
      Options.forEach(({ Name }) => {
        if (Name !== 'CashOnly') onHandleChange(Name, false);
      });
    }
  }, [isCashOnly]);

  return (
    <div className="Payment">
      <h4 className="BusinessOptions__Title">
        Payment options
      </h4>
      <div className="Payment__Grid">
        {Options.map(({ DisplayString, Name }) => (
          <label key={Name} className="mb-20 display-flex" htmlFor={`txt${Name}`}>
            <p className="BusinessOptions__Label">{DisplayString}</p>
            <Toggle
              checked={BusinessOption[Name]}
              icons={false}
              onChange={() => onHandleChange(Name, !BusinessOption[Name])}
              disabled={isCashOnly && Name !== 'CashOnly'}
            />
          </label>
        ))}
      </div>
    </div>
  );
};

Payment.propTypes = {
  formState: oneOfType([object]),
  businessOptionParamaters: oneOfType([array]),
  onHandleChange: func.isRequired,
};

Payment.defaultProps = {
  formState: {},
  businessOptionParamaters: null,
};

export default Payment;
