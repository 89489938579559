import React from 'react';
import { NavLink } from 'react-router-dom';

import { logout } from '../../../actions/auth';
import { ReactComponent as BusinessIcon } from '../../../assets/images/business.svg';
import { ReactComponent as PromotedContentIcon } from '../../../assets/images/content.svg';
import { ReactComponent as BusinessPromotedIcon } from '../../../assets/images/promotion.svg';
import { ReactComponent as FlaggedContentIcon } from '../../../assets/images/flag.svg';
import { ReactComponent as GigsIcon } from '../../../assets/images/gigs.svg';
import { ReactComponent as PostsIcon } from '../../../assets/images/posts.svg';
import { ReactComponent as UserIcon } from '../../../assets/images/user.svg';
import { ReactComponent as CameraIcon } from '../../../assets/images/camera.svg';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../config/pagination';
import USER_ROLES from '../../../config/user-roles';
import HamburgerMenu from '../../HamburgerMenu';
import HamburgerMenuIcon from '../../HamburgerMenu/HamburgerMenuIcon';

/* global localStorage, window */
const LoggedHeader = () => {
  const userRole = localStorage.getItem('frankiRole');

  const onLogout = () => {
    logout().subscribe(() => {
      localStorage.removeItem('frankiRole');
      localStorage.removeItem('frankiToken');
      localStorage.removeItem('frankiUser');
      localStorage.removeItem('frankiName');
      window.location.href = '/';
    });
  };

  const getRoleParamsForFC = () => {
    if (userRole === USER_ROLES.contentAdmin) return '&contentStatusIds=1,2';
    if (userRole === USER_ROLES.saleAdmin) return '&contentStatusIds=3,4';
    if (userRole === USER_ROLES.admin) return '&contentStatusIds=5,6';
    return '';
  };

  const IsActive = pathname => window.location.pathname.includes(pathname);

  return (
    <nav className="Header__Nav">
      <ul>
        {
          <>
            {[USER_ROLES.admin].includes(userRole) && (
              <li>
                <NavLink
                  to="/admin/creator-fund"
                  className="Header__Link"
                  activeClassName="Header__Link--Active"
                  isActive={() => IsActive('/admin/creator-fund')}
                >
                  <CameraIcon width="18" />
                  <span className="Header__Nav__Name">Locals Fund</span>
                </NavLink>
              </li>
            )}
            {[USER_ROLES.admin, USER_ROLES.saleAdmin].indexOf(userRole) > -1 && (
              <li>
                <NavLink
                  to={`/admin/gigs/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}?orderBy=0`}
                  className="Header__Link"
                  activeClassName="Header__Link--Active"
                  isActive={() => IsActive('/admin/gigs/')}
                >
                  <GigsIcon width="20" />
                  <span className="Header__Nav__Name">All GiGs</span>
                </NavLink>
              </li>
            )}
            {[USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.contentAdmin].indexOf(userRole)
              > -1 && (
              <li>
                <NavLink
                  to={`/admin/flagged-contents/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}?orderBy=LastFlaggedOn${getRoleParamsForFC()}`}
                  className="Header__Link"
                  activeClassName="Header__Link--Active"
                  isActive={() => IsActive('/admin/flagged-contents/')}
                >
                  <FlaggedContentIcon width="20" />
                  <span className="Header__Nav__Name">Flagged Content</span>
                </NavLink>
              </li>
            )}
            {[USER_ROLES.admin, USER_ROLES.saleAdmin].indexOf(userRole) > -1 && (
              <li>
                <NavLink
                  to={`/admin/businesses/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}?orderBy=recentlyCreated`}
                  className="Header__Link"
                  activeClassName="Header__Link--Active"
                  isActive={() => IsActive('/admin/businesses/')}
                >
                  <BusinessIcon width="15" height="14" />
                  <span className="Header__Nav__Name">All Businesses</span>
                </NavLink>
              </li>
            )}

            {[USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.contentAdmin].indexOf(userRole)
              > -1 && (
              <li>
                <NavLink
                  to={`/admin/videos/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}?sortingType=1&businessSource=FrankiLocalBusiness${getRoleParamsForFC()}`}
                  className="Header__Link"
                  activeClassName="Header__Link--Active"
                  isActive={() => IsActive('/admin/videos/')}
                >
                  <PostsIcon width="15" height="14" />
                  <span className="Header__Nav__Name">All Posts</span>
                </NavLink>
              </li>
            )}
            {[USER_ROLES.admin, USER_ROLES.saleAdmin].indexOf(userRole) > -1 && (
            <li>
              <NavLink
                to="/business-promotions"
                className="Header__Link"
                activeClassName="Header__Link--Active"
                isActive={() => IsActive('/business-promotions')}
              >
                <BusinessPromotedIcon width="14" />
                <span className="Header__Nav__Name">Content Promotions</span>
              </NavLink>
            </li>
            )}
            {[USER_ROLES.admin, USER_ROLES.saleAdmin].indexOf(userRole) > -1 && (
              <li>
                <NavLink
                  to={`/admin/promoted-content/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}?orderBy=Name`}
                  className="Header__Link"
                  activeClassName="Header__Link--Active"
                  isActive={() => IsActive('/admin/promoted-content/')}
                >
                  <PromotedContentIcon width="18" />
                  <span className="Header__Nav__Name">Promoted Content</span>
                </NavLink>
              </li>
            )}
          </>
        }

        {userRole === USER_ROLES.admin && (
          <>
            <li>
              <NavLink
                to={`/admin/users/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}?orderBy=1`}
                className="Header__Link"
                activeClassName="Header__Link--Active"
                isActive={() => IsActive('/admin/users/')}
              >
                <UserIcon width="20" />
                <span className="Header__Nav__Name">User Management</span>
              </NavLink>
            </li>
          </>
        )}
      </ul>

      <HamburgerMenu
        className="Header__User"
        icon="icon-user"
        menuItems={[
          // {
          //   innerContent: (
          //     <>
          //       <HamburgerMenuIcon icon="icon-settings" />
          //       settings
          //     </>
          //   ),
          //   url: '/admin/settings',
          // },
          {
            innerContent: (
              <>
                <HamburgerMenuIcon icon="icon-user" />
                profile
              </>
            ),
            url: '/admin/profile',
          },
          {
            innerContent: (
              <button onClick={() => onLogout()} type="button">
                <HamburgerMenuIcon icon="icon-signout" />
                logout
              </button>
            ),
          },
        ]}
      />
    </nav>
  );
};

export default LoggedHeader;
