import './index.sass';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { StandaloneSearchBox } from '@react-google-maps/api';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import useFormField from '../../../hooks/useFormField';
import { Col, Row } from '../../../components/Grid';
import BUSINESS_TYPES from '../../../config/business-types';
import CustomSelect from '../../../components/CustomSelect';
import AlertMessages from '../../../components/AlertMessages';
import {
  assignUserAsBusinessManager,
  createClaimBusinessProfile,
} from '../../../actions/businesses';
import LightBox from '../../../components/LightBox';
import ProgressBar from '../../../components/ProgressBar';
import googleReCaptchaHoc from '../../../hoc/googleReCaptchaHoc';
import { logout } from '../../../actions/auth';
import shopPurplePng from '../../../assets/images/shop-purple.png';
import { getBlob } from '../../../utils/api';

/* global localStorage */

const BusinessProfileCreate = () => {
  // #region component variables
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [businessProfile, setBusinessProfile] = useState(null);
  const [isDialogVisible, setIsDialogVisible] = useState(null);
  const [isUserLoginDialogVisible, setIsUserLoginDialogVisible] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formError, setFormError] = useState({});
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();
  // #endregion component variables

  // #region form variables
  const [businessType, setBusinessType] = useState(null);
  const businessCity = useFormField('');
  const businessName = useFormField('');
  const [businessAddress, setBusinessAddress] = useState(null);
  const [businessAddressLoad, setBusinessAddressLoad] = useState(null);
  const [businessLat, setBusinessLat] = useState(null);
  const [businessLng, setBusinessLng] = useState(null);
  const businessState = useFormField('');
  const businessCountry = useFormField('');
  const businessZipCode = useFormField('');
  // const [captchaToken, setCaptchaToken] = useState(null);
  const businessCityRef = useRef(null);
  const businessStateRef = useRef(null);
  const businessCountryRef = useRef(null);
  const businessZipCodeRef = useRef(null);
  // #endregion form variables

  const formValidator = () => {
    if (
      !businessName.value ||
      !businessType ||
      !businessAddress ||
      !businessCity.value ||
      !businessState.value ||
      !businessCountry.value ||
      !businessZipCode.value
    ) {
      return {
        businessName: !businessName.value && 'Required',
        businessType: !businessType && 'Required',
        businessAddress: !businessAddress && 'Required',
        businessCity: !businessCity.value && 'Required',
        businessState: !businessState.value && 'Required',
        businessCountry: !businessCountry.value && 'Required',
        businessZipCode: !businessZipCode.value && 'Required',
        alert:
          'Please complete all of the required fields, in order to create the business profile.',
      };
    }

    return {
      alert: '',
    };
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const formValidation = formValidator();
    setFormError(formValidation);
    setSubmitted(true);
  };

  const onBusinessTypeChangeHandler = ({ value: updatedValue }) => {
    const updatedBusinessType = BUSINESS_TYPES.find(({ value }) => value === updatedValue);
    if (updatedBusinessType) {
      setBusinessType(updatedBusinessType);
    } else {
      setBusinessType(null);
    }
  };

  const onAddressPlacesChange = () => {
    if (!businessAddressLoad || !businessAddressLoad.getPlaces()[0]) {
      return;
    }

    const place = businessAddressLoad.getPlaces()[0];
    setBusinessLat(place.geometry.location.lat());
    setBusinessLng(place.geometry.location.lng());
    if (place.formatted_address) {
      setBusinessAddress(place.formatted_address);
    }

    if (place.address_components) {
      let newCity = '';
      let newState = '';
      let newCountry = '';
      let newZipCode = '';
      place.address_components.forEach((item) => {
        if (item.types.includes('country')) {
          newCountry = item.long_name;
        }
        if (item.types.includes('administrative_area_level_1')) {
          newState = item.long_name;
        }
        if (item.types.includes('locality')) {
          newCity = item.long_name;
        }
        if (item.types.includes('postal_code')) {
          newZipCode = item.long_name;
        }
      });

      businessCity.forceValue(newCity);
      businessState.forceValue(newState);
      businessCountry.forceValue(newCountry);
      businessZipCode.forceValue(newZipCode);

      if (!newCity) {
        businessCityRef.current.focus();
      } else if (!newState) {
        businessStateRef.current.focus();
      } else if (!newCountry) {
        businessCountryRef.current.focus();
      } else if (!newZipCode) {
        businessZipCodeRef.current.focus();
      }
    }
  };

  const goToSignUp = () => {
    if (!businessProfile) return;
    const { BusinessId } = businessProfile;
    history.push({
      pathname: `/sign-up/${BusinessId}`,
      state: {
        businessId: BusinessId,
        thirdPartySourceId: null,
        nonFourSquare: true,
        profileCreated: true,
      },
    });
  };

  const onCreateNewUser = () => {
    logout().subscribe(() => {
      localStorage.removeItem('frankiRole');
      localStorage.removeItem('frankiToken');
      localStorage.removeItem('frankiUser');
      localStorage.removeItem('frankiName');
      goToSignUp();
    });
  };

  const onUseCurrentUser = () => {
    const { BusinessId } = businessProfile;
    assignUserAsBusinessManager({ BusinessId }, true).subscribe(
      ({ BusinessId: id }) => {
        history.push({
          pathname: `/business-phone-verification/${id}`,
          state: { businessId: id, hideStep: true, nonFourSquare: true },
        });
      },
      () => {
        history.push({
          pathname: `/business-phone-verification/${BusinessId}`,
          state: {
            businessId: BusinessId,
            hideStep: true,
            nonFourSquare: true,
          },
        });
      },
    );
  };

  const getSubmissionData = async () => {
    const imgValue = await getBlob(shopPurplePng);
    return {
      Name: businessName.value,
      TypeId: businessType.value,
      Address: businessAddress,
      City: businessCity.value,
      State: businessState.value,
      Country: businessCountry.value,
      ZipCode: businessZipCode.value,
      Latitude: businessLat,
      Longitude: businessLng,
      Logo: imgValue,
    };
  };

  const submitForm = async (token) => {
    setIsLoading(true);
    const reqData = await getSubmissionData();
    reqData.CaptchaToken = token;
    const postReq = () => {
      setSubmitted(false);
      setIsLoading(false);
    };
    createClaimBusinessProfile(reqData).subscribe(
      (response) => {
        setBusinessProfile(response);
        postReq();
      },
      (err) => {
        setErrorMessage(err);
        setIsDialogVisible(true);
        postReq();
      },
    );
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha || isLoading) {
      return;
    }
    const token = await executeRecaptcha('createBusinessProfile');
    // setCaptchaToken(token);
    submitForm(token);
  }, [executeRecaptcha, getSubmissionData, isLoading]);

  useEffect(() => {
    if (submitted && !formError.alert && !isLoading) {
      handleReCaptchaVerify();
    }
  }, [submitted, formError, handleReCaptchaVerify]);

  useEffect(() => {
    setSubmitted(false);
    if (businessProfile) setBusinessProfile(null);
  }, [
    businessType,
    businessCity.value,
    businessName.value,
    businessAddress,
    businessState.value,
    businessCountry.value,
    businessZipCode.value,
  ]);

  useEffect(() => {
    if (businessProfile && !isLoading) {
      if (!localStorage.getItem('frankiToken')) {
        goToSignUp();
      } else {
        setIsUserLoginDialogVisible(true);
      }
    }
  }, [businessProfile, isLoading]);

  return (
    <div>
      <Helmet title="Franki Admin: Create Business Profile" />
      <Header auth={false} global />
      <AlertMessages
        className="BusinessGigCreateSuccessfully__AlertMessages"
        show={submitted && formError.alert}
        type="error"
        message={formError.alert}
      />
      <main className="container display-grid BusinessProfileCreate">
        <ProgressBar className="BusinessProfileCreate__ProgressBar" totalSteps={5} activeStep={1} />

        <div className="BusinessProfileCreate__Info">
          <h1 className="title-h1 col-12">Alright, let’s create your business profile</h1>
          <p className="subtitle col-12">
            Add your business information below.
            <br />
            Note: users will use this information to find and connect with your business on franki.
          </p>
        </div>
        <form className="BusinessProfileCreate__Form" onSubmit={onSubmitHandler}>
          <Row cellSpacing={15} rowSpacing={10}>
            <Col lg={6} md={6} sm={12}>
              <label htmlFor="businessName">
                Business name
                <span className="required-asterisk">*</span>
                &nbsp;
                {submitted && formError.businessName}
              </label>
              <input
                autoComplete="none"
                className="full"
                name="businessProfileCreateName"
                maxLength={60}
                disabled={isLoading}
                placeholder="type your business name..."
                type="text"
                {...businessName}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <label htmlFor="businessType">
                Business type
                <span className="required-asterisk">*</span>
                &nbsp;
                {submitted && formError.businessType}
              </label>
              <CustomSelect
                className="full"
                name="businessProfileCreateType"
                disabled={isLoading}
                showDisabledValue
                onChange={onBusinessTypeChangeHandler}
                options={BUSINESS_TYPES}
                placeholder="select your business type..."
                value={businessType}
              />
            </Col>
            <Col lg={12} md={12} sm={12}>
              <label htmlFor="businessAddress">
                Business address
                <span className="required-asterisk">*</span>
                &nbsp;
                {submitted && formError.businessAddress}
              </label>
              <div className="SearchBoxWrapper">
                <StandaloneSearchBox
                  onLoad={setBusinessAddressLoad}
                  onPlacesChanged={onAddressPlacesChange}
                >
                  <input
                    type="text"
                    placeholder="type your business address..."
                    autoComplete="none"
                    name="businessProfileCreateAddress"
                    disabled={isLoading}
                  />
                </StandaloneSearchBox>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <label htmlFor="businessCity">
                City
                <span className="required-asterisk">*</span>
                &nbsp;
                {submitted && formError.businessCity}
              </label>
              <input
                autoComplete="none"
                className="full"
                name="businessProfileCreateCity"
                disabled={isLoading}
                type="text"
                ref={businessCityRef}
                {...businessCity}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <label htmlFor="businessState">
                State
                <span className="required-asterisk">*</span>
                &nbsp;
                {submitted && formError.businessState}
              </label>
              <input
                autoComplete="none"
                className="full"
                name="businessProfileCreateState"
                disabled={isLoading}
                type="text"
                ref={businessStateRef}
                {...businessState}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <label htmlFor="businessCountry">
                Country
                <span className="required-asterisk">*</span>
                &nbsp;
                {submitted && formError.businessCountry}
              </label>
              <input
                autoComplete="none"
                className="full"
                name="businessProfileCreateCountry"
                disabled={isLoading}
                type="text"
                ref={businessCountryRef}
                {...businessCountry}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <label htmlFor="businessZipCode">
                Zip code
                <span className="required-asterisk">*</span>
                &nbsp;
                {submitted && formError.businessZipCode}
              </label>
              <input
                autoComplete="none"
                className="full"
                name="businessProfileCreateZipCode"
                disabled={isLoading}
                type="text"
                ref={businessZipCodeRef}
                {...businessZipCode}
              />
            </Col>
          </Row>
          <div className="BusinessProfileCreate__Form__Controls">
            <button className="button orange-theme" disabled={isLoading} type="submit">
              {isLoading ? 'loading...' : 'Create Profile'}
            </button>
          </div>
        </form>
        <div className="wrapper-go-back">
          <button onClick={history.goBack} className="button button-go-back" type="button">
            BACK
          </button>
        </div>

        <LightBox
          onClose={() => setIsDialogVisible(false)}
          show={isDialogVisible}
          title="Something went wrong!"
          style={{
            width: 600,
            height: null,
            marginLeft: -300,
            marginTop: -200,
          }}
        >
          <div className="LightBox-text">
            <p>{errorMessage}</p>
            <p className="mb-5">
              If you need help please{' '}
              <Link to="/contact-sales-force" className="orange-text">
                contact support.
              </Link>
            </p>
          </div>
        </LightBox>
        <LightBox
          onClose={() => setIsUserLoginDialogVisible(false)}
          show={isUserLoginDialogVisible}
          title="It looks like you are already logged in"
          style={{
            width: 600,
            height: null,
            marginLeft: -300,
            marginTop: -200,
          }}
        >
          <div className="LightBox-text">
            <p>Would you like to use this email for sign up or create a new user?</p>

            <div>
              <button type="button" className="button small orange-theme" onClick={onCreateNewUser}>
                CREATE
              </button>
              <button type="button" className="button small gray-theme" onClick={onUseCurrentUser}>
                USE EMAIL
              </button>
            </div>
            <p className="mb-5">
              If you are unsure please{' '}
              <Link to="/contact-sales-force" className="orange-text">
                contact support.
              </Link>
            </p>
          </div>
        </LightBox>
      </main>
      <Footer className="position-bottom" />
    </div>
  );
};

BusinessProfileCreate.propTypes = {};

export default googleReCaptchaHoc(BusinessProfileCreate);
