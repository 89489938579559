import './index.sass';

import { arrayOf, func, number, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import uuidv1 from 'uuid/v1';

import gigPreviewImg from '../../../../../assets/images/gig_preview.png';
import AsyncContent from '../../../../../components/AsyncContent';
import PaymentBox from '../../../../../components/PaymentBox';
import StepsHeader from '../../../../../components/StepsHeader';
import PRIZE_TYPE from '../../../../../types/prize';
import GigPackageCard from '../GigPackageCard';
import GIG_PACKAGES from '../GigPackages';

const prizesField = [5, 10, 15, 20, 25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];
const winnersField = [1, 5, 10, 15, 20, 25];

function BusinessGigCreateFourthStep(props) {
  const { prizes, onHandleBack, onHandleChange, onHandleNextStep, gigType } = props;

  const activeGig = useMemo(() => GIG_PACKAGES.find((item) => item.type === gigType), [gigType]);

  const updatePrize = useCallback(
    (id, name, value) => {
      onHandleChange(
        'prizes',
        prizes.map((item) => {
          if (item.GigPrizeId === id) {
            return {
              ...item,
              [name]: value,
            };
          }

          return item;
        }),
      );
    },
    [onHandleChange, prizes],
  );

  const addPrize = useCallback(() => {
    onHandleChange(
      'prizes',
      prizes.concat([
        {
          Description: '',
          Disabled: false,
          GigPrizeId: prizes.length ? prizes.slice(-1)[0].GigPrizeId + 1 : 1,
          Label: 'custom',
          MaxPrizeAmount: 500,
          MaxWinners: 25,
          MinPrizeAmount: 5,
          MinWinners: 1,
          Name: 'custom',
          Placeholder: 'Add your own prize...',
          PrizeAmount: 5,
          Removable: true,
          Required: true,
          WinnerQty: 1,
        },
      ]),
    );
  }, [onHandleChange, prizes]);

  const deletePrize = useCallback(
    (gigPrizeId) => {
      onHandleChange(
        'prizes',
        prizes.filter((item) => item.GigPrizeId !== gigPrizeId),
      );
    },
    [onHandleChange, prizes],
  );

  const onHandleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onHandleNextStep();
    },
    [onHandleNextStep],
  );

  const gigCost = useMemo(
    () =>
      prizes.length &&
      prizes.map((item) => item.PrizeAmount * item.WinnerQty).reduce((a, b) => Number(a + b)),
    [prizes],
  );

  const isThereMorePrize = useMemo(() => prizes.length < 4, [prizes]);

  useEffect(() => {
    if (!prizes || !prizes.length) {
      addPrize();
    }
  }, [prizes, addPrize]);

  return (
    <AsyncContent
      content={() => (
        <form method="POST" onSubmit={onHandleSubmit}>
          <StepsHeader
            maxSteps={5}
            step={4}
            subtitle="This is what the user receives if they win.
              Things to think about: How much does the product you are telling them to review cost?
              Do you want them to take extreme time editing the video?"
            title={
              <div>
                GiG <strong>Prizes</strong>
              </div>
            }
            tooltip={
              <>
                <p>this is what your gig will look like in franki, be sure its enticing!</p>
                <figure>
                  <img src={gigPreviewImg} alt="" />
                </figure>
              </>
            }
            tooltipTitle="gig details"
          />

          <div className="BusinessGigCreateFourthStep">
            <div className="BusinessGigCreateFourthStep__Content">
              {activeGig ? <GigPackageCard {...activeGig} key={gigType} active /> : null}

              <div className="BusinessGigCreateFourthStep__Content__Main">
                <fieldset>
                  <legend>
                    Add Prize Type
                    <span className="required-asterisk">*</span>
                  </legend>
                  <span className="helper">
                    The first prize is required, but feel free to add more to entice more users.
                  </span>

                  <div className="BusinessGigCreateFourthStep__Prizes">
                    <div className="BusinessGigCreateFourthStep__PrizeRow">
                      <div className="BusinessGigCreateFourthStep__PrizeRow__Heading">
                        Prize Name/Task
                        <span className="required-asterisk">*</span>
                      </div>
                      <div className="BusinessGigCreateFourthStep__PrizeRow__Heading">Amount</div>
                      <div className="BusinessGigCreateFourthStep__PrizeRow__Heading">Winners</div>
                      <div className="BusinessGigCreateFourthStep__PrizeRow__Heading invisible">
                        Total
                      </div>
                    </div>
                    {(prizes || []).map((item) => (
                      <div
                        className="BusinessGigCreateFourthStep__PrizeRow"
                        key={`prize${item.GigPrizeId}`}
                      >
                        <div
                          className={`${
                            item.Disabled ? 'BusinessGigCreateFourthStep--NotEditable' : ''
                          }`}
                        >
                          <input
                            autoComplete="off"
                            className="full"
                            disabled={item.Disabled}
                            id={`txtDescription${item.GigPrizeId}`}
                            maxLength={60}
                            name="Description"
                            onChange={({ target }) => {
                              if (!item.Disabled) {
                                return updatePrize(item.GigPrizeId, target.name, target.value);
                              }

                              return false;
                            }}
                            placeholder={item.Placeholder}
                            required={item.Required}
                            type="text"
                            value={item.Description}
                          />
                        </div>

                        <div className="BusinessGigCreateFourthStep__Amount">
                          <select
                            className="full"
                            id={`txtAmount${item.GigPrizeId}`}
                            name="PrizeAmount"
                            onChange={({ target }) =>
                              updatePrize(item.GigPrizeId, target.name, target.value)
                            }
                            value={item.PrizeAmount}
                          >
                            {prizesField
                              .filter(
                                (prizeField) =>
                                  prizeField >= item.MinPrizeAmount &&
                                  prizeField <= item.MaxPrizeAmount,
                              )
                              .map((prize) => (
                                <option key={uuidv1()} value={prize}>
                                  {prize}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="BusinessGigCreateFourthStep__Winners">
                          <select
                            id={`txtWinners${item.GigPrizeId}`}
                            className="full"
                            name="WinnerQty"
                            value={item.WinnerQty}
                            onChange={({ target }) => {
                              updatePrize(item.GigPrizeId, target.name, target.value);
                            }}
                          >
                            {winnersField
                              .filter(
                                (winnerField) =>
                                  winnerField >= item.MinWinners && winnerField <= item.MaxWinners,
                              )
                              .map((winner) => (
                                <option key={uuidv1()} value={winner}>
                                  {winner}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="BusinessGigCreateFourthStep__Total">
                          <span className="BusinessGigCreateFourthStep__Total__Price">
                            {`$${item.WinnerQty * item.PrizeAmount}`}
                          </span>
                          {item.Removable && item.GigPrizeId !== prizes[0].GigPrizeId && (
                            <i
                              className="icon-error BusinessGigCreateFourthStep__Delete"
                              onClick={() => deletePrize(item.GigPrizeId)}
                              onKeyPress={() => deletePrize(item.GigPrizeId)}
                              role="button"
                              tabIndex={0}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="BusinessGigCreateFourthStep__Footer">
                    <div className="BusinessGigCreateFourthStep__AddPrizeActions">
                      {isThereMorePrize && (
                        <button
                          type="button"
                          className="button small outline-theme"
                          onClick={addPrize}
                        >
                          Add custom prize
                        </button>
                      )}
                    </div>
                    <div className="BusinessGigCreateFourthStep__Sum">
                      <PaymentBox totalCostItems={gigCost} />
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>

          <div className="buttons-actions">
            <button type="button" className="button small button--outline" onClick={onHandleBack}>
              BACK
            </button>

            <button className="button small button--primary" type="submit">
              NEXT
            </button>
          </div>
        </form>
      )}
      expectNoData
    />
  );
}

BusinessGigCreateFourthStep.propTypes = {
  gigId: number,
  gigType: string.isRequired,
  onHandleBack: func.isRequired,
  onHandleChange: func.isRequired,
  onHandleNextStep: func.isRequired,
  prizes: arrayOf(PRIZE_TYPE),
};

BusinessGigCreateFourthStep.defaultProps = {
  gigId: '',
  prizes: [],
};

export default BusinessGigCreateFourthStep;
