import {
  CHARGE_GIG_PAYMENT,
  CHARGE_GIG_PAYMENT_ERROR,
  CHARGE_GIG_PAYMENT_SUCCESS,
  COMPLETE_GIG,
  CREATE_GIG_PRIZES,
  CREATE_GIG_PRIZES_ERROR,
  CREATE_GIG_PRIZES_SUCCESS,
  DELETE_GIG,
  DELETE_GIG_ERROR,
  DELETE_GIG_SUCCESS,
  GET_ALL_GIGS,
  GET_ALL_GIGS_ERROR,
  GET_ALL_GIGS_SUCCESS,
  GET_GIG_DETAILS,
  GET_GIG_DETAILS_ERROR,
  GET_GIG_DETAILS_SUCCESS,
  GET_GIG_SUBMISSIONS,
  RESET_GIG_ACTION,
  UPDATE_GIG,
  UPDATE_GIG_ERROR,
  UPDATE_GIG_SUCCESS,
} from '../actions/gigs';

const initialState = {
  allGigs: {},
  allGigsError: '',
  isFetchingGigs: false,

  chargeSuccess: '',
  chargeError: '',
  isChargingGig: false,

  submissions: [],
  submissionsError: '',

  details: {},
  detailsError: '',

  deleted: '',
  deletedError: '',
  isDeletingGig: false,

  createdGigPrizes: [],
  createGigPrizesError: '',
  isCreatingGigPrizes: false,

  complete: '',
  completeError: '',

  updateGigSuccess: '',
  updateGigError: '',
  isGigUpdating: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_ALL_GIGS: {
      return {
        ...state,
        allGigs: {},
        allGigsError: '',
        isFetchingGigs: true,
      };
    }

    case GET_ALL_GIGS_SUCCESS: {
      return {
        ...state,
        allGigs: payload.Data || {},
        isFetchingGigs: false,
      };
    }

    case GET_ALL_GIGS_ERROR: {
      return {
        ...state,
        allGigsError: payload.error || '',
        isFetchingGigs: false,
      };
    }

    case GET_GIG_SUBMISSIONS: {
      return {
        ...state,
        submissions: payload.Data,
      };
    }

    case RESET_GIG_ACTION: {
      return initialState;
    }

    case UPDATE_GIG: {
      return {
        ...state,
        isGigUpdating: true,
        updateGigSuccess: '',
        updateGigError: '',
      };
    }

    case UPDATE_GIG_SUCCESS: {
      return {
        ...state,
        isGigUpdating: false,
        updateGigSuccess: payload || '',
      };
    }

    case UPDATE_GIG_ERROR: {
      return {
        ...state,
        isGigUpdating: false,
        updateGigError: payload.error || '',
      };
    }

    case CREATE_GIG_PRIZES: {
      return {
        ...state,
        isCreatingGigPrizes: true,
      };
    }

    case CREATE_GIG_PRIZES_SUCCESS: {
      return {
        ...state,
        createdGigPrizes: payload ? payload.map(({ Data }) => Data) : [],
        isCreatingGigPrizes: false,
      };
    }

    case CREATE_GIG_PRIZES_ERROR: {
      return {
        ...state,
        createGigPrizesError: payload.error || '',
        isCreatingGigPrizes: false,
      };
    }

    case DELETE_GIG: {
      return {
        ...state,
        isDeletingGig: true,
      };
    }

    case DELETE_GIG_SUCCESS: {
      return {
        ...state,
        deleted: payload.Message || '',
        isDeletingGig: false,
      };
    }

    case DELETE_GIG_ERROR: {
      return {
        ...state,
        deletedError: payload.error || '',
        isDeletingGig: false,
      };
    }

    case GET_GIG_DETAILS: {
      return {
        ...state,
        isFetchingGigDetails: true,
      };
    }

    case GET_GIG_DETAILS_SUCCESS: {
      return {
        ...state,
        details: payload.data || {},
        isFetchingGigDetails: false,
      };
    }

    case GET_GIG_DETAILS_ERROR: {
      return {
        ...state,
        detailsError: payload.error || '',
        isFetchingGigDetails: false,
      };
    }

    case COMPLETE_GIG: {
      return {
        ...state,
        complete: payload.data || '',
        completeError: payload.error || '',
      };
    }

    case CHARGE_GIG_PAYMENT: {
      return {
        ...state,
        chargeSuccess: '',
        chargeError: '',
        isChargingGig: true,
      };
    }

    case CHARGE_GIG_PAYMENT_SUCCESS: {
      return {
        ...state,
        chargeSuccess: payload.data || '',
        isChargingGig: false,
      };
    }

    case CHARGE_GIG_PAYMENT_ERROR: {
      return {
        ...state,
        chargeError: payload.error || '',
        isChargingGig: false,
      };
    }

    default:
      return state;
  }
}
