import { includes, intersection, isEmpty, keys, reduce } from 'lodash';

import { FILTER_ITEMS } from '../../../types/filters';

const isFilterUsedForFilterTagListComponent = (filterName, activeFilterNames) => {
  if (filterName === 'searchList' && includes(activeFilterNames, 'search')) {
    return true;
  }

  return intersection(keys(FILTER_ITEMS), activeFilterNames).indexOf(filterName) > -1;
};

export const filtersAfterTagsBeingRemoved = (removedTags, currentFilters, defaultFilters) => {
  const updatedFilters = { ...currentFilters };
  removedTags.forEach(({ key, value }) => {
    switch (key) {
      case 'location':
      case 'prizeAmount':
      case 'ratings':
      case 'state':
        updatedFilters[key] = defaultFilters[key];
        break;

      case 'fromDate':
      case 'toDate':
        updatedFilters.fromDate = defaultFilters.fromDate;
        updatedFilters.toDate = defaultFilters.toDate;
        break;

      case 'closingDateFrom':
      case 'closingDateTo':
        updatedFilters.closingDateFrom = defaultFilters.closingDateFrom;
        updatedFilters.closingDateTo = defaultFilters.closingDateTo;
        break;

      case 'hasGigs':
      case 'showDeactivatedBusiness':
        updatedFilters[key] = false;
        break;

      case 'rating':
        updatedFilters[key] = null;
        break;

      default: {
        const filterValues = updatedFilters[key].filter(filterValue => filterValue !== value);
        if (!isEmpty(filterValues)) {
          updatedFilters[key] = filterValues;
        } else {
          updatedFilters[key] = defaultFilters[key];
        }
        break;
      }
    }
  });

  updatedFilters.search = updatedFilters.searchList.join(',');
  return updatedFilters;
};

const convertFilterValueForTagListObject = (filterName, filterValue, activeFilterNames) => {
  if (!isFilterUsedForFilterTagListComponent(filterName, activeFilterNames)) {
    return {};
  }

  switch (filterName) {
    case 'hasGigs':
    case 'showDeactivatedBusiness':
      if (filterValue === 'true' || filterValue === true) {
        return { [filterName]: true };
      }

      return {};

    case 'rating':
      if (filterValue === 'true' || filterValue === true) {
        return { [filterName]: true };
      }

      if (filterValue === 'false' || filterValue === false) {
        return { [filterName]: false };
      }

      return {};

    case 'state':
      if (Number(filterValue)) {
        return { [filterName]: Number(filterValue) };
      }

      return {};

    default:
      if (isEmpty(filterValue)) {
        return {};
      }

      return { [filterName]: filterValue };
  }
};

export const filtersForTagList = (filters, activeFilterNames) => reduce(
  filters,
  (selectedFilters, filterValue, filterName) => ({
    ...selectedFilters,
    ...convertFilterValueForTagListObject(filterName, filterValue, activeFilterNames),
  }),
  {},
);
