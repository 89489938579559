import './index.sass';

import { isEmpty, reduce } from 'lodash';
import { func } from 'prop-types';
import React from 'react';

import FILTERS_TYPE from '../../../types/filters';
import SELECT_OPTIONS_TYPE from '../../../types/selectOptions';
import TagsList from '../../TagsList';
import generateTag from './tags.utils';

const FiltersTagList = (props) => {
  const { filters, onRemoveTags, userRoleOptions, usStateOptions } = props;

  if (isEmpty(filters)) {
    return null;
  }

  const { tagList, tagAndTermMap } = reduce(
    filters,
    (generatedData, value, key) =>
      generateTag(value, key, {
        ...generatedData,
        filters,
        userRoleOptions,
        usStateOptions,
      }),
    {
      tagList: [],
      tagAndTermMap: [],
    },
  );

  return (
    <div className="FiltersTagList__Container">
      <TagsList
        onRemove={(_, tagIndex) => onRemoveTags([tagAndTermMap[tagIndex]])}
        tags={tagList}
      />

      <button
        className="button purple-theme"
        onClick={() => onRemoveTags(tagAndTermMap)}
        type="button"
      >
        Clear
      </button>
    </div>
  );
};

FiltersTagList.propTypes = {
  filters: FILTERS_TYPE,
  onRemoveTags: func.isRequired, // (Array<{key: string, value: string}>) => void
  userRoleOptions: SELECT_OPTIONS_TYPE,
  usStateOptions: SELECT_OPTIONS_TYPE,
};

FiltersTagList.defaultProps = {
  filters: {},
  userRoleOptions: [],
  usStateOptions: [],
};

export default FiltersTagList;
export * from './filters.utils';
