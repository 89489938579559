import { number, shape, string } from 'prop-types';

import MEDIA_TYPE from './media';

const PRODUCT_TYPE = shape({
  Logo: MEDIA_TYPE,
  Name: string,
  ProductId: number,
  TotalReviews: number,
});

export default PRODUCT_TYPE;
