import { func, object, array, oneOfType } from 'prop-types';
import React from 'react';
import Toggle from 'react-toggle';


const Dietary = (props) => {
  const {
    formState: { BusinessOption },
    businessOptionParamaters,
    onHandleChange,
  } = props;

  const { Options = [] } = businessOptionParamaters.find(item => item.Name === 'Dietary') || {};

  return (
    <div>
      <h4 className="BusinessOptions__Title">
        Dietary options
      </h4>

      {Options.map(({ DisplayString, Name }) => (
        <label key={Name} className="mb-20 display-flex" htmlFor={`txt${Name}`}>
          <p className="BusinessOptions__Label">{DisplayString}</p>
          <Toggle
            defaultChecked={BusinessOption[Name]}
            icons={false}
            onChange={() => onHandleChange(Name, !BusinessOption[Name])}
          />
        </label>
      ))}

    </div>
  );
};

Dietary.propTypes = {
  formState: oneOfType([object]),
  businessOptionParamaters: oneOfType([array]),
  onHandleChange: func.isRequired,
};

Dietary.defaultProps = {
  formState: {},
  businessOptionParamaters: null,
};

export default Dietary;
