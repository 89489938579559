import './responsive.sass';

import { isEmpty } from 'lodash';
import { arrayOf, func } from 'prop-types';
import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import uuidv1 from 'uuid/v1';

import PlanBoxes from '../../../../components/Plans/PlanBoxes';
import PLAN_TYPE from '../../../../components/Plans/planType';

const ResponsivePlans = (props) => {
  const { plans, onHandleSelectPlan, onHandleMousePlanHover } = props;

  if (!isEmpty(plans)) {
    return (
      <Tabs className="frankiTabs">
        <TabList>
          {plans.map((item) => (
            <Tab
              key={uuidv1()}
              onClick={() => onHandleMousePlanHover(item.title, item.priceWithDiscount)}
            >
              {item.title}
              <span className="frankiTabs-priceWithDiscount">{`$${item.priceWithDiscount}`}</span>
            </Tab>
          ))}
        </TabList>

        {plans.map((item) => (
          <TabPanel key={uuidv1()}>
            <PlanBoxes
              title={item.title}
              period={item.period}
              price={item.price}
              priceWithDiscount={item.priceWithDiscount}
              priceDetails={item.priceDetails}
              items={item.items}
              onClick={() => onHandleSelectPlan()}
            />
          </TabPanel>
        ))}
      </Tabs>
    );
  }

  return null;
};

ResponsivePlans.propTypes = {
  onHandleMousePlanHover: func.isRequired,
  onHandleSelectPlan: func.isRequired,
  plans: arrayOf(PLAN_TYPE).isRequired,
};

export default ResponsivePlans;
