import './index.sass';

import { bool, instanceOf } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import WelcomeIMG from '../../assets/images/welcome.jpg';
import MasterPage from '../../components/MasterPage';
import RegisterFooter from '../../components/RegisterFooter';

const Register = ({ auth, routerProps }) => (
  <>
    <Helmet title="Franki Admin: register" />

    <MasterPage auth={auth} fluid>
      <div className="Register">
        <figure className="Register__Figure">
          <img src={WelcomeIMG} alt="Welcome" />
        </figure>

        <h1 className="Register__Fitle">ready to harness the hustle?</h1>

        <p className="Register__Fescription">
          your customers are waiting for you. to get you started we are going to give you a{' '}
          <strong>&nbsp;30 day free trial&nbsp;</strong> so you can learn what franki is all about.
        </p>

        <Link to={`/register-${routerProps.businessType}/account`} className="button orange-theme">
          Create Business profile
        </Link>

        <p className="Register__Fink">
          already have an account? &nbsp;
          <Link to="/">LOGIN</Link>
        </p>
      </div>
    </MasterPage>

    <RegisterFooter />
  </>
);

Register.propTypes = {
  auth: bool.isRequired,
  routerProps: instanceOf(Object).isRequired,
};

export default Register;
