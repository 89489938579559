import './index.sass';

import moment from 'moment';
import { bool } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Helmet from 'react-helmet';

import { validateAccountIdentifier } from '../../actions/auth';
import {
  changePassword,
  getMyProfile,
  identifierIsAvailable,
  postUserPhoto,
  saveMyProfile,
} from '../../actions/users';
import AlertMessages from '../../components/AlertMessages';
import AsyncContent from '../../components/AsyncContent';
import fileToBase64 from '../../components/FileUpload/fileToBase64';
import LightBox from '../../components/LightBox';
import MasterPage from '../../components/MasterPage';
import PhoneWithFlagInput from '../../components/PhoneWithFlagInput';
import ProfileSidebar from '../../components/ProfileSidebar';
import VerificationCode from '../../components/VerificationCode';
import USER_ROLES from '../../config/user-roles';
import { validEmailAddress, validPasswordStraight } from '../../helpers';
import encrypt from '../../helpers/crypto';

const PROFILE_PHOTO_MINIMUM_WIDTH_PX = 250;
const PROFILE_PHOTO_MINIMUM_HEIGHT_PX = 250;
const PROFILE_STATUS = {
  Active: 'Active',
  Inactive: 'Inactive',
};

/* global localStorage, Image */
export default function Profile(props) {
  const { auth, userRole } = props;

  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(null);
  const [modalLoading, setModalLoading] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [formDisabled, setFormDisabled] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [editPasswordModalVisibility, setEditPasswordModalVisibility] = useState(null);
  const [currentPasswordState, setCurrentPasswordState] = useState({ valid: true, message: '' });
  const [newPasswordState, setNewPasswordState] = useState({ valid: true, message: '' });
  const [confirmPasswordState, setConfirmPasswordState] = useState({ valid: true, message: '' });
  const [submittingPassword, setSubmittingPassword] = useState(false);

  const [editEmailModalVisibility, setEditEmailModalVisibility] = useState(null);
  const [currentEmail, setCurrentEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [currentEmailState, setCurrentEmailState] = useState({ valid: true, message: '' });
  const [newEmailState, setNewEmailState] = useState({ valid: true, message: '' });
  const [successMessage, setSuccessMessage] = useState('');
  const [newEmailVisibility, setNewEmailVisibility] = useState(false);
  const [emailCodeVisibility, setEmailCodeVisibility] = useState(false);

  const [editPhoneNumberModalVisibility, setEditPhoneNumberModalVisibility] = useState(null);
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [newPhoneNumberState, setNewPhoneNumberState] = useState({ valid: true, message: '' });
  const [phoneNumberCodeVisibility, setPhoneNumberCodeVisibility] = useState(false);

  const [photoErrorMessage, setPhotoErrorMessage] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState();
  const [profilePhoto, setProfilePhoto] = useState();
  const [profilePhotoLoading, setProfilePhotoLoading] = useState(null);

  const [formLoading, setFormLoading] = useState(false);
  const [touched, setTouched] = useState(null);

  const ACCEPTED_IMAGE_FORMAT = '.png, .jpg, .jpeg';
  const MAX_FILE_SIZE_MB = 4;

  const getData = useCallback(() => {
    setLoading(true);

    getMyProfile().subscribe(
      setProfile,
      (err) => {
        setErrorMessage(err);
        setLoading(false);
      },
      () => setLoading(false),
    );
  }, [setLoading, getMyProfile, setProfilePhoto, setErrorMessage]);

  const validateIdentity = useCallback(
    (identity, callback, errorCallback) => {
      setModalLoading(true);
      identifierIsAvailable(identity).subscribe(
        () =>
          validateAccountIdentifier(identity).subscribe(
            () => {
              callback();
              setModalLoading(false);
            },
            (err) => {
              setModalLoading(false);
              errorCallback(err);
            },
          ),
        (err) => {
          setModalLoading(false);
          errorCallback(err);
        },
      );
    },
    [setModalLoading, identifierIsAvailable, validateAccountIdentifier],
  );

  useEffect(() => {
    const username = localStorage.getItem('frankiUser');
    if (!username) {
      return;
    }
    getData();
  }, []);

  useEffect(() => {
    setRole(localStorage.getItem('frankiRole'));
  }, []);

  useEffect(() => {
    setFirstName(profile.FirstName || '');
    setLastName(profile.LastName || '');
    setEmail(profile.Email || '');
    setProfilePhotoUrl(profile.ProfilePhoto && profile.ProfilePhoto.FullResolutionUrl);
  }, [profile]);

  const onSubmitPassword = useCallback(() => {
    setErrorMessage('');
    setNewPasswordState({ valid: true, message: '' });
    setConfirmPasswordState({ valid: true, message: '' });
    setCurrentPasswordState({ valid: true, message: '' });
    if (!newPassword || !confirmPassword || !currentPassword) {
      if (!newPassword) {
        setNewPasswordState({ valid: false, message: '' });
      }

      if (!confirmPassword) {
        setConfirmPasswordState({ valid: false, message: '' });
      }

      if (!currentPassword) {
        setCurrentPasswordState({ valid: false, message: '' });
      }

      setErrorMessage('Mandatory fields have not been completed. Please try again.');
    } else if (!validPasswordStraight(newPassword).result) {
      setNewPasswordState({ valid: false, message: 'Password requirement not met' });
    } else if (newPassword !== confirmPassword) {
      setConfirmPasswordState({ valid: false, message: 'Password mismatch' });
      setNewPasswordState({ valid: true, message: '' });
    } else {
      const encryptedCurrentPassword = encrypt(currentPassword);
      const encryptedNewPassword = encrypt(newPassword);

      setSubmittingPassword(true);
      changePassword(encryptedNewPassword.iv64, {
        oldPassword: encryptedCurrentPassword.password64,
        newPassword: encryptedNewPassword.password64,
      }).subscribe(
        (msg) => {
          setSuccessMessage(msg);
          setEditPasswordModalVisibility(false);
          setSubmittingPassword(false);
          setSuccessMessage('You have set a new password');
          setTimeout(() => setSuccessMessage(''), 10000);
        },
        () => {
          setSubmittingPassword(false);
          setCurrentPasswordState({ valid: false, message: 'Invalid password try again' });
        },
      );
    }
  }, [
    setErrorMessage,
    setSubmittingPassword,
    setEditPasswordModalVisibility,
    setNewPasswordState,
    setConfirmPasswordState,
    setCurrentPasswordState,
    currentPassword,
    newPassword,
    confirmPassword,
  ]);

  const showPasswordModal = useCallback(() => {
    setConfirmPassword('');
    setCurrentPassword('');
    setEditPasswordModalVisibility(true);
    setErrorMessage('');
    setNewPassword('');
    setNewPasswordState({ valid: true, message: '' });
    setCurrentPasswordState({ valid: true, message: '' });
    setConfirmPasswordState({ valid: true, message: '' });
    setSuccessMessage('');
  }, [setCurrentPassword, setNewPassword, setConfirmPassword, setEditPasswordModalVisibility]);

  // Email
  // =====================================================

  const onVerifyEmail = useCallback(() => {
    setErrorMessage('');
    setCurrentEmailState({ valid: true, message: '' });
    if (!currentEmail) {
      setCurrentEmailState({ valid: false, message: '' });
      setErrorMessage('Mandatory fields have not been completed. Please try again.');
    } else if (currentEmail !== email) {
      setCurrentEmailState({
        valid: false,
        message: 'The email you entered is invalid. Please try again.',
      });
    } else {
      setNewEmailVisibility(true);
    }
  }, [setErrorMessage, setCurrentEmailState, currentEmail, email, setNewEmailVisibility]);

  const onVerifyNewEmail = useCallback(() => {
    setErrorMessage('');
    setNewEmailState({ valid: true, message: '' });
    if (!newEmail) {
      setNewEmailState({ valid: false, message: '' });
      setErrorMessage('Mandatory fields have not been completed. Please try again.');
    } else if (!validEmailAddress(newEmail).result) {
      setNewEmailState({ valid: false, message: 'Email requirement not met' });
    } else {
      validateIdentity(
        newEmail,
        () => setEmailCodeVisibility(true),
        (message) => setNewEmailState({ valid: false, message }),
      );
    }
  }, [setErrorMessage, setNewEmailState, newEmail, setLoading, validateAccountIdentifier]);

  const onEmailSuccess = useCallback(() => {
    setErrorMessage('');
    setCurrentEmailState({ valid: true, message: '' });
    setNewEmailState({ valid: true, message: '' });
    setSuccessMessage('Your email has been successfully updated');
    setEditEmailModalVisibility(false);
    setNewEmail('');
    getData();
    setEmailCodeVisibility(false);
    setNewEmailVisibility(false);
    setTimeout(() => setSuccessMessage(''), 10000);
  }, [
    setErrorMessage,
    setNewEmailState,
    setSuccessMessage,
    setCurrentEmailState,
    setEditEmailModalVisibility,
    setNewEmail,
    getData,
    setEmailCodeVisibility,
    setNewEmailVisibility,
  ]);

  const showEmailModal = useCallback(() => {
    setNewEmailState({ valid: true, message: '' });
    setEditEmailModalVisibility(true);
  }, [setNewEmailState, setEditEmailModalVisibility]);

  const emailCode = useMemo(() => {
    if (emailCodeVisibility) {
      return (
        <>
          <div className="ChangeEmailModal__Message">
            A verification code has been sent to your new email
          </div>

          <VerificationCode
            identifier={newEmail}
            onVerified={onEmailSuccess}
            resendTitle="Email Verification Resent"
            title="Enter Verification Code"
          />
        </>
      );
    }
    return null;
  }, [emailCodeVisibility, currentEmail]);

  const emailConfirmButton = useMemo(() => {
    if (emailCodeVisibility) {
      return null;
    }
    return (
      <button
        onClick={onVerifyNewEmail}
        className="button small orange-theme ChangeEmailModal__Submit"
        disabled={modalLoading}
        type="button"
      >
        CONFIRM
      </button>
    );
  }, [emailCodeVisibility, onVerifyNewEmail, modalLoading]);

  // Phone
  // =====================================================

  const displayedPhoneNumber = useMemo(() => {
    if (profile.CombinedPhoneNumber && profile.PhoneNumber) {
      const countryCode = profile.CombinedPhoneNumber.split(profile.PhoneNumber)[0];
      return `(+${countryCode}) ${profile.PhoneNumber}`;
    }
    return '';
  }, [profile.CombinedPhoneNumber, profile.PhoneNumber]);

  const onVerifyNewPhoneNumber = useCallback(() => {
    setTouched(true);
    if (!newPhoneNumber) {
      setNewPhoneNumberState({ valid: false, message: '' });
      setErrorMessage('Mandatory fields have not been completed. Please try again.');
    } else if (newPhoneNumberState.valid) {
      validateIdentity(
        newPhoneNumber,
        () => setPhoneNumberCodeVisibility(true),
        (message) => setNewPhoneNumberState({ valid: false, message }),
      );
    }
  }, [
    setErrorMessage,
    setNewPhoneNumberState,
    newPhoneNumber,
    newPhoneNumberState,
    setLoading,
    validateAccountIdentifier,
  ]);

  const onPhoneNumberSuccess = useCallback(() => {
    setErrorMessage('');
    setNewPhoneNumberState({ valid: true, message: '' });
    setSuccessMessage('Your phone number has been successfully updated');
    setEditPhoneNumberModalVisibility(false);
    setNewPhoneNumber('');
    setPhoneNumberCodeVisibility(false);
    getData();
    setTimeout(() => setSuccessMessage(''), 10000);
  }, [
    setErrorMessage,
    setNewPhoneNumberState,
    setSuccessMessage,
    setEditPhoneNumberModalVisibility,
    setNewPhoneNumber,
    setPhoneNumberCodeVisibility,
    getData,
  ]);

  const showPhoneNumberModal = useCallback(() => {
    setNewPhoneNumberState({ valid: true, message: '' });
    setEditPhoneNumberModalVisibility(true);
    setTouched(null);
  }, [setNewPhoneNumberState, setEditPhoneNumberModalVisibility]);

  const phoneNumberCode = useMemo(() => {
    if (phoneNumberCodeVisibility) {
      return (
        <>
          <div className="ChangePhoneNumberModal__Message">
            A verification code has been sent to your new phone number
          </div>

          <VerificationCode
            identifier={newPhoneNumber}
            onVerified={onPhoneNumberSuccess}
            resendTitle="Phone Number Verification Resent"
            title="Enter Verification Code"
          />
        </>
      );
    }
    return null;
  }, [phoneNumberCodeVisibility]);

  const phoneNumberConfirmButton = useMemo(() => {
    if (phoneNumberCodeVisibility) {
      return null;
    }

    return (
      <button
        onClick={onVerifyNewPhoneNumber}
        className="button small orange-theme ChangePhoneNumberModal__Submit"
        disabled={modalLoading || (touched && !newPhoneNumberState.valid)}
        type="button"
      >
        CONFIRM
      </button>
    );
  }, [phoneNumberCodeVisibility, onVerifyNewPhoneNumber, modalLoading]);

  const masterPageConfig = useMemo(
    () => ({
      auth,
      sidebar: <></>,
      headerBreadcrumb: [
        {
          name: 'Home',
          url:
            userRole != USER_ROLES.businessManager
              ? '/'
              : `/admin/business-profile/${localStorage.getItem('corporateGroupId')}`,
        },
        {
          name:
            userRole == USER_ROLES.businessManager ? 'Business Manager Profile' : 'Admin Profile',
        },
      ],
    }),
    [auth],
  );

  const profileStatus = useMemo(
    () => (profile.Enabled ? PROFILE_STATUS.Active : PROFILE_STATUS.Inactive),
    [profile.Enabled],
  );

  const transformData = useCallback(
    (file) => {
      fileToBase64(file)((url) => {
        setPhotoErrorMessage(null);
        const img = new Image();
        img.onload = () => {
          if (
            img.naturalHeight < PROFILE_PHOTO_MINIMUM_HEIGHT_PX ||
            img.naturalWidth < PROFILE_PHOTO_MINIMUM_WIDTH_PX
          ) {
            setPhotoErrorMessage(
              `Please select a file larger than ${PROFILE_PHOTO_MINIMUM_WIDTH_PX}x${PROFILE_PHOTO_MINIMUM_HEIGHT_PX} px.`,
            );
          } else {
            setProfilePhotoUrl(url);
          }
        };
        img.src = url;
      });
    },
    [setPhotoErrorMessage, setProfilePhotoUrl],
  );

  const onHandlePhotoChange = useCallback(
    (e) => {
      const file = e.target.files[0];

      if (file) {
        setPhotoErrorMessage('');
        const extension = file.name.split('.').pop();

        if (
          !ACCEPTED_IMAGE_FORMAT.split(',')
            .map((t) => t.trim().substring(1))
            .includes(extension)
        ) {
          setPhotoErrorMessage('Please select a .jpg, .jpeg, or .png file.');
          return;
        }

        if (file.size < MAX_FILE_SIZE_MB * 1000000) {
          transformData(file);
          setProfilePhoto(file);
        } else {
          setPhotoErrorMessage('Please select a file smaller than 4mb.');
        }
      }
    },
    [setPhotoErrorMessage, transformData, setProfilePhotoUrl, setProfilePhoto],
  );

  const save = useCallback(() => {
    if (!firstName) {
      setErrorMessage('First name is required');
    } else if (!lastName) {
      setErrorMessage('Last name is required');
    } else {
      setErrorMessage('');
      setFormLoading(true);
      saveMyProfile({
        FirstName: firstName,
        LastName: lastName,
      }).subscribe(
        () => {
          if (profilePhoto) {
            setProfilePhotoLoading(true);
            postUserPhoto(profilePhoto).subscribe(
              () => {
                setFormDisabled(true);
                setProfilePhotoLoading(false);
                setSuccessMessage('Your profile has been successfully updated');
              },
              (err) => {
                setPhotoErrorMessage(err);
                setProfilePhotoLoading(false);
              },
            );
          } else {
            setFormDisabled(true);
            setSuccessMessage('Your profile has been successfully updated');
          }
          setFormLoading(false);
        },
        (err) => {
          setErrorMessage(err);
          setFormLoading(false);
        },
      );
    }
  }, [
    profilePhoto,
    setProfilePhotoLoading,
    postUserPhoto,
    setFormDisabled,
    setPhotoErrorMessage,
    setFormLoading,
    firstName,
    lastName,
    saveMyProfile,
  ]);

  return (
    <>
      <Helmet title="Franki Admin: Profile" />

      <MasterPage
        {...masterPageConfig}
        sidebar={<ProfileSidebar userRole={userRole} profile={profile} />}
      >
        <AsyncContent
          onHandleCloseDialog={() => {}}
          content={() => (
            <div className="Profile">
              <div className={`Profile__Status Profile__Status--${profileStatus}`}>
                <span>{profileStatus}</span>
              </div>

              <div className="Profile__Name">{`${profile.FirstName} ${profile.LastName}`}</div>
              <div className="Profile__Subtitle">
                <span>{localStorage.getItem('frankiUser')}</span>
                <span>
                  Member since: {moment(new Date(profile.JoinedDate)).format('MMM DD, YYYY')}
                </span>
              </div>

              <div className="Profile__Photo">
                {profilePhotoUrl ? (
                  <div
                    className="Profile__Photo__Avatar"
                    style={{ backgroundImage: `url(${profilePhotoUrl})` }}
                  />
                ) : (
                  <i className="icon-user" />
                )}

                {formDisabled ? null : (
                  <div className="Profile__Photo__Upload">
                    <div className="Profile__Photo__Upload__Button">
                      <input
                        type="file"
                        onChange={onHandlePhotoChange}
                        accept={ACCEPTED_IMAGE_FORMAT}
                        disabled={profilePhotoLoading}
                      />

                      <div
                        className={`
                          Profile__Photo__Upload__Button__Indicator
                          ${
                            profilePhotoLoading
                              ? 'Profile__Photo__Upload__Button__Indicator--disabled'
                              : ''
                          }
                        `}
                      >
                        {profilePhotoLoading ? 'SAVING...' : 'CHANGE PHOTO'}
                      </div>
                    </div>

                    {photoErrorMessage ? (
                      <span className="Profile__Photo__Upload__Button__Hint error">
                        {photoErrorMessage}
                      </span>
                    ) : (
                      <span className="Profile__Photo__Upload__Button__Hint">
                        .jpg, .jpeg, or .png, 250x250px minimum
                        <br />
                        maximum file size 4mb
                      </span>
                    )}
                  </div>
                )}
              </div>

              <div className="Profile__Header Profile__Header--Main">
                <span>PERSONAL DETAILS</span>
              </div>

              <fieldset>
                <label htmlFor="txtFirstName">First name</label>

                <input
                  className="full"
                  disabled={formLoading || formDisabled}
                  id="txtFirstName"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </fieldset>

              <fieldset>
                <label htmlFor="txtLastName">Last name</label>

                <input
                  className="full"
                  disabled={formLoading || formDisabled}
                  id="txtLastName"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </fieldset>

              <fieldset>
                <label htmlFor="txtRole">Role</label>

                <input
                  className="full"
                  disabled={formLoading || formDisabled}
                  id="txtRole"
                  readOnly
                  type="text"
                  value={role}
                />
              </fieldset>

              <fieldset>
                <label htmlFor="txtEmail">Email</label>

                <input
                  className="full"
                  onClick={showEmailModal}
                  disabled={formLoading || formDisabled}
                  id="txtEmail"
                  readOnly
                  type="email"
                  value={email}
                />
              </fieldset>

              <fieldset>
                <label htmlFor="txtPhoneNumber">Phone number</label>

                <input
                  className="full"
                  onClick={showPhoneNumberModal}
                  disabled={formLoading || formDisabled}
                  id="txtPhoneNumber"
                  readOnly
                  type="tel"
                  value={displayedPhoneNumber}
                />
              </fieldset>

              {formDisabled ? (
                <button
                  onClick={() => {
                    setFormDisabled(false);
                    setSuccessMessage('');
                    setErrorMessage('');
                  }}
                  className="button small orange-theme FormButton"
                  disabled={loading}
                  type="button"
                >
                  EDIT
                </button>
              ) : (
                <div className="Profile__Actions">
                  <button
                    onClick={save}
                    className="button small orange-theme FormButton"
                    disabled={formLoading || profilePhotoLoading}
                    type="button"
                  >
                    SAVE
                  </button>

                  {errorMessage ? <div className="error error-message">{errorMessage}</div> : null}
                </div>
              )}

              <div className="Profile__Header">PASSWORD</div>

              <fieldset>
                <label htmlFor="txtPassword">Password</label>

                <input
                  className="full"
                  id="txtPassword"
                  type="password"
                  value="***********"
                  disabled
                />
              </fieldset>

              <button
                onClick={showPasswordModal}
                className="button small orange-theme FormButton"
                disabled={loading}
                type="button"
                style={{ width: 'fit-content' }}
              >
                CHANGE PASSWORD
              </button>
            </div>
          )}
          expectedData={profile}
          loading={loading}
        />
      </MasterPage>

      {/* Password */}
      <LightBox
        show={editPasswordModalVisibility}
        style={{
          width: 600,
          height: null,
          marginLeft: -300,
          marginTop: -300,
        }}
        title="Change password"
        onClose={() => {
          setEditPasswordModalVisibility(false);
          setSuccessMessage('');
          setErrorMessage('');
        }}
        className="ChangePasswordModal UpdateProfileModal"
      >
        <div className="form">
          <fieldset>
            <label
              htmlFor="txtCurrentPassword"
              className={currentPasswordState.valid ? '' : 'error'}
            >
              {currentPasswordState.message || 'Current password'}
              <em>*</em>
            </label>

            <input
              className={`full ${currentPasswordState.valid ? '' : 'error'}`}
              disabled={submittingPassword}
              id="txtCurrentPassword"
              onChange={(e) => {
                setCurrentPassword(e.target.value);
                setCurrentPasswordState({ valid: true, message: '' });
                setErrorMessage('');
              }}
              type="password"
              value={currentPassword}
            />
          </fieldset>

          <fieldset>
            <label htmlFor="txtNewPassword" className={newPasswordState.valid ? '' : 'error'}>
              {newPasswordState.message || 'New password'}
              <em>*</em>
            </label>

            <input
              className={`full ${newPasswordState.valid ? '' : 'error'}`}
              disabled={submittingPassword}
              id="txtNewPassword"
              onChange={(e) => {
                setNewPassword(e.target.value);
                setNewPasswordState({ valid: true, message: '' });
                setErrorMessage('');
              }}
              type="password"
              value={newPassword}
            />
          </fieldset>

          <fieldset>
            <label
              htmlFor="txConfirmPassword"
              className={confirmPasswordState.valid ? '' : 'error'}
            >
              {confirmPasswordState.message || 'Confirm new password'}
              <em>*</em>
            </label>

            <input
              className={`full ${confirmPasswordState.valid ? '' : 'error'}`}
              disabled={submittingPassword}
              id="txConfirmPassword"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setConfirmPasswordState({ valid: true, message: '' });
                setErrorMessage('');
              }}
              type="password"
              value={confirmPassword}
            />

            <div className="helper">
              Password must be 8 or more characters with a mix of uppercase, lowercase, numbers and
              special characters.
            </div>
          </fieldset>

          <button
            onClick={onSubmitPassword}
            className="button small orange-theme ChangePasswordModal__Submit"
            disabled={submittingPassword}
            type="button"
          >
            CONFIRM
          </button>

          <div className="error error-message">{errorMessage}</div>
        </div>
      </LightBox>

      <AlertMessages
        className="Profile__AlertMessage"
        show={!!successMessage}
        type="success"
        message={successMessage}
        timer={2}
      />

      {/* Email */}
      <LightBox
        show={editEmailModalVisibility}
        style={{
          width: 600,
          height: null,
          marginLeft: -300,
          marginTop: -300,
        }}
        title="Update your email"
        onClose={() => {
          setEditEmailModalVisibility(false);
          setEmailCodeVisibility(false);
          setNewEmailVisibility(false);
          setSuccessMessage('');
          setErrorMessage('');
          setCurrentEmailState({ valid: true, message: '' });
          setNewEmailState({ valid: true, message: '' });
        }}
        className="ChangeEmailModal UpdateProfileModal"
      >
        <div className="form">
          {newEmailVisibility ? (
            <>
              <fieldset>
                <label htmlFor="txtEmail" className={newEmailState.valid ? '' : 'error'}>
                  {newEmailState.message || 'Enter new email'}
                  <em>*</em>
                </label>

                <input
                  className={`full ${newEmailState.valid ? '' : 'error'}`}
                  disabled={modalLoading}
                  id="txtEmail"
                  onChange={(e) => {
                    setNewEmail(e.target.value);
                    setNewEmailState({ valid: true, message: '' });
                    setErrorMessage('');
                  }}
                  type="text"
                  value={newEmail}
                />
              </fieldset>

              {emailConfirmButton}
            </>
          ) : (
            <>
              <fieldset>
                <label htmlFor="txtEmail" className={currentEmailState.valid ? '' : 'error'}>
                  {currentEmailState.message || 'Email'}
                  <em>*</em>
                </label>

                <input
                  className={`full ${currentEmailState.valid ? '' : 'error'}`}
                  id="txtEmail"
                  onChange={(e) => {
                    setCurrentEmail(e.target.value);
                    setCurrentEmailState({ valid: true, message: '' });
                    setErrorMessage('');
                  }}
                  type="text"
                  value={currentEmail}
                />
              </fieldset>

              <button
                onClick={onVerifyEmail}
                className="button small orange-theme ChangeEmailModal__Submit"
                type="button"
                disabled={!currentEmailState.valid}
              >
                NEXT
              </button>
            </>
          )}

          {emailCode}

          <div className="error error-message">{errorMessage}</div>
        </div>
      </LightBox>

      {/* Phone Number */}

      <LightBox
        show={editPhoneNumberModalVisibility}
        style={{
          width: 600,
          height: null,
          marginLeft: -300,
          marginTop: -300,
          overflow: 'hidden',
        }}
        title="Update your phone number"
        onClose={() => {
          setEditPhoneNumberModalVisibility(false);
          setPhoneNumberCodeVisibility(false);
          setSuccessMessage('');
          setErrorMessage('');
          setNewPhoneNumberState({ valid: true, message: '' });
        }}
        className="ChangePhoneNumberModal UpdateProfileModal"
      >
        <div className="form">
          <fieldset>
            <label
              htmlFor="txtPhoneNumber"
              className={`ChangePhoneNumberModal__PhoneNumberLabel ${
                touched && !newPhoneNumberState.valid ? 'error' : ''
              }`}
            >
              {(touched && newPhoneNumberState.message) || 'Enter new phone number'}
              <em>*</em>
            </label>

            <PhoneWithFlagInput
              displayError={touched}
              onChange={(val) => {
                setNewPhoneNumber(val.number);
                switch (val.status) {
                  case 'valid':
                    setNewPhoneNumberState({ valid: true, message: '' });
                    setErrorMessage('');
                    break;

                  case 'invalid':
                    setNewPhoneNumberState({
                      valid: false,
                      message: 'Phone number is invalid. Please try again.',
                    });
                    setErrorMessage('');
                    break;

                  case 'empty':
                    setNewPhoneNumberState({ valid: false, message: '' });
                    setErrorMessage('Mandatory fields have not been completed. Please try again.');
                    break;

                  default:
                    break;
                }
              }}
            />
          </fieldset>

          {phoneNumberConfirmButton}

          {phoneNumberCode}

          <div className="error error-message">{touched && errorMessage}</div>
        </div>
      </LightBox>
    </>
  );
}

Profile.propTypes = {
  auth: bool.isRequired,
};
