import { isEmpty } from 'lodash';
import { func, instanceOf, oneOfType, string } from 'prop-types';
import React from 'react';
import uuidv1 from 'uuid/v1';

import happySvg from '../../assets/images/happy.svg';
import sadSvg from '../../assets/images/sad.svg';

const getLabel = (item) => {
  if (item.label) {
    return item.label;
  }

  switch (item) {
    case '**happy**':
      return <img src={happySvg} alt="rated" />;
    case '**sad**':
      return <img src={sadSvg} alt="unrated" />;
    default:
      return item.split(';')[0];
  }
};

const TagsList = (props) => {
  const { onRemove, tags, className } = props;
  return (
    <div className={`TagsList ${className}`}>
      <ul className="TagsInput__List">
        {!isEmpty(tags) &&
          tags.map((item, index) => (
            <li key={uuidv1()} className={`${onRemove ? 'tags-add' : 'tags'}`}>
              {getLabel(item)}

              {!!onRemove && (
                <i
                  role="button"
                  tabIndex="0"
                  className="icon-close"
                  title="delete item"
                  onClick={() => onRemove(item, index)}
                  onKeyPress={() => onRemove(item, index)}
                />
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

TagsList.propTypes = {
  onRemove: func,
  className: string,
  tags: oneOfType([instanceOf(Object), instanceOf(Array)]),
};

TagsList.defaultProps = {
  tags: [],
  className: '',
  onRemove: null,
};

export default TagsList;
