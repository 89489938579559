import './index.sass';

import { isEmpty } from 'lodash';
import { bool, func, oneOfType, string } from 'prop-types';
import React from 'react';
import Select from 'react-select';

import SELECT_OPTIONS_TYPE, { SELECT_OPTION_TYPE } from '../../types/selectOptions';
import CustomSelectRequiredInput from './CustomSelectRequiredInput';
import customSelectStyles from './customSelectStyle';
import frankiSelectStyles from './frankiSelectStyle';
import customSelectTheme from './customSelectTheme';
import DropdownIndicator from './DropdownIndicator';

const CustomSelect = (props) => {
  const {
    additionalStyles,
    className,
    disabled,
    isMulti,
    isSearchable,
    name,
    onChange,
    options,
    placeholder,
    required,
    value,
    showDisabledValue,
    isFrankiPurple,
    closeMenuOnSelect,
  } = props;

  return (
    <>
      <style>{additionalStyles}</style>

      <Select
        className={className}
        classNamePrefix="CustomSelect"
        components={{ DropdownIndicator }}
        isDisabled={disabled}
        isMulti={isMulti}
        name={name}
        onChange={onChange}
        options={disabled ? [] : options}
        placeholder={placeholder}
        styles={isFrankiPurple ? frankiSelectStyles : customSelectStyles}
        theme={customSelectTheme}
        value={showDisabledValue || !disabled ? value : ''}
        isSearchable={isSearchable}
        closeMenuOnSelect={closeMenuOnSelect}

      />

      <CustomSelectRequiredInput
        required={required}
        hasValue={!isEmpty(value) || typeof value === 'number'}
      />
    </>
  );
};

CustomSelect.propTypes = {
  additionalStyles: string,
  className: string,
  disabled: bool,
  isMulti: bool,
  isSearchable: bool,
  name: string.isRequired,
  onChange: func.isRequired,
  options: SELECT_OPTIONS_TYPE,
  placeholder: string.isRequired,
  required: bool,
  value: oneOfType([SELECT_OPTION_TYPE, SELECT_OPTIONS_TYPE]),
  showDisabledValue: bool,
  isFrankiPurple: bool,
  closeMenuOnSelect: bool,
};

CustomSelect.defaultProps = {
  additionalStyles: '',
  className: '',
  disabled: false,
  isMulti: false,
  isSearchable: true,
  options: [],
  required: false,
  value: [],
  showDisabledValue: false,
  isFrankiPurple: false,
  closeMenuOnSelect: true,
};

export default CustomSelect;
