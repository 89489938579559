import './index.sass';

import { arrayOf, func, number } from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import Currency from 'react-currency-formatter';

import PRIZE_TYPE from '../../types/prize';

function PaymentBox(props) {
  const {
    gigCredits,
    serviceFeePercent,
    setIsGigCostCoveredState,
    setTotal,
    totalCostItems,
    prizes,
  } = props;

  const subtotal = useMemo(
    () => Math.max(totalCostItems - gigCredits, 0),
    [totalCostItems, gigCredits],
  );

  const serviceFee = useMemo(
    () => totalCostItems * (serviceFeePercent / 100),
    [totalCostItems, serviceFeePercent],
  );

  const total = useMemo(() => subtotal + serviceFee, [subtotal, serviceFee]);

  useEffect(() => {
    setIsGigCostCoveredState(gigCredits > totalCostItems + serviceFee);
  }, [gigCredits, setIsGigCostCoveredState, serviceFee, totalCostItems]);

  useEffect(() => {
    setTotal(total);
  }, [total, setTotal]);

  return (
    <div className="PaymentBox">
      {prizes.map((prize) => (
        <div className="PaymentBox__Cost" key={prize.Description}>
          <span className="PaymentBox__Cost__Label">{prize.Description}</span>
          <span className="PaymentBox__Cost__Value">
            <Currency quantity={Number(prize.PrizeAmount * prize.WinnerQty)} />
          </span>
        </div>
      ))}

      <div className="PaymentBox__Subtotal">
        <span className="PaymentBox__Subtotal__Label">Subtotal:</span>
        <span className="PaymentBox__Subtotal__Value">
          <Currency quantity={Number(subtotal)} />
        </span>
      </div>

      <div className="PaymentBox__ServiceFee">
        <span className="PaymentBox__ServiceFee__Label">
          Service Fee
          <small>{`(${serviceFeePercent}% of GiG Cost)`}</small>
        </span>
        <span className="PaymentBox__ServiceFee__Value">
          <Currency quantity={Number(serviceFee)} />
        </span>
      </div>

      <div className="PaymentBox__ServiceFee">
        <span className="PaymentBox__ServiceFee__Label">Tax:</span>
        <span className="PaymentBox__ServiceFee__Value">
          <Currency quantity={0} />
        </span>
      </div>

      <div className="PaymentBox__Total">
        <span className="PaymentBox__Total__Label">
          <strong>Total:</strong>
        </span>
        <span className="PaymentBox__Total__Value">
          <Currency quantity={Number(total)} />
        </span>
      </div>
    </div>
  );
}

PaymentBox.propTypes = {
  gigCredits: number,
  serviceFeePercent: number,
  setIsGigCostCoveredState: func,
  setTotal: func,
  totalCostItems: number,
  prizes: arrayOf(PRIZE_TYPE),
};

PaymentBox.defaultProps = {
  gigCredits: 0,
  serviceFeePercent: 20,
  setIsGigCostCoveredState: () => {},
  setTotal: () => {},
  prizes: [],
  totalCostItems: 0,
};

export default PaymentBox;
