import './index.sass';

import { arrayOf, bool, func, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import uuidv1 from 'uuid/v1';

import {
  deletePromotionContent,
  editPromotion,
  getPromotionContents as getPromotionContentsAction,
  getPromotions as getPromotionsAction,
} from '../../../actions/promotions';
import AddressTagsInput from '../../../components/AddressTagsInput';
import AsyncContent from '../../../components/AsyncContent';
import FileUpload from '../../../components/FileUpload';
import fileToBase64 from '../../../components/FileUpload/fileToBase64';
import { Col, Row } from '../../../components/Grid';
import Loader from '../../../components/Loader';
import MasterPage from '../../../components/MasterPage';
import NotFound from '../../../components/NotFound';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../config/pagination';
import HISTORY_TYPE from '../../../types/history';
import PROMOTION_TYPE from '../../../types/promotion';
import PROMOTION_CONTENT_TYPE from '../../../types/promotionContent';
import ROUTER_MATCH_TYPE from '../../../types/routerMatch';

const generateAddressTag = (searchTerm, matchedAddress) => {
  const { city } = matchedAddress;
  if (!city) {
    return {
      error: `${searchTerm} is not a city`,
    };
  }

  return { value: city };
};

const generateStateAddressTag = (searchTerm, matchedAddress) => {
  const { state } = matchedAddress;
  if (!state) {
    return {
      error: `${searchTerm} is not a state`,
    };
  }

  return { value: state };
};

class EditPromotion extends React.Component {
  state = {
    Cities: '',
    States: '',
    error: '',
    loading: true,
    Image: '',
    Title: '',
    ShowEveryone: false,
    IsLoading: false,
  };

  componentDidMount() {
    const {
      getPromotions,
      getPromotionContents,
      match: {
        params: { promotionId },
      },
    } = this.props;

    getPromotions();
    getPromotionContents(promotionId);
  }

  componentDidUpdate() {
    const {
      isFetchingPromotions,
      match: {
        params: { promotionId },
      },
      promotions,
      promotionsError,
    } = this.props;

    const { loading } = this.state;

    if (!isFetchingPromotions && loading && !promotionsError) {
      const promotion = promotions.find(({ PromotionId }) => PromotionId === Number(promotionId));
      if (promotion) {
        this.setPromotion(promotion);
      }
    }
  }

  onHandleChange(name, value) {
    this.setState({ [name]: value });
    if (name === 'ShowEveryone' && value) {
      this.setState({ Cities: '' });
      this.setState({ States: '' });
    }
  }

  async onHandleSubmit(e) {
    e.preventDefault();
    this.setState({ error: '' });

    const { Cities, States, Image, Title, ShowEveryone } = this.state;

    if (!ShowEveryone) {
      if (!Cities && !States) {
        this.setState({ error: 'At least 1 city or state needs to be added' });
        return;
      }
    }
    this.setState({ IsLoading: true });
    if (typeof Image === 'object') {
      fileToBase64(Image)(async (url) => {
        await this.updatePromotion(Title, Cities, States, url);
      });
    } else if (Image.indexOf('data:image') === 0) {
      await this.updatePromotion(Title, Cities, States, Image);
    } else {
      await this.updatePromotion(Title, Cities, States);
    }
    this.setState({ IsLoading: false });
  }

  setPromotion(promotion) {
    this.setState(
      {
        Cities: promotion.CityNames,
        States: promotion.StateNames,
        Image: promotion.Photo && promotion.Photo.FullResolutionUrl,
        loading: false,
        Title: promotion.PromotionTitle,
        ShowEveryone: promotion.ShowEveryone,
      },
      () => {
        this.render();
      },
    );
  }

  async updatePromotion(Title, Cities, States, url) {
    const {
      match: {
        params: { promotionId },
      },
    } = this.props;
    const { ShowEveryone } = this.state;
    const requestParams = {
      Cities,
      States,
      Title,
      ShowEveryone,
      ...(url ? { Image: url } : {}),
    };
    const newPromotion = await editPromotion(promotionId, requestParams);
    if (newPromotion.Success) {
      const { history } = this.props;
      history.push(`/admin/promoted-content/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`);
    } else {
      this.setState({ error: newPromotion.Message });
    }
  }

  async deleteContent(promotionContentId) {
    const {
      getPromotionContents,
      match: {
        params: { promotionId },
      },
    } = this.props;

    await deletePromotionContent(promotionId, promotionContentId);
    getPromotionContents(promotionId);
  }

  render() {
    const {
      auth,
      history,
      isFetchingPromotionContents,
      match: {
        params: { promotionId },
      },
      promotionContents,
      promotionContentsError,
      promotions,
      promotionsError,
    } = this.props;

    const { Cities, States, error, loading, Image, Title, ShowEveryone, IsLoading } = this.state;

    const masterPageConfig = {
      auth,
      headerBreadcrumb: [
        {
          name: 'promoted content',
          url: `/admin/promoted-content/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`,
        },
        { name: 'edit promotion' },
      ],
    };

    return (
      <>
        <Helmet title="Franki Admin: Edit promotion" />

        <MasterPage {...masterPageConfig}>
          <AsyncContent
            content={() => (
              <>
                {!loading && (
                  <form method="POST" onSubmit={(e) => this.onHandleSubmit(e)}>
                    <div className="EditPromotion__Section">
                      <h3>Edit Promotion Title</h3>

                      <div className="EditPromotion__FormGroup">
                        <label htmlFor="Title">
                          Promotional Title
                          <span className="required-asterisk">*</span>
                        </label>

                        <input
                          autoComplete="off"
                          className="full"
                          id="Title"
                          maxLength={60}
                          minLength={3}
                          name="Title"
                          onChange={({ target }) => this.onHandleChange('Title', target.value)}
                          required
                          type="text"
                          value={Title}
                        />
                      </div>
                    </div>

                    <div className="EditPromotion__Section">
                      <h3>Change Selected Cities</h3>
                      <div className="EditPromotion__Section__Subtitle">
                        The promotion will only be displayed to app users that are currently in the
                        cities you select.
                      </div>

                      <div className="EditPromotion__FormGroup">
                        <label htmlFor="Cities">
                          Add Cities
                          {!ShowEveryone && !Cities && !States && (
                            <span className="required-asterisk">*</span>
                          )}
                        </label>

                        <AddressTagsInput
                          multiple
                          disabled={ShowEveryone}
                          generateAddressTag={(searchTerm, matchedAddress) =>
                            generateAddressTag(searchTerm, matchedAddress)
                          }
                          listOfTags={Cities}
                          name="Cities"
                          onChange={(value) => this.onHandleChange('Cities', value)}
                          value={Cities}
                          filter={{
                            types: ['(cities)'],
                          }}
                        />
                      </div>

                      <div className="DiscoverPromotion__FormGroup">
                        <label htmlFor="States">
                          Add States
                          {!ShowEveryone && !Cities && !States && (
                            <span className="required-asterisk">*</span>
                          )}
                        </label>

                        <AddressTagsInput
                          disabled={ShowEveryone}
                          generateAddressTag={(searchTerm, matchedAddress) =>
                            generateStateAddressTag(searchTerm, matchedAddress)
                          }
                          listOfTags={States}
                          name="States"
                          onChange={(value) => this.onHandleChange('States', value)}
                          value={States}
                        />
                      </div>

                      <fieldset className="DiscoverPromotion__ShowEveryone">
                        <label>
                          <input
                            checked={ShowEveryone}
                            name="ShowEveryone"
                            onChange={(event) =>
                              this.onHandleChange('ShowEveryone', event.target.checked)
                            }
                            type="checkbox"
                          />
                          <span />
                          Show to everyone
                        </label>
                      </fieldset>
                    </div>

                    <div className="EditPromotion__Section">
                      <h3>Replace Promotion Image</h3>
                      <div className="EditPromotion__Section__Subtitle">
                        This image will appear as the main promotion image
                      </div>

                      <div className="EditPromotion__FormGroup">
                        <FileUpload
                          accept=".png, .PNG, .jpg, .JPG, .jpeg, .JPEG"
                          aspect={16 / 9}
                          height={220}
                          fullWidth
                          id="txtFile"
                          maxFileSize={4}
                          name="Image"
                          onChange={(name, value) => this.onHandleChange(name, value)}
                          placeholder={`
                              <div class="EditPromotion__FileUpload">
                                <span class="EditPromotion__FileUpload__PlusButton">+</span>
                                <span class="EditPromotion__FileUpload__CTA">Drag and drop an image</span>
                              </div>
                            `}
                          type="image"
                          value={Image}
                        />
                      </div>
                    </div>

                    <AsyncContent
                      content={(list) => (
                        <div className="EditPromotion__Section">
                          <h3>Delete Content</h3>

                          <Row
                            cellSpacing={13}
                            rowSpacing={60}
                            className="EditPromotion__FormGroup EditPromotion__DeleteContent"
                          >
                            {list.map((content) => (
                              <Col
                                lg={3}
                                md={3}
                                sm={4}
                                xs={12}
                                horizontalAlign="flex-start"
                                verticalAlign="center"
                                key={uuidv1()}
                              >
                                <div
                                  className="EditPromotion__DeleteContent__Thumbnail"
                                  style={{
                                    backgroundImage: `url(${
                                      content.VideoThumbnails &&
                                      content.VideoThumbnails[0] &&
                                      content.VideoThumbnails[0].SmallUrl
                                    })`,
                                  }}
                                >
                                  <button
                                    className="EditPromotion__DeleteContent__DeleteButton"
                                    onClick={async () =>
                                      this.deleteContent(content.PromotionContentId)
                                    }
                                    type="button"
                                  />
                                </div>

                                <h3 className="EditPromotion__DeleteContent__BusinessName">
                                  {content.BusinessName}
                                </h3>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      )}
                      emptyPageContent={null}
                      errorMessage={promotionContentsError}
                      expectedData={promotionContents}
                      expectNoData
                      loading={isFetchingPromotionContents}
                    />
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '15rem' }}
                    >
                      {IsLoading && <Loader />}
                    </div>
                    <div className="EditPromotion__FormActions">
                      <button
                        type="submit"
                        className="button small orange-theme"
                        disabled={IsLoading}
                      >
                        Save
                      </button>

                      <button
                        className="button small red-theme"
                        onClick={() =>
                          history.push(
                            `/admin/promoted-content/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`,
                          )
                        }
                        type="button"
                        disabled={IsLoading}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                )}
              </>
            )}
            emptyPageContent={
              <NotFound description="We cannot find the promotion" title="No results" />
            }
            errorMessage={promotionsError || error}
            expectedData={
              promotions &&
              promotions.find(({ PromotionId }) => PromotionId === Number(promotionId))
            }
            loading={loading}
          />
        </MasterPage>
      </>
    );
  }
}

EditPromotion.propTypes = {
  auth: bool.isRequired,
  getPromotionContents: func.isRequired,
  getPromotions: func.isRequired,
  history: HISTORY_TYPE.isRequired,
  isFetchingPromotionContents: bool.isRequired,
  isFetchingPromotions: bool.isRequired,
  match: ROUTER_MATCH_TYPE({ promotionId: string.isRequired }).isRequired,
  promotionContents: arrayOf(PROMOTION_CONTENT_TYPE).isRequired,
  promotionContentsError: string.isRequired,
  promotions: arrayOf(PROMOTION_TYPE).isRequired,
  promotionsError: string.isRequired,
};

const mapStateToProps = (state) => {
  const {
    isFetchingPromotionContents,
    isFetchingPromotions,
    promotionContents,
    promotionContentsError,
    promotions,
    promotionsError,
  } = state.promotions;

  return {
    isFetchingPromotionContents,
    isFetchingPromotions,
    promotionContents,
    promotionContentsError,
    promotions,
    promotionsError,
  };
};

export default connect(mapStateToProps, {
  getPromotions: getPromotionsAction,
  getPromotionContents: getPromotionContentsAction,
})(EditPromotion);
