import './index.sass';

import { bool } from 'prop-types';
import React, { useEffect, useState } from 'react';

import Breadcrumb from '../../components/Breadcrumb';
import MasterPage from '../../components/MasterPage';
import { isAdmin } from '../../utils/isAdmin';

/* global localStorage */
export default function Settings(props) {
  const { auth } = props;
  const [showFoursquareData, setShowFoursquareData] = useState(
    localStorage.getItem('showFoursquareData') === 'true',
  );

  useEffect(
    () => localStorage.setItem('showFoursquareData', showFoursquareData),
    [showFoursquareData],
  );

  return (
    <MasterPage auth={auth} pageTitle="Franki Admin: Settings" hasFooter>
      <Breadcrumb items={[{ url: '/', name: 'Home' }, { name: 'Settings' }]} />

      {isAdmin() ? (
        <div className="Settings">
          <h1>Settings</h1>

          <button
            className="switch-container"
            onClick={() => setShowFoursquareData(!showFoursquareData)}
            type="button"
          >
            <div className="switch-title">Show foursquare data</div>
            <div className={`switch ${showFoursquareData ? 'on' : 'off'}`} />
          </button>
        </div>
      ) : null}
    </MasterPage>
  );
}

Settings.propTypes = {
  auth: bool.isRequired,
};
