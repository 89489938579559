import { bool, func, shape, string } from 'prop-types';
import React from 'react';
import checkSvg from '../../assets/images/check.svg';
import './index.sass';

const CustomOption = ({ data, isSelected, setValue, getValue }) => {
  const handleClick = () => {
    const currentValues = getValue();
    const newValue = isSelected
      ? currentValues.filter(({ value }) => value !== data.value)
      : [...currentValues, data];

    setValue({
      action: !isSelected ? 'select-option' : 'deselect-option',
      option: data,
      newValue,
    });
  };

  return (
    <button
      type="button"
      className={`SocialAccountSelect__Item ${
        isSelected ? 'SocialAccountSelect__Item--Selected' : ''
      }`}
      onClick={handleClick}
    >
      {data.icon ? <img src={data.icon} alt={data.value} /> : null}
      <span>{data.label}</span>
      <div className="SocialAccountSelect__Item__AddIcon">
        {isSelected ? <img src={checkSvg} alt="selected" /> : '+'}
      </div>
    </button>
  );
};

CustomOption.propTypes = {
  data: shape({
    label: string.isRequired,
    value: string.isRequired,
    icon: string.isRequired,
  }).isRequired,
  isSelected: bool.isRequired,
  getValue: func.isRequired,
  setValue: func.isRequired,
};

export default CustomOption;
