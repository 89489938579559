import './index.sass';

import { bool, func, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { resendVerificationCode, updateIdentifier, verifyIdentifier } from '../../actions/auth';
import LightBox from '../LightBox';

export default function VerificationCode(props) {
  const { identifier, onVerified, resendTitle, title, longCode } = props;

  const inputEl0 = useRef(null);
  const inputEl1 = useRef(null);
  const inputEl2 = useRef(null);
  const inputEl3 = useRef(null);
  const inputEl4 = useRef(null);
  const inputEl5 = useRef(null);

  const [isResendDialogVisible, setIsResendDialogVisible] = useState(false);

  const [isUpdatingIdentifier, setIsUpdatingIdentifier] = useState(false);
  const [updatingIdentifierSuccess, setUpdatingIdentifierSuccess] = useState(null);

  const [isVerifyingIdentifier, setIsVerifyingIdentifier] = useState(false);
  const [verifyIdentifierData, setVerifyIdentifierData] = useState(null);

  const [isResendingVerificationCode, setIsResendingVerificationCode] = useState(false);
  const [resendingVerificationCodeSuccess, setResendingVerificationCodeSuccess] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);
  const [verifyingIdentifierErrorMessage, setVerifyingIdentifierErrorMessage] = useState(null);

  const [verificationInput0, setVerificationInput0] = useState(null);
  const [verificationInput1, setVerificationInput1] = useState(null);
  const [verificationInput2, setVerificationInput2] = useState(null);
  const [verificationInput3, setVerificationInput3] = useState(null);
  const [verificationInput4, setVerificationInput4] = useState(null);
  const [verificationInput5, setVerificationInput5] = useState(null);

  const code = useMemo(
    () =>
      [
        verificationInput0,
        verificationInput1,
        verificationInput2,
        verificationInput3,
        verificationInput4,
        verificationInput5,
      ].join(''),
    [
      verificationInput0,
      verificationInput1,
      verificationInput2,
      verificationInput3,
      verificationInput4,
      verificationInput5,
    ],
  );

  useEffect(() => {
    setErrorMessage(null);
    setVerifyingIdentifierErrorMessage(null);

    if (code.length === (longCode ? 6 : 4)) {
      setIsVerifyingIdentifier(true);

      verifyIdentifier({
        Identifier: identifier,
        VerificationCode: code,
      }).subscribe(
        setVerifyIdentifierData,
        (err) => {
          setVerifyingIdentifierErrorMessage(err);
          setIsVerifyingIdentifier(false);
        },
        () => setIsVerifyingIdentifier(false),
      );
    } else {
      if (!verificationInput0) {
        inputEl0.current.focus();
        return;
      }
      if (!verificationInput1) {
        inputEl1.current.focus();
        return;
      }
      if (!verificationInput2) {
        inputEl2.current.focus();
        return;
      }
      if (longCode) {
        if (!verificationInput3) {
          inputEl3.current.focus();
          return;
        }

        if (!verificationInput4) {
          inputEl4.current.focus();
          return;
        }

        inputEl5.current.focus();
      } else {
        inputEl3.current.focus();
      }
    }
  }, [
    setErrorMessage,
    setVerifyingIdentifierErrorMessage,
    code,
    setIsVerifyingIdentifier,
    setVerifyIdentifierData,
    verificationInput0,
    verificationInput1,
    verificationInput2,
    verificationInput3,
    verificationInput4,
    verificationInput5,
    inputEl0.current,
    inputEl1.current,
    inputEl2.current,
    inputEl3.current,
    inputEl4.current,
    inputEl5.current,
  ]);

  useEffect(() => {
    if (verifyIdentifierData) {
      setIsUpdatingIdentifier(true);
      updateIdentifier({ JWTToken: verifyIdentifierData }).subscribe(
        () => setUpdatingIdentifierSuccess(true),
        (err) => {
          setErrorMessage(err);
          setIsUpdatingIdentifier(false);
        },
        () => setIsUpdatingIdentifier(false),
      );
    }
  }, [verifyIdentifierData]);

  const onHandleResend = useCallback(
    (e) => {
      e.preventDefault();
      setErrorMessage(null);
      setVerifyingIdentifierErrorMessage(null);
      setIsResendingVerificationCode(true);
      resendVerificationCode({ Identifier: identifier }).subscribe(
        setResendingVerificationCodeSuccess,
        (err) => {
          setErrorMessage(err);
          setIsResendingVerificationCode(false);
        },
        () => setIsResendingVerificationCode(false),
      );
      setVerificationInput0('');
      setVerificationInput1('');
      setVerificationInput2('');
      setVerificationInput3('');
      setVerificationInput4('');
      setVerificationInput5('');
    },
    [
      setErrorMessage,
      setVerifyingIdentifierErrorMessage,
      setIsResendingVerificationCode,
      identifier,
      setResendingVerificationCodeSuccess,
      setErrorMessage,
      setVerificationInput0,
      setVerificationInput1,
      setVerificationInput2,
      setVerificationInput3,
      setVerificationInput4,
      setVerificationInput5,
    ],
  );

  useEffect(() => {
    if (resendingVerificationCodeSuccess) {
      setIsResendDialogVisible(true);
      setTimeout(() => setIsResendDialogVisible(false), 60 * 1000);
    } else {
      setIsResendDialogVisible(false);
    }
  }, [resendingVerificationCodeSuccess]);

  useEffect(() => {
    if (updatingIdentifierSuccess) {
      onVerified();
    }
  }, [updatingIdentifierSuccess]);

  const isFetching = useMemo(
    () => isResendingVerificationCode || isVerifyingIdentifier || isUpdatingIdentifier,
    [isResendingVerificationCode, isVerifyingIdentifier, isUpdatingIdentifier],
  );

  const error = useMemo(() => {
    const message = errorMessage || verifyingIdentifierErrorMessage;
    return message ? <div className="VerificationCode__Message error">{message}</div> : null;
  }, [errorMessage, verifyingIdentifierErrorMessage]);

  return (
    <div className="VerificationCode">
      <div className="form">
        <fieldset>
          <div className="VerificationCode__InputTitle">
            {title}
            <span>*</span>
          </div>

          <div className="VerificationCode__Inputs">
            <input
              className={verifyingIdentifierErrorMessage && 'VerificationCode__Input--Error'}
              onChange={({ target: { value } }) => setVerificationInput0(value)}
              maxLength="1"
              ref={inputEl0}
              type="text"
              value={verificationInput0 || ''}
            />

            <input
              className={verifyingIdentifierErrorMessage && 'VerificationCode__Input--Error'}
              onChange={({ target: { value } }) => setVerificationInput1(value)}
              maxLength="1"
              ref={inputEl1}
              type="text"
              value={verificationInput1 || ''}
            />

            <input
              className={verifyingIdentifierErrorMessage && 'VerificationCode__Input--Error'}
              onChange={({ target: { value } }) => setVerificationInput2(value)}
              maxLength="1"
              ref={inputEl2}
              type="text"
              value={verificationInput2 || ''}
            />

            <input
              className={verifyingIdentifierErrorMessage && 'VerificationCode__Input--Error'}
              onChange={({ target: { value } }) => setVerificationInput3(value)}
              maxLength="1"
              ref={inputEl3}
              type="text"
              value={verificationInput3 || ''}
            />

            {longCode ? (
              <>
                <input
                  className={verifyingIdentifierErrorMessage && 'VerificationCode__Input--Error'}
                  onChange={({ target: { value } }) => setVerificationInput4(value)}
                  maxLength="1"
                  ref={inputEl4}
                  type="text"
                  value={verificationInput4 || ''}
                />

                <input
                  className={verifyingIdentifierErrorMessage && 'VerificationCode__Input--Error'}
                  onChange={({ target: { value } }) => setVerificationInput5(value)}
                  maxLength="1"
                  ref={inputEl5}
                  type="text"
                  value={verificationInput5 || ''}
                />
              </>
            ) : null}
          </div>
        </fieldset>

        {error}

        <button
          onClick={onHandleResend}
          className="button orange VerificationCode__Resend"
          disabled={isFetching || !verifyingIdentifierErrorMessage}
          type="button"
        >
          {isFetching ? 'loading...' : 'RESEND'}
        </button>
      </div>

      <LightBox
        height={256}
        onClose={() => setIsResendDialogVisible(false)}
        show={isResendDialogVisible}
        title={resendTitle}
        width={431}
      >
        <p className="VerificationCode__LightBoxContent">
          You have 60 seconds to add the code before it times out.
        </p>
      </LightBox>
    </div>
  );
}

VerificationCode.propTypes = {
  identifier: string,
  onVerified: func.isRequired,
  resendTitle: string.isRequired,
  title: string.isRequired,
  longCode: bool,
};

VerificationCode.defaultProps = {
  identifier: '',
  longCode: false,
};
