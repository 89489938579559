import React from 'react';
import { components } from 'react-select';
import { ReactComponent as IconIndicator } from '../../assets/images/dropdown-indicator.svg';

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <IconIndicator width={10} height={6} />
  </components.DropdownIndicator>
);

export default DropdownIndicator;
