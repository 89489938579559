import React, { useState, Suspense, lazy } from 'react';
import Helmet from 'react-helmet';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { bool } from 'prop-types';
import { Col, Row } from '../../components/Grid';

import MasterPage from '../../components/MasterPage';
import Loader from '../../components/Loader';

const ListPromotion = lazy(() => import('./ListPromotion'));


function BusinessPromotion(props) {
  const { auth } = props;
  const [activeTab, setActiveTab] = useState(0);
  return (
    <>
      <Helmet title="Create promotion" />
      <MasterPage auth={auth}>

        <Tabs className="frankiCompactTabs mt-3 " onSelect={setActiveTab} selectedIndex={activeTab}>
          <TabList>
            <Tab>Active Promotion Pricing </Tab>
            <Tab>Past Promotion Pricing </Tab>
          </TabList>

          <TabPanel>
            <Row cellSpacing={25} rowSpacing={15}>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Suspense fallback={Loader}>
                  <ListPromotion isActive={!activeTab} />
                </Suspense>
              </Col>
            </Row>
          </TabPanel>

          <TabPanel>
            <Row cellSpacing={25} rowSpacing={15}>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Suspense fallback={Loader}>
                  <ListPromotion isActive={!activeTab} />
                </Suspense>
              </Col>
            </Row>
          </TabPanel>
        </Tabs>
      </MasterPage>

    </>
  );
}

BusinessPromotion.propTypes = {
  auth: bool.isRequired,
};


export default BusinessPromotion;
