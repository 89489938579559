export const ADD_PATH = 'ADD_PATH';
export const BACK = 'BACK';

/* global window */
export function addPath() {
  return dispatch => dispatch({ type: ADD_PATH, path: window.location.href });
}

export function back() {
  window.history.back();
  return dispatch => dispatch({ type: BACK });
}
