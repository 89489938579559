import './CreateOrEdit.sass';

import { isEqual } from 'lodash';
import { bool, func, instanceOf, shape, string } from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';

import { deleteUser } from '../../actions/users';
import AsyncContent from '../../components/AsyncContent';
import BusinessSelect from '../../components/BusinessSelect';
import { Col, Row } from '../../components/Grid';
import LightBox from '../../components/LightBox';
import MasterPage from '../../components/MasterPage';
import UserRoleSelect from '../../components/UserRoleSelect';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../config/pagination';
import HISTORY_TYPE from '../../types/history';
import { USER_DETAILS_TYPE } from '../../types/users';

const BUSINESS_MANAGER_GROUP_ID = 5;
const ALERT_DELAY_MS = 4000;
const DEFAULT_USER_PROFILE = {
  AssociatedAsManagerCorporateGroups: [],
  Email: '',
  FirstName: '',
  GroupId: 0,
  LastName: '',
  UserId: '',
};

/* global localStorage */
class UserCreateOrEdit extends React.Component {
  state = {
    alertTimeoutId: null,
    form: DEFAULT_USER_PROFILE,
    message: '',
    success: null,
    isDeleteUserConfirmationShown: false,
    deleteUserErrorMessage: '',
    showDeleteButton: this.props.showDeleteButton,
  };

  componentDidUpdate(prevProps) {
    const { userProfiles } = this.props;
    if (userProfiles && !isEqual(userProfiles, prevProps.userProfiles)) {
      this.setFormValue(userProfiles);
    }
  }

  onDeleteUser() {
    const { history, userProfiles } = this.props;
    deleteUser(userProfiles.UserId).subscribe(
      () => {
        localStorage.setItem('successDeletingUser', true);
        history.push(`/admin/users/${DEFAULT_PAGE_SIZE}/${DEFAULT_PAGE_INDEX}`);
      },
      msg => this.setState({ deleteUserErrorMessage: msg }),
    );
  }

  onHandleChange(name, value) {
    this.setState(({ form }) => ({
      form: { ...form, [name]: value },
    }));
  }

  async onHandleSubmit(event) {
    event.preventDefault();
    const { callBackOnSubmit } = this.props;
    const {
      form: { AssociatedAsManagerCorporateGroups, Email, FirstName, GroupId, LastName, Username },
    } = this.state;
    const result = await callBackOnSubmit({
      AssociatedAsManagerCorporateGroups: AssociatedAsManagerCorporateGroups
        ? AssociatedAsManagerCorporateGroups.map(({ CorporateGroupId }) => CorporateGroupId)
        : [],
      Email,
      FirstName,
      GroupId,
      LastName,
      Username,
    });

    const hideAlertTimeoutId = setTimeout(
      () => this.hideAlertMessage(hideAlertTimeoutId),
      ALERT_DELAY_MS,
    );

    this.showAlertMessage(hideAlertTimeoutId, result);
  }

  setFormValue(userProfiles) {
    this.setState({
      form: userProfiles,
    });
  }

  hideAlertMessage(hideAlertTimeoutId) {
    const { alertTimeoutId } = this.state;
    if (alertTimeoutId === hideAlertTimeoutId) {
      this.setState({
        success: null,
        message: '',
      });
    }
  }

  showAlertMessage(alertTimeoutId, result) {
    this.setState({
      success: result.Success,
      message: result.Message,
      alertTimeoutId,
    });
  }

  render() {
    const {
      form: { AssociatedAsManagerCorporateGroups, Email, FirstName, GroupId, LastName },
      deleteUserErrorMessage,
      isDeleteUserConfirmationShown,
      message,
      success,
      showDeleteButton,
    } = this.state;
    const { className, pageSubtitle, pageTitle, submitButtonCaption, auth, breadcrumbs } = this.props;
    const isBusinessManagement = GroupId === BUSINESS_MANAGER_GROUP_ID;

    return (
      <div className="CreateOrEditUser__Wrapper">
        <Helmet title="Franki Admin: User Management" />

        <MasterPage auth={auth} headerBreadcrumb={breadcrumbs} noWrapper>
          <AsyncContent
            content={() => (
              <div className={`CreateOrEditUser ${className}`}>
                <form onSubmit={event => this.onHandleSubmit(event)}>
                  <div className="form-header">
                    <div className="CreateOrEditUser__Overline">
                      Step
                      {' '}
                      <strong>1</strong>
                      {' '}
                      of 1
                      <i className="tooltip-icon" />
                    </div>
                    <div className="CreateOrEditUser__Title">{pageTitle}</div>
                    {pageSubtitle && (
                      <div className="CreateOrEditUser__Subtitle">{pageSubtitle}</div>
                    )}
                  </div>
                  <div className="form-body">
                    <Row cellSpacing={10} rowSpacing={10}>
                      <Col lg={6} md={6} sm={6} xs={12}>
                        <div className="form-group">
                          <fieldset>
                            <label className="form-label" htmlFor="txtFirstName">
                              First name
                              <span className="required-asterisk">*</span>
                            </label>
                            <input
                              className="full"
                              id="txtFirstName"
                              name="FirstName"
                              onChange={({ target: { name, value } }) => this.onHandleChange(name, value)
                              }
                              placeholder="John"
                              required
                              maxLength={50}
                              type="text"
                              value={FirstName}
                            />
                          </fieldset>
                        </div>
                      </Col>

                      <Col lg={6} md={6} sm={6} xs={12}>
                        <div className="form-group">
                          <fieldset>
                            <label className="form-label" htmlFor="txtLastname">
                              Last name
                              <span className="required-asterisk">*</span>
                            </label>
                            <input
                              className="full"
                              id="txtLastname"
                              name="LastName"
                              onChange={({ target: { name, value } }) => this.onHandleChange(name, value)
                              }
                              placeholder="Doe"
                              required
                              type="text"
                              maxLength={50}
                              value={LastName}
                            />
                          </fieldset>
                        </div>
                      </Col>
                    </Row>

                    <Row cellSpacing={10} rowSpacing={10}>
                      <Col lg={6} md={6} sm={6} xs={12}>
                        <div className="form-group">
                          <fieldset>
                            <label className="form-label" htmlFor="txtEmailaddress">
                              Email address
                              <span className="required-asterisk">*</span>
                            </label>
                            <input
                              className="full"
                              id="txtEmailaddress"
                              name="Email"
                              onChange={({ target: { name, value } }) => this.onHandleChange(name, value)
                              }
                              placeholder="doe.john@gmail.com"
                              required
                              type="email"
                              value={Email}
                            />
                          </fieldset>
                        </div>
                      </Col>
                      <Col lg={6} md={6} sm={6} xs={12}>
                        <UserRoleSelect
                          id="userRole"
                          label="Role"
                          name="GroupId"
                          onChange={value => this.onHandleChange('GroupId', value)}
                          placeholder="select a role..."
                          required
                          value={GroupId}
                        />
                      </Col>
                    </Row>

                    {isBusinessManagement && (
                      <Row cellSpacing={10} rowSpacing={10}>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <div className="CreateOrEditUser__Business">
                            <BusinessSelect
                              id="userBusiness"
                              label="Bussiness"
                              name="AssociatedAsManagerCorporateGroups"
                              onChange={selectedItems => this.onHandleChange(
                                'AssociatedAsManagerCorporateGroups',
                                selectedItems,
                              )
                              }
                              placeholder="select your business..."
                              value={
                                AssociatedAsManagerCorporateGroups != null
                                  ? AssociatedAsManagerCorporateGroups.filter(f => f.DisplayName)
                                  : AssociatedAsManagerCorporateGroups
                              }
                            />
                            <div className="CreateOrEditUser__Business__Tooltip">
                              <i className="tooltip-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </div>

                  <div className="form-actions">
                    {pageTitle == 'Edit User' && (
                      <a
                        onClick={() => {
                          this.props.history.go(-1);
                        }}
                        type="button"
                        className="button"
                      >
                        Cancel
                      </a>
                    )}
                    {showDeleteButton && (
                      <button
                        onClick={() => this.setState({ isDeleteUserConfirmationShown: true })}
                        type="button"
                        className="button red-theme"
                      >
                        DELETE
                      </button>
                    )}
                    <button type="submit" className="button orange-theme">
                      {submitButtonCaption}
                    </button>
                  </div>
                </form>
              </div>
            )}
            errorMessage={!success && message}
            expectNoData
            successMessage={success && message}
          />
        </MasterPage>

        {isDeleteUserConfirmationShown && (
          <LightBox
            className="DeleteUserConfirmationShown"
            style={{
              width: 600,
              height: null,
              marginLeft: -250,
              marginTop: -200,
            }}
            onClose={() => this.setState({ isDeleteUserConfirmationShown: false })}
            show
            spacing={24}
            title="Attention!"
            width={450}
          >
            <div className="DeleteUserConfirmationShown__Content">
              Are you sure you want to delete this user and their content? You cannot undo this
              action.
            </div>

            <div className="DeleteUserConfirmationShown__Actions">
              <div className="DeleteUserConfirmationShown__Actions">
                <button
                  type="button"
                  className="button small"
                  onClick={() => this.setState({ isDeleteUserConfirmationShown: false })}
                >
                  NO, CANCEL
                </button>
                <button
                  type="button"
                  className="button small red-theme"
                  onClick={() => this.onDeleteUser()}
                >
                  YES, DELETE
                </button>
              </div>
            </div>

            <div className="DeleteUserConfirmationShown__Error">{deleteUserErrorMessage}</div>
          </LightBox>
        )}
      </div>
    );
  }
}

UserCreateOrEdit.propTypes = {
  auth: bool.isRequired,
  showDeleteButton: bool.isRequired,
  breadcrumbs: instanceOf(Array),
  callBackOnSubmit: func.isRequired,
  className: string,
  history: HISTORY_TYPE.isRequired,
  pageSubtitle: string,
  pageTitle: string.isRequired,
  submitButtonCaption: string.isRequired,
  userProfiles: shape(USER_DETAILS_TYPE),
};

UserCreateOrEdit.defaultProps = {
  breadcrumbs: [],
  className: '',
  pageSubtitle: '',
  userProfiles: {},
  showDeleteButton: true,
};

export default withRouter(UserCreateOrEdit);
