import uuidv1 from 'uuid/v1';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../config/pagination';
import { APIRequest } from '../utils/api';
import { isFoursquareData } from '../utils/placeId';

export const GET_VIDEOS = 'GET_VIDEOS';
export const GET_VIDEOS_ERROR = 'GET_VIDEOS_ERROR';
export const GET_VIDEOS_SUCCESS = 'GET_VIDEOS_SUCCESS';

export const CREATE_VIDEO = 'CREATE_VIDEO';
export const CREATE_VIDEO_ERROR = 'CREATE_VIDEO_ERROR';
export const CREATE_VIDEO_SUCCESS = 'CREATE_VIDEO_SUCCESS';

export const REMOVE_LINK = 'REMOVE_LINK';
export const SHARE_LINK = 'SHARE_LINK';
export const SHARE_LINK_ERROR = 'SHARE_LINK_ERROR';
export const SHARE_LINK_SUCCESS = 'SHARE_LINK_SUCCESS';

export const DELETE_VIDEO = 'DELETE_VIDEO';
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS';
export const DELETE_VIDEO_ERROR = 'DELETE_VIDEO_ERROR';
export const DELETE_VIDEO_INIT = 'DELETE_VIDEO_INIT';

export function createVideo(data) {
  return async (dispatch) => {
    dispatch({ type: CREATE_VIDEO });
    await APIRequest({
      headers: {
        'Content-Type': `multipart/form-data; charset=utf-8; boundary=${uuidv1()}`,
      },
      baseApiUri: '/api',
      uri: 'socialfeed/CreateVideoContent',
      method: 'POST',
      data,
    })
      .then(res => dispatch({
        type: CREATE_VIDEO_SUCCESS,
        payload: { data: res.Data },
      }))
      .catch(error => dispatch({
        type: CREATE_VIDEO_ERROR,
        payload: { error },
      }));
  };
}

export function getVideos(query, pageSize = DEFAULT_PAGE_SIZE, pageIndex = DEFAULT_PAGE_INDEX) {
  return async (dispatch) => {
    dispatch({ type: GET_VIDEOS });
    APIRequest({
      method: 'GET',
      uri: isFoursquareData()
        ? `V3/Content${query}&PageSize=${pageSize}&PageIndex=${pageIndex}`
        : `Content${query}&pageSize=${pageSize}&pageIndex=${pageIndex}`,
    })
      .then(({ Data }) => dispatch({
        type: GET_VIDEOS_SUCCESS,
        payload: {
          data: Data,
        },
      }))
      .catch(error => dispatch({
        type: GET_VIDEOS_ERROR,
        payload: error,
      }));
  };
}

export function shareLink(contentKey) {
  return async (dispatch) => {
    dispatch({ type: SHARE_LINK });
    APIRequest({
      method: 'GET',
      uri: `Content/${contentKey}/ShareLink`,
    })
      .then(({ Data }) => dispatch({
        type: SHARE_LINK_SUCCESS,
        payload: { Data },
      }))
      .catch(error => dispatch({
        type: SHARE_LINK_ERROR,
        payload: error,
      }));
  };
}

export function removeLink() {
  return async (dispatch) => {
    dispatch({ type: REMOVE_LINK });
  };
}

export function cleanDeleteVideo() {
  return async (dispatch) => {
    dispatch({ type: DELETE_VIDEO_INIT });
  };
}

export function deleteVideo(contentKey) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_VIDEO,
    });

    APIRequest({
      method: 'POST',
      baseApiUri: '/api',
      uri: `SocialFeed/ArchiveContent?contentKey=${contentKey}`,
    }).then((res) => {
      if (res.Success) {
        dispatch({
          type: DELETE_VIDEO_SUCCESS,
          payload: contentKey,
        });
      } else {
        dispatch({
          type: DELETE_VIDEO_ERROR,
          payload: res.Message,
        });
      }
    }).catch((err) => {
      dispatch({
        type: DELETE_VIDEO_ERROR,
        payload: err,
      });
    });
  };
}
