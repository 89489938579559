import './index.sass';

import React from 'react';
import { number, string } from 'prop-types';

const ProgressBar = (props) => {
  const { totalSteps, activeStep, className } = props;

  return totalSteps > 0 ? (
    <div className={`ProgressBar ${className}`}>
      {[...Array(totalSteps).keys()].map((step) => (
        <>
          <div
            className={`ProgressBar__Step ${
              step + 1 === activeStep ? 'ProgressBar__Step--Active' : ''
            }`}
          >
            {step + 1}
          </div>
          {step + 1 < totalSteps && <div className="ProgressBar__Connector" />}
        </>
      ))}
    </div>
  ) : (
    <></>
  );
};

ProgressBar.propTypes = {
  totalSteps: number,
  activeStep: number,
  className: string,
};

ProgressBar.defaultProps = {
  totalSteps: 0,
  activeStep: 1,
  className: '',
};

export default ProgressBar;
