/* eslint-disable react/no-danger */
import { bool, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import './index.sass';

const classWithModification = (className, classModification) => {
  const classList = [className];
  if (classModification) {
    classList.push(`${className}--${classModification}`);
  }
  return classList.join(' ');
};

const PlanCard = (props) => {
  const { bgDeep, classModification, thumbTitle, thumbSubtitles, heading, text, cta } = props;
  return (
    <div className="PlanCard">
      {bgDeep && <div className="PlanCard__TopBorder" />}

      {thumbTitle && (
        <div className="PlanCard__Thumb">
          <div className="PlanCard__Thumb__Container">
            <span className="PlanCard__Thumb__Title">{thumbTitle}</span>

            <span className="PlanCard__Thumb__Subtitles">{thumbSubtitles}</span>
          </div>
        </div>
      )}

      <div className={thumbTitle ? 'PlanCard__Detail PlanCard__Detail--Large' : 'PlanCard__Detail'}>
        {heading && (
          <h3
            className={classWithModification('PlanCard__Detail__Title', classModification)}
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}

        {text && (
          <p className={classWithModification('PlanCard__Detail__Description', classModification)}>
            {text}
          </p>
        )}

        {cta && (
          <div className="PlanCard__CTA">
            <Link to="/membership" className="button orange-theme small">
              CHECK OUT OUR PLANS
            </Link>
            <a
              href="https://frankiapp.com/business/"
              className="button small button-outline"
              target="_blank"
              rel="noopener noreferrer"
            >
              LEARN MORE ABOUT FRANKI
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

PlanCard.propTypes = {
  bgDeep: bool,
  classModification: string,
  cta: bool,
  heading: string,
  text: string,
  thumbSubtitles: string,
  thumbTitle: string,
};

PlanCard.defaultProps = {
  bgDeep: false,
  classModification: '',
  cta: false,
  heading: '',
  text: '',
  thumbSubtitles: '',
  thumbTitle: '',
};

export default PlanCard;
