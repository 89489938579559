import './CustomSelectRequiredInput.sass';

import { bool } from 'prop-types';
import React from 'react';

/*
 * Because react-select doesn't support required property, as mentioned in https://github.com/JedWatson/react-select/issues/2751,
 * before the official feature is introduced. I make a workaround for this.
 * TODO: Remove when the "required" attribute is supported by react-select.
 * @author: Thien Nguyen
 */
class CustomSelectRequiredInput extends React.PureComponent {
  render() {
    const { required, hasValue } = this.props;
    if (!required || hasValue) {
      return null;
    }

    return <input className="CustomSelectRequiredInput" required tabIndex={-1} />;
  }
}

CustomSelectRequiredInput.propTypes = {
  required: bool,
  hasValue: bool,
};

CustomSelectRequiredInput.defaultProps = {
  required: false,
  hasValue: false,
};

export default CustomSelectRequiredInput;
