/* global document */
import './index.sass';

import { arrayOf, bool, func, object, oneOf, oneOfType, shape, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import uuidv1 from 'uuid/v1';

const HamburgerMenu = (props) => {
  const {
    className,
    dropdownPosition,
    ellipsisColor,
    icon,
    menuItems,
    position,
    itemClassName,
    text,
    textClass,
  } = props;

  const [menuAnchor, setMenuAnchor] = useState(false);
  const ref = useRef(null);

  function onHandleClickAway(mouseEvent) {
    if (ref.current && !ref.current.contains(mouseEvent.target)) {
      setMenuAnchor(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onHandleClickAway);
    return () => document.removeEventListener('mousedown', onHandleClickAway);
  }, []);

  function onHandleClick(mouseEvent) {
    mouseEvent.stopPropagation();
    setMenuAnchor(!menuAnchor);
  }

  return (
    <div
      className={`
        ${className} HamburgerMenu HamburgerMenu--Position${position}
        ${Boolean(menuAnchor) && 'HamburgerMenu--Active'}
      `}
      ref={ref}
    >
      <span
        onClick={onHandleClick}
        onKeyPress={onHandleClick}
        role="button"
        tabIndex="0"
        className={`HamburgerMenu__Visible ${textClass}`}
      >
        <i
          className={`HamburgerMenu__Icon ${icon || 'icon-ellipsis'}`}
          style={{ color: ellipsisColor }}
        />
        <span>{text}</span>
      </span>
      {Boolean(menuAnchor) && (
        <ul className={`HamburgerMenu__Dropdown HamburgerMenu__Dropdown--${dropdownPosition}`}>
          {menuItems.map((item) => (
            <li
              key={uuidv1()}
              className={`${item.highlight && 'HamburgerMenu__Dropdown__Highlight'} ${
                item.hasTopBorder && 'HamburgerMenu__Dropdown__HasTopBorder'
              } ${itemClassName}`}
              role="menuItem"
            >
              {item.url ? <Link to={item.url}>{item.innerContent}</Link> : null}
              {item.action ? (
                <button onClick={item.action} type="button">
                  {item.innerContent}
                </button>
              ) : null}
              {!item.url && !item.action ? item.innerContent : null}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

HamburgerMenu.propTypes = {
  className: string,
  itemClassName: string,
  dropdownPosition: oneOf(['Bottom', 'Left', 'Right']),
  ellipsisColor: string,
  icon: string,
  menuItems: arrayOf(
    shape({
      hasTopBorder: bool,
      highlight: bool,
      action: func,
      innerContent: oneOfType([object, string]),
      url: string,
    }),
  ).isRequired,
  position: oneOf(['Left', 'Right']),
  text: string,
  textClass: string,
};

HamburgerMenu.defaultProps = {
  className: '',
  itemClassName: '',
  dropdownPosition: 'Bottom',
  ellipsisColor: '#333',
  icon: '',
  position: 'Right',
  text: '',
  textClass: '',
};

export default HamburgerMenu;
