import calendar from '../assets/images/amenities/calendar.png';
import card from '../assets/images/amenities/card.png';
import hour from '../assets/images/amenities/hour.png';
import tv from '../assets/images/amenities/tv.png';
import moto from '../assets/images/amenities/moto.png';
import kids from '../assets/images/amenities/kids.png';
import wifi from '../assets/images/amenities/wifi.png';
import park from '../assets/images/amenities/park.png';
import bag from '../assets/images/amenities/bag.png';
import desk from '../assets/images/amenities/desk.png';
import smoke from '../assets/images/amenities/smoke.png';
import score from '../assets/images/amenities/score.png';
import fork from '../assets/images/amenities/fork.png';
import handicap from '../assets/images/amenities/handicap.png';
import glass from '../assets/images/amenities/glass.png';
import mask from '../assets/images/amenities/mask.png';

export const businessOptions = [
  {
    key: 'Reservations',
    values: ['Reservations'],
    icon: calendar,
  }, {
    key: 'Payment',
    values: ['CashOnly', 'CreditCard', 'ApplePay', 'GooglePay', 'Cryptocurrency'],
    icon: card,
  },
  {
    key: 'HappyHourSpecials',
    values: ['HappyHourSpecials'],
    icon: hour,
  },
  {
    key: 'TV',
    values: ['TV'],
    icon: tv,
  },
  {
    key: 'Delivery',
    values: ['Delivery'],
    icon: moto,
  },
  {
    key: 'KidFriendly',
    values: ['KidFriendly'],
    icon: kids,
  },
  {
    key: 'FreeWiFi',
    values: ['FreeWiFi'],
    icon: wifi,
  },
  {
    key: 'Parking',
    values: ['Parking'],
    icon: park,
  },
  {
    key: 'TakeOut',
    values: ['TakeOut'],
    icon: bag,
  },
  {
    key: 'OutdoorSeating',
    values: ['OutdoorSeating'],
    icon: desk,
  },
  {
    key: 'Smoking',
    values: ['Smoking'],
    icon: smoke,
  },
  {
    key: 'HealthScore',
    values: ['HealthScore'],
    icon: score,
  },
  {
    key: 'Dietary',
    values: ['Vegan', 'Vegetarian'],
    icon: fork,
  },
  {
    key: 'WheelchairFriendly',
    values: ['WheelchairFriendly'],
    icon: handicap,
  },
  {
    key: 'FullBar',
    values: ['FullBar'],
    icon: glass,
  },
  {
    key: 'StaffWearsMasks',
    values: ['StaffWearsMasks'],
    icon: mask,
  },
];

export const flattenedOptions = (options) => {
  const flattened = [];

  const findSubs = (arr) => {
    arr.forEach(({ Name, Id, DisplayString, Values, Options }) => {
      if (Name && Id && DisplayString) {
        flattened.push({ Name, Id, DisplayString });
      }
      if (Options && Options.length > 0) {
        findSubs(Options);
      }
      if (Values && Values.length > 0) {
        Values.forEach(({ Key, Value }) => {
          if (Key && Value) {
            flattened.push({ Name: Value, Id: Key, DisplayString: Value });
          }
        });
      }
    });
  };

  findSubs(options);

  return flattened;
};

export const getHealthScoreString = item => item.DisplayString || item.DisplayName || item.Name || item.Id || item;


export const getParkingString = arr => arr.map(item => item.DisplayString).join(', ');
export default businessOptions;
