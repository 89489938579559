import USER_ROLES from '../config/user-roles';
import ActivatedAccount from '../containers/ActivatedAccount';
import AllGigs from '../containers/AllGigs';
import ForgotPassword from '../containers/Auth/ForgotPassword';
import Login from '../containers/Auth/Login';
import ResetPassword from '../containers/Auth/ResetPassword';
import Signup from '../containers/Auth/Signup';
import BusinessAdd from '../containers/Business/BusinessAdd';
import BusinessEdit from '../containers/Business/BusinessEdit';
import Businesses from '../containers/Business/Businesses';
import BusinessesBillingHistory from '../containers/Business/BusinessesBillingHistory';
import BusinessGigCreateSuccessfully from '../containers/Business/BusinessGig/BusinessGigCreateSuccessfully';
import BusinessGigDetails from '../containers/Business/BusinessGig/BusinessGigDetails';
import BusinessesGigsEdit from '../containers/Business/BusinessGig/BusinessGigEdit';
import BusinessesGigs from '../containers/Business/BusinessGig/BusinessGigs';
import BusinessPaymentMethods from '../containers/Business/BusinessPaymentMethods';
import BusinessProfile from '../containers/Business/BusinessProfile';
import BusinessProfileFree from '../containers/Business/BusinessProfileFree';
import BusinessProfileIllustrations from '../containers/Business/BusinessProfileIllustrations';
import BusinessProfileTrial from '../containers/Business/BusinessProfileTrial';
import BusinessUsers from '../containers/Business/BusinessUsers';
import BusinessesVideos from '../containers/Business/BusinessVideos';
import BusinessVideoDetails from '../containers/Business/BusinessVideos/BusinessVideoDetails';
import BusinessVideoForm from '../containers/Business/BusinessVideos/BusinessVideoForm';
import ManagePlan from '../containers/Business/ManagePlan';
import BusinessPhoneVerification from '../containers/BusinessPhoneVerification';
import BusinessPromotion from '../containers/BusinessPromotion';
import ClaimMyBusiness from '../containers/ClaimMyBusiness';
import BusinessProfileCreate from '../containers/Business/BusinessProfileCreate';
import Contact from '../containers/Contact';
import ContactSF from '../containers/ContactSF';
import EmailCodeVerification from '../containers/EmailCodeVerification';
import FlaggedContent from '../containers/FlaggedContent';
import FlaggedContentVideoDetails from '../containers/FlaggedContent/VideoDetails';
import Home from '../containers/Home';
import Icons from '../containers/Icons';
import MobileCodeVerification from '../containers/MobileCodeVerification';
import Profile from '../containers/Profile';
import CreatePromotion from '../containers/PromotedContent/CreatePromotion';
import EditPromotion from '../containers/PromotedContent/EditPromotion';
import PromotionDetails from '../containers/PromotedContent/PromotionDetails';
import Promotions from '../containers/PromotedContent/Promotions';
import Register from '../containers/Register';
import Membership from '../containers/Register/Membership';
import PlanAndAccount from '../containers/Register/PlanAndAccount';
import Settings from '../containers/Settings';
import UserCreate from '../containers/User/UserCreate';
import UserEdit from '../containers/User/UserEdit';
import Users from '../containers/User/Users';
import VideoDetails from '../containers/Videos/VideoDetails';
import Videos from '../containers/Videos/Videos';
import CreatorFund from '../containers/CreatorFund';

const myRoutes = [
  {
    path: '/',
    active: true,
    private: false,
    component: Login,
  },
  {
    path: '/sign-up/:businessId',
    active: true,
    private: false,
    component: Signup,
  },
  {
    path: '/contact',
    active: true,
    public: true,
    component: Contact,
  },
  {
    path: '/contact-sales-force',
    active: true,
    public: true,
    component: ContactSF,
  },
  {
    path: '/claim-my-business',
    active: true,
    public: true,
    component: ClaimMyBusiness,
  },
  {
    path: '/create-business-profile',
    active: true,
    public: true,
    component: BusinessProfileCreate,
  },
  {
    path: '/email-code-verification/:email/:businessId',
    active: true,
    private: false,
    component: EmailCodeVerification,
  },
  {
    path: '/mobile-code-verification/:businessId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: MobileCodeVerification,
  },
  {
    path: '/business-phone-verification/:businessId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessPhoneVerification,
  },
  {
    path: '/reset-password',
    active: true,
    private: false,
    component: ResetPassword,
  },
  {
    path: '/forgot-password',
    active: true,
    private: false,
    component: ForgotPassword,
  },
  {
    path: '/admin',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: Home,
  },
  {
    path: '/admin/activated-account',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: ActivatedAccount,
  },
  {
    path: '/admin/register-business',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin],
    component: BusinessAdd,
  },
  {
    path: '/admin/businesses/:itemsCountPerPage?/:pageNumber?',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin],
    component: Businesses,
  },
  {
    path: '/admin/business-profile/:corporateGroupId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessProfile,
  },
  {
    path: '/admin/business-profile-trial/:corporateGroupId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessProfileTrial,
  },
  {
    path: '/admin/business-profile-free/:corporateGroupId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessProfileFree,
  },
  {
    path: '/admin/business-profile-illustrations/:corporateGroupId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessProfileIllustrations,
  },
  {
    path: '/admin/business-edit/:corporateGroupId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessEdit,
  },
  {
    path: '/admin/business-videos/:corporateGroupId/:itemsCountPerPage?/:pageNumber?',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessesVideos,
  },
  {
    path: '/admin/business-video-details/:corporateGroupId/:contentKey',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessVideoDetails,
  },
  {
    path: '/admin/business-video-promote/:corporateGroupId/:contentKey/:videoId/:itemsCountPerPage?/:pageNumber?',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin],
    component: Promotions,
  },
  {
    path: '/business-promotions',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessPromotion,
  },
  {
    path: '/admin/business-video-create/:corporateGroupId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessVideoForm,
  },
  {
    path: '/admin/business-video-edit/:corporateGroupId/:videoId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessVideoForm,
  },
  {
    path: '/admin/business-gigs/:corporateGroupId/:itemsCountPerPage?/:pageNumber?',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessesGigs,
  },
  {
    path: '/admin/business-gig-details/:corporateGroupId/:gigId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessGigDetails,
  },
  {
    path: '/admin/business-gig-create-successfully/:corporateGroupId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessGigCreateSuccessfully,
  },
  {
    path: '/admin/business-gig-edit/:corporateGroupId/:gigId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessesGigsEdit,
  },
  {
    path: '/admin/business-users/:corporateGroupId/:itemsCountPerPage?/:pageNumber?',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessUsers,
  },
  {
    path: '/admin/business-manage-plan/:corporateGroupId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: ManagePlan,
  },
  {
    path: '/admin/business-payment-method/:corporateGroupId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessPaymentMethods,
  },
  {
    path: '/membership',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: Membership,
  },
  {
    path: '/admin/creator-fund',
    active: true,
    private: true,
    permission: [USER_ROLES.admin],
    component: CreatorFund,
  },
  {
    path: '/admin/creator-fund/:campaignId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin],
    component: CreatorFund,
  },
  {
    path: '/admin/gigs/:itemsCountPerPage?/:pageNumber?',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin],
    component: AllGigs,
  },
  {
    path: '/admin/videos/:itemsCountPerPage?/:pageNumber?',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.contentAdmin],
    component: Videos,
  },
  {
    path: '/admin/video-details/:corporateGroupId/:contentKey',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: VideoDetails,
  },
  {
    path: '/admin/flagged-contents/:itemsCountPerPage?/:pageNumber?',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.contentAdmin],
    component: FlaggedContent,
  },
  {
    path: '/admin/flagged-content-details/:ItemId/:ContentKey',
    active: true,
    private: true,
    permission: [
      USER_ROLES.admin,
      USER_ROLES.saleAdmin,
      USER_ROLES.businessManager,
      USER_ROLES.contentAdmin,
    ],
    component: FlaggedContentVideoDetails,
  },
  {
    path: '/admin/users/:itemsCountPerPage?/:pageNumber?',
    active: true,
    private: true,
    permission: [USER_ROLES.admin],
    component: Users,
  },
  {
    path: '/admin/user-create',
    active: true,
    private: true,
    permission: [USER_ROLES.admin],
    component: UserCreate,
  },
  {
    path: '/admin/user-edit/:username',
    active: true,
    private: true,
    permission: [USER_ROLES.admin],
    component: UserEdit,
  },
  {
    path: '/admin/settings',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: Settings,
  },
  {
    path: '/admin/profile',
    active: true,
    private: true,
    permission: [
      USER_ROLES.admin,
      USER_ROLES.contentAdmin,
      USER_ROLES.saleAdmin,
      USER_ROLES.businessManager,
    ],
    component: Profile,
  },
  {
    path: '/admin/business-billing-history/:corporateGroupId/:itemsCountPerPage?/:pageNumber?',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin, USER_ROLES.businessManager],
    component: BusinessesBillingHistory,
  },
  {
    path: '/admin/promoted-content/:itemsCountPerPage?/:pageNumber?',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin],
    component: Promotions,
  },
  {
    path: '/admin/promotion-details/:promotionId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin],
    component: PromotionDetails,
  },
  {
    path: '/admin/edit-promotion/:promotionId',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin],
    component: EditPromotion,
  },
  {
    path: '/admin/create-promotion/:type?',
    active: true,
    private: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin],
    component: CreatePromotion,
  },

  /** ********** REGISTER ROUTERS *********** */
  {
    path: '/register-cannabis',
    active: true,
    private: false,
    component: Register,
    routerProps: {
      businessType: 'cannabis',
    },
  },
  {
    path: '/register-hospitality',
    active: true,
    private: false,
    component: Register,
    routerProps: {
      businessType: 'hospitality',
    },
  },
  {
    path: '/register-cannabis/account',
    active: true,
    private: false,
    component: PlanAndAccount,
    routerProps: {
      businessType: 'cannabis',
    },
  },
  {
    path: '/register-hospitality/account',
    active: true,
    private: false,
    component: PlanAndAccount,
    routerProps: {
      businessType: 'hospitality',
    },
  },

  /** ********** DEV ROUTERS *********** */
  {
    path: '/icons',
    active: true,
    public: true,
    permission: [USER_ROLES.admin, USER_ROLES.saleAdmin],
    component: Icons,
  },
];

export default myRoutes;
